<template>
<div @click="interestVisible=true">
    <el-dialog
        class="log2"
        width="35%"
        title="编辑兴趣课类"
        :before-close="handleCancel"
        :visible.sync="interestVisible"
        custom-modal
        append-to-body>
        <el-input size="mini"
                  class="inp"
                  placeholder="搜索"
                  v-model="filterText">
            <template #suffix>
                <i class="el-icon-search"></i>
            </template>
        </el-input>
        <div class="check">
            <p>已选择:</p>
            <el-tag
                v-for="(item) in checkItems"
                :key="item.id"
                type="info"
                closable
                @close="handleClose(item)"
                style="background-color: #f5f7fa;">
                {{ (dataMap[item.parentId] ? dataMap[item.parentId].name + ' ' : '') + item.name }}
            </el-tag>
        </div>
        <el-tree
            class="filter-tree"
            :data="computedData"
            show-checkbox
            :props="{
                children: 'children',
                label: 'name'
            }"
            node-key="id"
            :render-content="renderContent"
            check-strictly
            @check-change="handleCheckChange"
            ref="tree">
        </el-tree>
        <template v-slot:footer>
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleConfirm" :loading="confirmLoading">确定</el-button>
        </template>
    </el-dialog>
    <slot></slot>
</div>
</template>
<script>
import {cloneObject} from '../../utils/ObjectUtils'
import {getClassTypeListApi} from '../../api/mall/classTypeApi'

export default {
    emits: ['update:value', 'change'],
    props: {
        value: {
            type: Array,
            default () {
                return []
            }
        },
        onOk: Function
    },
    model: {
        event: 'update:value',
        prop: 'value'
    },
    data () {
        return {
            simpleData: [],
            data: [],
            interestVisible: false,
            filterText: '',
            checkItems: [],
            loading: false,
            confirmLoading: false
        }
    },
    methods: {
        handleCheckChange (val, check) {
            if (check) {
                this.checkItems.push(val)
            } else {
                let index = -1
                for (let i = 0; i < this.checkItems.length; i++) {
                    const item = this.checkItems[i]
                    if (item.id === val.id) {
                        index = i
                    }
                }
                if (index >= 0) {
                    this.checkItems.splice(index, 1)
                }
            }
            if (val.parentId === 0) {
                for (let i = 0; i < this.data.length; i++) {
                    const classType = this.data[i]
                    if (val.id === classType.id && classType.children) {
                        for (let j = 0; j < classType.children.length; j++) {
                            this.$set(this.data[i].children[j], 'disabled', check)
                            if (check) {
                                this.$refs.tree.setChecked(classType.children[j].id, false)
                            }
                        }
                        break
                    }
                }
            }
        },
        handleCancel () {
            this.interestVisible = false
            this.checkItems = []
            this.$nextTick(() => {
                this.$refs.tree.setCheckedKeys([])
            })
        },
        handleConfirm () {
            if (this.checkItems.length) {
                const likeSubjectIds = this.checkItems.map(item => item.id)
                const handleOk = () => {
                    this.$emit('update:value', likeSubjectIds)
                    this.$emit('change', cloneObject(this.checkItems))
                    this.interestVisible = false
                }
                if (typeof this.onOk==='function') {
                    const promise = this.onOk(likeSubjectIds)
                    if (promise instanceof Promise) {
                        this.confirmLoading = true
                        promise.then(() => handleOk()).finally(() => this.confirmLoading = false)
                    } else {
                        handleOk()
                    }
                } else {
                    handleOk()
                }
            } else {
                this.$notify({type: 'warning', title: '提示', message: '请选择课程'})
            }
        },
        //  兴趣课类标签关闭事件 --效果待定
        handleClose (item) {
            let index = this.checkItems.findIndex(v => v.id === item.id)
            let arr = this.checkItems.splice(index, 1)
            for (let i = 0; i < arr.length; i++) {
                this.$refs.tree.setChecked(arr[i].id, false)
            }
        },
        formatTree (list) {
            const result = []
            const childrenMap = {}
            for (let item of list) {
                if (item.parentId === 0) {
                    result.push(item)
                } else {
                    let children = childrenMap[item.parentId]
                    if (!children) {
                        childrenMap[item.parentId] = children = []
                    }
                    delete item.children
                    children.push(item)
                }
            }
            for (let item of result) {
                if (childrenMap[item.id]) {
                    item.children = childrenMap[item.id]
                }
            }
            return result
        },
        syncProps () {
            if (this.value.length) {
                for (let classTypeId of this.value) {
                    for (let item of this.simpleData) {
                        if (item.id === classTypeId) {
                            this.$refs.tree.setChecked(item.id, true)
                            break
                        }
                    }
                }
            }
        },
        renderContent (createElement, {data}) {
            return createElement('div', {domProps: {innerHTML: data.name}})
        }
    },
    created () {
        this.loading = true
        getClassTypeListApi({timestamp: new Date().getTime(), formatTree: false}).then(res => {
                if (Array.isArray(res.data.data) && res.data.data.length) {
                    this.simpleData = res.data.data
                    this.data = this.formatTree(res.data.data)
                }
            }).finally(() => this.loading = false)
    },
    watch: {
        interestVisible (newVal) {
            if (newVal && !this.loading) {
                this.$nextTick(() => {
                    this.syncProps()
                })
            }
        }
    },
    computed: {
        computedData () {
            if (this.filterText.trim()) {
                return this.formatTree(this.simpleData.filter(item => item.name.indexOf(this.filterText) !== -1)
                    .map(item => {
                        let split = (' ' + item.name + ' ').split(this.filterText)
                        return {
                            ...item,
                            name: split.join(`<span style=\'color: #ff5500;\'>${this.filterText}</span>`).trim()
                        }
                    })
                )
            }
            return this.data
        },
        dataMap () {
            if (this.simpleData.length) {
                const result = {}
                this.simpleData.forEach(item => {
                    result[item.id] = item
                })
                return result
            }
            return {}
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/variables";

.check {
    display: flex;
    // border: 1px solid #f00;
    align-items: center;
    flex-wrap: wrap;

    span {
        margin-left: 10px;
    }

    p {
        font-size: 14px;
    }
}

.el-icon-search {
    margin-top: 5px;
    cursor: pointer;
    font-size: 16px;
}

.el-icon-search:hover {
    color: $--color-primary;
}
</style>
