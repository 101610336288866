<template>
    <div>
        <el-dialog
            title=""
            :visible.sync="visible"
            @update:visible="val=>$emit('update:visible',val)"
            width="1000px"
            custom-class="classOperationRecord-dialog"
            append-to-body
            top="5vh"
            :show-close="false">
            <img src="../../assets/img/cha3.png" alt="" @click="$emit('update:visible',false)" class="cha">
            <div class="top">
                <article>
                    <div>
                        <span>操作类型:</span>
                        <el-select size="mini" style="width:100%;" @change="getDataList" v-model="type" placeholder="">
                            <el-option
                                v-for="item in modelOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.key">
                            </el-option>
                        </el-select>
                    </div>
                    <div>
                        <span>操作人:</span>
                        <tenant-user-select
                            has-all-option
                            v-model="tenantUserId"
                            :multiple="false"
                            size="mini"
                            style="width: 100%"
                            showclear
                            @change="getDataList">
                        </tenant-user-select>
                    </div>
                    <div>
                        <span>查询时段</span>
                        <el-date-picker
                            v-model="time"
                            type="daterange"
                            range-separator="~"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            size="mini"
                            @change="changeTime"
                            style="width: 100%;">
                        </el-date-picker>
                    </div>
                </article>
            </div>
            <div class="content" ref="down" infinite-scroll-distance="100" style="overflow:auto">
                <div class="item" v-for="item in data" :key="item.id">
                    <div class="item_top">
                        <span>{{ showType(item.specificOperationType) }}</span>
                        <div>
                            <span>操作人: <em>{{ item.createUserName }}</em></span>
                            <span>操作时间: {{ item.createDate }}</span>
                        </div>
                    </div>
                    <div class="item_down">
                        <p v-html="item._content"></p>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {getClassOperationRecordListApi} from "../../api/class/classApi";
import dayjs from "dayjs";
import {formatDefaultTemplate} from "../../utils/StringUtils";
import operationRecord from "../Class/detail/StudentClassOptionRecordAndMessageModal.vue";
import TeacherSelect from "../Form/TeacherSelect.vue";
import TenantUserSelect from "../Form/TenantUserSelect.vue";

export default {
    name: 'BalletVueClassOperationRecord',
    components: {TenantUserSelect, TeacherSelect},
    props: ['visible', 'classId'],
    emits: ['update:visible'],
    data() {
        return {
            //操作类型绑定数据
            type: null,
            //操作类型下拉框数据
            modelOptions: [
                {
                    id: 1,
                    name: '全部',
                    key: null
                },
                {
                    id: 2,
                    name: '添加学员',
                    key: 'AddStudents'
                },
                {
                    id: 3,
                    name: '调整课程',
                    key: 'AdjustingCourses'
                },
                {
                    id: 4,
                    name: '变更课位',
                    key: 'ChangeCourseLocation'
                },
                {
                    id: 5,
                    name: '编辑课程',
                    key: 'EditCourse'
                },
                {
                    id: 6,
                    name: '其他',
                    key: 'Other'
                },
            ],
            //操作人绑定数据
            tenantUserId: undefined,
            //操作人下拉框数据
            operatorOptions: [],
            //查询时段
            time: '',
            //模拟数据
            data: [],
            currentPage: 1,
            pageSize: 10,
            dataList: [],
            totalCount: 0,
            totalPage: 0,
            down: null,
        };
    },

    mounted() {
        this.getDataList();
    },

    methods: {
        getMax(arr) {
            //获取arr的最大值 并向上取整10
            return Math.ceil(Math.max.apply(null, arr) / 10) * 10;
        },
        getDataList() {
            if (this.total >= this.data.length) {
                return;
            }
            getClassOperationRecordListApi({
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                classId: this.classId,
                startTime: this.time instanceof Array ? this.time[0] : '',
                endTime: this.time instanceof Array ? this.time[1] : '',
                tenantUserId: this.tenantUserId,
                type: this.type,
                timestamp: new Date().getTime(),
            }).then(res => {
                this.total = res.data.data.total;
                res.data.data.records.forEach(a => {
                    a._content = operationRecord.content = formatDefaultTemplate(a.content, (val) => {
                        return `<span class="primary-text-color">${val}</span>`
                    }, a.bindValues)
                })
                this.data = res.data.data.records;
                this.down = this.$refs.down;
                this.down.onscroll = this.load;
            })
        },
        showType(str) {
            let data = [
                {
                    id: 2,
                    name: '添加学员',
                    key: 'AddStudents'
                },
                {
                    id: 3,
                    name: '调整课程',
                    key: 'AdjustingCourses'
                },
                {
                    id: 4,
                    name: '变更课位',
                    key: 'ChangeCourseLocation'
                },
                {
                    id: 5,
                    name: '编辑课程',
                    key: 'EditCourse'
                },
                {
                    id: 6,
                    name: '其他',
                    key: 'Other'
                },

            ];
            return data.find(item => item.key === str) ? data.find(item => item.key === str).name : '系统操作';
        },
        changeTime(data) {
            if (!data) {
                this.time = '';
                this.getDataList();
                return;
            }
            this.time[0] = dayjs(data[0]).format('YYYY-MM-DD 00:00:00.000');
            this.time[1] = dayjs(data[1]).format('YYYY-MM-DD 23:59:59.999');
            this.getDataList();
        },
        load(e) {
            //计算是否滚动到底部
            if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
                //滚动到底部 加载更多
                this.currentPage++;
                getClassOperationRecordListApi({
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    classId: this.classId,
                    startTime: this.time[0],
                    endTime: this.time[1],
                    operatorId: this.tenantUserId,
                    type: this.type,
                    timestamp: new Date().getTime(),
                }).then(res => {
                    res.data.data.records.forEach(a => {
                        a._content = operationRecord.content = formatDefaultTemplate(a.content, (val) => {
                            return `<span class="primary-text-color">${val}</span>`
                        })
                    })
                    this.data = this.data.concat(res.data.data.records);
                })
            }
        },

    },
};
</script>

<style lang="scss" scoped>
.cha {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

em {
    font-style: normal;
    color: #29cb97;
}

.top {
    padding: 20px;
    box-sizing: border-box;
    background: #fff;

    article {
        width: 555px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
}

.content {
    //min-height: 200px;
    height: 600px;
    background: #f5f7fa;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
}

.item {
    margin-bottom: 20px;
    overflow: auto;

    .item_top, .item_down {
        padding: 0 20px;
        box-sizing: border-box;
    }

    .item_top {
        background: #edf0f2;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > span {
            font-weight: 600;
        }

        div {
            span + span {
                margin-left: 10px;
            }
        }
    }

    .item_down {
        background: #fff;
        height: 40px;

        p {
            margin: 0;
            line-height: 40px;
        }
    }
}
</style>
<style lang="scss">
.classOperationRecord-dialog {
    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        padding: 0px;
        box-sizing: border-box;

    }

    .el-dialog__footer {
        padding: 0;
        border-top: none;
        display: none;
    }
}
</style>
