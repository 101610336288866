<template>
    <el-dialog title="修改课程"
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               append-to-body
               width="500px"
               custom-modal>
        <el-form ref="formRef"
                 :rules="formRules"
                 :model="formData"
                 label-position="top"
                 size="mini">
            <div class="form-multiple-item-line">
                <el-form-item label="课程标题" prop="name">
                    <el-input v-model="formData.name" :maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="课类" prop="classTypeIds">
                    <class-type-cascader v-model="formData.classTypeIds"></class-type-cascader>
                </el-form-item>
            </div>
            <div class="form-multiple-item-line">
                <el-form-item label="是否试听" prop="isAudition">
                    <el-select v-model="formData.isAudition">
                        <el-option label="是" :value="1"></el-option>
                        <el-option label="否" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="最大试听人数"
                              v-if="formData.isAudition===1"
                              prop="maxAuditionCount">
                    <el-input-number v-model="formData.maxAuditionCount"
                                     placeholder="不填代表无限制"
                                     left
                                     controls-position="right"
                                     :min="0"
                                     :precision="0">
                    </el-input-number>
                </el-form-item>
                <el-form-item v-else></el-form-item>
            </div>
        </el-form>
        <div style="text-align: right;">
            <el-button type="text" style="padding: 0;color: #f5222d;font-size: 12px;" @click="handleDelete">删除本课程</el-button>
        </div>
        <template v-slot:footer>
            <el-button size="mini" @click="$emit('update:visible',false)">取消</el-button>
            <el-button size="mini"
                       type="primary"
                       @click="handleConfirm"
                       :loading="confirmLoading">
                确定
            </el-button>
        </template>
    </el-dialog>
</template>
<script>
import {defineComponent, getCurrentInstance, inject, ref, toRefs, watch} from "vue";
import ClassTypeCascader from "@/components/Form/ClassTypeCascader.vue";
import {deleteClassParentApi, updateTotalClassApi} from "@/api/class/classApi";

export default defineComponent({
    components: {ClassTypeCascader},
    props: {
        visible: Boolean,
        data: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    emits: ['update:visible', 'ok', 'delete'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const formRef = ref();

        const formData = ref({
            name: undefined,
            classTypeIds: [],
            isAudition: undefined,
            maxAuditionCount: undefined
        });

        const formRules = ref({
            name: [{required: true, message: '课程名称是必填的'}],
            classTypeIds: [{required: true, message: '未选择课类'}],
            isAudition: [{required: true, message: '未选择是否试听'}],
        });

        const confirmLoading = ref(false);

        const handleConfirm = () => {
            confirmLoading.value = true;
            formRef.value.validate().then(() => updateTotalClassApi({
                classFatherId: props.data.id,
                name: formData.value.name,
                classTypeId: formData.value.classTypeIds[0],
                childrenClassTypeId: formData.value.classTypeIds[0],
                isAudition: formData.value.isAudition,
                maxAuditionCount: formData.value.maxAuditionCount
            })).then(() => {
                emit('update:visible', false);
                emit('ok');
                refreshClass();
            }).finally(() => confirmLoading.value = false);
        };

        const handleDelete = () => {
            currentInstance.proxy.$confirm('您确认删除此课程吗？', {
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        deleteClassParentApi(props.data.id).then(() => {
                            emit('delete');
                            emit('update:visible', false);
                            done();
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            });
        };

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value = {
                    name: props.data.className,
                    classTypeIds: [props.data.classTypeId, props.data.childrenClassTypeId],
                    isAudition: props.data.isAudition,
                    maxAuditionCount: props.data.maxAuditionCount
                };
                setTimeout(() => {
                    formRef.value.clearValidate();
                });
            }
        });

        const reloadClass = inject('reloadClass');

        return {
            ...toRefs(props),
            formRef,
            formData,
            formRules,
            confirmLoading,
            handleConfirm,
            handleDelete
        };
    }

});
</script>
<style scoped>
.form-multiple-item-line {
    gap: 20px;
    flex: 1 1;
}
</style>