<template>
<div>
    <el-dialog
        title="转出合同"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        width="650px"
        append-to-body
        custom-modal>
        <template v-slot:title>
            <div class="header">
                <div :class="transferType==='Contract'?'on':''" @click="transferType='Contract'">
                    转出合同
                </div>
                <div :class="transferType==='ClassCount'?'on':''" @click="transferType='ClassCount'">
                    转出课时
                </div>
            </div>
        </template>
        <div class="content">
            <el-form v-if="transferType==='Contract'"
                     ref="form1Ref"
                     size="mini"
                     label-position="top"
                     :model="formData1"
                     :rules="formRules1"
                     label-width="80px">
                <el-form-item>
                    <el-radio-group v-model="formData1.transferAll">
                        <el-radio :label="true">全部转出</el-radio>
                        <el-radio :label="false">部分转出</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="选择中心" prop="storeId">
                    <store-select v-model="formData1.storeId" style="width:100%;"
                                  placeholder="请选择中心"></store-select>
                </el-form-item>
                <el-form-item label="" prop="ordinary">
                    <div class="class_flex">
                        <div>
                            <span>已排普通课时</span><br/>
                            <span>{{ data.planedOrdinaryClassCount }}课时</span>
                        </div>
                        <div>
                            <span>已排特殊课时</span><br/>
                            <span>{{ data.planedSpecialClassCount }}课时</span>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="" prop="amount">
                    <div class="label_font">
                        <span class="req">转出金额</span>
                        <p>(当前剩余金额<em>{{
                                currency(data.remainingAmount, {separator: ',', symbol: ''}).format()
                            }}</em>, 转出后剩余<em>{{
                                currency(data.remainingAmountAfterTransfer, {
                                    separator: ',',
                                    symbol: ''
                                }).format()
                            }}</em>)</p>
                    </div>
                    <el-input-number controls-position="right"
                                     left
                                     :min="0.01"
                                     style="width: 100%"
                                     v-model="formData1.amount"
                                     :disabled="formData1.transferAll">
                    </el-input-number>
                </el-form-item>
                <template v-if="!formData1.transferAll">
                    <el-form-item label="减少普通课时数" prop="ordinaryClassCount" v-if="contract.packageType===1">
                        <span slot="label">减少普通课时数（已排普通课时<em>{{
                                data.planedOrdinaryClassCount
                            }}</em>, 剩余普通课时<em>{{
                                data.remainingOrdinaryClassCount
                            }}</em>, 转出后剩余<em>{{ data.remainingOrdinaryClassCountAfterTransfer }}</em>）
                        </span>
                        <el-input-number controls-position="right"
                                         left
                                         :min="0"
                                         :max="data.remainingOrdinaryClassCount"
                                         v-model="formData1.ordinaryClassCount"
                                         style="width: 100%">
                        </el-input-number>
                    </el-form-item>
                    <el-form-item label="减少特殊课时数" prop="specialClassCount">
                        <span slot="label">减少特殊课时数（已排特殊课时<em>{{
                                data.planedSpecialClassCount
                            }}</em>, 剩余特殊课时<em>{{
                                data.remainingSpecialClassCount
                            }}</em>, 转出后剩余<em>{{ data.remainingSpecialClassCountAfterTransfer }}</em>）
                        </span>
                        <el-input-number controls-position="right"
                                         left
                                         style="width: 100%"
                                         :max="data.remainingSpecialClassCount"
                                         :min="0"
                                         v-model="formData1.specialClassCount">
                        </el-input-number>
                    </el-form-item>
                </template>
                <el-form-item label="支付方式" prop="payMethod">
                    <el-select v-model="formData1.payMethod" style="width:100%;" placeholder="请选择支付方式">
                        <el-option v-for="payMethod in $store.getters['common/payMethods']"
                                   v-bind="payMethod"
                                   :key="payMethod.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="原由" prop="reason">
                    <el-input v-model="formData1.reason" show-word-limit :maxlength="250"></el-input>
                </el-form-item>
            </el-form>
            <el-form v-else-if="transferType==='ClassCount'"
                     ref="form2Ref"
                     size="mini"
                     label-position="top"
                     :model="formData2"
                     :rules="formRules2"
                     label-width="80px">
                <el-form-item>
                    <el-radio-group v-model="formData2.transferAll">
                        <el-radio :label="true">全部转出</el-radio>
                        <el-radio :label="false">部分转出</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="" prop="ordinary">
                    <div class="class_flex">
                        <div>
                            <span>已排普通课时</span><br/>
                            <span>{{ data.planedOrdinaryClassCount }}课时</span>
                        </div>
                        <div>
                            <span>已排特殊课时</span><br/>
                            <span>{{ data.planedSpecialClassCount }}课时</span>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="减少普通课时数" prop="ordinaryClassCount" v-if="contract.packageType===1">
                        <span slot="label">减少普通课时数（已排普通课时<em>{{
                                data.planedOrdinaryClassCount
                            }}</em>, 剩余普通课时<em>{{
                                data.remainingOrdinaryClassCount
                            }}</em>, 转出后剩余<em>{{ data.remainingOrdinaryClassCountAfterTransfer }}</em>）
                        </span>
                    <el-input-number controls-position="right"
                                     left
                                     :max="data.remainingOrdinaryClassCount"
                                     :min="0"
                                     :disabled="formData2.transferAll"
                                     v-model="formData2.ordinaryClassCount"
                                     style="width: 100%">
                    </el-input-number>
                </el-form-item>
                <el-form-item label="减少特殊课时数" prop="specialClassCount">
                        <span slot="label">减少特殊课时数（已排特殊课时<em>{{
                                data.planedSpecialClassCount
                            }}</em>, 剩余特殊课时<em>{{
                                data.remainingSpecialClassCount
                            }}</em>, 转出后剩余<em>{{ data.remainingSpecialClassCountAfterTransfer }}</em>）
                        </span>
                    <el-input-number controls-position="right"
                                     left
                                     :disabled="formData2.transferAll"
                                     style="width: 100%"
                                     :max="data.remainingSpecialClassCount"
                                     :min="0"
                                     v-model="formData2.specialClassCount">
                    </el-input-number>
                </el-form-item>
                <el-form-item label="" prop="amount">
                    <div class="label_font">
                        <span class="req">转出金额</span>
                        <p>(当前剩余金额<em>{{
                                currency(data.remainingAmount, {separator: ',', symbol: ''}).format()
                            }}</em>, 转出后剩余<em>
                            {{
                                currency(data.remainingAmountAfterTransfer, {
                                    separator: ',',
                                    symbol: ''
                                }).format()
                            }}
                        </em>)</p>
                    </div>
                    <el-input-number controls-position="right"
                                     left
                                     :max="data.remainingAmount"
                                     :min="0"
                                     :step="contract.packageType===1?currency(data.remainingAmount).divide(data.remainingOrdinaryClassCount).value:1"
                                     style="width: 100%"
                                     v-model="formData2.amount"
                                     :disabled="formData2.transferAll">
                    </el-input-number>
                </el-form-item>
                <el-form-item label="支付方式" prop="payMethod">
                    <el-select v-model="formData2.payMethod" style="width:100%;" placeholder="请选择支付方式">
                        <el-option v-for="payMethod in $store.getters['common/payMethods']"
                                   v-bind="payMethod"
                                   :key="payMethod.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="button-form-items-wrapper">
                    <el-form-item label="选择中心" prop="storeId">
                        <store-select v-model="formData2.storeId" style="width:100%;"
                                      placeholder="请选择中心"></store-select>
                    </el-form-item>
                    <div class="_50">
                        <el-form-item label="接收人姓名" prop="receiverName">
                            <el-input size="mini" v-model="formData2.receiverName"></el-input>
                        </el-form-item>
                        <el-form-item label="手机" prop="receiverPhoneNumber">
                            <div class="flex_cont">
                                <el-select v-model="formData2.receiverPhoneNumberArea"
                                           style="width:100px;margin-right:5px;"
                                           placeholder="">
                                    <el-option v-for="item in $store.getters['common/phoneNumberAreas']"
                                               :key="item.value"
                                               v-bind="item">
                                    </el-option>
                                </el-select>
                                <el-input size="mini" v-model="formData2.receiverPhoneNumber">
                                    <template v-slot:prepend>+{{ formData2.receiverPhoneNumberArea }}</template>
                                </el-input>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <el-form-item label="原由" prop="reason">
                    <el-input type="textarea" v-model="formData2.reason" show-word-limit :maxlength="250"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer">
            <el-button size="mini" @click="$emit('update:visible',false)">取 消</el-button>
            <el-button size="mini" type="primary" @click="handleConfirm" :loading="confirmLoading">确 定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import StoreSelect from '../../Form/StoreSelect.vue'
import {defineComponent, toRefs, ref, computed, inject, watch, getCurrentInstance} from 'vue'
import {transferApi} from '../../../api/student/contractApi'
import {copyPropertyTo} from '../../../utils/ObjectUtils'
import currency from 'currency.js'
import {regexp} from '../../../utils/const'

export default defineComponent({
    components: {StoreSelect},
    props: ['visible'],
    emits: ['update:visible'],
    setup (props, {emit}) {

        const currentInstance = getCurrentInstance()

        const transferType = ref()

        const formData1 = ref({
            transferAll: true,
            storeId: undefined,
            amount: undefined,
            payMethod: undefined,
            reason: undefined,
            ordinaryClassCount: undefined,
            specialClassCount: undefined,
        })

        const formData2 = ref({
            transferAll: true,
            storeId: undefined,
            amount: undefined,
            payMethod: undefined,
            reason: undefined,
            ordinaryClassCount: undefined,
            specialClassCount: undefined,
            receiverName: undefined,
            receiverPhoneNumberArea: undefined,
            receiverPhoneNumber: undefined
        })

        const formRules1 = ref({
            storeId: [{required: true, message: '请选择中心'}],
            amount: [{required: true, message: '转出金额是必填的'}],
            payMethod: [{required: true, message: '请选择支付方式'}],
            reason: [{required: false, message: '原由是必填的'}],
            ordinaryClassCount: [{required: true, message: '减少普通课时数是必填的'}],
            specialClassCount: [{required: true, message: '减少特殊课时数是必填的'}],
        })

        const formRules2 = ref({
            storeId: [{required: true, message: '请选择中心'}],
            amount: [{required: true, message: '转出金额是必填的'}],
            payMethod: [{required: true, message: '请选择支付方式'}],
            reason: [{required: false, message: '原由是必填的'}],
            ordinaryClassCount: [{required: true, message: '减少普通课时数是必填的'}],
            specialClassCount: [{required: true, message: '减少特殊课时数是必填的'}],
            receiverName: [{required: true, message: '接收者姓名是必填的'}],
            receiverPhoneNumberArea: [{required: true, message: '接收者手机号区号是必填的'}],
            receiverPhoneNumber: [
                {required: true, message: '接收者手机号是必填的'},
                {pattern: regexp.phoneNumber, message: '错误的手机号格式'}
            ]
        })

        const form1Ref = ref()

        const form2Ref = ref()

        const confirmLoading = ref(false)

        const handleConfirm = () => {
            confirmLoading.value = true
            const data = {
                transferType: transferType.value,
                contractId: contract.value.id
            }
            let ref
            if (transferType.value === 'Contract') {
                copyPropertyTo(data, formData1.value)
                ref = form1Ref.value
            } else if (transferType.value === 'ClassCount') {
                copyPropertyTo(data, formData2.value)
                ref = form2Ref.value
            }
            ref && ref.validate().then(() => transferApi(data)).then(() => {
                refreshWater()
                refreshContract()
                emit('update:visible', false)
            }).finally(() => confirmLoading.value = false)
        }

        const getData = inject('getData')

        const refreshWater = inject('refreshWater')

        const refreshContract = inject('refreshContract')

        const contract = computed(() => {
            return getData()
        })

        const data = computed(() => {
            const result = {
                remainingAmount: contract.value.remainingAmount,
                remainingAmountAfterTransfer: contract.value.remainingAmount,
                remainingOrdinaryClassCount: contract.value.remainingOrdinaryClassCount,
                remainingSpecialClassCount: contract.value.remainingSpecialClassCount,
                remainingOrdinaryClassCountAfterTransfer: contract.value.remainingOrdinaryClassCount,
                remainingSpecialClassCountAfterTransfer: contract.value.remainingSpecialClassCount,
                planedOrdinaryClassCount: contract.value.planedOrdinaryClassConsumeCount,
                planedSpecialClassCount: contract.value.planedSpecialClassConsumeCount,
            }
            if (transferType.value === 'Contract') {
                result.remainingAmountAfterTransfer = currency(result.remainingAmount).subtract(formData1.value.amount).value
                result.remainingOrdinaryClassCountAfterTransfer = currency(result.remainingOrdinaryClassCount).subtract(formData1.value.ordinaryClassCount).value
                result.remainingSpecialClassCountAfterTransfer = currency(result.remainingSpecialClassCount).subtract(formData1.value.specialClassCount).value
            } else if (transferType.value === 'ClassCount') {
                result.remainingAmountAfterTransfer = currency(result.remainingAmount).subtract(formData2.value.amount).value
                result.remainingOrdinaryClassCountAfterTransfer = currency(result.remainingOrdinaryClassCount).subtract(formData2.value.ordinaryClassCount).value
                result.remainingSpecialClassCountAfterTransfer = currency(result.remainingSpecialClassCount).subtract(formData2.value.specialClassCount).value
            }
            return result
        })

        watch(() => props.visible, newVal => {
            if (newVal) {
                transferType.value = 'Contract'
            } else {
                transferType.value = undefined
            }
        })

        watch(transferType, newVal => {
            if (newVal === 'Contract') {
                formData1.value.amount = contract.value.remainingAmount
                formData1.value.ordinaryClassCount = contract.value.ordinaryClassCount + contract.value.giveOrdinaryClassCount
                formData1.value.specialClassCount = contract.value.specialClassCount
                setTimeout(() => form1Ref.value.clearValidate())
            } else if (newVal === 'ClassCount') {
                formData2.value.amount = contract.value.remainingAmount
                formData2.value.ordinaryClassCount = contract.value.ordinaryClassCount + contract.value.giveOrdinaryClassCount
                formData2.value.specialClassCount = contract.value.specialClassCount
                formData2.value.receiverPhoneNumberArea = currentInstance.proxy.$store.getters['common/defaultPhoneNumberArea']
                setTimeout(() => form2Ref.value.clearValidate())
            }
        })

        let ordinaryClassCountChange = false

        watch(() => formData2.value.ordinaryClassCount, newVal => {
            if (contract.value.packageType === 1) {
                if (ordinaryClassCountChange) {
                    ordinaryClassCountChange = false
                } else {
                    ordinaryClassCountChange = true
                    formData2.value.amount = currency(contract.value.remainingAmount).divide(contract.value.remainingOrdinaryClassCount).multiply(newVal).value
                }
            }
        })

        watch(() => formData2.value.amount, newVal => {
            if (contract.value.packageType === 1) {
                if (ordinaryClassCountChange) {
                    ordinaryClassCountChange = false
                } else {
                    ordinaryClassCountChange = true
                    formData2.value.ordinaryClassCount = currency(newVal).divide(currency(contract.value.remainingAmount).divide(contract.value.remainingOrdinaryClassCount)).value
                }
            }
        })

        return {
            ...toRefs(props),
            transferType,
            formData1,
            formData2,
            data,
            formRules1,
            formRules2,
            form1Ref,
            form2Ref,
            confirmLoading,
            contract,
            handleConfirm,
            currency
        }
    }
})
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: -15px;

    div {
        display: flex;
        align-items: center;
        border-bottom: 2px solid transparent;
        height: 100%;
        font-size: 16px;
        line-height: 45px;
        cursor: pointer;
        padding: 0 10px;

        &.on {
            border-bottom: 2px solid #29cb97;
        }
    }

    > img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.content {

    .class_flex {
        display: flex;
        align-items: center;

        > div {
            flex: 1;
        }
    }

    .label_font {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > span {
            text-align: left;
            margin-right: 10px;
        }

        > p {
            flex: 1;
            margin: 0;
            text-align: right;
        }

    }
}

.req {
    &::before {
        content: '*';
        color: #f00;
        margin-right: 5px;
    }
}

em {
    color: #29cb97;
    font-style: normal;
}

.button-form-items-wrapper {
    border-top: 1px solid #edf0f2;
    margin-top: 30px;
    padding-top: 20px;
}

._50 {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.flex_cont {
    display: flex;
    align-items: center;
}
</style>
<style scoped>
>>> .el-form-item--mini .el-form-item__label {
    margin-bottom: 0;
    line-height: 12px;
}
</style>
