<template>
    <div>
        <el-dialog :visible="visible"
                   @update:visible="val=>$emit('update:visible',val)"
                   :show-close="false"
                   width="1100px"
                   center
                   append-to-body
                   custom-class="detail_model_dialog student-detail">
            <!-- <article style="width:100%;height:100%;background:rgba(0,0,0,.3);position:absolute;left:0;top:0;z-index:10;" v-if="updateVipStudentModalVisible"></article> -->
            <div class="detail-main"
                 v-loading="loading"
                 v-infinite="loadMore">
                <div class="detail_top">
                    <div class="left">
                        <div class="info">
                            <div class="student-avatar" @click="uploadAvatarUrlBoolean = true">
                                <student-avatar
                                    style="width: 100%;height: 100%;background: none;border-radius: 0;margin: 0;"
                                    :gender="student.gender"
                                    :src="student.avatar">
                                </student-avatar>
                            </div>
                            <div class="detail_top_left">
                                <div class="people_info">
                                    <p>{{ student.name }}</p>
                                    <gender-view style="margin-left: 10px" :gender="student.gender"></gender-view>
                                    <img src="../../assets/img/wx.png" alt=""/>
                                    <span>{{ getBirthdayDisplay(student.birthdayTime) }}</span>
                                    <el-button @click="updateVipStudentModalVisible=true"
                                               type="text"
                                               style="padding: 0;margin: 0 0 0 20px;font-size: 20px;">
                                        <i class="el-icon-edit-outline"></i>
                                    </el-button>
                                </div>
                                <p class="addCard" @click="addCard">[添加实体卡]</p>
                                <div class="guwen">
                                    <div>
                                        <span>顾&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;问:</span>
                                        <p>&nbsp;{{ student.consultantName }}&nbsp;</p>
                                        <consultant-select :on-ok="handleConsultantSelected">
                                            <el-button type="text" style="padding: 0;margin: 0;">[更换]</el-button>
                                        </consultant-select>
                                    </div>
                                    <span>&nbsp;{{ $store.getters['common/sourceTypeMap'][student.sourceType] }}</span>
                                </div>
                                <div class="shichang">
                                    <span>市&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;场:</span>
                                    <p>&nbsp;{{ student.marketName }}&nbsp;</p>
                                    <market-select :on-ok="handleMarketSelected">
                                        <el-button type="text" style="padding: 0;margin: 0;">[更换]</el-button>
                                    </market-select>
                                </div>
                                <div class="kefu">
                                    <span>专属客服:</span>
                                    <p>{{ student.exclusiveCustomerServiceName }}</p>
                                </div>
                                <div class="intro">
                                    <span>转&nbsp;&nbsp;介&nbsp;&nbsp;绍:</span>
                                    <p><i>0</i>人</p>
                                </div>
                                <div class="link">
                                    <span style="white-space: nowrap">主联系人:</span>
                                    <p>
                                        <span>(+{{ student.mainContactPhoneNumberArea }})</span>
                                        <span>{{ student.mainContactPhoneNumber }}</span>
                                        <span>({{
                                                ($store.getters['common/relationMap'][student.mainContactAffiliation])
                                            }})</span>
                                        <span> {{ student.mainContactName }}</span>
                                    </p>
                                    <p>
                                        微信昵称:
                                        {{
                                            student.mainContactWechatName
                                                ? student.mainContactWechatName
                                                : '无'
                                        }}
                                    </p>
                                    <img src="../../assets/img/wx.png" alt=""/>
                                </div>
                                <span class="lookOther" @click="vipOtherInfoVisible = true">[查看其他信息]</span>
                            </div>
                        </div>
                        <div class="family" v-if="Array.isArray(student.family)&&student.family.length">
                            <div class="label">
                                <div>家庭</div>
                                <div>成员</div>
                            </div>
                            <el-tooltip v-for="f in student.family"
                                        :key="f.id"
                                        placement="top"
                                        :content="f.name">
                                <div class="family-member" @click="refreshData(f.id)">
                                    <student-avatar class="family-member-avatar"
                                                    :src="f.avatar"
                                                    :gender="f.sex">
                                    </student-avatar>
                                    <div>{{ f.name }}</div>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="detail_top_right">
                        <div class="btnBox">
                            <template v-if="student.status==='Normal'">
                                <div @click="appointVisible=true"><span>约课</span></div>
                                <div @click="arrangeVisible=true"><span>排课</span></div>
                                <div @click="vacateBoolean=true"><span>请假</span></div>
                                <div @click="sendEvaluateBoolean=true"><span>评价</span></div>
                                <div @click="renew"><span>续签</span></div>
                            </template>
                            <template v-else-if="student.status==='PendingReview'">
                                <el-button type="primary" size="mini"
                                           @click="approvalPassedAddFamilyMemberModalVisible=true">
                                    同意
                                </el-button>
                                <el-button type="danger" size="mini" @click="handleReject">不同意</el-button>
                            </template>
                        </div>
                        <div class="vip_tabs">
                            <span>会员标签:</span>
                            <ul v-if="Array.isArray(student.tags)&&student.tags.length" class="tag-list-wrapper">
                                <li v-for="item in student.tags"
                                    :key="item.id"
                                    class="tag-item"
                                    :style="`background: ${item.color}24;color: ${item.color};`">
                                    # {{ item.name }}
                                </li>
                                <li class="tag-item" style="background: transparent">
                                    <p class="update-tag-button"
                                       @click="vipTagsManager=true">编辑</p>
                                </li>
                            </ul>
                            <div v-else>
                                <p class="update-tag-button"
                                   @click="vipTagsManager=true">编辑</p>
                            </div>
                        </div>
                        <div class="vip_tabs">
                            <span>兴趣课类:</span>
                            <div v-if="Array.isArray(student.likeSubjects)&&student.likeSubjects.length"
                                 class="vip_tabs_item">
                                <span class="label"
                                      v-for="item in student.likeSubjects"
                                      :key="item.id">
                                    {{ item.name }}
                                </span>
                                <update-student-like-subject-modal :value="student.likeSubjects.map(item=>item.id)"
                                                                   :on-ok="handleUpdateStudentLikeSubject"
                                                                   @change="val=>student.likeSubjects=val">
                                    <p class="update-tag-button">
                                        编辑
                                    </p>
                                </update-student-like-subject-modal>
                            </div>
                            <div class="vip_tabs_item" v-else>
                                <update-student-like-subject-modal @change="val=>student.likeSubjects=val"
                                                                   :on-ok="handleUpdateStudentLikeSubject">
                                    <p class="update-tag-button">
                                        编辑
                                    </p>
                                </update-student-like-subject-modal>
                            </div>
                        </div>
                        <div class="vip_status">
                            <span>会员状态:</span>
                            <p>{{ status[student.status] }}</p>
                        </div>
                        <div class="nowWeek">
                            <span>本周排课:</span>
                            <div>
                                <div>
                                    <p>总课时</p>
                                    <p>
                                        {{
                                            student.weekConsumeOrdinaryClassCount ? student.weekConsumeOrdinaryClassCount : 0
                                        }} 课时
                                    </p>
                                </div>
                                <div>
                                    <p>特殊课时</p>
                                    <p>
                                        {{
                                            student.weekConsumeSpecialClassCount ? student.weekConsumeSpecialClassCount : 0
                                        }} 课时
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail_down">
                    <div class="detail_down_top"
                         ref="header">
                        <div v-for="selectTab in selectTabs"
                             :class="activeBar&&activeBar.code===selectTab.code ? 'on' : ''"
                             @click="activeBar=selectTab">
                            <el-tooltip effect="dark" :content="selectTab.name" placement="top">
                                <p>{{ selectTab.name }}</p>
                            </el-tooltip>
                        </div>
                    </div>
                    <div style="min-height: 400px;" v-if="activeBar">
                        <component :is="activeBar.component"
                                   ref="activeRef"
                                   v-bind="activeBar.getBindValue()"
                                   v-on="activeBar.on"
                                   @updated="updated++;">
                        </component>
                    </div>
                </div>
            </div>
        </el-dialog>
        <template>
            <update-student-tag-modal :visible.sync="vipTagsManager"
                                      :student-id="student.id"
                                      :tags="student.tags"
                                      @updated="refreshData(student.id)">
            </update-student-tag-modal>
            <!-- 编辑头像 -->
            <AvatarVue
                :visible="uploadAvatarUrlBoolean"
                :closeAvatarUrlModal="closeAvatarUrlModal"
                @update:visible="uploadAvatarUrlBoolean = $event">
            </AvatarVue>
            <!-- 编辑会员 -->
            <update-vip-student-modal
                :visible.sync="updateVipStudentModalVisible"
                @delete="handleDelete"
                @updated="refreshData(student.id)"
                :data="student">
            </update-vip-student-modal>
            <!-- 添加实体卡 -->
            <AddCardVue :visible="cardModalVisible" :closeCard="closeCard"></AddCardVue>
            <!-- 查看其他信息 -->
            <VipOtherInfoVue :visible.sync="vipOtherInfoVisible"
                             :data="student">
            </VipOtherInfoVue>
            <!-- 约课、排课 -->
            <appoint-class
                :visible.sync="appointVisible"
                :studentId="student.id"
                :defaultContractId="student.defaultContractId">
            </appoint-class>
            <!-- 排课-->
            <arrange-class
                :visible.sync="arrangeVisible"
                :studentId="student.id"
                :defaultContractId="student.defaultContractId">
            </arrange-class>
            <!-- 请假 -->
            <VacateVue
                v-if="vacateBoolean"
                :visible.sync="vacateBoolean"
                :closeVacateModal="closeVacateModal"
                :studentId="student.id">
            </VacateVue>
            <!-- 发送评价 -->
            <push-motion-modal
                :visible.sync="sendEvaluateBoolean"
                title="发送评价"
                :students="[student]"
                :type="2">
            </push-motion-modal>
            <approval-passed-add-family-member-modal
                :list-id="student.listId"
                :student-id="student.id"
                @ok="refreshData(student.id)"
                :visible.sync="approvalPassedAddFamilyMemberModalVisible">
            </approval-passed-add-family-member-modal>
        </template>
    </div>
</template>
<script>
//头像裁剪组件
import AvatarVue from '../image/ImageCrop.vue';
//课节信息组件
import StudentClassPage from './detail/StudentClassPage.vue';
//活动信息
import ActivityInfoVue from '../ActivityInfo/ActivityInfo.vue';
//动态列表
import MotionPage from '../Motion/MotionPage.vue';
//跟进记录
import StudentFollowUpRecord from './detail/StudentFollowUpRecord.vue';
//请假原因
import LeaveListVue from '../LeaveList/LeaveList.vue';
//照片库
import PhotographListVue from '../PhotographList/PhotographList.vue';
//编辑会员
import UpdateVipStudentModal from './detail/UpdateVipStudentModal.vue';
//添加实体卡弹窗
import AddCardVue from '../AddCard/AddCard.vue';
//会员其他信息
import VipOtherInfoVue from '../VipOtherInfo/VipOtherInfo.vue';
//约课、排课弹窗
import AppointClass from './AppointClass.vue';
//请假弹窗
import VacateVue from '../Vacate/Vacate.vue';
//发送消息..弹窗
import PushMotionModal from '../Motion/PushMotionModal.vue';
import ContractCardPage from './detail/ContractCardPage.vue';
import {getBirthdayDisplay} from '../../utils/studentUtils';
import GenderView from '../Common/GenderView.vue';
import ArrangeClass from './ArrangeClass.vue';
import {
    allocateConsultantApi,
    allocateMarketApi,
    approvalRejectAddFamilyMemberApi,
    getStudentDetailApi, updateLikeSubjectApi
} from '../../api/student/studentApi';
import ConsultantSelect from '../List/form/ConsultantSelect.vue';
import MarketSelect from '../List/form/MarketSelect.vue';
import StudentAvatar from '../Common/StudentAvatar.vue';
import ApprovalPassedAddFamilyMemberModal from './detail/ApprovalPassedAddFamilyMemberModal.vue';
import AppointClassVue from './AppointClass.vue';
import UpdateStudentTagModal from './detail/UpdateStudentTagModal.vue';
import UpdateStudentLikeSubjectModal from '../Form/UpdateStudentLikeSubjectModal.vue';
import StudentOperationRecordPage from './detail/StudentOperationRecordPage.vue';

const set = new Set();

export default {
    components: {
        UpdateStudentLikeSubjectModal,
        UpdateStudentTagModal,
        AppointClassVue,
        ApprovalPassedAddFamilyMemberModal,
        StudentAvatar,
        MarketSelect,
        ConsultantSelect,
        ArrangeClass,
        GenderView,
        AvatarVue,
        StudentClassPage,
        ActivityInfoVue,
        MotionPage,
        StudentFollowUpRecord,
        LeaveListVue,
        PhotographListVue,
        UpdateVipStudentModal,
        AddCardVue,
        VipOtherInfoVue,
        AppointClass,
        VacateVue,
        PushMotionModal,
        ContractCardPage,
        StudentOperationRecordPage
    },
    props: {
        studentId: {
            type: Number
        },
        visible: Boolean
    },
    emits: ['update:visible', 'updated', 'close'],
    data() {
        return {
            activeBar: undefined,
            activeRef: undefined,
            //上传头像的显隐
            uploadAvatarUrlBoolean: false,
            //编辑会员弹窗的显隐
            updateVipStudentModalVisible: false,
            //添加实体卡弹窗
            cardModalVisible: false,
            //会员其他信息弹窗显隐
            vipOtherInfoVisible: false,
            //约课排课弹窗显隐
            appointVisible: false,
            //排课visible
            arrangeVisible: false,
            //请假他弹窗显隐
            vacateBoolean: false,
            //发送评价弹窗显隐
            sendEvaluateBoolean: false,
            //会员标签管理弹窗显隐
            vipTagsManager: false,
            approvalPassedAddFamilyMemberModalVisible: false,
            selectTabs: [
                {
                    code: '1',
                    name: '合同信息',
                    component: ContractCardPage,
                    getBindValue: () => {
                        return {
                            studentId: this.student.id,
                            defaultContractId: this.student.defaultContractId
                        };
                    },
                    nextPage: () => {
                        this.$refs.activeRef.nextPage();
                    }
                },
                {
                    code: '2',
                    name: '课程信息',
                    component: StudentClassPage,
                    getBindValue: () => {
                        return {
                            studentId: this.student.id,
                        };
                    },
                    nextPage: () => {
                        this.$refs.activeRef.nextPage();
                    }
                },
                {
                    code: '3',
                    name: '活动信息',
                    component: ActivityInfoVue,
                    getBindValue: () => {

                    },
                    nextPage: () => {
                        this.$refs.activeRef.nextPage();
                    }
                },
                {
                    code: '4',
                    name: '动态列表',
                    component: MotionPage,
                    getBindValue: () => {
                        return {
                            student: this.student,
                        };
                    },
                    nextPage: () => {
                        this.$refs.activeRef.nextPage();
                    }
                },
                {
                    code: '5',
                    name: '跟进记录',
                    component: StudentFollowUpRecord,
                    getBindValue: () => {
                        return {
                            studentId: this.student.id,
                            listId: this.student.listId,
                        };
                    },
                    on: {
                        'updated': () => {
                            this.refreshData(this.student.id);
                        }
                    },
                    nextPage: () => {
                        this.$refs.activeRef.nextPage();
                    }
                },
                {
                    code: '6',
                    name: '操作记录',
                    component: StudentOperationRecordPage,
                    getBindValue: () => {
                        return {
                            studentId: this.student.id,
                        };
                    },
                    nextPage: () => {
                        this.$refs.activeRef.nextPage();
                    }
                },
                {
                    code: '7',
                    name: '课程请假/取消原因',
                    component: LeaveListVue,
                    getBindValue: () => {

                    },
                    nextPage: () => {
                        this.$refs.activeRef.nextPage();
                    }
                },
                {
                    code: '8',
                    name: '照片库',
                    component: PhotographListVue,
                    getBindValue: () => {
                        return {
                            studentId: this.student.id,
                        };
                    },
                    nextPage: () => {
                        this.$refs.activeRef.nextPage();
                    }
                },
            ],
            student: {},
            status: {
                Normal: '有效',
                PendingReview: '待审核',
                Rejected: '已驳回',
            },
            loading: false,
            updated: 0
        };
    },
    methods: {
        getBirthdayDisplay,
        refreshData(studentId) {
            const realStudentId = studentId ? studentId : this.studentId;
            if (set.has(realStudentId)) {
                this.updated++;
            } else {
                set.add(realStudentId);
            }
            this.loading = true;
            getStudentDetailApi(realStudentId).then(({data}) => {
                this.student = data.data;
                const currentCheck = this.activeBar ? this.activeBar : this.selectTabs[0];
                this.activeBar = undefined;
                setTimeout(() => {
                    this.activeBar = currentCheck;
                });
            }).finally(() => this.loading = false);
        },
        //修改合同类型筛选数据
        changeContractNum(num) {
            this.contractNum = num;
        },
        closeAvatarUrlModal() {
            this.uploadAvatarUrlBoolean = false;
        },
        //打开添加实体卡弹窗
        addCard() {
            this.cardModalVisible = true;
        },
        //关闭添加实体卡弹窗
        closeCard() {
            this.cardModalVisible = false;
        },
        //关闭请假弹窗
        closeVacateModal() {
            this.vacateBoolean = false;
        },
        //点击续签
        renew() {
            this.$emit('update:visible', false);
            this.$emit('close');
            setTimeout(() => {
                this.$router.push({
                    path: '/createContract',
                    query: {
                        listId: this.student.listId,
                        paperType: 2
                    }
                });
            });
        },
        handleConsultantSelected(consultant) {
            return allocateConsultantApi({
                studentId: this.student.id,
                consultantId: consultant.id
            }).then(() => {
                this.refreshData();
            });
        },
        handleMarketSelected(market) {
            return allocateMarketApi({
                studentId: this.student.id,
                marketId: market.id
            }).then(() => {
                this.refreshData();
            });
        },
        handleReject() {
            this.$confirm('确认不同意？', {
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        approvalRejectAddFamilyMemberApi({studentId: this.student.id})
                            .then(() => {
                                this.$emit('update:visible', false);
                                done();
                            }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                    }
                }
            });
        },
        handleUpdateStudentLikeSubject(likeSubjectIds) {
            return updateLikeSubjectApi({
                studentId: this.student.id,
                likeSubjectIds
            });
        },
        handleDelete() {
            setTimeout(() => {
                this.$emit('update:visible', false);
                this.$emit('updated');
            }, 100);
        },
        loadMore() {
            this.activeBar && this.activeBar.nextPage();
        },
        openAddFollowUpRecordModal() {
            setTimeout(() => {
                setTimeout(() => {
                    if (!this.activeBar || this.activeBar.code !== '5') {
                        this.activeBar = this.selectTabs[4];
                    }

                    const run = () => {
                        setTimeout(() => {
                            if (!this.$refs.activeRef) {
                                run();
                            } else {
                                this.$refs.activeRef.addStudentFollowUpRecordModalVisible = true;
                            }
                        }, 50);
                    };
                    run();

                });
            });
        }
    },
    watch: {
        visible: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    if (this.studentId) {
                        this.student = {id: this.studentId};
                        this.refreshData();
                    } else {
                        alert('未传递studentId!');
                    }
                } else {
                    this.activeBar = undefined;
                    if (this.updated) {
                        this.$emit('updated');
                    }
                    set.clear();
                    this.updated = 0;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.detail-main {
    width: 100%;
    height: 80vh;
    overflow: auto;
}

.wrap {
    // width:80%;
    width: 1110px;
    background: #fff;
    padding: 20px 0 0;
    box-sizing: border-box;
    border-radius: 2px;
    max-height: 85%;
    overflow-y: scroll;
    position: relative;
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    box-sizing: border-box;
}

.detail_top {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 30px 0 20px 20px;
    box-sizing: border-box;

    .left {
        display: flex;
        flex-direction: column;

        > .info {
            display: flex;

            .student-avatar {
                flex: 0 0 100px;
                height: 100px;
                margin-right: 35px;
                cursor: pointer;
                position: relative;
                transform: translateX(5px);
            }

            .student-avatar::after {
                content: "";
                position: absolute;
                display: block;
                width: 110px;
                height: 110px;
                left: -5px;
                top: -5px;
                z-index: 1;
                box-shadow: 0 0 5px rgba(0, 0, 0, .1);
            }


            .detail_top_left {
                > * {
                    margin: 10px 0;
                }

                .people_info {
                    display: flex;
                    align-items: center;

                    > p {
                        color: #29cb97;
                        font-size: 18px;
                        margin: 0;
                    }

                    > img:nth-of-type(1) {
                        background: #fda6c9;
                        border-radius: 50%;
                        margin: 0 20px;
                        margin-bottom: 4px;
                    }

                    img {
                        width: 17px;
                        height: 17px;
                        padding: 3px;
                    }

                    > span:nth-of-type(2) {
                        margin: 0 20px;
                    }
                }

                .addCard {
                    color: #29cb97;
                    margin: 10px 0;
                    cursor: pointer;
                }

                .guwen {
                    display: flex;
                    align-items: center;

                    > span {
                        margin: 0;
                    }

                    > div {
                        display: flex;
                        align-items: center;

                        > span {
                            text-align: justify;
                            text-justify: inter-word;
                            color: #818c99;
                        }

                        > p {
                            margin: 0;
                            margin-left: 5px;
                        }

                        > strong {
                            font-weight: normal;
                            color: #29cb97;
                            cursor: pointer;
                            margin-left: 10px;
                            margin-right: 15px;
                        }
                    }
                }

                .shichang,
                .kefu {
                    display: flex;
                    align-items: center;

                    > span {
                        text-align: justify;
                        text-justify: inter-word;
                        color: #818c99;
                    }

                    > p {
                        margin: 0;
                        margin-left: 5px;
                    }

                    > strong {
                        font-weight: normal;
                        color: #29cb97;
                        cursor: pointer;
                        margin-left: 10px;
                        margin-right: 15px;
                    }
                }

                .intro {
                    display: flex;
                    align-items: center;

                    > span {
                        color: #818c99;
                    }

                    p {
                        margin: 0;
                        margin-left: 5px;

                        > i {
                            font-style: normal;
                            color: #29cb97;
                        }
                    }
                }

                .link {
                    display: flex;
                    align-items: center;

                    > span {
                        color: #818c99;
                        display: inline-flex;
                        width: 55px;
                    }

                    > p {
                        margin: 0;
                    }

                    p:nth-of-type(1) {
                        margin-left: 5px;
                    }

                    p:nth-of-type(2) {
                        margin-left: 15px;

                        span {
                            color: #818c99;
                        }
                    }

                    > img {
                        width: 15px;
                        height: 15px;
                        margin-left: 15px;
                    }
                }

                .lookOther {
                    color: #29cb97;
                    cursor: pointer;
                }
            }
        }


        .family {
            margin-top: 14px;
            width: 100%;
            display: flex;
            color: #818c99;
            font-size: 14px;

            .label {
                margin-top: 20px;

                > div {
                    line-height: 1.1;
                }

                margin-right: 20px;
            }

            .family-member {
                padding: 10px 10px 3px 10px;
                text-align: center;
                cursor: pointer;

                .family-member-avatar {
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                }

                > :last-child {
                    margin-top: 5px;
                }

                &:hover {
                    background-color: #f5f7fa;
                    border-radius: 4px;
                }
            }
        }
    }

    > div {
        flex: 1;
    }

    > div:nth-of-type(2) {
        height: 299px;
    }


    .detail_top_right {
        .btnBox {
            display: flex;
            align-items: center;

            > div + div {
                margin-left: 10px;
            }

            > div {
                background: #29cb97;
                border-radius: 3px;
                width: 48px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                > span {
                    color: #fff;
                }
            }
        }

        .vip_tabs {
            display: flex;
            align-items: baseline;
            margin-top: 20px;

            span {
                color: #818c99;
                // width: fit-content;
                flex: 0 0 62px;
                line-height: 25px;
            }
        }

        .vip_tabs:nth-of-type(2) {
            margin-top: 10px;
        }

        .vip_status {
            display: flex;
            align-items: center;

            span {
                color: #818c99;
            }

            p {
                color: #000;
                margin-left: 5px;
            }
        }

        .nowWeek {
            display: flex;
            align-items: baseline;

            span {
                color: #818c99;
            }

            > div {
                margin-left: 5px;

                div {
                    display: flex;
                    align-items: baseline;

                    p {
                        margin: 5px 0;
                    }

                    > p:nth-of-type(1) {
                        color: #000;
                        width: 65px;
                    }

                    > p:nth-of-type(2) {
                        margin-left: 10px;
                        color: #818c99;

                        span {
                            color: #29cb97;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}

.vip_tabs_item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;
    flex: 1;

    > .label {
        width: fit-content;
        white-space: nowrap;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        border: 1px solid #edf0f2;
        font-size: 10px;
        margin-right: 10px;
        line-height: 20px;
        color: #292e33 !important;
    }

    > div {
        padding: 0 10px;
        box-sizing: border-box;
        height: 25px;
        margin: 5px 0;

        span {
            display: block;
            background: lightcoral;
            height: 25px !important;
            border-radius: 13px;
            width: fit-content;
            padding: 0 10px;
            box-sizing: border-box;
        }
    }
}

.detail_down {
    background: #f5f7fa;

    .detail_down_top {
        width: 100%;
        height: 45px;
        // border-top: 1px solid #818c99;
        border-top: 1px solid #edf0f2;
        display: flex;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 100;

        > div {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 2px solid transparent;
            background: #fff;
            cursor: pointer;

            p {
                color: #292e33;
                transition: all 0.3s;
            }
        }

        > div.on {
            border-bottom: 2px solid #29cb97;

            p {
                color: #29cb97;
            }
        }

        > div:hover {
            p {
                color: #29cb97;
            }
        }
    }
}

.guWenList {
    width: 100%;
    padding: 0;
    margin: 0;
    height: calc(100% - 35px);
    overflow-y: scroll;

    li {
        width: 100%;
        height: 35px;
        background: #eaeaea;
        padding-left: 30px;
        cursor: pointer;

        p {
            line-height: 35px;
            font-size: 14px;
            margin: 0;
        }
    }

    li:hover {
        background: #f5f5f5;
    }
}

header {
    display: flex;
    // align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;
    //height: 350px;

    > div {
        padding: 0 10px;
        box-sizing: border-box;
        height: 25px;
        margin: 5px 0;

        span {
            display: block;
            background: lightcoral;
            height: 25px !important;
            border-radius: 13px;
            width: fit-content;
            padding: 0 10px;
            box-sizing: border-box;
        }

        em {
            font-style: normal;
            cursor: pointer;
            color: #292e33;
        }
    }
}

.tag-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.tag-item {
    padding: 0 10px;
    border-radius: 100px;
}

.update-tag-button {
    margin: 0;
    color: #29cb97;
    width: 45px;
    height: 25px;
    display: flex;
    align-items: center;
    border: 1px solid #edf0f2;
    border-radius: 13px;
    justify-content: center;
    cursor: pointer;
}
</style>

<style scoped>
>>> .student-detail > .el-dialog__body {
    margin-top: -50px;
    padding: 0 !important;
}

>>> .student-detail > .el-dialog__header {
    display: none;
}
</style>

<style>
.my-popover {
    padding: 0 1px;
    box-sizing: border-box;
    transform: translateY(-40px);
    box-shadow: none;
    border: none;
    height: 350px;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
}
</style>
<style lang="scss">
.detail_model_dialog {
    .el-dialog__body {
        padding-top: 0 !important;
    }
}
</style>
<style scoped>
>>> .contract-cart-page-wrapper {
    height: inherit !important;
}
</style>
