<template>
    <el-dialog custom-modal
               width="470px"
               title="修改合同编号"
               :visible="visible"
               :modal-append-to-body="false"
               append-to-body>
        <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
            <el-form-item prop="code" label="新编号" size="mini">
                <el-input :maxlength="100" show-word-limit v-model="formData.code" clearable
                          placeholder="输入新编号"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button @click="$emit('update:visible',false)">取 消</el-button>
            <el-button type="primary" @click="handleConfirm" :loading="confirmLoading"
                       :disabled="formData.code===data.paperNo">确 定
            </el-button>
        </div>
    </el-dialog>
</template>
<script>
import {computed, defineComponent, inject, ref, toRefs} from "vue";
import {updateCodeApi} from "../../../api/student/contractApi";

export default defineComponent({
    emits: ['update:visible'],
    props: {visible: Boolean},
    setup(props, {emit}) {

        const formData = ref({code: undefined});

        const formRules = ref({code: [{required: true, message: "合同编号是必填的"}]});

        const confirmLoading = ref(false);

        const formRef = ref();

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate()
                .then(() => updateCodeApi({
                    ...formData.value,
                    contractId: data.value.id
                }))
                .then(() => {
                    emit('update:visible', false)
                    refreshContract()
                    refreshContractLog()
                })
                .finally(() => confirmLoading.value = false)
        }

        const getData = inject('getData')

        const data = computed(() => getData())

        const refreshContract = inject('refreshContract')

        const refreshContractLog = inject('refreshContractLog')

        return {
            ...toRefs(props),
            formData,
            formRules,
            confirmLoading,
            formRef,
            data,
            handleConfirm
        }
    }
})
</script>