<template>
<div>
    <el-dialog width="720px"
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               title="title"
               custom-class="addStudent-dialog"
               append-to-body>
        <header>
            <p :class="check === 1 ? 'on' : ''" @click="check=1">
                添加约课学员
            </p>
            <p :class="check === 2 ? 'on' : ''" @click="check=2">
                添加排课学员
            </p>
            <p v-if="isAudition" :class="check === 3 ? 'on' : ''" @click="check=3">
                添加约课名单
            </p>
            <img src="../../../assets/img/cha3.png" @click="$emit('update:visible',false)" alt=""/>
        </header>
        <add-student-for-reserve v-if="check===1"
                                 v-bind="$props"
                                 :visible="visible"
                                 @update:visible="val=>$emit('update:visible',val)">
        </add-student-for-reserve>
        <add-student-for-plan v-else-if="check===2"
                              v-bind="$props"
                              :visible="visible"
                              @update:visible="val=>$emit('update:visible',val)">
        </add-student-for-plan>
        <add-student-for-list v-else-if="check===3"
                              v-bind="$props" :visible="visible"
                              @update:visible="val=>$emit('update:visible',val)">
        </add-student-for-list>
    </el-dialog>
</div>
</template>

<script>
import AddStudentForReserve from './AddStudentForReserve.vue'
import AddStudentForPlan from './AddStudentForPlan.vue'
import AddStudentForList from './AddStudentForList.vue'
import {defineComponent, ref, toRefs, watch} from 'vue'

export default defineComponent({
    components: {AddStudentForList, AddStudentForPlan, AddStudentForReserve},
    props: {
        visible: Boolean,
        classId: Number,
        classNumberNo: String,
        consumeCount: Number,
        isAudition: Boolean,
        students: Array,
    },
    emits: ['update:visible'],
    setup (props) {

        const check = ref()
        watch(() => props.visible, newVal => {
            if (newVal) {
                check.value = 1
            } else {
                check.value = undefined
            }
        })

        return {
            ...toRefs(props),
            check
        }
    }
})
</script>

<style lang="scss" scoped>
header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #ccc;
    position: relative;

    img {
        position: absolute;
        width: 14px;
        height: 14px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    p {
        margin: 0;
        width: fit-content;
        padding: 0 10px;
        height: 60px;
        line-height: 60px;
        box-sizing: border-box;
        font-size: 14px;
        color: #292e33;
        cursor: pointer;
    }

    p.on {
        border-bottom: 4px solid #29cb97;
        color: #29cb97;
        font-weight: 500;
    }
}

.yueke_stu {
    padding: 0 15px;
    box-sizing: border-box;

    .filter {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .filter_left {
            display: flex;
            align-items: center;

            label {
                display: flex;
                align-items: center;
                margin-right: 20px;

                span {
                    cursor: pointer;
                }
            }

            p {
                margin-right: 20px;

                em {
                    color: #29cb97;
                    font-style: normal;
                }
            }
        }
    }
}
</style>


<style lang="scss">
.el-form--label-top .el-form-item__label {
    padding-bottom: 0;
}

.addStudent-dialog {
    //height: 520px;

    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #ccc;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 40px;
        color: #292e33;
        display: none;

        i::before {
            position: relative;
            top: -10px;
        }

        span {
            font-size: 14px;
        }
    }

    .el-dialog__body {
        padding: 0 0 30px;
        box-sizing: border-box;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
        overflow: hidden;
        border-top: 1px solid #ccc;
        display: none;
    }
}
</style>
<style lang="scss" scoped>
.text {
    .sex,
    .wx {
        position: absolute;
        right: 80px;
        top: 0;
        width: 10px;
        height: 10px;
    }

    .sex {
        background: #fda6c9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        right: 95px;
    }

    span {
        display: inline-block;
        color: #000;
        width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    p {
        color: #818c99;
        margin: 5px 0;
    }

    div {
        width: fit-content;
        padding: 2px 5px;
        box-sizing: border-box;
        border: 1px solid #dedede;
        border-radius: 5px;
        font-size: 12px;
    }
}

.linkMan {
    width: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
        margin: 0;
    }

    span {
        color: #818c99;
        margin-top: 5px;
    }
}

.counselor {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
        margin: 0;
    }

    span {
        color: #818c99;
        margin-top: 5px;
    }
}
</style>
