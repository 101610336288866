import { render, staticRenderFns } from "./AddChildClassModal.vue?vue&type=template&id=556ee8c4&scoped=true"
import script from "./AddChildClassModal.vue?vue&type=script&lang=js"
export * from "./AddChildClassModal.vue?vue&type=script&lang=js"
import style0 from "./AddChildClassModal.vue?vue&type=style&index=0&id=556ee8c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "556ee8c4",
  null
  
)

export default component.exports