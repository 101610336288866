import http from '../../utils/httpRequest';

export function storePageApi(params) {
    return http.get('/admin/balletstore/page', {params});
}

export function storeDetailApi(id) {
    return http.get(`/admin/balletstore/detail/${id}`);
}

export function createStoreApi(data) {
    return http.post('/admin/balletstore/create', data);
}

export function updateStoreApi(data) {
    return http.put('/admin/balletstore/update', data);
}
