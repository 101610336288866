<template>
    <div>
        <el-dialog
            :title="water.paymentPrice>0?'确认收款':'确认退款'"
            :visible="visible"
            @update:visible="val=>$emit('update:visible',val)"
            width="400px"
            custom-class="ballet-vue-confirm-modal"
            append-to-body>
            <el-form ref="formRef"
                     size="mini"
                     label-position="top"
                     :model="formData"
                     :rules="rules"
                     label-width="80px">
                <el-form-item label="支付方式" prop="payType">
                    <el-select style="width:100%;" v-model="formData.payType" placeholder="请选择">
                        <el-option v-for="item in $store.getters['common/payMethods']"
                                   v-bind="item"
                                   :key="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="交易备注" prop="checkNotes">
                    <el-input
                        type="textarea"
                        :autosize="{minRow:1,maxRows:1000}"
                        placeholder="请输入备注"
                        v-model="formData.checkNotes">
                    </el-input>
                </el-form-item>
                <el-form-item :label="water.paymentPrice>0?'收款日期':'退款日期'" prop="confirmCollectionDate">
                    <el-date-picker
                        style="width: 100%;"
                        v-model="formData.confirmCollectionDate"
                        :picker-options="pickerOptions"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="$emit('update:visible',false)">取 消</el-button>
                <el-button type="primary" @click="submit" :loading="confirmLoading">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {confirmWaterApi} from '../../../api/student/contractApi';
import dayjs from 'dayjs';

export default {
    props: {
        visible: Boolean,
        type: Number,
    },
    emits: ['update:visible'],
    inject: ['refreshWater', 'getData', 'getWater', 'refreshContract', 'callConfirmCollectionHook'],
    data() {
        return {
            formData: {
                payType: undefined,
                confirmCollectionDate: undefined,
                checkNotes: undefined,
            },
            rules: {
                payType: [{required: true, message: '请选择支付方式'}],
                confirmCollectionDate: [{required: true, message: '日期是必填的'}]
            },
            confirmLoading: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            }
        };
    },
    methods: {
        submit() {
            this.$confirm('确认要执行操作吗？', {
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        confirmWaterApi({
                            ...this.formData,
                            waterId: this.water.id,
                            payType: this.getData().payType,
                            result: true
                        }).then(() => {
                            done();
                            this.$emit('update:visible', false);
                            this.refreshWater();
                            this.refreshContract();
                            this.callConfirmCollectionHook();
                            this.$refs.formRef.resetFields();
                        }).finally(() => {
                            this.confirmLoading = false;
                        });
                    } else {
                        done();
                    }
                }
            });
        }
    },
    computed: {
        water() {
            const water = this.getWater();
            return water ? water : {};
        }
    },
    watch: {
        water: {
            immediate: true,
            handler(newVal) {
                if (newVal.id) {
                    this.formData = {
                        confirmCollectionDate: dayjs().format('YYYY-MM-DD'),
                        checkNotes: undefined,
                    };
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item--mini .el-form-item__label {
    line-height: 12px;
    margin: 0;

}
</style>
<style lang="scss">
.ballet-vue-confirm-modal {
    .el-dialog__body {
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeef5;
        border-top: 1px solid #ebeef5;
    }
}
</style>
<style scoped>
>>> .el-form-item--mini .el-form-item__label {
    line-height: 12px !important;
    margin: 0;
}

>>> .el-form--label-top .el-form-item__label {
    line-height: 12px;
    margin: 0;
}
</style>
