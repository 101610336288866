<template>
<div style="display: flex;gap: 20px;flex-wrap: wrap;">
    <div
        style="position: relative;width: calc(50% - 10px);min-width: fit-content;display: flex;gap: 10px;flex-direction: column;background-color: #f5f7fa;border-radius: 4px;padding: 14px;line-height: 1;"
        v-for="student  in bindList.students"
        :key="student.id">
        <div style="display: flex;gap: 5px;align-items: center;">
            <span>{{ student.name }}</span>
            <gender-view :gender="student.sex"></gender-view>
        </div>
        <div style="font-size: 12px;color: #818c99;">
            {{ getBirthdayDisplay(student.birthdayTime) }}
        </div>
        <div>
            <el-button type="text"
                       style="margin: 0;padding: 0;position: absolute;right: 6px;top: 6px;font-size: 20px;"
                       @click="updateStudentModalVisible=true;currentStudent=student"
                       icon="el-icon-edit-outline">
            </el-button>
        </div>
    </div>
    <update-student-modal :visible.sync="updateStudentModalVisible" :form-data="currentStudent"></update-student-modal>
</div>
</template>
<script>
import GenderView from '@/components/Common/GenderView.vue'
import {defineComponent, ref, toRefs} from 'vue'
import {getBirthdayDisplay} from '@/utils/studentUtils'
import UpdateStudentModal from '@/components/List/detail/UpdateStudentModal.vue'

export default defineComponent({
    components: {UpdateStudentModal, GenderView},
    props: {bindList: Object},
    setup (props) {

        const updateStudentModalVisible = ref(false)

        const currentStudent = ref({})

        return {
            ...toRefs(props),
            updateStudentModalVisible,
            currentStudent,
            getBirthdayDisplay
        }
    }
})
</script>
