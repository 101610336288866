import {randomId} from '@/utils/treeUtils';
import {cloneObject} from '@/utils/ObjectUtils';
import moreOptionsPathConst from '@/router/more/pathConst';

const PLUS_SIGN_CREATE_LIST = 'PLUS_SIGN_CREATE_LIST';

const PLUS_SIGN_IMPORT_LIST = 'PLUS_SIGN_IMPORT_LIST';

const PLUS_SIGN_ADD_VIP_FAMILY = 'PLUS_SIGN_ADD_VIP_FAMILY';

const PLUS_SIGN_IMPORT_VIP_STUDENT = 'PLUS_SIGN_IMPORT_VIP_STUDENT';

const PLUS_SIGN_CREATE_MANEUVER = 'PLUS_SIGN_CREATE_MANEUVER';

const PLUS_SIGN_CREATE_CLASS = 'PLUS_SIGN_CREATE_CLASS';

const plusSignItems = [
    {
        name: '新建名单',
        key: PLUS_SIGN_CREATE_LIST,
    },
    {
        name: '导入名单',
        key: PLUS_SIGN_IMPORT_LIST,
    },
    {
        name: '添加家庭成员',
        key: PLUS_SIGN_ADD_VIP_FAMILY,
    },
    {
        name: '导入会员',
        key: PLUS_SIGN_IMPORT_VIP_STUDENT,
    },
    {
        name: '新建活动',
        key: PLUS_SIGN_CREATE_MANEUVER,
    },
    {
        name: '新建课程',
        key: PLUS_SIGN_CREATE_CLASS,
    }
];

const DASH_BOARD_CONTRACTING_OVERVIEW = 'DASH_BOARD_CONTRACTING_OVERVIEW';

const DASH_BOARD_CONSUME_CLASS_OVERVIEW = 'DASH_BOARD_CONSUME_CLASS_OVERVIEW';

const DASH_BOARD_FAST_SIGN = 'DASH_BOARD_FAST_SIGN';

const DASH_BOARD_LIST_VISIT_MANAGE = 'DASH_BOARD_LIST_VISIT_MANAGE';

const DASH_BOARD_TASK_MANAGE = 'DASH_BOARD_TASK_MANAGE';

const DASH_BOARD_LIST_MANAGE = 'DASH_BOARD_LIST_MANAGE';

const DASH_BOARD_CONTRACT_MANAGE = 'DASH_BOARD_CONTRACT_MANAGE';

const DASH_BOARD_VIP_STUDENT_MANAGE = 'DASH_BOARD_VIP_STUDENT_MANAGE';

const DASH_BOARD_NEW_FAMILY = 'DASH_BOARD_NEW_FAMILY';

const DASH_BOARD_DAY_CLASS = 'DASH_BOARD_DAY_CLASS';

const DASH_BOARD_RANK = 'DASH_BOARD_RANK';

const DASH_BOARD_SOURCE_MANAGE = 'DASH_BOARD_SOURCE_MANAGE';

const dashboardItems = [
    {
        name: '签约概览',
        key: DASH_BOARD_CONTRACTING_OVERVIEW,
    },
    {
        name: '耗课概览',
        key: DASH_BOARD_CONSUME_CLASS_OVERVIEW,
    },
    {
        name: '快速签到',
        key: DASH_BOARD_FAST_SIGN,
    },
    {
        name: '到访管理',
        key: DASH_BOARD_LIST_VISIT_MANAGE,
    },
    {
        name: '任务管理',
        key: DASH_BOARD_TASK_MANAGE,
    },
    {
        name: '名单管理',
        key: DASH_BOARD_LIST_MANAGE,
    },
    {
        name: '合同管理',
        key: DASH_BOARD_CONTRACT_MANAGE,
    },
    {
        name: '会员管理',
        key: DASH_BOARD_VIP_STUDENT_MANAGE,
    },
    {
        name: '家庭新增成员',
        key: DASH_BOARD_NEW_FAMILY,
    },
    {
        name: '今日课节',
        key: DASH_BOARD_DAY_CLASS,
    },
    {
        name: '琅琊榜',
        key: DASH_BOARD_RANK,
    },
    {
        name: '渠道/来源管理',
        key: DASH_BOARD_SOURCE_MANAGE,
    }
];

function children(routes, parent, pages) {
    routes.forEach(route => {
        const data = {
            name: route.meta.title,
            key: parent.key + (route.path.startsWith('/') ? route.path : '/' + route.path),
            meta: route.meta
        };
        parent.children.push(data);
        if (Array.isArray(route.children) && route.children.length) {
            data.children = [];
            children(route.children, data, pages);
        } else {
            pages.push(data);
        }
    });
}

export default Object.freeze((() => {
    const tree = [];
    const pages = [];
    const requireContext = require.context('../router', true, /index\.js$/);
    requireContext.keys().forEach(filename => {
        if (filename !== './index.js') {
            const module = requireContext(filename);
            const name = module.meta.name;
            const routes = module.default;
            const parent = {name, children: [], key: ''};
            tree.push(parent);
            children(routes, parent, pages);
        }
    });

    const clonePlusSignItems = cloneObject(plusSignItems);
    tree.push({
        name: '大加号',
        children: clonePlusSignItems
    });
    pages.push(clonePlusSignItems);
    clonePlusSignItems.forEach(item => pages.push(item));

    const cloneObjectDashboardItems = cloneObject(dashboardItems);
    pages.push(cloneObjectDashboardItems);
    tree.push({
        name: '首页看板',
        children: cloneObjectDashboardItems
    });
    cloneObjectDashboardItems.forEach(item => pages.push(item));

    randomId(tree);
    return {tree, pages};
})());

const staticMenuKeyMap = Object.freeze({
    PLUS_SIGN_CREATE_LIST,
    PLUS_SIGN_IMPORT_LIST,
    PLUS_SIGN_ADD_VIP_FAMILY,
    PLUS_SIGN_IMPORT_VIP_STUDENT,
    PLUS_SIGN_CREATE_MANEUVER,
    PLUS_SIGN_CREATE_CLASS,
    DASH_BOARD_CONTRACTING_OVERVIEW,
    DASH_BOARD_CONSUME_CLASS_OVERVIEW,
    DASH_BOARD_FAST_SIGN,
    DASH_BOARD_LIST_VISIT_MANAGE,
    DASH_BOARD_TASK_MANAGE,
    DASH_BOARD_LIST_MANAGE,
    DASH_BOARD_CONTRACT_MANAGE,
    DASH_BOARD_VIP_STUDENT_MANAGE,
    DASH_BOARD_NEW_FAMILY,
    DASH_BOARD_DAY_CLASS,
    DASH_BOARD_RANK,
    DASH_BOARD_SOURCE_MANAGE,
});

export {staticMenuKeyMap, moreOptionsPathConst};
