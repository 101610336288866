import http from '../../utils/httpRequest'

export function getListFollowUpRecordPageApi (listId, params) {
  return http.get(`/followUpRecord/list/page/${listId}`, {params})
}

export function addListFollowUpRecordApi (data) {
  return http.post('/followUpRecord/addTableFollow', data)
}


export function updatePurchaseOrderRecordApi(data){
  return http.put('/followUpRecord/updatePurchaseOrderRecord', data)
}
