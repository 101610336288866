<template>
<el-dialog title="放弃名单" width="21%" custom-modal append-to-body :visible="visible"
           @update:visible="val=>$emit('update:visible',val)">
    <el-form :rules="formRules" ref="form" :model="formData" size="mini" style="padding: 0 50px;" label-position="top">
        <el-form-item prop="abandonReason">
            <template v-slot:label>
                <span style="font-size: 12px;">放弃后名单将进入公海池，请选择放弃原因：</span>
            </template>
            <el-select v-model="formData.abandonReason" style="width: 100%;">
                <el-option v-for="listGiveUpReason in $store.getters['common/listGiveUpReasonList']"
                           :label="listGiveUpReason.label"
                           :value="listGiveUpReason.label"
                           :key="listGiveUpReason.id">
                </el-option>
            </el-select>
        </el-form-item>
    </el-form>
    <template v-slot:footer>
        <el-button @click="$emit('update:visible',false)">取消</el-button>
        <el-button type="primary" @click="handleConfirm" :loading="confirmLoading">确定</el-button>
    </template>
</el-dialog>
</template>
<script>
import {abandonApi} from '../../../api/student/listApi'

export default {
    emits: ['update:visible', 'ok'],
    props: {
        visible: {
            type: Boolean,
            default () {
                return true
            }
        },
        listId: {
            type: Number
        }
    },
    data () {
        return {
            confirmLoading: false,
            formData: {},
            formRules: {
                abandonReason: [{required: true, message: '请选择放弃原因'}]
            }
        }
    },
    methods: {
        handleConfirm () {
            this.confirmLoading = true
            this.$refs.form.validate()
                .then(() => abandonApi({listId: this.listId, reason: this.formData.abandonReason}))
                .then(() => {
                    this.$emit('ok')
                    this.$emit('update:visible', false)
                    this.$refs.form.resetFields()
                })

                .finally(() => this.confirmLoading = false)
        }
    }
}
</script>
