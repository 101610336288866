<template>
    <el-cascader v-bind="$attrs"
                 :value="value"
                 @input="val=>$emit('update:value',val)"
                 filterable
                 remote
                 :multiple="multiple"
                 :remote-method="remoteMethod"
                 :loading="selectLoading"
                 :props="{value: 'id', label: 'name', emitPath: true,multiple: multiple,...props}"
                 :options="options"
                 @change="handleChange">
    </el-cascader>
</template>
<script>
import {computed, defineComponent, onMounted, ref, toRefs} from 'vue'
import {getClassTypeListApi} from '../../api/mall/classTypeApi'

export default defineComponent({
    props: {
        value: [Array, Number],
        isChildren: Number,
        multiple: {
            type: Boolean,
            default() {
                return false
            }
        },
        props: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    model: {
        value: 'value',
        event: 'update:value'
    },
    emits: ['update:value', 'change'],
    setup(props, {emit}) {

        const selectLoading = ref(true)

        const defaultOptions = ref([])

        const searchOptions = ref()

        const remoteMethod = (val) => {
            if (selectLoading.value) {
                return
            }
            if (val) {
                const result = []
                for (let item of defaultOptions.value) {
                    if (item.name.indexOf(val) !== -1) {
                        result.push(item)
                    } else if (Array.isArray(item.children) && item.children.length) {
                        for (let c of item.children) {
                            if (c.name.indexOf(val) !== -1) {
                                result.push(item)
                                break
                            }
                        }
                    }
                }
                searchOptions.value = result
            } else {
                searchOptions.value = undefined
            }
        }

        onMounted(() => {
            getClassTypeListApi({timestamp: new Date().getTime()})
                .then(res => {
                    defaultOptions.value = res.data.data
                }).finally(() => selectLoading.value = false)
        })

        const options = computed(() => {
            if (selectLoading.value) {
                return []
            }
            if (searchOptions.value === undefined) {
                return defaultOptions.value
            } else {
                return searchOptions.value
            }
        })

        const handleChange = (val) => {
            if (props.multiple) {
                if (Array.isArray(val)) {
                    const result = []
                    val.forEach(v => {
                        const r = []
                        if (Array.isArray(v)) {
                            for (let option of options.value) {
                                if (option.id === v[0]) {
                                    r.push(option);
                                    if (r.length === v.length) {
                                        break
                                    }
                                }
                            }
                            if (v[1]) {
                                r.push(r[0].children.find(item => item.id === v[1]))
                            }
                        } else {
                            result.push([]);
                        }
                        result.push([])
                        emit('change', val, result)
                    })
                } else {
                    emit('change', [], [])
                }
            } else {
                if (Array.isArray(val)) {
                    if (!val.length) {
                        emit('change', [], [])
                    }
                    const result = []
                    for (const option of options.value) {
                        if (val.includes(option.id)) {
                            result.push(option)
                            if (result.length === val.length) {
                                break
                            }
                        }
                    }
                    if (val[1]) {
                        result.push(result[0].children.find(item => item.id === val[1]))
                    }
                    emit('change', val, result)
                } else {
                    for (const option of options.value) {
                        if (val === option.id) {
                            emit('change', val, option)
                            break
                        }
                    }
                }
            }
        }

        return {
            ...toRefs(props),
            selectLoading,
            options,
            remoteMethod,
            handleChange
        }
    }
})
</script>
