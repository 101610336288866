<template>
    <div class="content">
        <template v-if="step===1">
            <template v-if="classInfo">
                <p class="date">复制源日期 {{ dayjs(classInfo.beginTime).format('YYYY-MM-DD') }}</p>
            </template>
            <template v-else>
                <el-form size="mini">
                    <el-form-item label="复制源日期" required>
                        <el-date-picker type="date"
                                        v-model="selectDate"
                                        :clearable="false"
                                        value-format="yyyy-MM-dd"
                                        :picker-options="pickerOptions1">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </template>
            <el-form style="margin-top: 20px;" size="mini">
                <el-form-item label="复制目标日期" required>
                    <el-date-picker type="date"
                                    v-model="toDate"
                                    :clearable="false"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions2">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div v-loading="loading">
                <el-table :data="tableData"
                          :header-cell-style="{backgroundColor:'#f7f7f7'}"
                          :cell-style="{fontSize:'12px'}">
                    <el-table-column v-if="!classInfo" prop="time">
                        <template v-slot:header>
                            <el-checkbox @change="val=>handleSelectAll(tableData,val)"
                                         :disabled="!tableData.find(item=>!item.isOver)"
                                         :value="selected.length&&isChecked(tableData)">
                            </el-checkbox>
                        </template>
                        <template v-slot="{row}">
                            <el-checkbox @change="val=>handleSelectChange(row,val)"
                                         :value="selected.indexOf(row.id)!==-1"
                                         :disabled="row.isOver">
                            </el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column prop="beginTime" label="上课时间">
                    </el-table-column>
                    <el-table-column prop="name" label="课程名称">
                    </el-table-column>
                    <el-table-column prop="classTypeName" label="课类">
                    </el-table-column>
                    <el-table-column prop="roomNames" label="教室">
                    </el-table-column>
                    <el-table-column prop="teacherNames" label="老师">
                    </el-table-column>
                    <el-table-column label="状态">
                        <template v-slot="{row}">
                            <div>
                                <span v-if="row.isOver" style="color: #f5222d;">已结束</span>
                                <span v-else class="primary-text-color">未结束</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="stop_class_info">
                <p class="title">停课信息</p>
                <div>
                    <span>预计调整课程 {{ selected.length }} 节</span>
                    <span>未结束课程已签到 {{ countObject.noClassesCount }} 人次</span>
                    <span>请假 {{ countObject.leaveOfAbsenceCount }} 人次</span>
                    <span>排课会员 {{ countObject.planCount }} 人次</span>
                    <span>约课会员 {{ countObject.reserveCount }} 人次</span>
                    <span>约课名单 {{ countObject.listCount }} 人次</span>
                </div>
            </div>
            <div class="submit">
                <el-tooltip :disabled="!!selected.length&&!!toDate">
                    <template v-slot:content>
                        <span v-if="!selected.length">未选择课程,无法提交</span>
                        <span v-else-if="!toDate">请先选择复制目标日期</span>
                    </template>
                    <el-button type="primary"
                               size="small"
                               @click="handleNext"
                               :disabled="!selected.length||!toDate"
                               :loading="nextLoading">
                        下一步
                    </el-button>
                </el-tooltip>
            </div>
        </template>
        <template v-if="step===2">
            <div class="top-present">预计复制后的课程信息如下</div>
            <div class="table-wrapper">
                <el-table :data="selectedTableData"
                          :header-cell-style="{backgroundColor:'#f7f7f7'}"
                          :cell-style="{fontSize:'12px'}">
                    <el-table-column prop="beginTime" label="上课时间">
                    </el-table-column>
                    <el-table-column prop="name" label="课程名称">
                    </el-table-column>
                    <el-table-column prop="classTypeName" label="课类">
                    </el-table-column>
                    <el-table-column prop="roomNames" label="教室">
                    </el-table-column>
                    <el-table-column prop="teacherNames" label="老师">
                    </el-table-column>
                    <el-table-column label="状态">
                        <template v-slot="{row}">
                            <div v-if="row._isValid" class="primary-text-color">校验通过</div>
                            <div v-else>
                                <div style="color: #f5222d;">
                                    <i class="el-icon-error"></i>
                                    <span>有冲突</span>
                                </div>
                                <div style="color: #f5222d;" class="under-line"
                                     @click="classOccupancyDetailModalVisible=true;classOccupancyDetailModalData=row._validData">
                                    点击查看详情
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="bottom-present ok" v-if="validCount===selectedTableData.length">
                <i class="el-icon-warning-outline"></i>
                <span>所有课程均已通过校验,无教师、教室、学员时间安排冲突,点击</span>
                <span class="primary-text-color">确定</span>
                <span>复制课程！</span>
            </div>
            <div class="bottom-present error" v-else-if="validCount===0">
                <i class="el-icon-warning-outline"></i>
                <span>所有课程均未通过校验,教师/教室/学员时间安排存在冲突,请调整时间安排后重试！</span>
            </div>
            <div class="bottom-present ok" v-else>
                <i class="el-icon-warning-outline"></i>
                <span>部分课程已通过校验,无教师、教室、学员时间安排冲突,点击</span>
                <span class="primary-text-color">确定</span>
                <span>复制通过校验的课程！</span>
            </div>
            <div class="submit">
                <el-button size="small" @click="step=1">上一步</el-button>
                <el-button size="small"
                           type="primary"
                           :loading="confirmLoading"
                           @click="handleConfirm"
                           :disabled="validCount===0">
                    确定
                </el-button>
            </div>
        </template>
        <occupancy-detail-modal :visible.sync="classOccupancyDetailModalVisible"
                                :data="classOccupancyDetailModalData">
        </occupancy-detail-modal>
    </div>
</template>
<script>
import {computed, defineComponent, getCurrentInstance, inject, onMounted, ref, toRefs, watch} from 'vue';
import dayjs from 'dayjs';
import {
    classRescheduleApi,
    copyClassApi,
    getClassDataListApi,
    validClassRescheduleApi
} from '../../../api/class/classApi';
import {cloneObject} from '../../../utils/ObjectUtils';
import OccupancyDetailModal from '../OccupancyDetailModal.vue';

export default defineComponent({
    components: {OccupancyDetailModal},
    props: {classInfo: Object},
    emits: ['update:visible', 'updated'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const tableData = ref([]);

        const selectDate = ref();

        const toDate = ref();

        const selected = ref([]);

        const loading = ref(false);

        const countObject = ref({
            noClassesCount: 0,
            leaveOfAbsenceCount: 0,
            planCount: 0,
            reserveCount: 0,
            listCount: 0
        });

        const nextLoading = ref(false);

        const confirmLoading = ref(false);

        const step = ref();

        const selectedTableData = ref([]);

        const pickerOptions1 = {
            disabledDate(time) {
                return dayjs(time).isBefore(dayjs().startOf('day'));
            }
        };

        const pickerOptions2 = {
            disabledDate(time) {
                return props.classInfo ? dayjs(time).isSame(dayjs(props.classInfo.beginTime).startOf('day')) : dayjs(time).isSame(dayjs(selectDate.value));
            }
        };

        const classOccupancyDetailModalVisible = ref(false);

        const classOccupancyDetailModalData = ref({});

        let map = {};

        const getClassList = () => {
            loading.value = true;
            getClassDataListApi({
                startDate: selectDate.value + ' 00:00:00.000',
                endDate: selectDate.value + ' 23:59:59.999',
            }).then(res => {
                if (Array.isArray(res.data.data) && res.data.data.length) {
                    tableData.value = res.data.data.map(record => {
                        computeCount(record.students);
                        let fci = formatClassItem(record);
                        map[record.id] = fci;
                        if (!record.isOver) {
                            selected.value.push(record.id);
                        }
                        return fci;
                    });
                }
            }).finally(() => loading.value = false);
        };

        const formatClassItem = (classItem) => {
            return {
                id: classItem.id,
                beginTime: dayjs(classItem.beginTime).format('HH:mm') + '~' + dayjs(classItem.endTime).format('HH:mm'),
                name: classItem.name,
                classTypeName: classItem.classTypeName,
                roomNames: Array.isArray(classItem.classrooms) && classItem.classrooms.length ?
                    classItem.classrooms.map(item => item.name).join(',') : '',
                teacherNames: Array.isArray(classItem.mainTeachers) && classItem.mainTeachers.length ?
                    classItem.mainTeachers.map(item => item.name).join(',')
                    + (Array.isArray(classItem.assistTeachers) && classItem.assistTeachers.length ?
                        ',' + classItem.assistTeachers.map(item => item.name).join(',') : '')
                    : '',
                isOver: classItem.isOver
            };
        };

        const computeCount = (students) => {
            if (Array.isArray(students) && students.length) {
                for (let student of students) {
                    if (student.status === 'NoClasses' || student.status === 'Overdue') {
                        countObject.value.noClassesCount++;
                    } else if (student.status === 'LeaveOfAbsence' || student.status === 'LeaveOfAbsenceNoConsume') {
                        countObject.value.leaveOfAbsenceCount++;
                    }
                    if (student.courseType === 'Plan') {
                        countObject.value.planCount++;
                    } else if (student.courseType === 'Reserve') {
                        countObject.value.reserveCount++;
                    }
                    if (student.listId !== -1) {
                        countObject.value.listCount++;
                    }
                }
            }
        };

        const handleSelectAll = (list, val) => {
            if (val) {
                for (let item of list) {
                    if (!item.isOver && selected.value.indexOf(item.id) === -1) {
                        selected.value.push(item.id);
                    }
                }
            } else {
                for (let item of list) {
                    let indexOf = selected.value.indexOf(item.id);
                    if (indexOf !== -1) {
                        selected.value.splice(indexOf, 1);
                    }
                }
            }
        };

        const handleSelectChange = (row, val) => {
            if (val) {
                selected.value.push(row.id);
            } else {
                selected.value.splice(selected.value.indexOf(row.id), 1);
            }
        };

        const isChecked = (list) => {
            let result = true;
            for (let classItem of list) {
                if (!classItem.isOver && selected.value.indexOf(classItem.id) === -1) {
                    return false;
                }
            }
            return result;
        };

        const handleNext = () => {
            nextLoading.value = true;
            validClassRescheduleApi({classIds: selected.value, toDate: toDate.value}).then(res => {
                step.value = 2;
                const validInfo = {};
                if (Array.isArray(res.data.data) && res.data.data.length) {
                    for (let item of res.data.data) {
                        validInfo[item.classId] = item;
                    }
                }
                selectedTableData.value = selected.value.map(classId => {
                    const record = cloneObject(map[classId]);
                    record._validData = validInfo[classId];
                    record._isValid = true;
                    if (record._validData) {
                        if (Array.isArray(record._validData.teacherOccupancyList) && record._validData.teacherOccupancyList.length) {
                            record._isValid = false;
                        } else if (Array.isArray(record._validData.classroomOccupancyList) && record._validData.classroomOccupancyList.length) {
                            record._isValid = false;
                        } else if (Array.isArray(record._validData.studentOccupancyList) && record._validData.studentOccupancyList.length) {
                            record._isValid = false;
                        }
                    }
                    return record;
                });
            }).finally(() => nextLoading.value = false);
        };

        const handleConfirm = () => {
            confirmLoading.value = true;
            copyClassApi({
                classIds: selectedTableData.value.filter(item => item._isValid).map(item => item.id),
                toDate: toDate.value,
            }).then(() => {
                emit('update:visible', false);
                emit('updated');
            }).finally(() => confirmLoading.value = false);
        };

        onMounted(() => {
            step.value = 1;
            if (props.classInfo) {
                tableData.value = [formatClassItem(props.classInfo)];
                computeCount(props.classInfo.students);
                selected.value = [props.classInfo.id];
                map[tableData.value[0].id] = tableData.value[0];
            } else {
                selectDate.value = dayjs().format('YYYY-MM-DD');
            }
        });

        const validCount = computed(() => {
            if (selectedTableData.value.length) {
                return selectedTableData.value.filter(item => item._isValid).length;
            }
        });

        watch(selectDate, () => {
            map = {};
            selected.value = [];
            getClassList();
        }, {deep: true});

        return {
            ...toRefs(props),
            tableData,
            selectDate,
            toDate,
            selected,
            countObject,
            loading,
            nextLoading,
            confirmLoading,
            step,
            selectedTableData,
            validCount,
            pickerOptions1,
            pickerOptions2,
            classOccupancyDetailModalVisible,
            classOccupancyDetailModalData,
            handleSelectAll,
            handleSelectChange,
            isChecked,
            handleNext,
            handleConfirm,
            dayjs
        };
    }
});
</script>
<style lang="scss" scoped>
.content {
    padding: 0 15px;
    box-sizing: border-box;

    .date {
        font-size: 14px;
        color: #292e33;
    }

    .date_now {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        height: 40px;
        border: 1px solid #edf0f2;
        cursor: pointer;

        span {
            font-size: 14px;
            color: #292e33;
        }

        img {
            width: 15px;
            height: 15px;
        }
    }

    .stop_class_info {
        margin-top: 20px;

        .title {
            margin: 0 0 0 0;
            font-size: 14px;
            color: #292e33;
        }

        div {
            background: #f5f7fa;
            padding: 20px 30px;
            box-sizing: border-box;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 10px;

            span {
                font-size: 14px;
                color: #292e33;
            }
        }
    }
}

.stop_reason {
    padding: 0 15px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;

    .reason {
        margin-bottom: 10px;

        span {
            font-size: 16px;
            color: #292e33;
            margin-right: 5px;
        }
    }
}

.submit {
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-present {
    font-size: 12px;
}

.table-wrapper, .submit, .bottom-present {
    margin-top: 20px;
}

.bottom-present {
    font-size: 0;
}

.bottom-present > span {
    font-size: 12px;
    vertical-align: middle;
}

.bottom-present > i {
    font-size: 16px;
    margin-right: 5px;
    vertical-align: middle;

}

.bottom-present.ok > i {
    color: #faad14;
}

.bottom-present.error > i, .bottom-present.error > span {
    color: #f5222d;
}
</style>
