<template>
    <div>
        <el-dialog
            title="编辑课程"
            :visible="visible"
            width="640px"
            @update:visible="val=>$emit('update:visible',val)"
            append-to-body
            custom-modal>
            <div class="select">
                <span class="req">选择修改项:</span>
                <div>
                    <p :class="select===1?'on':''" @click="handleSelectChange(1)">课程开始日期</p>
                    <p :class="select===2?'on':''" @click="handleSelectChange(2)">课程结束日期</p>
                    <p :class="select===3?'on':''" @click="handleSelectChange(3)">子课程详情</p>
                </div>
            </div>
            <div v-if="select===1" class="fle_box">
                <span class="req">课程开始日期:</span>
                <el-date-picker
                    v-model="startDate"
                    type="date"
                    placeholder="选择日期"
                    style="width: 100%;"
                    value-format="yyyy-MM-dd"
                    size="mini">
                </el-date-picker>
            </div>
            <div v-if="select===2" class="fle_box">
                <span class="req">课程结束日期:</span>
                <el-date-picker
                    v-model="endDate"
                    type="date"
                    placeholder="选择日期"
                    style="width: 100%;"
                    value-format="yyyy-MM-dd"
                    size="mini">
                </el-date-picker>
            </div>
            <el-form v-show="select===3"
                     ref="formRef"
                     :model="formData"
                     size="mini"
                     :rules="formRules"
                     label-position="top">
                <div class="form-multiple-item-line">
                    <el-form-item label="修改子课程标题:" prop="name">
                        <el-input v-model="formData.name"
                                  placeholder=""
                                  :maxlength="100"
                                  show-word-limit>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="consumeClassCount">
                        <template v-slot:label>
                            <span style="vertical-align: middle;margin-right: 5px;">耗课数:</span>
                            <el-tooltip effect="dark" content="修改子课程耗课数不影响单独修改过耗课数的课节"
                                        placement="top">
                                <img style="width: 20px;height: 20px;vertical-align: middle;cursor: pointer;"
                                     src="../../../assets/img/wenhao2.png"
                                     alt="">
                            </el-tooltip>
                        </template>
                        <el-input-number v-model="formData.consumeClassCount"
                                         :min="0"
                                         controls-position="right"
                                         left
                                         :precision="0"
                                         placeholder="">
                        </el-input-number>
                    </el-form-item>
                </div>
                <div class="form-multiple-item-line">
                    <el-form-item label="平移日期:" prop="dateTransfer">
                        <el-select @change="handleDateTransferChange" v-model="formData.dateTransfer" placeholder="">
                            <el-option
                                v-for="item in dateOptions"
                                :key="item.count"
                                :label="item.name"
                                :value="item.count">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="平移后课程开始星期:" prop="weekday">
                        <el-input :value="getWeekDayDisplay(formData.weekday,1)"
                                  disabled
                                  placeholder="">
                        </el-input>
                    </el-form-item>
                </div>
                <div class="form-multiple-item-line">
                    <el-form-item label="开课时间:" prop="startTime">
                        <el-time-picker
                            v-model="formData.startTime"
                            placeholder="选择时间"
                            value-format="HH:mm">
                        </el-time-picker>
                    </el-form-item>
                    <el-form-item>
                        <template v-slot:label>
                            <div style="display: flex;gap: 5px;align-items: center;">
                                <span>课程时长:</span>
                                <span class="fail-text-color">*</span>
                                <img style="width: 12px;height: 12px;cursor: pointer;"
                                     src="../../../assets/img/edit.png"
                                     alt="">
                            </div>
                        </template>
                        <el-input v-model="formData.classDuration"
                                  disabled
                                  placeholder="">
                        </el-input>
                    </el-form-item>
                </div>
                <div class="form-multiple-item-line">
                    <el-form-item label="教室:" prop="classroomIds">
                        <class-room-select v-model="formData.classroomIds"
                                           multiple>
                        </class-room-select>
                    </el-form-item>
                </div>
                <div class="form-multiple-item-line">
                    <el-form-item label="主讲老师:" prop="mainTeacherIds">
                        <teacher-select use-teacher-id
                                        v-model="formData.mainTeacherIds"
                                        multiple>
                        </teacher-select>
                    </el-form-item>
                    <el-form-item label="辅讲老师:" prop="assistTeacherIds">
                        <teacher-select use-teacher-id
                                        v-model="formData.assistTeacherIds"
                                        multiple>
                        </teacher-select>
                    </el-form-item>
                </div>
            </el-form>
            <div class="del">
                <el-tooltip content="该子课程包含的课节存在约课排课记录，无法删除" placement="top">
                    <el-button style="padding: 0;font-size: 12px;color: #818c99;" class="under-line" type="text">
                        删除此子课程
                    </el-button>
                </el-tooltip>
            </div>
            <div slot="footer">
                <el-button @click="$emit('update:visible',false)">取 消</el-button>
                <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import TeacherSelect from "@/components/Form/TeacherSelect.vue";
import ClassRoomSelect from "@/components/Form/ClassRoomSelect.vue";
import {defineComponent, inject, ref, toRefs, watch} from "vue";
import {cloneObject} from "@/utils/ObjectUtils";
import {getWeekDayDisplay} from "@/utils/dateUtils";
import {updateChildClassApi} from "@/api/class/classApi";


const defaultFormData = ref({
    name: undefined,
    consumeClassCount: undefined,
    dateTransfer: 0,
    weekday: undefined,
    startTime: undefined,
    classDuration: undefined,
    classroomIds: [],
    mainTeacherIds: [],
    assistTeacherIds: [],
});

export default defineComponent({
    methods: {getWeekDayDisplay},
    components: {ClassRoomSelect, TeacherSelect},
    props: {
        visible: Boolean,
        data: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    emits: ['update:visible', 'ok'],
    setup(props, {emit}) {

        const select = ref(1);

        const startDate = ref();

        const endDate = ref();

        const formRef = ref();

        const formData = ref(cloneObject(defaultFormData));

        const formRules = ref({
            consumeClassCount: [{required: true, message: '耗课数量是必填的'}],
            dateTransfer: [{required: true, message: '平移日期是必填的'}],
            startTime: [{required: true, message: '课程开始时间是必填的'}],
            classDuration: [{required: true, message: '课程时长是必填的'}],
            classroomIds: [{required: true, message: '耗课数量是必填的'}],
            mainTeacherIds: [{required: true, message: '未选择主讲教师'}],
        });

        const dateOptions = (() => {
            const result = [];
            for (let i = -7; i <= 7; i++) {
                result.push({name: i === 0 ? '不平移' : `往${i > 0 ? '后' : '前'}平移${Math.abs(i)}天`, count: i});
            }
            return result;
        })();

        const confirmLoading = ref(false);

        const handleSelectChange = (index) => {
            select.value = index;
        };

        const handleDateTransferChange = (val) => {
            let computeVal = (val + props.data.cycleValue) % 7;
            if (computeVal < 0) {
                formData.value.weekday = 7 - Math.abs(computeVal);
            } else {
                formData.value.weekday = computeVal;
            }
        };

        const handleConfirm = () => {
            confirmLoading.value = true;
            formRef.value.validate().then(() => updateChildClassApi({
                ...formData.value,
                classChildrenId: props.data.id
            })).then(() => {
                emit('update:visible', false);
                emit('ok');
                reloadClass();
            }).finally(() => confirmLoading.value = false);
        };

        watch(() => props.visible, newVal => {
            if (newVal) {
                select.value = 1;
                formData.value = {
                    name: props.data.name,
                    consumeClassCount: props.data.consumeClassCount,
                    weekday: props.data.cycleValue,
                    dateTransfer: 0,
                    startTime: props.data.startTime,
                    classDuration: props.data.classDuration,
                    classroomIds: Array.isArray(props.data.classrooms) && props.data.classrooms.length ? props.data.classrooms.map(item => item.id) : [],
                    mainTeacherIds: Array.isArray(props.data.mainTeachers) && props.data.mainTeachers.length ? props.data.mainTeachers.map(item => item.id) : [],
                    assistTeacherIds: Array.isArray(props.data.assistTeachers) && props.data.assistTeachers.length ? props.data.assistTeachers.map(item => item.id) : [],
                };
                setTimeout(() => {
                    formRef.value.clearValidate();
                });
            }
        });

        const reloadClass = inject('reloadClass');

        return {
            ...toRefs(props),
            select,
            startDate,
            endDate,
            formRef,
            formData,
            formRules,
            dateOptions,
            confirmLoading,
            handleSelectChange,
            handleDateTransferChange,
            handleConfirm
        };
    },
});
</script>
<style lang="scss" scoped>
.select {
    margin-bottom: 20px;

    span {
        font-size: 14px;
        color: #292e33;

        &::after {
            content: '*';
            color: #f00;
        }
    }

    div {
        display: flex;
        align-items: center;

        p {
            border: 1px solid #e5e5e5;
            cursor: pointer;
            width: 100px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            font-size: 12px;
            color: #525b66;
        }

        p:nth-of-type(1) {
            border-radius: 4px 0 0 4px;
            border-right: none;
        }

        p:nth-of-type(3) {
            border-radius: 0 4px 4px 0;
            border-left: none;
        }

        p.on {
            border: 1px solid #29cb97;
            color: #29cb97;
            transform: scale(1.002);
        }
    }
}

.del {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}


.form-multiple-item-line {
    gap: 20px;
    flex: 1 1;
}
</style>