<template>
<div class="list-operation-record-main" v-loading="loading===1">
    <time-line :records="records" time-property="createDate">
        <template v-slot:default="{record}">
            <div>{{ record.createUserName }} {{ record.formatContent }}</div>
        </template>
    </time-line>
    <div v-if="loading===2">
        <i class="el-icon-loading"></i>
    </div>
    <div style="padding: 20px 0;text-align: center;" v-else-if="page.total <= records.length">暂无更多数据</div>
</div>
</template>
<script>
import {defineComponent, onMounted, ref, toRefs} from 'vue'
import {getDefaultPage} from '../../../utils'
import {getListOperationRecordPageApi} from '../../../api/student/listApi'
import TimeLine from '../TimeLine.vue'

export default defineComponent({
    components: {TimeLine},
    props: {listId: Number},
    setup (props) {

        const loading = ref(0)

        const page = ref(getDefaultPage())

        const records = ref([])

        const refreshPage = () => {
            loading.value = 1
            records.value = []
            page.value = getDefaultPage()
            getPage()
        }

        const getPage = () => {
            getListOperationRecordPageApi({
                listId: props.listId,
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp,
            }).then(res => {
                if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                    for (let record of res.data.data.records) {
                        records.value.push(record)
                    }
                }
                page.value.currentPage = res.data.data.current
                page.value.pageSize = res.data.data.size
                page.value.total = res.data.data.total
            }).finally(() => loading.value = 0)
        }

        const nextPage = () => {
            if (page.value.total <= records.value.length) {
                return
            }
            loading.value = 2
            page.value.currentPage++
            getPage()
        }

        onMounted(() => {
            refreshPage()
        })

        return {
            ...toRefs(props),
            loading,
            records,
            page,
            nextPage
        }
    }
})
</script>
<style scoped>
.list-operation-record-main {
    min-height: inherit;
}
</style>
