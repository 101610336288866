import pathConst from '@/router/more/pathConst';

export default [
    {
        path: pathConst.CENTER_OVERVIEW,
        component: () => import('@/views/statistics/CenterOverview.vue'),
        name: 'CenterOverview',
        meta: {title: '中心概览'},
    },
    {
        path: pathConst.MARKET_STATISTICS,
        component: () => import('@/views/statistics/MarketStatistics.vue'),
        name: 'marketStatistics',
        meta: {title: '市场统计'},
    },

    {
        path: pathConst.CLASS_STATISTICS,
        component: () => import('@/views/statistics/ClassStatistics.vue'),
        name: 'classStatistics',
        meta: {title: '课程统计'},
    },
    {
        path: pathConst.SALES_STATISTICS,
        component: () => import('@/views/statistics/SalesStatistics.vue'),
        name: 'salesStatistics',
        meta: {title: '销售统计'},
    },
    {
        path: pathConst.FOLLOWUP_STATISTICIANS,
        component: () => import('@/views/statistics/FollowUpStatisticians.vue'),
        name: 'followUpStatisticians',
        meta: {title: '跟进统计'},
    },
    {
        path: pathConst.CONTRACT_STATISTICS,
        component: () => import('@/views/statistics/ContractStatistics.vue'),
        name: 'contractStatistics',
        meta: {title: '合同统计'},

    },
    {
        path: pathConst.CONTRACT_STATISTICS_DETAIL,
        component: () => import('@/views/statistics/ContractStatisticsDetail.vue'),
        name: 'contractStatisticsDetail',
        meta: {title: '合同统计-详情'}
    },
    {
        path: pathConst.CONTRACT_WATER_STATISTICS,
        component: () => import('@/views/statistics/ContractWaterStatistics.vue'),
        name: 'contractWaterStatistics',
        meta: {title: '流水统计'},
    },
    {
        path: pathConst.STUDENT_STATISTICS,
        component: () => import('@/views/statistics/StudentStatistics.vue'),
        name: 'studentStatistics',
        meta: {title: '会员统计'},
    },
    {
        path: pathConst.STUDENT_CLASS_STATISTICS,
        component: () => import('@/views/statistics/StudentClassStatistics.vue'),
        name: 'studentClassStatistics',
        meta: {title: '课位统计'},
    }
];
