<template>
<div>
    <el-dialog
        custom-modal
        width="400px"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        title="解冻合同"
        :close-on-click-modal="false"
        append-to-body>
        <el-form :model="formData" :rules="fromRules" ref="formRef" label-position="top" size="mini">
            <el-form-item prop="reason" label="原因">
                <el-input  :maxlength="250" show-word-limit  type="textarea" v-model="formData.reason" size="mini" placeholder=""></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="$emit('update:visible',false)">取 消</el-button>
            <el-button type="primary" @click="handleConfirm()" size="small">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import {computed, defineComponent, inject, ref} from 'vue'
import {freezeApi, unfreezeApi} from '../../../api/student/contractApi'

export default defineComponent({
    props: {
        visible: Boolean
    },
    emits: ['update:visible'],
    setup (props, {emit}) {

        const formRef = ref()

        const fromRules = ref({
            reason: [{required: true, message: '原因是必填的'}]
        })

        const formData = ref({
            reason: undefined
        })

        const confirmLoading = ref(false)

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate()
            .then(() => unfreezeApi({
                ...formData.value,
                contractId: data.value.id
            }))
            .then(() => {
                emit('update:visible', false)
                refreshWater()
                refreshContract()
            })
            .finally(() => {
                confirmLoading.value = false
            })
        }

        const refreshWater = inject('refreshWater')

        const refreshContract = inject('refreshContract')

        const getData = inject('getData')

        const data = computed(() => getData())

        return {
            formRef,
            fromRules,
            formData,
            data,
            confirmLoading,
            handleConfirm
        }
    }
})
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 !important;
    color: #3b3b3b !important;
}
</style>
