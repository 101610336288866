<template>
    <div class="item">
        <article>
            <user-avatar style="border-radius: 50%"
                         :src="data.tenantUserAvatar"
                         :gender="data.tenantUserGender">
            </user-avatar>
        </article>
        <div>
            <div class="top">
                <div class="left_info">
                    <p class="name">{{ data.tenantUserName }}</p>
                    <p class="time">{{ dayjs(data.createTime).fromNow() }}</p>
                </div>
                <p><span>#</span>{{ data.tagName }}</p>
            </div>
            <div class="content">
                <p class="title">{{ data.content }}</p>
                <div class="imgBox" v-if="data.motionMedias.length">
                    <img v-for="(it,ind) in data.motionMedias" :key="ind" :src="it.mediaUrls[0]" alt="动态图1">
                </div>
            </div>
            <div class="down" @click="openInp">
                <div class="hezi" style="cursor: pointer">
                    <img src="../../assets/img/message.png" alt="">
                    <p>评论（{{ data.replyCount || 0 }}）</p>
                </div>
                <div style="cursor: pointer">
                    <el-tooltip placement="top" effect="light" v-if="data.deleteFlag">
                        <template v-slot:content>
                            <div style="display: flex;flex-direction: column;gap: 8px;">
                                <div style="font-size: 14px;color: #292e33;">动态已删除</div>
                                <div style="font-size: 12px;color: #b4bfcc;">操作人：</div>
                                <div style="font-size: 12px;color: #b4bfcc;">时间：</div>
                            </div>
                        </template>
                        <span style="color: #f5222d;font-size: 12px;">
                            已删除
                        </span>
                    </el-tooltip>
                    <el-button type="text"
                               style="color: #818c99;font-size: 12px;margin: 0;"
                               @click.stop="handleDelete"
                               v-show="!data.deleteFlag">
                        删除
                    </el-button>
                    <el-button type="text"
                               style="color: #818c99;font-size: 12px;margin: 0;"
                               @click.stop="listVisible=true">
                        发送对象（{{ data.receiveUserCount }}）
                    </el-button>
                </div>
            </div>
            <div v-if="dat.showInp" style="padding-bottom: 10px;">
                <div class="inp">
                    <img src="../../assets/img/default_male.png" alt="">
                    <el-input :placeholder="placeholder" v-model="talkMessage" size="mini"
                              style="margin-right: 10px"></el-input>
                    <el-button type="primary" size="mini" @click="submit">评论</el-button>
                </div>
                <div class="msgList" v-for="(ms,msin) in talkData" :key="msin">
                    <div>
                        <img src="../../assets/img/default_male.png" alt="">
                    </div>
                    <div>
                        <div class="reply-info">
                            <span class="primary-text-color">{{ ms.userNickName }}</span>
                            <template v-if="ms.replyUserNickName">
                                <span> 回复 </span>
                                <span class="primary-text-color">{{ ms.replyUserNickName }}</span>
                            </template>
                            <span class="primary-text-color" style="margin-left: -5px;">:</span>
                            <span class="cont">{{ ms.content }}</span>
                        </div>
                        <div class="time-group">
                            <span>{{ showTime(ms.createDate) }}</span>
                            <div>
                                <el-button type="text"
                                           style="color: #292e33;font-size: 12px;margin: 0;">
                                    删除
                                </el-button>
                                <el-button type="text"
                                           style="color: #292e33;font-size: 12px;margin: 0;"
                                           @click="reply(ms)">
                                    回复
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <el-pagination
                    v-if="sumPage>1"
                    background
                    small
                    style="text-align: right"
                    layout="prev, pager, next"
                    @current-change="changeCurrentPage"
                    :current-page="page.currentPage"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <message-list
            :visible.sync="listVisible"
            :motion-unique-id="data.uniqueId">
        </message-list>
    </div>
</template>
<script>
import {defineComponent, getCurrentInstance, ref, toRefs} from "vue";
import {deleteMotionApi, getReplyMessageListApi, replyMessageApi} from "@/api/motion/message";
import {getDefaultPage} from "@/utils";
import MessageList from "@/components/MessageList/MessageList.vue";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import StudentAvatar from "@/components/Common/StudentAvatar.vue"; // 引入相对时间插件
import "dayjs/locale/zh-cn";
import UserAvatar from "@/components/Common/UserAvatar.vue";

dayjs.locale('zh-cn');
dayjs.extend(relativeTime); // 使用相对时间插件

export default defineComponent({
    methods: {dayjs},
    components: {UserAvatar, StudentAvatar, MessageList},
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    emits: ["delete"],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const talkMessage = ref();

        const dat = ref(props.data);

        const page = ref(getDefaultPage());

        const submit = () => {
            replyMessageApi(!replyUniqueId.value ? {
                content: talkMessage.value,
                motionUniqueId: props.data.uniqueId,
            } : {
                content: talkMessage.value,
                motionUniqueId: props.data.uniqueId,
                replyCommentUniqueId: replyUniqueId.value
            }).then(res => {
                //更新状态
                talkMessage.value = undefined;
                dat.value.showInp = false;
                getData();
            });
        };

        const getData = () => {
            getReplyMessageListApi(Object.assign({}, page.value, {
                timestamp: new Date().getTime(),
                motionUniqueId: props.data.uniqueId
            })).then(res => {
                sumPage.value = res.data.data.pages;
                total.value = res.data.data.total;
                talkData.value = res.data.data.records;
            });
        };

        const openInp = () => {
            dat.value.showInp = !dat.value.showInp;
            if (dat.value.showInp) {
                getData();
            }
        };

        const listVisible = ref(false);

        const handleDelete = () => {
            currentInstance.proxy.$confirm("确认要删除动态吗？", {
                type: "warning",
                showClose: false,
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        deleteMotionApi(props.data.uniqueId).then(() => {
                            emit('delete');
                            done();
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            });
        };

        const sumPage = ref(undefined);

        const total = ref(undefined);

        const talkData = ref([]);

        const showTime = (time) => {
            // 使用自定义的格式解析时间字符串
            const pastDate = dayjs(time, 'YYYY-MM-DD HH:mm:ss');
            const now = dayjs();
            const diff = now.diff(pastDate, 'second'); // 计算秒数差
            // 根据时间差返回中文相对时间描述
            if (diff < 60) {
                return '刚刚';
            } else if (diff < 3600) {
                return `${Math.floor(diff / 60)}分钟前`;
            } else if (diff < 86400) {
                return `${Math.floor(diff / 3600)}小时前`;
            } else {
                return `${Math.floor(diff / 86400)}天前`;
            }
        };

        const replyUniqueId = ref(undefined);

        const reply = (ms) => {
            replyUniqueId.value = ms.uniqueId;
            placeholder.value = '回复' + (ms.userNickName ? ms.userNickName : '');
        };

        const placeholder = ref('回复评论');

        const changeCurrentPage = (nowPage) => {
            page.value.currentPage = nowPage;
            getData();
        };

        return {
            ...toRefs(props),
            talkMessage,
            submit,
            handleDelete,
            openInp,
            dat,
            page,
            listVisible,
            sumPage,
            total,
            talkData,
            showTime,
            reply,
            placeholder,
            replyUniqueId,
            getData,
            changeCurrentPage,
        };
    }
});

</script>
<style scoped lang="scss">
.item {
    border-bottom: 1px solid #edf0f2;
    display: flex;
    padding: 10px;
    padding-bottom: 0;
    box-sizing: border-box;
    height: fit-content;
    min-height: 170px;

    article {
        flex: 0 0 72px;
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        height: 100%;

        > img {
            width: 40px;
            height: 40px;
        }
    }

    > div {
        padding: 0 10px;
        box-sizing: border-box;
        flex: 1;
        position: relative;

        .top {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .left_info {
                .name {
                    margin: 0;
                }

                .time {
                    color: #b4bfcc;
                    margin: 0;
                    margin-top: 5px;
                }
            }

            > p {
                margin: 0;
                color: #b4bfcc;
                border: 1px solid #b4bfcc;
                height: fit-content;
                border-radius: 10px;
                padding: 0 5px;
                box-sizing: border-box;

                span {
                    color: #28cb97;
                    margin-right: 5px;
                }
            }
        }

        .content {
            .title {
                display: block;
                margin: 5px 0;
            }

            .nr {
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin: 5px 0;
                overflow: hidden;
            }

            .imgBox {
                width: 440px;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 10px;

                img {
                    height: 100px;
                    // border: 1px solid #0f0;
                }
            }
        }

        .down {
            //position: absolute;
            left: 0;
            bottom: 0;
            margin-top: 20px;
            height: 45px;
            width: 100%;
            border-top: 1px solid #edf0f2;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .hezi {
                display: flex;
                align-items: center;

                > img {
                    width: 16px;
                    height: 14px;
                    margin-right: 5%;
                }

                p {
                    margin: 0;
                    color: #28cb97;
                    white-space: nowrap;
                }
            }

            > div {
                display: flex;
                align-items: center;

                span {
                    color: #b4bfcc;
                    cursor: pointer;
                }

                button:nth-of-type(2)::before {
                    content: '|';
                    margin: 0 10px;
                    position: relative;
                    top: -2px;
                }
            }
        }

        .inp {
            display: flex;
            align-items: center;
            background: #f5f7fa;
            height: 70px;
            padding: 0 20px;
            box-sizing: border-box;

            > img {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
    }
}

.msgList {
    width: 100%;
    display: flex;
    align-items: baseline;
    background: #f5f7fa;
    padding: 5px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #e5e5e5;

    > div:nth-of-type(1) {
        margin-right: 10px;

        img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
        }
    }

    > div:nth-of-type(2) {
        width: 100%;

        .reply-info {
            word-break: break-word;

            span {
                margin-right: 5px;
                white-space: nowrap;
            }

            .cont {
                color: #818c99;
                white-space: normal;
            }
        }

        .time-group {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            button + button::before {
                content: '|';
                margin: 0 5px;
            }

            > span {
                color: #b4bfcc;
            }

            span {
                cursor: pointer;
            }
        }
    }
}
</style>
