<template>
<consultant-select v-model="value" @change="handleChange">
    <slot :loading="loading"></slot>
</consultant-select>
</template>
<script>
import ConsultantSelect from './ConsultantSelect.vue'
import {allocateConsultantApi} from '../../../api/student/listApi'

export default {
    components: {ConsultantSelect},
    emits: ['ok'],
    props: {
        defaultValue: Number,
        listId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            value: undefined,
            loading: false
        }
    },
    methods: {
        handleChange (val) {
            if (val.id !== this.defaultValue) {
                this.loading = true
                allocateConsultantApi({
                    listId: this.listId,
                    consultantId: val.id
                }).then(() => {
                    this.$emit('ok', val)
                }).finally(() => this.loading = false)
            }
        }
    }
}
</script>
