<template>
    <header>
        <el-dialog
            title="提示"
            :visible="visible"
            @update:visible="val=>$emit('update:visible',val)"
            width="650px"
            append-to-body
            custom-class="appointer-dialog">
            <div class="top_header">
                <img @click="computeDay(-1)"
                     src="../../assets/img/left_green.png"
                     alt="">
                <text-date-picker v-model="currentDay">
                    <span>{{ currentDay.format('YYYY-MM-DD') }}{{ currentWeekDay }}</span>
                </text-date-picker>
                <img @click="computeDay(1)"
                     src="../../assets/img/right_green.png"
                     alt="">
            </div>
            <div class="filter">
                <el-select v-model="queryFormData.type" size="mini" style="width:22%;" clearable>
                    <el-option label="全部课程" :value="undefined"></el-option>
                    <el-option label="普通课" value="Normal"></el-option>
                    <el-option label="试听课" value="Audition"></el-option>
                    <el-option label="活动课" value="Maneuver"></el-option>
                    <el-option label="补课专用" value="Reschedule"></el-option>
                </el-select>
                <teacher-select use-teacher-id
                                v-model="queryFormData.teacherId"
                                size="mini"
                                style="width:22%;"
                                has-all-option>
                </teacher-select>
                <class-type-cascader v-model="queryFormData.classTypeIds"
                                     size="mini"
                                     style="width:44%;"
                                     clearable
                                     :props="{checkStrictly:true}">
                </class-type-cascader>
            </div>
            <dl v-infinite-scroll="getNextPage" v-loading="loading===1">
                <el-tooltip v-for="item in records"
                            :key="item.id"
                            :disabled="!item._disabled"
                            placement="top"
                            content="所选合同不适用此课节，适用课类不符合">
                    <dd :class="{selected:selected&&selected.id===item.id}"
                        :style="item._disabled?'cursor:not-allowed;opacity:.5;':`cursor:pointer;`"
                        @click="item._disabled?'':openArrangeClassModal(item)">
                        <div class="font">
                            <div>
                                <el-tooltip effect="dark" placement="top">
                                    <template v-slot:content>
                                        <div>
                                            <p>
                                                <span>{{ item.name }}</span>
                                                <span>/{{ (item.classTypeName ? item.classTypeName : '') + ' ' + (item.childrenClassTypeName ? item.childrenClassTypeName : '') }}</span>
                                            </p>
                                        </div>
                                    </template>
                                    <p>
                                        {{ item.name }}
                                        <span>
                                            ({{
                                                (item.classTypeName ? item.classTypeName : '') + ' ' + (item.childrenClassTypeName ? item.childrenClassTypeName : '')
                                            }})
                                        </span>
                                    </p>
                                </el-tooltip>
                                <div>
                                    {{ $store.getters['common/classTypeMap'][item.classType] }}
                                </div>
                            </div>
                            <div style="margin-bottom:5px">
                                <article>
                                    <span>排课开始日期：</span>
                                    <span>{{ item.startDate }}</span>
                                </article>
                                <article>
                                    <img src="../../assets/img/book.png" alt="">
                                    <span>{{ item.consumeCount }}</span>
                                </article>
                            </div>
                            <div>
                                <article>
                                    <img src="../../assets/img/time2.png" alt="">
                                    <span>{{ dayjs(item.startDate).format('HH:mm:ss') }}</span>
                                </article>
                                <article v-for="(teacherName,index)  in item.teacherNames" :key="index">
                                    <img src="../../assets/img/user.png" alt="">
                                    <span>{{ teacherName }}</span>
                                </article>
                                <article v-for="(classroomName,index)  in item.classroomNames" :key="index">
                                    <img src="../../assets/img/address.png" alt="">
                                    <span>{{ classroomName }}</span>
                                </article>
                                <article>
                                    <img src="../../assets/img/book.png" alt="">
                                    <span>{{ item.consumeCount }}</span>
                                </article>
                            </div>
                        </div>
                        <div style="font-size: 16px;" class="primary-text-color">
                            <span>{{ item.currentStudentCount }}</span>
                            <span>/</span>
                            <span>{{ item.maxStudentCount }}</span>
                        </div>
                    </dd>
                </el-tooltip>

                <div style="text-align: center;padding: 20px 0;" v-if="loading===2">正在加载中...</div>
                <div style="text-align: center;padding: 20px 0;" v-else-if="!hasMore">暂无更多课程</div>
            </dl>
            <div class="contract_info">
                <div v-loading="contractLoading">
                    <change-contract-button
                        :student-id="studentId"
                        click-mode="select"
                        :contract="{id:contract.id,name:contract.paperName?contract.paperName:contract.contractName}"
                        @change="val=>contract=val">
                        <template v-slot:contract>
                            <span>已选择合同:&nbsp;&nbsp;</span>
                            <el-tooltip effect="dark" content="" placement="top">
                                <template v-slot:content>
                                    <div>
                                        <p style="margin:0;">合同名称:
                                            {{ contract.paperName ? contract.paperName : contract.contractName }}</p>
                                        <p style="margin:3px 0 0 0;">合同编号:
                                            {{ contract.paperNo ? contract.paperNo : contract.contractCode }}</p>
                                    </div>
                                </template>
                                <span>{{ contract.paperName ? contract.paperName : contract.contractName }}</span>
                            </el-tooltip>
                        </template>
                    </change-contract-button>
                </div>
            </div>
        </el-dialog>

        <plan-class-modal
            v-bind="planClassObject"
            :visible.sync="planClassObject.planClassVisible"
            @ok="handlePlaned">
        </plan-class-modal>
    </header>
</template>

<script>
import {getClassListApi} from '../../api/class/classApi';
import {getContractDetailApi} from '../../api/student/contractApi';
import TeacherSelect from '../Form/TeacherSelect.vue';
import {computed, defineComponent, getCurrentInstance, reactive, ref, toRefs, watch} from 'vue';
import dayjs from 'dayjs';
import {getDefaultPage} from '../../utils';
import ClassTypeCascader from '../Form/ClassTypeCascader.vue';
import PlanClassModal from '../Class/detail/PlanClassModal.vue';
import ChangeContractButton from '../Class/detail/ChangeContractButton.vue';
import isDisable from '../../utils/isDisable';
import TextDatePicker from "@/components/Form/TextDatePicker.vue";

export default defineComponent({
    components: {
        TextDatePicker,
        ChangeContractButton,
        PlanClassModal,
        ClassTypeCascader,
        TeacherSelect,
    },
    props: {
        appointType: {
            type: Number,
            default: 2
        },
        defaultContractId: Number,
        studentId: Number,
        visible: Boolean,
        suitClassCommonType: Array,
        suitClassSpecialType: Array,
    },
    emits: ['update:visible', 'ok'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const page = ref(getDefaultPage());

        const selected = ref();

        const queryFormData = ref({
            type: undefined,
            teacherId: undefined,
            classTypeIds: []
        });

        const currentDay = ref(dayjs());

        const loading = ref(0);

        const contractLoading = ref(false);

        const records = ref([]);

        const isFree = ref(false);

        const checkContractBoolean = ref(false);

        const courseClassBoolean = ref(false);

        const contract = ref({});

        const confirmLoading = ref(false);

        const planClassObject = reactive({
            consumeCount: null,
            studentId: null,
            planClassVisible: false,
            classId: null,
            defaultContractId: null,
        });

        const refreshClassPage = () => {
            page.value = getDefaultPage();
            loading.value = 1;
            records.value = [];
            getClassPage();
        };

        const getContractDetail = () => {
            contractLoading.value = true;
            getContractDetailApi(props.defaultContractId).then(res => {
                contract.value = res.data.data;
            }).finally(() => contractLoading.value = false);
        };

        const getClassPage = () => {
            getClassListApi({
                startDate: currentDay.value.format('YYYY-MM-DD') + ' 00:00:00.000',
                endDate: currentDay.value.format('YYYY-MM-DD') + ' 23:59:59.999',
                type: queryFormData.value.type,
                teacherId: queryFormData.value.teacherId,
                classTypeId: Array.isArray(queryFormData.value.classTypeIds) && queryFormData.value.classTypeIds.length ? queryFormData.value.classTypeIds[0] : undefined,
                childrenClassTypeId: Array.isArray(queryFormData.value.classTypeIds) && queryFormData.value.classTypeIds.length ? queryFormData.value.classTypeIds[1] : undefined,
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp
            }).then(res => {
                page.value.currentPage = res.data.current;
                page.value.pageSize = res.data.size;
                page.value.total = res.data.total;
                if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                    for (let item of res.data.data.records) {
                        item._disaled = isDisable(
                            item,
                            contract.allowOrdinaryClassList,
                            contract.allowSpecialClassList,
                            contract.ordinaryClassAllowWeekdays,
                            contract.specialClassAllowWeekdays
                        );
                        records.value.push(item);
                    }
                }
            }).finally(() => loading.value = 0);
        };

        const getNextPage = () => {
            if (hasMore.value) {
                page.value.currentPage++;
                loading.value = 2;
                getClassPage();
            }
        };

        const computeDay = (val) => {
            currentDay.value = currentDay.value.add(val, 'day');
        };

        const openArrangeClassModal = (classData) => {
            planClassObject.classId = classData.id;
            planClassObject.consumeCount = classData.consumeCount;
            planClassObject.studentId = props.studentId;
            planClassObject.defaultContractId = props.defaultContractId;
            planClassObject.planClassVisible = true;
        };

        const handlePlaned = () => {
            currentInstance.proxy.$notify({type: 'success', message: '排课成功'});
        };

        const hasMore = computed(() => {
            return page.value.total > records.value.length;
        });

        const currentWeekDay = computed(() => {
            let weekday = '';
            let numberWeekDay = currentDay.value.day();
            switch (numberWeekDay) {
                case 0:
                    weekday = '天';
                    break;
                case 1:
                    weekday = '一';
                    break;
                case 2:
                    weekday = '二';
                    break;
                case 3:
                    weekday = '三';
                    break;
                case 4:
                    weekday = '四';
                    break;
                case 5:
                    weekday = '五';
                    break;
                case 6:
                    weekday = '六';
                    break;
                default:
            }
            return '星期' + weekday;
        });

        watch(() => props.visible, newVal => {
            if (newVal) {
                refreshClassPage();
                if (props.defaultContractId) {
                    getContractDetail();
                }
            } else {
                selected.value = undefined;
            }
        });

        watch(currentDay, () => {
            refreshClassPage();
        });

        watch(queryFormData, () => {
            refreshClassPage();
        }, {deep: true});

        return {
            ...toRefs(props),
            currentDay,
            queryFormData,
            loading,
            contractLoading,
            contract,
            selected,
            isFree,
            records,
            hasMore,
            currentWeekDay,
            checkContractBoolean,
            courseClassBoolean,
            confirmLoading,
            getNextPage,
            computeDay,
            dayjs,
            openArrangeClassModal,
            planClassObject,
            handlePlaned,
            isDisable,
        };
    }
});
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

.top_header {
    width: 300px;
    margin: 0 auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
}

.filter {
    display: flex;
    align-items: center;
    height: 55px;
    // border: 1px solid #f00;
    justify-content: space-around;
    padding: 0 35px;
    box-sizing: border-box;
}

dl {
    padding: 0;
    margin: 0;
    height: 310px;
    overflow-y: auto;
    background: #eaeaea;

    dd.selected {
        color: $__color-primary;
        border-right: 5px solid $__color-primary;
        background-color: #f5f5f5;
        width: 100%;
    }

    dd {
        cursor: pointer;
        width: calc(100% - 5px);
        margin: 0;
        padding: 0 45px 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-bottom: 1px solid #ccc;

        section {
            position: absolute;
            cursor: no-drop;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;

            span {
                height: 25px;
                width: 100%;
                // border: 1px solid #f00;
            }
        }

        .font {
            height: 100%;
            padding-top: 3px;
            box-sizing: border-box;

            > div {
                display: flex;
                align-items: center;

                p {
                    margin: 0;
                    font-size: 16px;

                    span {
                        font-size: 12px;
                    }
                }

                > div {
                    padding: 0 8px;
                    box-sizing: border-box;
                    background: #28cb97;
                    color: #fff;
                    border-radius: 10px;
                    font-size: 12px;
                    margin-left: 10px;
                }

                article + article {
                    margin-left: 20px;
                }

                article {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 12px;
                        height: 12px;
                        margin-right: 3px;
                    }

                    span {
                        font-size: 12px;
                    }
                }
            }
        }

        > p {
            color: #28cb97;
            margin: 0;
        }
    }
}

.contract_info {
    height: 55px;
    box-sizing: border-box;
    position: relative;
    padding: 5px 40px 0;

    article {
        position: absolute;
        right: 30px;
        top: 3px;
        display: flex;
        align-items: center;

        span {
            font-size: 12px;
            color: #292e33;
            margin-left: 4px;
        }
    }

    div {
        display: flex;
        align-items: center;

        p {
            margin: 0;
            color: #28cb97;
            cursor: pointer;
        }

        span {
            font-size: 12px;
            color: #292e33;
        }
    }
}

.operation-buttons {
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: right;
}
</style>
<style lang="scss">
.appointer-dialog {
    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        padding: 10px 0;
        box-sizing: border-box;
    }
}
</style>
