<template>
    <div>
        <ul v-loading="loading===1">
            <template v-if="records.length">
                <li :class="['operating-record',{last:index===records.length-1}]"
                    v-for="(item,index) in records"
                    :key="item.id">
                    <component v-if="waterTypeMap[item.type]&&waterTypeMap[item.type].component"
                               :is="waterTypeMap[item.type].component"
                               :water="item">
                    </component>
                    <div v-else>当前版本不支持展示此类型流水！</div>
                </li>
            </template>
        </ul>
        <div v-if="loading===2">正在加载中...</div>
        <div v-else-if="!records.length" class="operating-record">暂无流水</div>
    </div>
</template>
<script>
import {waterTypeMap} from "@/components/Contract/detail/config";
import {defineComponent, provide, toRefs} from "vue";

export default defineComponent({
    props: {records: Array, loading: Number},
    setup(props) {

        let approveHooks = [];

        let onConfirmCollectionHooks = [];

        provide('callApproveHook', () => {
            if (approveHooks.length) {
                for (let approveHook of approveHooks) {
                    typeof approveHook === 'function' && approveHook();
                }
                approveHooks = [];
            }
        });

        provide('callConfirmCollectionHook', () => {
            if (onConfirmCollectionHooks.length) {
                for (let onConfirmCollectionHook of onConfirmCollectionHooks) {
                    typeof onConfirmCollectionHook === 'function' && onConfirmCollectionHook();
                }
                onConfirmCollectionHooks = [];
            }
        });

        provide('onApprove', (hook) => {
            approveHooks.push(hook);
        });

        provide('onConfirmCollection', (hook) => {
            onConfirmCollectionHooks.push(hook);
        });

        return {
            ...toRefs(props),
            waterTypeMap
        };
    }
});
</script>
<style scoped>
.operating-record {
    position: relative;
    margin-left: 25px;
    height: auto;
    box-sizing: border-box;
}

.operating-record .confirm_income::after {
    top: 0 !important;
}

ul > .operating-record:not(.last)::before {
    content: "";
    position: absolute;
    top: 3px;
    left: -22px;
    width: 2px;
    background: #edf0f2;
    height: 100%;
}

.operating-record::after {
    content: "";
    position: absolute;
    left: -25px;
    top: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #edf0f2;
    display: block;
}

</style>