<template>
    <el-select v-bind="$attrs"
               filterable
               remote
               :multiple="multiple"
               placeholder="请选择"
               :remote-method="remoteMethod"
               :loading="selectLoading"
               :value="value"
               @input="val=>$emit('update:value',val)"
               @change="handleChange">
        <el-option v-if="hasAllOption" label="全部" :value="undefined"></el-option>
        <slot :options="options" :valueProp="valueProp">
            <el-option v-for="option in options"
                       :key="option[valueProp]"
                       :value="option[valueProp]"
                       :label="option.name">
            </el-option>
        </slot>
    </el-select>
</template>
<script>
import {computed, defineComponent, getCurrentInstance, onMounted, ref, toRefs, watch, watchEffect} from 'vue';
import {isEqual} from '../../utils/ObjectUtils';
import {getTenantUserPageApi} from '../../api/system/tenantUserApi';

export default defineComponent({
    model: {
        prop: 'value',
        event: 'update:value'
    },
    emits: ['update:value', 'change'],
    props: {
        hasAllOption: Boolean,
        value: [Number, Array],
        multiple: {
            type: Boolean,
            default() {
                return false;
            }
        },
        role: [Array, Number],
        valueProp: {
            type: String,
            default() {
                return 'id';
            }
        },
    },

    setup(props, {emit}) {
        const currentInstance = getCurrentInstance();

        const selectValue = ref();

        const selectLoading = ref(true);

        const defaultOptions = ref([]);

        const searchOptions = ref();

        let searchParams = undefined;

        const remoteMethod = (val) => {
            if (selectLoading.value) {
                return;
            }
            if (val) {
                selectLoading.value = true;
                const currentSearchParams = searchParams = {
                    name: val,
                    timestamp: new Date().getTime(),
                    roles: roles.value
                };
                getTenantUserPageApi(searchParams)
                    .then(res => {
                        if (isEqual(searchParams, currentSearchParams)) {
                            searchOptions.value = res.data.data;
                        }
                    }).finally(() => selectLoading.value = false);
            } else {
                searchOptions.value = undefined;
            }
        };

        const handleChange = (val) => {
            if (props.multiple) {
                if (Array.isArray(val) && val.length) {
                    emit('change', val, val.map(item => options.value.find(record => record[props.valueProp] === item)));
                } else {
                    emit('change', val, [], []);
                }
            } else {
                if (val) {
                    emit('change', val, options.value.find(record =>  record[props.valueProp] === val));
                } else {
                    emit('change', undefined, undefined);
                }
            }
        };

        onMounted(() => {
            getTenantUserPageApi({
                timestamp: new Date().getTime(),
                pageSize: 100,
                roles: roles.value
            }).then(res => {
                defaultOptions.value = res.data.data.records;
            }).finally(() => selectLoading.value = false);
        });

        const roles = computed(() => {
            if (Array.isArray(props.role)) {
                return props.role.filter(r => currentInstance.proxy.$store.getters['common/fixedRoles'].find(fr => fr.value === r));
            } else if (typeof props.role === 'string') {
                try {
                    return currentInstance.proxy.$store.getters['common/fixedRoles'].filter(fr => fr.value.indexOf(props.role) !== -1);
                } catch (e) {
                }
            }
            return [];
        });

        const options = computed(() => {
            if (selectLoading.value) {
                return [];
            }
            if (searchOptions.value === undefined) {
                return defaultOptions.value;
            } else {
                return searchOptions.value.records;
            }
        });

        return {
            ...toRefs(props),
            selectValue,
            selectLoading,
            options,
            remoteMethod,
            handleChange
        };
    }
});
</script>
