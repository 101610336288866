<template>
    <el-input-number controls-position="right"
                     left
                     :value="value"
                     :precision="precision"
                     :min="min"
                     v-on="$listeners"
                     @input="val=>$emit('update:value',val)"
                     @change="val=>$emit('change',val)"
                     style="width: 100%"
                     v-bind="$attrs">
    </el-input-number>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
    props: {
        value: Number,
        precision: {
            type: Number,
            default() {
                return 0;
            }
        },
        min: {
            type: Number,
            default() {
                return 0;
            }
        }
    },
    emits: ['update:value', 'change'],
    model: {
        value: 'value',
        event: 'update:value'
    },
});
</script>