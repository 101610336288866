<script>
import {defineComponent, getCurrentInstance, onMounted, ref, toRefs} from "vue";
import {getClassDetailApi} from "../../../api/class/classApi";
import StudentAvatar from "../../Common/StudentAvatar.vue";
import dayjs from "dayjs";
import GenderView from "../../Common/GenderView.vue";
import {sentStudentPhotoLibraryApi} from "../../../api/class/classPhoto";

export default defineComponent({
    components: {GenderView, StudentAvatar},
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        closeClassPhotoLibraryCheckStudent: {
            type: Function,
            default: () => {},
        },
        classId: {
            type: Number,
            default: undefined
        },
        checkPhoto:{
            type:Array,
            default:()=>[]
        },
    },
    setup(props, {emit}) {
        const proxy = getCurrentInstance().proxy;

        let selectStudentArray = ref([]);
        const handleSelectionChange = (val) => {
            selectStudentArray.value = val;
        }
        const tableData = ref([]);
        const loadData = ref(false);

        let getClassDetail = () => {
            if (props.classId) {
                loadData.value = true;
                getClassDetailApi(props.classId).then(res => {
                    tableData.value = Array.isArray(res.data.data.students) ? res.data.data.students.filter(studentInfo => studentInfo.status === 'SignedIn'||studentInfo.status === 'SignedInLateness') : []
                }).finally(() => {
                    loadData.value = false;
                })
            }
        }
        onMounted(() => {
            getClassDetail();
        })
        let showAge = (birthday) => {
            if (!birthday) {
                return '未知'
            }
            // 将生日格式化为 dayjs 对象
            let birthDate = dayjs(birthday);
            let currentDate = dayjs();

            // 计算完整的年龄
            let age = currentDate.year() - birthDate.year();

            // 检查是否已经过了今年的生日
            if (currentDate.month() < birthDate.month() ||
                (currentDate.month() === birthDate.month() && currentDate.date() < birthDate.date())) {
                age -= 1; // 如果还没过生日，年龄减一
            }

            // 计算当前月份与生日月份的差值
            let monthDiff = currentDate.month() - birthDate.month();

            // 如果月份差为负，表示当前月份未到生日月份，需要加上12个月
            if (monthDiff < 0) {
                monthDiff += 12;
            }

            // 计算完整的月数
            let fullMonths = age * 12 + monthDiff;

            // 返回格式化的年龄和月份
            return `${age}岁${fullMonths % 12}个月`;
        };
        let showRelation = (relation) => {
            return  proxy.$store.state.common.relationList.find(it=>it.value===relation).label;
        }

        let submitLoading = ref(false);
        let submit = () => {
            if(!props.checkPhoto.length||!selectStudentArray.value.length){
                return;
            }
            submitLoading.value = true;
            let photoIds = props.checkPhoto.map(it=>it.id);
            let studentIds = selectStudentArray.value.map(it=>it.studentId);
            sentStudentPhotoLibraryApi({
                classId:props.classId,
                photoIds,
                studentIds,
            }).then(res=>{
                proxy.$notify({
                    title: '成功',
                    message: '发送成功',
                    type: 'success'
                })
                selectStudentArray.value = [];
                props.closeClassPhotoLibraryCheckStudent();
            }).finally(()=>{
                submitLoading.value = false;
            });
        }
        return {
            ...toRefs(props),
            handleSelectionChange,
            tableData,
            loadData,
            getClassDetail,
            showAge,
            showRelation,
            submit,
        };
    },
});
</script>

<template>
    <el-dialog
        title="选择学员"
        :visible.sync="visible"
        width="900ox"
        :before-close="closeClassPhotoLibraryCheckStudent"
        append-to-body
        custom-class="checkStudentDialogClass"
    >
        <div class="title">以下为所选照片关联课节的已签到学员</div>
        <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            v-loading="loadData"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#f7f7f7' }"
        >
            <el-table-column type="selection" align="center" width="125px"></el-table-column>
            <el-table-column prop="classStartTime" label="学员信息">
                <template v-slot="scope">
                    <div class="studentInfo">
                        <student-avatar
                            style="width: 50px;height: 50px;border-radius: 50%"
                            :gender="scope.row.gender"
                            :src="scope.row.avatar"
                        ></student-avatar>
                        <div>
                            <span>{{scope.row.name}}
                                <gender-view :gender="scope.row.gender"></gender-view>
                            </span>
                            <span>{{showAge(scope.row.birthdayTime)}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="联系人信息">
                <template v-slot="scope">
                    <div class="contactInfo">
                        <div>
                            <span>{{scope.row.mainContactName}}</span>
                            <span>{{showRelation(scope.row.mainContactAffiliation)}}</span>
                        </div>
                        <div>
                            <span>{{scope.row.mainContactPhoneNumberArea}}</span>
                            <span>-{{scope.row.mainContactPhoneNumber}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div slot="footer">
            <el-button size="mini" @click="closeClassPhotoLibraryCheckStudent">取 消</el-button>
            <el-button size="mini" v-loading="submitLoading" type="primary" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
</template>

<style scoped lang="scss">
.title {
    margin: 10px 0 20px 0;
}
.studentInfo{
    display: flex;
    align-items: center;
    >div{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
    }
}
.contactInfo{
    >div{
        display: flex;
        align-items: center;
        span{
            margin: 0;
            color: #292e33;
        }
        &:nth-of-type(2){
            span{
                color: #818c99;
            }
        }
    }
}
</style>
<style lang="scss">
.checkStudentDialogClass {
    .el-dialog__body {
        padding: 0 10px 40px;
        box-sizing: border-box;
        border-top: 1px solid #e5e6e7;
        border-bottom: 1px solid #e5e6e7;
    }

    .el-dialog__footer {
        padding: 10px;
        box-sizing: border-box;
    }
}
</style>
