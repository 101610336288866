<template>
<el-dialog title="合同设置"
           custom-modal
           append-to-body
           width="900px"
           :visible="visible"
           @update:visible="val=>$emit('update:visible',val)">
    <div style="margin-bottom: 10px;">
        <span>适用合同</span>
        <el-tooltip content="学员只能使用适用合同进行耗课" placement="top">
            <i style="cursor: pointer;color: #b4bfcc;" class="el-icon-question"></i>
        </el-tooltip>
    </div>
    <el-table v-loading="loading"
              :data="records"
              :cell-style="{fontSize:'12px'}"
              :header-cell-style="{backgroundColor:'#f7f7f7'}"
              @selection-change="handleSelectionChange">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="合同编号" prop="paperNo"></el-table-column>
        <el-table-column label="套餐名称" prop="paperName"></el-table-column>
        <el-table-column label="套餐类型">
            <template v-slot="{row}">
                <div>{{ row.packageType === 1 ? '次卡' : (row.packageType === 2 ? '年卡' : '') }}</div>
            </template>
        </el-table-column>
        <el-table-column label="合同状态">
            <template v-slot="{row}">
                <div>{{ $store.getters['common/contractStateMap'][row.state] }}</div>
            </template>
        </el-table-column>
        <el-table-column label="操作">
            <template v-slot="{row}">
                <div>
                    <el-button type="primary"
                               size="mini"
                               v-if="defaultContractId===row.id">
                        已默认
                    </el-button>
                    <el-button type="primary"
                               plain
                               size="mini"
                               :disabled="selected.indexOf(row.id)===-1"
                               @click="defaultContractId=row.id"
                               v-else>
                        设为默认
                    </el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
    <div style="color: #f5222d;margin-bottom: 20px;margin-top: 10px;font-size: 12px;">
        注：默认选中“适用学员”设置为全部学员的合同且不可取消；选中的合同才可设置为默认合同
    </div>
    <el-pagination
        v-if="page.total>records.length+page.currentPage*(page.pageSize-1)"
        style="text-align: right;margin-top:20px;"
        background
        v-bind="page"
        layout="total,prev, pager, next, sizes ,jumper"
        :page-sizes="[10,20,50,100]"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle">
    </el-pagination>
    <template v-slot:footer>
        <el-button @click="$emit('update:visible',false)">取消</el-button>
        <el-button @click="handleConfirm"
                   :loading="confirmLoading"
                   :disabled="!selected.length||!defaultContractId"
                   type="primary">
            确认
        </el-button>
    </template>
</el-dialog>
</template>
<script>
import {defineComponent, ref, toRefs, watch} from 'vue'
import {getDefaultPage} from '../../../utils'
import {getContractSimplePageApi} from '../../../api/student/contractApi'
import {approvalPassedAddFamilyMemberApi} from '../../../api/student/studentApi'

export default defineComponent({
    props: {visible: Boolean, listId: Number, studentId: Number},
    emits: ['update:visible', 'ok'],
    setup (props, {emit}) {

        const page = ref(getDefaultPage())

        const loading = ref(false)

        const confirmLoading = ref(false)

        const records = ref([])

        const defaultContractId = ref()

        const selected = ref([])

        const refreshContractPage = () => {
            page.value = getDefaultPage()
            getContractPage()
        }

        const getContractPage = () => {
            loading.value = true
            getContractSimplePageApi({
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp,
                listId: props.listId
            }).then(res => {
                records.value = res.data.data.records
                page.value.currentPage = res.data.data.current
                page.value.pageSize = res.data.data.size
                page.value.total = res.data.data.total
            }).finally(() => loading.value = false)
        }

        const sizeChangeHandle = (val) => {
            page.value.pageSize = val
            page.value.currentPage = 1
            getContractPage()
        }
        const currentChangeHandle = (val) => {
            page.currentPage = val
            getContractPage()
        }

        const handleSelectionChange = (rows) => {
            selected.value = rows.map(row => row.id)
            if (defaultContractId.value) {
                if (selected.value.indexOf(defaultContractId.value) === -1) {
                    defaultContractId.value = undefined
                }
            }
        }

        const handleConfirm = () => {
            confirmLoading.value = true
            approvalPassedAddFamilyMemberApi({
                studentId: props.studentId,
                contractIds: selected.value,
                defaultContractId: defaultContractId.value
            }).then(() => {
                emit('update:visible', false)
                emit('ok')
            }).finally(() => confirmLoading.value = false)
        }

        watch(() => props.visible, newVal => {
            if (newVal) {
                refreshContractPage()
            } else {
                defaultContractId.value = undefined
            }
        })

        return {
            ...toRefs(props),
            page,
            loading,
            confirmLoading,
            records,
            selected,
            defaultContractId,
            sizeChangeHandle,
            currentChangeHandle,
            handleSelectionChange,
            handleConfirm
        }
    }
})
</script>
