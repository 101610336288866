<template>
<el-dialog
    title="编辑"
    :visible.sync="visible"
    @update:visible="val=>$emit('update:visible',val)"
    width="800px"
    custom-class="operationRecord-dialog"
    class="operationRecord"
    append-to-body>
    <div class="tab">
        <p :class="type===1?'on':''" @click="changeType(1)">课位历史记录</p>
        <p :class="type===2?'on':''" @click="changeType(2)">微信消息推送记录</p>
    </div>
    <template v-if="type===1">
        <div class="time_line" ref="content" v-loading="loading===1">
            <div class="line" v-for="(operationRecord, index) in operationRecords" :key="index">
                <p>{{ operationRecord.createDate }}</p>
                <article>
                    <div></div>
                    <span></span>
                </article>
                <el-card class="time_info">
                    <span class="primary-text-color">【{{ operationRecord.createUserName }}】</span>
                    <span v-html="operationRecord.content"></span>
                </el-card>
            </div>
        </div>
    </template>
    <div v-if="type===2" class="table">
        <el-table
            :data="notificationTableData"
            style="width: 100%"
            :header-cell-style="{background:'#f7f7f7'}">
            <el-table-column
                prop="date"
                label="记录时间"
                width="100">
            </el-table-column>
            <el-table-column
                prop="name"
                label="联系人姓名"
                width="90">
            </el-table-column>
            <el-table-column
                prop="tel"
                label="手机号"
                width="120">
            </el-table-column>
            <el-table-column
                prop="link"
                label="关系">
            </el-table-column>
            <el-table-column
                prop="message"
                label="推送消息"
                width="150">
            </el-table-column>
            <el-table-column
                prop="status"
                label="推送状态">
            </el-table-column>
            <el-table-column
                prop="status"
                label="操作">
                <template slot-scope="scope">
                    <div>
                        <p style="color:#29cb97;cursor:pointer;">重新推送</p>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</el-dialog>
</template>
<script>
import {getClassOperationRecordListApi, getStudentClassOperationRecordPageApi} from '../../../api/class/classApi'
import {formatDefaultTemplate} from '../../../utils/StringUtils'
import {defineComponent, ref, toRefs, watch} from 'vue'
import {getDefaultPage} from '../../../utils'

export default defineComponent({
    props: {
        visible: Boolean,
        studentClassId: Number
    },
    emits: ['update:visible'],
    setup (props, {emit}) {

        const type = ref(1)

        const page = ref()

        const content = ref()

        const operationRecords = ref([])

        const loading = ref(0)

        const notificationTableData = ref([
            {
                date: '2016-05-02',
                name: '王小虎',
                tel: '15112641087',
                link: '其他',
                message: '预约上课成功通知',
                status: 2,
            },
        ])

        const refreshOperationRecordPage = () => {
            loading.value = 1
            page.value = getDefaultPage()
            operationRecords.value = []
            getOperationRecordPage()
        }

        const getOperationRecordPage = () => {
            getStudentClassOperationRecordPageApi({
                id: props.studentClassId,
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp,
            }).then(res => {
                if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                    res.data.data.records.forEach(operationRecord => {
                        operationRecord.content = formatDefaultTemplate(operationRecord.content, (val) => {
                            return `<span class="primary-text-color">${val}</span>`
                        }, operationRecord.bindValues)
                        operationRecords.value.push(operationRecord)
                    })
                }
                content.value.onscroll = loadMore
            }).finally(() => loading.value = 0)
        }

        const changeType = (type) => {
            if (type === 1) {
                this.getDataList()
            }
            this.type = type
        }

        const loadMore = (e) => {
            //计算是否滚动到底部
            if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
                //滚动到底部 加载更多
                loading.value = 1
                page.value.currentPage += 1
                getOperationRecordPage()
            }
        }

        watch(() => props.visible, newVal => {
            if (newVal) {
                refreshOperationRecordPage()
            }
        })

        return {
            ...toRefs(props),
            type,
            page,
            operationRecords,
            loading,
            changeType,
            loadMore,
            notificationTableData,
            content
        }
    }
})
</script>

<style scoped>
.time_info >>> .el-card__body {
    padding: 10px;
    line-height: 16px;
    font-size: 12px;
}

.time_line >>> .el-timeline-item__node--normal {
    bottom: 38px;
}

.operationRecord >>> .el-dialog {
    /* margin-top: 25% !important; */
}
</style>
<style lang="scss" scoped>
.tab {
    height: 45px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;

    p {
        cursor: pointer;
        font-size: 16px;
        color: #818c99;
        height: 100%;
        line-height: 45px;

        &.on {
            color: #29cb97;
            border-bottom: 1px solid #29cb97;
        }

        & + p {
            margin-left: 10px;
        }
    }
}

.time_line {
    padding: 30px 30px 20px;
    box-sizing: border-box;
    overflow-y: auto;
    height: fit-content;
    max-height: 600px;

    .line {
        display: flex;
        align-items: flex-end;
        height: 55px;

        &:nth-of-type(1) {
            article {
                div {
                    height: 40px;
                }
            }
        }

        > p {
            margin: 0;
            font-size: 12px;
            color: #292e33;
            line-height: 38px;
        }

        article {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            top: -10px;
            margin: 0 10px;

            div {
                width: 2px;
                // height: 20px;
                background: #cccccc;
                height: 55px;
            }

            span {
                width: 8px;
                height: 8px;
                background: #ccc;
                border-radius: 50%;
                position: relative;
                top: -3px;
            }
        }

        .cont {
            p {
                margin: 0;
                font-size: 12px;
                color: #292e33;

                em {
                    font-style: normal;
                    color: #29cb97;
                }
            }
        }
    }
}

.time_info {
    position: relative;
    overflow: visible;
    z-index: 10000;
    border: none;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .1));
    background: #fff;

    &::before {
        content: '';
        position: absolute;
        border: 5px solid #ffffff;
        height: 0;
        overflow: hidden;
        border-bottom: 5px solid transparent;
        border-right: 5px solid transparent;
        left: -4px;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .1));
    }
}

.table {
    padding: 0 10px;
    box-sizing: border-box;

    p {
        font-size: 12px;
        white-space: nowrap;
    }
}
</style>
<style lang="scss">
.operationRecord-dialog {
    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #ccc;
        padding-top: 0;
        padding-bottom: 0;
        color: #292e33;
        align-items: center;
        display: none;

        span {
            font-size: 14px;
        }

        i::before {
            position: relative;
            top: -8px;
        }
    }

    .el-dialog__body {
        padding: 0;
        box-sizing: border-box;
    }
}
</style>
