<template>
    <el-dialog custom-modal
               width="30%"
               title="编辑学员"
               :visible="visible"
               :close-on-click-modal="false"
               append-to-body
               @update:visible="val=>$emit('update:visible',val)">
        <el-form :model="formData"
                 :rules="formRules"
                 ref="formRef"
                 size="mini"
                 inline
                 label-position="top">
            <div style="display: flex;justify-content: space-between;">
                <el-form-item style="width: 48%;margin: 0;" label="学员名字：">
                    <el-input v-model="updateFormData.name" :maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <el-form-item style="width: 48%;margin: 0;" label="学员昵称：">
                    <el-input v-model="updateFormData.nickname" :maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <el-form-item style="width: 48%;margin: 0;" label="生日：">
                    <el-date-picker v-model="updateFormData.birthdayTime"
                                    value-format="yyyy-MM-dd"
                                    style="width: 100%">
                    </el-date-picker>
                </el-form-item>
                <el-form-item style="width: 48%;margin: 0;" label="性别：">
                    <gender-select style="width: 100%;" v-model="updateFormData.sex"></gender-select>
                </el-form-item>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <el-form-item style="width: 48%;margin: 0;" label="就读学校：">
                    <el-input v-model="updateFormData.school" :maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <el-form-item style="width: 48%;margin: 0;" label="就读年级：">
                    <el-input v-model="updateFormData.grade" :maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div style="text-align: right;" v-if="allowDelete">
            <el-button type="text" size="mini" @click="handleDelete">删除学员</el-button>
        </div>
        <div slot="footer">
            <el-button @click="$emit('update:visible',false)">取消</el-button>
            <el-button type="primary" @click="handleConfirm" :loading="confirmLoading">确认</el-button>
        </div>
    </el-dialog>
</template>
<script>
import {defineComponent, getCurrentInstance, ref, toRef, watch} from 'vue';
import {updateStudentApi, deleteStudentApi} from '@/api/student/listApi';
import GenderSelect from '@/components/Form/GenderSelect.vue';

export default defineComponent({
    components: {GenderSelect},
    emits: ['update:visible', 'ok,', 'delete'],
    props: {
        formData: {
            type: Object,
            default() {
                return {};
            }
        },
        visible: Boolean,
        allowDelete: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const formRules = ref({
            name: [{required: true, message: '姓名是必填的'}],
            birthdayTime: [{required: true, message: '生日是必填的'}],
            sex: [{required: true, message: '请选择性别'}]
        });
        const formRef = ref();

        const updateFormData = ref({
            name: undefined,
            nickname: undefined,
            birthdayTime: undefined,
            sex: undefined,
            school: undefined,
            grade: undefined,
        });

        const confirmLoading = ref(false);

        const handleConfirm = () => {
            confirmLoading.value = true;
            formRef.value.validate()
                .then(() => updateStudentApi({
                    ...updateFormData.value,
                    birthdayTime: updateFormData.value.birthdayTime + ' 00:00:00'
                }))
                .then(() => {
                    emit('update:visible', false);
                    emit('ok', updateFormData.value);
                })
                .finally(() => confirmLoading.value = false);
        };

        const handleDelete = () => {
            currentInstance.proxy.$confirm('删除学员将清空该学员名下所有记录，且不能恢复，请再次输入需要删除的学员名字', {
                showClose: false,
                showInput: true,
                customClass: 'confirm-delete-student',
                cancelButtonClass: 'confirm-delete-student-cancel',
                confirmButtonClass: 'confirm-delete-student-confirm',
                beforeClose: (action, box, done) => {
                    if (action === 'confirm') {
                        const value = box.$refs.input.$el.querySelector('input').value;
                        if (value === updateFormData.value.name) {
                            box.confirmButtonLoading = true;
                            deleteStudentApi(updateFormData.value.id).then(() => {
                                emit('update:visible', false);
                                emit('delete');
                                done();
                            }).finally(() => box.confirmButtonLoading = false);
                        } else {
                            currentInstance.proxy.$notify({type: 'error', title: '提示', message: '名字不正确！'});
                        }
                    } else {
                        done();
                    }
                }
            }).catch();
        };

        watch(() => props.visible, newVal => {
            if (newVal) {
                updateFormData.value = {
                    id: props.formData.id,
                    name: props.formData.name,
                    nickname: props.formData.nickname,
                    birthdayTime: props.formData.birthdayTime ? props.formData.birthdayTime.split(' ')[0] : undefined,
                    sex: props.formData.sex,
                    school: props.formData.school,
                    grade: props.formData.grade,
                };
            }
        });

        return {
            visible: toRef(props, 'visible'),
            formRules,
            formRef,
            updateFormData,
            confirmLoading,
            handleConfirm,
            handleDelete
        };
    }
});
</script>
<style lang="scss" scoped>

</style>
<style lang="scss">
@import "../../../assets/scss/variables";

.confirm-delete-student {
    width: 14%;
    padding: 0 !important;
}

.confirm-delete-student > .el-message-box__header {
    padding: 0;
}

.confirm-delete-student > .el-message-box__content {
    padding: 20px 20px 0 20px !important;
}

.confirm-delete-student > .el-message-box__content > .el-message-box__message > p {
    color: #292e33;
    line-height: 20px;
}

.confirm-delete-student .el-input__inner {
    height: 28px;
    line-height: 28px;
}

.confirm-delete-student > .el-message-box__btns {
    padding: 0;
    border-top: 1px solid #f1f1f1;
    display: flex;
}

.confirm-delete-student-cancel, .confirm-delete-student-confirm {
    width: 50%;
    margin: 0 !important;
    border: none;
    color: $--color-primary !important;
    background-color: #ffffff !important;
    border-radius: 0;
}

.confirm-delete-student-cancel:hover, .confirm-delete-student-confirm:hover {
    background-color: #f5f7fa !important;
    border: none;
    color: $--color-primary !important;
}


.confirm-delete-student-cancel {
    border-right: 1px solid #f1f1f1 !important;
}
</style>
