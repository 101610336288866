<template>
    <div>
        <el-dialog
            :title="title"
            :visible="visible"
            @update:visible="val=>$emit('update:visible',val)"
            width="600px"
            append-to-body
            custom-class="qj-dialog">
            <div>
                <p>备注</p>
                <el-input v-model="remark" type="textarea" placeholder=""></el-input>
            </div>
            <div slot="footer">
                <el-button @click="$emit('update:visible',false)" size="mini">取 消</el-button>
                <el-button
                    type="primary"
                    size="mini"
                    style="background: #29cb97; border: none"
                    :loading="confirmLoading"
                    @click="submit">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {changStudentClassStatusApi,} from "../../../api/class/classApi";


export default {
    props: {
        visible: Boolean,
        type: Number,
        title: String,
        leaveClassId: Number
    },
    emits: ['update:visible'],
    inject: ['refreshClass'],
    data() {
        return {
            confirmLoading: false,
            remark: undefined,
        };
    },

    mounted() {
    },

    methods: {
        submit() {
            const actions = {
                1: this.submitQingJia,
            };

            if (actions[this.type]) {
                actions[this.type]();
            }
        },
        submitQingJia() {
            this.confirmLoading = true;
            changStudentClassStatusApi({
                id: this.leaveClassId,
                status: 'LeaveOfAbsence',
                remark: this.remark,
            }).then(() => {
                this.$emit('update:visible', false);
                this.refreshClass();
            }).finally(() => this.confirmLoading = false);
        }
    },
};
</script>

<style lang="scss" scoped>
p {
    color: #818c99;
    margin: 0;
    margin-bottom: 5px;
}
</style>

<style lang="scss">
.qj-dialog {
    .el-dialog__body {
        padding: 30px 30px 10px;
        border: 1px solid #ebeef5;
        border-left: none;
        border-right: none;
    }
}
</style>
