<template>
    <div class="once_class">
        <div class="class_info">
            <div class="class_info_top">
                <div>
                    <div class="primary-text-color">
                        <span class="class-name">{{ data.name }}</span>
                        <span class="class-type-name">
                           ({{ data.classTypeName }}
                           {{ data.childrenClassTypeName }})
                        </span>
                    </div>
                    <div style="color: #b4bfcc;font-size: 12px" v-if="data.isOver">(已完结)</div>
                </div>
                <div>第{{ data.classSpeed }}节/共{{ data.classTotalCount }}节</div>
            </div>
            <div class="class_info_down">
                <p>
                    <span>时间: </span>
                    {{ data.beginTime.split(' ')[1].slice(0, 5) }} -
                    {{ data.endTime.split(' ')[1].slice(0, 5) }}
                </p>
                <p>
                    <span>老师: </span>
                    {{
                        Array.isArray(data.mainTeachers) ? data.mainTeachers.map(item => item.name).join(',') : ''
                    }}
                    {{
                        Array.isArray(data.assistTeachers) ? ',' + data.assistTeachers.map(item => item.name).join(',') : ''
                    }}
                </p>
                <p>
                    <span>教室: </span>
                    {{
                        Array.isArray(data.classrooms) ? data.classrooms.map(item => item.name).join(',') : ''
                    }}
                </p>
            </div>
        </div>
        <el-form :model="formData"
                 inline
                 label-position="top"
                 size="mini"
                 :rules="formRules"
                 ref="formRef">
            <div class="line">
                <el-form-item label="上课日期" prop="classDay">
                    <el-date-picker
                        v-model="formData.classDay"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上课时间" prop="startTime">
                    <el-time-select
                        v-model="formData.startTime"
                        style="width: 100%"
                        value-format="HH:mm"
                        :picker-options="{
                       start: '08:00',
                       step: '00:15',
                       end: '23:00'
                    }"
                        placeholder="选择时间">
                    </el-time-select>
                </el-form-item>
                <el-form-item prop="consumeMinute">
                    <template v-slot:label>
                        <div style="display: flex;align-items: center;gap: 5px;">
                            <span>本节课时长(分钟)</span>
                            <span style="color: red;">*</span>
                            <i class="el-icon-edit-outline primary-text-color"
                               style="font-size: 16px;cursor: pointer;"
                               @click="updateConsumeLessonMinuteModalVisible=true"></i>
                        </div>
                    </template>
                    <el-input disabled
                              v-model="formData.consumeMinute"
                              placeholder="">
                    </el-input>
                </el-form-item>
                <el-form-item label="本节课人数" prop="maxStudentCount">
                    <el-input-number :min="0"
                                     controls-position="right"
                                     left
                                     style="width: 100%;"
                                     v-model="formData.maxStudentCount"
                                     placeholder="">
                    </el-input-number>
                </el-form-item>
            </div>
            <div class="line">
                <div style="display: flex;gap: 10px;">
                    <el-form-item label="试听"
                                  :style="`${formData.isAudition===1?'width: calc((100% - 10px) / 2)':'width: 100%'}`"
                                  prop="isAudition">
                        <el-select
                            style="width: 100%"
                            size="mini"
                            v-model="formData.isAudition"
                            placeholder="">
                            <el-option label="可试听" :value="1"></el-option>
                            <el-option label="不可试听" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="最大试听人数"
                                  v-if="formData.isAudition===1"
                                  style="width: calc((100% - 10px) / 2)"
                                  prop="maxAuditionCount">
                        <el-input-number
                            controls-position="right"
                            left
                            style="width: 100%"
                            size="mini"
                            v-model="formData.maxAuditionCount">
                        </el-input-number>
                    </el-form-item>
                </div>
                <el-form-item label="课节主题">
                    <el-input
                        style="width: 100%"
                        size="mini"
                        v-model="formData.title"
                        placeholder="">
                    </el-input>
                    <el-checkbox v-model="formData.updateAllTitle">
                        应用到此课节后的全部课节
                    </el-checkbox>
                </el-form-item>
            </div>
            <div class="line">
                <el-form-item label="主讲老师" prop="teacherIds">
                    <teacher-select
                        use-teacher-id
                        v-model="formData.teacherIds"
                        style="width: 100%;"
                        multiple>
                    </teacher-select>
                </el-form-item>
                <el-form-item label="辅讲老师" prop="assistTeacherIds">
                    <teacher-select
                        use-teacher-id
                        v-model="formData.assistTeacherIds"
                        style="width: 100%;"
                        multiple>
                    </teacher-select>
                </el-form-item>
            </div>
            <div class="line">
                <el-form-item label="教室" prop="classroomIds">
                    <class-room-select
                        multiple
                        style="width: 100%;"
                        v-model="formData.classroomIds">
                    </class-room-select>
                </el-form-item>
                <el-form-item label="耗课数" prop="consumeCount">
                    <el-input-number
                        :min="0"
                        controls-position="right"
                        left
                        style="width: 100%;"
                        v-model="formData.consumeCount"
                        placeholder="">
                    </el-input-number>
                </el-form-item>
            </div>
        </el-form>
        <div class="footer">
            <span v-if="data.isOver"></span>
            <span @click="handleDelete" v-else>删除该课节</span>
            <div>
                <el-button size="mini" @click="$emit('update:visible',false)">取消</el-button>
                <el-button
                    type="primary"
                    size="mini"
                    @click="handleConfirm"
                    :loading="confirmLoading">
                    应用到本次课
                </el-button>
            </div>
        </div>

        <!-- 修改课节时长 -->
        <el-dialog
            title="修改课节时长"
            :visible.sync="updateConsumeLessonMinuteModalVisible"
            width="310px"
            custom-modal
            append-to-body>
            <el-form label-position="top">
                <el-form-item label="时长（分钟）">
                    <el-input-number controls-position="right"
                                     :min="0"
                                     left
                                     style="width: 100%;"
                                     v-model="consumeLessonMinute"
                                     size="mini"
                                     placeholder="">
                    </el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="updateConsumeLessonMinuteModalVisible=false">取 消</el-button>
                <el-button type="primary"
                           @click="formData.consumeMinute=consumeLessonMinute;updateConsumeLessonMinuteModalVisible=false">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import TeacherSelect from '../../Form/TeacherSelect.vue';
import ClassRoomSelect from '../../Form/ClassRoomSelect.vue';
import {defineComponent, getCurrentInstance, ref, toRefs, watch, inject, onMounted} from 'vue';
import {cloneObject} from '../../../utils/ObjectUtils';
import {deleteClassApi, updateClassApi} from '../../../api/class/classApi';
import dayjs from 'dayjs';

export default defineComponent({
    components: {TeacherSelect, ClassRoomSelect},
    props: {
        data: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    emits: ['update:visible'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const classInfo = ref({});

        const defaultFormData = {
            classDay: undefined,
            startTime: undefined,
            consumeMinute: undefined,
            maxStudentCount: undefined,
            isAudition: undefined,
            maxAuditionCount: undefined,
            title: undefined,
            updateAllTitle: false,
            teacherIds: [],
            assistTeacherIds: [],
            classroomIds: [],
            consumeCount: undefined
        };

        const formData = ref(cloneObject(defaultFormData));

        const formRules = ref({
            classDay: [{required: true, message: '上课日期是必填的'}],
            startTime: [{required: true, message: '上课时间是必填的'}],
            consumeMinute: [{
                validator(rule, value, callback) {
                    if (!value) {
                        callback(new Error('课程时长是必填的'));
                        return;
                    }
                    callback();
                }
            }],
            maxStudentCount: [{required: true, message: '本节课人数是必填的'}],
            isAudition: [{required: true, message: '请选择是否可试听'}],
            maxAuditionCount: [{required: true, message: '最大试听人数是必填的'}],
            teacherIds: [{required: true, message: '请选择主讲老师'}],
            classroomIds: [{required: true, message: '请选择教室'}],
            consumeCount: [{required: true, message: '耗课数是必填的'}]
        });

        const updateConsumeLessonMinuteModalVisible = ref(false);

        const consumeLessonMinute = ref();

        const formRef = ref();

        const confirmLoading = ref(false);

        const handleConfirm = () => {
            confirmLoading.value = true;
            formRef.value.validate().then(() => updateClassApi({
                ...formData.value,
                startTime: formData.value.classDay + ' ' + formData.value.startTime + ':00',
                classId: props.data.id
            })).then(() => {
                emit('update:visible', false);
                reloadClass();
            }).finally(() => confirmLoading.value = false);
        };

        const handleDelete = () => {
            currentInstance.proxy.$confirm('确认删除该课程？', {
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        deleteClassApi(props.data.id).then(() => {
                            done();
                            emit('update:visible', false);
                            if (handleClassDelete) {
                                handleClassDelete();
                            } else {
                                reloadClass();
                            }
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                    }
                }
            });
        };

        const reloadClass = inject('reloadClass');

        onMounted(() => {
            formData.value = {
                classDay: props.data.beginTime.split(' ')[0],
                startTime: dayjs(props.data.beginTime).format('HH:mm'),
                consumeMinute: props.data.consumeMinute,
                maxStudentCount: props.data.maxStudentCount,
                isAudition: props.data.isAudition ? 1 : 0,
                maxAuditionCount: props.data.maxAuditionCount,
                title: props.data.title,
                updateAllTitle: false,
                teacherIds: Array.isArray(props.data.mainTeachers) && props.data.mainTeachers.length ? props.data.mainTeachers.map(item => item.id) : [],
                assistTeacherIds: Array.isArray(props.data.assistTeachers) && props.data.assistTeachers.length ? props.data.assistTeachers.map(item => item.id) : [],
                classroomIds: Array.isArray(props.data.classrooms) && props.data.classrooms.length ? props.data.classrooms.map(item => item.id) : [],
                consumeCount: props.data.consumeCount
            };
            setTimeout(() => {
                formRef.value.clearValidate();
            });
        });

        const handleClassDelete = inject('handleClassDelete');

        watch(updateConsumeLessonMinuteModalVisible, newVal => {
            if (newVal) {
                consumeLessonMinute.value = formData.value.consumeLessonMinute;
            }
        });

        return {
            ...toRefs(props),
            classInfo,
            formData,
            formRules,
            updateConsumeLessonMinuteModalVisible,
            consumeLessonMinute,
            formRef,
            confirmLoading,
            handleConfirm,
            handleDelete
        };
    }
});
</script>
<style lang="scss" scoped>
.once_class {
    padding: 15px;
    box-sizing: border-box;

    .class_info {
        border-radius: 5px;
        background: #f5f7fa;
        min-height: 130px;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;

        .class_info_top {
            border-bottom: 1px solid #e5e5e5;

            > div:nth-of-type(1) {
                font-size: 16px;
                color: #29cb97;
                margin: 0;
                display: flex;
                justify-content: space-between;

                .class-type-name {
                    font-size: 11px;
                }
            }

            > div:nth-of-type(2) {
                font-size: 12px;
                color: #b4bfcc;
                margin: 0;
                margin-bottom: 10px;
            }
        }

        .class_info_down {
            margin-top: 10px;
            margin-bottom: 0;

            > p {
                font-size: 12px;
                color: #b4bfcc;
                margin: 0;

                span {
                    color: #292e33;
                }
            }
        }
    }
}
</style>
<style scoped>
.once_class .line {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    margin-top: 10px;
}

.once_class .line .el-form-item {
    margin: 0 !important;
}

.once_class .line .el-form-item .el-input {
    width: 100% !important;
}

.once_class .line:first-child > .el-form-item {
    width: calc((100% - 30px) / 4);
}

.once_class .line:not(:first-child) > * {
    width: calc((100% - 20px) / 2);
}

.once_class .footer {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-top: 40px;
    padding-top: 10px;
    position: relative;


}

.once_class .footer > span {
    font-size: 12px;
    color: #f5222d;
    cursor: pointer;
}

.once_class .footer::before {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% + 30px);
    border-top: 1px solid #e9e9e9;
    top: 0;
    left: -15px;
}
</style>
