<template>
<div>
    <el-dialog
        custom-modal
        width="600px"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        title="修改合同适用规则"
        :close-on-click-modal="false"
        append-to-body>
        <el-form :model="formData" :rules="fromRules" ref="formRef" label-position="top" size="mini">
            <el-form-item label="旧课程">
                <div class="old-package-rule">
                    <div class="package-rule-item">
                        <div>普通课时适用课类</div>
                        <div>{{ data.ordinaryClassName }}</div>
                    </div>
                    <div v-if="data.pack.type===1" class="package-rule-item">
                        <div>普通课时适用周期</div>
                        <div>{{ data.contractPackage.ordinaryTime }}</div>
                    </div>
                    <div class="package-rule-item">
                        <div>特殊课时适用课类</div>
                        <div>{{ data.specialClassName }}</div>
                    </div>
                    <div v-if="data.pack.type===1" class="package-rule-item">
                        <div>特殊课时适用周期</div>
                        <div>{{ data.contractPackage.specialTime }}</div>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="新课程">
                <package-rule-select :package-id="data.packageId"
                                     :ordinary-class.sync="formData.ordinaryClass"
                                     :special-class.sync="formData.specialClass"
                                     :ordinary-class-periodicity.sync="formData.ordinaryTime"
                                     :special-class-periodicity.sync="formData.specialTime"></package-rule-select>
            </el-form-item>
            <el-form-item prop="reason" label="原因">
                <el-input v-model="formData.reason" size="mini" placeholder=""></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="$emit('update:visible',false)">取 消</el-button>
            <el-button type="primary" @click="handleConfirm()" size="small">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import {computed, defineComponent, inject, ref} from 'vue'
import {updateApplicableRuleApi} from '../../../api/student/contractApi'
import PackageRuleSelect from '../../Form/PackageRuleSelect.vue'

export default defineComponent({
    components: {PackageRuleSelect},
    props: {
        visible: Boolean
    },
    emits: ['update:visible'],
    setup (props, {emit}) {

        const formRef = ref()

        const fromRules = ref({
            reason: [{required: true, message: '原因是必填的'}]
        })

        const formData = ref({
            ordinaryClass: undefined,
            specialClass: undefined,
            ordinaryTime: undefined,
            specialTime: undefined,
            reason: undefined,
        })

        const confirmLoading = ref(false)

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate()
                .then(() => updateApplicableRuleApi({
                    ...formData.value,
                    ordinaryClass: formData.value.ordinaryClass.join(','),
                    specialClass: formData.value.specialClass.join(','),
                    ordinaryTime: formData.value.ordinaryTime ? formData.value.ordinaryTime.join(',') : '',
                    specialTime: formData.value.specialTime ? formData.value.specialTime.join(',') : '',
                    contractId: data.value.id
                }))
                .then(() => {
                    emit('update:visible', false)
                    refreshWater()
                })
                .finally(() => {
                    confirmLoading.value = false
                })
        }

        const refreshWater = inject('refreshWater')

        const getData = inject('getData')

        const data = computed(() => getData())

        return {
            formRef,
            fromRules,
            formData,
            data,
            confirmLoading,
            handleConfirm
        }
    }
})
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 0 5px 0 !important;
    color: #3b3b3b !important;
}

.old-package-rule {
    padding: 10px;
    background: #f0f0f0;
    border-radius: 5px;
    margin-top: 5px;
}

.package-rule-item {
    margin: 0 20px 20px 0;
    color: #3b3b3b;
}
</style>
