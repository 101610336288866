<template>
    <div class="text-date-picker">
        <slot></slot>
        <div class="date-picker">
            <el-date-picker :type="Array.isArray(value)?'daterange':'date'"
                            value-format="yyyy-MM-dd"
                            v-bind="pickerOptions"
                            :value="computedValue"
                            @input="handleInput">
            </el-date-picker>
        </div>
    </div>
</template>
<script>
import {computed, defineComponent, toRefs} from "vue";
import dayjs from "dayjs";

export default defineComponent({
    methods: {dayjs},
    props: {value: [Object, Array, String], pickerOptions: Object},
    emits: ['update:value'],
    model: {
        value: 'value',
        event: 'update:value'
    },
    setup(props, {emit}) {

        const handleInput = (val) => {
            if (Array.isArray(props.value) && props.value.length) {
                emit('update:value', val.map(value => value ? dayjs(value) : undefined));
            } else {
                emit('update:value', dayjs(val));
            }
        };

        const transferToDateString = (value) => {
            if (!value) {
                return undefined;
            }
            return (typeof value === 'object' ? value : dayjs(value)).format('YYYY-MM-DD');
        };

        const computedValue = computed(() => {
            if (Array.isArray(props.value) && props.value.length) {
                return props.value.map(value => transferToDateString(value));
            } else {
                return transferToDateString(props.value);
            }
        });

        return {
            ...toRefs(props),
            handleInput,
            computedValue,
        };
    }
});
</script>
<style scoped>
.text-date-picker {
    color: #28cb97;
    margin: 0;
    overflow: hidden;
    position: relative;
    display: inline-flex;

}

.text-date-picker >>> .date-picker {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.text-date-picker >>> .date-picker * {
    cursor: pointer !important;
}
</style>