<template>
<el-dialog title="转出中心"
           width="28%"
           custom-modal
           append-to-body
           :visible="visible"
           @update:visible="val=>$emit('update:visible',val)">
    <el-form size="mini" label-position="top">
        <el-form-item label="请选择中心">
            <store-select style="width: 100%"
                          v-model="formData.schoolDistrictId"
                          @change="handleChange"
                          clearable>
                <template v-slot="{options}">
                    <el-option v-for="option in options"
                               :disabled="option.id===storeId"
                               :key="option.id"
                               :value="option.id"
                               :label="option.campusName">
                    </el-option>
                </template>
            </store-select>
        </el-form-item>
    </el-form>
    <template v-slot:footer>
        <el-button class="ballet-modal-button" @click="carbonCopyingTransfer">复制转出</el-button>
        <el-button type="primary" class="ballet-modal-button" @click="fullTransferOut">完全转出</el-button>
    </template>
</el-dialog>
</template>
<script>
import {transferOutApi} from '../../../api/student/listApi'
import StoreSelect from '@/components/Form/StoreSelect.vue'

export default {
    components: {StoreSelect},
    emits: ['update:visible', 'ok'],
    props: {
        visible: Boolean,
        listId: Number,
        storeId: Number,
    },
    data () {
        return {
            formData: {},
            schoolDistrictName: undefined,
        }
    },
    methods: {
        handleChange (val, store) {
            this.schoolDistrictName = store.campusName
        },
        carbonCopyingTransfer () {
            if (this.formData.schoolDistrictId) {
                this.$confirm(`确认转入中心 [${this.schoolDistrictName}] ？`, {
                    showClose: false,
                    type: 'warning',
                    message: `复制转出后名单在本中心依然可以进行跟进操作。如果名单在 [${this.schoolDistrictName}] 中心已存在将会覆盖名单。`,
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            this.transferOut('CarbonCopyingTransfer').then(() => done()).finally(() => {
                                instance.confirmButtonLoading = false
                            })
                        } else {
                            done()
                        }
                    }
                })
            } else {
                this.$notify({type: 'warning', title: '提示', message: '请选择校区'})
            }
        },
        fullTransferOut () {
            if (this.formData.schoolDistrictId) {
                this.$confirm(`确认转入中心 [${this.schoolDistrictName}] ？`, {
                    showClose: false,
                    type: 'warning',
                    message: `完全转出后名单在本中心名单管理中删除，不可进行跟进操作。如果名单在 [${this.schoolDistrictName}] 中心已存在将会覆盖名单。`,
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            this.transferOut('FullTransferOut').then(() => done()).finally(() => {
                                instance.confirmButtonLoading = false
                            })
                        } else {
                            done()
                        }
                    }
                })
            } else {
                this.$notify({type: 'warning', title: '提示', message: '请选择校区'})
            }
        },
        transferOut (transferOutType) {
            return transferOutApi({
                listId: this.listId,
                transferOutType,
                schoolDistrictId: this.formData.schoolDistrictId
            }).then(() => {
                this.$emit('update:visible', false)
                this.$emit('ok')
            })
        }
    },
}
</script>
