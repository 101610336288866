<template>
    <div class="photo_wrap">
        <div class="pictureBox">
            <label v-for="(item,index) in data" :key="item.image" @click="preview(index)">
                <div class="imageBox">
                    <el-image
                        style="width: 100%;"
                        :src="item.image"
                        ref="previewImg"
                        :preview-src-list="data.map(it=>it.image)">
                    </el-image>
                </div>
                <span class="classType">{{ item.classTypeName }} {{ item.className }}</span>
                <div class="classInfo">
                    <span>{{ item.teacherName ? item.teacherName : '未知' }}</span>
                    <span>{{ item.classroomName ? item.classroomName : '未知' }}</span>
                </div>
                <div class="time">{{ item.classStartTime }}</div>
            </label>
        </div>
        <p class="noHave" v-if="!data.length">无更多数据</p>
    </div>
</template>

<script>
import {getStudentClassPhotoLibraryApi} from "../../api/student/studentApi";

export default {
    name: 'BalletVuePhotographList',
    props: {
        studentId: {
            type: Number,
            default: undefined,
        }
    },

    data() {
        return {
            page: {
                currentPage: 1,
                pageSize: 10,
            },
            data: [],
            total: 0,
        };
    },

    mounted() {
        this.getList();
    },

    methods: {
        getList() {
            this.page.timestamp = new Date().getTime();
            this.page.studentId = this.studentId;
            getStudentClassPhotoLibraryApi(this.page).then(res => {
                this.data = this.data.concat(res.data.data.records);
                this.total = res.data.data.total;
            })
        },
        nextPage() {
            if (this.data.length >= this.total) {
                return
            }
            this.page.currentPage += 1;
            this.getList();
        },
        preview(index) {
            this.$refs.previewImg[index].showViewer = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.photo_wrap {
    padding: 10px 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    //min-height: 402px;
    height: 400px;

    .noHave {
        padding: 20px 0;
        box-sizing: border-box;
        margin: 0 auto;
        color: #818c99;
        width: fit-content;
        font-size: 14px;
        margin-top: 30px;
    }
}

.pictureBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    label + label {
        margin-left: 10px;
    }

    label {
        width: 198px;
        height: 210px;
        background: #fff;
        padding: 10px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        border: 1px solid #edf0f2;
        border-radius: 4px;
        cursor: pointer;

        .imageBox {
            width: 100%;
            height: 100px;
            overflow: hidden;
        }

        .classType {
            display: block;
            font-size: 14px;
            color: #292e33;
            margin-top: 5px;
        }

        .classInfo {
            display: flex;
            align-items: center;
            width: 100%;
            line-height: 16px;
            position: absolute;
            bottom: 30px;

            span {
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 12px;
                color: #818c99;
            }
        }

        .time {
            position: absolute;
            bottom: 10px;
            font-size: 12px;
            color: #818c99;
        }
    }
}
</style>
