<template>
    <div>
        <el-dialog
                width="470px"
                :title="title"
                :visible.sync="visible"
                v-if="visible"
                :before-close="closeContractDetailModal"
                custom-class="contractDetailModal-dialog"
                append-to-body>
                <div v-if="type==1">
                    <div class="money">
                        <p>价格</p>
                        <span>25800</span>
                    </div>
                    <div class="valid">
                        <div>
                            <p>有效期</p>
                            <span>365天（12月5天）</span>
                            <el-tooltip effect="dark" content="1个月=30天" placement="top">
                                <img src="../../../assets/img/wenhao2.png" alt="">
                            </el-tooltip>
                        </div>
                        <div>
                            <p>每周上课课时数</p>
                            <span>40</span>
                        </div>
                        <div>
                            <p>周末上课次数</p>
                            <span>20</span>
                        </div>
                    </div>
                    <div class="special">
                        <p>特殊课时</p>
                        <span>0</span>
                    </div>
                </div>
                <div v-if="type==2">
                    <div class="money">
                        <p>价格</p>
                        <span>10680</span>
                    </div>
                    <div class="valid">
                        <div>
                            <p>普通课时</p>
                            <span>60）</span>
                        </div>
                        <div>
                            <p>赠送课时</p>
                            <span>0</span>
                        </div>
                        <div>
                            <p>特殊课时</p>
                            <span>0</span>
                        </div>
                    </div>
                    <div class="special">
                        <p>有效期</p>
                        <span>240天（8月）</span>
                        <el-tooltip effect="dark" content="1个月=30天" placement="top">
                            <img src="../../../assets/img/wenhao2.png" alt="">
                        </el-tooltip>
                    </div>
                </div>
            <StudentDetailModal v-bind="StudentDetailData" :visible.sync="visible"></StudentDetailModal>
            </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'BalletVueContractDetailModal',
    props:['visible','closeContractDetailModal','title','type'],
    data() {
        return {
            StudentDetailData:{},
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.money,.special{
    >p,span{
        margin: 0;
        font-size: 14px;
        line-height: 14px;
        color: #292e33;
    }
}
.special{
    margin-top: 20px;
    img{
        width: 14px;
        height: 14px;
        cursor: pointer;
    }
}
.valid{
    display: flex;
    margin-top: 20px;
    >div+div{
        margin-left: 30px;
    }
    >div{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        p,span{
            margin: 0;
            line-height: 18px;
            color: #292e33;
        }
        img{
            width: 14px;
            height: 14px;
            cursor: pointer;
        }
    }
}
</style>

<style lang="scss">

.contractDetailModal-dialog{
    .el-dialog__header {
    height: 40px;
    border-bottom: 1px solid #ccc;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 40px;
    color: #292e33;
    i::before{
        position: relative;
        top: -10px;
    }
    span{
        font-size: 14px;
    }
  }
    .el-dialog__body {
    padding: 15px 15px;
    box-sizing: border-box;
  }
  .el-dialog__footer {
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: hidden;
    border-top: 1px solid #ccc;
  }
}
</style>
