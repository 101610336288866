<template>
<div>
    <el-dialog
        title="年卡自然周流逝详情"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',false)"
        width="1020px"
        custom-modal
        append-to-body
        top="8vh">
        <p class="p">流式周期:2023-10-17 00:00:00 ~ 2023-11-19 23:59:59</p>
        <p class="p">计算公式:周流逝金额 = 周消耗金额 - 周耗课金额</p>
        <p class="p">本周消耗金额:381.9元 本周耗课金额:57.24元 本周流逝金额: 324.66元</p>
        <h5 style="margin-bottom: 10px;margin-top: 20px;">本周耗课记录</h5>
        <el-table :header-cell-style="{backgroundColor:'#f7f7f7'}"
                  v-loading="tableLoading"
                  :data="tableData"
                  height="450px">
            <el-table-column
                prop="ordinaryClassChange"
                label="普通课时变化">
            </el-table-column>
            <el-table-column
                prop="specialClassChange"
                label="特殊课时变化">
            </el-table-column>
            <el-table-column
                prop="priceChange"
                label="金额变化">
            </el-table-column>
            <el-table-column
                prop="classInfo"
                label="课节信息">
                <template v-slot="{row}">
                    <div v-if="row.classInfo">
                        <div>{{ row.classInfo.startTime }}</div>
                        <div>{{ row.classInfo.name }}</div>
                    </div>
                    <div v-else>-</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="studentInfo"
                label="学员信息"
                width="165">
                <template v-slot="{row}">
                    <div class="studentInfo" v-if="row.student">
                        <img v-if="row.student.avatar" :src="row.student.avatar" alt="">
                        <img v-else-if="row.student.sex===1"
                             src="https://lolita-api.oss-cn-shenzhen.aliyuncs.com/images/member_avatars/default_male.png"
                             alt="">
                        <img v-else
                             src="https://lolita-api.oss-cn-shenzhen.aliyuncs.com/images/member_avatars/default_female.png"
                             alt="">
                        <div>
                            <div>
                                <span style="color:#3b3b3b;">{{ row.student.name }}</span>
                                <gender-view :gender="row.student.sex"></gender-view>
                            </div>
                            <p>{{ getBirthdayDisplay(row.student.birthdayTime) }}</p>
                        </div>
                    </div>
                    <div v-else>-</div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="total,prev, pager, next, sizes ,jumper"
            v-bind="page"
            :page-sizes="[10,20,50,100]"
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            style="text-align: right;margin-top: 20px;margin-bottom: 20px;">
        </el-pagination>
    </el-dialog>
</div>
</template>

<script>
import {ref, toRefs, watch} from 'vue'
import {getUseRecordPageApi} from '../../../api/student/contractApi'
import {getDefaultPage} from '../../../utils'
import GenderView from '../../Common/GenderView.vue'
import {getBirthdayDisplay} from '../../../utils/studentUtils'

export default {
    name: 'BalletVueElapseDetail',
    components: {GenderView},
    props: {visible: Boolean, contractId: Number, weekEndTime: String},
    emits: ['update:visible'],
    setup (props, {emit}) {

        const page = ref(getDefaultPage())

        const tableData = ref([])

        const tableLoading = ref(false)

        const refreshTableData = () => {
            page.value = getDefaultPage()
            getPage()
        }

        const getPage = () => {
            tableLoading.value = true
            getUseRecordPageApi({
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp,
                contractId: props.contractId,
                contractConsumptionType: 'AttendClass'
            }).then(res => {
                tableData.value = res.data.data.records
                page.value.total = res.data.data.total
            }).finally(() => tableLoading.value = false)
        }

        const sizeChangeHandle = (val) => {
            page.value.pageSize = val
            page.value.currentPage = 1
            refreshTableData()
        }
        const currentChangeHandle = (val) => {
            page.value.currentPage = val
            getTableData()
        }

        watch(() => props.visible, newVal => {
            if (newVal) {
                refreshTableData()
            }
        })

        return {
            ...toRefs(props),
            page,
            tableData,
            tableLoading,
            sizeChangeHandle,
            currentChangeHandle,
            getBirthdayDisplay
        }
    }
}
</script>

<style lang="scss" scoped>
p {
    margin: 0;
}

.p {
    font-size: 14px;
    color: #292e33;
}

h5 {
    margin: 10px 0 0 0;
    font-size: 16px;
    color: #292e33;
}

em {
    color: #29cb97;
    font-style: normal;
    cursor: pointer;
}

.studentInfo {
    display: flex;
    align-items: center;

    > img {
        flex: 0 0 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        background: #04be02;
    }

    > div {
        position: relative;
        flex: 1;

        > img {
            width: 16px;
            height: 16px;
            position: absolute;
            right: 55px;
            transform: scale(.9);
            top: 2px;
            border-radius: 50%;
            padding: 3px;
            box-sizing: border-box;
        }

        img:nth-of-type(1) {
            background: #fda6c9;
        }

        img:nth-of-type(2) {
            background: #29cb97;
        }
    }
}
</style>

<style lang="scss">
.elapse-dialog {
    margin: 0 auto;

    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #ccc;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 40px;
        color: #292e33;
        font-size: 12px;
        display: flex;
        align-items: center;

        i::before {
            // line-height: 40px;
            position: relative;
            top: -5px;
        }

        span {
            font-size: 16px;
        }
    }

    .el-dialog__body {
        padding: 15px 10px;
        box-sizing: border-box;
        height: 630px;
    }
}
</style>
<style scoped>
>>> .el-table__cell.gutter {
    background-color: #f7f7f7 !important;
}
</style>
