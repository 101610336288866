<template>
    <header>
        <el-dialog
            title="提示"
            :visible.sync="visible"
            width="650px"
            :before-close="closeVacateModal"
            custom-class="leave-dialog"
        >
            <div class="top_header">
                <img :style="currentDate2==currentDate?'opacity:0;':'opacity:1;'"
                     @click="currentDate2==currentDate?'':getPrevDay(currentDate)" src="../../assets/img/left_green.png"
                     alt="">
                <p>{{ currentDate }}{{ currentWeekDay }}</p>
                <img @click="getNextDay(currentDate);" src="../../assets/img/right_green.png" alt="">
            </div>
            <dl ref="dl">
                <dd v-for="item in classList" v-if="classList.length" :class="selectId===item.id?'act':''" :key="item.id"
                    @click="selectClass(item)">
                    <div class="font">
                        <div>
                            <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
                                <div slot="content">
                                    <p style="margin:0;width:300px;white-space:wrap;">
                                        {{ item.childrenClassTypeName }}<span>({{
                                            item.classTypeName + item.name
                                        }})</span></p>
                                </div>
                                <p style="width:300px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                                    {{
                                        item.name
                                    }}<span>({{ item.classTypeName + ' ' + item.childrenClassTypeName }})</span></p>
                            </el-tooltip>
                            <!-- <p>{{item.title}}<span>({{item.remark}})</span></p> -->
                        </div>
                        <div>
                            <article>
                                <img src="../../assets/img/time2.png" alt="">
                                <span>{{ dayjs(item.startDate).format('HH:mm') }}</span>
                            </article>
                            <article>
                                <img src="../../assets/img/user.png" alt="">
                                <span>{{ item.teacherNames[0] }}</span>
                            </article>
                            <article>
                                <img src="../../assets/img/address.png" alt="">
                                <span>{{ item.classroomNames[0] }}</span>
                            </article>
                        </div>
                    </div>
                    <footer></footer>
                </dd>
                <p v-if="!classList.length" style="text-align: center">暂无课程</p>
            </dl>
            <div slot="footer" v-if="selectId">
                <el-button type="primary" size="mini" @click="confirmLeaveClass">确定</el-button>
            </div>
        </el-dialog>
        <leave-reason :visible="leaveModalVisible" :student-id="studentId" :classId="selectId" :close-leave-modal="closeLeaveModal"></leave-reason>
    </header>
</template>

<script>
import {getClassListApi} from "../../api/class/classApi";
import dayjs from "dayjs";
import LeaveReason from "../leaveReason/leaveReason.vue";

const advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

export default {
    name: 'BalletVueVacate',
    components: {LeaveReason},
    props: ['visible', 'closeVacateModal', 'studentId'],
    data() {
        return {
            //当前日期 例如 2023年11月06日
            currentDate: '',
            currentDate2: '',
            //当前日期对应的是周几,例如2023年11月06日对应的是周一
            currentWeekDay: '',
            //类型 1代表全部类型
            type: 1,
            //类型模拟数组
            typeOptions: [
                {
                    id: 1,
                    name: '全部类型'
                },
                {
                    id: 2,
                    name: '普通课程'
                },
                {
                    id: 3,
                    name: '试听课'
                },
                {
                    id: 4,
                    name: '活动课'
                },
                {
                    id: 5,
                    name: '补课专用'
                }
            ],
            //老师id 1代表全部老师
            teacherId: 1,
            //老师模拟数组
            teacherOptions: [

            ],
            //课类 1代表全部课类
            courseType: 1,
            //课类模拟数组
            courseTypeOptions: [

            ],
            //子课类 1代表全部子课类
            currentSubject: 1,
            //子课类模拟数组
            currentSubjectOptions: [
                {
                    id: 1,
                    name: '全部子课类',
                }
            ],
            //课程模拟数据
            classList: [

            ],
            //是否免费
            isFree: false,
            //更换合同弹窗显隐
            checkContractBoolean: false,
            currentPage: 1,
            pageSize: 10,
            selectId: null,
            leaveModalVisible:false,
        };
    },

    mounted() {
        this.getCurrentDate();
    },
    //弹窗关闭时情况onscroll和selectId currentPage
    beforeDestroy() {
        this.$refs.dl.onscroll = null;
        this.selectId = null;
        this.currentPage = 1;
    },
    methods: {
        dayjs,
        //获取当前日期
        getCurrentDate() {
            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            var dt = year + '年' + (month < 10 ? '0' + month : month) + '月' + (day < 10 ? '0' + day : day) + '日';
            this.currentDate = dt;
            this.currentDate2 = dt;
            this.getCurrentWeekDat(this.currentDate);
        },
        //获取明天的函数
        getNextDay(currentDate) {
            // 验证 currentDate 是否为字符串
            if (typeof currentDate !== 'string') {
                console.log('不执行');
                return 'Invalid input';
            }

            // 验证 currentDate 是否符合指定格式
            var dateRegex = /^(\d{4})年(\d{2})月(\d{2})日$/;
            if (!dateRegex.test(currentDate)) {
                return 'Invalid date format. Please use YYYY年MM月DD日';
            }

            var dateParts = currentDate.match(dateRegex);
            var year = parseInt(dateParts[1], 10);
            var month = parseInt(dateParts[2], 10) - 1; // 月份从0开始，需要减1
            var day = parseInt(dateParts[3], 10);

            var date = new Date(year, month, day);
            date.setDate(date.getDate() + 1);

            var nextDayYear = date.getFullYear();
            var nextDayMonth = date.getMonth() + 1; // 月份从0开始，需要加1
            var nextDay = date.getDate();

            var nextDayDate = nextDayYear + '年' + (nextDayMonth < 10 ? '0' + nextDayMonth : nextDayMonth) + '月' + (nextDay < 10 ? '0' + nextDay : nextDay) + '日';
            this.currentDate = nextDayDate;
            this.getCurrentWeekDat(this.currentDate);
        },
        //获取昨天的日期
        getPrevDay(currentDate) {
            // 验证 currentDate 是否为字符串
            if (typeof currentDate !== 'string') {
                return 'Invalid input';
            }

            // 验证 currentDate 是否符合指定格式
            var dateRegex = /^(\d{4})年(\d{2})月(\d{2})日$/;
            if (!dateRegex.test(currentDate)) {
                return 'Invalid date format. Please use YYYY年MM月DD日';
            }

            var dateParts = currentDate.match(dateRegex);
            var year = parseInt(dateParts[1], 10);
            var month = parseInt(dateParts[2], 10) - 1; // 月份从0开始，需要减1
            var day = parseInt(dateParts[3], 10);

            var date = new Date(year, month, day);
            date.setDate(date.getDate() - 1); // 获取昨天的日期

            var yesterdayYear = date.getFullYear();
            var yesterdayMonth = date.getMonth() + 1; // 月份从0开始，需要加1
            var yesterday = date.getDate();

            var yesterdayDate = yesterdayYear + '年' + (yesterdayMonth < 10 ? '0' + yesterdayMonth : yesterdayMonth) + '月' + (yesterday < 10 ? '0' + yesterday : yesterday) + '日';
            this.currentDate = yesterdayDate;
            this.getCurrentWeekDat(this.currentDate);
        },
        //获取指定日期是星期几的函数
        getCurrentWeekDat(currentDate) {
            // 验证 currentDate 是否为字符串
            if (typeof currentDate !== 'string') {
                return 'Invalid input';
            }

            // 验证 currentDate 是否符合指定格式
            var dateRegex = /^(\d{4})年(\d{2})月(\d{2})日$/;
            if (!dateRegex.test(currentDate)) {
                return 'Invalid date format. Please use YYYY年MM月DD日';
            }

            var dateParts = currentDate.match(dateRegex);
            var year = parseInt(dateParts[1], 10);
            var month = parseInt(dateParts[2], 10) - 1; // 月份从0开始，需要减1
            var day = parseInt(dateParts[3], 10);

            var date = new Date(year, month, day);
            var weekDayNumber = date.getDay();

            var weekDays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            var weekDay = weekDays[weekDayNumber];
            this.currentWeekDay = weekDay;
            this.getDataList();
        },
        //改变全部课类时,动态设置子课类
        changeCourseType(id) {
            if (id == 1) {
                this.currentSubjectOptions = [{id: 1, name: '全部课类'}];
                return;
            }
            //根据id 找到对应的数据
            let courseType = this.courseTypeOptions.find(item => item.id == id);
            // console.log(courseType.children,'courseType.children');
            //循环courseType.children 依次把每一项添加到this.currentSubjectOptions中
            courseType.children.forEach(item => {
                // console.log(item,'item');
                this.currentSubjectOptions.push(item);
            })
        },
        //关闭更换弹窗
        closeCheckContractModal() {
            console.log('关闭');
            this.checkContractBoolean = false;
        },
        //获取数据
        getDataList() {
            const cleanedDateString = this.currentDate.replace(/[\u4e00-\u9fa5]/g, ' ');

            const [year, month, day] = cleanedDateString.split(' ');

            const formattedDate = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');
            getClassListApi({
                startDate: dayjs(formattedDate).format('YYYY-MM-DD 00:00:00.000'),
                endDate: dayjs(formattedDate).format('YYYY-MM-DD 23:59:59.999'),
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                timestamp: new Date().getTime(),
                studentId: this.studentId
            }).then(res => {
                this.classList = res.data.data.records;
                //设置边界
                if(res.data.data.pages===this.currentPage){
                    this.$refs.dl.onscroll = null;
                    return;
                }
                this.$refs.dl.onscroll ? '' : this.$refs.dl.onscroll = this.load;
            })
        },
        selectClass(item) {
            console.log(item,'item')
            this.selectId = item.id;
        },
        load(e) {
            //计算是否滚动到底部
            const {scrollTop, clientHeight, scrollHeight} = e.target;
            if (scrollTop + clientHeight >= scrollHeight) {
                // 滚动到底部，执行加载更多数据的操作
                this.currentPage++;
                this.getDataList();
            }
        },
        confirmLeaveClass(){
            console.log('学生id:'+this.studentId,'\n选中的课id:'+this.selectId);
            this.leaveModalVisible = true;
            // this.$confirm('确定要对该节课请假吗?',{
            //     type:'warning',
            //     beforeClose:(action,instance,done)=>{
            //         if(action==='confirm'){
            //             instance.confirmButtonLoading = true;
            //             instance.confirmButtonLoading = false;
            //             done();
            //         }else {
            //             done();
            //         }
            //     }
            // });
        },
        closeLeaveModal(){
            this.leaveModalVisible = false;
            this.closeVacateModal();
        },
    },
};
</script>

<style lang="scss" scoped>
.top_header {
    width: 300px;
    margin: 0 auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        color: #28cb97;
        margin: 0;
    }

    img {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
}

.filter {
    display: flex;
    align-items: center;
    height: 55px;
    // border: 1px solid #f00;
    justify-content: space-around;
    padding: 0 35px;
    box-sizing: border-box;
}

dl {
    padding: 0;
    margin: 0;
    height: 230px;
    overflow-y: auto;
    background: #eaeaea;

    dd {
        height: 60px;
        background: #eaeaea;
        width: 100%;
        margin: 0;
        padding: 0 45px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-bottom: 1px solid #ccc;
        cursor: pointer;

        &:hover {
            background: #f5f5f5;

            footer {
                display: block;
            }
        }

        &.act {
            background: #f5f5f5;

            footer {
                display: block;
                background: #29cb97;
            }
        }

        footer {
            height: 100%;
            position: absolute;
            width: 6px;
            right: 0;
            top: 0;
            background: #b4b4b4;
            display: none;
        }

        section {
            position: absolute;
            cursor: no-drop;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;

            span {
                height: 25px;
                width: 100%;
                // border: 1px solid #f00;
            }
        }

        .font {
            height: 100%;
            padding-top: 3px;
            box-sizing: border-box;

            > div {
                display: flex;
                align-items: center;

                p {
                    margin: 0;
                    font-size: 16px;

                    span {
                        font-size: 12px;
                    }
                }

                > div {
                    padding: 0 8px;
                    box-sizing: border-box;
                    background: #28cb97;
                    color: #fff;
                    border-radius: 10px;
                    font-size: 12px;
                    margin-left: 10px;
                }

                article + article {
                    margin-left: 20px;
                }

                article {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 12px;
                        height: 12px;
                        margin-right: 3px;
                    }

                    span {
                        font-size: 12px;
                    }
                }
            }
        }

        > p {
            color: #28cb97;
            margin: 0;
        }
    }
}

.contract_info {
    height: 55px;
    padding: 0 40px;
    box-sizing: border-box;
    position: relative;
    padding-top: 5px;
    box-sizing: border-box;

    article {
        position: absolute;
        right: 30px;
        top: 3px;
        display: flex;
        align-items: center;

        span {
            font-size: 12px;
            color: #292e33;
            margin-left: 4px;
        }
    }

    div {
        display: flex;
        align-items: center;

        p {
            margin: 0;
            color: #28cb97;
            cursor: pointer;
        }

        span {
            font-size: 12px;
            color: #292e33;
        }
    }
}
</style>
<style lang="scss">
.leave-dialog {
    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        padding: 10px 0px;
        box-sizing: border-box;
    }
}
</style>
