<template>
<div class="water-info">
    <div class="confirm_left">
        <div class="confirm_left-title">【{{ title }}】{{ water.createTime }}</div>
        <slot :water="water"></slot>
        <div v-if="water.payType">
            支付方式:
            {{ water.payType === -1 ? '转中心' : $store.getters['common/payMethodMap'][water.payType] }}
        </div>
        <div>理由: {{ water.reason }}</div>
        <div>
            <span>操作人:</span>
            <b>{{ water.operatorName }}</b>
        </div>
        <template v-if="water.checkResult===3||water.checkResult===5">
            <div>
                <span>业绩归属:</span>
                <el-tooltip v-if="water.performanceUpdateFlag===1" placement="top" content="点击查看修改记录">
                    <b style="color: #faad14;cursor: pointer;" @click="performanceChangeRecordModalVisible=true">
                        {{ water.performancePerson }}
                    </b>
                </el-tooltip>
                <b v-else>{{ water.performancePerson }}</b>
                <em class="cur" v-if="water.checkResult===3"
                    @click="updatePerformanceVestingModalVisible=true">
                    [更换]
                </em>
            </div>
            <div v-if="water.performanceType">
                <span>业绩类型:</span>
                <b>{{ $store.getters['common/performanceTypeMap'][water.performanceType] }}</b>
                <em class="cur" @click="handleUpdatePerformanceTypeClick">[更换]</em>
            </div>
        </template>
    </div>
    <slot name="right">
        <div v-if="water.checkResult===0" style="padding-left: 60px;padding-top: 40px;">
            <el-button type="primary"
                       size="mini" style="width: fit-content;"
                       @click="confirmWater(true,'是否同意此合同流水？')">
                同意
            </el-button>
            <el-button type="danger"
                       size="mini"
                       style="width: fit-content; background-color: #f5222d;"
                       @click="confirmWater(false,'是否拒绝此合同流水？')">
                不同意
            </el-button>
        </div>
        <div class="confirm_right" v-else-if="water.checkResult===1">
            <div>{{ water.checkTime }}</div>
            <div style="font-size: 16px;margin: 5px 0;" class="primary-text-color">
                <span>已同意</span>
                <el-button type="danger"
                           v-if="water._isLastFreezeWater&&water.checkResult===1&&dayjs(water.freezeStartTime).isAfter(dayjs())"
                           @click="cancelFreeze"
                           style="background-color: #f5222d;border: none;margin-left: 5px;" size="mini">
                    取消冻结
                </el-button>
            </div>
            <div>
                <span>操作人: </span>
                <b>{{ water.checkUserName }}</b>
            </div>
        </div>
        <div class="confirm_right" v-else-if="water.checkResult===2">
            <div>{{ water.checkTime }}</div>
            <div style="font-size: 16px;margin: 5px 0;color:#f5222d;">
                已取消
            </div>
            <div>
                <span>操作人: </span>
                <b>{{ water.checkUserName }}</b>
            </div>
        </div>
        <div class="confirm_right" v-else-if="water.checkResult===3">
            <span>{{ water.checkTime }}</span>
            <p>
                <em v-if="water.paymentPrice>0">已收款</em>
                <em v-else>已退款</em>
            </p>
            <span>
                <span>支付方式:</span>
                <em>
                    {{
                        water.checkPayType === -1 ? '转中心' : $store.getters['common/payMethodMap'][water.checkPayType]
                    }}
                </em>
            </span>
            <span>交易备注: {{ water.checkNotes }}</span>
            <span>
                <span>收款日期:</span>
                <i>{{ water.confirmCollectionTime.split(' ')[0] }}</i>
                <em class="cur" @click="handleUpdateReceiptDateClick">[修改]</em>
            </span>
            <span>
                <span>操作人:</span>
                <b> {{ water.checkUserName }}</b>
            </span>
        </div>
        <div class="confirm_right" v-else-if="water.checkResult===4">
            <div>{{ water.checkTime }}</div>
            <div>
                <em :style="'color:#f5222d;'">
                    已取消
                </em>
            </div>
            <div>
                <span>操作人: </span>
                <b>{{ water.checkUserName }}</b>
            </div>
        </div>
        <div style="padding-left: 60px;padding-top: 40px;" v-else-if="water.checkResult===5">
            <el-button type="primary"
                       size="mini" style="width: fit-content;"
                       @click="confirmCollection(true)">
                {{ water.paymentPrice > 0 ? '确认收款' : '确认退款' }}
            </el-button>
            <el-button type="danger"
                       size="mini"
                       style="width: fit-content; background-color: #f5222d;"
                       @click="confirmCollection(false)">
                取消交易
            </el-button>
        </div>
    </slot>
    <confirm-modal-vue :visible.sync="confirmCollectionModalVisible"></confirm-modal-vue>
    <update-performance-type-modal
        :visible.sync="updatePerformanceTypeModalVisible">
    </update-performance-type-modal>
    <update-receipt-date-modal :visible.sync="updateReceiptDateModalVisible">
    </update-receipt-date-modal>
    <update-performance-vesting-modal
        :visible.sync="updatePerformanceVestingModalVisible"></update-performance-vesting-modal>
    <el-dialog title="业绩归属修改记录"
               width="700px"
               custom-modal
               v-if="water.performanceUpdateFlag===1"
               :visible.sync="performanceChangeRecordModalVisible"
               append-to-body>
        <el-table :data="performanceChangeRecords">
            <el-table-column label="操作人" prop="createOperatorName"></el-table-column>
            <el-table-column label="修改前数据" prop="formerPerformancePerson"></el-table-column>
            <el-table-column label="修改后数据" prop="performancePerson"></el-table-column>
            <el-table-column label="原因" prop="reason"></el-table-column>
        </el-table>
        <el-pagination
            v-if="performanceChangeRecordPage.total>performanceChangeRecordPage.pageSize"
            style="text-align: right;margin-top:20px;"
            background
            layout="total,prev, pager, next, sizes ,jumper"
            v-bind="performanceChangeRecordPage"
            :page-sizes="[10,20,50,100]"
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle">
        </el-pagination>
    </el-dialog>
</div>
</template>
<script>
import {defineComponent, getCurrentInstance, inject, provide, ref, toRefs, watch} from 'vue'
import {
    cancelFreezeApi,
    confirmWaterApi,
    getWaterPerformanceChangeRecordPageApi
} from '../../../api/student/contractApi'
import MoneyOperation from './MoneyOperation.vue'
import ConfirmModalVue from './ConfirmModal.vue'
import UpdatePerformanceTypeModal from './UpdatePerformanceTypeModal.vue'
import UpdateReceiptDateModal from './UpdateReceiptDateModal.vue'
import UpdatePerformanceVestingModal from './UpdatePerformanceVestingModal.vue'
import {getDefaultPage} from '../../../utils'
import dayjs from 'dayjs'

export default defineComponent({
    components: {
        MoneyOperation,
        UpdatePerformanceVestingModal,
        UpdateReceiptDateModal,
        UpdatePerformanceTypeModal,
        ConfirmModalVue
    },
    props: {
        title: String,
        water: Object
    },
    setup (props) {

        const proxy = getCurrentInstance().proxy

        const confirmCollectionModalVisible = ref(false)

        const updatePerformanceTypeModalVisible = ref(false)

        const updateReceiptDateModalVisible = ref(false)

        const updatePerformanceVestingModalVisible = ref(false)

        const performanceChangeRecordModalVisible = ref(false)

        const performanceChangeRecordTableLoading = ref(false)

        const performanceChangeRecordPage = ref(getDefaultPage())

        const performanceChangeRecords = ref([])

        const cancelFreeze = () => {
            proxy.$confirm('确认要取消冻结吗？', {
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true
                        cancelFreezeApi({waterId: props.water.id})
                            .then(() => {
                                refreshContract()
                                refreshWater()
                                done()
                            }).finally(() => instance.confirmButtonLoading = false)
                    } else {
                        done()
                    }
                }
            })
        }

        const confirmWater = (result, title) => {
            proxy.$confirm(title, {
                type: 'warning',
                beforeClose (action, instance, done) {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true
                        confirmWaterApi({
                            waterId: props.water.id,
                            result
                        }).then(() => {
                            done()
                            if (result) {
                                callApproveHook()
                            }
                            refreshWater()
                        }).finally(() => instance.confirmButtonLoading = false)
                    } else {
                        done()
                    }
                }
            })
        }

        const confirmCollection = (result) => {
            if (result) {
                confirmCollectionModalVisible.value = true
            } else {
                proxy.$confirm('是否拒绝此合同流水', {
                    type: 'warning',
                    beforeClose (action, instance, done) {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            confirmWaterApi({
                                waterId: props.water.id,
                                result: false
                            }).then(() => {
                                done()
                                refreshWater()
                            }).finally(() => instance.confirmButtonLoading = false)
                        } else {
                            done()
                        }
                    }
                })
            }
        }

        const handleUpdatePerformanceTypeClick = () => {
            updatePerformanceTypeModalVisible.value = true
        }

        const handleUpdateReceiptDateClick = () => {
            updateReceiptDateModalVisible.value = true
        }

        const getPerformanceChangeRecordPage = () => {
            performanceChangeRecordTableLoading.value = true
            getWaterPerformanceChangeRecordPageApi({
                timestamp: performanceChangeRecordPage.value.timestamp,
                currentPage: performanceChangeRecordPage.value.currentPage,
                pageSize: performanceChangeRecordPage.value.pageSize,
                waterId: props.water.id
            }).then(res => {
                performanceChangeRecords.value = res.data.data.records
                performanceChangeRecordPage.value.total = res.data.data.total
            }).finally(() => performanceChangeRecordTableLoading.value = false)
        }

        const refreshPerformanceChangeRecordPage = () => {
            performanceChangeRecordPage.value = getDefaultPage()
            getPerformanceChangeRecordPage()
        }

        const sizeChangeHandle = (val) => {
            performanceChangeRecordPage.value.pageSize = val
            performanceChangeRecordPage.value.currentPage = 1
            getPerformanceChangeRecordPage()
        }

        const currentChangeHandle = (val) => {
            performanceChangeRecordPage.value.currentPage = val
            getPerformanceChangeRecordPage()
        }

        provide('getWater', () => props.water)

        provide('confirmWater', confirmWater)

        provide('confirmCollection', confirmCollection)

        provide('handleUpdateReceiptDateClick', handleUpdateReceiptDateClick)

        const refreshContract = inject('refreshContract')

        const refreshWater = inject('refreshWater')

        const callApproveHook = inject('callApproveHook')

        watch(performanceChangeRecordModalVisible, newVal => {
            if (newVal) {
                refreshPerformanceChangeRecordPage()
            }
        })

        return {
            ...toRefs(props),
            confirmCollectionModalVisible,
            updatePerformanceTypeModalVisible,
            updateReceiptDateModalVisible,
            updatePerformanceVestingModalVisible,
            performanceChangeRecordModalVisible,
            performanceChangeRecords,
            performanceChangeRecordPage,
            cancelFreeze,
            confirmWater,
            confirmCollection,
            handleUpdatePerformanceTypeClick,
            handleUpdateReceiptDateClick,
            sizeChangeHandle,
            currentChangeHandle,
            dayjs
        }
    }
})
</script>
<style lang="scss" scoped>
.water-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & + div {
        padding-top: 20px;
    }
}

.confirm_left {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    > .confirm_left-title {
        font-size: 12px;
        color: #818c99;
    }

    > .confirm_left-change-content {
        font-size: 14px;
        color: #3b3b3b;
        margin: 10px 0;

        > .confirm_left-change-amount {
            margin-top: 10px;
            padding-top: 5px;
        }
    }

    .jie {
        margin-top: 5px;
    }

    > div {
        font-size: 12px;
        color: #818c99;
        line-height: 20px;

        b {
            font-style: normal;
            color: #4fc3f7;
        }
    }

    em {
        font-style: normal;
        color: #29cb97;
    }

    p {
        font-size: 14px;
        color: #292e33;
        line-height: 18px;
        margin: 5px 0 0 0;

    }

    var {
        font-style: normal;
        color: #f7c86d;
    }

    .newDate {
        margin: 5px 0;
    }

    h5 {
        margin: 0 0;
    }

    .cur {
        cursor: pointer;
    }
}

.confirm_right {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;

    .jie {
        margin-top: 5px;
    }

    > span {
        font-size: 12px;
        color: #818c99;
        line-height: 20px;
    }

    b {
        font-style: normal;
        color: #4fc3f7;
    }

    i {
        font-style: normal;
        color: #292e33;
        margin-right: 2px;
    }

    em {
        font-style: normal;
        color: #29cb97;
    }

    p {
        font-size: 16px;
        color: #292e33;
        margin: 5px 0;
    }

    .cur {
        cursor: pointer;
    }
}
</style>
