import {defineComponent, h} from 'vue';
import currency from "currency.js";
import {createTempComponent} from "@/utils/vueUtils";
import {cloneObject, copyPropertyTo} from "@/utils/ObjectUtils";

export function getComponent(something) {
    if (something) {
        if (something instanceof Object) {
            if (typeof something.render === 'function' || typeof something.setup === 'function' || typeof something.template === 'string') {
                return something;
            } else {
                return defineComponent({
                    render() {
                        return something;
                    }
                });
            }
        } else {
            return defineComponent({
                render() {
                    return h('div', something);
                }
            });
        }
    }
}

export function openModal(modal, props = {}, slot) {
    return createTempComponent((createElement, instance) => {
        const handleClose = (val) => {
            if (!val) {
                instance.$data.visible = false;
                setTimeout(() => instance.$destroy());
            }
        };

        const on = {
            'update:visible': handleClose,
        };
        const realProps = {};
        for (let propsKey in props) {
            if (propsKey.startsWith('on')) {
                const eventName = propsKey.replace('on', '');
                on[eventName.substring(0, 1).toLowerCase() + eventName.substring(1)] = (...val) => props[propsKey](val);
            } else {
                realProps[propsKey] = props[propsKey];
            }
        }
        const data = {
            props: {...realProps, ...instance.$data},
            on
        };
        return createElement(modal, data, slot);
    }, null, {visible: true});
}

export function renderNumber(val, config = {}) {
    let {
        format,
        positiveSymbol,
        onClick,
        formatOptions,
        isZeroPositive,
        adaptiveColor,
        alwaysFail,
        alwaysPrimary
    } = config;
    if (!(typeof format === 'boolean')) {
        format = true;
    }
    if (!(typeof positiveSymbol === 'string')) {
        positiveSymbol = '';
    } else if (!positiveSymbol.endsWith(' ')) {
        positiveSymbol += ' ';
    }
    if (!(typeof formatOptions === 'object')) {
        formatOptions = {
            separator: ',',
            symbol: ''
        };
    } else {
        copyPropertyTo(formatOptions, {
            separator: ',',
            symbol: ''
        });
    }
    if (!(typeof isZeroPositive === 'boolean')) {
        isZeroPositive = true;
    }
    if (!(typeof adaptiveColor === 'boolean')) {
        adaptiveColor = true;
    }
    if (!(typeof alwaysPrimary === 'boolean')) {
        alwaysPrimary = false;
    }
    if (!(typeof alwaysFail === 'boolean')) {
        alwaysFail = false;
    }
    const data = {
        style: {
            cursor: 'pointer'
        },
        on: {
            click() {
                typeof onClick === 'function' && onClick();
            }
        }
    };
    if (!val) {
        val = 0;
    }
    if (Array.isArray(val)) {
        const numberArray = val;
        let currencyVal = currency(0);
        for (let number of numberArray) {
            currencyVal = currencyVal.add(number);

        }
        val = currencyVal.value;
    }
    let content;
    if (val >= 0) {
        if (adaptiveColor) {
            data.class = 'primary-text-color';
        }
        content = ((!isZeroPositive && val === 0) ? '' : positiveSymbol) + (val !== 0 && format ? currency(val, formatOptions).format() : val);
    } else {
        if (adaptiveColor) {
            data.class = 'fail-text-color';
        }
        content = '- ' + (format ? currency(Math.abs(val), formatOptions).format() : Math.abs(val));
    }
    if (alwaysPrimary) {
        data.class = 'primary-text-color';
    }
    if (alwaysFail) {
        data.class = 'fail-text-color';
    }
    return h('span', data, content);
}
