<template>
<div>
    <el-dialog custom-modal
               title="修改收款日期"
               width="500px"
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               append-to-body>
        <el-form ref="formRef" :rules="fromRules" :model="formData" label-position="top" size="mini">
            <el-form-item label="旧收款日期">
                <div>{{ water.confirmCollectionTime ? water.confirmCollectionTime.split(' ')[0] : '' }}</div>
            </el-form-item>
            <el-form-item prop="confirmCollectionTime" label="新收款日期">
                <el-date-picker value-format="yyyy-MM-dd" v-model="formData.confirmCollectionTime"
                                :picker-options="pickerOptions"
                                style="width: 100%">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="reason" label="原因">
                <el-input type="textarea" v-model="formData.reason" :maxlength="250" show-word-limit></el-input>
            </el-form-item>
        </el-form>
        <template v-slot:footer>
            <el-button class="ballet-modal-button" @click="$emit('update:visible',false)">取消</el-button>
            <el-button class="ballet-modal-button"
                       :disabled="water.confirmCollectionTime&&formData.confirmCollectionTime===water.confirmCollectionTime.split(' ')[0]"
                       type="primary" :loading="confirmLoading" @click="handleConfirm">
                确认
            </el-button>
        </template>
    </el-dialog>
</div>
</template>
<script>
import {computed, defineComponent, inject, ref} from 'vue'
import {updateReceiptDateApi} from '../../../api/student/contractApi'

export default defineComponent({
    props: {
        visible: Boolean
    },
    emits: ['update:visible'],
    setup (props, {emit}) {

        const formRef = ref()

        const fromRules = ref({
            confirmCollectionTime: [{required: true, message: '新收款时间是必填的'}],
            reason: [{required: true, message: '原因是必填的'}]
        })

        const formData = ref({
            performanceType: undefined,
            reason: undefined
        })

        const pickerOptions = {
            disabledDate (time) {
                return time.getTime() > Date.now()
            }
        }

        const confirmLoading = ref(false)

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate()
                .then(() => updateReceiptDateApi({...formData.value, waterId: water.value.id}))
                .then(() => {
                    emit('update:visible', false)
                    refreshWater()
                    refreshContractLog()
                })
                .finally(() => {
                    confirmLoading.value = false
                })
        }

        const getWater = inject('getWater')

        const refreshWater = inject('refreshWater')

        const refreshContractLog = inject('refreshContractLog')

        const water = computed(() => {
            const result = getWater()
            return result ? result : {}
        })

        return {
            formRef,
            fromRules,
            formData,
            pickerOptions,
            water,
            confirmLoading,
            handleConfirm
        }
    }
})
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 !important;
    color: #3b3b3b !important;
}
</style>
