<template>
    <div>
        <el-dialog
            title=""
            :visible.sync="visible"
            width="300px"
            :before-close="closeDelContractModal"
            custom-class="del-contract-modal"
            append-to-body
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <p>删除合同将清空该合同名下所有记录（流水等记录），且不能恢复。</p>
            <span>请输入确认需要删除的合同编号</span>
            <el-input v-model="contractNum" size="mini" placeholder=""></el-input>

            <div class="btnBox">
                <div @click="closeDelContractModal"><span>取消</span></div>
                <div><span>确认</span></div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'BalletVueDelContract',
    props:['visible','closeDelContractModal'],

    data() {
        return {
            contractNum:'',
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
span,p{
    font-size: 14px;
    line-height: 14px;
    color: #292e33;
}
p{
    line-height: 18px;
    margin:0 0 5px 0;
}
.btnBox{
    position: absolute;
    width: 100%;
    height: 35px;
    border-top: 1px solid #ebeef5;
    display: flex;
    align-items: center;
    left: 0;
    bottom: 0;
    div{
        flex: 1;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        span{
            color: #29cb97;
            font-size: 12px;
        };
        &+div{
            border-left: 1px solid #ebeef5;
        }
        &:hover{
            background: #f5f7fa;
        }
    }
}
</style>
<style lang="scss">
.del-contract-modal{
    .el-dialog__body{
        padding: 10px;
        padding-bottom: 50px;
        box-sizing: border-box;
        position: relative;
    }
    .el-dialog__header{
        display: none;
    }
    .el-dialog__footer{
        display: none;
    }
}
</style>
