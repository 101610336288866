<template>
<div>
    <time-line :records="records">
        <template v-slot:default="{record}">
            <div class="timeline-content-box-title">
                                        <span :class="'color-' + record.type">
                                         [ {{ $store.getters['common/listFollowUpRecordTypeListMap'][record.type] }} ]
                                        </span>
                <span>&nbsp;</span>
                <span style="color: #818c99;">操作人：</span>
                <span>{{ record.createOperatorName }}</span>
                <i v-if="record.type==='Collect'"
                   style="cursor: pointer;font-size: 15px;margin-left: 10px;"
                   class="el-icon-edit primary-text-color"
                   @click="openUpdateModal(record)"></i>
            </div>
            <div class="timeline-content-box-main">
                <template v-if="record.content&&record.content.startsWith('abandon;;;')">
                    <div class="content" style="flex-direction: row;">
                        <div class="label">名单放弃原因:</div>
                        <div class="text">{{ record.content.split(';;;')[1] }}</div>
                    </div>
                    <div class="content">
                        <div class="text">{{ record.content.split(';;;')[2] }}</div>
                    </div>
                </template>
                <template v-else>
                    <div v-if="record.type==='Collect'">
                        <div class="item">
                            <div class="label">采单时间：</div>
                            <div class="text">{{ record.purchaseOrderDate.split(' ')[0] }}</div>
                        </div>
                        <div class="item">
                            <div class="label">评级：</div>
                            <div class="text" style="display: flex;gap: 5px;align-items: center;">
                                <icon-svg name="star" v-for="i in record.listRating"
                                          :key="i"></icon-svg>
                            </div>
                        </div>
                        <div class="item" style="width: 100%;">
                            <div class="label">来源/渠道：</div>
                            <div class="text">{{ record.sourceNames }}</div>
                        </div>
                        <div class="item">
                            <div class="label">采单备注：</div>
                            <div class="text">{{ record.remark }}</div>
                        </div>
                    </div>
                    <div v-else-if="record.type==='FollowUp'">
                        <div class="item">
                            <div class="label">跟进状态：</div>
                            <div class="text">
                                {{ $store.getters['common/followStateMap'][record.followUpStatus] }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="label">名单状态：</div>
                            <div class="text">
                                <span v-if="record.listStatus==='AwaitADecision'">
                                    待定
                                </span>
                                <span class="primary-text-color"
                                      v-else-if="record.listStatus==='Efficiently'">
                                    有效
                                </span>
                                <span style="color: red;" v-else-if="record.listStatus==='NotValid'">
                                    无效
                                </span>
                            </div>
                        </div>
                        <div class="item">
                            <div class="label">结果原因：</div>
                            <div class="text">{{ record.followUpResultReason ? record.followUpResultReasonName : '默认'}}</div>
                        </div>
                        <div class="item">
                            <div class="label">评级：</div>
                            <div class="text" style="display: flex;gap: 5px;align-items: center;">
                                <img alt=""
                                     style="width: 1em;height: 1em;"
                                     src="../../../icons/svg/icon-star.svg"
                                     v-for="i in record.listRating"
                                     :key="i">
                                </img>
                            </div>
                        </div>
                        <div class="item" v-if="record.nextTime">
                            <div class="label">下次跟进时间：</div>
                            <div class="text">{{ record.nextTime }}</div>
                        </div>
                    </div>
                    <div class="content" v-if="record.type!=='Collect'">
                        <div class="label">跟进内容:</div>
                        <div class="text">{{ record.content }}</div>
                    </div>
                </template>
                <ul v-if="Array.isArray(record.images)&&record.images.length" class="images-wrapper">
                    <li v-for="(image,index) in record.images" :key="index">
                        <el-image :preview-src-list="[image]" class="image-item" alt="" :src="image"></el-image>
                    </li>
                </ul>
            </div>
        </template>
    </time-line>
    <update-purchase-order-record-modal :visible.sync="updatePurchaseOrderRecordModalVisible"
                                        :data="updatePurchaseOrder"
                                        @updated="$emit('updated')">
    </update-purchase-order-record-modal>
</div>
</template>
<script>
import {defineComponent, ref, toRefs} from 'vue';
import TimeLine from '../TimeLine.vue';
import UpdatePurchaseOrderRecordModal from './UpdatePurchaseOrderRecordModal.vue';

export default defineComponent({
    components: {UpdatePurchaseOrderRecordModal, TimeLine},
    props: {records: Array},
    emits: ['updated'],
    setup (props) {

        const updatePurchaseOrderRecordModalVisible = ref(false);

        const updatePurchaseOrder = ref({});

        const openUpdateModal = (record) => {
            updatePurchaseOrderRecordModalVisible.value = true;
            updatePurchaseOrder.value = {
                recordId: record.id,
                purchaseOrderDate: record.purchaseOrderDate,
                remark: record.remark
            };
        };

        return {
            ...toRefs(props),
            updatePurchaseOrderRecordModalVisible,
            updatePurchaseOrder,
            openUpdateModal
        };
    }
});
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variables";

.timeline-content-box-title {
    border-bottom: 1px solid #edf0f2;
    padding-bottom: 10px;
    margin: 0 1em;
}

.timeline-content-box-main {
    margin: 0 10px;
    padding: 5px 0;
}

.timeline-content-box-main > div {
    display: flex;
    flex-wrap: wrap;
}

.timeline-content-box-main > div > .item {
    width: 50%;
    margin-bottom: 5px;
    display: flex;
}

.timeline-content-box-main > .content {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.timeline-content-box-main > div .label {
    color: #818c99;
}

.timeline-content-box-main > div .text {
    color: #292e33;
}

.color-Collect {
    color: #faad14;
}

.color-Allocate {
    color: $--color-primary;
}

.color-FollowUp {
    color: #4fc3f7;
}

.color-System {
    color: $--color-primary;
}

.color-PhoneCall {

}

.images-wrapper {
    display: flex;
    gap: 12px;
    padding: 10px 0;
}

.images-wrapper .image-item {
    height: 102px;
    border: 1px solid #edf0f2;
}
</style>
