import http from '../../utils/httpRequest'

export function updateLikeSubjectApi (data) {
    return http.put('/admin/balletstudent/updateLikeSubject', data)
}

export function getClassPageApi (params) {
    return http.get('/admin/balletstudent/classPage', {params})
}

export function getClassPageByClassTypeApi (params) {
    return http.get('/admin/balletstudent/classPageByClassType', {params})
}

export function getStudentDetailApi (id) {
    return http.get(`/admin/balletstudent/detail/${id}`)
}

export function allocateConsultantApi (data) {
    return http.put(`/admin/balletstudent/allocateConsultant`, data)
}

export function allocateMarketApi (data) {
    return http.put(`/admin/balletstudent/allocateMarket`, data)
}

export function getFamilyApi (params) {
    return http.get('/admin/balletstudent/family', {params})
}

export function addFamilyMemberApi (data) {
    return http.post('/admin/balletstudent/addFamilyMember', data)
}

export function approvalPassedAddFamilyMemberApi (data) {
    return http.put('/admin/balletstudent/approvalPassedAddFamilyMember', data)
}

export function approvalRejectAddFamilyMemberApi (data) {
    return http.put('/admin/balletstudent/approvalRejectAddFamilyMember', data)
}

export function addTagToStudentApi (data) {
    return http.post('/admin/balletstudent/addTagToStudent', data)
}

export function deleteTagFromStudentApi (data) {
    return http.delete('/admin/balletstudent/deleteTagFromStudent', {data})
}

export function operationRecordPageApi (params) {
    return http.get('/admin/balletstudent/operationRecordPage', {params})
}

export function deleteVipStudentApi (id) {
    return http.delete(`/admin/balletstudent/deleteVip/${id}`)
}

export function deleteFamilyApi (id) {
    return http.delete(`/admin/balletstudent/deleteFamily/${id}`)
}

export function updateVipStudentApi (data) {
    return http.put('/admin/balletstudent', data)
}

export function updateMainContactApi (data) {
    return http.put('/admin/balletstudent/updateMainContact', data)
}

export function getStudentClassPhotoLibraryApi (params) {
    return http.get('/admin/balletstudent/photo', {params})
}
