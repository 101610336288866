import store from '@/store';
import Vue from 'vue';

const beforeEnter = (to, from, next) => {
    if (!(store.getters['user/getLoadStatePromise'] instanceof Promise)) {
        store.commit('user/getUserInfo');
    }
    store.getters['user/getLoadStatePromise'].then(() => {
        if (Vue.prototype.hasMenuKey(to.path)) {
            next();
        } else {
            next('/401');
        }
    });
};

export {
    beforeEnter
};
