<template>
<div>
    <el-dialog
        class="asd"
        :modal="true"
        width="675px"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        title="改套餐"
        :close-on-click-modal="false"
        custom-class="changeCombo-dialog"
        append-to-body>
        <div class="box" @click="openChangeContractModal">
            <p>改合同</p>
            <span>在原合同的基础上修改金额及课时信息</span>
        </div>
        <div class="box" @click="openChangePackageModal">
            <p>更换套餐</p>
            <span>更改当前合同的套餐类型或者直接更换其他套餐</span>
        </div>
    </el-dialog>
    <change-contract :visible.sync="changeContractModalVisible" @back="$emit('update:visible', true)"></change-contract>
    <change-package :visible.sync="changePackageModalVisible" @back="$emit('update:visible', true)"></change-package>
</div>
</template>

<script>
import ChangeContract from './ChangeContract.vue'
import ChangePackage from './ChangePackage.vue'

export default {
    components: {
        ChangePackage,
        ChangeContract
    },
    props: ['visible'],
    emits: ['update:visible', 'updated'],
    data () {
        return {
            changeContractModalVisible: false,
            changePackageModalVisible: false
        }
    },

    mounted () {

    },

    methods: {
        openChangeContractModal () {
            this.changeContractModalVisible = true
            this.$emit('update:visible', false)
        },
        openChangePackageModal(){
            this.changePackageModalVisible = true
            this.$emit('update:visible', false)
        }
    },
}
</script>

<style lang="scss" scoped>
.box {
    height: 90px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    &:nth-child(1) {
        background: #29cb97;

        p {
            font-size: 18px;
            color: #fff;
            margin: 0;
        }

        span {
            font-size: 14px;
            color: #fff;
        }
    }

    &:nth-child(2) {
        border: 1px solid #edf0f2;
        margin-top: 20px;

        p {
            font-size: 18px;
            color: #29cb97;
            margin: 0;
        }

        span {
            font-size: 14px;
            color: #292e33;
        }
    }
}
</style>
<style lang="scss">
.changeCombo-dialog {
    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #ccc;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 40px;
        color: #292e33;

        i::before {
            position: relative;
            top: -10px;
        }

        span {
            font-size: 14px;
        }
    }

    .el-dialog__body {
        padding: 10px 15px 20px 15px;
        box-sizing: border-box;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
        overflow: hidden;
        border-top: 1px solid #ccc;
    }
}

</style>
