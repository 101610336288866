<template>
<div class="timeline-container">
    <div v-for="(record,index) in records" :key="record[keyProperty]?record[keyProperty]:index" class="timeline-item">
        <div class="timeline-item-time">
            <div style="margin-top: 13px;line-height: 1rem;">{{ record[timeProperty] }}</div>
        </div>
        <div class="timeline-content-box">
            <slot :record="record"></slot>
        </div>
    </div>
</div>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
    props: {
        records: Array,
        timeProperty: {
            type: String,
            default () {
                return 'createTime'
            }
        },
        keyProperty: {
            type: String,
            default () {
                return 'id'
            }
        }
    }
})
</script>
<style scoped>
.timeline-container {
    width: 85%;
    margin-left: 12%;
    position: relative;
}

.timeline-item {
    display: flex;
}

.timeline-item:first-child {
    padding-top: 20px;
}

.timeline-item:not(:first-child) > .timeline-content-box, .timeline-item:not(:first-child) > .timeline-item-time::after {
    margin-top: 30px;
}

.timeline-item:not(:first-child) > .timeline-item-time {
    padding-top: 30px;
}

.timeline-item:last-child > .timeline-item-time::before {
    height: 43px;
}

.timeline-item-time {
    width: 160px;
    position: relative;
    font-size: 12px;
    color: #818c99;
}

.timeline-item-time::after {
    content: "";
    display: block;
    position: absolute;
    top: 13px;
    right: -3px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #cccccc;
}

.timeline-item-time::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #cccccc;
}

.timeline-item:first-child > .timeline-item-time::before {
    top: -20px !important;
    height: calc(100% + 20px);
}

.timeline-content-box {
    background-color: #ffffff;
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    width: calc((100% - 160px) * .8);
    margin-left: 20px;
    position: relative;
    color: #292e33;
}

.timeline-content-box::before {
    content: "";
    display: block;
    background-color: #ffffff;
    position: absolute;
    left: -6px;
    top: 13px;
    width: 12px;
    height: 22px;
    margin-top: -8px;
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
    box-shadow: inherit;
}

.timeline-item:first-child:last-child > .timeline-item-time::before {
    height: 40px !important;
    top: -20px !important;
}
</style>
