<template>
    <div class="follow-up-record-container">
        <div class="follow-up-record-header">
            <div class="header-form">
                <el-form inline size="mini">
                    <el-form-item>
                        <el-select v-model="queryFormData.type">
                            <el-option :value="undefined" label="全部记录"></el-option>
                            <el-option v-bind="type"
                                       v-for="(type,index) in $store.state.common.listFollowUpRecordTypeList"
                                       :key="index">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="跟进方式：">
                        <el-select v-model="queryFormData.followUpMethod" clearable>
                            <el-option :value="followUpMethod.value"
                                       :label="followUpMethod.label"
                                       :key="followUpMethod.value"
                                       v-for="followUpMethod in $store.getters['common/followUpMethod']">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker value-format="yyyy-MM-dd"
                                        v-model="queryFormData.timeRange"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                        type="daterange">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <div class="call-record">
                <el-button type="primary" class="ballet-modal-button">待处理通话记录（0）</el-button>
            </div>
        </div>
        <div v-loading="state==='refreshPage'">
            <div v-if="state==='refreshError'" @click="getPage"
                 class="present" style="cursor: pointer;">
                加载失败,点击重试
            </div>
            <template v-else>
                <list-follow-up-record-timeline :records="records"
                                                @updated="getPage">
                </list-follow-up-record-timeline>
                <div v-if="state==='loadMore'" style="text-align: center; padding: 20px 0;">
                    <i class="el-icon-loading"></i>
                </div>
                <div v-else-if="state==='loadMoreError'"
                     @click="getPage"
                     class="present"
                     style="cursor: pointer;">
                    加载失败,点击重试
                </div>
                <div class="present" v-else-if="total<=records.length">
                    无更多数据
                </div>
            </template>
        </div>
        <div class="add-record-button">
            <el-button style="position: sticky;bottom: 30px;"
                       type="primary"
                       v-if="(allocationState===2||allocationState===4)&&followUpPhase!=='Contracted'"
                       @click="addListFollowUpRecordModalVisible=true">
                添加记录
            </el-button>
        </div>
        <el-dialog width="32%"
                   custom-modal
                   title="添加跟进记录"
                   append-to-body
                   :visible.sync="addListFollowUpRecordModalVisible">
            <el-form :model="addFormData" size="mini" :rules="formRules" ref="form">
                <el-form-item label="客户评级：" style="width: 100%;" prop="listRating">
                    <div style="text-align: left;font-size: 20px;display: flex;gap: 5px;align-items: center;">
                        <div v-for="i in 5" :key="i" style="cursor: pointer;" @click="addFormData.listRating=i">
                            <img style="width: 20px;height: 20px;" alt="" v-if="addFormData.listRating>=i"
                                 src="../../../icons/svg/icon-star.svg">
                            <img style="width: 20px;height: 20px;" alt="" v-else
                                 src="../../../icons/svg/icon-star-empty.svg">
                        </div>
                    </div>
                </el-form-item>
                <el-form :model="addFormData" size="mini" :rules="formRules" label-position="top"
                         style="display: flex;flex-wrap: wrap;" ref="_form">
                    <el-form-item label="跟进方式：" prop="followUpMethod" style="width: 45%;">
                        <el-select style="width: 100%;" v-model="addFormData.followUpMethod" clearable>
                            <el-option :value="followUpMethod.value"
                                       :label="followUpMethod.label"
                                       :key="followUpMethod.value"
                                       v-for="followUpMethod in $store.getters['common/followUpMethod']">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="跟进进度：" prop="followUpSpeed" style="width: 45%;margin-left: 4%;">
                        <el-select style="width: 100%;" v-model="addFormData.followUpSpeed">
                            <el-option :value="followUpStatus.value"
                                       :label="followUpStatus.label"
                                       :key="followUpStatus.value"
                                       v-for="followUpStatus in $store.getters['common/followUpStatusPoint'][0].followUpStatusList">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="跟进结果：" style="width: 45%;" prop="listStatus">
                        <div style="display: flex;gap: 8px;">
                            <div @click="addFormData.listStatus='AwaitADecision'"
                                 :class="['list-status-item',{active: addFormData.listStatus==='AwaitADecision'}]">
                                待定
                            </div>
                            <div @click="addFormData.listStatus='Efficiently'"
                                 :class="['list-status-item','primary-text-color',{active: addFormData.listStatus==='Efficiently'}]">
                                有效
                            </div>
                            <div @click="addFormData.listStatus='NotValid'"
                                 :class="['list-status-item',{active: addFormData.listStatus==='NotValid'}]"
                                 style="color: red;">
                                无效
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="结果原因：" style="width: 45%;margin-left: 4%;" prop="followUpResultReason">
                        <el-select v-model="addFormData.followUpResultReason" style="width: 100%;">
                            <el-option :value="listStatusChangeReason.value"
                                       :label="listStatusChangeReason.label"
                                       :key="listStatusChangeReason.value"
                                       v-for="listStatusChangeReason in listStatusChangeReasons">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="跟进内容：" style="width: 100%;" prop="content">
                        <el-input type="textarea" v-model="addFormData.content"></el-input>
                    </el-form-item>
                    <el-form-item label="下次跟进时间：" style="width: 100%;" prop="nextTime">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="请选择时间"
                                        type="datetime"
                                        v-model="addFormData.nextTime">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form-item label="上传图片：" style="width: 100%;">
                    <el-upload
                        :limit="9"
                        :action="baseUrl+'/file/upload'"
                        :headers="{'Access-Token':localStorage.getItem('token')}"
                        :on-success="handleUploadFileSuccess"
                        v-model:file-list="addFormDataFileList"
                        list-type="picture-card">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <div style="display: flex;justify-content: space-between;">
                    <div style="color: red;">提示：记录提交后不可更改</div>
                    <div>
                        <el-button class="ballet-modal-button" @click="addListFollowUpRecordModalVisible=false">
                            取消
                        </el-button>
                        <el-button class="ballet-modal-button" type="primary"
                                   :loading="confirmLoading"
                                   @click="addListFollowUpRecord">
                            确定
                        </el-button>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {getListFollowUpRecordPageApi, addListFollowUpRecordApi} from '../../../api/student/listFollowUpRecordApi';
import UpdatePurchaseOrderRecordModal from './UpdatePurchaseOrderRecordModal.vue';
import TimeLine from '../TimeLine.vue';
import ListFollowUpRecordTimeline from './ListFollowUpRecordTimeline.vue';
import http from '../../../utils/httpRequest';
import {getDefaultPage} from '@/utils';

export default {
    emits: ['updated'],
    components: {
        ListFollowUpRecordTimeline,
        TimeLine,
    },
    props: {
        listId: {
            type: Number,
            required: true
        },
        formListStatus: {
            type: String,
            required: true
        },
        listStatus: {
            type: String,
            required: true
        },
        allocationState: {
            type: Number,
            required: true
        },
        followUpPhase: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            baseUrl: http.defaults.baseURL,
            queryFormData: {timeRange: []},
            addFormData: {},
            addFormDataFileList: [],
            page: getDefaultPage(),
            total: 0,
            records: [],
            state: 'refreshPage',
            addListFollowUpRecordModalVisible: false,
            confirmLoading: false,
            formRules: {
                listRating: [{required: true, message: '评分是必填的'}],
                followUpSpeed: [{required: true, message: '跟进进度是必填的'}],
                listStatus: [{required: true, message: '跟进结果是必填的'}],
                followUpResultReason: [{required: true, message: '结果原因是必填的'}],
                content: [{required: true, message: '跟进内容是必填的'}]
            },
        };
    },
    methods: {
        refreshPage() {
            this.page = getDefaultPage();
            this.records = [];
            this.getPage();
            this.state = 'refreshPage';
        },
        getPage() {
            const query = {
                currentPage: this.page.currentPage,
                pageSize: this.page.pageSize,
                timestamp: this.page.timestamp,
                ...this.queryFormData
            };
            delete query.timeRange;
            if (this.queryFormData.timeRange.length) {
                query.startDate = this.queryFormData.timeRange[0] + ' 00:00:00';
                query.endDate = this.queryFormData.timeRange[1] + ' 00:00:00';
            }
            getListFollowUpRecordPageApi(this.listId, query)
                .then(res => {
                    this.page.currentPage = res.data.data.current;
                    this.page.pageSize = res.data.data.size;
                    this.total = res.data.data.total;
                    if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                        if (this.page.currentPage === 1) {
                            this.records = res.data.data.records;
                        } else {
                            for (let record of res.data.data.records) {
                                this.records.push(record);
                            }
                        }
                    }

                    this.state = 'ok';
                })
                .catch(() => this.state += 'Error');
        },
        nextPage() {
            if (this.total <= this.records.length) {
                return;
            }
            this.state = 'loadingMore';
            this.page.currentPage++;
            this.getPage();
        },
        handleUploadFileSuccess(response, file, fileList) {
            if (response.status) {
                this.addFormDataFileList = fileList;
            } else {
                if (fileList.length) {
                    fileList[fileList.length - 1] = {...fileList[fileList.length - 1], status: 'error'};
                    this.addFormDataFileList = fileList;
                }
            }
        },
        addListFollowUpRecord() {
            this.confirmLoading = true;
            this.$refs.form.validate()
                .then(() => this.$refs._form.validate())
                .then(() => {
                    const data = {...this.addFormData, listId: this.listId};
                    data.images = this.addFormDataFileList.map(item => {
                        return item.response.data;
                    });
                    return addListFollowUpRecordApi(data);
                })
                .then(() => {
                    this.$emit('updated');
                    this.addListFollowUpRecordModalVisible = false;
                })
                .finally(() => this.confirmLoading = false);
        }
    },
    created() {
        this.refreshPage();
    },
    watch: {
        addListFollowUpRecordModalVisible(newVal) {
            if (newVal) {
                this.addFormData = {listRating: 5, listStatus: this.formListStatus};
                setTimeout(() => {
                    if (this.$refs.form) {
                        this.$refs.form.clearValidate();
                        this.$refs._form.clearValidate();
                    }
                });
            }
        },
        queryFormData: {
            deep: true,
            handler() {
                this.refreshPage();
            }
        },
        addFormData: {
            deep: true,
            handler(newVal) {
                setTimeout(() => {
                    if (this.addFormData.followUpResultReason && !this.listStatusChangeReasons.find(item => item.value === newVal.followUpResultReason)) {
                        this.addFormData.followUpResultReason = undefined;
                    }
                });
            }
        },
    },
    computed: {
        listStatusChangeReasons() {
            if (this.addFormData.listStatus) {
                const groups = this.$store.getters['common/listStatusChangeReasonGroup'];
                for (let i = 0; i < groups.length; i++) {
                    if (groups[i].listStatus === this.addFormData.listStatus) {
                        return groups[i].listStatusChangeReasons;
                    }
                }
            }
            return [];
        }
    }
};
</script>
<style scoped>
.follow-up-record-header {
    position: sticky;
    padding: 10px;
    top: 45px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: #f5f7fa;
    z-index: 1000;
}

.call-record {
    display: flex;
    gap: 3px;
    align-items: center;
}

.add-record-button {
    position: absolute;
    right: 50px;
    bottom: 30px;
    z-index: 2000;
}

.present {
    text-align: center;
    padding: 20px 0;
    color: #818c99;
    font-size: 14px;
}

.list-status-item {
    text-align: center;
    padding: 5px 20px;
    border: 1px solid #edf0f2;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    line-height: 1rem;
}

</style>
<style lang="scss">
@import "../../../assets/scss/variables";

.header-form > .el-form.el-form--inline > .el-form-item.el-form-item--mini {
    margin-bottom: 0;
}

.list-status-item.active {
    border: 1px solid $--color-primary !important;
}
</style>
