<template>
<div class="operation-record-main" v-loading="loading===1">
    <time-line :records="records" time-property="createDate">
        <template v-slot="{record}">
            <div>{{ record.createUserName + ' ' + record.formatContent }}</div>
        </template>
    </time-line>
    <div v-if="loading===2" style="padding: 20px 0;text-align: center;">
        <i class="el-icon-loading"></i>
    </div>
    <div v-else-if="page.total<=records.length" style="padding: 20px 0;text-align: center;">暂无更多数据</div>
</div>
</template>
<script>
import {defineComponent, onMounted, ref, toRefs} from 'vue'
import {operationRecordPageApi} from '../../../api/student/studentApi'
import {getDefaultPage} from '../../../utils'
import TimeLine from '../../List/TimeLine.vue'

export default defineComponent({
    components: {TimeLine},
    props: {studentId: Number},
    setup (props) {

        const page = ref(getDefaultPage())

        const records = ref([])

        const loading = ref(0)

        const refreshPage = () => {
            page.value = getDefaultPage()
            loading.value = 1
            records.value = []
            getPage()
        }

        const getPage = () => {
            operationRecordPageApi({
                studentId: props.studentId,
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp,
            }).then(res => {
                if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                    for (let record of res.data.data.records) {
                        records.value.push(record)
                    }
                }
                page.value.currentPage = res.data.data.current
                page.value.pageSize = res.data.data.pageSize
                page.value.total = res.data.data.total
            }).finally(() => loading.value = 0)
        }

        const nextPage = () => {
            if (page.value.total <= records.value.length) {
                return
            }
            loading.value = 2
            page.value.currentPage++
            getPage()
        }

        onMounted(() => {
            refreshPage()
        })

        return {
            ...toRefs(props),
            page,
            records,
            loading,
            nextPage
        }
    }
})
</script>
<style scoped>
.operation-record-main {
    min-height: inherit;
}
</style>
