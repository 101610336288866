import http from '../../utils/httpRequest';

export function getListPageApi(params) {
    return http.get('/admin/listtable/page', {params});
}

export function getCountInfoApi(params) {
    return http.get('/admin/listtable/countInfo', {params});
}

export function createListApi(data) {
    return http.post('/admin/listtable/create', data, {});
}

export function allocateConsultantApi(data) {
    return http.put('/admin/listtable/allocateConsultant', data);
}

export function allocateMarketApi(data) {
    return http.put('/admin/listtable/allocateMarket', data);
}

export function transferOutApi(data) {
    return http.post('/admin/listtable/transferOut', data);
}

export function batchTransferOutApi(data) {
    return http.post('/admin/listtable/batchTransferOut', data);
}

export function updateSourceApi(data) {
    return http.put('/admin/listtable/updateSource', data);
}

export function batchUpdateSourceApi(data) {
    return http.put('/admin/listtable/batchUpdateSource', data);
}

export function activateListApi(listId) {
    return http.put(`/admin/listtable/activate/${listId}`);
}

export function updateRatingApi(data) {
    return http.put('/admin/listtable/updateRating', data);
}

export function recallApi(data) {
    return http.put('/admin/listtable/recall', data);
}

export function abandonApi(data) {
    return http.put('/admin/listtable/abandon', data);
}

export function acceptApi(data) {
    return http.put('/admin/listtable/accept', data);
}

export function getPhoneNumberStateApi(phoneNumber) {
    return http.get('/admin/listtable/phoneNumberState', {params: {phoneNumber}});
}

export function getDetailApi(listId) {
    return http.get(`/admin/listtable/tableInfo/${listId}`);
}

export function reserveClassApi(data) {
    return http.post('/admin/listtable/reserveClass', data);
}

export function getListClassPageApi(params) {
    return http.get('/admin/listtable/classPage', {params});
}

export function getListOperationRecordPageApi(params) {
    return http.get('/admin/listtable/operationRecordPage', {params});
}

export function updateLikeSubjectApi(data) {
    return http.put('/admin/listtable/updateLikeSubject', data);
}

export function addStudentApi(data) {
    return http.post('/admin/listtable/addStudent', data);
}

export function updateStudentApi(data) {
    return http.put('/admin/listtable/updateStudent', data);
}

export function deleteStudentApi(id) {
    return http.delete(`/admin/listtable/deleteStudent/${id}`);
}

export function updateListApi(data) {
    return http.put(`/admin/listtable`, data);
}

export function updateMainContactPhoneNumber(data) {
    return http.put('/admin/listtable/updateMainContactPhoneNumber', data);
}

export function deleteListApi(id) {
    return http.delete(`/admin/listtable/${id}`);
}

export function getListFollowUpDetailRecordsApi(params) {
    return http.get('/followUpRecord/list/detailPage', {params});
}
