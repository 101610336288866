<template>
<!-- 该页面不接收id作为参数,修改完之后把选择的值传递给父组件,由父组件根据存的id进行执行相应的操作 -->
<el-dialog
    title="编辑"
    :visible="visible"
    @update:visible="val=>$emit('update:visible',val)"
    width="950px"
    custom-class="changeSignInStatus-dialog"
    append-to-body>
    <el-form class="state" label-position="top">
        <el-form-item label="签到状态:">
            <el-radio-group v-model="studentClassStatus" class="radio_box">
                <el-radio v-for="a in btnList"
                          :key="a.key"
                          :label="a.key"
                          v-if="a.isShow">
                    {{ a.name.split('###')[0] }}
                    <em v-if="a.name.split('###')[1]">{{ a.name.split('###')[1] }}</em>
                </el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
    <span slot="footer">
            <el-button size="mini" @click="$emit('update:visible',false)">取 消</el-button>
            <el-button size="mini"
                       type="primary"
                       @click="handleConfirm()"
                       :loading="confirmLoading"
                       :disabled="status===studentClassStatus">
                确 定
            </el-button>
        </span>
</el-dialog>
</template>

<script>
import {changStudentClassStatusApi} from '../../../api/class/classApi'
import {defineComponent, getCurrentInstance, inject, ref, toRefs, watch} from 'vue'

export default defineComponent({
    props: {visible: Boolean, studentClassId: Number, status: String},
    emits: ['update:visible'],
    setup (props, {emit}) {

        const currentInstance = getCurrentInstance()

        const studentClassStatus = ref()

        const remark = ref()

        const btnList = ref([
            {
                name: '签到###(未迟到)',
                isShow: true,
                key: 'SignedIn',
            },
            {
                name: '取消签到',
                isShow: true,
                key: 'NoClasses',
            },
            {
                name: '签到###(迟到)',
                isShow: true,
                key: 'SignedInLateness',
            },
            {
                name: '已请假',
                isShow: true,
                key: 'LeaveOfAbsence',
            },
            {
                name: '已旷课',
                isShow: true,
                key: 'Absenteeism',
            },
            {
                name: '已取消',
                isShow: true,
                key: 'Canceled',
            },
            {
                name: '请假###(不扣课节)',
                isShow: true,
                key: 'LeaveOfAbsenceNoConsume',
            }
        ])

        const confirmLoading = ref(false)

        const handleConfirm = (force = false) => {
            confirmLoading.value = true
            changStudentClassStatusApi({
                id: props.studentClassId,
                remark: remark.value,
                status: studentClassStatus.value,
                force
            }).then(res => {
                if (res.data.code === 40110) {
                    currentInstance.proxy.$confirm('当前课程已满人,是否强制约课?', {
                        type: 'warning',
                        beforeClose (action, instance, done) {
                            if (action === 'confirm') {
                                setTimeout(() => {
                                    emit('update:visible', true)
                                    handleConfirm(true)
                                })
                            }
                            done()
                        }
                    })
                } else {
                    refreshClass()
                    emit('update:visible', false)
                }
            }).finally(() => confirmLoading.value = false)
        }

        const refreshClass = inject('refreshClass')

        watch(() => props.visible, newVal => {
            if (newVal) {
                studentClassStatus.value = props.status
            }
        })

        return {
            ...toRefs(props),
            studentClassStatus,
            remark,
            btnList,
            confirmLoading,
            handleConfirm
        }
    }
})
</script>

<style scoped>
em {
    color: #f00;
    font-style: normal;
    font-size: 12px;
    margin-left: 5px;
}

.radio_box {
    display: flex;
    gap: 20px;
}

>>> .el-radio {
    margin: 0 !important;
}

</style>
<style lang="scss">
.changeSignInStatus-dialog {
    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #edf0f2;
        padding-top: 0;
        padding-bottom: 0;
        color: #292e33;
        display: flex;
        align-items: center;

        span {
            font-size: 14px;
        }

        i::before {
            position: relative;
            top: -8px;
        }
    }

    .el-dialog__body {
        padding: 10px 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #edf0f2;
        // height: 600px;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
    }
}
</style>
