<template>
<div>
    <el-dialog
        custom-modal
        width="400px"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        title="修改耗课"
        :close-on-click-modal="false"
        append-to-body>
        <el-form :model="formData" :rules="fromRules" ref="formRef" label-position="top" size="mini">
            <el-form-item required label="课时数" prop="count">
                <el-select style="width:25%;" v-model="formData.consumeClassType" placeholder="请选择">
                    <el-option v-if="data.packageType===1" label="普通课时" value="Ordinary"></el-option>
                    <el-option label="特殊课时" value="Special"></el-option>
                </el-select>
                <el-select style="width:25%;margin:0 20px;" v-model="formData.operation" placeholder="操作">
                    <el-option label="增加" :value="1"></el-option>
                    <el-option label="减少" :value="2"></el-option>
                </el-select>
                <custom-number-input style="width:30%;"
                                     v-model="formData.count"
                                     placeholder="请输入课时">
                </custom-number-input>
                <div style="color:#f00;">注意：年卡只能修改特殊课时</div>
            </el-form-item>
            <el-form-item prop="reason" label="原因">
                <el-input v-model="formData.reason" size="mini" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="发送微信消息通知家长">
                <el-switch v-model="formData.sendNotify" active-color="#29cb97" inactive-color="#cccccc"></el-switch>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="$emit('update:visible',false)">取 消</el-button>
            <el-button type="primary" @click="handleConfirm()" size="small">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import {computed, defineComponent, inject, ref, watch} from 'vue'
import {updateClassConsumerApi} from '../../../api/student/contractApi'
import {cloneObject} from '../../../utils/ObjectUtils'
import CustomNumberInput from "@/components/Form/CustomNumberInput.vue";

export default defineComponent({
    components: {CustomNumberInput},
    props: {
        visible: Boolean
    },
    emits: ['update:visible'],
    setup (props, {emit}) {

        const formRef = ref()

        const fromRules = ref({
            count: [{
                validator (val, rules, callback) {
                    if (!formData.value.consumeClassType) {
                        callback(new Error('请选择课时类型'))
                        return
                    }
                    if (!formData.value.operation) {
                        callback(new Error('请选择操作'))
                        return
                    }
                    if (!formData.value.count) {
                        callback(new Error('数量是必填的'))
                        return
                    }
                    callback()
                }
            }],
            reason: [{required: true, message: '原因是必填的'}]
        })

        const defaultFormData = {
            consumeClassType: undefined,
            operation: undefined,
            count: undefined,
            reason: undefined,
            sendNotify: false
        }

        const formData = ref(cloneObject(defaultFormData))

        const confirmLoading = ref(false)

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate()
                .then(() => updateClassConsumerApi({
                    ...formData.value,
                    count: formData.value.operation === 1 ? formData.value.count : -formData.value.count,
                    contractId: data.value.id
                }))
                .then(() => {
                    emit('update:visible', false)
                    refreshWater()
                })
                .finally(() => {
                    confirmLoading.value = false
                })
        }

        const refreshWater = inject('refreshWater')

        const getData = inject('getData')

        const data = computed(() => getData())

        watch(() => props.visible, newVal => {
            if (!newVal) {
                formData.value = cloneObject(defaultFormData)
                formRef.value.resetFields()
            }
        })

        return {
            formRef,
            fromRules,
            formData,
            data,
            confirmLoading,
            handleConfirm
        }
    }
})
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 !important;
    color: #3b3b3b !important;
}
</style>
