<template>
<el-dialog custom-modal
           title="会员其他信息"
           :visible="visible"
           @update:visible="val=>$emit('update:visible',val)"
           width="30%">
    <div style="padding-bottom: 30px;">
        <article>联系人</article>
        <div class="info">
            <span>{{ data.mainContactName }}（{{
                    $store.getters['common/relationMap'][data.mainContactAffiliation]
                }}）</span>
            <div>
                <img src="../../assets/img/tel.png" alt="">
                <span>（+{{ data.mainContactPhoneNumberArea }}）{{ data.mainContactPhoneNumber }}</span>
            </div>
            <span>微信昵称:<em>{{ data.mainContactWechatName ? data.mainContactWechatName : '无' }}</em></span>
            <img src="../../assets/img/wx.png" alt="">
            <p>（主）</p>
        </div>
        <article>就读年级</article>
        <p class="text">{{ data.grade ? data.grade : '无' }}</p>
        <article>家庭地址</article>
        <p class="text">{{ data.homeAddress ? data.homeAddress : '无' }}</p>
        <article>就读学校</article>
        <p class="text">{{ data.school ? data.school : '无' }}</p>
        <article>所在小区</article>
        <p class="text">{{ data.distinguish ? data.distinguish : '无' }}</p>
    </div>
</el-dialog>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
    props: {
        visible: Boolean,
        data: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    emits: ['update:visible']
})
</script>

<style lang="scss" scoped>
article {
    font-size: 14px;
    color: #292e33;
}

.info {
    display: flex;
    align-items: center;

    span {
        font-size: 14px;
        color: #292e33;
    }

    img {
        width: 14px;
        height: 14px;
    }

    > div {
        display: flex;
        align-items: center;

        img {
            position: relative;
            top: 0;
            right: -3px;
        }
    }

    em {
        font-style: normal;
        margin: 0 20px 0 10px;
        color: #b4bfcc;
    }

    p {
        margin: 0;
        color: #f5222d;
    }
}

article:nth-of-type(n+2) {
    // color: #f00;
    margin-top: 10px;
}

.text {
    font-size: 14px;
    margin: 0;
    color: #292e33;
}
</style>
