import http from '../../utils/httpRequest'

export function getMsgTagListApi (params) {
    return http.get('/admin/motion/tag',{params})
}
export function createMsgTagApi (data,fun) {
    return http[fun]('/admin/motion/tag', data)
}
export function deleteMsgTagApi (id) {
    return http.delete(`/admin/motion/tag/${id}`)
}
export function sendMsgApi (data) {
    return http.post('/admin/motion/push',data)
}

export function sendAfterEvaluateApi (data) {
    return http.post('/admin/balletclass/messageAfterClass',data)
}

export function sendDynamicStateApi (data) {
    return http.post('/admin/motion/push',data)
}
