import {copyPropertyTo} from '../../utils/ObjectUtils';

export default {
    namespaced: true,
    state: {
        name: null,
        code: null,
        userStoreType: null,
        type: null,
    },
    mutations: {
        update(state, campus) {
            if (campus) {
                copyPropertyTo(state, campus);
                localStorage.setItem('store', JSON.stringify(campus));
            }
        },
        clear(state) {
            copyPropertyTo(state, {
                name: undefined,
                code: undefined,
                userStoreType: undefined,
                type: undefined,
            });
            localStorage.removeItem('store');
        }
    },
};
