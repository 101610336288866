<template>
    <div>
        <div class="detail_wrap">
            <div v-if="loading" class="detail_left" v-loading="true"></div>
            <template v-else>
                <div class="detail_left">
                    <template v-if="isTransferClassCount">
                        <div class="contract_info">
                            <span class="title">接收人信息</span>
                        </div>
                        <template v-if="firstWater.listId">
                            <div class="combo" v-if="bindList==='loading'" v-loading="true"></div>
                            <div class="combo" v-else-if="bindList==='error'">
                                加载失败,点击重试
                            </div>
                            <bind-list-student-info :bind-list="bindList"
                                                    v-else-if="typeof bindList==='object'">
                            </bind-list-student-info>
                            <el-button type="text"
                                       class="under-line"
                                       style="margin: 10px 0 0 0;padding: 0;"
                                       @click="handleCancelBindList">
                                取消关联
                            </el-button>
                        </template>
                        <div class="combo flex" v-else>
                            <div class="combo-info-item">
                                <div>姓名</div>
                                <div class="text">{{ firstWater.updateAfter.receiverName }}</div>
                            </div>
                            <div class="combo-info-item">
                                <div>手机号</div>
                                <div class="text">
                                    {{
                                        `(+${firstWater.updateAfter.receiverPhoneNumberArea}) ${firstWater.updateAfter.receiverPhoneNumber}`
                                    }}
                                </div>
                            </div>
                            <div class="combo-info-item" style="justify-content: end;align-items: end;">
                                <el-button type="text"
                                           class="under-line"
                                           @click="openBindListModal"
                                           style="margin: 0;padding: 0;">
                                    关联会员/名单
                                </el-button>
                            </div>
                        </div>
                        <div class="contract_info" style="display: flex;justify-content: space-between">
                            <div>
                                <span class="title">转入课时信息</span>
                                <span class="title" style="color: #f5222d;">(转课时)</span>
                            </div>
                            <el-button type="text" class="under-line" @click="simpleDetailModalVisible=true">
                                原合同信息
                            </el-button>
                        </div>
                        <div class="combo flex">
                            <div class="combo-info-item">
                                <div>转入合同金额</div>
                                <div class="text">{{ firstWater.paymentPrice }}</div>
                            </div>
                            <div class="combo-info-item"></div>
                            <div class="combo-info-item"></div>
                            <div class="combo-info-item" v-if="contract.packageType===1">
                                <div>转入普通课时</div>
                                <div class="text">{{ firstWater.updateAfter.ordinaryClassCount }}</div>
                            </div>
                            <div class="combo-info-item">
                                <div>转入特殊课时</div>
                                <div class="text">{{ firstWater.updateAfter.specialClassCount }}</div>
                            </div>
                            <div class="combo-info-item" v-if="contract.packageType!==1"></div>
                            <div class="combo-info-item"></div>
                            <div class="combo-info-item">
                                <div>转入有效天数</div>
                                <div class="text">{{ firstWater.updateAfter.validityPeriod }}</div>
                            </div>
                            <div class="combo-info-item"></div>
                            <div class="combo-info-item"></div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="left_header">
                            <p v-if="contract.contacts">
                                (+{{ contract.contacts.phoneArea }})
                                {{ contract.contacts.phone }}
                                {{ contract.contacts.name }}</p>
                            <el-button v-if="!isTransfer" type="text" icon="el-icon-download">生成PDF</el-button>
                        </div>
                        <div class="operation">
                            <p :class="{'primary-bg-color__important':contract.paperType===1}">
                                {{ $store.getters["common/performanceTypeMap"][contract.paperType] }}</p>
                            <p v-if="contract.state===6" class="primary-bg-color__important">
                                {{ $store.getters["common/overTypeMap"][contract.overType] }}</p>
                        </div>
                        <div class="source">
                            <span>来源:</span>
                            <p v-if="isTransferContract">合同转中心</p>
                            <p v-else>WALK IN</p>
                        </div>
                        <div style="cursor: pointer;"
                             @click="openStudentModal(student)"
                             class="info"
                             v-for="student in contract.students"
                             :key="student.id">
                            <div>
                                <span>{{ student.name }}</span>
                                <img v-if="student.sex === 2" src="../../assets/img/woman.png" alt="">
                                <img v-else style="background:#4fc3f7" src="../../assets/img/man.png" alt="">
                            </div>
                            <p>{{ getBirthdayDisplay(student.birthdayTime) }}</p>
                            <div class="student-contract-selected"
                                 v-if="Array.isArray(contract.applicableStudentIds)&&contract.applicableStudentIds.indexOf(student.id)!==-1">
                                <i class="el-icon-check"></i>
                            </div>
                        </div>
                    </template>
                    <div class="contract_info" v-if="isTransferContract">
                        <span class="title">合同信息</span>
                        <span class="title" style="color: #f5222d;">([转中心] - 可转为普通合同)</span>
                    </div>
                    <div class="contract_info" v-else-if="!isTransferClassCount">
                        <div>
                            <span class="title">合同信息</span>
                            <p class="recordBtn" @click="consumeRecordBoolean = true">消耗记录</p>
                        </div>
                        <div>
                            <span>合同编号:</span>
                            <p>{{ contract.paperNo }}</p>
                            <em @click="changeContractNumBoolean = true">[修改合同编号]</em>
                        </div>
                        <div class="contain" v-if="contract.packageType===1">
                            <div class="box">
                                <span>总普通课时</span>
                                <p>
                                        <span style="color: inherit;font-size: inherit;">
                                            {{ contract.ordinaryClassCount + contract.giveOrdinaryClassCount }}
                                        </span>
                                    <span>（赠送{{ contract.giveOrdinaryClassCount }}）</span>
                                </p>
                            </div>
                            <div class="box">
                                <span>总特殊课时</span>
                                <p>{{ contract.specialClassCount }}</p>
                            </div>
                            <div class="box" v-if="contract.state===1">
                                <span>本次实收金额(元)</span>
                                <p class="primary-text-color__important">{{
                                        currency(contract.amountReceivedThis, {symbol: '', separator: ','}).format()
                                    }}</p>
                            </div>
                            <div class="box">
                                <span>已收金额(元)</span>
                                <p class="primary-text-color__important">
                                    {{ currency(contract.amountReceived, {symbol: '', separator: ','}).format() }}</p>
                            </div>
                        </div>
                        <div class="contain" v-else-if="contract.packageType===2">
                            <div class="box">
                                <span>每周上课课时数</span>
                                <p>{{ contract.weekOrdinaryClassCount }}</p>
                            </div>
                            <div class="box">
                                <span>周末上课次数</span>
                                <p>{{ contract.weekendOrdinaryClassCount }}</p>
                            </div>
                            <div class="box">
                                <span>总特殊课时</span>
                                <p>{{ contract.specialClassCount }}</p>
                            </div>
                            <div class="box" v-if="contract.state===1">
                                <span>本次实收金额(元)</span>
                                <p class="primary-text-color__important">{{
                                        currency(contract.amountReceivedThis, {symbol: '', separator: ','}).format()
                                    }}</p>
                            </div>
                            <div class="box">
                                <span>已收金额(元)</span>
                                <p class="primary-text-color__important">
                                    {{ currency(contract.amountReceived, {symbol: '', separator: ','}).format() }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="combo" v-if="!isTransfer">
                        <div class="combo_header">
                            <p>套餐</p>
                            <div>
                                <p>{{ contract.paperName }}</p>
                                <span @click="contractDetailBoolean = true">[详情]</span>
                            </div>
                        </div>
                        <div class="combo_content">
                            <div>
                                <div>
                                    <p>套餐类型</p>
                                </div>
                                <span>
                                    {{
                                        contract.packageType === 1 ? '次卡' : (contract.packageType === 2 ? '年卡' : '')
                                    }}
                                </span>
                            </div>
                            <div>
                                <div>
                                    <p>合同总价(元)</p>
                                </div>
                                <span>{{ contract.amountReceivable }}</span>
                            </div>
                            <div>
                                <div>
                                    <p>会员优惠(%)</p>
                                </div>
                                <span>
                                    {{
                                        (() => {
                                            const result = currency(1)
                                                .subtract(currency(contract.shouldMoney).divide(contract.amountReceivable))
                                                .multiply(100);
                                            if (result.value <= 0) {
                                                return '-';
                                            }
                                            return result.format({symbol: '', precision: 2}) + '%';
                                        })()
                                    }}
                                </span>
                            </div>
                            <div>
                                <div>
                                    <p>应收总金额(元)</p>
                                </div>
                                <span style="font-weight:600;background-color: #292e3324">{{
                                        currency(contract.shouldMoney, {
                                            symbol: '',
                                            separator: ','
                                        }).format()
                                    }}</span>
                            </div>
                            <div>
                                <div>
                                    <p>签约日期</p>
                                    <img @click="changeDataBoolean=true" style="cursor:pointer;"
                                         src="../../assets/img/edit.png" alt="">
                                </div>
                                <span>{{ contract.contractDate.split(' ')[0] }}</span>
                            </div>
                            <div>
                                <div>
                                    <p>合同状态</p>
                                </div>
                                <span v-if="contract.state === 6 || contract.state === 7"
                                      class="fail-text-color__important">{{
                                        $store.getters['common/contractStateMap'][contract.state]
                                    }}</span>
                                <span v-else class="primary-text-color__important">{{
                                        $store.getters['common/contractStateMap'][contract.state]
                                    }}</span>
                            </div>
                            <template>
                                <div>
                                    <div>
                                        <p>合同启用日期</p>
                                    </div>
                                    <span v-if="contract.activationTime">
                                        {{ contract.startTime ? contract.startTime.split(' ')[0] : '-' }}
                                    </span>
                                    <span v-else class="fail-text-color__important">未激活</span>
                                </div>
                                <div>
                                    <div>
                                        <p>终止日期</p>
                                    </div>
                                    <span v-if="contract.activationTime">{{
                                            contract.endTime ? contract.endTime.split(' ')[0] : '-'
                                        }}</span>
                                    <span v-else class="fail-text-color__important">未激活</span>
                                </div>
                            </template>
                            <div>
                                <div>
                                    <p>合同有效期</p>
                                    <el-tooltip class="item" effect="dark" content="1月 = 30天" placement="top">
                                        <img style="cursor:pointer;" src="../../assets/img/wenhao2.png" alt="">
                                    </el-tooltip>
                                </div>
                                <span>
                                        {{
                                        `${contract.leaveDate}天（${parseInt(contract.leaveDate / 12)}月${contract.leaveDate % 30}天）`
                                    }}
                                    </span>
                            </div>
                            <div>
                                <div>
                                    <p>合同剩余有效期</p>
                                    <el-tooltip class="item" effect="dark" content="1月 = 30天" placement="top">
                                        <img style="cursor:pointer;" src="../../assets/img/wenhao2.png" alt="">
                                    </el-tooltip>
                                </div>
                                <span>
                            {{ getRemainingValidityPeriod(contract) }}
                        </span>
                            </div>
                        </div>
                    </div>
                    <div class="combo" v-if="!isTransfer">
                        <div class="combo_header" v-if="contract.packageType===2">
                            <p>年卡流逝金额</p>
                            <div>
                                <p>
                                    {{
                                        currency(contract.naturalPassageAmount, {
                                            separator: ',',
                                            symbol: ''
                                        }).format()
                                    }}
                                </p>
                            </div>
                        </div>
                        <div class="combo_content">
                            <div>
                                <div>
                                    <p>导入耗课金额</p>
                                </div>
                                <span>-</span>
                            </div>
                            <div>
                                <div>
                                    <p>耗课金额(元)</p>
                                </div>
                                <span>
                                    {{
                                        currency(contract.usedOrdinaryClassAmount, {
                                            separator: ',',
                                            symbol: ''
                                        }).format()
                                    }}
                                </span>
                            </div>
                            <div>
                                <div>
                                    <p>手动耗课金额(元)</p>
                                </div>
                                <span>
                                    {{
                                        currency(contract.manualConsumeOrdinaryClassAmount, {
                                            separator: ',',
                                            symbol: ''
                                        }).format()
                                    }}
                                </span>
                            </div>
                            <div>
                                <div>
                                    <p>剩余金额(元)</p>
                                </div>
                                <span class="primary-text-color">
                                    {{
                                        contract.state === 6 ? '0.00' : currency(contract.remainingAmount, {
                                            separator: ',',
                                            symbol: ''
                                        }).format()
                                    }}
                                </span>
                            </div>
                            <div>
                                <div>
                                    <p>导入已耗普通课时</p>
                                </div>
                                <span>-</span>
                            </div>
                            <div>
                                <div>
                                    <p>已上普通课时</p>
                                </div>
                                <span>{{ contract.studiedOrdinaryClassConsumeCount }}</span>
                            </div>
                            <div>
                                <div>
                                    <p>普通课时手动耗课</p>
                                    <el-tooltip class="item" effect="dark"
                                                content="包含修改耗课及确认收入造成的已耗课变动"
                                                placement="top">
                                        <img style="cursor:pointer;" src="../../assets/img/wenhao2.png" alt="">
                                    </el-tooltip>
                                </div>
                                <span>{{ contract.manualConsumeOrdinaryClassCount }}</span>
                            </div>
                            <div v-if="contract.packageType === 1">
                                <div>
                                    <p>剩余普通课时</p>
                                </div>
                                <span>
                                    {{
                                        contract.state === 6 ? 0 :
                                            contract.remainingOrdinaryClassCount + contract.planedOrdinaryClassConsumeCount - contract.studiedOrdinaryClassConsumeCount
                                    }}
                                    <em>
                                        (已排:
                                        {{
                                            contract.planedNoStudyOrdinaryClassConsumeCount
                                        }}
                                        |可用:{{ contract.state === 6 ? 0 : contract.remainingOrdinaryClassCount }})
                                    </em>
                                    </span>
                            </div>
                            <div v-else>
                                <div>
                                    <p>已排普通课时</p>
                                </div>
                                <span>{{ contract.planedNoStudyOrdinaryClassConsumeCount }}</span>
                            </div>
                            <div>
                                <div>
                                    <p>导入已耗特殊课时</p>
                                </div>
                                <span>-</span>
                            </div>
                            <div>
                                <div>
                                    <p>已上特殊课时</p>
                                </div>
                                <span>{{ contract.studiedSpecialClassConsumeCount }}</span>
                            </div>
                            <div>
                                <div>
                                    <p>特殊课时手动耗课</p>
                                    <el-tooltip class="item" effect="dark"
                                                content="包含修改耗课及确认收入造成的已耗课变动"
                                                placement="top">
                                        <img style="cursor:pointer;" src="../../assets/img/wenhao2.png" alt="">
                                    </el-tooltip>
                                </div>
                                <span>{{ contract.manualConsumeSpecialClassCount }}</span>
                            </div>
                            <div>
                                <div>
                                    <p>剩余特殊课时</p>
                                </div>
                                <span>
                                    {{
                                        contract.state === 6 ? 0 :
                                            contract.remainingSpecialClassCount + contract.planedSpecialClassConsumeCount - contract.studiedSpecialClassConsumeCount
                                    }}
                                    <em>
                                        (已排:{{
                                            contract.planedNoStudySpecialClassConsumeCount
                                        }}
                                        |可用:{{ contract.state === 6 ? 0 : contract.specialClassCount }})
                                    </em>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="combo" v-if="isTransferContract">
                        <div class="combo_header">
                            <p>已转入金额</p>
                            <div>
                                {{ firstWater.paymentPrice }}
                            </div>
                        </div>
                    </div>
                    <div class="combo" v-if="!isTransferClassCount">
                        <div class="combo_header">
                            <p>适用学员</p>
                            <div>
                                <p v-if="contract.suitStudent === 1">全部学员</p>
                                <p v-else>
                                    {{ contract.students.map(stu => stu.name).join(',') }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="combo" v-if="detailBoolean">
                        <div class="combo_content2">
                            <div>
                                <div>
                                    <p>普通课时适用课类</p>
                                </div>
                                <span v-if="contract.ordinaryClassName">{{ contract.ordinaryClassName }}</span>
                                <span v-else>全部课类</span>
                            </div>
                            <div>
                                <div>
                                    <p>普通课时适用周期</p>
                                </div>
                                <span v-if="contract.contractPackage.specialTime">{{
                                        contract.contractPackage.ordinaryTime
                                    }}</span>
                                <span v-else>全部周期</span>
                            </div>
                            <div>
                                <div>
                                    <p>特殊课时适用课类</p>
                                </div>
                                <span v-if="contract.specialClassName">{{ contract.specialClassName }}</span>
                                <span v-else>全部课类</span>
                            </div>
                            <div>
                                <div>
                                    <p>特殊课时适用周期</p>
                                </div>
                                <span v-if="contract.contractPackage.specialTime">{{
                                        contract.contractPackage.specialTime
                                    }}</span>
                                <span v-else>全部周期</span>
                            </div>
                        </div>
                    </div>
                    <div class="combo" v-if="detailBoolean">
                        <div class="combo_content">
                            <div>
                                <div>
                                    <p>合同冻结日期</p>
                                </div>
                                <span v-if="contract.state === 8">
                                    {{ contract.freezeTime ? contract.freezeTime.split(' ')[0] : '-' }}
                                </span>
                                <span v-else>-</span>
                            </div>
                            <div>
                                <div>
                                    <p>合同解冻日期</p>
                                </div>
                                <span v-if="contract.state === 8">
                                    {{ contract.unfreezeTime ? contract.unfreezeTime.split(' ')[0] : '-' }}
                                </span>
                                <span v-else>-</span>
                            </div>
                            <div>
                                <div>
                                    <p>累计冻结次数</p>
                                </div>
                                <span>{{ contract.freezeNum }}</span>
                            </div>
                            <div>
                                <div>
                                    <p>累计冻结天数</p>
                                </div>
                                <span>{{ contract.freezeDate }}</span>
                            </div>
                            <div>
                                <div>
                                    <p>归属顾问</p>
                                </div>
                                <span>{{ contract.consultant.staffName }}</span>
                            </div>
                            <div>
                                <div>
                                    <p>转介绍</p>
                                </div>
                                <span v-if="contract.listTable.introducerPhone">
                                    {{ contract.listTable.introducerPhone }}
                                </span>
                                <span v-else-if="contract.listTable.introducerEmail">
                                    {{ contract.listTable.introducerEmail }}
                                </span>
                                <span v-else-if="contract.listTable.introducerOther">
                                    {{ contract.listTable.introducerOther }}
                                </span>
                            </div>
                        </div>
                        <div class="footer">
                            <p>赠送礼物</p>
                            <span></span>
                        </div>
                    </div>
                    <div class="detail" v-if="!isTransfer" @click="changeDetailBoolean">
                        <p>查看明细</p>
                        <img
                            :src="detailBoolean?require('/src/assets/img/up.png'):require('/src/assets/img/down.png')"
                            alt="">
                    </div>
                    <div class="accessory" v-if="!isTransfer">
                        <div class="h4">
                            <p>附件</p>
                            <el-tooltip class="item" effect="dark" content="增加" placement="top">
                                <img @click="addAdditionBoolean = true" src="../../assets/img/jiahao.png" alt="">
                            </el-tooltip>
                        </div>
                        <div class="accessory_content">
                            <span>无</span>
                        </div>
                    </div>
                    <div class="accessory">
                        <div class="h4">
                            <p>备注</p>
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <img @click="updateRemarkModalVisible=true" src="../../assets/img/edit.png" alt="">
                            </el-tooltip>
                        </div>
                        <div class="accessory_content">
                            <span>{{ contract.remark ? contract.remark : '无' }}</span>
                        </div>
                    </div>
                    <div class="accessory">
                        <div class="h4">
                            <p>合同操作记录</p>
                        </div>
                        <div class="accessory_table">
                            <el-table
                                :data="operationLogRecords"
                                style="width: 100%"
                                :header-cell-style="{background:'#f5f7fa',color:'#292e33'}"
                                :row-class-name="onTableRowClassName">
                                <el-table-column
                                    prop="type"
                                    label="类型"
                                    width="180">
                                </el-table-column>
                                <el-table-column
                                    prop="reason"
                                    label="原因"
                                    width="180">
                                </el-table-column>
                                <el-table-column
                                    prop="operatorName"
                                    label="操作">
                                    <template v-slot="scope">
                                        <div>
                                            <span>{{ scope.row.operatorName }}</span><br
                                            v-if="scope.row.operatorName"/>
                                            <span>{{ scope.row.createTime }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="performance"
                         v-if="!isTransfer&&(contract.state === 3 || contract.state === 6 || contract.state === 7)">
                        <div class="performance_header">
                            <p>分单业绩信息</p>
                            <em @click="allocationBoolean=true">[变更]</em>
                        </div>
                        <div class="performance_table">
                            <el-table
                                :data="performanceSpiltRecords"
                                style="width: 100%"
                                :header-cell-style="{'background':'#f5f7fa','color':'#292e33','text-align': 'center'}"
                                :row-class-name="onTableRowClassName2">
                                <el-table-column
                                    prop="tenantUserName"
                                    label="对象">
                                </el-table-column>
                                <el-table-column
                                    prop="amount"
                                    label="金额（元）">
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <template v-if="isTransfer">
                        <div class="operation_box">
                            <el-tooltip placement="top"
                                        :disabled="firstWater.checkResult===5&&!(!firstWater.listId&&isTransferClassCount)">
                                <template v-slot:content>
                                    <div v-if="firstWater.checkResult!==5">请先接收再创建合同</div>
                                    <div v-if="!firstWater.listId&&isTransferClassCount">
                                        请先绑定会员/名单再创建合同
                                    </div>
                                </template>
                                <el-button type="primary"
                                           style="width:100px;height:33px;"
                                           :disabled="firstWater.checkResult!==5||(!firstWater.listId&&isTransferClassCount)"
                                           @click="toCreatePage">
                                    生成合同
                                </el-button>
                            </el-tooltip>
                        </div>
                    </template>
                    <template v-else>
                        <!--待审核-->
                        <div class="operation_box" v-if="contract.state === 1">
                            <el-button type="primary"
                                       class="primary-bg-color__important"
                                       style="width:100px;height:33px;border:none;"
                                       @click="check(true)">
                                同意
                            </el-button>
                            <el-button type="primary"
                                       style="width:100px;height:33px;background:#faad14;border:none;"
                                       @click="check(false)">
                                驳回
                            </el-button>
                            <el-button type="primary"
                                       style="width:100px;height:33px;background:#f5222d;border:none;"
                                       @click="del">
                                删除
                            </el-button>
                        </div>
                        <!--已审核-->
                        <div class="operation_box" v-else-if="contract.state === 2">
                            <el-button type="primary"
                                       style="width:100px;height:33px;background:#f5222d;border:none;"
                                       @click="del">
                                删除
                            </el-button>
                        </div>
                        <!--已驳回-->
                        <div class="operation_box" v-else-if="contract.state === 5">
                            <el-button type="primary"
                                       style="width:100px;height:33px;background:#29cb97;border:none;">
                                重新编辑
                            </el-button>
                            <el-button type="primary"
                                       style="width:100px;height:33px;background:#f5222d;border:none;"
                                       @click="del">
                                删除
                            </el-button>
                        </div>
                        <!--已完结-->
                        <div class="operation_box" v-else-if="contract.state === 6||contract.state === 9">
                            <el-button type="primary" style="width:100px;height:33px;"
                                       @click="rescheduleModalVisible=true">
                                改期
                            </el-button>
                        </div>
                        <div class="operation_box" v-else>
                            <el-button type="primary" v-if="contract.state === 3&&!contract.freezeTime"
                                       style="width:100px;height:33px;"
                                       @click="freezeContractModalBoolean=true">
                                冻结
                            </el-button>
                            <el-button type="primary"
                                       v-if="contract.state === 3&&contract.freezeTime"
                                       class="freeze"
                                       style="width:100px;height:33px;"
                                       @click="updateContractFreezeDateModalVisible=true">
                                修改冻结
                            </el-button>
                            <template v-if="contract.state === 8">
                                <el-button type="primary"
                                           v-if="dayjs(contract.freezeTime).isBefore(dayjs())"
                                           class="freeze"
                                           style="width:100px;height:33px;"
                                           @click="thawContractBoolean = true">
                                    解冻
                                </el-button>
                            </template>
                            <el-button type="primary" v-if="contract.state === 7"
                                       style="width:100px;height:33px;">
                                确认收入
                            </el-button>
                            <el-button type="primary" style="width:100px;height:33px;"
                                       @click="rollUpContractModalBoolean=true">
                                转出
                            </el-button>
                            <el-button type="primary" style="width:100px;height:33px;"
                                       @click="rescheduleModalVisible=true">
                                改期
                            </el-button>
                        </div>
                    </template>
                    <div v-if="contract.state!==1&&!isTransfer" class="del" @click="del">
                        删除此合同
                    </div>
                </div>
            </template>
            <contract-water ref="contractWaterRef"
                            :data="contract"
                            :contract-loaded="contractLoaded">
            </contract-water>
        </div>
        <template v-if="!loading">
            <student-detail-modal :visible.sync="studentDetailModalVisible"
                                  :student-id="studentId"
                                  :default-contract-id="defaultContractId"
                                  @close="$emit('close')">
            </student-detail-modal>
            <update-code-modal :visible.sync="changeContractNumBoolean"></update-code-modal>
            <consume-record :visible.sync="consumeRecordBoolean" :contract-id="contract.id"></consume-record>
            <contract-detail-modal :title="'深业上城/东海/壹方-年卡（多校）[年卡]'"
                                   :type="'1'"
                                   :visible="contractDetailBoolean"
                                   :closeContractDetailModal="closeContractDetailModal">
            </contract-detail-modal>
            <reschedule-modal :visible.sync="rescheduleModalVisible"></reschedule-modal>
            <update-remark-modal :visible.sync="updateRemarkModalVisible"></update-remark-modal>
            <addition-vue :visible="addAdditionBoolean"
                          :closeAdditionModal="closeAdditionModal">
            </addition-vue>
            <allocation-vue :visible.sync="allocationBoolean" :records="performanceSpiltRecords">
            </allocation-vue>
            <freeze-contract-vue :visible.sync="freezeContractModalBoolean"></freeze-contract-vue>
            <thaw-contract-vue :visible.sync="thawContractBoolean"></thaw-contract-vue>
            <!-- 删除合同弹窗 -->
            <del-contract :visible="delContractModalBoolean"
                          :closeDelContractModal="closeDelContractModal">
            </del-contract>
            <transfer-contract :visible.sync="rollUpContractModalBoolean">
            </transfer-contract>
            <update-contract-date-modal :visible.sync="changeDataBoolean"></update-contract-date-modal>
            <update-contract-freeze-date
                :visible.sync="updateContractFreezeDateModalVisible"></update-contract-freeze-date>
            <bind-list-modal
                :visible.sync="bindListModalVisible"
                :data="bindListModalData"
                @ok="$refs.contractWaterRef.refreshWaterPage">
            </bind-list-modal>
            <simple-contract-detail-modal :contract-id="data?data.id:undefined"
                                          :visible.sync="simpleDetailModalVisible">
            </simple-contract-detail-modal>
        </template>
    </div>
</template>
<script>
import ConsumeRecord from '../../components/Contract/detail/ConsumeRecord.vue';
import ContractDetailModal from '../../components/Contract/detail/ContractDetailModal.vue';
import ChangeDataVue from '../../components/Contract/detail/UpdateContractDateModal.vue';
import AdditionVue from '../../components/Contract/detail/Addition.vue';
import AllocationVue from '../../components/Contract/detail/Allocation.vue';
import FreezeContractVue from '../../components/Contract/detail/FreezeContract.vue';
import DelContract from '../../components/Contract/detail/DelContract.vue';
import TransferContract from '../../components/Contract/detail/TransferContract.vue';
import RescheduleModal from '../../components/Contract/detail/RescheduleModal.vue';
import ThawContractVue from '../../components/Contract/detail/ThawContract.vue';
import ContractWater from '../../components/Contract/detail/ContractWater.vue';
import UpdateCodeModal from '../../components/Contract/detail/UpdateCodeModal.vue';
import UpdateRemarkModal from '../../components/Contract/detail/UpdateRemarkModal.vue';
import {getBirthdayDisplay} from '../../utils/studentUtils';
import {
    cancelBindListForReceiveApi,
    checkWaterApi,
    deleteContractApi,
    getContractDetailApi,
    getContractOperationPagePageApi,
    getContractPerformanceSpiltPageApi,
} from '../../api/student/contractApi';
import {getDefaultPage} from '../../utils';
import UpdateContractDateModal from '../../components/Contract/detail/UpdateContractDateModal.vue';
import dayjs from 'dayjs';
import UpdateContractFreezeDate from '../../components/Contract/detail/UpdateContractFreezeDate.vue';
import {h} from 'vue';
import BindListModal from '@/components/Contract/detail/BindListModal.vue';
import {getDetailApi} from '@/api/student/listApi';
import GenderView from '@/components/Common/GenderView.vue';
import BindListStudentInfo from '@/components/Contract/detail/BindListStudentInfo.vue';
import currency from 'currency.js';
import transferContract from '@/components/Contract/detail/TransferContract.vue';
import SimpleContractDetailModal from '@/components/Contract/detail/SimpleContractDetailModal.vue';
import {openConfirmModal} from "@/utils/messageUtils";

export default {
    components: {
        SimpleContractDetailModal,
        BindListStudentInfo,
        GenderView,
        BindListModal,
        UpdateContractFreezeDate,
        UpdateContractDateModal,
        UpdateRemarkModal,
        UpdateCodeModal,
        ContractWater,
        ConsumeRecord,
        ContractDetailModal,
        ChangeDataVue,
        AdditionVue,
        AllocationVue,
        FreezeContractVue,
        ThawContractVue,
        DelContract,
        TransferContract,
        RescheduleModal
    },
    emits: ['updated', 'delete', 'close'],
    props: {
        data: Object,
    },
    provide() {
        return {
            getData: () => this.contract,
            refreshContract: () => {
                this.getDetail();
            },
            refreshContractLog: () => {
                this.refreshOperationLogPage();
            },
            refreshPerformanceSpilt: () => {
                this.refreshPerformanceSpiltPage();
            },
            refreshWater: () => this.$refs.contractWaterRef.refreshWaterPage()
        };
    },
    data() {
        return {
            loading: true,
            detailBoolean: false,
            consumeRecordBoolean: false,
            changeContractNumBoolean: false,
            contractDetailBoolean: false,
            changeDataBoolean: false,
            addAdditionBoolean: false,
            allocationBoolean: false,
            delContractModalBoolean: false,
            freezeContractModalBoolean: false,
            rollUpContractModalBoolean: false,
            changeDateBoolean: false,
            thawContractBoolean: false,
            studentDetailModalVisible: false,
            updateRemarkModalVisible: false,
            rescheduleModalVisible: false,
            updateContractFreezeDateModalVisible: false,
            bindListModalVisible: false,
            simpleDetailModalVisible: false,
            bindListModalData: {},
            studentId: undefined,
            defaultContractId: undefined,
            contract: {},
            performanceSpiltLoading: false,
            operationLogLoading: false,
            performanceSpiltPage: getDefaultPage(),
            operationLogPage: getDefaultPage(),
            performanceSpiltRecords: [],
            operationLogRecords: [],
            contractLoaded: false,
            messageVNode: undefined,
            bindList: undefined,
            updated: 0,
        };
    },
    methods: {
        currency,
        dayjs,
        getBirthdayDisplay,
        getDetail() {
            this.contract.id = this.data.id;
            this.loading = true;
            getContractDetailApi(this.data.id)
                .then(res => {
                    this.contract = res.data.data;
                    this.contractLoaded = true;
                })
                .finally(() => this.loading = false);
        },
        refreshPerformanceSpiltPage() {
            this.performanceSpiltPage = getDefaultPage();
            this.getPerformanceSpiltPage();
        },
        getPerformanceSpiltPage() {
            this.performanceSpiltLoading = true;
            getContractPerformanceSpiltPageApi({
                currentPage: this.performanceSpiltPage.currentPage,
                pageSize: this.performanceSpiltPage.pageSize,
                timestamp: this.performanceSpiltPage.timestamp,
                contractId: this.data.id
            }).then(res => {
                this.performanceSpiltRecords = res.data.data.records;
                this.performanceSpiltPage.total = res.data.data.total;
            }).finally(() => this.performanceSpiltLoading = false);
        },
        refreshOperationLogPage() {
            this.operationLogPage = getDefaultPage();
            this.getOperationLogPage();
        },
        getOperationLogPage() {
            this.operationLogLoading = true;
            getContractOperationPagePageApi({
                currentPage: this.operationLogPage.currentPage,
                pageSize: this.operationLogPage.pageSize,
                timestamp: this.operationLogPage.timestamp,
                contractId: this.data.id
            }).then(res => {
                this.operationLogRecords = res.data.data.records;
                this.operationLogPage.total = res.data.data.total;
            }).finally(() => this.operationLogLoading = false);
        },
        //是否查看详情
        changeDetailBoolean() {
            this.detailBoolean = !this.detailBoolean;
        },
        //设置左侧表格斑马纹颜色
        onTableRowClassName({row, rowIndex}) {
            if (rowIndex % 2 !== 0) {
                return 'statistics-warning-row';
            } else {
                return '';
            }
        },
        onTableRowClassName2({row, rowIndex}) {
            if (rowIndex % 2 !== 0) {
                return 'statistics-warning-row split';
            } else {
                return 'split';
            }
        },
        //关闭消耗记录弹窗函数
        closeConsumeRecord() {
            this.consumeRecordBoolean = false;
        },
        //关闭修改合同编号弹窗函数
        closeChangeContractNumModal() {
            this.changeContractNumBoolean = false;
        },
        //关闭合同详情弹窗函数
        closeContractDetailModal() {
            this.contractDetailBoolean = false;
        },
        //关闭添加附件弹窗函数
        closeAdditionModal() {
            this.addAdditionBoolean = false;
        },
        //关闭分单业绩更改弹窗
        closeAllocationModal() {
            this.allocationBoolean = false;
        },
        //关闭改套餐弹窗函数
        closeChangeComboModal() {
            this.changeComboBoolean = false;
        },
        getRemainingValidityPeriod(contract) {
            if (contract.state === 6 || contract.state === 7) {
                return '0天';
            }
            if (contract.activationTime) {
                const validityPeriod = dayjs(contract.endTime).diff(dayjs(), 'day');
                return `${validityPeriod}天（${parseInt(validityPeriod / 12)}月${validityPeriod % 12}天）`;
            } else {
                return `${contract.leaveDate}天（${parseInt(contract.leaveDate / 12)}月${contract.leaveDate % 12}天）`;
            }
        },
        openStudentModal(student) {
            this.studentDetailModalVisible = true;
            this.studentId = student.id;
            this.defaultContractId = student.defaultContractId;
        },
        check(status) {
            if (status) {
                openConfirmModal('是否同意此合同?', () => {
                    this.httpCheck(true);
                });
            } else {
                this.$prompt('原因', '驳回原因', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType: 'textarea',
                    inputRows: 5
                }).then(({value}) => {
                    this.httpCheck(false, value);
                });
            }
        },
        httpCheck(status, value) {
            checkWaterApi(this.data.id, {
                status: status,
                message: value
            }).then(() => {
                this.getDetail();
                if (status) {
                    this.$refs.contractWaterRef.refreshWaterPage();
                    this.$notify({
                        type: 'success',
                        message: '审核成功!'
                    });
                } else {
                    this.$notify({
                        type: 'success',
                        message: '驳回成功!'
                    });
                }
            });
        },
        //关闭删除合同弹窗
        closeDelContractModal() {
            this.delContractModalBoolean = false;
        },
        openBindListModal() {
            this.bindListModalVisible = true;
            this.bindListModalData = this.firstWater.updateAfter;
        },
        handleCancelBindList() {
            this.$confirm('确认要取消关联吗？', {
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        cancelBindListForReceiveApi({contractId: this.contract.id}).then(() => {
                            done();
                            this.$refs.contractWaterRef.refreshWaterPage();
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                    }
                }
            });
        },
        del() {
            this.$confirm(this.messageVNode, {
                title: `合同编号【${this.contract.paperNo}】`,
                type: 'warning',
                showClose: false,
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        deleteContractApi(this.contract.id).then(() => {
                            this.$notify({type: 'success', title: '提示', message: '删除合同成功！'});
                            this.$emit('delete');
                            this.$emit('updated');
                            done();
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                    }
                }
            });
        },
        toCreatePage() {
            this.$router.push({
                path: '/createContract',
                query: {
                    listId: this.contract.tableId,
                    paperType: 1,
                    transferType: this.contract.transferType,
                    remark: this.operationLogRecords[0].reason,
                    transferData: JSON.stringify(this.firstWater),
                    timestamp: new Date().getTime()
                }
            });
            this.$emit('update:visible', false);
        }
    },
    computed: {
        transferContract() {
            return transferContract;
        },
        isTransfer() {
            return parseInt(this.$store.state.campus.code) !== this.contract.storeId && this.contract.transferTo < 0;
        },
        isTransferContract() {
            return this.isTransfer ? this.contract.transferType === 'Contract' : false;
        },
        isTransferClassCount() {
            return this.isTransfer ? this.contract.transferType === 'ClassCount' : false;
        },
        firstWater() {
            const newVal = this.$refs.contractWaterRef && this.$refs.contractWaterRef.waterList[0] ? this.$refs.contractWaterRef.waterList[0] : {updateAfter: {}};
            if (newVal.listId) {
                this.bindList = 'loading';
                getDetailApi(newVal.listId).then(res => {
                    this.bindList = res.data.data;
                }).catch(() => this.bindList = 'error');
            }
            return newVal;
        }
    },
    created() {
        this.messageVNode = h('div', {
            style: {
                color: '#f5222d',
                fontWeight: 700
            }
        }, '删除数据无法恢复，且相关数据无法查询。请确认是否删除?');

        if (this.data && this.data.id) {
            this.getDetail();
            this.refreshPerformanceSpiltPage();
            this.refreshOperationLogPage();
        } else {
            alert('未传递合同id！');
        }
    },
};
</script>
<style lang="scss" src="../../assets/scss/modules/contract/contractDetail.scss" scoped></style>
<style lang="scss">
.statistics-warning-row {
    background: #f5f7fa !important;
}

.split {
    td {
        text-align: center;
    }
}
</style>
