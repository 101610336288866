<template>
    <div class="yueke_stu" v-if="visible">
        <div class="filter">
            <div>
                <search-input @search="handleSearch"
                              :disabled="loading"
                              placeholder="请输入内容"
                              size="mini"
                              v-model="keyword">
                    <template v-slot:append>
                        <div style="width: 100%;height: 100%;cursor: pointer;padding: 0 20px" @click="handleSearch">
                            搜索
                        </div>
                    </template>
                </search-input>
            </div>
        </div>
        <el-table :data="tableData" style="width: 100%;" max-height="500px" v-loading="loading">
            <el-table-column prop="studentInfo" label="学员信息" width="180">
                <template v-slot="scope">
                    <div class="text">
                        <div style="display: flex;align-items: center;gap: 8px;">
                            <span>{{ scope.row.students[0].name }}</span>
                            <gender-view :gender="scope.row.students[0].sex"></gender-view>
                        </div>
                        <p>{{ getBirthdayDisplay(scope.row.students[0].birthdayTime, false) }}</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="contactsList" label="联系人" width="180">
                <template v-slot="scope">
                    <div class="linkMan">
                        <p>
                            <span> {{ scope.row.contactsList[0].name }}</span>
                            <span :style="'color:black;'">
                            （{{
                                    $store.getters['common/relationMap'][scope.row.contactsList[0].affiliation]
                                }}）
                        </span>
                        </p>
                        <span>{{ scope.row.contactsList[0].phone }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="construct"
                label="顾问&合同">
                <template v-slot="scope">
                    <div class="counselor">
                        <p v-if="scope.row.consultant">
                            {{ '顾问：' + scope.row.consultant.staffName }}
                        </p>
                        <span v-if="scope.row.contract">
                        {{ scope.row.contract.paperName }}
                    </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="operation"
                header-align="center"
                align="center"
                label="操作">
                <template v-slot="scope">
                    <el-tooltip :disabled="!studentIds.has(scope.row.id)"
                                placement="top"
                                content="名单已经预约该课程,无法重复预约！">
                        <el-button type="primary"
                                   :disabled="studentIds.has(scope.row.id)"
                                   plain
                                   @click="handleConfirm(scope.row)">
                            约课
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: right;margin-top:20px;"
                       background
                       layout="total,prev, pager, next, sizes ,jumper"
                       v-bind="page"
                       :page-sizes="[10,20,50,100]"
                       @size-change="sizeChangeHandle"
                       @current-change="currentChangeHandle"
                       v-if="page.pages>1">
        </el-pagination>
    </div>
</template>
<script>
import {getBirthdayDisplay} from '../../../utils/studentUtils';
import {computed, defineComponent, getCurrentInstance, inject, onUnmounted, ref, toRefs} from 'vue';
import {getListPageApi, reserveClassApi} from '../../../api/student/listApi';
import GenderView from '../../Common/GenderView.vue';
import {getDefaultPage} from '../../../utils';
import SearchInput from "@/components/Form/SearchInput.vue";

export default defineComponent({
    components: {SearchInput, GenderView},
    props: {
        visible: Boolean,
        classId: Number,
        classNumberNo: String,
        consumeCount: Number,
        students: Array,
    },
    emits: ['update:visible'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const keyword = ref();

        const page = ref(getDefaultPage());

        const tableData = ref([]);

        const loading = ref(false);

        const handleConfirm = (row, force) => {
            currentInstance.proxy.$confirm('确认要预约此课吗？', {
                type: 'warning',
                beforeClose(action, instance, done) {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        reserveClassApi({
                            listId: row.id,
                            classId: props.classId,
                            force
                        }).then(res => {
                            done();
                            if (res.data.code === 40110 || res.data.code === 40111) {
                                setTimeout(() => {
                                    currentInstance.proxy.$confirm(res.data.code === 40110 ? '当前课程已满人,是否强制约课?' : '当前课程试听名额已满,是否强制约课', {
                                        type: 'warning',
                                        beforeClose: (action1, instance1, done1) => {
                                            if (action1 === 'confirm') {
                                                setTimeout(() => {
                                                    handleConfirm(row, true);
                                                });
                                                done1();
                                            } else {
                                                done1();
                                            }
                                        }
                                    });
                                });
                            } else {
                                refreshClass();
                                refreshList();
                                setTimeout(() => {
                                    currentInstance.proxy.$confirm('继续添加还是返回课程卡片?', '添加成功', {
                                        type: 'warning',
                                        confirmButtonText: '继续添加',
                                        cancelButtonText: '返回',
                                        beforeClose: (action1, instance1, done1) => {
                                            if (action1 === 'confirm') {
                                                done1();
                                            } else {
                                                done1();
                                                emit('update:visible', false);
                                            }
                                        }
                                    });
                                });
                            }
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                    }
                }
            });
        };

        const handleSearch = () => {
            refreshPage();
        };

        const refreshPage = () => {
            page.value = getDefaultPage();
            getPage();
        };

        const getPage = () => {
            console.log({
                followUpPhaseList: ['PreVisit', 'Visited'],
                followUpStatusesList: [[], []],
                keyword: keyword.value,
                timestamp: page.value.timestamp,
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
            });
            loading.value = true;
            getListPageApi({
                followUpPhaseList: ['PreVisit', 'Visited'],
                followUpStatusesList: [[], []],
                keyword: keyword.value,
                timestamp: page.value.timestamp,
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
            }).then(res => {
                if (loading.value) {
                    tableData.value = res.data.data.records;
                    page.value.currentPage = res.data.data.current;
                    page.value.pageSize = res.data.data.size;
                    page.value.total = res.data.data.total;
                    page.value.pages = res.data.data.pages;
                }
            }).finally(() => loading.value = false);
        };

        const sizeChangeHandle = (val) => {
            page.value.pageSize = val;
            page.value.currentPage = 1;
            getPage();
        };

        const currentChangeHandle = (val) => {
            page.value.currentPage = val;
            getPage();
        };

        const refreshClass = inject('refreshClass');

        const refreshList = inject('refreshList');

        onUnmounted(() => {
            keyword.value = undefined;
            tableData.value = [];
            page.value = getDefaultPage();
            loading.value = false;
        });

        const studentIds = computed(() => {
            if (Array.isArray(props.students) && props.students.length) {
                return new Set(props.students.filter(student => student.studentType === 'List').map(student => student.listId));
            }
            return new Set();
        });

        return {
            ...toRefs(props),
            keyword,
            page,
            tableData,
            loading,
            studentIds,
            handleConfirm,
            handleSearch,
            sizeChangeHandle,
            currentChangeHandle,
            getBirthdayDisplay
        };

    }
});
</script>

<style lang="scss" scoped>
header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #ccc;
    position: relative;

    img {
        position: absolute;
        width: 14px;
        height: 14px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    p {
        margin: 0;
        width: fit-content;
        padding: 0 10px;
        height: 60px;
        line-height: 60px;
        box-sizing: border-box;
        font-size: 14px;
        color: #292e33;
        cursor: pointer;
    }

    p.on {
        border-bottom: 4px solid #29cb97;
        color: #29cb97;
        font-weight: 500;
    }
}

.yueke_stu {
    padding: 0 15px;
    box-sizing: border-box;

    .filter {
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
}
</style>


<style lang="scss">
.el-form--label-top .el-form-item__label {
    padding-bottom: 0;
}

.addStudent-dialog {
    //height: 520px;

    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #ccc;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 40px;
        color: #292e33;
        display: none;

        i::before {
            position: relative;
            top: -10px;
        }

        span {
            font-size: 14px;
        }
    }

    .el-dialog__body {
        padding: 0 0 30px;
        box-sizing: border-box;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
        overflow: hidden;
        border-top: 1px solid #ccc;
        display: none;
    }
}
</style>
<style lang="scss" scoped>
.text {
    .sex,
    .wx {
        position: absolute;
        right: 80px;
        top: 0;
        width: 10px;
        height: 10px;
    }

    .sex {
        background: #fda6c9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        right: 95px;
    }

    span {
        display: inline-block;
        color: #000;
        max-width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    p {
        color: #818c99;
        margin: 5px 0;
    }
}

.linkMan {
    width: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
        margin: 0;
    }

    span {
        color: #818c99;
        margin-top: 5px;
    }
}

.counselor {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
        margin: 0;
    }

    span {
        color: #818c99;
        margin-top: 5px;
    }
}
</style>
<style scoped>
>>> .el-input-group__append {
    padding: 0 !important;
}
</style>
