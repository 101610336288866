<template>
     <el-dialog class="asd" :modal="true" width="700px" :visible.sync="visible" title="编辑会员" :close-on-click-modal="false" :show-close="false">
        <p>请刷卡</p>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeCard" size="small">取 消</el-button>
            <el-button type="primary" @click="closeCard" size="small">确 定</el-button>
        </span>
    </el-dialog>
    
</template>

<script>
export default {
    name: 'BalletVueAddCard',
    props:['visible','closeCard'],
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
       
    },
};
</script>

<style lang="scss" scoped>
p{
    margin: 0;
    text-align: center;
    font-size: 18px;
}
</style>