import Vue from 'vue';
import Vuex from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import common from './modules/common';
import user from './modules/user';
import campus from './modules/campus';
import {getNotReadMessageCount} from "../api/message/message";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        common,
        user,
        campus
    },
    state: {
        drawer: false,
        sumNum: 0,
    },
    mutations: {
        // 重置vuex本地储存状态
        resetStore(state) {
            this.commit('campus/clear');
        },
        // 切换抽屉状态
        toggleDrawer(state) {
            state.drawer = !state.drawer;
        },
        //关闭抽屉
        closeDrawer(state) {
            state.drawer = false;
            state.sumNum = 0;
        },
        getNotReadMessageNum(state) {
            getNotReadMessageCount({
                timestamp: new Date().getTime()
            }).then(res => {
                state.sumNum = res.data.data.countInfo.Message + res.data.data.countInfo.Task;
                return res.data.data.countInfo;
            });
        },
    },
    strict: process.env.NODE_ENV !== 'production'
});
