<template>
<div class="follow_wrap" v-loading="loading===1">
    <div class="follow_header follow-up-record-header">
        <div style="display: flex;gap: 5px;">
            <div class="btn_box">
                <span
                    :style="active==='1'?'border:1px solid #29cb97;color:#29cb97;font-size:12px;':'border:1px solid #d8dee9;border-right:none;font-size:12px;'"
                    @click="active='1'">会员跟进内容</span>
                <span
                    :style="active==='2'?'border:1px solid #29cb97;color:#29cb97;font-size:12px;':'border:1px solid #d8dee9;border-left:none;font-size:12px;'"
                    @click="active='2'">名单跟进内容</span>
            </div>
            <el-form inline size="mini" v-if="active==='1'">
                <el-form-item>
                    <el-select style="width:80px;"
                               v-model="studentFollowUpRecordQueryForm.type"
                               placeholder="请选择">
                        <el-option :value="undefined" label="全部记录"></el-option>
                        <el-option value="FollowUp" label="跟进记录"></el-option>
                        <el-option value="Call" label="通话记录"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="跟进方式:">
                    <el-select style="width:80px;"
                               v-model="studentFollowUpRecordQueryForm.followUpMethod"
                               clearable
                               placeholder="请选择">
                        <el-option :value="followUpMethod.value"
                                   :label="followUpMethod.label"
                                   :key="followUpMethod.value"
                                   v-for="followUpMethod in $store.getters['common/followUpMethod']">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="服务类型:">
                    <el-select size="mini"
                               style="width:80px;"
                               v-model="studentFollowUpRecordQueryForm.serviceType"
                               clearable
                               placeholder="请选择">
                        <el-option v-for="item in $store.getters['common/serviceTypeList']"
                                   :key="item.value"
                                   v-bind="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        style="width:210px;"
                        align="center"
                        v-model="listFollowUpRecordQueryForm.dateRange"
                        type="daterange"
                        range-separator="~"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <el-form inline size="mini" v-else-if="active==='2'">
                <el-form-item>
                    <el-select style="width:80px;"
                               v-model="listFollowUpRecordQueryForm.type"
                               placeholder="请选择">
                        <el-option value="" label="跟进记录"></el-option>
                        <el-option value="" label="通话记录"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="跟进方式:">
                    <el-select style="width:80px;"
                               v-model="listFollowUpRecordQueryForm.followUpMethod"
                               clearable
                               placeholder="请选择">
                        <el-option :value="followUpMethod.value"
                                   :label="followUpMethod.label"
                                   :key="followUpMethod.value"
                                   v-for="followUpMethod in $store.getters['common/followUpMethod']">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        style="width:210px;"
                        align="center"
                        v-model="studentFollowUpRecordQueryForm.dateRange"
                        type="daterange"
                        range-separator="~"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </div>
        <div class="right_box">
            <el-button style="background:#29cb97;border:1px solid #29cb97;" type="primary" size="mini">
                待处理会员通话记录（0）
            </el-button>
            <el-tooltip class="item" effect="dark"
                        content="待处理通话记录:未填写跟进记录的通话记录,此类纪录不计入跟进次数统计中,请尽快补录跟进记录"
                        placement="top">
                <div style="width:250px;box-sizing:border-box;white-space: normal;line-height:18px;" slot="content">
                    待处理通话记录:未填写跟进记录的通话记录,此类纪录不计入跟进次数统计中,请尽快补录跟进记录
                </div>
                <img src="../../../assets/img/wenhao3.png" alt="">
            </el-tooltip>
        </div>
    </div>
    <template v-if="active==='1'">
        <time-line :records="studentFollowUpRecords">
            <template v-slot="{record}">
                <div class="timeline-item-title">
                    <span style="color: #4fc3f7;">
                        [ {{
                            record.type === 'FollowUp' ? '跟进记录' : (record.type === 'Call' ? '通话记录' : '')
                        }} ]
                    </span>
                    <span class="label">操作人:</span>
                    <span>{{ record.createTenantUserName }}</span>
                </div>
                <div class="timeline-item-content">
                    <div class="item" v-if="record.followUpMethod">
                        <div class="label" style="color: #b4bfcc;">跟进方式:</div>
                        <div class="text">{{ record.followUpMethodName }}</div>
                    </div>
                    <div class="item" v-if="record.serviceType">
                        <div class="label" style="color: #b4bfcc;">服务类型:</div>
                        <div class="text"> {{ record.serviceTypeName }}</div>
                    </div>
                    <div class="item" v-if="Array.isArray(record.tags)&&record.tags.length">
                        <div class="label">标签:</div>
                        <ul class="text tag-list-wrapper">
                            <li class="tag-item"
                                v-for="tag in record.tags"
                                :style="{color:tag.color,backgroundColor:tag.color+'24'}"
                                :key="tag.id">
                                #{{ tag.name }}
                            </li>
                        </ul>
                    </div>
                    <div class="item" v-if="record.nextTime">
                        <div class="label">下次跟进时间:</div>
                        <div class="text">{{ record.nextTime }}</div>
                    </div>
                    <div>
                        <div class="label">跟进内容:</div>
                        <div>{{ record.content }}</div>
                    </div>
                    <ul v-if="Array.isArray(record.images)&&record.images.length" class="images-wrapper">
                        <li v-for="(image,index) in record.images" :key="index">
                            <el-image :preview-src-list="[image]" class="image-item" alt="" :src="image"></el-image>
                        </li>
                    </ul>
                </div>
            </template>
        </time-line>
        <div v-if="studentFollowUpRecordPageQuery.total<=studentFollowUpRecords.length"
             style="padding: 20px 0;text-align: center;">
            暂无更多数据
        </div>
    </template>
    <template v-if="active==='2'">
        <list-follow-up-record-timeline :records="listFollowUpRecords"></list-follow-up-record-timeline>
        <div v-if="listFollowUpRecordPageQuery.total<=listFollowUpRecords.length"
             style="padding: 20px 0;text-align: center;">
            暂无更多数据
        </div>
    </template>
    <div class="add-record-button">
        <el-button class="addListBtn"
                   style="position: sticky;bottom: 30px;"
                   type="primary"
                   @click="addStudentFollowUpRecordModalVisible=true">
            添加记录
        </el-button>
    </div>
    <add-student-follow-up-record :visible.sync="addStudentFollowUpRecordModalVisible"
                                  :student-id="studentId"
                                  @updated="$emit('updated')">
    </add-student-follow-up-record>
</div>
</template>
<script>
//添加跟进记录弹窗
import AddStudentFollowUpRecord from './AddStudentFollowUpRecord.vue'
import {defineComponent, onMounted, ref, toRefs, watch} from 'vue'
import TimeLine from '../../List/TimeLine.vue'
import {getDefaultPage} from '../../../utils'
import {getStudentFollowUpRecordPageApi} from '../../../api/student/studentFollowUpRecordApi'
import {getListFollowUpRecordPageApi} from '../../../api/student/listFollowUpRecordApi'
import ListFollowUpRecordTimeline from '../../List/detail/ListFollowUpRecordTimeline.vue'
import dayjs from 'dayjs'
import {cloneObject} from '../../../utils/ObjectUtils'

export default defineComponent({
    components: {ListFollowUpRecordTimeline, TimeLine, AddStudentFollowUpRecord},
    props: {studentId: Number, listId: Number},
    emits: ['updated'],
    setup (props) {

        const addStudentFollowUpRecordModalVisible = ref(false)

        const studentFollowUpRecordPageQuery = ref(getDefaultPage())

        const listFollowUpRecordPageQuery = ref(getDefaultPage())

        const defaultStudentFollowUpRecordQueryForm = {
            type: undefined,
            followUpMethod: undefined,
            serviceType: undefined,
            dateRange: []
        }

        const defaultListFollowUpRecordQueryForm = {
            type: undefined,
            followUpMethod: undefined,
            dateRange: []
        }

        const studentFollowUpRecordQueryForm = ref(cloneObject(defaultStudentFollowUpRecordQueryForm))

        const listFollowUpRecordQueryForm = ref(cloneObject(defaultListFollowUpRecordQueryForm))

        const studentFollowUpRecords = ref([])

        const listFollowUpRecords = ref([])

        const loading = ref(0)

        const active = ref()

        const getStudentFollowUpRecordPage = () => {
            getStudentFollowUpRecordPageApi(props.studentId, {
                type: studentFollowUpRecordQueryForm.value.type,
                followUpMethod: studentFollowUpRecordQueryForm.value.followUpMethod,
                serviceType: studentFollowUpRecordQueryForm.value.serviceType,
                startDate: Array.isArray(studentFollowUpRecordQueryForm.value.dateRange) && studentFollowUpRecordQueryForm.value.dateRange.length ?
                    dayjs(studentFollowUpRecordQueryForm.value.dateRange[0]).format('YYYY-MM-DD') + ' 00:00:00.000' : undefined,
                endDate: Array.isArray(studentFollowUpRecordQueryForm.value.dateRange) && studentFollowUpRecordQueryForm.value.dateRange.length ?
                    dayjs(studentFollowUpRecordQueryForm.value.dateRange[1]).format('YYYY-MM-DD') + ' 23:59:59.999' : undefined,
                currentPage: studentFollowUpRecordPageQuery.value.currentPage,
                pageSize: studentFollowUpRecordPageQuery.value.pageSize,
                timestamp: studentFollowUpRecordPageQuery.value.timestamp,
            }).then(res => {
                if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                    for (let record of res.data.data.records) {
                        studentFollowUpRecords.value.push(record)
                    }
                }
                studentFollowUpRecordPageQuery.value.currentPage = res.data.data.current
                studentFollowUpRecordPageQuery.value.pageSize = res.data.data.size
                studentFollowUpRecordPageQuery.value.total = res.data.data.total
            }).finally(() => {
                loading.value = 0
            })
        }

        const getListFollowUpRecordPage = () => {
            getListFollowUpRecordPageApi(props.listId, {
                type: listFollowUpRecordQueryForm.value.type,
                followUpMethod: listFollowUpRecordQueryForm.value.followUpMethod,
                startDate: Array.isArray(listFollowUpRecordQueryForm.value.dateRange) && listFollowUpRecordQueryForm.value.dateRange.length ?
                    dayjs(listFollowUpRecordQueryForm.value.dateRange[0]).format('YYYY-MM-DD') + ' 00:00:00.000' : undefined,
                endDate: Array.isArray(listFollowUpRecordQueryForm.value.dateRange) && listFollowUpRecordQueryForm.value.dateRange.length ?
                    dayjs(listFollowUpRecordQueryForm.value.dateRange[1]).format('YYYY-MM-DD') + ' 23:59:59.999' : undefined,
                currentPage: listFollowUpRecordPageQuery.value.currentPage,
                pageSize: listFollowUpRecordPageQuery.value.pageSize,
                timestamp: listFollowUpRecordPageQuery.value.timestamp,
            }).then(res => {
                if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                    for (let record of res.data.data.records) {
                        listFollowUpRecords.value.push(record)
                    }
                }
                listFollowUpRecordPageQuery.value.currentPage = res.data.data.current
                listFollowUpRecordPageQuery.value.pageSize = res.data.data.size
                listFollowUpRecordPageQuery.value.total = res.data.data.total
            }).finally(() => {
                loading.value = 0
            })
        }

        const refreshPage = () => {
            if (active.value === '1') {
                loading.value = 1
                studentFollowUpRecordPageQuery.value = getDefaultPage()
                studentFollowUpRecords.value = []
                getStudentFollowUpRecordPage()
            } else if (active.value === '2') {
                loading.value = 1
                listFollowUpRecordPageQuery.value = getDefaultPage()
                listFollowUpRecords.value = []
                getListFollowUpRecordPage()
            }
        }

        const nextPage = () => {
            if (active.value === '1') {
                if (studentFollowUpRecordPageQuery.value.total <= studentFollowUpRecords.value.length) {
                    return
                }
                loading.value = 2
                studentFollowUpRecordPageQuery.value.currentPage++
                getStudentFollowUpRecordPage()
            } else if (active.value === '2') {
                if (listFollowUpRecordPageQuery.value.total <= listFollowUpRecords.value.length) {
                    return
                }
                loading.value = 2
                listFollowUpRecordPageQuery.value.currentPage++
                getListFollowUpRecordPage()
            }
        }

        onMounted(() => {
            active.value = '1'
        })

        watch(studentFollowUpRecordQueryForm, refreshPage, {deep: true})

        watch(listFollowUpRecordQueryForm, refreshPage, {deep: true})

        watch(active, newVal => {
            if (newVal === '1') {
                studentFollowUpRecordQueryForm.value = cloneObject(defaultStudentFollowUpRecordQueryForm)
            } else if (newVal === '2') {
                listFollowUpRecordQueryForm.value = cloneObject(defaultListFollowUpRecordQueryForm)
            }
        })

        return {
            ...toRefs(props),
            addStudentFollowUpRecordModalVisible,
            studentFollowUpRecordPageQuery,
            listFollowUpRecordPageQuery,
            studentFollowUpRecordQueryForm,
            listFollowUpRecordQueryForm,
            loading,
            active,
            studentFollowUpRecords,
            listFollowUpRecords,
            refreshPage,
            nextPage
        }
    }
})
</script>

<style lang="scss" scoped>
::v-deep.el-range-editor--mini .el-range-input {
    font-size: 11px;
}

::v-deep .el-input__inner {
    padding: 0;
    height: 25px !important;
}

.follow_wrap {
    padding: 10px 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    min-height: inherit;

    .follow_header {

        .btn_box {
            display: flex;
            align-items: center;
            margin-right: 5px;

            span {
                color: #525b66;
                border: 1px solid #d8dee9;
                cursor: pointer;
                padding: 0 10px;
                box-sizing: border-box;
                height: 27px;
                line-height: 27px;
                background: #fff;
                transition: all .3s;
            }

            span:hover {
                color: #29cb97;
            }

            span:nth-of-type(1) {
                border-radius: 4px 0 0 4px;
            }

            span:nth-of-type(2) {
                border-radius: 0 4px 4px 0;
            }
        }

        .follow_type {
            width: 140px;
            display: flex;
            // align-items: center;
            margin-left: 3px;
            height: 27px;

            span {
                color: #818c99;
                font-size: 12px;
                line-height: 30px;
                white-space: nowrap;
                margin-right: 5px;
            }
        }

        .service_type {
            width: 140px;
            display: flex;
            // align-items: center;
            margin-left: 3px;
            height: 27px;

            span {
                color: #818c99;
                font-size: 12px;
                line-height: 30px;
                white-space: nowrap;
                margin-right: 5px;
            }
        }

        .right_box {
            display: flex;
            align-items: center;
            height: 100%;

            img {
                width: 15px;
                height: 15px;
                margin-left: 5px;
            }
        }
    }
}

.follow-up-record-header {
    position: sticky;
    padding: 10px;
    top: 45px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: #f5f7fa;
    z-index: 1000;
    align-items: center;
}

.add-record-button {
    position: absolute;
    right: 50px;
    bottom: 30px;
    z-index: 2000;
}

.timeline-item-title {
    border-bottom: 1px solid #edf0f2;
    padding-bottom: 10px;
    margin: 0 14px;
}

.timeline-item-title > span:not(:first-child) {
    margin-left: 5px;
}

.timeline-item-content {
    margin: 0 14px;
    padding-top: 5px;
}

.item {
    width: 50%;
    margin-bottom: 5px;
    display: flex;
}

.label {
    color: #818c99;
}

.text {
    color: #292e33;
    margin-left: 10px;
}

.images-wrapper {
    display: flex;
    gap: 12px;
    padding: 10px 0;
}

.images-wrapper .image-item {
    height: 102px;
    border: 1px solid #edf0f2;
}

.tag-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.tag-item {
    padding: 0 10px;
    border-radius: 100px;
    font-size: 12px;
}
</style>
<style scoped>
>>> .el-form--inline .el-form-item {
    margin-right: 5px !important;
    margin-bottom: 0 !important;
}

>>> .el-form-item__label {
    color: #818c99 !important;
    font-size: 12px !important;
}
</style>
