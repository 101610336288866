import { render, staticRenderFns } from "./UpdatePerformanceVestingModal.vue?vue&type=template&id=59a393e3&scoped=true"
import script from "./UpdatePerformanceVestingModal.vue?vue&type=script&lang=js"
export * from "./UpdatePerformanceVestingModal.vue?vue&type=script&lang=js"
import style0 from "./UpdatePerformanceVestingModal.vue?vue&type=style&index=0&id=59a393e3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a393e3",
  null
  
)

export default component.exports