import Vue from 'vue';
import App from './App.vue';
import router from './router';                 // api: https://github.com/vuejs/vue-router
import store from './store';                   // api: https://github.com/vuejs/vuex
// api: https://github.com/alfhen/vue-cookie
import '@/element-ui';                         // api: https://github.com/ElemeFE/element
import '@/icons';                              // api: http://www.iconfont.cn/
import '@/element-ui-theme';
import '@/assets/scss/index.scss';
import '@/app.css';
import httpRequest from './utils/httpRequest'; // api: https://github.com/axios/axios
import {isAuth} from './utils';
import InfiniteScroll from 'vue-infinite-scroll';
import StudentDetailModal from './components/Student/StudentDetailModal.vue';
import ContractDetailInfo from './components/Contract/ContractDetailModal.vue';
import ListDetailModal from './components/List/ListDetailModal.vue';
import ClassInfo from './components/Class/detail/ClassInfo.vue';

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 50);
        super(callback);
    }
};

Vue.use(InfiniteScroll);
Vue.config.productionTip = false;

// 挂载全局
Vue.prototype.$http = httpRequest; // ajax请求方法
Vue.prototype.isAuth = isAuth;     // 权限方法
Vue.prototype.localStorage = localStorage;
Vue.prototype.console = console;
Vue.prototype.alert = alert;
Vue.prototype.hasMenuKey = (menuKey) => {
    return store.getters['user/hasMenuKey'](menuKey);
};
Vue.prototype.hasPermission = (permission) => {
    return store.getters['user/hasPermission'](permission);
};

// 保存整站vuex本地储存初始状态
//window.SITE_CONFIG['storeState'] = cloneDeep(store.state)

Vue.component('student-detail-modal', StudentDetailModal);
Vue.component('contract-detail-modal', ContractDetailInfo);
Vue.component('list-detail-modal', ListDetailModal);
Vue.component('contract-detail-info', ContractDetailInfo);
Vue.component('class-detail', ClassInfo);

const scrollUMap = {};

const handleScroll = (e, fun, uid, isDisabled) => {
    if (!isDisabled) {
        let height = 0;
        for (let i = 0; i < e.target.children.length; i++) {
            height += e.target.children[i].clientHeight;
        }
        if (Math.abs(e.target.scrollTop - (height - e.target.clientHeight)) <= 1) {
            if (scrollUMap[uid].lastRefreshTop === 0) {
                scrollUMap[uid].lastRefreshTop = e.target.scrollTop;
                fun();
            }
        } else {
            if (scrollUMap[uid].lastRefreshTop > 0) {
                scrollUMap[uid].lastRefreshTop = 0;
            }
        }
    }
};

Vue.directive('infinite', {
    bind(el, binding, vNode, oldVNode) {
        let isDisabled = false;
        if (Array.isArray(vNode.data.directives) && vNode.data.directives.length) {
            for (let directive of vNode.data.directives) {
                if (directive.name === 'infinite-disabled') {
                    isDisabled = !!directive.value;
                    break;
                }
            }
        }
        scrollUMap[vNode.context._uid] = {lastRefreshTop: 0};
        el.addEventListener('scroll', e => handleScroll(e, binding.value, vNode.context._uid, isDisabled));
    },
    unbind(el, binding, vNode, oldVNode) {
        delete scrollUMap[vNode.context._uid];
        el.removeEventListener('scroll', handleScroll);
    }
});

Vue.directive('infiniteDisabled', (el, binding) => {
    if (typeof binding.value !== 'boolean') {
        console.warn('v-infinite-disabled need to specify a boolean value!');
    }
});

Vue.directive('hasMenuKey', (el, binding, vnode) => {
    let menuKey = binding.value;
    if (typeof binding.value === 'function') {
        menuKey = binding.value();
    } else if (typeof binding.value !== 'string') {
        menuKey = binding.value ? binding.value + '' : '';
    }
    if (!store.getters['user/hasMenuKey'](menuKey)) {
        el.remove();
    }
});

//Vue.use(ElementUI);

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    template: '<App/>',
    components: {App}
});
