<template>
    <div class="yueke_stu" v-if="visible" v-loading="loading">
        <div class="filter">
            <div class="filter_left">
                <p>优先扣除课时: <em>普通课时</em></p>
            </div>
            <div class="filter_right">
                <search-input :disabled="loading"
                              placeholder="请输入内容"
                              size="mini"
                              v-model="keyword"
                              @search="handleSearch">
                    <template v-slot:append>
                        <div style="width: 100%;height: 100%;cursor: pointer;padding: 0 20px" @click="handleSearch">
                            搜索
                        </div>
                    </template>
                </search-input>
            </div>
        </div>
        <el-table :data="tableData" style="width: 100%" max-height="500px">
            <el-table-column prop="studentInfo" label="学员信息" width="180">
                <template v-slot="scope">
                    <div class="text">
                        <div>
                            <span>{{ scope.row.name }}</span>
                            <gender-view :gender="scope.row.sex"></gender-view>
                        </div>
                        <p>{{ getBirthdayDisplay(scope.row.birthdayTime, false) }}</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="contactsList" label="联系人" width="180">
                <template v-slot="scope">
                    <div class="linkMan">
                        <p>
                            <span> {{ scope.row.contactsList[0].name }}</span>
                            <span :style="'color:black;'">
                            （{{
                                    $store.getters['common/relationMap'][scope.row.contactsList[0].affiliation]
                                }}）
                        </span>
                        </p>
                        <span>{{ scope.row.contactsList[0].phone }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="construct"
                label="顾问&合同">
                <template v-slot="scope">
                    <div class="counselor" style="width: 100%;">
                        <p v-if="scope.row.consultant">
                            {{ '顾问：' + scope.row.consultant.staffName }}
                        </p>
                        <template v-if="scope.row.contract">
                            <change-contract-button :student-id="scope.row.id"
                                                    :contract="{name:scope.row.contract.paperName,id:scope.row.contract.id}"
                                                    click-mode="select"
                                                    @change="contract=>{
                            scope.row.contract.id=contract.id
                            scope.row.contract.paperName=contract.paperName?contract.paperName:contract.contractName
                        }">
                            </change-contract-button>
                        </template>
                        <change-contract-button v-else
                                                :student-id="scope.row.id"
                                                :contract="{}"
                                                click-mode="select"
                                                @change="contract=>{
                            scope.row.contract.id=contract.id
                            scope.row.contract.paperName=contract.paperName?contract.paperName:contract.contractName
                        }">
                        </change-contract-button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="operation"
                header-align="center"
                align="center"
                label="操作">
                <template v-slot="scope">
                    <el-button type="primary"
                               plain
                               size="small"
                               @click="handleConfirm(scope.row)">
                        排课
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <plan-class-modal v-bind="planInfo"
                          :visible.sync="planClassModalVisible"
                          @ok="handlePlaned">
        </plan-class-modal>
    </div>
</template>
<script>
import {getBirthdayDisplay} from '../../../utils/studentUtils';
import {defineComponent, getCurrentInstance, inject, onUnmounted, ref, toRefs} from 'vue';
import httpRequest from '../../../utils/httpRequest';
import PlanClassModal from './PlanClassModal.vue';
import GenderView from '../../Common/GenderView.vue';
import ChangeContractButton from './ChangeContractButton.vue';
import SearchInput from '@/components/Form/SearchInput.vue';

export default defineComponent({
    components: {SearchInput, ChangeContractButton, GenderView, PlanClassModal},
    props: {
        visible: Boolean,
        classId: Number,
        classNumberNo: String,
        consumeCount: Number
    },
    setup(props) {

        const currentInstance = getCurrentInstance();

        const keyword = ref();

        const tableData = ref([]);

        const planInfo = ref({});

        const planClassModalVisible = ref(false);

        const loading = ref(false);

        const handleConfirm = (row) => {
            planClassModalVisible.value = true;
            planInfo.value = {studentId: row.id, defaultContractId: row.contract.id, classId: props.classId};
        };

        const handleSearch = () => {
            if (!keyword.value) {
                currentInstance.proxy.$notify({type: 'warning', message: '请先输入搜索内容再搜索！'});
                return;
            }
            loading.value = true;
            httpRequest({
                url: httpRequest.adornUrl('/admin/balletstudent/getList'),
                method: 'get',
                params: httpRequest.adornParams({
                    name: keyword.value,
                }),
            }).then(({data}) => {
                if (loading.value) {
                    tableData.value = data.list;
                }
            }).finally(() => loading.value = false);
        };

        const handlePlaned = () => {
            reloadClass();
            currentInstance.proxy.$notify({type: 'success', message: '排课成功'});
        };

        const reloadClass = inject('reloadClass');

        onUnmounted(() => {
            keyword.value = undefined;
            tableData.value = [];
            loading.value = false;
        });

        return {
            ...toRefs(props),
            keyword,
            tableData,
            planClassModalVisible,
            planInfo,
            loading,
            handleConfirm,
            handleSearch,
            handlePlaned,
            getBirthdayDisplay
        };

    }
});
</script>

<style lang="scss" scoped>
header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #ccc;
    position: relative;

    img {
        position: absolute;
        width: 14px;
        height: 14px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    p {
        margin: 0;
        width: fit-content;
        padding: 0 10px;
        height: 60px;
        line-height: 60px;
        box-sizing: border-box;
        font-size: 14px;
        color: #292e33;
        cursor: pointer;
    }

    p.on {
        border-bottom: 4px solid #29cb97;
        color: #29cb97;
        font-weight: 500;
    }
}

.yueke_stu {
    padding: 0 15px;
    box-sizing: border-box;

    .filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;

        .filter_left {
            display: flex;
            align-items: center;

            label {
                display: flex;
                align-items: center;
                margin-right: 20px;

                span {
                    cursor: pointer;
                }
            }

            p {
                margin: 0;

                em {
                    color: #29cb97;
                    font-style: normal;
                }
            }
        }
    }
}
</style>


<style lang="scss">
.el-form--label-top .el-form-item__label {
    padding-bottom: 0;
}

.addStudent-dialog {
    //height: 520px;

    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #ccc;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 40px;
        color: #292e33;
        display: none;

        i::before {
            position: relative;
            top: -10px;
        }

        span {
            font-size: 14px;
        }
    }

    .el-dialog__body {
        padding: 0 0 30px;
        box-sizing: border-box;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
        overflow: hidden;
        border-top: 1px solid #ccc;
        display: none;
    }
}
</style>
<style lang="scss" scoped>
.text {
    .sex,
    .wx {
        position: absolute;
        right: 80px;
        top: 0;
        width: 10px;
        height: 10px;
    }

    .sex {
        background: #fda6c9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        right: 95px;
    }

    p {
        color: #818c99;
        margin: 5px 0;
    }
}

.linkMan {
    width: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
        margin: 0;
    }

    span {
        color: #818c99;
        margin-top: 5px;
    }
}

.counselor {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
        margin: 0;
    }

    span {
        color: #818c99;
        margin-top: 5px;
    }
}
</style>
<style scoped>
>>> .el-input-group__append {
    padding: 0 !important;
}
</style>
