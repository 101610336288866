<script>
import {defineComponent, getCurrentInstance, ref} from "vue";
import {leaveClassApi} from "../../api/class/createClass";

export default defineComponent({
    props: {
        visible: {
            type: Boolean
        },
        closeLeaveModal: {
            type: Function
        },
        classId: {
            type: Number
        },
        studentId:{
            type: Number
        },
    },
    setup(props) {
        const currentInstance = getCurrentInstance().proxy;
        let check = ref(undefined);
        let textarea = ref('');
        const submit = ()=>{
            console.log(props.classId,check.value,textarea.value,'id');
            //leaveClassApi
            currentInstance.$confirm('确认要请假吗',{
                type:'warning',
                beforeClose:(action, instance, done)=>{
                    if(action === 'confirm'){
                        instance.confirmButtonLoading = true;
                        leaveClassApi({
                            consumption:check.value,
                            classId:props.classId,
                            studentId:props.studentId,
                            reason:textarea.value
                        }).then(res=>{
                            currentInstance.$notify({type: 'success', title: '提示', message: '请假成功！'})
                            if(res.data==20111){
                                // currentInstance.$notify({
                                //     message: '本次请假将会扣课',
                                //     type: 'warning',
                                //     duration: 1500,
                                // })
                                currentInstance.$notify({type: 'error', title: '提示', message: '本次请假将会扣课！'})
                            }
                        }).finally(()=>{
                            props.closeLeaveModal();
                            instance.confirmButtonLoading = false;
                            done();
                        })
                    }else {
                        done();
                    }
                }
            });
        };
        return {
            check,
            textarea,
            submit,
        }
    }
})

</script>

<template>
    <el-dialog
        width="400px"
        :visible="visible"
        append-to-body
        :before-close="closeLeaveModal"
        custom-class="leave-reason-dialog"
        title="请假原因"
    >
        <div class="check">
            <span>选择: </span>
            <div>
                <el-radio-group size="mini" v-model="check">
                    <el-radio size="mini" :label="undefined">默认</el-radio>
                    <el-radio size="mini" :label="true">扣课</el-radio>
                    <el-radio size="mini" :label="false">不扣课</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="check">
            <span>原因: </span>
            <div style="width: 100%">
                <el-input
                    type="textarea"
                    style="width: 100%"
                    :autosize="{ minRows: 3, maxRows: 5}"
                    :rows="3"
                    placeholder="请输入内容"
                    v-model="textarea">
                </el-input>
            </div>
        </div>

        <div slot="footer">
            <el-button size="mini" @click="closeLeaveModal">取消</el-button>
            <el-button size="mini" type="primary" @click="submit">提交</el-button>
        </div>
    </el-dialog>
</template>

<style scoped lang="scss">
.check{
    display: flex;
    &+&{
        margin-top: 10px;
    }
    span{
        white-space: nowrap;
        margin-right: 10px;
    }
}
</style>
<style lang="scss">
.leave-reason-dialog {
    .el-dialog__header {
        border-bottom: 1px solid #e5e6e7;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
        border-top: 1px solid #e5e6e7;
    }
}
</style>
