<template>
<el-select v-bind="$attrs"
           :value="value"
           @input="val=>$emit('update:value',val)"
           filterable
           remote
           :multiple="multiple"
           :remote-method="remoteMethod"
           :loading="selectLoading"
           @change="handleChange">
    <slot :options="options">
        <el-option v-if="hasAllOption" label="全部" :value="undefined"></el-option>
        <el-option v-for="option in options"
                   :key="option.id"
                   :value="option.id"
                   :label="option.campusName">
        </el-option>
    </slot>
</el-select>
</template>
<script>
import {computed, defineComponent, onMounted, ref, toRefs} from 'vue'
import {isEqual} from '../../utils/ObjectUtils'
import {storePageApi} from '../../api/store/storeApi'

export default defineComponent({
    props: {
        hasAllOption: Boolean,
        value: [Number, Array],
        isChildren: Number,
        multiple: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    model: {
        value: 'value',
        event: 'update:value'
    },
    emits: ['update:value', 'change'],
    setup (props, {emit}) {

        const selectLoading = ref(true)

        const defaultOptions = ref([])

        const searchOptions = ref()

        let searchParams = undefined

        const remoteMethod = (val) => {
            if (selectLoading.value) {
                return
            }
            if (val) {
                selectLoading.value = true
                const currentSearchParams = searchParams = {
                    keyword: val,
                    timestamp: new Date().getTime(),
                    isChildren: props.isChildren
                }
                storePageApi(searchParams)
                    .then(res => {
                        if (isEqual(searchParams, currentSearchParams)) {
                            searchOptions.value = res.data.data
                        }
                    }).finally(() => selectLoading.value = false)
            } else {
                searchOptions.value = undefined
            }
        }

        const handleChange = (val) => {
            if (props.multiple) {
                if (Array.isArray(val) && val.length) {
                    emit('change', val, val.map(item => options.value.find(record => record.id === item)))
                } else {
                    emit('change', val, [], [])
                }
            } else {
                if (val) {
                    emit('change', val, options.value.find(record => record.id === val))
                } else {
                    emit('change', undefined, undefined)
                }
            }
        }

        onMounted(() => {
            storePageApi({timestamp: new Date().getTime()})
                .then(res => {
                    defaultOptions.value = res.data.data.records
                }).finally(() => selectLoading.value = false)
        })

        const options = computed(() => {
            if (selectLoading.value) {
                return []
            }
            if (searchOptions.value === undefined) {
                return defaultOptions.value
            } else {
                return searchOptions.value.records
            }
        })

        return {
            ...toRefs(props),
            selectLoading,
            options,
            remoteMethod,
            handleChange
        }
    }
})
</script>
