<template>
<el-dialog custom-modal
           width="35%"
           title="修改来源渠道"
           :visible="visible"
           show-close
           :update:visible="val=>$emit('update:visible',val)"
           append-to-body>
    <el-form size="mini" label-position="top">
        <el-form-item label="来源" required>
            <source-channel-select style="width: 100%;"
                                   :value.sync="formData.sourceIds"
                                   :source-type.sync="formData.sourceType">
            </source-channel-select>
        </el-form-item>
    </el-form>
    <template v-slot:footer>
        <el-button @click="$emit('update:visible',false)">取消</el-button>
        <el-tooltip :disabled="!disabled"
                    placement="top"
                    content="修改后的来源和原来源相同,无法修改！">
            <el-button type="primary"
                       @click="handleConfirm"
                       :loading="confirmLoading"
                       :disabled="disabled">
                确定
            </el-button>
        </el-tooltip>
    </template>
</el-dialog>
</template>
<script>
import {computed, defineComponent, toRefs, watch, ref} from 'vue';
import SourceChannelSelect from '@/components/Form/SourceChannelSelect.vue';
import {updateSourceApi} from '@/api/student/listApi';

export default defineComponent({
    components: {SourceChannelSelect},
    props: {
        visible: Boolean, data: {
            type: Object,
            default () {
                return {
                    sources: [],
                    sourceIds: [],
                };
            }
        }
    },
    emits: ['update:visible', 'ok'],
    setup (props, {emit}) {

        const confirmLoading = ref(false);

        const formData = ref({
            sourceIds: [],
            sourceType: undefined
        });

        const handleConfirm = () => {
            confirmLoading.value = true;
            updateSourceApi({
                listId: props.data.id,
                sourceType: formData.value.sourceType,
                sourceIds: formData.value.sourceIds
            }).then(() => {
                emit('update:visible', false);
                emit('ok');
            }).finally(() => confirmLoading.value = false);
        };

        const disabled = computed(() => {
            return (!Array.isArray(props.data.sources) || !props.data.sources.length || props.data.sources.map(s => s.id).join(',') === formData.value.sourceIds.join(','))
                && props.data.sourceType === formData.value.sourceType;
        });

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value = {
                    sourceIds: props.data.sourceIds,
                    sourceType: props.data.sourceType
                };
            } else {
                formData.value = {
                    sourceIds: [],
                    sourceType: undefined
                };
            }
        });

        return {
            ...toRefs(props),
            formData,
            confirmLoading,
            handleConfirm,
            disabled,
        };
    }
});
</script>
