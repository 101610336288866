import {labelValueToObjectKeyValue} from '../../utils';
import {getProfileApi} from '../../api/system/systemConfigApi';
import {
    classType,
    listStatusList,
    listTransferTypeList,
    listRecordSourceList,
    performanceType,
    listVisitForList, overType
} from './const';
import {fixedRole} from '../../utils/const';
import {cloneObject, copyPropertyTo} from '../../utils/ObjectUtils';

export default {
    namespaced: true,
    state: {
        //页面文档可视高度(随窗口改变大小)
        documentClientHeight: 0,
        //导航条, 布局风格, default(默认) / inverse(反向)
        navbarLayoutType: 'default',
        //侧边栏, 布局皮肤, light(浅色) / dark(黑色)
        sidebarLayoutSkin: 'dark',
        //侧边栏, 折叠状态
        sidebarFold: false,
        //上方栏, 折叠状态
        navbarFold: false,
        //侧边栏, 菜单
        menuList: [],
        menuActiveName: '',
        //内容, 是否需要刷新
        contentIsNeedRefresh: false,
        //主入口标签页
        mainTabs: [],
        mainTabsActiveName: '',
        //门店名称
        storeName: '',
        //是否儿童校区
        storeChild: 0,
        //校区列表
        _schoolDistrictList: undefined,
        //  系统配置
        _profile: undefined,
        //名单状态
        listStatusList,
        //名单转出类型
        listTransferTypeList,
        //名单录入来源
        listRecordSourceList,
        //业绩类型
        performanceType,
        //完结类型
        overType,
        //课程类型
        classType,
        //到访事宜
        listVisitForList,
        //跟进状态
        followStateList: [
            {value: 1, label: '未跟进'},
            {value: 2, label: '未接通'},
            {value: 3, label: '已诺访'},
            {value: 4, label: '已电访'},
            {value: 5, label: '已跟进'},
            {value: 6, label: '已到访'},
            {value: 7, label: '已试听'},
            {value: 8, label: '已签约'}
        ],
        //跟进类型
        listFollowUpRecordTypeList: [
            {value: 'Collect', label: '采单记录'},
            {value: 'Allocate', label: '分配记录'},
            {value: 'FollowUp', label: '跟进记录'},
            {value: 'System', label: '系统记录'},
            {value: 'PhoneCall', label: '通话记录'}
        ],
        //分配状态
        allocationStateList: [
            {value: 1, label: '待分配'},
            {value: 2, label: '仅顾问'},
            {value: 3, label: '仅TMK'},
            {value: 4, label: '顾问和TMK'},
            {value: 5, label: '回收'}
        ],
        classState: [
            {value: 1, label: '待签到'},
            {value: 2, label: '已签到'},
            {value: 4, label: '已请假'},
            {value: 6, label: '已旷课'},
            {value: 7, label: '超时未到'},
            {value: 5, label: '已取消'}
        ],
        studentClassStatusList: [
            {value: 'NoClasses', label: '待签到'},
            {value: 'SignedIn', label: '已签到(未迟到)'},
            {value: 'SignedInLateness', label: '已签到(迟到)'},
            {value: 'Canceled', label: '已取消'},
            {value: 'LeaveOfAbsence', label: '已请假(扣课)'},
            {value: 'LeaveOfAbsenceNoConsume', label: '已请假(不扣课)'},
            {value: 'Absenteeism', label: '已旷课'},
            {value: 'LineUp', label: '正在排队'},
        ],
        relationList: [
            {label: '爸爸', value: 1},
            {label: '妈妈', value: 2},
            {label: '爷爷', value: 3},
            {label: '奶奶', value: 4},
            {label: '外公', value: 5},
            {label: '外婆', value: 6},
            {label: '本人', value: 7},
            {label: '保姆', value: 8},
            {label: '其他', value: 9}
        ],
        contractStateList: [
            {label: '待审核', value: 1},
            {label: '已审核', value: 2},
            {label: '已生效', value: 3},
            {label: '已终止', value: 4},
            {label: '已驳回', value: 5},
            {label: '已完结', value: 6},
            {label: '已过期', value: 7},
            {label: '已冻结', value: 8},
            {label: '退款完结', value: 9}
        ],
        contractStatusList: [
            {label: '待审核', value: 'WaitAudit'},
            {label: '已审核', value: 'Audit'},
            {label: '已生效', value: 'Effective'},
            {label: '已终止', value: 'Termination'},
            {label: '已驳回', value: 'Reject'},
            {label: '已完结', value: 'Over'},
            {label: '已过期', value: 'Expire'},
            {label: '已冻结', value: 'Freeze'},
            {label: '退款完结', value: 'RefundOver'},
            {label: '定金合同', value: 'Deposit'},
        ],
        contractPerformanceTypeList: [
            {label: '新签', value: 'New'},
            {label: '续签', value: 'Renew'},
            {label: '退款', value: 'Refund'},
            {label: '转中心', value: 'Transfer'},
        ],
        contractOverTypeList: [
            {label: '确认收入完结', value: 'ConfirmRevenues'},
            {label: '转中心完结', value: 'Transfer'},
            {label: '退款完结', value: 'Refund'},
            {label: '自然完结', value: 'Nature'},
        ],
        contractWaterStatusList: [
            {label: '收款', value: 1},
            {label: '冻结', value: 2},
            {label: '转出', value: 3},
            {label: '改期', value: 4},
            {label: '改套餐（大改小）', value: 5},
            {label: '改套餐（小改大）', value: 6},
            {label: '修改课时', value: 7},
            {label: '修改礼物', value: 8},
            {label: '修改耗课', value: 9},
            {label: '修改适用规则', value: 10},
            {label: '修改适用学员', value: 11},
            {label: '解冻', value: 12},
            {label: '改合同（大改小）', value: 13},
            {label: '改合同（小改大）', value: 14},
            {label: '退款完结', value: 15},
            {label: '确认收入完结', value: 16},
        ],
        sourceTypeList: [
            {label: 'CALL IN', value: 'CallIn'},
            {label: 'WALK IN', value: 'WalkIn'},
            {label: 'REFERRAL', value: 'Referral'},
            {label: 'MKT', value: 'Mkt'},
            {label: 'NET IN', value: 'NetIn'},
            {label: '合同转中心', value: 'ContractTransfer'},
            {label: '名单转中心', value: 'ListTransfer'},
            {label: '软件自来客', value: 'App'},
        ],
    },
    getters: {
        fixedRoles() {
            return Object.keys(fixedRole)
                .map(k => {
                    const val = fixedRole[k];
                    return {
                        label: val.name,
                        value: val.code,
                        key: k
                    };
                });
        },
        followUpMethod(state) {
            return getOrDefaultFormProfile(state, profile => {
                return profile.followUpMethods.map(item => {
                    return {label: item.name, value: item.id};
                });
            }, []);
        },
        serviceType(state) {
            return getOrDefaultFormProfile(state, profile => {
                return profile.serviceTypes.map(item => {
                    return {label: item.name, value: item.id};
                });
            }, []);
        },
        followUpStatusPoint(state) {
            return getOrDefaultFormProfile(state, profile => {
                return profile.followUpStatusPoints.map(item => {
                    return {
                        label: item.name,
                        value: item.followUpPhase,
                        followUpStatusList: item.followUpStatusList.map(it => {
                            return {label: it.name, value: it.id};
                        })
                    };
                });
            }, []);
        },
        listStatusChangeReasonGroup(state) {
            return getOrDefaultFormProfile(state, profile => {
                return profile.listStatusChangeReasonGroups.map(item => {
                    return {
                        label: item.name,
                        value: item.id,
                        listStatus: item.listStatus,
                        listStatusChangeReasons: item.listStatusChangeReasons.map(it => {
                            return {label: it.reason, value: it.id};
                        })
                    };
                });
            }, []);
        },
        payMethods(state) {
            return getOrDefaultFormProfile(state, profile => {
                return profile.payMethods.map(item => {
                    return {label: item.payName, value: item.id};
                });
            }, []);
        },
        defaultPhoneNumberArea(state) {
            return getOrDefaultFormProfile(state, profile => {
                return profile.configGroup[4].items[0].value;
            }, '');
        },
        phoneNumberAreas(state) {
            return getOrDefaultFormProfile(state, profile => {
                return profile.configGroup[4].items[0].allowValues;
            }, []);
        },
        relationMap(state) {
            return labelValueToObjectKeyValue(state.relationList);
        },
        followStateMap(state) {
            return labelValueToObjectKeyValue(state.followStateList);
        },
        allocationStateMap(state) {
            return labelValueToObjectKeyValue(state.allocationStateList);
        },
        listFollowUpRecordTypeListMap(state) {
            return labelValueToObjectKeyValue(state.listFollowUpRecordTypeList);
        },
        listStatusMap() {
            return labelValueToObjectKeyValue(listStatusList);
        },
        listTransferTypeMap() {
            return labelValueToObjectKeyValue(listTransferTypeList);
        },
        listRecordSourceMap() {
            return labelValueToObjectKeyValue(listRecordSourceList);
        },
        contractWaterStatusMap(state) {
            return labelValueToObjectKeyValue(state.contractWaterStatusList);
        },
        payMethodMap(state, getters) {
            return labelValueToObjectKeyValue(getters.payMethods);
        },
        performanceTypeMap(state) {
            return labelValueToObjectKeyValue(state.performanceType);
        },
        overTypeMap(state) {
            return labelValueToObjectKeyValue(state.overType);
        },
        classTypeMap(state) {
            return labelValueToObjectKeyValue(state.classType);
        },
        studentClassStatusMap(state) {
            return labelValueToObjectKeyValue(state.studentClassStatusList);
        },
        sourceTypeMap(state) {
            return labelValueToObjectKeyValue(state.sourceTypeList);
        },
        contractStateMap(state) {
            return labelValueToObjectKeyValue(state.contractStateList);
        },
        contractStatusMap(state) {
            return labelValueToObjectKeyValue(state.contractStatusList);
        },
        contractPerformanceTypeMap(state) {
            return labelValueToObjectKeyValue(state.contractPerformanceTypeList);
        },
        contractOverTypeMap(state) {
            return labelValueToObjectKeyValue(state.contractOverTypeList);
        },
        sourceChannels(state, getters) {
            return getOrDefaultFormProfile(state, profile => {
                const result = [];
                const sourceList = cloneObject(profile.sourceList);
                for (let key in sourceList) {
                    function clearEmptyChildren(children) {
                        for (let item of children) {
                            if (Array.isArray(item.children) && item.children.length) {
                                if (!item.children[0].id) {
                                    item.children = undefined;
                                } else {
                                    clearEmptyChildren(item.children);
                                }
                            } else {
                                item.children = undefined;
                            }
                        }
                    }

                    const children = sourceList[key];
                    if (Array.isArray(children) && children.length) {
                        clearEmptyChildren(children);
                        result.push({id: key, name: getters.sourceTypeMap[key], children});
                    } else {
                        result.push({id: key, name: getters.sourceTypeMap[key]});
                    }

                }
                return result;
            }, []);
        },
        modifiableListSourceChannels(state, getters) {
            if (getters.sourceChannels.length) {
                return getters.sourceChannels.filter(item => {
                    return !['ContractTransfer', 'ListTransfer', 'App'].includes(item.id);
                });
            }
            return [];
        },
        vipTags(state) {
            return getOrDefaultFormProfile(state, profile => {
                const result = [];
                if (Array.isArray(profile.vipTagGroup) && profile.vipTagGroup.length) {
                    for (let vipTagGroup of profile.vipTagGroup) {
                        if (Array.isArray(vipTagGroup.vipTags) && vipTagGroup.vipTags.length) {
                            for (let vipTag of vipTagGroup.vipTags) {
                                result.push({
                                    label: vipTag.name,
                                    value: vipTag.id,
                                    enableFlag: vipTag.enableFlag,
                                    color: vipTagGroup.color
                                });
                            }
                        }
                    }
                }
                return result;
            }, []);
        },
        serviceTypeList(state) {
            return getOrDefaultFormProfile(state, profile => {
                if (Array.isArray(profile.serviceTypes) && profile.serviceTypes.length) {
                    return profile.serviceTypes.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                }
            }, []);
        },
        listGiveUpReasonList(state) {
            return getOrDefaultFormProfile(state, profile => {
                if (Array.isArray(profile.listGiveUpReasons) && profile.listGiveUpReasons.length) {
                    return profile.listGiveUpReasons.map(item => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                }
            }, []);
        }
    },
    mutations: {
        updateStoreName(state, name) {
            state.storeName = name;
        },
        updateStoreChild(state, child) {
            state.storeChild = child;
        },
        updateDocumentClientHeight(state, height) {
            state.documentClientHeight = height;
        },
        updateNavbarLayoutType(state, type) {
            state.navbarLayoutType = type;
        },
        updateSidebarLayoutSkin(state, skin) {
            state.sidebarLayoutSkin = skin;
        },
        updateSidebarFold(state, fold) {
            state.sidebarFold = fold;
        },
        updateNavbarFold(state, fold) {
            state.navbarFold = fold;
        },
        updateMenuList(state, list) {
            state.menuList = list;
        },
        updateMenuActiveName(state, name) {
            state.menuActiveName = name;
        },
        updateContentIsNeedRefresh(state, status) {
            state.contentIsNeedRefresh = status;
        },
        updateMainTabs(state, tabs) {
            state.mainTabs = tabs;
        },
        updateMainTabsActiveName(state, name) {
            state.mainTabsActiveName = name;
        },
        addSourceChannel(state, sourceChannel) {
            //这里不校验状态,既然都调用编辑方法了肯定是已经初始化完成来源渠道了
            let sourceChannels = state._profile.sourceList[sourceChannel.sourceType];
            if (!Array.isArray(sourceChannels)) {
                state._profile.sourceList[sourceChannel.sourceType] = sourceChannels = [];
            }
            if (sourceChannel.level === 1) {
                sourceChannels.push(sourceChannel);
            } else {
                class MyError extends Error {
                }

                try {
                    function addChannelToChildren(childrenList) {
                        for (let sc of childrenList) {
                            if (sc.id === sourceChannel.parentId) {
                                let children = sc.children;
                                if (!Array.isArray(children)) {
                                    sc.children = children = [];
                                }
                                children.push(sourceChannel);
                                throw new MyError();
                            } else if (Array.isArray(sc.children) && sc.children.length) {
                                addChannelToChildren(sc.children);
                            }
                        }
                    }

                    addChannelToChildren(sourceChannels);
                } catch (e) {
                    if (!e instanceof MyError) {
                        throw e;
                    }
                }

            }
        },
        deleteSourceChannel(state, sourceChannel) {
            const sourceChannels = state._profile.sourceList[sourceChannel.sourceType];
            if (Array.isArray(sourceChannels) && sourceChannels.length) {
                if (sourceChannel.level === 1) {
                    for (let i = 0; i < sourceChannels.length; i++) {
                        if (sourceChannels[i].id === sourceChannel.id) {
                            sourceChannels.splice(i, 1);
                            if (!sourceChannels.length) {
                                state._profile.sourceList[sourceChannel.sourceType] = undefined;
                            }
                            break;
                        }
                    }
                } else {
                    class MyError extends Error {
                    }

                    try {
                        function deleteFromChildren(parent, childrenList) {
                            for (let i = 0; i < childrenList.length; i++) {
                                const children = childrenList[i];
                                if (children.level === sourceChannel.level) {
                                    if (children.id === sourceChannel.id) {
                                        childrenList.splice(i, 1);
                                        throw new MyError();
                                    }
                                } else if (Array.isArray(children.children) && children.children.length) {
                                    deleteFromChildren(children, children.children);
                                }
                            }
                        }

                        sourceChannels.forEach(item => {
                            if (Array.isArray(item.children) && item.children.length) {
                                deleteFromChildren(item, item.children);
                            }
                        });
                    } catch (e) {
                        if (!e instanceof MyError) {
                            throw e;
                        }
                    }

                }
            }

        },
        updateProfile(state, profile) {
            copyPropertyTo(state._profile, profile);
        }
    }
};

function getOrDefaultFormProfile(state, getterFun, defaultVal) {
    if (typeof state._profile === 'object') {
        return getterFun(state._profile);
    }
    if (state._profile === undefined) {
        state._profile = 'loading';
        getProfileApi().then(res => {
            state._profile = res.data.data;
        }).catch(() => state._profile = 'error');
    }
    return defaultVal;
}
