<template>
    <div class="main-view" v-loading="loading">
        <el-select class="student-select" v-model="selectedStudent" size="mini">
            <el-option label="全部学员" :value="undefined"></el-option>
            <el-option v-for="student in students"
                       :key="student.id"
                       :label="student.name"
                       :value="student.id">
            </el-option>
        </el-select>
        <ul class="status-list">
            <li>已签到</li>
            <li>排队</li>
            <li>已请假</li>
            <li>旷课</li>
        </ul>
        <ul class="class-list-container">
            <li :class="[
            'class-item',
            {'signed':['SignedIn','SignedInLateness'].includes(record.studentClassStatus)},
            {'lineup':record.studentClassStatus==='lineup'},
            {'leave':['LeaveOfAbsence','LeaveOfAbsenceNoConsume'].includes(record.studentClassStatus)},
            {'absenteeism':record.studentClassStatus==='Absenteeism'},
            ]"
                v-for="record in records"
                :key="record.id"
                @click="openClassDetailModal(record)">
                <div>{{ record.studentList[0].name }}</div>
                <div class="primary-text-color" style="font-size: 17px;">{{ record.className }}</div>
                <div>
                    <img class="class-item-icon" src="../../../assets/img/time2.png" alt="">
                    <span>{{ dayjs(record.classStartTime).format('YYYY-MM-DD') }}</span>
                    <span>{{ getWeekDayDisplay(record.classStartTime) }}</span>
                    <span>{{ dayjs(record.classStartTime).format('HH:mm') }}</span>
                </div>
                <div>
                    <img class="class-item-icon" src="../../../assets/img/address.png" alt="">
                    <span>{{ record.classrooms[0].name }}</span>
                    <img class="class-item-icon" src="../../../assets/img/user.png" alt="">
                    <span>{{ record.teachers[0].name }}</span>
                </div>
            </li>
        </ul>
        <div style="text-align: center;padding: 20px 0;" v-if="!records.length">暂无数据</div>
        <class-detail-modal :visible.sync="classDetailModalVisible" :class-id="classId"></class-detail-modal>
    </div>
</template>
<script>
import {defineComponent, onMounted, ref, toRefs, watch} from 'vue';
import {getListClassPageApi} from '../../../api/student/listApi';
import {getDefaultPage} from '../../../utils';
import dayjs from 'dayjs';
import {getWeekDayDisplay} from '../../../utils/dateUtils';
import ClassDetailModal from '../../Class/ClassDetailModal.vue';

export default defineComponent({
    components: {ClassDetailModal},
    props: {
        listId: Number,
        students: Array
    },
    setup(props) {

        const page = ref(getDefaultPage());

        const selectedStudent = ref();

        const loading = ref(false);

        const records = ref([]);

        const classDetailModalVisible = ref(false);

        const classId = ref();

        const refreshClassPage = () => {
            records.value = [];
            page.value = getDefaultPage();
            getListClassPage();
        };

        const getListClassPage = () => {
            loading.value = true;
            getListClassPageApi({
                listId: props.listId,
                studentId: selectedStudent.value,
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp,
            }).then(res => {
                if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                    for (let record of res.data.data.records) {
                        records.value.push(record);
                    }
                    page.value.currentPage = res.data.data.current;
                    page.value.pageSize = res.data.data.size;
                    page.value.total = res.data.data.total;
                }
            }).finally(() => loading.value = false);
        };

        const nextPage = () => {
            if (page.value.total <= records.value.length) {
                return;
            }
            page.value.currentPage++;
            getListClassPage();
        };

        const openClassDetailModal = (record) => {
            classDetailModalVisible.value = true;
            classId.value = record.classId;
        };

        onMounted(() => {
            refreshClassPage();
        });

        watch(selectedStudent, refreshClassPage);

        return {
            ...toRefs(props),
            selectedStudent,
            loading,
            records,
            classDetailModalVisible,
            classId,
            openClassDetailModal,
            getWeekDayDisplay,
            nextPage,
            dayjs
        };
    }
});
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variables";

.main-view {
    padding: 10px;
    position: relative;
}

.student-select {
    width: 120px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.status-list {
    display: flex;
    justify-content: center;
    padding: 10px 0 20px;
    gap: 30px;
}

.status-list > li {
    display: flex;
    gap: 12px;
    align-items: center;
}

.status-list > li::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.status-list > li:nth-child(1)::before {
    background-color: $--color-primary;
}

.status-list > li:nth-child(2)::before {
    background-color: #4fc3f7;
}

.status-list > li:nth-child(3)::before {
    background-color: #faad14;
}

.status-list > li:nth-child(4)::before {
    background-color: #f5222d;
}

.class-list-container {
    display: flex;
    gap: 20px;
}

.class-item {
    color: #292e33;
    cursor: pointer;
    width: 270px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    padding: 12px 8px 12px 20px;
    box-sizing: border-box;
    position: relative;
}

.class-item > div:not(:first-child) {
    margin-top: 10px;
}

.class-item::before {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 100%;
    left: 0;
    top: 0;
    background: #ccc;
}

.class-item.signed::before {
    background: $--color-primary;
}

.class-item.lineup::before {
    background: #4fc3f7;
}

.class-item.leave::before {
    background: #faad14;
}

.class-item.absenteeism::before {
    background: #f5222d;
}

.class-item-icon {
    width: 13px;
    height: 13px;
    vertical-align: middle;
}

.class-item-icon + * {
    vertical-align: middle;
}
</style>
