const classStatusTabs = [
    {
        name: '上课人数',
        code: '0',
        tooltip(studentClassList) {
            let yc = 0;
            let pc = 0;
            if (Array.isArray(studentClassList) && studentClassList.length) {
                studentClassList.filter(item => {
                    return ['NoClasses', 'SignedIn', 'Overdue', 'SignedInLateness'].includes(item.status);
                }).forEach(studentClass => {
                    if (studentClass.courseType === 'Reserve') {
                        yc++;
                    } else if (studentClass.courseType === 'Plan') {
                        pc++;
                    }
                });
            }
            return `<div style="line-height: 18px">约课人数: ${yc}</div>
             <div>排课人数: ${pc}</div>`;
        },
        show() {
            return true;
        },
        countDisplay(classInfo) {
            return `
                <span>${Array.isArray(classInfo.students) ? classInfo.students.filter(item => {
                return ['NoClasses', 'SignedIn', 'Overdue', 'SignedInLateness'].includes(item.status);
            }).length : 0}</span>
                <span>/</span>
                <span  style="color: #818c99">${classInfo.maxStudentCount}</span>`;

        },
        filterData(studentClassList) {
            return Array.isArray(studentClassList) ? studentClassList.filter(item => {
                return ['NoClasses', 'SignedIn', 'Overdue', 'SignedInLateness'].includes(item.status);
            }) : [];
        }
    },
    {
        name: '已签到',
        code: '2',
        tooltip(students) {
            let vip = 0;
            let testLister = 0;
            if (Array.isArray(students) && students.length) {
                students.forEach(student => {
                    if (student.studentType === 'List') {
                        testLister++;
                    } else if (student.studentType === 'VipStudent') {
                        vip++;
                    }
                });
            }
            return `<div style="line-height: 18px">会员人数: ${vip}</div>
             <div>试听人数: ${testLister}</div>`;
        },
        show() {
            return true;
        },
        countDisplay(classInfo) {
            let num = 0;
            if (classInfo.students) {
                classInfo.students.forEach(student => {
                    //SignedIn 已签到
                    if (student.status === 'SignedIn') {
                        num++;
                    }
                });
            }
            return num;
        },
        filterData(studentClassList) {
            return Array.isArray(studentClassList) ? studentClassList.filter(studentClass => studentClass.status === 'SignedIn') : [];
        }
    },
    {
        name: '未签到',
        code: '1',
        show(classInfo) {
            return !classInfo.isOver;
        },
        countDisplay(classInfo) {
            let num = 0;
            if (Array.isArray(classInfo.students) && classInfo.students.length) {
                classInfo.students.forEach(student => {
                    //Overdue 未签到
                    if (student.status === 'Overdue' || student.status === 'NoClasses') {
                        num++;
                    }
                });
            }
            return num;
        },
        filterData(studentClassList) {
            return Array.isArray(studentClassList) ? studentClassList.filter(studentClass => studentClass.status === 'Overdue' || studentClass.status === 'NoClasses') : [];
        }
    },
    {
        name: '旷课',
        code: '6',
        show(classInfo) {
            return classInfo.isOver;
        },
        countDisplay(classInfo) {
            let num = 0;
            if (classInfo.students) {
                classInfo.students.forEach(student => {
                    //Absenteeism 旷课
                    if (student.status === 'Absenteeism') {
                        num++;
                    }
                });
            }
            return num;
        },
        filterData(studentClassList) {
            return Array.isArray(studentClassList) ? studentClassList.filter(studentClass => studentClass.status === 'Absenteeism') : [];
        }
    },
    {
        name: '排队',
        code: '3',
        show() {
            return true;
        },
        countDisplay(classInfo) {
            let num = 0;
            if (classInfo.students) {
                classInfo.students.forEach(student => {
                    //LineUp 排队
                    if (student.status === 'LineUp') {
                        num++;
                    }
                });
            }
            return num;
        },
        filterData(studentClassList) {
            return Array.isArray(studentClassList) ? studentClassList.filter(studentClass => studentClass.status === 'LineUp') : [];
        }
    },
    {
        name: '请假',
        code: '4',
        show() {
            return true;
        },
        countDisplay(classInfo) {
            let num = 0;
            if (classInfo.students) {
                classInfo.students.forEach(student => {
                    //LeaveOfAbsence LeaveOfAbsenceNoConsume 请假
                    if (student.status === 'LeaveOfAbsence' || student.status === 'LeaveOfAbsenceNoConsume') {
                        num++;
                    }
                });
            }
            return num;
        },
        filterData(studentClassList) {
            return Array.isArray(studentClassList) ? studentClassList.filter(studentClass => studentClass.status === 'LeaveOfAbsence' || studentClass.status === 'LeaveOfAbsenceNoConsume') : [];
        }
    },
    {
        name: '取消',
        code: '5',
        show() {
            return true;
        },
        countDisplay(classInfo) {
            let num = 0;
            if (classInfo.students) {
                classInfo.students.forEach(student => {
                    //Canceled 取消
                    if (student.status === 'Canceled') {
                        num++;
                    }
                });
            }
            return num;
        },
        filterData(studentClassList) {
            return Array.isArray(studentClassList) ? studentClassList.filter(studentClass => studentClass.status === 'Canceled') : [];
        }
    },
];

export {classStatusTabs};
