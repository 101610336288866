<template>
<el-dialog width="30%"
           custom-modal
           title="名单更多信息"
           append-to-body
           :visible="visible"
           @update:visible="val=>$emit('update:visible',val)">
    <el-form label-position="top">
        <el-form-item label="联系人">
            <div style="display: flex;gap: 8px;" v-for="(contact,index) in data.contactsList"
                 :key="contact.id">
                <div>{{ contact.name }}（{{ $store.getters['common/relationMap'][contact.affiliation] }}）</div>
                <div>(+{{ contact.phoneArea }}){{ contact.phone }}</div>
                <div style="display: flex;gap: 5px;">
                    <span>微信昵称：</span>
                    <span style="color: #b4bfcc;">{{ contact.wechatName ? contact.wechatName : '无' }}</span>
                    <span style="color: red;" v-if="index===0">（主）</span>
                </div>
            </div>
        </el-form-item>
        <el-form-item label="家庭住址">
            {{ data.homeAddress ? data.homeAddress : '暂无' }}
        </el-form-item>
        <el-form-item label="所在小区">
            {{ data.distinguish ? data.distinguish : '暂无' }}
        </el-form-item>
    </el-form>
</el-dialog>
</template>
<script>
import {defineComponent, toRefs} from 'vue'

export default defineComponent({
    props: {
        visible: Boolean,
        data: {
            type: Object,
            default () {
                return {
                    contactsList: []
                }
            }
        }
    },
    emits: ['update:visible'],
    setup (props) {

        return {
            ...toRefs(props),
        }
    }
})
</script>
