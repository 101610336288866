<template>
    <div class="student-class-container">
        <div class="info p1 part">
            <div class="wxTip" v-if="studentClass.isWx">微信</div>
            <slot></slot>
            <list-student-avatar class="avatar"
                                 :list-id="studentClass.listId"
                                 :avatar="studentClass.avatar"
                                 :status="studentClass.status"
                                 @close="$emit('close')">
            </list-student-avatar>
            <div class="text">
                <div style="display: flex;gap: 8px;border: none;align-items: center;">
                    <span style="width: fit-content;font-size: 14px;">{{ studentClass.name }}</span>
                    <gender-view :gender="studentClass.gender" style="border: none;"></gender-view>
                </div>
                <p>{{ getBirthdayDisplay(studentClass.birthdayTime) }}</p>
                <div>{{ studentClass.isComment ? '已点评' : '未点评' }}</div>
                <img
                    src="../../../assets/img/wx.png"
                    alt=""
                    v-if="studentClass.isWx"
                    class="wx"/>
            </div>
        </div>
        <div class="linkMan p2 part">
            <p>
                <span>{{ studentClass.mainContactName }}</span>
                <span :style="'color:black;'">
                ({{
                        $store.getters['common/relationMap'][studentClass.mainContactAffiliation]
                    }})
            </span>
            </p>
            <span>
            (+{{ studentClass.mainContactPhoneNumberArea }})
            {{ studentClass.mainContactPhoneNumber }}
        </span>
        </div>
        <div class="counselor p3 part">
            <div>
                {{ studentClass.consultantName }}
            </div>
            <div style="width: 270px">
            </div>
        </div>
        <div class="p4 part">
            <student-class-option :status="studentClass.status"
                                  :student-class-id="studentClass.id"
                                  :class-begin-time="studentClass.classStartTime"
                                  :student-class="studentClass">
            </student-class-option>
        </div>

    </div>
</template>
<script>
import {defineComponent, toRefs} from 'vue';
import GenderView from '../../Common/GenderView.vue';
import StudentClassOption from './StudentClassOption.vue';
import {getBirthdayDisplay} from '../../../utils/studentUtils';
import ListStudentAvatar from './ListStudentAvatar.vue';

export default defineComponent({
    components: {
        ListStudentAvatar,
        StudentClassOption,
        GenderView,
    },
    props: {studentClass: Object},
    emits: ['close'],
    setup(props) {

        return {
            ...toRefs(props),
            getBirthdayDisplay
        };
    }

});
</script>
<style lang="scss" scoped>

.button-change-contract {
    cursor: pointer;
}

.button-change-contract:hover {
    text-decoration: none;
}

.student-class-container {
    display: flex;
    align-items: center;
    height: 90px;
    padding: 0 10px;
    box-sizing: border-box;
    background: #fff;
    position: relative;
    width: 100%;

    & + & {
        border-bottom: 1px solid #dedede;
    }

    .wxTip {
        width: 40px;
        height: 15px;
        background: #29cb97;
        color: #fff;
        font-size: 11px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 11px 0;
    }


    .linkMan {
        // width: 160px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        p {
            margin: 0;
        }

        span {
            color: #818c99;
            margin-top: 5px;
        }
    }

    .counselor {
        // width: 230px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        div {
            margin: 0;
        }

        span {
            color: #818c99;
            // margin-top: 5px;
        }
    }
}

.info {
    // width: 230px;
    display: flex;
    align-items: center;
    position: relative;

    > .avatar {
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
        margin-left: 20px;
    }

    .text {
        .sex,
        .wx {
            position: absolute;
            right: 80px;
            top: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }

        .sex {
            background: #fda6c9;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 147px;
            padding: 3px;
            width: 15px;
            height: 15px;
            box-sizing: border-box;
        }

        span {
            display: inline-block;
            color: #000;
            width: 60px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        p {
            color: #818c99;
            margin: 0;
            //margin: 5px 0;
            margin-bottom: 5px;
        }

        div {
            width: fit-content;
            padding: 2px 5px;
            box-sizing: border-box;
            border: 1px solid #dedede;
            border-radius: 15px;
            font-size: 12px;
            line-height: 1;
        }
    }
}


.p0 {
    width: 25px;
    padding-right: 0.5em;
    box-sizing: border-box;
}

.h100 {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.p1 {
    width: 30%;
}

.p1 .student {
    cursor: pointer;
    float: left;
    height: 100%;
    font-size: 0.9rem;
    text-align: center;
    color: #818c99;
    margin: 0 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}

.item .p1 .name {
    margin-left: 5.5rem;
}

.item .p1 .age {
    margin-left: 5.5rem;
}

.p2 {
    width: 20%;
}

.p3 {
    flex: 1 1 0%;
    box-flex: 1;
    -webkit-box-flex: 1;
    position: relative;
}

.p4 {
    min-width: 5em;
    cursor: pointer;
}

.part {
    vertical-align: middle;
    margin: 0;
}

.chocloud-icon {
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 18px;
    vertical-align: middle;
    color: #818c99;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
