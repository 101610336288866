<template>
<div>
    <el-dialog
        :title="receiveRefund?'收款':'退款'"
        :visible="visible"
        :update:visible="val=>$emit('update:visible',val)"
        width="400px"
        custom-class="money-operation-modal"
        append-to-body>
        <el-form ref="formRef" size="mini" label-position="top" :model="formData" :rules="rules">
            <el-form-item prop="payType" style="width: 45%;" label="支付方式">
                <el-select style="width: 100%;" v-model="formData.payType">
                    <el-option v-for="payMethod in $store.getters['common/payMethods']"
                               v-bind="payMethod"
                               :key="payMethod.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="金额" prop="paymentPrice">
                <el-input v-model="formData.paymentPrice"></el-input>
            </el-form-item>
            <el-form-item label="原因" prop="reason">
                <el-input v-model="formData.reason"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button @click="$emit('update:visible',false)" size="mini" class="ballet-modal-button">取 消</el-button>
            <el-button :loading="confirmLoading"
                       @click="handleConfirm"
                       type="primary"
                       size="mini"
                       class="ballet-modal-button">
                确定
            </el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import {defineComponent, inject, ref, toRefs} from 'vue'
import {paymentApi} from '../../../api/student/contractApi'

export default defineComponent({
    props: {visible: Boolean, receiveRefund: Boolean},
    emits: ['update:visible'],
    setup (props, {emit}) {

        const formRef = ref()

        const rules = ref({
            payType: [{required: true, message: '请选择支付方式'}],
            paymentPrice: [
                {required: true, message: '请输入金额'},
                {
                    pattern: /['1','2','3','4','5','6','7','9','0']+/,
                    message: '请输入正确的金额'
                },
                {
                    validator (rules, value, callback) {
                        if (value === '0') {
                            callback(new Error('金额不能为0'))
                        }
                        callback()
                    }
                }
            ],
            reason: [{required: true, message: '请输入原因'}],
        })

        const formData = ref({
            payType: undefined,
            paymentPrice: undefined,
            reason: undefined,
        })

        const confirmLoading = ref(false)

        const handleConfirm = () => {
            confirmLoading.value = true

            formRef.value.validate()
            .then(() => {
                let paymentPrice = parseFloat(formData.value.paymentPrice)
                if (!props.receiveRefund) {
                    paymentPrice = -paymentPrice
                }
                return paymentApi({...formData.value, paymentPrice, id: getData().id})
            })
            .then(() => {
                formRef.value.resetFields()
                emit('update:visible')
                refreshWater()
            })
            .finally(() => {
                confirmLoading.value = false
            })
        }

        const refreshWater = inject('refreshWater')

        const getData = inject('getData')

        return {
            ...toRefs(props),
            formRef,
            formData,
            confirmLoading,
            rules,
            handleConfirm
        }
    }
})
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">
.money-operation-modal {
    .el-dialog__body {
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeef5;
        border-top: 1px solid #ebeef5;
    }

    .el-dialog__footer {
        padding: 5px 20px;
        border-top: none;
    }
}
</style>
<style scoped>
>>> .el-form-item--mini.el-form-item {
    margin-bottom: 15px;
}

>>> .el-form-item--mini .el-form-item__label {
    line-height: 12px;
}

>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 !important;
    color: #3b3b3b !important;
}
</style>
