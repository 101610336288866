<template>
<div>
    <el-dialog
        class="asd"
        :modal="true"
        width="700px"
        :visible.sync="visible"
        @update:visible="val=>$emit('update:visible',val)"
        title="编辑会员"
        :show-close="false"
        custom-modal
        append-to-body
        :close-on-click-modal="false">
        <el-form ref="formRef" :model="formData" size="mini" label-width="0px" :rules="formRules">
            <div class="wrap_box">
                <div class="form_item">
                    <p class="require">会员名字</p>
                    <el-form-item style="padding:0;margin:0;width:100%;" label="" prop="name">
                        <el-input v-model="formData.name"

                                  show-word-limit
                                  :maxlength="100">
                        </el-input>
                    </el-form-item>
                </div>
                <div class="form_item item2">
                    <p>昵称</p>
                    <el-form-item style="padding:0;margin:0;width:100%;" label="" prop="nickName">
                        <el-input v-model="formData.nickName"

                                  show-word-limit
                                  :maxlength="100">
                        </el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="wrap_box">
                <div class="form_item">
                    <p class="require">生日</p>
                    <el-form-item style="padding:0;margin:0;width:100%;" label="" prop="birthdayTime">
                        <el-date-picker
                            v-model="formData.birthdayTime"
                            type="date"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :picker-options="pickerOptions"
                            style="width:100%;"
                            clearable
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div class="form_item item2">
                    <p class="require">性别</p>
                    <el-form-item style="padding:0;margin:0;width:100%;" label="" prop="gender">
                        <el-select style="width:100%;" v-model="formData.gender" placeholder="请选择">
                            <el-option label="男" :value="1"></el-option>
                            <el-option label="女" :value="2"></el-option>
                            <el-option label="未知" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="wrap_box">
                <div class="form_item">
                    <article style="margin-bottom: 5px">
                        <p class="require">联系人</p>
                        <img src="../../../assets/img/jiahao2.png" @click="addContact" alt="">
                    </article>
                    <div class="line">
                        <el-form-item>
                            <el-input disabled style="width:127px;" v-model="formData.mainContactName"
                                      placeholder="姓名"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-select disabled style="width:98px;"
                                       v-model="formData.mainContactAffiliation"
                                       placeholder="关系">
                                <el-option
                                    v-for="item in $store.state.common.relationList"
                                    v-bind="item"
                                    :key="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select disabled style="width:75px;padding:0;"
                                       v-model="formData.mainContactPhoneNumberArea">
                                <el-option v-for="phoneNumberArea in $store.getters['common/phoneNumberAreas']"
                                           v-bind="phoneNumberArea"
                                           :key="phoneNumberArea.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input disabled style="width:147px;"
                                      v-model="formData.mainContactPhoneNumber">
                                <template v-slot:prepend>
                                    <span>+{{ formData.mainContactPhoneNumberArea }}</span>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input disabled style="width:127px;"
                                      v-model="formData.mainContactWechatName"></el-input>
                        </el-form-item>
                        <span>(主)</span>
                        <img src="../../../assets/img/edit.png"
                             @click="openUpdateMainContractModal"
                             style="width:15px;height:15px;margin-left:10px;cursor:pointer;" alt="">
                    </div>

                </div>
            </div>
            <!-- 添加的副联系人 -->
            <div class="wrap_box" v-for="(contact,index) in formData.contactList" :key="contact.id">
                <div class="line">
                    <el-form-item>
                        <el-input style="width:127px;" v-model="contact.name"
                                  placeholder="姓名"></el-input>
                    </el-form-item>
                    <el-form-item :rules="contactAffiliationRule(index)" :prop="index+'1'">
                        <el-select style="width:98px;"
                                   v-model="contact.affiliation"
                                   placeholder="关系">
                            <el-option
                                v-for="item in $store.state.common.relationList"
                                v-bind="item"
                                :key="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :rules="contactPhoneAreaRule(index)" :prop="index+'2'">
                        <el-select style="width:75px;padding:0;"
                                   v-model="contact.phoneArea" placeholder="地区">
                            <el-option v-for="phoneNumberArea in $store.getters['common/phoneNumberAreas']"
                                       v-bind="phoneNumberArea"
                                       :key="phoneNumberArea.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        :rules="contactPhoneRule(index)"
                        :prop="index+'3'">
                        <el-input style="width:147px;"
                                  placeholder="电话" v-model="contact.phone">
                            <template v-slot:prepend>
                                <span>+{{ contact.phoneArea }}</span>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input style="width:127px;" v-model="formData.wechatName"
                                  placeholder="微信昵称"></el-input>
                    </el-form-item>
                    <span>(副)</span>
                    <img src="../../../assets/img/jianhao.png"
                         style="width:15px;height:15px;margin-left:10px;cursor:pointer;"
                         alt=""
                         @click="formData.contactList.splice(index,1)">
                </div>
            </div>
            <div class="wrap_box">
                <div class="form_item">
                    <p>家庭住址</p>
                    <el-form-item style="padding:0;margin:0;width:100%;" label="" prop="">
                        <el-input
                            v-model="formData.homeAddress"
                            placeholder=""
                            show-word-limit
                            :maxlength="250">
                        </el-input>
                    </el-form-item>
                </div>
                <div class="form_item item2">
                    <p>所在小区</p>
                    <el-form-item style="padding:0;margin:0;width:100%;" label="" prop="">
                        <el-input
                            v-model="formData.distinguish"
                            placeholder=""
                            show-word-limit
                            :maxlength="250">
                        </el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="wrap_box">
                <div class="form_item">
                    <p>就读年级</p>
                    <el-form-item style="padding:0;margin:0;width:100%;" label="" prop="">
                        <el-input
                            v-model="formData.grade"
                            placeholder=""
                            show-word-limit
                            :maxlength="100">
                        </el-input>
                    </el-form-item>
                </div>
                <div class="form_item item2">
                    <p>就读学校</p>
                    <el-form-item style="padding:0;margin:0;width:100%;" label="" prop="">
                        <el-input
                            v-model="formData.school"
                            placeholder=""
                            show-word-limit
                            :maxlength="100">
                        </el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="wrap_box">
                <div class="form_item">
                    <p>会员编码</p>
                    <el-form-item style="padding:0;margin:0;width:48.5%;" label="" prop="">
                        <el-input
                            v-model="formData.vipCode"
                            placeholder=""
                            show-word-limit
                            :maxlength="100">
                        </el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="del-buttons">
                <el-button class="under-line"
                           type="text"
                           @click="handleDeleteVipStudent">
                    删除此会员
                </el-button>
                <el-button class="under-line"
                           type="text"
                           @click="handleDeleteFamily">
                    删除此会员的家庭
                </el-button>
            </div>
        </el-form>
        <template v-slot:footer>
            <el-button @click="$emit('update:visible',false)" size="small">取 消</el-button>
            <el-button type="primary" @click="handleConfirm" size="small" :loading="confirmLoading">确 定</el-button>
        </template>
    </el-dialog>
    <el-dialog
        custom-modal
        width="450px"
        title="修改主家长信息"
        :visible.sync="updateMainContactModalVisible"
        append-to-body
        :modal="true">
        <el-form ref="mainContactFormRef" size="mini" :model="mainContactFormData" :rules="mainContactFormRules">
            <div class="wrap_box">
                <div class="line">
                    <el-form-item style="padding:0;margin:0;width:200px;" label="家长姓名" prop="">
                        <el-input v-model="mainContactFormData.name" placeholder="" show-word-limit
                                  :maxlength="100"></el-input>
                    </el-form-item>
                    <el-form-item label="关系">
                        <el-select style="width:100%;"

                                   v-model="mainContactFormData.affiliation"
                                   placeholder="关系">
                            <el-option
                                v-for="item in $store.state.common.relationList"
                                v-bind="item"
                                :key="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="wrap_box">
                <div class="form_item">
                    <p>新手机号</p>
                    <div class="line">
                        <el-form-item style="padding:0;margin:0;" label="" prop="">
                            <el-select style="width: 200px;margin-right:5px;"

                                       v-model="mainContactFormData.phoneNumberArea"
                                       placeholder="">
                                <el-option v-for="phoneNumberArea in $store.getters['common/phoneNumberAreas']"
                                           v-bind="phoneNumberArea"
                                           :key="phoneNumberArea.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="phoneNumber">
                            <el-input v-model="mainContactFormData.phoneNumber" placeholder="">
                                <template v-slot:prepend>
                                    <span>+{{ mainContactFormData.phoneNumberArea }}</span>
                                </template>
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="wrap_box">
                <div class="form_item">
                    <p>微信昵称</p>
                    <el-form-item style="padding:0;margin:0;width:100%;" label="" prop="">
                        <el-input v-model="mainContactFormData.wechatName" placeholder="" show-word-limit
                                  :maxlength="100"></el-input>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <template v-slot:footer>
            <el-button @click="updateMainContactModalVisible=false" size="small">取 消</el-button>
            <el-button type="primary"
                       @click="handleUpdateMainContract"
                       size="small"
                       :loading="updateMainContractLoading">
                确 定
            </el-button>
        </template>
    </el-dialog>
</div>
</template>
<script>

import {defineComponent, getCurrentInstance, h, nextTick, ref, toRefs, watch} from 'vue';
import {
    deleteFamilyApi,
    deleteVipStudentApi,
    updateMainContactApi,
    updateVipStudentApi
} from '../../../api/student/studentApi';
import {cloneObject} from '../../../utils/ObjectUtils';
import {regexp} from '../../../utils/const';

export default defineComponent({
    props: {
        visible: Boolean,
        data: {
            type: Object,
            default () {
                return {};
            }
        }
    },
    emits: ['update:visible', 'delete', 'updated'],
    setup (props, {emit}) {

        const currentInstance = getCurrentInstance();

        const defaultContactFormData = {
            name: undefined,
            nikeName: undefined,
            birthdayTime: undefined,
            gender: 3,
            homeAddress: undefined,
            distinguish: undefined,
            grade: undefined,
            school: undefined,
            vipCode: undefined
        };

        const formRef = ref();

        const mainContactFormRef = ref();

        const formData = ref(cloneObject(defaultContactFormData));

        const mainContactFormData = ref({});

        const formRules = ref({
            name: [{required: true, message: '请输入会员名字'}],
            nickName: [{required: false, message: '请输入昵称'}],
            birthdayTime: [{required: true, message: '请选择生日'}],
            gender: [{required: true, message: '请选择性别'}],
        });

        const mainContactFormRules = ref({
            phoneNumber: [
                {required: true, message: '手机号是必填的'},
                {pattern: regexp.phoneNumber, message: '错误的手机号'}
            ]
        });

        const updateMainContactModalVisible = ref(false);

        const confirmLoading = ref(false);

        const updateMainContractLoading = ref(false);

        const pickerOptions = {
            disabledDate (time) {
                return time.getTime() > Date.now();
            }
        };

        const deleteStudentMessageVNode = h('div', {
            style: {
                color: '#f5222d',
                fontWeight: 700
            }
        }, '删除数据无法恢复，且相关数据无法查询。请确认是否删除?');

        const deleteFamilyMessageVNode = h('div', {
            style: {
                color: '#f5222d',
                fontWeight: 700
            }
        }, '删除此会员的家庭，其他成员也将会删除，删除数据无法恢复，且相关数据无法查询。请确认是否删除?');

        const handleConfirm = () => {
            confirmLoading.value = true;
            formRef.value.validate().then(() => updateVipStudentApi({
                ...formData.value,
                contactList: formData.value.contactList ? formData.value.contactList.map(item => {
                    return {
                        name: item.name,
                        phoneNumberArea: item.phoneArea,
                        phoneNumber: item.phone,
                        affiliation: item.affiliation,
                        wechatName: item.wechatName
                    };
                }) : []
            })).then(() => {
                emit('update:visible', false);
                emit('updated');
            }).finally(() => confirmLoading.value = false);
        };

        const handleDeleteVipStudent = () => {
            currentInstance.proxy.$confirm(deleteStudentMessageVNode, {
                type: 'warning',
                showClose: false,
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        deleteVipStudentApi(props.data.id).then(() => {
                            setTimeout(() => {
                                emit('update:visible', false);
                                emit('delete');
                            });
                            done();
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                    }
                }
            });
        };

        const handleDeleteFamily = () => {
            currentInstance.proxy.$confirm(deleteFamilyMessageVNode, {
                type: 'warning',
                showClose: false,
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        deleteFamilyApi(props.data.listId).then(() => {
                            setTimeout(() => {
                                emit('update:visible', false);
                                emit('delete');
                            });
                            done();
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                    }
                }
            });
        };

        const addContact = () => {
            if (!Array.isArray(formData.value.contactList)) {
                formData.value.contactList = [];
            }
            formData.value.contactList.push({
                name: undefined,
                phoneArea: currentInstance.proxy.$store.getters['common/defaultPhoneNumberArea'],
                phone: undefined,
                affiliation: undefined,
                wechatName: undefined
            });
        };

        const contactAffiliationRule = (index) => {
            return [{
                validator (val, rule, callback) {
                    if (!formData.value.contactList[index].affiliation) {
                        return callback(new Error('请选择关系'));
                    }
                    callback();
                }
            }];
        };

        const contactPhoneAreaRule = (index) => {
            return [{
                validator (val, rule, callback) {
                    if (!formData.value.contactList[index].phoneArea) {
                        return callback(new Error('请选择手机区号'));
                    }
                    callback();
                }
            }];
        };

        const contactPhoneRule = (index) => {
            return [{
                validator (val, rule, callback) {
                    if (!formData.value.contactList[index].phone) {
                        return callback(new Error('手机号是必填的'));
                    }
                    if (!regexp.phoneNumber.test(formData.value.contactList[index].phone)) {
                        return callback(new Error('错误的手机号'));
                    }
                    callback();

                }
            }];
        };

        const openUpdateMainContractModal = () => {
            updateMainContactModalVisible.value = true;
            emit('update:visible', false);
            mainContactFormData.value = {
                studentId: formData.value.id,
                name: formData.value.mainContactName,
                phoneNumber: formData.value.mainContactPhoneNumber,
                phoneNumberArea: formData.value.mainContactPhoneNumberArea,
                wechatName: formData.value.mainContactWechatName,
                affiliation: formData.value.mainContactAffiliation
            };
        };

        const handleUpdateMainContract = () => {
            updateMainContractLoading.value = true;
            mainContactFormRef.value.validate().then(() => updateMainContactApi({...mainContactFormData.value})).then(() => {
                updateMainContactModalVisible.value = false;
                emit('updated');
            }).finally(() => updateMainContractLoading.value = false);
        };

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value = cloneObject(props.data);
                nextTick(() => {
                    formRef.value.clearValidate();
                });
            } else {
                formData.value = cloneObject(defaultContactFormData);
            }
        });

        watch(updateMainContactModalVisible, newVal => {
            if (!newVal) {
                mainContactFormRef.value.resetFields();
            }
        });

        return {
            ...toRefs(props),
            formRef,
            mainContactFormRef,
            formData,
            mainContactFormData,
            formRules,
            mainContactFormRules,
            updateMainContactModalVisible,
            confirmLoading,
            updateMainContractLoading,
            pickerOptions,
            handleConfirm,
            handleDeleteVipStudent,
            handleDeleteFamily,
            addContact,
            contactAffiliationRule,
            contactPhoneRule,
            contactPhoneAreaRule,
            openUpdateMainContractModal,
            handleUpdateMainContract,
            regexp
        };
    }
});
</script>
<style lang="scss" scoped>
.el-input-group {
    height: 100% !important;
}

.wrap_box {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.item2 {
    margin-left: 20px;
}

.form_item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
        margin: 10px 0 0;
    }

    p.require::after {
        content: '*';
        color: #f76169;
        margin-left: 1px;
    }

    article {
        display: flex;
        align-items: center;

        img {
            width: 15px;
            height: 15px;
            margin-left: 10px;
            cursor: pointer;
            position: relative;
            top: 5px;
        }
    }
}

.del-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
    margin-top: 20px;
}

</style>
<style scoped>
>>> .del-buttons button > span {
    font-size: 12px !important;
}

>>> .del-buttons button {
    color: #f5222d;
    padding: 0;
    margin: 0;
}

>>> .el-input-group__prepend {
    padding: 0 5px !important;
}

.line {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    align-items: center;
    margin-bottom: 10px;
}

>>> .line > .el-form-item {
    margin: 0 !important;
    padding: 0 !important;
}

>>> .el-form-item__content {
    line-height: 1;
}
</style>
