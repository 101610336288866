import http from '../../utils/httpRequest';

export function getPackagePageApi(params) {
    return http.get('/admin/balletpackage/page', {params});
}

export function getPackageApi(id) {
    return http.get(`/admin/balletpackage/info/${id}`);
}

export function addPackageApi(data) {
    return http.post(`/admin/balletpackage`, data);
}

export function updatePackageApi(data) {
    return http.put(`/admin/balletpackage`, data);
}

export function deletePackageApi(id) {
    return http.delete(`/admin/balletpackage/${id}`);
}