import Vue from "vue";

const defaultConfirmConfig = {
    type: 'warning',
    showClose: false,
    onOk: undefined
};

/**
 * 打开确认弹窗
 * @param message {string}
 * @param config {object&{onOK:function|?}|function}
 * @returns {Promise<?>}
 */
export function openConfirmModal(message, config = defaultConfirmConfig) {
    return new Promise((resolve, reject) => {
        let onOk;
        if (typeof config === 'function') {
            onOk = config;
            config = {};
        } else {
            onOk = config.onOk;
            delete config.onOk;
        }
        if (typeof onOk === 'function') {
            config.beforeClose = (action, instance, done) => {
                if (action === 'confirm') {
                    try {
                        const res = onOk();
                        if (res instanceof Promise) {
                            instance.confirmButtonLoading = true;
                            return res.then(res => {
                                done();
                                return res;
                            }).finally(() => instance.confirmButtonLoading = false);
                        } else {
                            done();
                            resolve();
                        }
                    } catch (e) {
                        done();
                        reject(e);
                    }
                } else {
                    done();
                    reject('cancel');
                }
            };
        }
        const promise = Vue.prototype.$confirm(message, {...defaultConfirmConfig, ...config});
        if (typeof onOk !== 'function') {
            promise.then(resolve).catch(reject);
        }
    });
}