<template>
<div>
    <el-dialog custom-modal
               title="合同的消耗记录"
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               width="1150px"
               custom-class="consume-dialog"
               :modal-append-to-body='false'
               append-to-body
               top="5vh">
        <el-table :cell-style="{color:'#525b66',fontSize: '13px'}"
                  v-loading="tableLoading"
                  :data="tableData">
            <el-table-column
                prop="recordTime"
                label="记录时间"
                width="150">
            </el-table-column>
            <el-table-column
                prop="ordinaryClassChange"
                label="普通课时变化"
                width="105">
            </el-table-column>
            <el-table-column
                prop="giveOrdinaryClassChange"
                label="赠送课时变化"
                width="105">
            </el-table-column>
            <el-table-column
                prop="specialClassChange"
                label="特殊课时变化"
                width="105">
            </el-table-column>
            <el-table-column
                prop="priceChange"
                label="金额变化"
                width="90">
            </el-table-column>
            <el-table-column
                prop="desc"
                label="描述"
                width="140">
                <template v-slot="{row}">
                    <div>
                        <div v-if="row.contractConsumptionType==='Effective'">合同生效</div>
                        <div v-else-if="row.contractConsumptionType==='AttendClass'">合同耗课</div>
                        <div v-else-if="row.contractConsumptionType==='Transfer'">转中心</div>
                        <div v-else-if="row.contractConsumptionType==='WeekRunningOut'">
                            <div>年卡自然周流逝</div>
                            <em @click="handleOpenWeekDetail(row)">[查看]</em>
                        </div>
                        <div v-else-if="row.contractConsumptionType==='ManualSpendClass'">手动耗课</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="classInfo"
                label="课节信息"
                width="220">
                <template v-slot="{row}">
                    <div v-if="row.classInfo">
                        <div>{{ row.classInfo.startTime }}</div>
                        <div>{{ row.classInfo.name }}</div>
                    </div>
                    <div v-else>-</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="studentInfo"
                label="学员信息"
                width="165">
                <template v-slot="{row}">
                    <div class="studentInfo" v-if="row.student">
                        <img v-if="row.student.avatar" :src="row.student.avatar" alt="">
                        <img v-else-if="row.student.sex===1"
                             src="https://lolita-api.oss-cn-shenzhen.aliyuncs.com/images/member_avatars/default_male.png"
                             alt="">
                        <img v-else
                             src="https://lolita-api.oss-cn-shenzhen.aliyuncs.com/images/member_avatars/default_female.png"
                             alt="">
                        <div>
                            <div>
                                <span style="color:#3b3b3b;">{{ row.student.name }}</span>
                                <gender-view :gender="row.student.sex"></gender-view>
                            </div>
                            <p>{{ getBirthdayDisplay(row.student.birthdayTime) }}</p>
                        </div>
                    </div>
                    <div v-else>-</div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: right;margin-top:20px;"
                       background
                       layout="total,prev, pager, next, sizes ,jumper"
                       v-bind="page"
                       :page-sizes="[10,20,50,100]"
                       @size-change="sizeChangeHandle"
                       @current-change="currentChangeHandle">
        </el-pagination>
        <elapse-detail v-bind="currentWeekAutoConsume" :visible.sync="elapseDetailModalVisible"></elapse-detail>
    </el-dialog>
</div>
</template>

<script>
import ElapseDetail from './ElapseDetail.vue'
import {getBirthdayDisplay} from '../../../utils/studentUtils'
import {computed, inject, ref, toRefs, watch} from 'vue'
import {getDefaultPage} from '../../../utils'
import {getUseRecordPageApi} from '../../../api/student/contractApi'
import GenderView from '../../Common/GenderView.vue'

export default {
    components: {
        GenderView,
        ElapseDetail
    },
    props: {visible: Boolean, contractId: Number},
    emits: ['update:visible'],
    setup (props, {emit}) {

        const elapseDetailModalVisible = ref(false)

        const page = ref(getDefaultPage())

        const tableLoading = ref(false)

        const tableData = ref([])

        const currentWeekAutoConsume = ref({})

        const refreshTableData = () => {
            page.value = getDefaultPage()
            getTableData()
        }

        const getTableData = () => {
            tableLoading.value = true
            getUseRecordPageApi({
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp,
                contractId: props.contractId
            }).then(res => {
                tableData.value = res.data.data.records
                page.value.total = res.data.data.total
            }).finally(() => tableLoading.value = false)
        }

        const sizeChangeHandle = (val) => {
            page.value.pageSize = val
            page.value.currentPage = 1
            refreshTableData()
        }
        const currentChangeHandle = (val) => {
            page.value.currentPage = val
            getTableData()
        }

        const handleOpenWeekDetail = (row) => {
            elapseDetailModalVisible.value = true
            currentWeekAutoConsume.value = {contractId: row.contractId, weekEndTime: row.recordTime}
        }

        watch(() => props.visible, newVal => {
            if (newVal) {
                refreshTableData()
            }
        }, {immediate: true})

        return {
            ...toRefs(props),
            elapseDetailModalVisible,
            page,
            tableLoading,
            tableData,
            currentWeekAutoConsume,
            sizeChangeHandle,
            currentChangeHandle,
            handleOpenWeekDetail,
            getBirthdayDisplay
        }
    }
}
</script>

<style lang="scss" scoped>
p {
    margin: 0;
}

em {
    color: #29cb97;
    font-style: normal;
    cursor: pointer;
}

.studentInfo {
    display: flex;
    align-items: center;

    > img {
        flex: 0 0 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        background: #04be02;
    }

    > div {
        position: relative;
        flex: 1;

        > img {
            width: 16px;
            height: 16px;
            position: absolute;
            right: 35px;
            transform: scale(.9);
            top: 2px;
            border-radius: 50%;
            padding: 3px;
            box-sizing: border-box;
        }

        img:nth-of-type(1) {
            background: #fda6c9;
        }

        img:nth-of-type(2) {
            background: #29cb97;
        }
    }
}
</style>

<style lang="scss">
</style>
