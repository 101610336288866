<template>
    <div class="act_warp">
        <div class="act_info_header">
            <p v-for="item in statusArr" :key="item.id"><span :style="'background:'+item.color+';'"></span>{{item.name}}</p>
        </div>
        <div class="noHave"><span>无更多数据</span></div>
    </div>
</template>

<script>
export default {
    name: 'BalletVueActivityInfo',

    data() {
        return {
            //状态数组
            statusArr:[
                {
                    id:1,
                    name:'排队中',
                    color:'#4fc3f7'
                },
                {
                    id:2,
                    name:'已签到',
                    color:'#29cb97'
                },
                {
                    id:3,
                    name:'已请假',
                    color:'#faad14'
                },
                {
                    id:4,
                    name:'旷课',
                    color:'#f5222d'
                }
            ],
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.act_warp{
    padding: 10px 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    //min-height: 402px;
    height: 400px;
    .act_info_header{
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            margin: 0;
            display: flex;
            align-items: center;
            span{
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 12px;
            }
        }
        p+p{
            margin-left: 55px;
        }
    }
    .noHave{
        text-align: center;
        margin-top: 30px;
        span{
            color: #818c99;
        }
    }
}
</style>
