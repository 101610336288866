<template>
    <div>
        <el-dialog
            title="上传照片"
            :visible.sync="visible"
            width="1100px"
            :before-close="closeUploadPhotoModal"
            append-to-body
            custom-class="uploadPhoto-dialog">
            <el-upload
                class="upload-demo"
                :action="baseUrl+'/file/upload'"
                :headers="{'Access-Token':localStorage.getItem('token')}"
                accept=".png,.jfif,.pjpeg,.jpeg,.pjp,.jpg,.bmp"
                list-type="picture-card"
                :multiple="false"
                :limit="9"
                :on-remove="handleRemove"
                :file-list="fileList"
                :on-success="handleAvatarSuccess">
                <i class="el-icon-plus"></i>
                <div slot="tip" style="color:#f00;margin:20px 0 0 0;">注： 一次最多上传9张图片, 超过9张请分批上传</div>
            </el-upload>
            <div slot="footer">
                <el-button @click="closeUploadPhotoModal">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import http from "../../utils/httpRequest";
import {uploadFileToClassPhotoLibraryApi} from "../../api/class/classPhoto";

export default {
    name: 'BalletVueUploadPhoto',
    props: ['visible', 'closeUploadPhotoModal','classId'],
    emits:['uploadSuccess'],
    data() {
        return {
            fileList:[],
            baseUrl: http.defaults.baseURL
        };
    },

    mounted() {

    },

    methods: {
        handleAvatarSuccess(response, file, fileList) {
            if (response.code == 200) {
                this.fileList.push({url: response.data});
                console.log(response, 'res')
                console.log(this.fileList, 'fileList')
            }
        },
        handleRemove(a,b,c){
            let {uid} = a;
            this.fileList = this.fileList.filter(item => item.uid != uid);
        },
        submit(){
            uploadFileToClassPhotoLibraryApi({
                classId: this.classId,
                images: this.fileList.map(a=>a.url)
            }).then(res=>{
                console.log(res.data,'上传照片');
                this.$emit('uploadSuccess',null);
                this.closeUploadPhotoModal();
            });
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">
.uploadPhoto-dialog{
    .el-dialog__body{
        border: 1px solid #e5e5e5;
        border-left: none;
        border-right: none;
    }
}
</style>
