import http from '../../utils/httpRequest';

export function getDayClassListForStudentApi(params) {
    return http.get('/admin/balletclass/listByStudent', {params});
}

export function batchSignInApi(ids) {
    return http.put('/admin/studentclass/batchSign', ids);
}

export function reserveApi(data) {
    return http.post('/admin/balletclass/reserve', data);
}

export function getClassListApi(params) {
    return http.get('/admin/balletclass/simplePage', {params});
}

export function getClassOperationRecordListApi(params) {
    return http.get('/admin/balletclass/operationPage', {params});
}

export function getClassFatherOperationRecordListApi(params) {
    return http.get('/admin/balletclass/fatherOperationPage', {params});
}

export function getClassChildrenOperationRecordListApi(params) {
    return http.get('/admin/balletclass/childrenOperationPage', {params});
}

export function getClassRevisionOperationPageRecordListApi(params) {
    return http.get('/admin/balletclass/revisionOperationPage', {params});
}

export function changStudentClassStatusApi(data) {
    return http.post('/admin/studentclass/changeStatus', data);
}

export function getClassDataListApi(params) {
    return http.get('/admin/balletclass/list', {params});
}

export function getStudentClassOperationRecordPageApi(params) {
    return http.get('/admin/studentclass/operationRecordPage', {params});
}

export function getClassDetailApi(id) {
    return http.get(`/admin/balletclass/${id}`);
}

export function getPrePlanClassInfoApi(params) {
    return http.get('/admin/balletclass/getPrePlanClassInfo', {params});
}

export function getClassFatherApi(classId) {
    return http.get(`/admin/balletclass/classFather/${classId}`);
}

export function getClassChildrenListApi(params) {
    return http.get(`/admin/balletclass/classChildrenList`, {params});
}

export function planClassApi(data) {
    return http.post('/admin/balletclass/planClass', data);
}

export function changeContractApi(data) {
    return http.put('/admin/studentclass/changeContract', data);
}

export function updateClassApi(data) {
    return http.put('/admin/balletclass', data);
}

export function deleteClassApi(classId) {
    return http.delete(`/admin/balletclass/${classId}`);
}


export function deleteClassParentApi(classFatherId) {
    return http.delete(`admin/balletclass/parent/${classFatherId}`);
}

export function stopClassApi(data) {
    return http.put('/admin/balletclass/stop', data);
}

export function classRescheduleApi(data) {
    return http.put('/admin/balletclass/reschedule', data);
}

export function copyClassApi(data) {
    return http.post('/admin/balletclass/copy', data);
}

export function validClassRescheduleApi(data) {
    return http.post('/admin/balletclass/validClassReschedule', data);
}

export function exportClassListApi(params, fileName) {
    return http.download.get('/admin/balletclass/exportExcel', {params}, fileName);
}

export function exportClassSignApi(params) {
    return http.download.get('/admin/balletclass/exportSignExcel', {params}, '课程签到表.xlsx');
}

export function exportClassSignByIdApi(classId) {
    return http.download.get('/admin/balletclass/exportSignExcelById', {params: {classId}}, '课程签到表.xlsx');
}

export function lockTotalClassApi(params) {
    return http.put('/admin/balletclass/lockTotal', null, {params});
}

export function updateTotalCapacityApi(data) {
    return http.put('/admin/balletclass/updateTotalCapacity', data);
}

export function updateTotalClassApi(data) {
    return http.put('/admin/balletclass/total', data);
}

export function addChildrenClassApi(data) {
    return http.post("/admin/balletclass/children", data);
}

export function updateChildClassApi(data) {
    return http.put("/admin/balletclass/children", data);
}

export function lockClassApi (params) {
    return http.put('/admin/balletclass/lock', null,{params})
}
