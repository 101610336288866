<template>
    <article>
        <el-dialog
            :title="title"
            :visible="visible"
            @update:visible="val=>$emit('update:visible',val)"
            width="830px"
            custom-modal
            append-to-body>
            <div class="box_wrap">
                <div class="header">
                    <h2>发布选项</h2>
                </div>
                <div class="content">
                    <div class="tags">
                        <p>动态标签:</p>
                        <div>
                            <span :class="tabCheck===-1?'on':''" @click="changeTabCheck(-1)">通知</span>
                            <span :class="tabCheck===-2?'on':''" @click="changeTabCheck(-2)">动态</span>
                            <span :class="tabCheck===-3?'on':''" @click="changeTabCheck(-3)">评价</span>
                            <span v-for="it in tagsList2"
                                  :class="tabCheck===it.id?'on':''"
                                  @click="changeTabCheck(it.id)">
                                {{ it.name }}
                            </span>
                            <p @click="openTagVisible">标签管理</p>
                        </div>
                    </div>
                    <div class="toObj">
                        <p>发送对象</p>
                        <div>
                            <em v-for="it in students" :key="it.id">{{ it.name }}</em>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box_wrap">
                <div class="header">
                    <h2>发布内容</h2>
                </div>
                <div class="content">
                    <el-input
                        type="textarea"
                        :rows="4"
                        placeholder="输入正文内容"
                        v-model="textContent">
                    </el-input>
                </div>
            </div>
            <div class="box_wrap">
                <div class="header">
                    <h2>图片/视频 (0/9)</h2>
                </div>
                <div class="content">
                    <div class="moveUpload" @click="alertMsg($event)">
                        <el-upload
                            action="https://jsonplaceholder.typicode.com/posts/"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                            accept=".ogm,.wmv,.mpg,.webm,.ogv,.mov,.asx,.mpeg,.mp4,.m4v,.avi"
                            style="position:relative;opacity:1;"
                            disabled>
                            <i class="el-icon-plus"></i>
                            <span slot="tip" style="position:absolute;left:6.1%;bottom:30px;">添加视频</span>
                        </el-upload>
                    </div>
                    <div class="picUpload">
                        <el-popover
                            v-model="winOpen"
                            placement="top-start"
                            trigger="manual"
                            :visible-arrow="true"
                            style="padding:0;"
                            popper-class="sendMsg-popover"
                            :disabled="photographListVisible">
                            <div class="modal">
                                <div class="modal_header">
                                    <p>请选择</p>
                                </div>
                                <div class="modal_down">
                                    <el-upload
                                        class="upload-demo"
                                        :action="baseUrl+'/file/upload'"
                                        :headers="{'Access-Token':localStorage.getItem('token')}"
                                        accept=".png,.jfif,.pjpeg,.jpeg,.pjp,.jpg,.bmp"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess"
                                        :multiple="true">
                                        <el-button size="small" type="primary"
                                                   style="background:#29cb97;width:150px;height:30px;margin:15px auto;">
                                            本地上传
                                        </el-button>
                                    </el-upload>
                                    <el-button size="small" style="width:150px;height:30px;margin:0px auto;"
                                               @click="openPhotoLibrary()">会员照片库
                                    </el-button>
                                </div>
                            </div>
                            <div style="display: flex;align-items: center;flex-wrap: wrap" slot="reference">
                                <img style="width: 150px;height: 150px;margin-right: 15px" v-for="it in picUrl"
                                     :key="it.url" :src="it.url" alt="" srcset="">
                                <div class="addBox" @click="showProWin">
                                    <span></span>
                                    <span></span>
                                    <p>添加图片</p>
                                </div>
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>
            <el-dialog
                width="30%"
                title="动态标签管理"
                :visible.sync="tabVisible"
                append-to-body
                custom-class="send-dialog"
                top="20vh">
                <div style="display:flex;justify-content:flex-end;">
                    <el-button
                        style="width:90px;height:20px;display:flex;justify-content:center;align-items:center;color:#29cb97;"
                        @click="openAddOrEditTag('',1)">+新建标签
                    </el-button>
                </div>
                <dl>
                    <dt>
                        <span>标签名称</span>
                        <span>操作</span>
                    </dt>
                    <template v-if="currentPage===1">
                        <dd>
                            <p>通知</p>
                        </dd>
                        <dd>
                            <p>动态</p>
                        </dd>
                        <dd>
                            <p>评价</p>
                        </dd>
                    </template>
                    <dd v-for="item in tagsList" :key="item.id">
                        <p>{{ item.name }}</p>
                        <article>
                            <span @click="deleteTag(item)">删除</span>
                            <span>停用</span>
                            <span @click="openAddOrEditTag(item,2)">编辑</span>
                        </article>
                    </dd>
                </dl>
                <footer style="text-align:right;margin-top:10px;">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="total"
                        @current-change="changePage">
                    </el-pagination>
                </footer>
                <el-dialog
                    width="400px"
                    :title="tagType===1?'新增':'编辑'"
                    :visible.sync="addOrEditDialogVisible"
                    append-to-body
                    custom-modal
                    top="30vh">
                    <el-input v-model="newContent" placeholder="请输入内容" size="mini"></el-input>
                    <span slot="footer"
                          style="width:100%;height:100%;display:flex;align-items:center;justify-content:flex-end;">
                <el-button
                    style="margin:0px 0 0 0;width:96px;height:30px;display:flex;align-items:center;justify-content:center;"
                    @click="closeAddOrEditTag">取消</el-button>
                <el-button type="primary" @click="changeTagName"
                           style="margin:0px 0 0 10px;width:96px;height:30px;background:#2fcc9a;display:flex;align-items:center;justify-content:center;">确认</el-button>
            </span>
                </el-dialog>
            </el-dialog>
            <span slot="footer"
                  style="width:100%;height:35px;display:flex;align-items:center;justify-content:flex-end;">
            <el-button
                style="margin:0px 0 0 0;width:96px;height:30px;display:flex;align-items:center;justify-content:center;"
                @click="$emit('update:visible',false)">取消</el-button>
            <el-button type="primary"
                       @click="handleConfirm"
                       style="margin:0px 0 0 10px;width:96px;height:30px;background:#2fcc9a;display:flex;align-items:center;justify-content:center;">
                确认
            </el-button>
        </span>
        </el-dialog>
        <photo-library :visible="photographListVisible" :closePhotoLibrary="closePhotoLibrary"></photo-library>
    </article>
</template>

<script>
import PhotoLibrary from '../PhotoLibrary/PhotoLibrary.vue';
import {
    createMsgTagApi,
    deleteMsgTagApi,
    getMsgTagListApi,
    sendAfterEvaluateApi,
    sendDynamicStateApi,
} from "@/api/motion/sendMsg";
import http from "../../utils/httpRequest";

export default {
    components: {
        PhotoLibrary,
    },
    props: {
        visible: Boolean,
        closeSendMsgModal: Function,
        title: String,
        type: Number,
        students: Array
    },
    emits: ['update:visible', 'ok'],
    mounted() {
        if (this.type === 1) {
            this.tabCheck = -3;
        } else if (this.type === 2) {
            this.tabCheck = -2;
        } else {
            this.tabCheck = -1;
        }
    },
    data() {
        return {
            //标签选择
            tabCheck: -1,
            //正文内容
            textContent: '',
            //上传的视频地址
            moveUrl: '',
            //上传的图片地址
            picUrl: [],
            //上传图片弹出层的显隐
            winOpen: false,
            //会员照片库弹框显隐
            photographListVisible: false,
            //动态标签管理弹窗显隐
            tabVisible: false,
            tagsList: [],
            //外层显示
            tagsList2: [],
            //新增或者编辑标签显隐
            addOrEditDialogVisible: false,
            //点击编辑的id
            ids: '',
            //新增标签还是编辑标签 1新增 2编辑
            tagType: '',
            //新标签内容
            newContent: '',
            currentPage: 1,
            pageSize: 10,
            total: 0,
            currentPage2: 1,
            pageSize2: 100,
            confirmLoading: false,
            baseUrl: http.defaults.baseURL
        };
    },
    watch: {
        visible(val) {
            if (val) {
                this.getTagLists();
                this.getTagLists2();
            }
        }
    },
    methods: {
        http,
        //修改选中的标签
        changeTabCheck(val) {
            this.tabCheck = val;
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.moveUrl = file.url;
        },
        handleRemove2(file, fileList) {
            console.log(file, fileList);
        },
        handleAvatarSuccess(response, file, fileList) {
            this.picUrl.push({url: response.data});
            this.closePropWin();
        },
        //打开上传图片弹出层函数
        showProWin(e) {
            e.stopPropagation();
            this.winOpen = true;
            document.body.addEventListener('click', this.closePropWin, false);
        },
        //关闭上传图片弹出层函数
        closePropWin() {
            this.winOpen = false;
            document.body.removeEventListener('click', this.closePropWin, false);
        },
        //关闭会员照片库弹出层函数
        closePhotoLibrary() {
            this.photographListVisible = false;
        },
        //打开会员照片库弹出层函数
        openPhotoLibrary() {
            this.winOpen = false;
            this.photographListVisible = true;
        },
        //关闭动态标签管理弹窗函数
        closeTagVisible() {
            this.tabVisible = false;
        },
        //打开动态标签管理弹窗函数
        openTagVisible() {
            this.tabVisible = true;
        },
        //打开新增或者编辑标签弹窗
        openAddOrEditTag(item, type) {
            this.ids = '';
            this.ids = item.id;
            this.addOrEditDialogVisible = true;
            this.tagType = type;
            this.newContent = item.id ? item.name : '';
        },
        //关闭新增或者编辑标签弹窗
        closeAddOrEditTag() {
            this.ids = '';
            this.addOrEditDialogVisible = false;
            this.getTagLists();
            this.getTagLists2();
        },
        //获取标签列表
        getTagLists() {
            getMsgTagListApi({
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                timestamp: new Date().getTime()
            }).then(res => {
                this.tagsList = res.data.data.records;
                this.total = res.data.data.total;
            });
        },
        //获取标签列表
        getTagLists2() {
            getMsgTagListApi({
                currentPage: this.currentPage2,
                pageSize: this.pageSize2,
                timestamp: new Date().getTime()
            }).then(res => {
                this.tagsList2 = res.data.data.records;
            });
        },
        changeTagName() {
            createMsgTagApi({
                    name: this.newContent,
                    id: this.ids,
                },
                this.ids ? 'put' : 'post'
            ).then(res => {
                this.addOrEditDialogVisible = false;
            }).finally(() => {
                this.getTagLists();
                this.getTagLists2();
                this.newContent = '';
            });
        },
        deleteTag(item) {
            this.$confirm('此操作将永久删除该标签, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    instance.confirmButtonLoading = true;
                    if (action === 'confirm') {
                        deleteMsgTagApi(item.id).then(res => {
                            console.log(res.data, '删除标签');
                        }).finally(() => {
                            this.getTagLists();
                            this.getTagLists2();
                            done();
                        }).finally(() => {
                            instance.confirmButtonLoading = false;
                        });
                    } else {
                        done();
                    }
                }
            });
        },
        changePage(val) {
            this.currentPage = val;
            this.getTagLists();
            this.getTagLists2();
        },
        handleConfirm() {
            // //缺少视频，视频未做
            for (const key in this.picUrl) {
                this.picUrl[key].type = 'Image';
            }
            let motionMedias = [];
            for (const key in this.picUrl) {
                const media = {
                    type: "Image",
                    mediaUrls: [this.picUrl[key].url],
                };
                motionMedias.push(media);
            }
            const studentIds = this.students.map(student => student.studentId ? student.studentId : student.id);
            if (this.type === 1) {
                this.confirmLoading = true;
                sendAfterEvaluateApi({
                    content: this.textContent,
                    motionMedias,
                    tagId: this.tabCheck,
                    studentIds,
                    classId: this.students[0].classId
                }).then(() => {
                    this.$emit('update:visible', false);
                    this.$emit('ok');
                });
            } else if (this.type === 2) {
                this.confirmLoading = true;
                //todo 发布动态是否发布全部学员待处理 toAll默认false
                sendDynamicStateApi({
                    content: this.textContent,
                    motionMedias,
                    tagId: this.tabCheck,
                    studentIds,
                    toAll: false,
                }).then(() => {
                    this.$emit('update:visible', false);
                    this.$emit('ok');
                }).finally(() => this.confirmLoading = false);
            }
        },
        alertMsg(e) {
            this.$message.warning('暂不支持该功能');
            e.preventDefault();
        },
    },
};
</script>

<style lang="scss" scoped>
.box_wrap + .box_wrap {
    margin-top: 25px;
}

.box_wrap {
    border: 1px solid #ccc;

    .header {
        padding: 10px 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;

        h2 {
            font-size: 16px;
            margin: 0;
            font-weight: 600;
            font-family: 'Microsoft YaHei';
        }
    }

    .content {
        padding: 20px;
        box-sizing: border-box;
    }
}

.tags {
    display: flex;
    align-items: baseline;

    > p {
        white-space: nowrap;
    }

    > div {
        margin-left: 10px;

        span {
            color: #b4bfcc;
            padding: 0 8px;
            box-sizing: border-box;
            border: 1px solid transparent;
            border-radius: 10px;
            cursor: pointer;
        }

        span + span {
            margin-left: 10px;
        }

        span::before {
            content: '#';
            margin-right: 5px;
        }

        span.on {
            font-weight: 600;
            border: 1px solid #29cb97;
            color: #000;
        }

        span.on::before {
            color: #29cb97;
        }

        p {
            display: inline;
            color: #29cb97;
            margin: 0;
            margin-left: 20px;
            cursor: pointer;
        }
    }
}

.toObj {
    display: flex;

    p {
        margin: 0;
    }

    div {
        display: flex;

        em {
            font-style: normal;
            color: #29cb97;
            margin-left: 10px;
        }
    }
}

.picUpload {
    margin-top: 35px;
}

.addBox:hover {
    border: 1px dashed #29cb97;
}

.addBox {
    width: 145px;
    height: 145px;
    border-radius: 5px;
    border: 1px dashed #ccc;
    background: #fbfdff;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        // position: absolute;
        width: 3px;
        height: 25px;
        background: #8c939d;
        left: 50%;
        top: 50%;
        // transform: translate(-50%);
    }

    span:nth-of-type(2) {
        transform: translateX(-3px) rotate(90deg);
    }

    p {
        position: absolute;
        left: 50%;
        bottom: 25px;
        margin: 0;
        transform: translate(-50%);
    }
}

.modal {
    width: 230px;
    height: 155px;
    border-radius: 5px;
    background: #fff;
    margin: 0;

    .modal_header {
        width: 100%;
        height: 35px;
        border-bottom: 1px solid #ccc;
        margin: 0;
        padding-left: 15px;
        box-sizing: border-box;

        p {
            margin: 0;
            line-height: 35px;
            font-size: 12px;
            color: #525b66;
        }
    }

    .modal_down {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

dl {
    width: 100%;
    margin: 0;
    margin-top: 10px;

    dt {
        height: 25px;
        background: #f7f7f7;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            font-size: 12px;
            color: #b4bfcc;
            padding: 0 10px;
            box-sizing: border-box;
        }
    }

    dd {
        height: 25px;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
        margin: 0;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
            font-size: 14px;
            color: #818c99;
        }

        article {
            span {
                font-size: 14px;
                color: #818c99;
                cursor: pointer;
            }

            span:nth-of-type(2)::after {
                content: '|';
                position: relative;
                top: -1px;
                margin: 0 2px;
                left: 2px;
            }

            span:nth-of-type(2)::before {
                content: '|';
                position: relative;
                top: -1px;
                margin: 0 2px;
                right: 2px;
            }

            span:nth-of-type(3) {
                color: #29cb97;
            }
        }
    }
}
</style>

<style lang="scss">
.sendMsg-popover {
    padding: 0;
    box-sizing: border-box;
    box-shadow: none;
    border: none;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
}
</style>
