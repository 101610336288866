export function formatDefaultTemplate (template, fun, ...values) {
    return formatTemplate(template, '{}', fun, ...values)
}

export function formatTemplate (template, topic, fun, ...values) {
    if (!topic || topic.length < 2) {
        throw new Error('通配符至少两个字符')
    }
    if (template) {
        if (values.length) {
            let formatter = null
            if (typeof fun === 'function') {
                formatter = fun
            } else {
                values = fun
            }

            if (values.length === 1 && Array.isArray(values[0])) {
                values = values[0]
            }

            let result = template
            let start = 0
            for (const value of values) {
                if (start >= result.length) {
                    break
                } else {
                    let indexOf = result.slice(start).indexOf(topic)
                    if (indexOf === -1) {
                        break
                    } else {
                        indexOf = indexOf + start
                        start = indexOf + topic.length
                        let val = value
                        if (formatter) {
                            val = formatter(value)
                            if (!val) {
                                val = ''
                            }
                        }
                        result = result.slice(0, indexOf) + val + (start >= result.length ? '' : result.slice(indexOf + topic.length))
                    }
                }
            }
            let ignoreStr = topic.split('').join('\\')
            if (template.indexOf(ignoreStr) !== -1) {
                template.repalce(new RegExp(ignoreStr, 'g'), topic)
            }
            return result
        }
    }
    return template
}

