<template>
<el-dialog title="关联名单/会员"
           width="600px"
           custom-modal
           :visible="visible"
           :close-on-click-modal="false"
           @update:visible="val=>$emit('update:visible',val)"
           append-to-body>
    <div>
        <div class="info-title">接收人信息</div>
        <div class="info-wrapper">
            <div class="info-item">
                <div>姓名</div>
                <div>{{ data.receiverName }}</div>
            </div>
            <div class="info-item">
                <div>手机</div>
                <div>{{ `(+${data.receiverPhoneNumberArea})${data.receiverPhoneNumber}` }}</div>
            </div>
        </div>
        <search-input v-model="searchKey"
                      size="mini"
                      style="margin-bottom: 20px;"
                      placeholder="请输入手机号"
                      icon
                      @search="refreshPage">
        </search-input>
        <el-radio-group v-model="select" style="width: 100%;">
            <el-table :data="records"
                      :header-cell-style="{backgroundColor:'#f7f7f7'}"
                      :cell-style="{fontSize:'12px'}"
                      v-loading="loading">
                <el-table-column label="" width="50">
                    <template v-slot="{row}">
                        <el-radio :label="row.id" :value="row.id">
                            <div></div>
                        </el-radio>
                    </template>
                </el-table-column>
                <el-table-column label="联系人">
                    <template v-slot="{row}">
                        <div v-for="contact in row.contactsList"
                             :key="contact.id"
                             style="display: flex;gap: 5px;flex-wrap: wrap;align-items: center;">
                            <span>{{ contact.name }}</span>
                            <span>(+{{ contact.phoneArea }})</span>
                            <span>{{ contact.phone }}</span>
                            <span v-if="contact.isHost===0" style="color: #818c99;">(主)</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="学员信息">
                    <template v-slot="{row}">
                        <div v-for="student in row.students"
                             :key="student.id"
                             style="display: flex;gap: 5px;flex-wrap: wrap;align-items: center;">
                            <span>{{ student.name }}</span>
                            <gender-view :gender="student.sex"></gender-view>
                            <div class="primary-bg-color"
                                 v-if="row.followUpPhase==='Contracted'"
                                 style="display: inline-flex;width: 16px;height: 16px;border-radius: 50%;color: #ffffff;align-items: center;justify-content: center;">
                                <span style="transform: rotateZ(20deg) rotateY(20deg);font-size: 15px;font-weight: 600;margin-top: -2px;">v</span>
                            </div>
                            <span>{{ getBirthdayDisplay(student.birthdayTime, false) }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-radio-group>
    </div>
    <template v-slot:footer>
        <el-button @click="$emit('update:visible',false)">取消</el-button>
        <el-tooltip content="请先选择会员/名单" :disabled="!!select">
            <el-button type="primary"
                       :loading="confirmLoading"
                       @click="handleConfirm"
                       :disabled="!select">
                关联
            </el-button>
        </el-tooltip>

    </template>
</el-dialog>
</template>
<script>
import {computed, defineComponent, inject, ref, toRefs, watch} from 'vue'
import {getDefaultPage} from '@/utils'
import {getListPageApi} from '@/api/student/listApi'
import GenderView from '@/components/Common/GenderView.vue'
import {getBirthdayDisplay} from '../../../utils/studentUtils'
import {bindListForReceiveApi} from '@/api/student/contractApi'
import SearchInput from '@/components/Form/SearchInput.vue'

export default defineComponent({
    methods: {},
    components: {SearchInput, GenderView},
    props: {
        visible: Boolean,
        data: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    emits: ['update:visible', 'ok'],
    setup (props, {emit}) {

        const searchKey = ref()

        const page = ref(getDefaultPage())

        const records = ref([])

        const select = ref()

        const loading = ref(false)

        const confirmLoading = ref(false)

        const refreshPage = () => {
            records.value = []
            page.value = getDefaultPage()
            getPage()
        }

        const getPage = () => {
            getListPageApi({
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp,
                keyword: searchKey.value
            }).then(res => {
                if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                    records.value = res.data.data.records
                    if (res.data.data.records.length === 1) {
                        select.value = res.data.data.records[0].id
                    }
                }
                page.value.currentPage = res.data.current
                page.value.pageSize = res.data.size
                page.value.total = res.data.total
            })
        }

        const handleConfirm = () => {
            confirmLoading.value = true
            bindListForReceiveApi({contractId: contract.value.id, listId: select.value}).then(() => {
                emit('update:visible', false)
                emit('ok', false)
            }).finally(() => confirmLoading.value = false)
        }

        const getData = inject('getData')

        const contract = computed(() => getData())

        watch(() => props.visible, newVal => {
            if (newVal) {
                searchKey.value = props.data.receiverPhoneNumber
                select.value = undefined
                refreshPage()
            }
        })

        return {
            ...toRefs(props),
            searchKey,
            records,
            select,
            loading,
            confirmLoading,
            refreshPage,
            handleConfirm,
            getBirthdayDisplay,
        }
    }
})
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variables";

.info-title {
    color: #818c99;
    padding-bottom: 10px;
    font-size: 12px;
}

.info-wrapper {
    display: flex;
    gap: 130px;
    background: #f5f7fa;
    padding: 20px 20px 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.info-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.el-icon-search {
    margin-top: 10px;
}

.el-icon-search:hover {
    color: $--color-primary;
    cursor: pointer;
}
</style>
