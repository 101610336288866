import http from '../../utils/httpRequest'

export function getVisitCountInfoApi ( params) {
  return http.get(`/admin/ballettablevisit/countInfo`, {params})
}

export function getVisitPageApi ( params) {
  return http.get(`/admin/ballettablevisit/page`, {params})
}

export function reserveVisitApi (data) {
  return http.post('/admin/ballettablevisit/reserveVisit', data)
}

export function signInApi (data) {
  return http.post('/admin/ballettablevisit/signIn', data)
}

export function cancelApi (data) {
  return http.post('/admin/ballettablevisit/cancel', data)
}

export function rescheduleApi (data) {
  return http.post('/admin/ballettablevisit/reschedule', data)
}

export function cancelSignInApi (data) {
  return http.post('/admin/ballettablevisit/cancelSignIn', data)
}