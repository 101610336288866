<template>
    <div>
        <el-dialog
            custom-modal
            width="400px"
            :visible="visible"
            @update:visible="val=>$emit('update:visible',val)"
            title="退款"
            :close-on-click-modal="false"
            append-to-body>
            <div class="fail-text-color" style="padding-bottom: 10px;">
                警告：退款会让合同完结，如果想单纯退一笔款可以使用改套餐。
            </div>
            <el-form :model="formData" :rules="fromRules" ref="formRef" label-position="top" size="mini">
                <el-form-item required label="支付方式" prop="payType">
                    <el-select v-model="formData.payType" style="width: 100%;">
                        <el-option v-for="payMethod in $store.getters['common/payMethods']"
                                   v-bind="payMethod"
                                   :key="payMethod.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="paymentPrice" label="金额">
                    <amount-input v-model="formData.paymentPrice"
                                  :max="currency(data.remainingAmount).add(data.planedNoStudyOrdinaryClassConsumeAmount).value">
                    </amount-input>
                </el-form-item>
                <el-form-item prop="reason" label="原因">
                    <el-input v-model="formData.reason"
                              placeholder=""
                              :maxlength="250"
                              show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="$emit('update:visible',false)">取 消</el-button>
            <el-button type="primary"
                       @click="handleConfirm"
                       size="small"
                       :loading="confirmLoading">
                确 定
            </el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import {computed, defineComponent, getCurrentInstance, inject, ref, watch} from 'vue';
import {refundApi} from '../../../api/student/contractApi';
import AmountInput from "@/components/Form/AmountInput.vue";
import currency from "currency.js";

export default defineComponent({
    methods: {currency},
    components: {AmountInput},
    props: {
        visible: Boolean
    },
    emits: ['update:visible'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const formRef = ref();

        const fromRules = ref({
            payType: [{required: true, message: '支付方式是必填的'}],
            paymentPrice: [{required: true, message: '金额是必填的'}],
            reason: [{required: true, message: '原因是必填的'}]
        });

        const formData = ref({
            payType: undefined,
            paymentPrice: undefined,
            reason: undefined
        });

        const confirmLoading = ref(false);

        const handleConfirm = () => {
            currentInstance.proxy.$confirm(`警告！确认是否申请退款，确认申请退款将退课 (${data.value.planedOrdinaryClassConsumeCount - data.value.studiedOrdinaryClassConsumeCount + data.value.remainingOrdinaryClassCount}) 课时（其中已约未上 (${data.value.planedOrdinaryClassConsumeCount - data.value.studiedOrdinaryClassConsumeCount}) 课时）?注意！此操作无法恢复。`, {
                type: 'warning',
                showClose: false,
            }).then(() => {
                confirmLoading.value = true;
                formRef.value.validate().then(() => refundApi({
                    ...formData.value,
                    id: data.value.id,
                    expectRefundOrdinaryClassCount: data.value.planedOrdinaryClassConsumeCount - data.value.studiedOrdinaryClassConsumeCount + data.value.remainingOrdinaryClassCount
                })).then(() => {
                    emit('update:visible', false);
                    refreshWater();
                }).finally(() => {
                    confirmLoading.value = false;
                });
            });
        };

        const refreshWater = inject('refreshWater');

        const getData = inject('getData');

        const data = computed(() => getData());

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value = {
                    payType: data.value.payType,
                    paymentPrice: currency(data.value.remainingAmount).add(data.value.planedNoStudyOrdinaryClassConsumeAmount).value,
                    reason: undefined
                };
                setTimeout(() => {
                    formRef.value.clearValidate();
                });
            } else {
                formData.value = {
                    payType: undefined,
                    paymentPrice: undefined,
                    reason: undefined
                };
            }
        });

        return {
            formRef,
            fromRules,
            formData,
            data,
            confirmLoading,
            handleConfirm
        };
    }
});
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 !important;
    color: #3b3b3b !important;
}
</style>
