<template>
<el-dialog title="课程冲突详情"
           custom-modal
           append-to-body
           :visible.sync="visible"
           @update:visible="val=>$emit('update:visible',val)"
           width="1000px">
    <el-tabs v-model="activeTab" style="min-height: 500px" tab-position="right">
        <el-tab-pane label="老师" name="1"
                     :disabled="!(Array.isArray(data.teacherOccupancyList)&&data.teacherOccupancyList.length)">
            <el-table :header-cell-style="{backgroundColor:'#f7f7f7'}"
                      :cell-style="{fontSize:'12px'}"
                      :data="data.teacherOccupancyList">
                <el-table-column label="课程名称">
                    <template v-slot="{row}">
                        <div>
                            <span>目标课程：</span>
                            <span>
                                {{ row.className }}
                            </span>
                        </div>
                        <div style="color: #f5222d;">
                            <span>冲突课程：</span>
                            <span>
                                {{ row.occupancyClassName }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="老师姓名" prop="name"></el-table-column>
                <el-table-column label="冲突日期">
                    <template v-slot="{row}">
                        <div>
                            {{
                                dayjs(row.startTime).startOf('day').isSame(dayjs(row.endTime).startOf('day')) ?
                                    dayjs(row.startTime).startOf('day').format('YYYY-MM-DD') :
                                    dayjs(row.startTime).startOf('day').format('YYYY-MM-DD') + ' ' + dayjs(row.endTime).startOf('day').format('YYYY-MM-DD')
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="上课时间">
                    <template v-slot="{row}">
                        <div>
                            <span>目标课程：</span>
                            <span>
                                {{
                                    `${dayjs(row.startTime).format('HH:mm')}-${dayjs(row.endTime).format('HH:mm')}`
                                }}
                            </span>
                        </div>
                        <div style="color: #f5222d;">
                            <span>冲突课程：</span>
                            <span>
                                {{
                                    `${dayjs(row.occupancyStartTime).format('HH:mm')}-${dayjs(row.occupancyEndTime).format('HH:mm')}`
                                }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template v-slot="{row}">
                        <div style="display:flex;flex-direction: column;gap: 8px;">
                            <el-button type="text"
                                       style="margin: 0;padding: 0;color: #3b3b3b;"
                                       class="under-line"
                                       size="mini"
                                       v-if="row.classId"
                                       @click="classDetailModalVisible=true;classId=row.classId">
                                查看目标课程
                            </el-button>
                            <el-button type="text"
                                       style="margin: 0;padding: 0;"
                                       class="under-line"
                                       size="mini"
                                       @click="classDetailModalVisible=true;classId=row.occupancyClassId">
                                调整冲突课程
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-tab-pane>
        <el-tab-pane label="教室" name="2"
                     :disabled="!(Array.isArray(data.classroomOccupancyList)&&data.classroomOccupancyList.length)">
            <el-table :header-cell-style="{backgroundColor:'#f7f7f7'}"
                      :cell-style="{fontSize:'12px'}"
                      :data="data.classroomOccupancyList">
                <el-table-column label="课程名称">
                    <template v-slot="{row}">
                        <div>
                            <span>目标课程：</span>
                            <span>
                                {{ row.className }}
                            </span>
                        </div>
                        <div style="color: #f5222d;">
                            <span>冲突课程：</span>
                            <span>
                                {{ row.occupancyClassName }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="教室名称" prop="name"></el-table-column>
                <el-table-column label="冲突日期">
                    <template v-slot="{row}">
                        <div>
                            {{
                                dayjs(row.startTime).startOf('day').isSame(dayjs(row.endTime).startOf('day')) ?
                                    dayjs(row.startTime).startOf('day').format('YYYY-MM-DD') :
                                    dayjs(row.startTime).startOf('day').format('YYYY-MM-DD') + ' ' + dayjs(row.endTime).startOf('day').format('YYYY-MM-DD')
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="上课时间">
                    <template v-slot="{row}">
                        <div>
                            <span>目标课程：</span>
                            <span>
                                {{
                                    `${dayjs(row.startTime).format('HH:mm')}-${dayjs(row.endTime).format('HH:mm')}`
                                }}
                            </span>
                        </div>
                        <div style="color: #f5222d;">
                            <span>冲突课程：</span>
                            <span>
                                {{
                                    `${dayjs(row.occupancyStartTime).format('HH:mm')}-${dayjs(row.occupancyEndTime).format('HH:mm')}`
                                }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template v-slot="{row}">
                        <div style="display:flex;flex-direction: column;gap: 8px;">
                            <el-button type="text"
                                       style="margin: 0;padding: 0;color: #3b3b3b;"
                                       class="under-line"
                                       size="mini"
                                       v-if="row.classId"
                                       @click="classDetailModalVisible=true;classId=row.classId">
                                查看目标课程
                            </el-button>
                            <el-button type="text"
                                       style="margin: 0;padding: 0;"
                                       class="under-line"
                                       size="mini"
                                       @click="classDetailModalVisible=true;classId=row.occupancyClassId">
                                调整冲突课程
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-tab-pane>
        <el-tab-pane label="学员" name="3"
                     :disabled="!(Array.isArray(data.studentOccupancyList)&&data.studentOccupancyList.length)">
            <el-table :header-cell-style="{backgroundColor:'#f7f7f7'}"
                      :cell-style="{fontSize:'12px'}"
                      :data="data.studentOccupancyList">
                <el-table-column label="课程名称">
                    <template v-slot="{row}">
                        <div>
                            <span>目标课程：</span>
                            <span>
                                {{ row.className }}
                            </span>
                        </div>
                        <div style="color: #f5222d;">
                            <span>冲突课程：</span>
                            <span>
                                {{ row.occupancyClassName }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="学员姓名" prop="name"></el-table-column>
                <el-table-column label="冲突日期">
                    <template v-slot="{row}">
                        <div>
                            {{
                                dayjs(row.startTime).startOf('day').isSame(dayjs(row.endTime).startOf('day')) ?
                                    dayjs(row.startTime).startOf('day').format('YYYY-MM-DD') :
                                    dayjs(row.startTime).startOf('day').format('YYYY-MM-DD') + ' ' + dayjs(row.endTime).startOf('day').format('YYYY-MM-DD')
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="上课时间">
                    <template v-slot="{row}">
                        <div>
                            <span>目标课程：</span>
                            <span>
                                {{
                                    `${dayjs(row.startTime).format('HH:mm')}-${dayjs(row.endTime).format('HH:mm')}`
                                }}
                            </span>
                        </div>
                        <div style="color: #f5222d;">
                            <span>冲突课程：</span>
                            <span>
                                {{
                                    `${dayjs(row.occupancyStartTime).format('HH:mm')}-${dayjs(row.occupancyEndTime).format('HH:mm')}`
                                }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template v-slot="{row}">
                        <div style="display:flex;flex-direction: column;gap: 8px;">
                            <el-button type="text"
                                       style="margin: 0;padding: 0;color: #3b3b3b;"
                                       class="under-line"
                                       size="mini"
                                       v-if="row.classId"
                                       @click="classDetailModalVisible=true;classId=row.classId">
                                查看目标课程
                            </el-button>
                            <el-button type="text"
                                       style="margin: 0;padding: 0;"
                                       class="under-line"
                                       size="mini"
                                       @click="classDetailModalVisible=true;classId=row.occupancyClassId">
                                调整冲突课程
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-tab-pane>
    </el-tabs>
    <class-detail-modal :class-id="classId" :visible.sync="classDetailModalVisible"></class-detail-modal>
</el-dialog>
</template>
<script>
import {defineComponent, toRefs, ref} from 'vue'
import dayjs from 'dayjs'
import ClassDetailModal from './ClassDetailModal.vue'

export default defineComponent({
    components: {ClassDetailModal},
    props: {
        visible: Boolean,
        data: {
            type: Object,
            default () {
                return {
                    teacherOccupancyList: [],
                    classroomOccupancyList: [],
                    studentOccupancyList: []
                }
            }
        }
    },
    setup (props) {

        const activeTab = ref('1')

        const classDetailModalVisible = ref(false)

        const classId = ref()

        return {
            ...toRefs(props),
            activeTab,
            classDetailModalVisible,
            classId,
            dayjs
        }
    }
})
</script>
