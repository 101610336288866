<template>
    <el-select v-bind="$attrs"
               :value="value"
               @input="val=>$emit('update:value',val)"
               style="width: 100%;">
        <el-option v-for="payMethod in $store.getters['common/payMethods']"
                   v-bind="payMethod"
                   :key="payMethod.value">
        </el-option>
    </el-select>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
    props: {value: [Number, Array]},
    emits: ['update:value'],
    model: {
        value: 'value',
        event: 'update:value'
    },
});
</script>