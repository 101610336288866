import {cloneObject, copyPropertyTo} from "@/utils/ObjectUtils";

const defaultTreeConfig = {
    parentId: 0,
    start: 0,
    fun: undefined
};

const defaultFormatTreeConfig = {
    parentId: 0,
    parent: 'parentId',
    children: 'children',
    fun: undefined,
    key: 'id'
};

export function randomId(tree, config = defaultTreeConfig) {
    const newConfig = cloneObject(defaultTreeConfig);
    copyPropertyTo(newConfig, config, true);
    const {parentId, start, fun} = newConfig;
    doRandomId(tree, parentId, {index: start}, fun);
}

export function parseTree(tree, childrenProp = 'children', fun = undefined) {
    return doParseTree(tree, childrenProp, fun, null, []);
}

export function formatTree(list, config = defaultFormatTreeConfig) {
    const newConfig = cloneObject(defaultFormatTreeConfig);
    copyPropertyTo(newConfig, config, true);
    return doFormatTree(list, newConfig);
}

function doRandomId(tree, parentId = 0, idObj = {index: 0}, fun = undefined, parents = []) {
    if (Array.isArray(tree) && tree.length) {
        tree.forEach(node => {
            idObj.index++;
            node.id = idObj.index;
            node.parentId = parentId;
            if (typeof fun === 'function') {
                fun(node, parents[parents.length - 1], [...parents]);
            }
            doRandomId(node.children, node.id, idObj, fun, [...parents, node]);
        });
    }
}

export function doParseTree(tree, childrenProp = 'children', fun, parentNode, parents) {
    const result = [];
    if (Array.isArray(tree) && tree.length) {
        tree.forEach(node => {
            result.push(node);
            const children = node[childrenProp];
            if (typeof fun === "function") {
                fun(node, parentNode, parents);
            }
            if (Array.isArray(children) && children.length) {
                doParseTree(children, childrenProp, fun, node, [...parents, node]).forEach(childrenItem => {
                    result.push(childrenItem);
                });
            }
        });
    }
    return result;
}

function doFormatTree(list, config) {
    const result = [];
    const childrenMap = {};
    for (let item of list) {
        if (item[config.parent] === config.parentId) {
            result.push(item);
        } else {
            let children = childrenMap[item[config.parent]];
            if (!children) {
                childrenMap[item[config.parent]] = children = [];
            }
            delete item[config.children];
            children.push(item);
        }
        if (typeof config.fun === 'function') {
            config.fun(item);
        }
    }
    for (let item of result) {
        if (childrenMap[item[config.key]]) {
            item[config.children] = childrenMap[item[config.key]];
        }
    }
    return result;
}
