import Vue from 'vue';
import router from '@/router';
import store from '@/store';

/**
 * 获取uuid
 */
export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16);
    });
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
    return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false;
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
    var res = [];
    var temp = {};
    for (var i = 0; i < data.length; i++) {
        temp[data[i][id]] = data[i];
    }
    for (var k = 0; k < data.length; k++) {
        if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
            if (!temp[data[k][pid]]['children']) {
                temp[data[k][pid]]['children'] = [];
            }
            if (!temp[data[k][pid]]['_level']) {
                temp[data[k][pid]]['_level'] = 1;
            }
            data[k]['_level'] = temp[data[k][pid]]._level + 1;
            temp[data[k][pid]]['children'].push(data[k]);
        } else {
            res.push(data[k]);
        }
    }
    return res;
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
    store.commit('resetStore');
}

export function addDaysToDate(dateString, days) {
    var date = new Date(dateString);  // 将日期字符串转换为 Date 对象
    date.setDate(date.getDate() + days);  // 计算日期相加
    return checkTime(date);  // 返回结果日期的字符串形式（yyyy-MM-dd）
}

export function checkTime(timeValue, val) {
    let year = timeValue.getFullYear();
    let mounth = timeValue.getMonth() + 1;
    let date = timeValue.getDate();
    let hour = timeValue.getHours();
    let minute = timeValue.getMinutes();
    let sec = timeValue.getSeconds();
    if (mounth < 10) {
        mounth = '0' + mounth;
    }
    if (date < 10) {
        date = '0' + date;
    }
    if (hour < 10) {
        hour = '0' + hour;
    }
    if (minute < 10) {
        minute = '0' + minute;
    }
    if (sec < 10) {
        sec = '0' + sec;
    }
    let expireTime = `${year}-${mounth}-${date} ${hour}:${minute}:${sec}`;
    if (val === 1) {
        expireTime = `${year}-${mounth}-${date}`;
    }
    return expireTime;
}

export function getBirthdate(age) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const birthYear = currentYear - age;
    const birthdate = new Date(birthYear, currentDate.getMonth(), currentDate.getDate());

    // 格式化出生日期
    const year = birthdate.getFullYear();
    const month = birthdate.getMonth() + 1; // 月份从0开始，需要加1
    const day = birthdate.getDate();

    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
}

export function getBirthMoth(months) {
    const currentDate = new Date();
    const previousDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - months, currentDate.getDate());

    // 格式化日期
    const year = previousDate.getFullYear();
    const month = previousDate.getMonth() + 1; // 月份从0开始，需要加1
    const day = previousDate.getDate();

    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} 00:00:00`;
}

/**
 * 获取开始截止日期有多少个周几
 * @param startDate
 * @param endDate
 * @param dayOfWeek
 * @returns {number}
 */
export function countWeekdays(startDate, endDate, dayOfWeek) {
    // 将字符串日期转换为日期对象
    let start = new Date(startDate);
    let end = new Date(endDate);

    // 确保开始日期在结束日期之前
    if (start > end) {
        let temp = start;
        start = end;
        end = temp;
    }

    let count = 0;
    let current = new Date(start.getTime());

    // 循环遍历每一天，判断是否为指定的星期几
    while (current.getTime() <= end.getTime()) {
        if (current.getDay() === dayOfWeek) {
            count = count + 1;
        }
        current.setDate(current.getDate() + 1);
    }

    return count;
}

/**
 * 根据当前周几返回日期
 * @param weekday
 * @returns {string}
 */
export function getNextWeekdayDate(weekday) {
    let currentDate = new Date();
    let currentWeekday = currentDate.getDay(); // 当前日期的星期几
    // 0表示星期日，1表示星期一，以此类推
    // 计算目标星期几在当前周的日期
    let diff = weekday - currentWeekday;
    if (diff < 0) {
        diff += 7; // 如果目标星期几已过，则计算下一周的星期几
    }
    let targetDate = new Date(currentDate);
    targetDate.setDate(targetDate.getDate() + diff);
    return checkTime(targetDate, 1);
}

//返回课表时间格式
export function dateFormatter(startTime, endTime, isType) {
    // 将字符串转换为日期对象
    var startDateObj = new Date(startTime);
    var endDateObj = new Date(endTime);

    // 获取年、月、日、小时和分钟
    var year = startDateObj.getFullYear();
    var month = ('0' + (startDateObj.getMonth() + 1)).slice(-2);
    var day = ('0' + startDateObj.getDate()).slice(-2);
    var startHour = ('0' + startDateObj.getHours()).slice(-2);
    var startMinute = ('0' + startDateObj.getMinutes()).slice(-2);
    var endHour = ('0' + endDateObj.getHours()).slice(-2);
    var endMinute = ('0' + endDateObj.getMinutes()).slice(-2);
    var type = isType ? '-' : '~';
    // 构建新的时间范围字符串
    var timeRange = year + '-' + month + '-' + day + ' ' + startHour + ':' + startMinute + type + endHour + ':' + endMinute;

    // console.log(timeRange); // 输出：2024-01-03 12:15~13:45
    return timeRange;
}

//根据时间返回格式（星期一 12:30）
export function dateForWeek(time) {// 创建一个Date对象，表示当前时间
    var currentDate = new Date(time);
    // 获取星期几的索引（0表示星期日，1表示星期一，依此类推）
    var dayIndex = currentDate.getDay();
    // 定义一个数组，包含星期几的名称
    var weekDays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    // 根据索引获取星期几的名称
    var currentDay = weekDays[dayIndex];

    let hour = currentDate.getHours();
    let minute = currentDate.getMinutes();
    let sec = currentDate.getSeconds();
    if (hour < 10) {
        hour = '0' + hour;
    }
    if (minute < 10) {
        minute = '0' + minute;
    }

    var showTime = currentDay + ' ' + hour + ':' + minute;
    return showTime;
}

/**
 * 将label value转换为键值对象
 * @param array
 * @returns {{}}
 */
export function labelValueToObjectKeyValue(array) {
    if (Array.isArray(array)) {
        const result = {};
        for (let item of array) {
            result[item.value] = item.label;
        }
        return result;
    }
    return {};
}

/**
 * 获得默认分页信息
 * @returns {{total: number, pageSize: number, currentPage: number, timestamp: number}}
 */
export function getDefaultPage() {
    return {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        timestamp: new Date().getTime() + 60000
    };
}

export function debounce(func, wait = 300, immediate = true) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
