import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import qs from 'qs';
import merge from 'lodash/merge';
import {clearLoginInfo} from '@/utils/index';

/**
 * 自定义查询参数序列化方法
 * @param params 查询参数对象
 * @returns {string} queryString
 */
export const paramsSerializer = (params) => {
    if (!params || typeof params !== 'object' || Array.isArray(params)) {
        return '';
    }
    const resolve = (key, value) => {
        if (value !== null && value !== undefined) {
            if (value && typeof value === 'object') {
                let resultArr = [];
                if (!Array.isArray(value) || value.length) {
                    for (let vk in value) {
                        resultArr.push(resolve(`${key}${Array.isArray(value) ? '[' : '.'}${vk}${Array.isArray(value) ? ']' : ''}`, value[vk]));
                    }
                } else if (key.indexOf('[') !== -1) {
                    resultArr.push(resolve(key, ''));
                }
                return resultArr.filter(item => item).join('&');
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(value);
            }
        } else {
            return '';
        }
    };
    const resultArr = [];
    for (let key in params) {
        resultArr.push(resolve(key, params[key]));
    }
    return resultArr.filter(item => item).join('&');
};

const http = axios.create({
    timeout: 1000 * 60,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    paramsSerializer,
    baseURL: process.env.VUE_APP_BASE_URL,
});

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
    config.headers['Access-Token'] = localStorage.getItem('token');
    try {
        const localStore = JSON.parse(localStorage.getItem('store'));
        config.headers['store'] = localStore ? (localStore.userStoreType + "_" + localStore.code) : undefined;
    } catch (e) {
        console.error(e);
    }
    return config;
}, error => {
    return Promise.reject(error);
});

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
    if (typeof response.data === 'object' && !Array.isArray(response.data)) {
        if (response.data instanceof Blob) {
            return response;
        }
        if (typeof response.data.status === 'boolean') {
            if (!response.data.status) {
                Vue.prototype.$message({type: 'error', message: response.data.message});
                return Promise.reject(response);
            }
        } else {
            if (response.data.code !== 0) {
                Vue.prototype.$message({type: 'error', message: response.data.msg});
                return Promise.reject(response);
            }
        }
    }
    return response;
}, error => {
    try {
        const response = error.response;
        if (response) {
            if (response.status === 403) {
                clearLoginInfo();
                router.push({name: 'login'});
            } else if (response && typeof response.data === 'object') {
                //JsonResult
                if (typeof response.data.status === 'boolean') {
                    Vue.prototype.$message({type: 'error', message: response.data.message});
                } else if (response.data.msg) {
                    Vue.prototype.$message({type: 'error', message: response.data.msg});
                }
            } else if (response.status === 401) {
                if (response.data && response.data.message && response.data.message !== 'Unauthorized') {
                    Vue.prototype.$message({type: 'error', message: response.data.message});
                } else {
                    Vue.prototype.$message({type: 'error', message: '您没有对应的操作权限！'});
                }
            } else if (response.status === 500) {
                Vue.prototype.$message({type: 'error', message: '服务端异常！'});
            } else if (response.status === 502 || response.status === 503) {
                Vue.prototype.$message({type: 'error', message: '系统正在升级维护中！'});
            } else if (response.status === 504) {
                Vue.prototype.$message({type: 'error', message: '请求超时！'});
            } else if (response.status === 400) {
                Vue.prototype.$message({type: 'error', message: '调用接口时参数异常！'});
            } else if (response.status === 404) {
                Vue.prototype.$message({type: 'error', message: '接口不存在！'});
            } else if (response.status === 405) {
                Vue.prototype.$message({type: 'error', message: '调用接口方法异常！'});
            } else if (response.status === 413) {
                Vue.prototype.$message({type: 'error', message: '文件大小超出上传限制！'});
            } else {
                Vue.prototype.$message({type: 'error', message: response.data.message});
            }
        } else {
            if (error.code === 'ERR_NETWORK') {
                Vue.prototype.$message({type: 'error', message: '网络异常！'});
            } else {
                Vue.prototype.$message({type: 'error', message: '未知异常！'});
            }
        }
    } catch (e) {
        console.error(e);
    }
    return Promise.reject(error);
});

function downloadBlob(blob, fileName) {
    const objectURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    if (fileName) {
        a.setAttribute('download', fileName);
    }
    a.setAttribute('href', objectURL);
    a.click();
    a.remove();
    URL.revokeObjectURL(objectURL);
}

http.download = {
    get(url, config, fileName) {
        return http.get(url, typeof config === 'object' ? {...config, responseType: 'blob'} : {responseType: 'blob'})
            .then(res => {
                downloadBlob(res.data, fileName);
            });
    },
    post(url, data, config, fileName) {
        return http.post(url, data, typeof config === 'object' ? {
            ...config,
            responseType: 'blob'
        } : {responseType: 'blob'})
            .then(res => {
                downloadBlob(res.data, fileName);
            });
    }
};

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
    // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
    return process.env.VUE_APP_BASE_URL + actionName;
};

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefaultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefaultParams = true) => {
    var defaults = {
        't': new Date().getTime()
    };
    return openDefaultParams ? merge(defaults, params) : params;
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefaultData 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefaultData = true, contentType = 'json') => {
    var defaults = {
        't': new Date().getTime()
    };
    data = openDefaultData ? merge(defaults, data) : data;
    return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data);
};

export default http;
