const listStatusList = [
    {value: 'AwaitADecision', label: '待定'},
    {value: 'Efficiently', label: '有效'},
    {value: 'NotValid', label: '无效'},
];

const listTransferTypeList = [
    {value: 'FullTransferOut', label: '全部转出'},
    {value: 'CarbonCopyingTransfer', label: '复制转出'},
];

const listRecordSourceList = [
    {value: 'SelfCenter', label: '本中心'},
    {value: 'OtherCenter', label: '其他中心'},
    {value: 'Organization', label: '机构'},
];

const performanceType = [
    {value: 1, label: '新签'},
    {value: 2, label: '续签'},
    {value: 3, label: '退款'},
    {value: 4, label: '转中心'},
];

const overType = [
    {label: '确认收入完结', value: 1},
    {label: '转中心完结', value: 2},
    {label: '退款完结', value: 3},
    {label: '自然完结', value: 4},
];

const classType = [
    {value: 'Normal', label: '普通课'},
    {value: 'Audition', label: '试听课'},
    {value: 'Maneuver', label: '活动课'},
    {value: 'Reschedule', label: '补课'},
    {value: 'PrivateCoach', label: '私教'},
    {value: 'TrainingCamp', label: '训练营'},
];

const listVisitForList = [
    {value: 'Visit', label: '到访'},
    {value: 'Audition', label: '试听'},
];

export {
    listStatusList,
    listTransferTypeList,
    listRecordSourceList,
    performanceType,
    overType,
    classType,
    listVisitForList
};

