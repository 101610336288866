import dayjs from 'dayjs'

export function getBirthdayDisplay (birthdayStr, showYear = true) {
    if (birthdayStr) {
        const now = dayjs()
        const birthday = dayjs(birthdayStr)

        if (now.$y <= birthday.$y) {
            return now.$M - birthday.$M + '个月'
        } else {
            const year = showYear ? (birthdayStr.split(' ')[0] + ' ') : ''
            //  还没有过今年生日
            if (now.$M < birthday.$M) {
                return year + (-1 + now.$y - birthday.$y + '岁' + (now.$M + 12 - birthday.$M) + '个月')
            } else {
                return year + (now.$y - birthday.$y + '岁' + (now.$M - birthday.$M) + '个月')
            }
        }
    }
    return '未知年龄'
}
