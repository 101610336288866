<template>
    <div>
        <el-dialog
            class="asd"
            :modal="true"
            width="450px"
            :visible.sync="visible"
            :before-close="closeAdditionModal"
            title="添加附件"
            :close-on-click-modal="false"
            custom-class="addition-dialog"
            append-to-body>
        <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :show-file-list="false">
            <el-button size="mini">点击上传</el-button>
        </el-upload>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeAdditionModal" size="small">取 消</el-button>
            <el-button type="primary" size="small">确 定</el-button>
        </span>
    </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'BalletVueAddition',
    props:['visible','closeAdditionModal'],
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {
        handlePreview(file) {
            console.log(file);
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">
.addition-dialog{
    .el-dialog__header {
    height: 40px;
    border-bottom: 1px solid #ccc;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 40px;
    color: #292e33;
    i::before{
        position: relative;
        top: -10px;
    }
    span{
        font-size: 14px;
    }
  }
    .el-dialog__body {
    padding: 10px 15px 20px 15px;
    box-sizing: border-box;
  }
  .el-dialog__footer {
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: hidden;
    border-top: 1px solid #ccc;
  }
}

</style>
