import { render, staticRenderFns } from "./ConsumeRecord.vue?vue&type=template&id=22b7b151&scoped=true"
import script from "./ConsumeRecord.vue?vue&type=script&lang=js"
export * from "./ConsumeRecord.vue?vue&type=script&lang=js"
import style0 from "./ConsumeRecord.vue?vue&type=style&index=0&id=22b7b151&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22b7b151",
  null
  
)

export default component.exports