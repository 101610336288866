<template>
    <el-dialog title="到访详情"
               custom-modal
               append-to-body
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)">
        <list-visit-table :records="records"
                          v-loading="tableLoading"
                          @updated="handleUpdated"
                          :page="page">
        </list-visit-table>
    </el-dialog>
</template>
<script>
import {getVisitPageApi} from '../../../api/student/listVisitApi';
import dayjs from 'dayjs';
import {getDefaultPage} from '../../../utils';
import GenderView from '../../Common/GenderView.vue';
import ListVisitTable from "@/components/List/detail/ListVisitTable.vue";

export default {
    components: {ListVisitTable, GenderView},
    emits: ['update:visible', 'updated'],
    props: {
        visible: Boolean,
        listId: Number
    },
    data() {
        return {
            page: getDefaultPage(),
            tableLoading: false,
            records: [],
            updated: 0
        };
    },
    methods: {
        dayjs,
        getPage() {
            this.tableLoading = true;
            getVisitPageApi({
                listId: this.listId,
                currentPage: this.page.currentPage,
                pageSize: this.page.pageSize,
                timestamp: this.page.timestamp
            }).then(res => {
                this.records = res.data.data.records;
                this.page.currentPage = res.data.data.current;
                this.page.pageSize = res.data.data.pageSize;
                this.page.total = res.data.data.total;
            }).finally(() => this.tableLoading = false);
        },
        refreshPage() {
            this.page = getDefaultPage();
            this.getPage();
        },
        handleUpdated() {
            this.updated++;
        }
    },
    watch: {
        visible: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.refreshPage();
                } else {
                    if (this.updated) {
                        this.$emit('updated');
                    }
                    this.updated = 0;
                }
            }
        }
    }
};
</script>
<style scoped>
>>> .el-pagination {
    margin-top: 140px !important;
}
</style>