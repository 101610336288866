<template>
    <el-dialog
        title="从照片库添加图片"
        :visible.sync="visible"
        width="900px"
        :before-close="closePhotoLibrary"
        custom-class="photo-dialog"
        :modal-append-to-body='false'
    >
    <div class="content">
        <div class="left">
            <div class="top">
                <article>
                    <p>会员名字</p>
                    <el-input v-model="viperName" placeholder="请输入" size="mini"></el-input>
                </article>
                <article>
                    <p>拍摄日期</p>
                    <el-date-picker
                        v-model="date"
                        type="daterange"
                        size="mini"
                        range-separator="~"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        popper-class="dateTime"
                        style="width: 100%"
                    >
                    </el-date-picker>
                </article>
                <article>
                    <p>课类</p>
                    <el-select style="width:100%;" v-model="value" size="mini" filterable placeholder="请选择">
                        <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </article>
                <article>
                    <p>课程内容</p>
                    <el-input v-model="classContent" size="mini" placeholder="请输入"></el-input>
                </article>
                <article>
                    <p>老师</p>
                    <el-select style="width:100%;" v-model="teacher" size="mini" filterable placeholder="请选择">
                        <el-option
                            v-for="item in teacherOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </article>
                <article>
                    <p>教室</p>
                    <el-select style="width:100%;" v-model="classRoom" size="mini" filterable placeholder="请选择">
                        <el-option
                            v-for="item in classRoomOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </article>
                <div style="display:flex;align-items:flex-start;">
                    <el-button type="primary" style="width:96px;height:30px;background:#2fcc9a;display:flex;align-items:center;justify-content:center;">查询</el-button>
                    <el-button style="width:96px;height:30px;display:flex;align-items:center;justify-content:center;">重置</el-button>
                </div>
            </div>
            <div class="down">
                <p>全部会员</p>
                <div class="contain">
                    <span>无更多数据</span>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="header">
                <p>已选图片（<span>0</span>/9）</p>
            </div>
        </div>
    </div>
    <span slot="footer" style="display:flex;align-items:center;justify-content:flex-end;">
        <el-button style="width:96px;height:30px;display:flex;align-items:center;justify-content:center;" @click="closePhotoLibrary">取消</el-button>
        <el-button type="primary" style="width:96px;height:30px;background:#2fcc9a;display:flex;align-items:center;justify-content:center;">确认</el-button>
    </span>
    </el-dialog>
</template>

<script>
export default {
    name: 'BalletVuePhotoLibrary',
    props:['visible','closePhotoLibrary'],

    data() {
        return {
            //会员名字
            viperName:'',
            //拍摄日期
            date:'',
            options: [
                {
                    id: 1,
                    name: '黄金糕'
                },
                {
                    id: 2,
                    name: '双皮奶'
                },
                {
                    id: 3,
                    name: '蚵仔煎'
                },
                {
                    id: 4,
                    name: '龙须面'
                },
                {
                    id: 5,
                    name: '北京烤鸭'
                }
            ],
            value: '',
            //课程内容
            classContent:'',
            //老师
            teacher:'',
            //教室
            classRoom:'',
            //教室数据
            teacherOptions:[
                {
                    id:1,
                    name:'张三'
                },
                {
                    id:2,
                    name:'李四'
                },
                {
                    id:3,
                    name:'王五'
                },
                {
                    id:4,
                    name:'赵六'
                },
                {
                    id:5,
                    name:'钱七'
                },
            ],
            //教室数据
            classRoomOptions:[
                {
                    id:1,
                    name:'教室1'
                },
                {
                    id:2,
                    name:'教室2'
                },
                {
                    id:3,
                    name:'教室3'
                },
                {
                    id:4,
                    name:'教室4'
                },
                {
                    id:5,
                    name:'教室5'
                },
            ],
        };
    },

    mounted() {
       
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.content{
    display: flex;
    width: 100%;
    min-height: 400px;
    .left{
        flex: 1;
        padding: 0 20px;
        box-sizing: border-box;
        .top{
            height: 220px;
            border-bottom: 1px solid #ccc;
            display: grid;
            grid-template-columns: repeat(3,1fr);
            grid-gap: 10px;
            padding-top: 20px;
            box-sizing: border-box;
            article{
                margin: 0;
                p{
                    margin: 0 0 2px 0;
                }
            }
        }
        .down{
            height: 345px;
            .contain{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30%;
            }
        }
    }
    .right{
        border-left: 1px solid #ccc;
        flex: 0 0 150px;
        .header{
            height: 35px;
            border-bottom: 1px solid #ccc;
            p{
                line-height: 35px;
                margin: 0;
                text-align: center;
                span{
                    color: #29cb97;
                }
            }
        }
    }
}
</style>
<style lang="scss">
    .photo-dialog{
        .el-dialog__header {
            height: 50px;
            border-bottom: 1px solid #ccc;
            padding-top: 0;
            padding-bottom: 0;
            line-height: 50px;
            color: #292e33;
            font-size: 16px;
        }
        .el-dialog__body {
            padding: 0px;
            box-sizing: border-box;
        }
        .el-dialog__footer{
            border-top: 1px solid #ccc;
        }
    }
</style>