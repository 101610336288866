<template>
<el-dialog title="新约到访"
           width="21%"
           custom-modal
           append-to-body
           :visible="visible"
           @update:visible="val=>$emit('update:visible',val)">
    <el-form :model="formData" size="mini" :rules="formRules" ref="form" label-width="100px">
        <el-form-item label="预约时间：" prop="willVisitDate">
            <el-date-picker v-model="formData.willVisitDate" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择日期"
                            type="datetime"></el-date-picker>
        </el-form-item>
        <el-form-item label="到访事宜：" prop="reason">
            <el-input type="textarea" v-model="formData.reason"></el-input>
        </el-form-item>
    </el-form>
    <template slot="footer">
        <el-button class="ballet-modal-button" @click="$emit('update:visible',false)">取消</el-button>
        <el-button type="primary" class="ballet-modal-button" @click="reserveVisit" :loading="confirmLoading">
            确认
        </el-button>
    </template>
</el-dialog>
</template>
<script>
import {reserveVisitApi} from '../../../api/student/listVisitApi'

export default {
    emits: ['update:visible', 'ok'],
    props: {
        visible: Boolean,
        listId: Number
    },
    data () {
        return {
            formData: {},
            confirmLoading: false,
            formRules: {
                willVisitDate: [{required: true, message: '预约时间是必填的'}]
            }
        }
    },
    methods: {
        reserveVisit () {
            this.confirmLoading = true
            this.$refs.form.validate().then(() => reserveVisitApi({
                listId: this.listId,
                ...this.formData
            }).then(() => {
                this.$emit('update:visible', false)
                this.$emit('ok', this.formData.willVisitDate)
            }))

                .finally(() => this.confirmLoading = false)
        }
    }
}
</script>
<style lang="scss" scoped>

</style>
