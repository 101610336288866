import Vue from "vue";
import store from "@/store";
import router from "@/router";

export function isKeepAlive(componentInstance) {
    return (
        componentInstance.$vnode &&
        componentInstance.$vnode.data &&
        componentInstance.$vnode.data.keepAlive
    );
}

export function createTempComponent(element, render, data = {}) {
    if (!(element instanceof HTMLElement)) {
        render = element;
        element = document.createElement("div");
    }
    document.body.append(element);
    return new Vue({
        store,
        router,
        data() {
            return data;
        },
        el: element,
        render(createElement, hack) {
            if (typeof render === 'function') {
                return render(createElement, this);
            }
        },
    });
}
