<template>
    <transition name="fade">
        <router-view v-if="isRouterAlive"></router-view>
    </transition>
</template>
<script>
import hookStore from './hook/hookStore';
import pageMap from '@/router/pageMenu';

export default {
    name: 'App',
    provide() {
        return {
            reload: this.reload,
            refreshList: this.refreshList,
            refreshClass: this.refreshClass
        };
    },
    data() {
        return {
            isRouterAlive: true,
            hookStore
        };
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        refreshList() {
            this.hookStore._refreshList.forEach(fun => {
                typeof fun === 'function' && fun();
            });
        },
        refreshClass() {
            this.hookStore._refreshClass.forEach(fun => {
                typeof fun === 'function' && fun();
            });
        }
    },
    created() {
        try {
            if (!this.$store.state.campus.type) {
                this.$store.commit('campus/update', JSON.parse(localStorage.getItem('store')));
            }
        } catch (e) {
            this.push('/chooseStore');
        }

        // 在页面刷新时将vuex里的信息保存到sessionStorage里
        // beforeunload事件在页面刷新时先
        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem('store', JSON.stringify(this.$store.state));
        });
        this.$store.commit('closeDrawer');
    }
};
</script>
