/**
 * 全站路由配置
 *
 * 建议:
 * 1. 代码中路由统一使用name属性跳转(不使用path属性)
 */
import Vue from 'vue';
import Router from 'vue-router';
import {clearLoginInfo} from '@/utils';
import main from '@/router/main';
import more from '@/router/more';
import homeSchool from "@/router/homeSchool";
import {beforeEnter} from '@/router/routeGuard';

// 主入口路由(需嵌套上左右整体布局)
const defaultRoute = {
    path: '/',
    component: () => import('../layout/default/MainLayout.vue'),
    name: 'main',
    redirect: {name: 'home'},
    meta: {title: '主入口整体布局'},
    children: [
        ...main,
        ...more,
    ],
    beforeEnter
};

const hsiRoute = {
    path: '/',
    component: () => import('@/layout/homeSchool/HLayout.vue'),
    name: 'hsi',
    redirect: {name: 'home'},
    meta: {title: '主入口整体布局'},
    children: [
        ...homeSchool
    ],
    beforeEnter
};

const commonRoute = {
    path: '/',
    component: () => import('../layout/default/MainLayout.vue'),
    name: 'common',
    redirect: {name: 'home'},
    meta: {title: '主入口整体布局'},
    children: [
        {
            path: '/home',
            component: () => import('../views/common/Dashboard.vue'),
            name: 'home',
            meta: {title: '首页'}
        },
        {
            path: '/chooseStore',
            component: () => import('../views/common/ChooseStore.vue'),
            name: 'chooseStore',
            meta: {title: '选择校区'}
        },
        {
            path: '/theme',
            component: () => import('../views/common/theme.vue'),
            name: 'theme',
            meta: {title: '主题'}
        },
    ]
};

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
    {
        path: '/404',
        component: () => import('../views/common/404.vue'),
        name: '404',
        meta: {title: '404未找到'}
    },
    {
        path: '/401',
        component: () => import('../views/common/401.vue'),
        name: '401',
        meta: {title: '401无权限'}
    },
    {
        path: '/login',
        component: () => import('../views/common/login.vue'),
        name: 'login',
        meta: {title: '登录', auth: false}
    },
    {
        path: '/geotrust/form',
        component: () => ('../views/modules/geotrust/GeotrustForm.vue'),
        name: 'geotrustForm',
        meta: {title: '地推名单录入表单', auth: false}
    },
    {...commonRoute},
    {...defaultRoute},
    {...hsiRoute},
];

const router = new Router({
    mode: 'hash',
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            // savedPosition 会在你使用浏览器前进或后退按钮时候生效
            // savedPosition: 会记录滚动条的坐标，点击"后退/前进" 时的记录值(x:?,y:?)
            return savedPosition;
        } else if (to.path !== from.path) {
            // 期望滚动的位置
            return {x: 0, y: 0};
        }
    },
    routes: globalRoutes
});

router.beforeEach((to, from, next) => {
    if (to.meta.auth !== false) {
        let token = localStorage.getItem('token');
        if (!token || !/\S/.test(token)) {
            clearLoginInfo();
            return next({name: 'login'});
        }
    }
    if (from.path === '/chooseStore' && to.path !== '/chooseStore' && !JSON.parse(localStorage.getItem('store'))) {
        next('/chooseStore');
    } else {
        next();
    }
});

Vue.use(Router);

export default router;
