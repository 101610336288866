<template>
<div style="display: inline-flex;">
    <span v-if="gender>2||gender<1">未知性别</span>
    <span v-else
          :style="`background-color: ${gender===1?'#5ad3f5':'#fda6c9'};width: 16px;height: 16px;border-radius: 50%;text-align: center;display: flex;justify-content: center;align-items: center;`">
    <i class="el-icon-male" style="color:#FFFFFF;font-size: 10px;"
       v-if="gender===1"></i>
    <i class="el-icon-female"
       style="color:#FFFFFF;font-size: 10px;transform: rotate(45deg);"
       v-else-if="gender===2"></i>
</span>
</div>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
    props: {
        gender: Number,
    }
})
</script>
