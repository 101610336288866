import cTerminal from '@/router/main/cTerminal';

export default [
    // 通过meta对象设置路由展示方式
    // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
    // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
    // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
    {
        path: '/largeClassTable',
        component: () => import('../../views/common/LargeClassTable.vue'),
        name: 'class',
        meta: {title: '大课表', isClass: true}
    },
    {
        path: '/searchPage',
        component: () => import('../../views/common/SearchPage.vue'),
        name: 'searchPage',
        meta: {title: '搜索页'}
    },
    {
        path: '/createContract',
        component: () => import('@/views/store/CreateContract.vue'),
        name: 'CreateContract',
        meta: {title: '新增合同'}
    },
    {
        path: '/contractDetail',
        component: () => import('@/views/store/ContractDetail.vue'),
        name: 'contractDetail',
        meta: {title: '合同详情'}
    },
    {
        path: '/todayVisit',
        component: () => import('@/views/store/TodayVisit.vue'),
        name: 'todayVisit',
        meta: {title: '今日到访'}
    },
    {
        path: '/taskManage',
        component: () => import('@/views/store/TaskManage.vue'),
        name: 'TaskManage',
        meta: {title: '任务管理'}
    },
    {
        path: '/vipFixedAbsentRemind',
        component: () => import('@/views/store/VipFixedAbsentRemin.vue'),
        name: 'vipFixedAbsentRemind',
        meta: {title: '会员固定缺位提醒'}
    },
    {
        path: '/listManage',
        component: () => import('@/views/store/ListManage.vue'),
        name: 'ListManage',
        meta: {title: '名单管理'}
    },
    {
        path: '/listPublicSea',
        component: () => import('@/views/store/ListPublicSea.vue'),
        name: 'ListPublicSea',
        meta: {title: '名单管理(公海)'}
    },
    {
        path: '/listBatchLog',
        component: () => import('@/views/store/ListBatchLog.vue'),
        name: 'ListBatchLog',
        meta: {title: '名单批量操作日志'}
    },
    {
        path: '/contractManagement',
        component: () => import('@/views/store/ContractManagement.vue'),
        name: 'contractManagement',
        meta: {title: '合同管理'}
    },
    {
        path: '/vipManagement',
        component: () => import('@/views/store/VipManagement.vue'),
        name: 'vipManagement',
        meta: {title: '会员管理'}
    },
    {
        path: '/c',
        component: () => import('@/layout/cTerminal/CTerminalLayout.vue'),
        meta: {title: 'c端管理'},
        children: cTerminal,
    }
];

const meta = {
    name: '数据管理'
};

export {meta};
