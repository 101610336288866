import http from '../../utils/httpRequest'

export function getContractCountInfoApi (params) {
    return http.get('/admin/balletcontract/contractGroupByNum', {params})
}

export function getContractPageApi (params) {
    return http.get('/admin/balletcontract/contractPage', {params})
}

export function getContractDetailApi (id) {
    return http.get(`/admin/balletcontract/detail/${id}`)
}

export function changeDefaultContractApi (data) {
    return http.put(`/admin/balletcontract/change`, data)
}

export function updateCodeApi (data) {
    return http.put(`/admin/balletcontract/updateCode`, data)
}

export function updateContractDateApi (data) {
    return http.put(`/admin/balletcontract/updateContractDate`, data)
}

export function updateRemarkApi (data) {
    return http.put(`/admin/balletcontract/updateRemark`, data)
}

export function getContractPerformanceSpiltPageApi (params) {
    return http.get('/admin/balletcontract/performanceSpiltPage', {params})
}

export function getContractOperationPagePageApi (params) {
    return http.get('/admin/balletcontract/operationPage', {params})
}

export function paymentApi (data) {
    return http.post('/admin/balletcontract/payment', data)
}

export function updatePerformanceSpiltApi (data) {
    return http.put('/admin/balletcontract/updatePerformanceSpilt', data)
}

export function confirmWaterApi (data) {
    return http.post('/admin/balletcontract/ok', data)
}

export function freezeApi (data) {
    return http.put('/admin/balletcontract/freeze', data)
}

export function unfreezeApi (data) {
    return http.put('/admin/balletcontract/unfreeze', data)
}

export function rescheduleApi (data) {
    return http.put('/admin/balletcontract/reschedule', data)
}

export function deleteContractApi (id) {
    return http.delete(`/admin/balletcontract/${id}`)
}

export function getWaterCountInfoApi (params) {
    return http.get('/admin/balletcontract/waterCountAndAmount', {params})
}

export function getWaterPageApi (params) {
    return http.get('/admin/balletcontract/waterPage', {params})
}

export function getSingleContractWaterPageApi (params) {
    return http.get(`/admin/balletcontract/water`, {params})
}

export function getWaterPerformanceChangeRecordPageApi (params) {
    return http.get('/admin/balletcontract/waterPerformanceChangeRecordPage', {params})
}

export function changeContractApi (data) {
    return http.put(`/admin/balletcontract/changeContract`, data)
}

export function changeClassTimeApi (data) {
    return http.put(`/admin/balletcontract/classTimeChange`, data)
}

export function updateClassConsumerApi (data) {
    return http.put(`/admin/balletcontract/hourClassChange`, data)
}

export function updateApplicableRuleApi (data) {
    return http.put('/admin/balletcontract/updateApplicableRules', data)
}

export function updateApplicableStudent (data) {
    return http.put('/admin/balletcontract/updateApplicableStudent', data)
}

export function refundApi (data) {
    return http.put('/admin/balletcontract/refund', data)
}

export function changeContractPackageApi (data) {
    return http.put(`/admin/balletcontract/updatePack`, data)
}

export function updatePerformanceTypeApi (data) {
    return http.put(`/admin/balletcontract/updatePerformanceType`, data)
}

export function updateReceiptDateApi (data) {
    return http.put(`/admin/balletcontract/updateReceiptDate`, data)
}

export function updatePerformanceVestingApi (data) {
    return http.put(`/admin/balletcontract/updatePerformanceVesting`, data)
}

export function getUseRecordPageApi (params) {
    return http.get('/admin/balletcontract/useRecordPage', {params})
}

export function getContractSimplePageApi (params) {
    return http.get('/admin/balletcontract/simple/page', {params})
}

export function cancelFreezeApi (data) {
    return http.put('/admin/balletcontract/cancelFreeze', data)
}

export function updateFreezeApi (data) {
    return http.put('/admin/balletcontract/updateFreeze', data)
}

export function checkWaterApi (contractId, params) {
    return http.post(`/admin/balletcontract/check/${contractId}`, null, {params})
}

export function createContractApi (data) {
    return http.post('/admin/balletcontract/create', data)
}

export function receiveAndCreateContractApi (data) {
    return http.post('/admin/balletcontract/receiveAndCreate', data)
}

export function transferApi (data) {
    return http.put('/admin/balletcontract/transform', data)
}

export function withdrawTransferApi (data) {
    return http.put('/admin/balletcontract/withdrawTransfer', data)
}

export function bindListForReceiveApi (data) {
    return http.put('/admin/balletcontract/bindListForReceive', data)
}

export function cancelBindListForReceiveApi (data) {
    return http.put('/admin/balletcontract/cancelBindListForReceive', data)
}
