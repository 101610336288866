<template>
    <div>
        <el-dialog title="改合同"
                   :visible="visible"
                   @update:visible="val=>$emit('update:visible',val)"
                   width="750px"
                   :close-on-click-modal="false"
                   custom-class="ballet-vue-change-contract"
                   append-to-body>
            <div slot="title" class="title">
                <span @click="goBackModal">改套餐</span>&gt;
                <p>改合同</p>
            </div>
            <div class="contract_info">
                <div>
                    <div class="contract_title">当前合同</div>
                    <div class="info-items">
                        <div class="info-item">
                            <span>剩余金额（元）</span>
                            <span>{{ remainingAmount.toFixed(2) }}</span>
                        </div>
                        <template v-if="data.packageType===1">
                            <div class="info-item">
                                <span>剩余普通课时</span>
                                <span>{{ data.remainingOrdinaryClassCount }}</span>
                            </div>
                            <div class="info-item">
                                <span>剩余特殊课时</span>
                                <span>{{ data.remainingSpecialClassCount }}</span>
                            </div>
                        </template>
                    </div>
                </div>
                <el-divider/>
                <div>
                    <div class="contract_title" style="color: #faad14;">修改后合同</div>
                    <div class="info-items">
                        <div class="info-item">
                            <span>剩余金额（元）</span>
                            <span>{{ afterUpdateRemainingAmount.toFixed(2) }}</span>
                        </div>
                        <template v-if="data.packageType===1">
                            <div class="info-item">
                                <span>剩余普通课时</span>
                                <span>{{ formData.allOrdinaryClass }}</span>
                            </div>
                            <div class="info-item">
                                <span>剩余特殊课时</span>
                                <span>{{ formData.allSpecialClass }}</span>
                            </div>
                        </template>
                    </div>
                    <el-divider/>
                    <div class="info-items">
                        <div class="info-item">
                            <span>合同差价（元）</span>
                            <span>{{ difference.toFixed(2) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <el-form :model="formData" ref="formRef" :rules="formRules" label-position="top" size="mini" inline>
                <el-form-item prop="shouldMoney" style="width: 45%;" label="应收价格（元）">
                    <amount-input v-model="formData.shouldMoney"></amount-input>
                </el-form-item>
                <el-form-item prop="allOrdinaryClass" style="width: 45%;" label="普通课时+赠送课时">
                    <custom-number-input v-model="formData.allOrdinaryClass"></custom-number-input>
                </el-form-item>
                <el-form-item prop="allSpecialClass" style="width: 45%;" label="特殊课时">
                    <custom-number-input v-model="formData.allSpecialClass"></custom-number-input>
                </el-form-item>
                <br>
                <el-form-item prop="payType" style="width: 45%;" label="支付方式">
                    <pay-method-select v-model="formData.payType"></pay-method-select>
                </el-form-item>
                <el-form-item prop="reason" style="width: 100%;" label="原因">
                    <el-input v-model="formData.reason"
                              :maxlength="250"
                              show-word-limit>
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button size="mini" @click="$emit('update:visible',false)">取 消</el-button>
                <el-button size="mini" style="background:#29cb97;border:none;" type="primary" @click="doChange"
                           :loading="confirmLoading">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {changeContractApi} from '../../../api/student/contractApi';
import {cloneObject, isEqual} from '../../../utils/ObjectUtils';
import AmountInput from "@/components/Form/AmountInput.vue";
import CustomNumberInput from "@/components/Form/CustomNumberInput.vue";
import PayMethodSelect from "@/components/Form/PayMethodSelect.vue";

const defaultFormData = {
    shouldMoney: undefined,
    allOrdinaryClass: undefined,
    allSpecialClass: undefined,
    payType: undefined,
    reason: undefined
};

export default {
    components: {PayMethodSelect, CustomNumberInput, AmountInput},
    props: ['visible'],
    emits: ['update:visible', 'back'],
    inject: ['getData', 'refreshWater'],
    data() {
        return {
            formRules: {
                shouldMoney: [{required: true, message: '应收金额是必填的'}],
                allOrdinaryClass: [{required: true, message: '普通课时+赠送课时是必填的'}],
                allSpecialClass: [{required: true, message: '特殊课时是必填的'}],
                payType: [{required: true, message: '支付方式是必填的'}],
                reason: [{required: true, message: '原因是必填的'}],
            },
            formData: cloneObject(defaultFormData),
            confirmLoading: false,
        };
    },
    methods: {
        goBackModal() {
            this.$emit('update:visible', false);
            this.$emit('back');
        },
        doChange() {
            this.confirmLoading = true;
            this.$refs.formRef.validate()
                .then(() => changeContractApi({...this.formData, contractId: this.data.id}))
                .then(() => {
                    this.refreshWater();
                    this.$emit('update:visible', false);
                })
                .finally(() => this.confirmLoading = false);
        }
    },
    computed: {
        data() {
            return this.getData();
        },
        remainingAmount() {
            return this.data.remainingAmount;
        },
        difference() {
            const float = parseFloat(this.formData.shouldMoney);
            if (float) {
                return float - this.data.shouldMoney;
            }
            return 0;
        },
        afterUpdateRemainingAmount() {
            return this.remainingAmount + this.difference;
        },
    },
    watch: {
        visible: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.formData = cloneObject(defaultFormData);
                    this.formData.shouldMoney = this.data.shouldMoney;
                    this.formData.allOrdinaryClass = this.data.remainingOrdinaryClassCount;
                    this.formData.allSpecialClass = this.data.remainingSpecialClassCount;
                    this.formData.payType = this.data.payType;
                    setTimeout(() => {
                        this.$refs.formRef.clearValidate();
                    });
                }
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.title {
    display: flex;
    align-items: center;

    span {
        margin-right: 10px;
    }

    p {
        margin: 0 0 0 10px;
    }

    span {
        font-size: 16px;
        color: #818c99;
        text-decoration: underline;
        text-align: center;
        cursor: pointer;
    }
}

.contract_info {
    background: #f5f7fa;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 4px;

    div {
        .contract_title {
            font-size: 14px;
            margin: 0 0 10px;
            color: #292e33;
        }

        .info-items {
            display: flex;
            align-items: start;

            .info-item {
                width: 25%;
                display: flex;
                gap: 8px;
                flex-direction: column;

                span:first-child {
                    color: #818c99;
                }

                span:last-child {
                    word-break: break-all;
                    line-height: 1.3em;
                    color: #3b3b3b;
                }
            }
        }

        .info-items:not(:last-child) {
            margin-bottom: 10px;
        }
    }

}
</style>
<style lang="scss">
.ballet-vue-change-contract {
    .el-dialog__body {
        padding: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeef5;
        border-top: 1px solid #ebeef5;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
    }
}
</style>
<style scoped>
>>> .el-form-item__label {
    color: #818c99 !important;
    padding: 0 !important;
}
</style>
