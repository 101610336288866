import dayjs from 'dayjs';

export function getWeekDayDisplay(dateStrOrNumber, displayMode = 0) {
    if (dateStrOrNumber || typeof dateStrOrNumber === 'number') {
        let weekday = dateStrOrNumber;
        if (typeof weekday !== 'number') {
            weekday = dayjs(dateStrOrNumber).day();
        }
        if (displayMode === 0) {
            switch (weekday) {
                case 1:
                    return '星期一';
                case 2:
                    return '星期二';
                case 3:
                    return '星期三';
                case 4:
                    return '星期四';
                case 5:
                    return '星期五';
                case 6:
                    return '星期六';
                case 0:
                    return '星期天';
            }
        } else if (displayMode === 1) {
            switch (weekday) {
                case 1:
                    return '周一';
                case 2:
                    return '周二';
                case 3:
                    return '周三';
                case 4:
                    return '周四';
                case 5:
                    return '周五';
                case 6:
                    return '周六';
                case 0:
                    return '周日';
            }
        } else if (displayMode === 2) {
            switch (weekday) {
                case 1:
                    return '礼拜一';
                case 2:
                    return '礼拜二';
                case 3:
                    return '礼拜三';
                case 4:
                    return '礼拜四';
                case 5:
                    return '礼拜五';
                case 6:
                    return '礼拜六';
                case 0:
                    return '礼拜天';
            }
        }
    }
    return '';
}
