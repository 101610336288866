<template>
    <div class="content">
        <template v-if="classInfo">
            <p class="date">停课日期 {{ dayjs(classInfo.beginTime).format('YYYY-MM-DD') }}</p>
        </template>
        <template v-else>
            <el-form size="mini">
                <el-form-item label="停课日期" required>
                    <el-date-picker type="daterange"
                                    v-model="dateRange"
                                    :clearable="false"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions1">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div style="padding-bottom: 20px;color: #f5222d;">
                <i class="el-icon-warning-outline"></i>
                <span>停课默认会将所选日期全部未结束的课程关停，可选择具体日期进行详细操作。</span>
            </div>
        </template>

        <div v-loading="loading">
            <div v-for="item in group">
                <div class="date_now" @click="handleOpenClose(item)">
                    <span>{{ item.date }}</span>
                    <img src="../../../assets/img/down.png" alt="">
                </div>
                <el-table :data="item.classList"
                          v-if="tableShow[item.date]"
                          :header-cell-style="{backgroundColor:'#f7f7f7'}"
                          :cell-style="{fontSize:'12px'}">
                    <el-table-column v-if="!classInfo" prop="time">
                        <template v-slot:header>
                            <el-checkbox @change="val=>handleSelectAll(item.classList,val)"
                                         :disabled="!item.classList.find(item=>!item.isOver)"
                                         :value="selected.length&&isChecked(item.classList)">
                            </el-checkbox>
                        </template>
                        <template v-slot="{row}">
                            <el-checkbox @change="val=>handleSelectChange(row,val)"
                                         :value="selected.indexOf(row.id)!==-1"
                                         :disabled="row.isOver">
                            </el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column prop="beginTime" label="上课时间">
                    </el-table-column>
                    <el-table-column prop="name" label="课程名称">
                    </el-table-column>
                    <el-table-column prop="classTypeName" label="课类">
                    </el-table-column>
                    <el-table-column prop="roomNames" label="教室">
                    </el-table-column>
                    <el-table-column prop="teacherNames" label="老师">
                    </el-table-column>
                    <el-table-column label="状态">
                        <template v-slot="{row}">
                            <div>
                                <span v-if="row.isOver" style="color: #f5222d;">已结束</span>
                                <span v-else class="primary-text-color">未结束</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div class="stop_class_info">
            <p class="title">停课信息</p>
            <div>
                <span>预计关停课程 {{ selected.length }} 节</span>
                <span>未结束课程已签到 {{ countObject.noClassesCount }} 人次</span>
                <span>请假 {{ countObject.leaveOfAbsenceCount }} 人次</span>
                <span>排课会员 {{ countObject.planCount }} 人次</span>
                <span>约课会员 {{ countObject.reserveCount }} 人次</span>
                <span>约课名单 {{ countObject.listCount }} 人次</span>
            </div>
        </div>
        <div class="stop_reason">
            <div class="reason">
                <span>发送停课原因</span>
                <el-switch
                    v-model="sendMessageToClient"
                    active-color="#29cb97"
                    inactive-color="#cccccc">
                </el-switch>
            </div>
            <el-input v-if="sendMessageToClient"
                      v-model="reason"
                      placeholder="请填写需要发送的内容..."
                      type="textarea">
            </el-input>
        </div>
        <div class="submit">
            <el-tooltip :disabled="!!selected.length" content="未选择课程,无法提交">
                <el-button type="primary"
                           size="small"
                           @click="handleConfirm"
                           :disabled="!selected.length"
                           :loading="confirmLoading">
                    提交
                </el-button>
            </el-tooltip>
        </div>
    </div>
</template>
<script>
import {defineComponent, getCurrentInstance, inject, onMounted, ref, toRefs, watch} from 'vue';
import dayjs from 'dayjs';
import {getClassDataListApi, stopClassApi} from '../../../api/class/classApi';

export default defineComponent({
    props: {classInfo: Object},
    emits: ['update:visible', 'updated'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const tableShow = ref({});

        const group = ref([]);

        const sendMessageToClient = ref(false);

        const reason = ref();

        const dateRange = ref();

        const selected = ref([]);

        const loading = ref(false);

        const countObject = ref({
            noClassesCount: 0,
            leaveOfAbsenceCount: 0,
            planCount: 0,
            reserveCount: 0,
            listCount: 0
        });

        const confirmLoading = ref(false);

        const pickerOptions1 = {
            disabledDate(time) {
                return time.getTime() <= Date.now();
            }
        };

        const getClassList = () => {
            loading.value = true;
            getClassDataListApi({
                startDate: dateRange.value[0] + ' 00:00:00.000',
                endDate: dateRange.value[1] + ' 23:59:59.999',
            }).then(res => {
                if (Array.isArray(res.data.data) && res.data.data.length) {
                    const dateMap = {};
                    for (let record of res.data.data) {
                        computeCount(record.students);
                        let list = dateMap[dayjs(record.beginTime).format('YYYY-MM-DD')];
                        if (!list) {
                            list = dateMap[dayjs(record.beginTime).format('YYYY-MM-DD')] = [];
                        }
                        list.push(formatClassItem(record));
                        if (!record.isOver) {
                            selected.value.push(record.id);
                        }
                    }
                    let sortedKeys = Object.keys(dateMap).sort((a, b) => {
                        return dayjs(a).toDate().getTime() - dayjs(b).toDate().getTime();
                    });
                    group.value = sortedKeys.map(key => {
                        tableShow.value[key] = true;
                        return {
                            date: key,
                            classList: dateMap[key]
                        };
                    });
                }
            }).finally(() => loading.value = false);
        };

        const formatClassItem = (classItem) => {
            return {
                id: classItem.id,
                beginTime: dayjs(classItem.beginTime).format('HH:mm') + '~' + dayjs(classItem.endTime).format('HH:mm'),
                name: classItem.name,
                classTypeName: classItem.classTypeName,
                roomNames: Array.isArray(classItem.classrooms) && classItem.classrooms.length ?
                    classItem.classrooms.map(item => item.name).join(',') : '',
                teacherNames: Array.isArray(classItem.mainTeachers) && classItem.mainTeachers.length ?
                    classItem.mainTeachers.map(item => item.name).join(',')
                    + (Array.isArray(classItem.assistTeachers) && classItem.assistTeachers.length ?
                        ',' + classItem.assistTeachers.map(item => item.name).join(',') : '')
                    : '',
                isOver: classItem.isOver
            };
        };

        const computeCount = (students) => {
            if (Array.isArray(students) && students.length) {
                for (let student of students) {
                    if (student.status === 'NoClasses' || student.status === 'Overdue') {
                        countObject.value.noClassesCount++;
                    } else if (student.status === 'LeaveOfAbsence' || student.status === 'LeaveOfAbsenceNoConsume') {
                        countObject.value.leaveOfAbsenceCount++;
                    }
                    if (student.courseType === 'Plan') {
                        countObject.value.planCount++;
                    } else if (student.courseType === 'Reserve') {
                        countObject.value.reserveCount++;
                    }
                    if (student.listId !== -1) {
                        countObject.value.listCount++;
                    }
                }
            }
        };

        const handleSelectAll = (list, val) => {
            if (val) {
                for (let item of list) {
                    if (!item.isOver && selected.value.indexOf(item.id) === -1) {
                        selected.value.push(item.id);
                    }
                }
            } else {
                for (let item of list) {
                    let indexOf = selected.value.indexOf(item.id);
                    if (indexOf !== -1) {
                        selected.value.splice(indexOf, 1);
                    }
                }
            }
        };

        const handleSelectChange = (row, val) => {
            if (val) {
                selected.value.push(row.id);
            } else {
                selected.value.splice(selected.value.indexOf(row.id), 1);
            }
        };

        const isChecked = (list) => {
            let result = true;
            for (let classItem of list) {
                if (!classItem.isOver && selected.value.indexOf(classItem.id) === -1) {
                    return false;
                }
            }
            return result;
        };

        const handleConfirm = () => {
            confirmLoading.value = true;
            stopClassApi({classIds: selected.value}).then(() => {
                emit('update:visible', false);
                emit('updated');
            }).finally(() => confirmLoading.value = false);
        };

        const handleOpenClose = (item) => {
            tableShow.value[item.date] = !tableShow.value[item.date];
            currentInstance.proxy.$forceUpdate();
        };

        onMounted(() => {
            if (props.classInfo) {
                group.value = [{
                    date: dayjs(props.classInfo.beginTime).format('YYYY-MM-DD'),
                    classList: [formatClassItem(props.classInfo)]
                }];
                computeCount(props.classInfo.students);
                selected.value = [props.classInfo.id];
            } else {
                dateRange.value = [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')];
            }
        });

        watch(dateRange, () => {
            getClassList();
        }, {deep: true});

        return {
            ...toRefs(props),
            tableShow,
            group,
            sendMessageToClient,
            reason,
            dateRange,
            selected,
            countObject,
            loading,
            confirmLoading,
            pickerOptions1,
            handleSelectAll,
            handleSelectChange,
            isChecked,
            handleConfirm,
            handleOpenClose,
            dayjs
        };
    }
});
</script>
<style lang="scss" scoped>
.content {
    padding: 0 15px;
    box-sizing: border-box;

    .date {
        font-size: 14px;
        color: #292e33;
    }

    .date_now {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        height: 40px;
        border: 1px solid #edf0f2;
        cursor: pointer;

        span {
            font-size: 14px;
            color: #292e33;
        }

        img {
            width: 15px;
            height: 15px;
        }
    }

    .stop_class_info {
        margin-top: 20px;

        .title {
            margin: 0 0 0 0;
            font-size: 14px;
            color: #292e33;
        }

        div {
            background: #f5f7fa;
            padding: 20px 30px;
            box-sizing: border-box;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 10px;

            span {
                font-size: 14px;
                color: #292e33;
            }
        }
    }
}

.stop_reason {
    padding: 0 15px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;

    .reason {
        margin-bottom: 10px;

        span {
            font-size: 16px;
            color: #292e33;
            margin-right: 5px;
        }
    }
}

.submit {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
