import {defineComponent} from "vue";

export default [
    {
        path: '',
        component: defineComponent({
            template:`<router-view></router-view>`
        }),
        children: [
            {
                path: '/HomeSchool/motionMange',
                component: () => import('@/views/homeSchool/MotionMange.vue'),
                meta: {title: '动态管理'},
            },
            {
                path: '/HomeSchool/motionCommentMange',
                component: () => import('@/views/homeSchool/MotionCommentMange.vue'),
                meta: {title: '评论管理'},
            },
        ],
        meta: {title: '动态'},
    },
    {
        path: '/HomeSchool/message',
        component: () => import('@/views/homeSchool/Message.vue'),
        meta: {title: '留言管理'},
    },
    {
        path: '/HomeSchool/myStudent',
        component: () => import('@/views/homeSchool/MyStudent.vue'),
        meta: {title: '我的学员'},
    },
    {
        path: '/HomeSchool/studentAllocate',
        component: () => import('@/views/homeSchool/StudentAllocate.vue'),
        meta: {title: '学员分配'},
    },
    {
        path: '/HomeSchool/motionStatisticians',
        component: () => import('@/views/homeSchool/MotionStatisticians.vue'),
        meta: {title: '动态统计'},
    },
    {
        path: '/HomeSchool/settings',
        component: () => import('@/views/homeSchool/Settings.vue'),
        meta: {title: '设置'},
    }
];

const meta = {
    name: '家校互通'
};

export {meta};