<template>
<tenant-user-select v-bind="$attrs"
                    :role="[fixedRole.Teacher.code,fixedRole.MasterTeacher.code]"
                    :value="value"
                    @change="(val,data) => $emit('change',val,data)"
                    @update:value="val=>$emit('update:value',val)"
                    :value-prop="useTeacherId?'teacherId':undefined"
                    :has-all-option="hasAllOption"
                    :multiple="multiple">
    <template v-slot="{options,valueProp}">
        <el-option v-for="option in options"
                   :key="option[valueProp]"
                   :value="option[valueProp]"
                   :label="option.name">
        </el-option>
    </template>
</tenant-user-select>
</template>
<script>
import TenantUserSelect from './TenantUserSelect.vue'
import {defineComponent, toRefs} from 'vue'
import {fixedRole} from '../../utils/const'

export default defineComponent({
    components: {TenantUserSelect},
    model: {
        prop: 'value',
        event: 'update:value'
    },
    emits: ['update:value', 'change'],
    props: {
        hasAllOption: Boolean,
        useTeacherId: {
            type: Boolean,
            default () {
                return false
            }
        },
        value: [Number, Array],
        multiple: {
            type: Boolean,
            default () {
                return false
            }
        },
    },
    setup (props) {
        return {
            ...toRefs(props),
            fixedRole
        }
    },
})
</script>
