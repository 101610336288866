import http from '../../utils/httpRequest'

export function getClassTypeListApi(params) {
    return http.get('/admin/balletclasstype/list', {params})
}

export function addClassTypeApi(data) {
    return http.post('/admin/balletclasstype/add', data)
}

export function updateClassTypeApi(data) {
    return http.put('/admin/balletclasstype/update', data)
}

export function updateEnableFlagApi(data) {
    return http.put("/admin/balletclasstype/updateEnableFlag", data)
}

export function deleteClassTypeApi(data) {
    return http.delete('/admin/balletclasstype/delete', {data})
}