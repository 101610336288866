const CENTER_OVERVIEW = 'centerOverview';

const MARKET_STATISTICS = 'marketStatistics';

const CLASS_STATISTICS = 'classStatistics';

const SALES_STATISTICS = 'salesStatistics';

const FOLLOWUP_STATISTICIANS = 'followUpStatisticians';

const CONTRACT_STATISTICS = 'contractStatistics';

const CONTRACT_STATISTICS_DETAIL = 'contractStatisticsDetail';

const CONTRACT_WATER_STATISTICS = 'contractWaterStatistics';

const STUDENT_STATISTICS = 'studentStatistics';

const STUDENT_CLASS_STATISTICS = 'studentClassStatistics';

const CONCURRENT_JOB_MANAGE = '/concurrentJobManage';

const EMPLOYEE = '/employee';

const EMPLOYEE_DETAIL = '/employeeDetail';

const CENTER_CONFIG = '/centerConfig';

const CENTER_STATISTIC = '/centerStatistic';

const PACKAGE_MANAGE = '/package';

const ROOM_MANAGE = '/room';

const CLASS_TYPE_MANAGE = '/classType';

const LIST_TRANSFER = '/listTransfer';

const RESCHEDULE_CLASS = '/rescheduleClass';

const CENTER_PERMISSION = '/centerPermission';

const CENTER_MENU_PERMISSION = '/centerMenuPermission';

const CENTER_STATISTIC_CENTER_OVERVIEW = CENTER_STATISTIC + '/' + CENTER_OVERVIEW;

const CENTER_STATISTIC_MARKET_STATISTICS = CENTER_STATISTIC + '/' + MARKET_STATISTICS;

const CENTER_STATISTIC_CLASS_STATISTICS = CENTER_STATISTIC + '/' + CLASS_STATISTICS;

const CENTER_STATISTIC_SALES_STATISTICS = CENTER_STATISTIC + '/' + SALES_STATISTICS;

const CENTER_STATISTIC_FOLLOWUP_STATISTICIANS = CENTER_STATISTIC + '/' + FOLLOWUP_STATISTICIANS;

const CENTER_STATISTIC_CONTRACT_STATISTICS = CENTER_STATISTIC + '/' + CONTRACT_STATISTICS;

const CENTER_STATISTIC_CONTRACT_STATISTICS_DETAIL = CENTER_STATISTIC + '/' + CONTRACT_STATISTICS_DETAIL;

const CENTER_STATISTIC_CONTRACT_WATER_STATISTICS = CENTER_STATISTIC + '/' + CONTRACT_WATER_STATISTICS;

const CENTER_STATISTIC_STUDENT_STATISTICS = CENTER_STATISTIC + '/' + STUDENT_STATISTICS;

const CENTER_STATISTIC_STUDENT_CLASS_STATISTICS = CENTER_STATISTIC + '/' + STUDENT_CLASS_STATISTICS;

export default {
    CENTER_OVERVIEW,
    MARKET_STATISTICS,
    CLASS_STATISTICS,
    SALES_STATISTICS,
    FOLLOWUP_STATISTICIANS,
    CONTRACT_STATISTICS,
    CONTRACT_STATISTICS_DETAIL,
    CONTRACT_WATER_STATISTICS,
    STUDENT_STATISTICS,
    STUDENT_CLASS_STATISTICS,
    CONCURRENT_JOB_MANAGE,
    EMPLOYEE,
    EMPLOYEE_DETAIL,
    CENTER_CONFIG,
    CENTER_STATISTIC,
    PACKAGE_MANAGE,
    ROOM_MANAGE,
    CLASS_TYPE_MANAGE,
    LIST_TRANSFER,
    RESCHEDULE_CLASS,
    CENTER_PERMISSION,
    CENTER_MENU_PERMISSION,
    CENTER_STATISTIC_CENTER_OVERVIEW,
    CENTER_STATISTIC_MARKET_STATISTICS,
    CENTER_STATISTIC_CLASS_STATISTICS,
    CENTER_STATISTIC_SALES_STATISTICS,
    CENTER_STATISTIC_FOLLOWUP_STATISTICIANS,
    CENTER_STATISTIC_CONTRACT_STATISTICS,
    CENTER_STATISTIC_CONTRACT_STATISTICS_DETAIL,
    CENTER_STATISTIC_CONTRACT_WATER_STATISTICS,
    CENTER_STATISTIC_STUDENT_STATISTICS,
    CENTER_STATISTIC_STUDENT_CLASS_STATISTICS
};
