<template>
    <div class="contractInfo_wrap" v-loading="loading===1">
        <div class="top_operation">
            <div class="filtrate">
                <span
                    :style="active===1?'border:1px solid #29cb97;color:#29cb97;':'border:1px solid #d8dee9;border-right:none;'"
                    @click="active=1">
                    按课类显示
                </span>
                <span
                    :style="active===2?'border:1px solid #29cb97;color:#29cb97;':'border:1px solid #d8dee9;border-left:none;'"
                    @click="active=2">
                    按课节显示
                </span>
            </div>
            <div v-if="active===2" class="time_filter">
                <p>已上<span>{{ classPage.signedCount }}</span>节/共<span>{{ classPage.total }}</span>节课</p>
                <el-date-picker
                    v-model="classPageQuery.dateRange"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    size="mini">
                </el-date-picker>
                <el-checkbox v-model="classPageQuery.showCanceled">显示取消的课节</el-checkbox>
            </div>
            <div class="status_box">
                <p v-for="item in statusData" :key="item.id">
                    <span :style="'background:'+item.color+';'"></span>{{ item.name }}</p>
            </div>
        </div>
        <div class="contract_content">
            <template v-if="active===1">
                <div class="gaine"
                     v-for="classType in classTypeRecords"
                     :key="classType.id">
                    <div class="gaine_left">
                        <el-tooltip class="item" effect="dark" content="深业上城校区-成人次卡 深业-零基础"
                                    placement="top">
                            <p class="left_title">深业上城校区-成人次卡 深业-零基础</p>
                        </el-tooltip>
                        <div class="attendance">
                            <p><span>出勤率:</span>&nbsp;&nbsp;100%</p>
                            <el-progress :percentage="100" :show-text="false"></el-progress>
                        </div>
                        <div class="type_box">
                            <p><span>已排课节:</span>1</p>
                            <p><span>剩余课节:</span>0</p>
                            <p><span>签到:</span>1</p>
                            <p><span>旷课:</span>0</p>
                            <p><span>请假:</span>0</p>
                            <p><span>排队:</span>0</p>
                        </div>
                        <!--                    <div class="info_box">
                                                <p><span>耗课数:</span>1</p>
                                                <p><span>日期:</span>2023-10-10~2023-12-26</p>
                                                <p><span>时间:</span>星期二 14:00</p>
                                                <p><span>老师:</span>黄震</p>
                                                <p><span>教室:</span>壹方城校区-舞蹈室1</p>
                                            </div>
                                            <div class="btn_box">
                                                <span>[调整课程]</span>
                                            </div>-->
                    </div>
                    <div class="gaine_right">
                        <div class="class_item class-item"
                             :class="[
                             'class-item',
                             {'signed':['SignedIn','SignedInLateness'].includes(record.studentClassStatus)},
                             {'lineup':record.studentClassStatus==='lineup'},
                             {'leave':['LeaveOfAbsence','LeaveOfAbsenceNoConsume'].includes(record.studentClassStatus)},
                             {'absenteeism':record.studentClassStatus==='Absenteeism'},
                             ]"
                             v-for="(record,index) in classType.classList"
                             :key="index"
                             @click="openClassDetailModal(record)">
                            <div class="class_content">
                                <div>
                                    <img src="../../../assets/img/time2.png" alt="">
                                    <span>{{ dayjs(record.classStartTime).format('YYYY-MM-DD') }}</span>
                                    <span>&nbsp;{{ getWeekDayDisplay(record.classStartTime) }} </span>
                                    <span>&nbsp;{{ dayjs(record.classStartTime).format('HH:mm') }} </span>
                                </div>
                                <div>
                                    <img src="../../../assets/img/user.png" alt="">
                                    <span>{{ record.teachers[0].name }}</span>
                                </div>
                                <div>
                                    <img src="../../../assets/img/address.png" alt="">
                                    <span>{{ record.classrooms[0].name }}</span>
                                </div>
                                <div class="show_info">
                                    <el-tooltip effect="dark" content="万豪-零基础进阶万豪校区-成人次卡万豪次卡"
                                                placement="top">
                                        <div slot="content">
                                            <div>
                                                <p style="margin:0;">
                                                    <span>合同名称: </span>
                                                    <span>{{ record.contractName }}</span>
                                                </p>
                                                <p style="margin:5px 0 0 0;">
                                                    <span>合同编号:</span>
                                                    <span>{{ record.contractCode }}</span>
                                                </p>
                                                <p style="margin:5px 0 0 0;">
                                                    <span>合同有效期:</span>
                                                    <span>
                                                        {{ record.contractStartDate }} ~ {{
                                                            record.contractEndDate
                                                        }}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="item-contract-info">
                                            <span>合同:</span>
                                            <change-contract-button
                                                :contract="{id:record.contractId,name: record.contractName}"
                                                :student-id="studentId"
                                                :student-class-id="record.studentClassId"
                                                style="white-space: normal">
                                            </change-contract-button>
                                        </div>
                                    </el-tooltip>
                                    <div class="item-contract-info">
                                        <span>实际耗课:</span>
                                        <span> {{ record.consumeClassCount }}</span>
                                        <span>
                                           [{{
                                                record.consumeClassType === 'Ordinary' ? '普通' : (record.consumeClassType === 'Special' ? '特殊' : '')
                                            }}]
                                        </span>
                                    </div>
                                </div>
                                <div class="class-tag"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="noHave" v-if="loading===2">
                    <i class="el-icon-loading"></i>
                </div>
                <div class="noHave" v-else-if="classPage.total<=classRecords.length">暂无更多数据</div>
            </template>
            <template v-if="active===2">
                <div class="class_show">
                    <!-- 按课节展示 的内容 -->
                    <div class="class_item class-item"
                         :class="[
                         'class-item',
                         {'signed':['SignedIn','SignedInLateness'].includes(record.studentClassStatus)},
                         {'lineup':record.studentClassStatus==='lineup'},
                         {'leave':['LeaveOfAbsence','LeaveOfAbsenceNoConsume'].includes(record.studentClassStatus)},
                         {'absenteeism':record.studentClassStatus==='Absenteeism'},
                         ]"
                         v-for="(record,index) in classRecords"
                         :key="index"
                         @click="openClassDetailModal(record)">
                        <div class="class_content">
                            <el-tooltip effect="dark"
                                        :content="`${record.className||''}/${record.classTypeName||''} ${record.childrenClassTypeName||''}`"
                                        placement="top">
                                <p class="class_show_title"
                                   style="white-space: nowrap;text-overflow:ellipsis;overflow: hidden;width: 100%;">
                                    <span style="font-size: inherit;">{{ record.className }}</span>
                                    <span>({{ record.classTypeName }}</span>
                                    <span>&nbsp;{{ record.childrenClassTypeName }})</span>
                                </p>
                            </el-tooltip>
                            <div>
                                <img src="../../../assets/img/time2.png" alt="">
                                <span>{{ dayjs(record.startTime).format('YYYY-MM-DD') }}</span>
                                <span>&nbsp;{{ getWeekDayDisplay(record.startTime) }} </span>
                                <span>&nbsp;{{ dayjs(record.startTime).format('HH:mm') }} </span>
                            </div>
                            <div>
                                <img src="../../../assets/img/user.png" alt="">
                                <span>{{ record.teachers[0].name }}</span>
                            </div>
                            <div>
                                <img src="../../../assets/img/address.png" alt="">
                                <span>{{ record.classrooms[0].name }}</span>
                            </div>
                            <div class="show_info">
                                <el-tooltip effect="dark" content="万豪-零基础进阶万豪校区-成人次卡万豪次卡"
                                            placement="top">
                                    <div slot="content">
                                        <div>
                                            <p style="margin:0;">
                                                <span>合同名称: </span>
                                                <span>{{ record.contractName }}</span>
                                            </p>
                                            <p style="margin:5px 0 0 0;">
                                                <span>合同编号:</span>
                                                <span>{{ record.contractCode }}</span>
                                            </p>
                                            <p style="margin:5px 0 0 0;">
                                                <span>合同有效期:</span>
                                                <span>
                                                    {{ record.contractStartDate }} ~ {{
                                                        record.contractEndDate
                                                    }}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="item-contract-info">
                                        <span>合同:</span>
                                        <change-contract-button
                                            :contract="{id:record.contractId,name: record.contractName}"
                                            :student-id="studentId"
                                            :student-class-id="record.studentClassId"
                                            style="white-space: normal">
                                        </change-contract-button>
                                    </div>
                                </el-tooltip>
                                <div class="item-contract-info">
                                    <span>实际耗课:</span>
                                    <span> {{ record.consumeClassCount }}</span>
                                    <span>
                                    [{{
                                            record.consumeClassType === 'Ordinary' ? '普通' : (record.consumeClassType === 'Special' ? '特殊' : '')
                                        }}]
                                </span>
                                </div>
                            </div>
                            <div class="class-tag"></div>
                        </div>
                    </div>
                </div>
                <div class="noHave" v-if="loading===2">
                    <i class="el-icon-loading"></i>
                </div>
                <div class="noHave" v-else-if="classPage.total<=classRecords.length">暂无更多数据</div>
            </template>
        </div>
        <class-detail-modal @refresh="handleClassRefresh"
                            :visible.sync="classDetailModalVisible"
                            :class-id="classId">
        </class-detail-modal>
    </div>
</template>
<script>
import {defineComponent, inject, onMounted, ref, toRefs, watch} from 'vue';
import {getDefaultPage} from '../../../utils';
import {getClassPageApi, getClassPageByClassTypeApi} from '../../../api/student/studentApi';
import dayjs from 'dayjs';
import {getWeekDayDisplay} from '../../../utils/dateUtils';
import {cloneObject} from '../../../utils/ObjectUtils';
import ChangeContractButton from '../../Class/detail/ChangeContractButton.vue';
import ClassDetailModal from '../../Class/ClassDetailModal.vue';

export default defineComponent({
    components: {ClassDetailModal, ChangeContractButton},
    props: {studentId: Number},
    setup(props) {

        const active = ref(1);

        const statusData = [
            {
                id: 1,
                name: '未上',
                color: '#b4bfcc',
            },
            {
                id: 2,
                name: '已签到',
                color: '#29cb97',
            },
            {
                id: 3,
                name: '排队',
                color: '#4fc3f7'
            },
            {
                id: 4,
                name: '已请假',
                color: '#faad14'
            },
            {
                id: 5,
                name: '旷课',
                color: '#f5222d'
            }
        ];

        const startTime = ref();

        const endTime = ref();

        const classPage = ref(getDefaultPage());

        const defaultClassQuery = {
            dateRange: [],
            showCanceled: false
        };

        const classPageQuery = ref(cloneObject(defaultClassQuery));

        const classRecords = ref([]);

        const classTypePage = ref(getDefaultPage());

        const classTypeRecords = ref([]);

        const classDetailModalVisible = ref(false);

        const classId = ref();

        const loading = ref(0);

        const refreshClassPage = () => {
            classPage.value = getDefaultPage();
            classPage.value.signedCount = 0;
            classRecords.value = [];
            getClassPage();
        };

        const getClassPage = () => {
            loading.value = true;
            getClassPageApi({
                studentId: props.studentId,
                startTime: Array.isArray(classPageQuery.value.dateRange) && classPageQuery.value.dateRange.length ?
                    dayjs(classPageQuery.value.dateRange[0]).format('YYYY-MM-DD') + ' 00:00:00.000' :
                    undefined,
                endTime: Array.isArray(classPageQuery.value.dateRange) && classPageQuery.value.dateRange.length ?
                    dayjs(classPageQuery.value.dateRange[1]).format('YYYY-MM-DD') + ' 23:59:59.999' :
                    undefined,
                showCanceled: classPageQuery.value.showCanceled,
                currentPage: classPage.value.currentPage,
                pageSize: classPage.value.pageSize,
                timestamp: classPage.value.timestamp,
            }).then(res => {
                if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                    for (let record of res.data.data.records) {
                        classRecords.value.push(record);
                    }
                    classPage.value.currentPage = res.data.data.current;
                    classPage.value.pageSize = res.data.data.pageSize;
                    classPage.value.total = res.data.data.total;
                    classPage.value.signedCount = res.data.data.signedCount;
                }

            }).finally(() => loading.value = false);

        };

        const refreshClassTypePage = () => {
            classTypePage.value = getDefaultPage();
            classTypeRecords.value = [];
            getClassTypePage();
        };

        const getClassTypePage = () => {
            getClassPageByClassTypeApi({
                studentId: props.studentId,
                currentPage: classTypePage.value.currentPage,
                pageSize: classTypePage.value.pageSize,
                timestamp: classTypePage.value.timestamp,
            }).then(res => {
                if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                    for (let record of res.data.data.records) {
                        classTypeRecords.value.push(record);
                    }
                    classTypePage.value.currentPage = res.data.data.current;
                    classTypePage.value.pageSize = res.data.data.pageSize;
                    classTypePage.value.total = res.data.data.total;
                }
            }).finally(() => {
                loading.value = 0;
            });
        };

        const refreshData = () => {
            if (active.value === 1) {
                loading.value = 1;
                refreshClassTypePage();
            } else if (active.value === 2) {
                loading.value = 1;
                classPageQuery.value = cloneObject(defaultClassQuery);
            }
        };

        const nextPage = () => {
            if (active.value === 1) {
                if (classTypePage.value.total <= classTypeRecords.value.length) {
                    return;
                }
                loading.value = 2;
                classTypePage.value.currentPage++;
                getClassTypePage();
            } else if (active.value === 2) {
                if (classPage.value.total <= classRecords.value.length) {
                    return;
                }
                loading.value = 2;
                classPage.value.currentPage++;
                getClassPage();
            }
        };

        const openClassDetailModal = (record) => {
            classDetailModalVisible.value = true;
            classId.value = record.classId;
        };

        const handleClassRefresh = () => {
            refreshClassPage();
        };

        onMounted(() => {
            refreshData();
            refreshClass();
        });

        const refreshClass = inject('refreshClass');

        watch(active, refreshData);

        watch(classPageQuery, refreshClassPage, {deep: true});

        return {
            ...toRefs(props),
            active,
            statusData,
            classPageQuery,
            startTime,
            endTime,
            classPage,
            classRecords,
            classTypePage,
            classTypeRecords,
            classDetailModalVisible,
            classId,
            loading,
            openClassDetailModal,
            refreshData,
            nextPage,
            handleClassRefresh,
            getWeekDayDisplay,
            dayjs
        };
    }
});
</script>

<style lang="scss" scoped>
.el-progress-bar__inner {
    background: linear-gradient(90deg, #72cf82, #55c3b3);
}

.contractInfo_wrap {
    padding: 10px 15px;
    box-sizing: border-box;
    background: #f5f7fa;

    .noHave {
        text-align: center;
        padding: 20px 0;
    }

    .top_operation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .filtrate {
            display: flex;
            align-items: center;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 95px;
                height: 30px;
                border: 1px solid #d8dee9;
                box-sizing: content-box;
                cursor: pointer;
                transition: all .3s;
            }

            span:nth-of-type(1) {
                border-radius: 4px 0 0 4px;
            }

            span:nth-of-type(2) {
                border-radius: 0 4px 4px 0;
            }

            span:hover {
                color: #29cb97;
            }
        }

        .status_box {
            display: flex;
            align-items: center;

            p + p {
                margin-left: 20px;
            }

            p {
                display: flex;
                align-items: center;

                span {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin-right: 5px;
                }
            }
        }

        .time_filter {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex: 1;

            > p {
                margin: 0;
                font-size: 14px;

                span {
                    color: #29cb97;
                }
            }

            .el-date-editor {
                width: 250px;
            }
        }
    }

    .contract_content {
        .gaine {
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: 14px;
            }

            .gaine_left {
                background: #fff;
                // height: 425px;
                flex: 0 0 320px;
                //阴影
                box-shadow: 0 0 5px #ccc;
                padding: 15px;
                box-sizing: border-box;

                .left_title {
                    font-size: 18px;
                    color: #29cb97;
                    margin: 0;
                    margin-bottom: 20px;
                }

                .attendance {
                    margin-bottom: 10px;

                    p {
                        margin: 0;
                        margin-bottom: 10px;
                        color: #292e33;

                        span {
                            color: #b4bfcc;
                        }
                    }
                }

                .type_box {
                    display: flex;
                    flex-wrap: wrap;
                    border-bottom: 1px solid #edf0f2;
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    box-sizing: border-box;

                    p:nth-child(n+3) {
                        margin-top: 10px;
                    }

                    p:nth-child(3) {
                        color: #29cb97;
                    }

                    p:nth-child(4) {
                        color: #f00;
                    }

                    p:nth-child(5) {
                        color: #faad14;
                    }

                    p:nth-child(6) {
                        color: #55c3b3;
                    }

                    p {
                        width: 50%;
                        // border: 1px solid #f00;
                        margin: 0;

                        span {
                            // letter-spacing: 3px;
                            color: #b4bfcc;
                            margin-right: 5px;
                        }
                    }
                }

                .info_box {
                    border-bottom: 1px solid #edf0f2;
                    padding-bottom: 15px;

                    p + p {
                        margin-top: 15px;
                    }

                    p {
                        margin: 0;

                        span {
                            color: #b4bfcc;
                            margin-right: 5px;
                        }
                    }
                }

                .btn_box {
                    height: 30px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;

                    span {
                        color: #29cb97;
                        cursor: pointer;
                    }
                }
            }

            .gaine_right {
                flex: 1;
                background: #e9e9e9;
                padding: 10px;
                box-sizing: border-box;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 10px;

                > div {
                    background: #fff;
                    border-radius: 5px;
                    display: flex;
                    overflow: hidden;

                    em {
                        font-style: normal;
                        background: #29cb97;
                        height: 100%;
                        flex: 0 0 20px;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .font {
                        flex: 1;
                        padding: 0 10px;
                        box-sizing: border-box;

                        .font_title {
                            width: 100%;
                            margin: 0;
                            height: 30px;
                            border-bottom: 1px dashed #d8dee9;
                            // text-align: center;
                            line-height: 30px;
                        }

                        > div {
                            display: flex;
                            align-items: center;

                            p {
                                margin: 0;
                                width: 150px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                margin: 8px 0;
                                font-size: 12px;

                                span {
                                    color: #818c99;
                                }
                            }

                            img {
                                width: 14px;
                                height: 14px;
                                cursor: pointer;
                                margin-left: 20px;
                            }
                        }

                        > p {
                            margin: 0;
                            font-size: 12px;

                            span {
                                color: #818c99;
                            }
                        }
                    }
                }
            }
        }

        .class_show {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;
        }
    }

}

.class_item {
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    overflow: hidden;
    display: flex;
    cursor: pointer;
    background: #fff;
    color: #292e33;
    font-size: 14px;
    position: relative;
    padding-left: 10px;

    .classState2 {
        background: rgb(41, 203, 151);
    }

    .classState3 {
        background: rgb(79, 195, 247);
    }

    .classState4 {
        background: rgb(250, 173, 20);
    }

    .classState6 {
        background: rgb(245, 34, 45);
    }

    .class_content {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;

        .class_show_title {
            height: 30px;
            width: 100%;
            font-size: 14px;
            color: #29cb97;
            line-height: 30px;
            border-bottom: 1px solid #edf0f2;
            margin: 0;

            span {
                font-size: 10px;
                color: #29cb97;
            }
        }

        > div {
            display: flex;
            align-items: center;
            margin: 5px 0;

            > img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }

        .show_info {
            font-size: 12px;
            display: block;
            border-top: 1px dashed #edf0f2;
            box-sizing: border-box;

            .item-contract-info {
                margin: 0;
                width: 210px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: flex;
            }

            .item-contract-info > *:first-child {
                color: #818c99;
                margin-right: 5px;
            }

            .item-contract-info > img {
                width: 14px;
                height: 14px;
                margin-left: 10px;
                cursor: pointer;
            }


            > p {
                margin: 0;
                margin-top: 5px;
            }
        }
    }

    .class-tag {
        padding-top: 5px;
        height: 25px;

    }
}
</style>
<style lang="scss" scoped>
@import "../../../assets/scss/variables";

.class-item::before {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
    background: #ccc;
}

.class-item.signed::before {
    background: $--color-primary;
}

.class-item.lineup::before {
    background: #4fc3f7;
}

.class-item.leave::before {
    background: #faad14;
}

.class-item.absenteeism::before {
    background: #f5222d;
}
</style>
