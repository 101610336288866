<template>
    <div class="all_class">
        <div class="all_class_top" v-loading="classFatherLoading">
            <div class="all_class_header">
                <p class="primary-text-color">
                    <span>{{ classFather.className }}</span>
                    <span>({{ classFather.classTypeName }} {{ classFather.childrenClassTypeName }})</span>
                </p>
                <div>
                    <el-tooltip effect="dark" content="总课程操作记录" placement="top">
                        <img src="../../../assets/img/lishi.png"
                             alt=""
                             @click="classFatherOperationRecordModalVisible=true">
                    </el-tooltip>
                    <el-tooltip v-if="classFather.isLock" effect="dark" content="解锁该课程" placement="top">
                        <img @click="handleLockUnlock" src="../../../assets/img/lock2.png" alt="">
                    </el-tooltip>
                    <el-tooltip v-else effect="dark" content="锁定该课程" placement="top">
                        <img @click="handleLockUnlock" src="../../../assets/img/lock.png" alt="">
                    </el-tooltip>
                    <el-tooltip effect="dark" content="编辑课程" placement="top">
                        <img src="../../../assets/img/edit-cc.png"
                             alt=""
                             @click="updateTotalClassModalVisible=true">
                    </el-tooltip>
                </div>
            </div>
            <div class="time-or-num">
                <div class="class-date">
                    <span>{{ dayjs(classFather.startDate).format("YYYY/MM/DD") }}</span>
                    <span>~</span>
                    <span>{{ dayjs(classFather.endDate).format("YYYY/MM/DD") }}</span>
                </div>
                <span>课程容量{{ classFather.classCapacity }}人</span>
                <el-button type="text"
                           class="update-capacity-button"
                           @click="updateTotalClassCapacityModalVisible=true">
                    修改课程容量
                </el-button>
            </div>
            <div class="progress">
                <p>出勤率: {{ classFather.attendanceRate }}%</p>
                <el-progress
                    :percentage="classFather.attendanceRate"
                    color="linear-gradient(to right, #79d087, #55c3b4)"
                    :show-text="false">
                </el-progress>
            </div>
            <div class="progress">
                <p>排课率: {{ classFather.planClassRate }}%</p>
                <el-progress :percentage="classFather.planClassRate" :show-text="false"></el-progress>
            </div>
            <div class="progress">
                <p>课程进度: {{ classFather.classSpeedRate }}%</p>
                <el-progress :percentage="classFather.classSpeedRate" :show-text="false"></el-progress>
                <span>{{ classFather.overClassCount }}/{{ classFather.totalClassCount }}</span>
            </div>
        </div>
        <div class="all_class_down" v-loading="classChildrenListLoading">
            <img class="add" src="../../../assets/img/jiahao3.png" @click="addChildClassModalVisible=true" alt="">
            <div class="month">
                <img src="../../../assets/img/left_green.png" @click="currentDay=currentDay.add(-1,'month')" alt=""/>
                <span>{{ currentDay.$y }}年{{ currentDay.$M + 1 }}月</span>
                <img src="../../../assets/img/right_green.png" @click="currentDay=currentDay.add(1,'month')" alt=""/>
            </div>
            <div class="child_class" v-for="classChildren in classChildrenList" :key="classChildren.id">
                <div class="child_class_header">
                    <span>子课程标题: {{ classChildren.name }}</span>
                    <div>
                        <el-tooltip effect="dark" content="子课程操作记录" placement="top">
                            <img src="../../../assets/img/lishi.png"
                                 @click="classChildrenOperationRecordModalVisible=true;classChildrenId=classChildren.id"
                                 alt="">
                        </el-tooltip>
                        <el-tooltip effect="dark" content="修改子课程" placement="top">
                            <img src="../../../assets/img/edit-cc.png"
                                 @click="updateChildrenClassModalVisible=true;currentClassChildren=classChildren"
                                 alt="">
                        </el-tooltip>
                    </div>
                </div>
                <div class="child_class_main">
                    <p>上课日期: <span>{{ classChildren.startDate }} ~ {{ classChildren.endDate }} 每{{
                            getWeekDayDisplay(classChildren.cycleValue, 1)
                        }} {{ classChildren.startTime }} ~ {{ classChildren.endTime }}</span></p>
                    <div>
                        <p>耗课数: {{ classChildren.consumeClassCount }}</p>
                        <p>课节数: {{ classChildren.classCount }}</p>
                        <p>教室: {{
                                Array.isArray(classChildren.classrooms) && classChildren.classrooms.length ? classChildren.classrooms.map(item => item.name).join(',') : ''
                            }}
                        </p>
                        <p>老师: {{
                                Array.isArray(classChildren.mainTeachers) && classChildren.mainTeachers.length ? classChildren.mainTeachers.map(item => item.name).join(',') : ''
                            }}
                        </p>
                    </div>
                </div>
                <div class="child_class_footer">
                    <div :class="{past:classInfo._beforeToday}"
                         v-for="(classInfo,index) in classChildren.classList" :key="index">
                        <span>{{ dayjs(classInfo.startDate).format('MM月DD日') }}</span>
                        <template v-if="classInfo._beforeToday">
                            <p>出勤人数 <em :class="{'fail-text-color':classInfo._planClassCount===0}">{{
                                    classInfo._signInCount
                                }}</em>/{{ classFather.classCapacity }}</p>
                            <p v-if="classInfo._leaveOfAbsenceCount">请假人数 <em
                                class="fail-text-color">{{ classInfo._leaveOfAbsenceCount }}</em></p>
                        </template>
                        <template v-else>
                            <p>排课人数 <em :class="{'fail-text-color':classInfo._planClassCount===0}">{{
                                    classInfo._planClassCount
                                }}</em>/{{ classFather.classCapacity }}</p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <class-father-operation-record :class-father-id="classFather.id"
                                       :visible.sync="classFatherOperationRecordModalVisible">
        </class-father-operation-record>
        <class-children-operation-record :class-children-id="classChildrenId"
                                         :visible.sync="classChildrenOperationRecordModalVisible"
                                         @ok="handleRefresh">
        </class-children-operation-record>
        <update-total-class-modal :visible.sync="updateTotalClassModalVisible"
                                  :data="classFather"
                                  @ok="handleRefresh"
                                  @delete="handleDelete">
        </update-total-class-modal>
        <update-total-class-capacity-modal :visible.sync="updateTotalClassCapacityModalVisible"
                                           :data="classFather"
                                           @ok="handleRefresh">
        </update-total-class-capacity-modal>
        <!-- 添加子课程 -->
        <add-child-class-modal
            :visible.sync="addChildClassModalVisible"
            :data="classFather"
            @ok="handleRefresh">
        </add-child-class-modal>
        <update-children-class-modal :visible.sync="updateChildrenClassModalVisible"
                                     :data="currentClassChildren">
        </update-children-class-modal>
    </div>
</template>
<script>
import {defineComponent, getCurrentInstance, inject, onMounted, ref, watch} from "vue";
import {getClassChildrenListApi, getClassFatherApi, lockTotalClassApi} from "@/api/class/classApi";
import dayjs from "dayjs";
import AddChildClassModal from "@/components/Class/detail/AddChildClassModal.vue";
import ClassFatherOperationRecord from "@/components/Class/detail/ClassFatherOperationRecord.vue";
import UpdateChildrenClassModal from "@/components/Class/detail/UpdateChildrenClass.vue";
import {cloneObject} from "@/utils/ObjectUtils";
import ClassChildrenOperationRecord from "@/components/Class/detail/ClassChildrenOperationRecord.vue";
import UpdateTotalClassModal from "@/components/Class/detail/UpdateTotalClassModal.vue";
import UpdateTotalClassCapacityModal from "@/components/Class/detail/UpdateTotalClassCapacityModal.vue";
import {getWeekDayDisplay} from "../../../utils/dateUtils";

export default defineComponent({
    methods: {getWeekDayDisplay},
    components: {
        UpdateTotalClassCapacityModal,
        UpdateTotalClassModal,
        ClassChildrenOperationRecord,
        UpdateChildrenClassModal,
        ClassFatherOperationRecord,
        AddChildClassModal
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    emits: ['update:visible', 'delete'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const currentDay = ref(dayjs());

        const classFatherLoading = ref(false);

        const classChildrenListLoading = ref(false);

        const classFather = ref({});

        const classChildrenList = ref([]);

        const updateTotalClassModalVisible = ref(false);

        const updateTotalClassCapacityModalVisible = ref(false);

        const updateChildrenClassModalVisible = ref(false);

        const addChildClassModalVisible = ref(false);

        const classFatherOperationRecordModalVisible = ref(false);

        const classChildrenOperationRecordModalVisible = ref(false);

        const classChildrenId = ref();

        const currentClassChildren = ref();

        const getClassFather = () => {
            classFatherLoading.value = true;
            getClassFatherApi(props.data.id).then(res => {
                classFather.value = res.data.data;
            }).finally(() => classFatherLoading.value = false);
        };

        const getClassChildrenList = () => {
            classChildrenListLoading.value = true;
            getClassChildrenListApi({
                classId: props.data.id,
                startTime: currentDay.value.startOf('month').format('YYYY-MM-DD' + ' 00:00:00.000'),
                endTime: currentDay.value.endOf('month').format('YYYY-MM-DD' + ' 23:59:59.999')
            }).then(res => {
                if (Array.isArray(res.data.data) && res.data.data.length) {
                    const data = res.data.data;
                    for (let classChildren of data) {
                        if (Array.isArray(classChildren.classList) && classChildren.classList) {
                            for (let classInfo of classChildren.classList) {
                                let leaveOfAbsenceCount = 0;
                                let signInCount = 0;
                                let planClassCount = 0;
                                if (classInfo.attendanceInfo && typeof classInfo.attendanceInfo === 'object') {
                                    if (typeof classInfo.attendanceInfo.LeaveOfAbsence === 'number') {
                                        leaveOfAbsenceCount += classInfo.attendanceInfo.LeaveOfAbsence;
                                    }
                                    if (typeof classInfo.attendanceInfo.LeaveOfAbsenceNoConsume === 'number') {
                                        leaveOfAbsenceCount += classInfo.attendanceInfo.LeaveOfAbsenceNoConsume;
                                    }
                                    if (typeof classInfo.attendanceInfo.SignedInLateness === 'number') {
                                        signInCount += classInfo.attendanceInfo.SignedInLateness;
                                    }
                                    if (typeof classInfo.attendanceInfo.SignedIn === 'number') {
                                        signInCount += classInfo.attendanceInfo.SignedIn;
                                    }
                                    Object.keys(classInfo.attendanceInfo).filter(key => key !== 'Canceled').forEach(key => planClassCount += classInfo.attendanceInfo[key]);
                                }
                                classInfo._beforeToday = dayjs().isAfter(classInfo.startDate);
                                classInfo._leaveOfAbsenceCount = leaveOfAbsenceCount;
                                classInfo._signInCount = signInCount;
                                classInfo._planClassCount = planClassCount;
                            }
                        }
                    }
                    classChildrenList.value = data;
                } else {
                    classChildrenList.value = [];
                }

            }).finally(() => classChildrenListLoading.value = false);
        };

        const handleLockUnlock = () => {
            currentInstance.proxy.$confirm(`确认要 ${classFather.value.isLock ? '解锁' : '锁定'} 课程？`, {
                type: "warning",
                showClose: false,
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        lockTotalClassApi({
                            classFatherId: classFather.value.id,
                            type: classFather.value.isLock ? 0 : 1
                        }).then(() => {
                            classFather.value.isLock = !classFather.value.isLock;
                            done();
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            });
        };

        const handleRefresh = () => {
            getClassFather();
            getClassChildrenList();
        };

        const handleDelete = () => {
            emit('update:visible', false);
            handleClassDelete();
        };

        onMounted(() => {
            handleRefresh();
        });

        const handleClassDelete = inject('handleClassDelete');

        watch(currentDay, () => {
            getClassChildrenList();
        });

        return {
            currentDay,
            classFatherLoading,
            classChildrenListLoading,
            classFather,
            classChildrenList,
            updateTotalClassModalVisible,
            updateTotalClassCapacityModalVisible,
            updateChildrenClassModalVisible,
            addChildClassModalVisible,
            classFatherOperationRecordModalVisible,
            classChildrenOperationRecordModalVisible,
            classChildrenId,
            currentClassChildren,
            handleLockUnlock,
            handleRefresh,
            handleDelete,
            dayjs
        };
    }
});
</script>
<style lang="scss" scoped>
.all_class {
    padding: 15px;
    box-sizing: border-box;

    .all_class_top {
        padding: 10px;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0 0 3px #ccc;

        > .all_class_header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > p {
                margin: 0;

                > span:first-child {
                    font-size: 16px;
                }

                > span:last-child {
                    font-size: 11px;
                }
            }

            > div {
                display: flex;
                align-content: center;

                > img + img {
                    margin-left: 10px;
                }

                > img {
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                }
            }
        }

        > .time-or-num {
            font-size: 14px;
            color: #292e33;
            display: flex;
            gap: 12px;

            .class-date {
                display: flex;
                gap: 5px;
            }

            .update-capacity-button {
                color: #faad14;
                padding: 0;
                margin: 0;
            }
        }

        > .progress {
            display: flex;
            align-items: center;

            > p {
                margin: 0;
                font-size: 14px;
                flex: 0 0 125px;
                text-align: right;
                margin-right: 20px;
                color: #292e33;
            }

            .el-progress {
                width: 455px;
            }

            > span {
                font-size: 14px;
                color: #292e33;
                margin-left: 10px;
            }
        }
    }

    .all_class_down {
        background: #f5f7fa;
        padding: 10px;
        box-sizing: border-box;
        position: relative;

        > .add {
            position: absolute;
            width: 15px;
            height: 15px;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }

        > .month {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 120px;
            margin: 10px auto;

            > img {
                width: 15px;
                height: 15px;
                position: relative;
                top: -1px;
                cursor: pointer;
            }

            > span {
                color: #29cb97;
            }
        }

        .child_class {
            box-shadow: 0 0 3px #ccc;
            background: #fff;

            &:not(:first-child) {
                margin-top: 20px;
            }

            .child_class_header {
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
                box-sizing: border-box;
                border-bottom: 1px solid #e5e5e5;

                span {
                    font-size: 16px;
                    color: #818c99;
                }

                > div {
                    display: flex;
                    align-items: center;

                    > img + img {
                        margin-left: 10px;
                    }

                    > img {
                        width: 18px;
                        height: 18px;
                        cursor: pointer;
                    }
                }
            }

            .child_class_main {
                padding: 10px;
                box-sizing: border-box;

                > p {
                    font-size: 14px;
                    color: #818c99;
                    margin: 0;

                    span {
                        color: #292e33;
                    }
                }

                > div {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);

                    > p {
                        font-size: 14px;
                        color: #818c99;
                        margin: 0;

                        span {
                            color: #292e33;
                        }
                    }
                }
            }

            .child_class_footer {
                background: #f5f7fa;
                padding: 0 10px 10px 10px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;

                > div {
                    margin-top: 10px;
                    height: 100px;
                    width: 150px;
                    background: #fff;
                    border-radius: 5px;
                    box-shadow: 0 0 3px #ccc;
                    padding: 5px;
                    box-sizing: border-box;

                    span {
                        font-size: 14px;
                        color: #818c99;
                    }

                    p {
                        margin: 0;
                        font-size: 14px;
                        color: #292e33;

                        em {
                            font-style: normal;
                        }
                    }

                    &.past {
                        background-color: #edf0f2;
                    }
                }
            }
        }
    }
}
</style>