import {transferStoreCountApi} from '@/api/student/listTransferApi';
import Vue from 'vue';
import {cloneObject} from '@/utils/ObjectUtils';

export function initListDetailData (data) {
    const clonedObject = cloneObject(data);
    if (!Array.isArray(clonedObject.contactsList) || !clonedObject.contactsList.length) {
        clonedObject.contactsList = [{}];
    }
    if (!Array.isArray(clonedObject.students) || !clonedObject.students.length) {
        clonedObject.students = [{}];
    }
    if (Array.isArray(clonedObject.sources)) {
        clonedObject.sourceIds = clonedObject.sources.map(s => s.id);
    } else {
        clonedObject.sources = [];
        clonedObject.sourceIds = [];
    }
    if (!clonedObject.purchaseOrderTime) {
        clonedObject.purchaseOrderTime = '';
    }
    if (!clonedObject.lastPurchaseOrderTime) {
        clonedObject.lastPurchaseOrderTime = '';
    }
    return clonedObject;
}

export function confirmUpdateTransferType (ids, transferType, promiseFun) {
    return transferStoreCountApi(ids).then((res) => {
        setTimeout(() => {
            Vue.prototype.$confirm(
                transferType === 'FullTransferOut' ?
                    '点击确定后名单将在名单管理中不可见、名单顾问/TMK关系清除、未来的课位及预约到访全部取消且不可恢复。' :
                    '点击确定后名单将在名单管理中可见并可重新分配进行跟进操作。',
                {
                    title: `名单共转出${res.data.data}个中心，将会同步更改转出类型为[${transferType === 'FullTransferOut' ? '完全转出' : '复制转出'}]`,
                    type: 'warning',
                    showClose: false,
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            promiseFun().then(() => {
                                done();
                            }).finally(() => instance.confirmButtonLoading = false);
                        } else {
                            done();
                            instance.confirmButtonLoading = false;
                        }
                    }
                }
            );
        });
    });
}
