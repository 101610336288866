import http from '../../utils/httpRequest';

export function getProfileApi() {
    return http.get('/system/tenantProfile');
}

export function getClientProfileApi() {
    return http.get('/system/clientProfile');
}

export function addSourceChannelApi(data) {
    return http.post('/system/addSourceChannel', data);
}

export function deleteSourceChannelApi(id) {
    return http.delete(`/system/deleteSourceChannel/${id}`);
}

export function updateSystemConfigApi(data) {
    return http.put('/system/updateTenantSystemConfig', data);
}

export function updateClientSystemConfigApi(data) {
    return http.put('/system/updateClientSystemConfig', data);
}

export function resetSourceChannelApi(key) {
    return http.put(`/system/resetTenantSystemConfig?key=${key}`,);
}

export function getConfigOperationRecordsByKetApi(query) {
    return http.get(`/system/tenantSystemConfigOperationLog/page?${query}`);
}

export function addConfigFollowStatusApi2(data) {
    return http.post('/system/addFollowUpStatus', data);
}

export function addConfigFollowStatusApi(data) {
    return http.post('/system/addFollowUpStatus1', data);
}

export function deleteConfigFollowStatusApi(id) {
    return http.delete(`/system/deleteFollowUpStatus/${id}`);
}

export function updateConfigFollowStatusApi(data) {
    return http.put('/system/updateFollowUpStatus', data);
}


export function createVipTagGroupApi(data) {
    return http.post('/system/addVipTagGroup', data);
}

export function createVipTagApi(data) {
    return http.post('/system/addVipTag', data);
}

export function deleteVipTagApi(data) {
    return http.delete(`/system/deleteVipTag/${data}`);
}

export function updateVipTagGroupApi(data) {
    return http.put('/system/updateVipTagGroup', data);
}

export function updateVipTagApi(data) {
    return http.put('/system/updateVipTag', data);
}

export function createFollowUpMethodApi(data) {
    return http.post('/system/addFollowUpMethod', data);
}

export function updateFollowUpMethodApi(data) {
    return http.put('/system/updateFollowUpMethod', data);
}

export function deleteFollowUpMethodApi(id) {
    return http.delete(`/system/deleteFollowUpMethod/${id}`);
}

export function getSumNumberApi(params) {
    return http.get('/admin/home/totals', {params});
}

export function createListAbandonReasonApi(data) {
    return http.post('/system/addListGiveUpReason', data);
}

export function updateListAbandonReasonApi(data) {
    return http.put('/system/updateListGiveUpReason', data);
}

export function deleteListAbandonReasonApi(id) {
    return http.delete(`/system/deleteListGiveUpReason/${id}`);
}

export function createListStatusChangeReasonApi(data) {
    return http.post('/system/addListStatusChangeReason', data);
}

export function updateListStatusChangeReasonApi(data) {
    return http.put('/system/updateListStatusChangeReason', data);
}

export function deleteListStatusChangeReasonApi(id) {
    return http.delete(`/system/deleteListStatusChangeReason/${id}`);
}

export function createPayMethodApi(data) {
    return http.post('/system/payMethod', data);
}

export function updatePayMethodApi(data) {
    return http.put('/system/payMethod', data);
}

export function deletePayMethodApi(data) {
    return http.delete(`/system/payMethod`, {data});
}

