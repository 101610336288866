<template>
<el-dialog custom-modal
           append-to-body
           title="编辑名单信息"
           width="37%"
           :visible="visible"
           :close-on-click-modal="false"
           @update:visible="val=>$emit('update:visible',val)">
    <el-form ref="formRef" :model="formData" label-position="top" size="mini">
        <div class="form-line" v-for="(contact,index) in formData.contactList">
            <el-form-item>
                <template v-slot:label>
                    <span style="vertical-align: middle;">家长姓名</span>
                    <img class="contact-button"
                         src="../../../assets/img/jiahao2.png"
                         @click="addContact"
                         v-if="index===0"
                         alt="">
                </template>
                <el-input v-model="contact.name" :maxlength="100" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label=" ">
                <el-select v-model="contact.affiliation" style="width:110px;" filterable placeholder="请选择">
                    <el-option
                        v-for="item in $store.state.common.relationList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" ">
                <div style="display: flex;gap: 8px;">
                    <el-select :disabled="index===0" v-model="contact.phoneNumberArea" style="width: 70px;">
                        <el-option v-for="phoneNumberArea in $store.getters['common/phoneNumberAreas']"
                                   v-bind="phoneNumberArea"
                                   :key="phoneNumberArea.value">
                        </el-option>
                    </el-select>
                    <el-input :disabled="index===0"
                              placeholder="电话"
                              v-model="contact.phoneNumber"
                              style="width: 120px">
                        <template v-slot:prepend>
                            <span>{{ contact.phoneNumberArea ? '+' + contact.phoneNumberArea : '' }}</span>
                        </template>
                    </el-input>
                </div>
            </el-form-item>
            <el-form-item label=" ">
                <el-input v-model="contact.wechatName"
                          placeholder="微信昵称"
                          style="width: 120px;"
                          :maxlength="100"
                          show-word-limit>
                </el-input>
            </el-form-item>
            <div style="display: flex;align-items: end;">
                <div style="display: flex;align-items: center;gap: 5px;margin-bottom: 18px;" v-if="index===0">
                    <span>(主)</span>
                    <i class="el-icon-edit-outline primary-text-color"
                       style="cursor: pointer;"
                       @click="updateListMainContactPhoneNumberModalVisible=true;$emit('update:visible',false);">
                    </i>
                </div>
                <div style="display: flex;align-items: center;gap: 5px;margin-bottom: 18px;" v-else>
                    <span>(副)</span>
                    <img src="../../../assets/img/jianhao.png"
                         style="width:15px;height:15px;cursor:pointer;"
                         alt=""
                         @click="formData.contactList.splice(index,1)">
                </div>
            </div>
        </div>
        <div class="form-line">
            <el-form-item label="家庭地址">
                <el-input :maxlength="100" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="所在小区">
                <el-input :maxlength="100" show-word-limit></el-input>
            </el-form-item>
        </div>
    </el-form>
    <div style="text-align: right">
        <el-button type="text" class="under-line" style="color: #f5222d;" @click="handleDelete">删除此名单</el-button>
    </div>
    <template v-slot:footer>
        <el-button @click="$emit('update:visible',false)">取消</el-button>
        <el-button type="primary" @click="handleConfirm" :loading="confirmLoading">确定</el-button>
    </template>
    <update-list-main-contact-phone-number-modal :visible.sync="updateListMainContactPhoneNumberModalVisible"
                                                 :list-id="list.id"
                                                 :phone-number="formData.contactList[0].phoneNumber"
                                                 :phone-number-area="formData.contactList[0].phoneNumberArea"
                                                 @ok="$emit('updated')">
    </update-list-main-contact-phone-number-modal>
</el-dialog>
</template>
<script>
import {defineComponent, toRefs, ref, getCurrentInstance, watch, h} from 'vue';
import {cloneObject, copyPropertyTo} from '@/utils/ObjectUtils';
import UpdateListMainContactPhoneNumberModal from '@/components/List/detail/UpdateListMainContactPhoneNumberModal.vue';
import {deleteListApi, updateListApi} from '@/api/student/listApi';

export default defineComponent({
    components: {UpdateListMainContactPhoneNumberModal},
    props: {
        visible: Boolean,
        list: {
            type: Object,
            default () {
                return {};
            }
        }
    },
    emits: ['update:visible', 'updated', 'delete'],
    setup (props, {emit}) {

        const currentInstance = getCurrentInstance();

        const getDefaultContact = () => {
            return {
                name: undefined,
                phoneNumber: undefined,
                wechat: undefined,
                affiliation: 1,
                phoneNumberArea: currentInstance.proxy.$store.getters['common/defaultPhoneNumberArea'],
                wechatName: undefined
            };
        };

        const updateListMainContactPhoneNumberModalVisible = ref(false);

        const formData = ref({
            contactList: [getDefaultContact()]
        });

        const formRef = ref();

        const confirmLoading = ref(false);

        const deleteStudentMessageVNode = h('div', {
            style: {
                color: '#f5222d',
                fontWeight: 700
            }
        }, '删除数据无法恢复，且相关数据无法查询。请确认是否删除?');

        const addContact = () => {
            if (formData.value.contactList.length >= 50) {
                currentInstance.proxy.$notify.warning('最多50个联系人');
                return;
            }
            formData.value.contactList.push(getDefaultContact());
        };

        const handleConfirm = () => {
            confirmLoading.value = true;
            formRef.value.validate().then(() => updateListApi({
                id: props.list.id,
                ...formData.value,
                contactList: formData.value.contactList.slice(1),
                mainContact: formData.value.contactList[0]
            })).then(() => {
                emit('updated');
                emit('update:visible', false);
            }).finally(() => confirmLoading.value = false);
        };

        const handleDelete = () => {
            currentInstance.proxy.$confirm(deleteStudentMessageVNode, {
                type: 'warning',
                showClose: false,
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        deleteListApi(props.list.id).then(() => {
                            setTimeout(() => {
                                emit('update:visible', false);
                                emit('delete');
                            });
                            done();
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                    }
                }
            });
        };

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value = {
                    contactList: [
                        {
                            name: undefined,
                            phoneNumber: undefined,
                            affiliation: undefined,
                            phoneNumberArea: undefined,
                            wechatName: undefined
                        }
                    ]
                };
                formData.value.homeAddress = props.list.homeAddress;
                formData.value.distinguish = props.list.distinguish;
                if (Array.isArray(props.list.contactsList) && props.list.contactsList.length) {
                    const contactList = cloneObject(props.list.contactsList);
                    contactList.sort((a, b) => {
                        if (b.isHost) {
                            return -1;
                        }
                        return 0;
                    });
                    formData.value.contactList.push({});
                    formData.value.contactList.splice(1, 1);
                    setTimeout(() => {
                        contactList.forEach((contact, index) => {
                            if (!formData.value.contactList[index]) {
                                formData.value.contactList[index] = {};
                            }
                            copyPropertyTo(formData.value.contactList[index], {
                                id: contact.id,
                                name: contact.name,
                                phoneNumber: contact.phone,
                                affiliation: contact.affiliation,
                                phoneNumberArea: contact.phoneArea,
                                wechatName: contact.wechatName
                            });
                        });
                    });
                }
            }
        });

        return {
            ...toRefs(props),
            updateListMainContactPhoneNumberModalVisible,
            formData,
            formRef,
            confirmLoading,
            addContact,
            handleConfirm,
            handleDelete
        };
    }
});
</script>
<style scoped>
.form-line {
    display: flex;
    gap: 10px;
}

>>> .el-form-item--mini.el-form-item {
    width: 100% !important;
}

>>> .el-form-item--mini .el-form-item__label {
    line-height: 12px !important;
    margin: 0;
    padding-bottom: 5px;
}

>>> .el-form--label-top .el-form-item__label {
    line-height: 12px;
    margin: 0;
}

.contact-button {
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
}

>>> .el-input-group__prepend {
    padding: 0 5px !important;
}
</style>
