<template>
    <el-dialog :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               width="1200px"
               append-to-body
               top="5vh"
               center>
        <contract-detail v-if="visible"
                         :data="data"
                         @close="$emit('update:visible',false);$emit('close')"
                         @delete="$emit('update:visible',false);">
        </contract-detail>
    </el-dialog>
</template>
<script>
import ContractDetail from "@/components/Contract/ContractDetail.vue";

export default {
    components: {
        ContractDetail
    },
    emits: ['updated', 'update:visible', 'close'],
    props: {
        data: Object,
        visible: Boolean,
    },
    data() {
        return {
            updated: 0,
        };
    },
    watch: {
        visible: {
            //TODO   @delete 不能掉用updated,因为目前弹窗关闭就会调用updated,后续改进
            handler(newVal) {
                if (newVal) {
                    this.updated++;
                } else {
                    if (this.updated > 0) {
                        this.$emit('updated');
                    }
                }
            },
            immediate: true
        }
    }
};
</script>
<style scoped>
>>> .el-dialog__header {
    height: 50px;
    border-bottom: 1px solid #ccc;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 50px;
    color: #292e33;
    font-size: 16px;
    display: none;
}

>>> .el-dialog__body {
    padding: 0;
    height: 860px;
    overflow: hidden;
    box-sizing: border-box;
}

>>> .el-dialog__footer {
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: hidden;
    border-top: 1px solid #ccc;
    display: none;
}

>>> .detail_left, >>> .detail_right {
    height: 860px;
    overflow-y: auto;
}
</style>
