<template>
    <div class="contract-cart-page-wrapper" v-loading="loading===1">
        <div>
            <div class="contract">
                <el-radio-group v-model="contractNum" @input="changeContractNum" size="mini">
                    <el-radio-button :label="undefined">全部</el-radio-button>
                    <el-radio-button :label="3">有效合同</el-radio-button>
                    <el-radio-button :label="9">定金合同</el-radio-button>
                    <el-radio-button :label="1">待审核合同</el-radio-button>
                    <el-radio-button :label="6">完结合同</el-radio-button>
                </el-radio-group>
            </div>
            <!-- 合同信息 -->
            <div class="contract_box" v-infinite-scroll="nextPage">
                <div :class="['contract_item',{selected:clickMode!=='openDetail'&&item.id===contractId}]"
                     v-for="item in records"
                     :key="item.id"
                     @click.stop="handleCardClick(item)">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="'合同名称:' + item.contractName + '合同编号:' + item.contractCode"
                        placement="top">
                        <template v-slot:content>
                            <div>
                                <div>
                                    <p style="margin: 0">
                                        合同名称:<span>{{ item.contractName }}</span>
                                    </p>
                                    <p style="margin: 5px 0 0 0">
                                        合同编号:<span>{{ item.contractCode }}</span>
                                    </p>
                                </div>
                            </div>
                        </template>
                        <div class="top_title">
                            <p>
                                <span class="title">{{ item.contractName }}</span>
                                <span style="font-size:18px;" :class="[{'fail-text-color':item.status==='Over'}]">
                                （{{
                                        $store.getters['common/contractStatusMap'][item.status]
                                    }}）
                            </span>
                            </p>
                            <span class="code">合同编号: {{ item.contractCode }}</span>
                        </div>
                    </el-tooltip>
                    <div class="operation">
                        <p class="primary-bg-color" :style="`${item.signType==='Renew'?'background-color:#4fc3f7':''}`">
                            {{ $store.getters["common/contractPerformanceTypeMap"][item.signType] }}
                        </p>
                        <p v-if="item.overTime">{{ $store.getters["common/contractOverTypeMap"][item.overType] }}</p>
                    </div>
                    <div class="info-line icon">
                        <img src="../../../assets/img/money.png" alt=""/>
                        <span>{{ item.amountReceivable }}元</span>
                    </div>
                    <div class="info-line icon">
                        <img src="../../../assets/img/time2.png" alt=""/>
                        <span v-if="item.activationTime">
                            {{ item.startTime.split(' ')[0] }} ~
                            {{ item.endTime.split(' ')[0] }}
                        </span>
                        <span v-else>未激活</span>
                    </div>
                    <div class="info-line icon">
                        <img src="../../../assets/img/book.png" alt=""/>
                        <span>剩余普通课时</span>
                        <el-button type="text"
                                   style="padding: 0;margin: 0;"
                                   @click.stop="openContractConsumeModal(item)">
                            查看消耗记录
                        </el-button>
                    </div>
                    <template v-if="item.packageType===1">
                        <div class="info-line">
                            <el-progress
                                :text-inside="false"
                                :show-text="false"
                                :stroke-width="8"
                                :percentage="item.status==='Over'?0:(item.remainingOrdinaryClassCount/(item.ordinaryClassCount+item.giveOrdinaryClassCount)*100)"
                                color="#29cb97"
                                style="width: 130px"/>
                            <div>
                                <span class="primary-text-color">
                                    {{
                                        item.status === 'Over' ? '0.00' : currency(item.remainingOrdinaryClassCount, {
                                            separator: ',',
                                            symbol: '',
                                        }).format()
                                    }}
                                </span>
                                <span>/</span>
                                <span>
                                    {{
                                        currency(item.ordinaryClassCount, {
                                            separator: ',',
                                            symbol: '',
                                        }).add(item.giveOrdinaryClassCount).format()
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="info-line">
                            <span>已上</span>
                            <span class="primary-text-color">
                                {{
                                    currency(item.studiedOrdinaryClassConsumeCount, {
                                        separator: ',',
                                        symbol: '',
                                    }).format()
                                }}
                            </span>
                            <span>课时</span>
                            <span>已排</span>
                            <span class="primary-text-color">
                                 {{
                                    currency(item.planedNoStudyOrdinaryClassConsumeCount, {
                                        separator: ',',
                                        symbol: '',
                                    }).format()
                                }}
                            </span>
                            <span>课时</span>
                            <span>可用</span>
                            <span class="primary-text-color">
                                  {{
                                    item.status === 'Over' ? '0.00' : currency(item.remainingOrdinaryClassCount, {
                                        separator: ',',
                                        symbol: '',
                                    }).format()
                                }}
                            </span>
                            <span>课时</span>
                        </div>
                    </template>
                    <div class="info-line" v-if="item.packageType === 2">
                        <span>已上</span>
                        <span class="primary-text-color">
                            {{
                                currency(item.studiedOrdinaryClassConsumeCount, {
                                    separator: ',',
                                    symbol: '',
                                }).format()
                            }}
                        </span>
                        <span>课时</span>
                        <span>已排</span>
                        <span class="primary-text-color">
                            {{
                                currency(item.planedNoStudyOrdinaryClassConsumeCount, {
                                    separator: ',',
                                    symbol: '',
                                }).format()
                            }}
                        </span>
                        <span>课时</span>
                    </div>
                    <div class="info-line">
                        剩余特殊课时
                    </div>
                    <div class="info-line">
                        <el-progress
                            :text-inside="false"
                            :show-text="false"
                            :stroke-width="8"
                            :percentage="(item.status==='Over'?0:item.remainingSpecialClassCount/item.specialClassCount*100)"
                            color="#29cb97"
                            style="width: 130px"/>
                        <div>
                            <span class="primary-text-color">
                                {{
                                    item.status === 'Over' ? '0.00' : currency(item.remainingSpecialClassCount, {
                                        separator: ',',
                                        symbol: '',
                                    }).format()
                                }}
                            </span>
                            <span>/</span>
                            <span>
                                {{
                                    currency(item.specialClassCount, {
                                        separator: ',',
                                        symbol: '',
                                    }).format()
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="info-line">
                        <span>已上</span>
                        <span class="primary-text-color">
                             {{
                                currency(item.studiedSpecialClassConsumeCount, {
                                    separator: ',',
                                    symbol: '',
                                }).format()
                            }}
                        </span>
                        <span>课时</span>
                        <span>已排</span>
                        <span class="primary-text-color">
                            {{
                                currency(item.planedSpecialClassConsumeCount, {
                                    separator: ',',
                                    symbol: '',
                                }).subtract(item.studiedSpecialClassConsumeCount).format()
                            }}
                        </span>
                        <span>课时</span>
                        <span>可用</span>
                        <span class="primary-text-color">
                            {{
                                item.status === 'Over' ? '0.00' : currency(item.remainingSpecialClassCount, {
                                    separator: ',',
                                    symbol: '',
                                }).format()
                            }}
                        </span>
                        <span>课时</span>
                    </div>
                    <p v-if="clickMode==='openDetail'" :class="item.id===contractId?'isDefault':'noDefault'"
                       @click.stop="contractId!==item.id&&changeDefaultContract(item)">
                        {{ item.id === contractId ? '已默认' : '设为默认' }}
                    </p>
                    <!-- 绿色块 -->
                    <div class="num_tab primary-bg-color"
                         :style="item.packageType === 1 ? '' : 'background:#4fc3f7;'">
                    </div>
                    <p class="num_p">
                        {{ (item.packageType === 1 && '次') || (item.packageType === 2 && '年') }}
                    </p>
                </div>
                <div v-if="loading===2" style="padding: 20px 0;text-align: center;width: 100%;">
                    正在加载中...
                </div>
                <div v-else-if="page.total<=records.length" style="padding: 20px 0;text-align: center;width: 100%;">
                    没有更多数据
                </div>
            </div>
            <contract-detail-modal @updated="refreshPage();$emit('updated')"
                                   :visible.sync="contractDetailInfoBoolean"
                                   :data="contractDetailInfoData">
            </contract-detail-modal>
            <consume-record :visible.sync="contractConsumeBoolean"
                            :contract-id="currentContract.id">
            </consume-record>
        </div>
    </div>
</template>
<script>
import {changeDefaultContractApi, getContractSimplePageApi} from '../../../api/student/contractApi';
import ConsumeRecord from '../../Contract/detail/ConsumeRecord.vue';
import {defineComponent, getCurrentInstance, onMounted, ref, toRefs, watch} from 'vue';
import {getDefaultPage} from '../../../utils';
import currency from "currency.js";

export default defineComponent({
    methods: {currency},
    components: {ConsumeRecord},
    props: {
        studentId: Number,
        defaultContractId: Number,
        listId: Number,
        //openDetail 打开详情, select 选择器, custom 自定义
        clickMode: {
            type: String,
            default() {
                return "openDetail";
            }
        }
    },
    emits: ['change', 'updated'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const loading = ref(0);

        const contractNum = ref();

        const records = ref([]);

        const contractId = ref();

        const contractDetailInfoData = ref({});

        const contractDetailInfoBoolean = ref(false);

        const contractConsumeBoolean = ref(false);

        const currentContract = ref({id: 0});

        const page = ref(getDefaultPage());

        const handleCardClick = (item) => {
            if (props.clickMode === 'custom') {
                if (item.id !== props.defaultContractId) {
                    emit('change', item);
                }
            } else if (props.clickMode === 'select') {
                if (item.id !== props.defaultContractId) {
                    currentInstance.proxy.$confirm('确认要切换为此合同吗？', {
                        type: 'warning',
                        showClose: false
                    }).then(() => emit('change', item));
                }
            } else {
                contractDetailInfoData.value = item;
                contractDetailInfoBoolean.value = true;
            }
        };

        const changeDefaultContract = (contract) => {
            changeDefaultContractApi({
                studentId: props.studentId,
                contractId: contract.id,
            }).then(() => {
                contractId.value = contract.id;
                emit('change', contract);
            });
        };

        const openContractConsumeModal = (contract) => {
            currentContract.value = contract;
            contractConsumeBoolean.value = true;
        };

        const changeContractNum = (data) => {
            contractNum.value = data;
            refreshPage();
        };

        const refreshPage = () => {
            loading.value = 1;
            records.value = [];
            page.value = getDefaultPage();
            getPage();
        };

        const nextPage = () => {
            if (page.value.total <= records.value.length) {
                return;
            }
            loading.value = 2;
            page.value.currentPage++;
            getPage();
        };

        const getPage = () => {
            getContractSimplePageApi({
                studentId: props.studentId,
                listId: props.listId,
                status: contractNum.value,
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp,
            }).then(({data}) => {
                if (Array.isArray(data.data.records) && data.data.records.length) {
                    for (let record of data.data.records) {
                        records.value.push(record);
                    }
                }
                page.value.currentPage = data.data.current;
                page.value.pageSize = data.data.size;
                page.value.total = data.data.total;
            }).finally(() => loading.value = 0);
        };

        onMounted(() => {
            refreshPage();
            contractId.value = props.defaultContractId;
        });

        watch(() => props.defaultContractId, newVal => {
            contractId.value = newVal;
        });

        return {
            ...toRefs(props),
            loading,
            contractNum,
            records,
            contractId,
            contractDetailInfoData,
            contractDetailInfoBoolean,
            contractConsumeBoolean,
            currentContract,
            page,
            handleCardClick,
            changeDefaultContract,
            openContractConsumeModal,
            changeContractNum,
            refreshPage,
            nextPage
        };
    }
});
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variables";

.contract-cart-page-wrapper {
    background: transparent;
    padding: 0 10px;
    box-sizing: border-box;
    overflow-y: auto;
    height: 600px;
}

.contract {
    display: flex;
    align-items: center;
    padding: 20px 0;
    box-sizing: border-box;


}

.contract_box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .contract_item:hover:not(.selected) {
        box-shadow: 0 0 10px #ccc;
        transform: translateY(-3px);
    }

    .contract_item.selected {
        cursor: not-allowed;
        border: 1px solid $--color-primary;
    }

    .contract_item {
        overflow: hidden;
        transition: all 0.2s;
        box-shadow: 0 0 3px 0 #ccc;
        cursor: pointer;
        width: fit-content;
        position: relative;
        min-height: 327px;
        background: #fff;
        padding: 10px;
        box-sizing: border-box;
        align-items: center;
        min-width: 420px;

        > .info-line {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 8px;
        }

        > .info-line:not(.icon) {
            padding-left: 22px;
        }

        img {
            width: 14px;
            height: 14px;
        }

        article + article {
            margin-top: 10px;
        }

        .top_title {
            p {
                font-size: 18px;
                color: #29cb97;
                margin: 0;
            }

            .title {
                display: inline-flex;
                max-width: 340px;
                overflow: hidden;
            }

            .code {
                font-size: 12px;
                color: #818c99;
            }
        }

        .operation {
            display: flex;
            align-items: center;

            > p + p {
                margin-left: 5px;
            }

            > p {
                width: fit-content;
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                background: #29cb97;
                color: #fff;
                border-radius: 5px;
                cursor: pointer;
                font-weight: 600;
            }
        }

        .isDefault {
            position: absolute;
            right: 10px;
            bottom: 10px;
            color: #29cb97;
            cursor: default;
        }

        .noDefault {
            position: absolute;
            right: 10px;
            bottom: 10px;
            cursor: pointer;
        }

        .noDefault:hover {
            color: #29cb97;
        }

        .num_tab {
            position: absolute;
            right: -50px;
            top: -10px;
            width: 80px;
            height: 40px;
            background: #29cb97;
            transform: rotate(45deg);
        }

        .num_p {
            font-size: 12px;
            color: #fff;
            position: absolute;
            right: 2px;
            top: 2px;
            margin: 0 0 0 10px;
        }
    }
}
</style>
