<template>
    <el-dialog
        width="600px"
        title="会员标签管理"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        append-to-body
        custom-modal
        top="10vh">
        <div style="min-height: 400px;">
            <student-tag-select v-model="value"
                                style="width: 85%; margin-right: 10px"
                                multiple
                                clearable>
            </student-tag-select>
            <el-button
                type="primary"
                size="mini"
                :disabled="!value||!value.length"
                :loading="addTagLoading"
                @click="addTag">
                添加
            </el-button>
            <ul v-if="Array.isArray(tags)&&tags.length" class="tag-list-container">
                <li v-for="(item,index) in tags"
                    :key="item.id"
                    class="tag-item"
                    :style="`background: ${item.color}24;color: ${item.color};`">
                    <span># {{ item.name }}</span>
                    <span class="delete-button" @click="deleteTag(item,index)">
                            &times;
                </span>
                </li>
            </ul>
        </div>
    </el-dialog>
</template>
<script>
import {defineComponent, getCurrentInstance, ref, toRefs, watch} from 'vue'
import {addTagToStudentApi, deleteTagFromStudentApi} from '../../../api/student/studentApi'
import StudentTagSelect from "@/components/Form/StudentTagSelect.vue";

export default defineComponent({
    components: {StudentTagSelect},
    props: {
        visible: Boolean,
        studentId: Number,
        tags: {
            type: Array,
            default() {
                return []
            }
        }
    },
    emits: ['update:visible', 'updated'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance()

        const addTagLoading = ref(false)

        const tags = ref([])

        const value = ref([])

        const addTag = () => {
            addTagLoading.value = true
            addTagToStudentApi({
                studentId: props.studentId,
                tagIds: value.value
            }).then(() => {
                emit('updated')
                const vipTags = currentInstance.proxy.$store.getters['common/vipTags']
                for (let tagId of value.value) {
                    for (const vipTag of vipTags) {
                        if (vipTag.value === tagId) {
                            tags.value.push({
                                id: vipTag.value,
                                name: vipTag.label,
                                color: vipTag.color
                            })
                            break
                        }
                    }
                }
                value.value = []
            }).finally(() => {
                addTagLoading.value = false
            })
        }

        const deleteTag = (tag, index) => {
            currentInstance.proxy.$confirm('确认要删除标签吗？', {
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true
                        deleteTagFromStudentApi({
                            studentId: props.studentId,
                            tagId: tag.id
                        }).then(() => {
                            tags.value.splice(index, 1)
                            emit('updated')
                            done()
                        }).finally(() => {
                            instance.confirmButtonLoading = false
                        })
                    } else {
                        done()
                    }
                }
            })
        }

        watch(() => props.visible, newVal => {
            if (newVal) {
                tags.value = props.tags ? props.tags : []
            } else {
                value.value = []
            }
        })

        return {
            ...toRefs(props),
            addTagLoading,
            tags,
            value,
            addTag,
            deleteTag
        }
    }
})
</script>
<style scoped>
.tag-list-container {
    display: flex;
    gap: 5px;
    margin-top: 16px;
    flex-wrap: wrap;
}

.tag-item {
    border-radius: 100px;
    font-size: 12px;
    padding: 0 8px;
}

.delete-button {
    cursor: pointer;
}

.delete-button:hover {
    opacity: .7;
}

.delete-button:hover:active {
    opacity: 1
}
</style>
