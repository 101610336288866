<template>
    <el-tooltip content="点击查看学员详情" effect="light" placement="top">
        <div @click="studentDetailModalVisible=true">
            <student-avatar :class="[{failed:status==='LeaveOfAbsence'||status==='LeaveOfAbsenceNoConsume'||status==='Canceled'},
                                     {success:status==='SignedIn'||status==='SignedInLateness'}]"
                            :src="avatar"
                            :gender="gender">
            </student-avatar>
            <student-detail-modal :visible.sync="studentDetailModalVisible"
                                  :student-id="studentId"
                                  @updated="handleStudentUpdated"
                                  @close="$emit('close')">
            </student-detail-modal>
        </div>
    </el-tooltip>
</template>
<script>
import {defineComponent, inject, ref, toRefs} from 'vue';
import StudentAvatar from "@/components/Common/StudentAvatar.vue";

export default defineComponent({
    components: {StudentAvatar},
    props: {
        avatar: String,
        studentId: Number,
        gender: Number,
        status: String,
        defaultContractId: Number
    },
    emits: ['updated', 'close'],
    setup(props, {emit}) {

        const studentDetailModalVisible = ref(false);

        const handleStudentUpdated = () => {
            reloadClass();
        };

        const reloadClass = inject('reloadClass');

        return {
            ...toRefs(props),
            studentDetailModalVisible,
            handleStudentUpdated
        };
    }
});
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variables";

img {
    margin: 0 auto;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    overflow: hidden;
    -webkit-border-radius: 50%;
    z-index: 1;
    position: relative;
    border: 3px solid #d8dee9;
    background-color: #fff;
    cursor: pointer;
}

.success {
    border: 3px solid $--color-primary
}

.failed {
    border: 3px solid #faad14
}
</style>
