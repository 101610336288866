<template>
    <el-dialog custom-modal
               width="470px"
               title="修改签约日期"
               :visible="visible"
               :modal-append-to-body="false"
               append-to-body>
        <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
            <el-form-item label="当前合同签约日期" required>
                {{ data.contractDate.split(" ")[0] }}
            </el-form-item>
            <el-form-item prop="contractDate" label="新合同签约日志" size="mini">
                <el-date-picker type="date"
                                value-format="yyyy-MM-dd"
                                v-model="formData.contractDate"
                                :picker-options="pickerOptions"
                                placeholder="请选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="原因" prop="reason">
                <el-input type="textarea" :maxlength="250" show-word-limit v-model="formData.reason"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button @click="$emit('update:visible',false)">取 消</el-button>
            <el-button type="primary"
                       @click="handleConfirm"
                       :loading="confirmLoading"
                       :disabled="formData.contractDate===data.contractDate.split(' ')[0]">
                确 定
            </el-button>
        </div>
    </el-dialog>
</template>
<script>
import {computed, defineComponent, inject, ref, toRefs} from "vue";
import {updateContractDateApi} from "../../../api/student/contractApi";

export default defineComponent({
    emits: ['update:visible'],
    props: {visible: Boolean},
    setup(props, {emit}) {

        const formData = ref({code: undefined});

        const formRules = ref({
            contractDate: [{required: true, message: "请选择新签约日期"}],
            reason: [{required: true, message: "原因是必填的"}]
        });

        const confirmLoading = ref(false);

        const formRef = ref();

        const pickerOptions = {
            disabledDate (time) {
                return time.getTime() > Date.now()
            }
        }

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate()
                .then(() => updateContractDateApi({
                    ...formData.value,
                    contractId: data.value.id
                })).then(() => {
                    emit('update:visible', false)
                    refreshContract()
                    refreshContractLog()
                }).finally(() => confirmLoading.value = false)
        }

        const getData = inject('getData')

        const data = computed(() => getData())

        const refreshContract = inject('refreshContract')

        const refreshContractLog = inject('refreshContractLog')

        return {
            ...toRefs(props),
            formData,
            formRules,
            confirmLoading,
            formRef,
            data,
            pickerOptions,
            handleConfirm
        }
    }
})
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 !important;
    color: #3b3b3b !important;
}
</style>