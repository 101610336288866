<template>
    <div>
        <el-dialog
            title="选择需要发送评价的学员"
            :visible="visible"
            @update:visible="val=>$emit('update:visible',val)"
            width="900px"
            append-to-body
            custom-modal>
            <div class="content">
                <div class="left">
                    <div class="filter">
                        <p :class="filterList.includes(1)?'on':''" @click="filterCommon(1)">
                            已签到
                            ({{ summary.signedIn.checked }}/{{ summary.signedIn.total }})</p>
                        <p :class="filterList.includes(2)?'on':''" @click="filterCommon(2)">
                            未签到
                            ({{ summary.notSignedIn.checked }}/{{ summary.notSignedIn.total }})</p>
                        <p :class="filterList.includes(3)?'on':''" @click="filterCommon(3)">
                            排队
                            ({{ summary.lineUp.checked }}/{{ summary.lineUp.total }})</p>
                        <p :class="filterList.includes(4)?'on':''" @click="filterCommon(4)">
                            请假
                            ({{ summary.leave.checked }}/{{ summary.leave.total }})</p>
                        <p :class="filterList.includes(5)?'on':''" @click="filterCommon(5)">
                            取消
                            ({{ summary.cancel.checked }}/{{ summary.cancel.total }})</p>
                    </div>
                    <el-table
                        :data="filteredData"
                        ref="multipleTable"
                        tooltip-effect="dark"
                        style="width: 100%"
                        :header-cell-style="{backgroundColor:'#f7f7f7',fontSize:'12px'}"
                        :cell-style="{fontSize:'12px'}"
                        @select="handleSelectionChange"
                        @select-all="handleSelectionChangeAll">
                        <el-table-column label="学员信息">
                            <template v-slot="{row}">
                                <div class="info">
                                    <span>{{ row.name }}</span>
                                    <gender-view :gender="row.gender"></gender-view>
                                    <span>{{ getBirthdayDisplay(row.birthtime) }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="点评状态">
                            <template v-slot="{row}">
                                <div>
                                    <span>{{ row.isComment ? '已点评' : '未点评' }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="50px" type="selection">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="right">
                    <div class="right_header">
                        <p>已选学员(<span>{{ multipleSelection.length }}</span>)</p>
                        <span @click="clearAllCheck">全部删除</span>
                    </div>
                    <dl>
                        <dt>
                            <span>学员信息</span>
                            <span>操作</span>
                        </dt>
                        <dd v-for="item in multipleSelection" :key="item.id">
                            <div>
                                <span>{{ item.name }}</span>
                                <gender-view :gender="item.gender"></gender-view>
                            </div>
                            <img @click="delCheckStudent(item.id)" src="../../assets/img/cha3.png" alt="">
                        </dd>
                    </dl>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="$emit('update:visible',false)">取 消</el-button>
                <el-button type="primary" @click="openSendMsg">确 定</el-button>
            </div>

        </el-dialog>
        <push-motion-modal
            :visible.sync="sendMsgVisible"
            title="发布动态"
            :type="1"
            :students="this.multipleSelection">
        </push-motion-modal>
    </div>
</template>

<script>
import PushMotionModal from "../Motion/PushMotionModal.vue";
import GenderView from "@/components/Common/GenderView.vue";
import {cloneObject} from "@/utils/ObjectUtils";
import {getBirthdayDisplay} from "../../utils/studentUtils";

export default {
    components: {GenderView, PushMotionModal},
    props: {
        visible: Boolean, students: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    emits: ['update:visible'],
    data() {
        return {
            //模拟学生数据
            tableData: [],
            //被选中的数据
            multipleSelection: [],
            //筛选条件集合 选择把状态添加进来 再次点击就删除 用于筛选 1已签到 2未签到 3排队 4请假 5取消
            filterList: [],
            sendMsgVisible: false,//发布动态页面显隐bol
            data: [],
        };
    },
    computed: {
        summary() {
            const counts = {
                signedIn: {total: 0, checked: 0},
                notSignedIn: {total: 0, checked: 0},
                lineUp: {total: 0, checked: 0},
                leave: {total: 0, checked: 0},
                cancel: {total: 0, checked: 0}
            };

            this.tableData.forEach(item => {
                switch (item.status) {
                    case 'SignedIn':
                    case 'SignedInLateness': {
                        counts.signedIn.total++;
                        if (item.checked) counts.signedIn.checked++;
                        break;
                    }
                    case 'Overdue':
                    case 'NoClasses': {
                        counts.notSignedIn.total++;
                        if (item.checked) counts.notSignedIn.checked++;
                        break;
                    }
                    case 'LineUp': {
                        counts.lineUp.total++;
                        if (item.checked) counts.lineUp.checked++;
                        break;
                    }
                    case 'LeaveOfAbsence':
                    case 'LeaveOfAbsenceNoConsume': {
                        counts.leave.total++;
                        if (item.checked) counts.leave.checked++;
                        break;
                    }
                    case 'Canceled': {
                        counts.cancel.total++;
                        if (item.checked) counts.cancel.checked++;
                        break;
                    }
                }
            });
            return counts;
        },
        filteredData() {
            if (this.filterList.length === 0) {
                return this.tableData;
            }
            const statusMap = {
                1: ['SignedIn', 'SignedInLateness'],
                2: ['Overdue', 'NoClasses'],
                3: ['LineUp'],
                4: ['LeaveOfAbsence', 'LeaveOfAbsenceNoConsume'],
                5: ['Canceled']
            };

            return this.tableData.filter(item => {
                for (let key in statusMap) {
                    if (this.filterList.includes(parseInt(key)) && statusMap[key].includes(item.status)) {
                        return true;
                    }
                }
                return false;
            });
        }
    },
    methods: {
        getBirthdayDisplay,
        handleSelectionChange(val, row) {
            let fil = val.find(a => a.id === row.id);
            row.checked = !!fil;
            this.multipleSelection = val;
        },
        handleSelectionChangeAll(arr) {
            this.multipleSelection = JSON.parse(JSON.stringify(arr));
            this.tableData.forEach(item => {
                item.checked = arr.length > 0;
            });
        },
        //右侧删除学员
        delCheckStudent(id) {
            let ind = this.tableData.findIndex(item => item.id === id);
            this.tableData[ind].checked = false;
            let index = this.multipleSelection.findIndex(item => item.id == id);
            this.multipleSelection.splice(index, 1);
            //让表格上的对应行不选中
            this.$refs.multipleTable.toggleRowSelection(this.tableData.find(item => item.id == id));
        },
        //全部删除
        clearAllCheck() {
            this.multipleSelection = [];
            this.tableData.forEach(item => {
                item.checked = false;
            });
            this.$refs.multipleTable.clearSelection();
        },
        //筛选公用函数
        filterCommon(type) {
            let index = this.filterList.findIndex(item => item === type);
            if (index === -1) {
                this.filterList.push(type);
            } else {
                this.filterList.splice(index, 1);
            }
        },
        openSendMsg() {
            console.log(this.multipleSelection, '发送短信');
            if (this.multipleSelection.length === 0) {
                return;
            }
            this.sendMsgVisible = true;
            this.$emit('update:visible', false);
        },
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                const students = cloneObject(this.students);
                students.forEach(item => {
                    item.checked = false;
                });
                this.tableData = students;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.content {
    display: flex;
    width: 100%;
    height: 100%;

    .left {
        flex: 1;
        padding-right: 20px;
        box-sizing: border-box;
        overflow: hidden;

        .filter {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            p {
                margin: 0 15px 0 0;
                height: 30px;
                width: fit-content;
                padding: 0 10px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #292e33;
                border: 1px solid #e5e5e5;
                border-radius: 5px;
                cursor: pointer;
            }

            p.on {
                border: 1px solid #29cb97;
                color: #29cb97;
            }
        }
    }

    .right {
        flex: 0 0 260px;
        border-left: 1px solid #e5e5e5;
        height: 360px;
        padding-left: 20px;
        box-sizing: border-box;

        .right_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            p {
                margin: 0;
                font-size: 16px;
                color: #292e33;
                font-weight: 600;

                span {
                    color: #f5222d;
                    font-weight: normal;
                }
            }

            > span {
                font-size: 12px;
                color: #f5222d;
                cursor: pointer;
            }
        }

        dl {
            list-style-type: none;

            dt {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 40px;
                padding: 0 20px;
                box-sizing: border-box;
                background: #f5f7fa;

                span {
                    font-size: 12px;
                    color: #292e33;
                }
            }

            dd {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 0 20px;
                box-sizing: border-box;
                justify-content: space-between;
                height: 40px;
                border-bottom: 1px solid #e5e5e5;
                margin: 0;

                div {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    span {
                        font-size: 14px;
                        color: #292e33;
                    }

                    img {
                        width: 15px;
                        height: 15px;
                        padding: 3px;
                        background: #fda8ca;
                        border-radius: 50%;
                        margin: 0 10px;
                    }

                    p {
                        margin: 0;
                        font-size: 12px;
                        color: #818c99;
                    }
                }

                > img {
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                }
            }
        }
    }
}

.info {
    display: flex;
    gap: 5px;
    color: #292e33;
    white-space: nowrap;
}
</style>
