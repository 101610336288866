import http from "../../utils/httpRequest";

export function getMessageListApi(params) {
    return http.get('/admin/motion/page', {params});
}

export function replyMessageApi(data) {
    return http.post('/admin/motion/comment', data);
}

export function getReplyMessageListApi(params) {
    return http.get(`/admin/motion/commentPage`, {params});
}

export function getReceptionStudentListApi(params) {
    return http.get('/admin/motion/getReceiveStudentPage', {params});
}

export function deleteMotionApi(id) {
    return http.delete(`/admin/motion/${id}`);
}
