<template>
    <div>
        <el-dialog
            title="新建子课程"
            :visible="visible"
            @update:visible="val=>$emit('update:visible',val)"
            width="400px"
            custom-modal
            append-to-body>
            <el-form ref="formRef"
                     size="mini"
                     :model="formData"
                     label-position="top"
                     :rules="formRules">
                <div class="form-multiple-item-line">
                    <el-form-item label="子课程标题" prop="name">
                        <el-input v-model="formData.name"
                                  placeholder="请输入课程子标题">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="耗课数" prop="consumeClassCount">
                        <el-input-number left
                                         controls-position="right"
                                         :min="0"
                                         :precision="0"
                                         v-model="formData.consumeClassCount"
                                         placeholder="">
                        </el-input-number>
                    </el-form-item>
                </div>
                <div class="form-multiple-item-line">
                    <el-form-item label="上课频率" prop="classCycleType">
                        <el-select v-model="formData.classCycleType" placeholder="请选择">
                            <el-option label="每周循环" value="WeekCycle"></el-option>
                            <el-option label="单次课" value="WeekSingleton"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="课节数" prop="classCount">
                        <el-input-number left
                                         style="width: 80%;"
                                         controls-position="right"
                                         :min="0"
                                         :precision="0"
                                         v-model="formData.classCount"
                                         placeholder=""
                                         :disabled="!formData.isCustomClassNum">
                        </el-input-number>
                        <el-tooltip effect="dark"
                                    content="打开手动修改后可修改自定义课节数，修改后的子课程结束日期有可能与预计结束日期不符"
                                    placement="top">
                        <span slot="content"
                              style="display:block;width:230px;">
                            打开手动修改后可修改自定义课节数，修改后的子课程结束日期有可能与预计结束日期不符
                        </span>
                            <el-checkbox style="width: 20%;" v-model="formData.isCustomClassNum"></el-checkbox>
                        </el-tooltip>
                    </el-form-item>
                </div>
                <div class="form-multiple-item-line">
                    <el-form-item label="开始日期" prop="startDate">
                        <el-date-picker
                            size="mini"
                            v-model="formData.startDate"
                            type="date"
                            style="width: 100%;"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            :picker-options="{
                            disabledDate(time) {
                                return time.getTime() < Date.now() - 8.64e7;
                            }
                        }">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束日期" prop="endDate">
                        <el-date-picker
                            v-model="formData.endDate"
                            type="date"
                            size="mini"
                            style="width: 100%;"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            :picker-options="{
                            disabledDate(time) {
                                return time.getTime() < Date.now() - 8.64e7;
                            }
                        }">
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div class="form-multiple-item-line">
                    <el-form-item label="星期" prop="cycleValue">
                        <el-select size="mini" style="width: 100%;" v-model="formData.cycleValue" placeholder="请选择">
                            <el-option label="周一" :value="1"></el-option>
                            <el-option label="周二" :value="2"></el-option>
                            <el-option label="周三" :value="3"></el-option>
                            <el-option label="周四" :value="4"></el-option>
                            <el-option label="周五" :value="5"></el-option>
                            <el-option label="周六" :value="6"></el-option>
                            <el-option label="周日" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上课时间" prop="timeRange">
                        <el-time-picker
                            v-model="formData.timeRange"
                            is-range
                            :picker-options="{
                                disabledDate(time) {
                                    return time.getTime() < Date.now();
                                }
                            }"
                            placeholder="选择时间"
                            value-format="HH:mm">
                        </el-time-picker>
                    </el-form-item>
                </div>
                <div class="form-multiple-item-line">
                    <el-form-item label="教室" prop="classroomIds">
                        <class-room-select multiple v-model="formData.classroomIds"></class-room-select>
                    </el-form-item>
                </div>
                <div class="form-multiple-item-line">
                    <el-form-item label="主讲老师" prop="mainTeacherIds">
                        <teacher-select use-teacher-id multiple v-model="formData.mainTeacherIds"></teacher-select>
                    </el-form-item>
                </div>
                <div class="form-multiple-item-line">
                    <el-form-item label="辅讲老师" prop="assistTeacherIds">
                        <teacher-select use-teacher-id multiple v-model="formData.assistTeacherIds"></teacher-select>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer">
                <el-button @click="$emit('update:visible',false)">取 消</el-button>
                <el-button type="primary"
                           @click="handleConfirm"
                           :loading="confirmLoading">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import TeacherSelect from "@/components/Form/TeacherSelect.vue";
import ClassRoomSelect from "@/components/Form/ClassRoomSelect.vue";
import {defineComponent, inject, ref, toRefs, watch} from "vue";
import {countWeekdays} from "@/utils";
import {addChildrenClassApi} from "@/api/class/classApi";
import dayjs from "dayjs";

export default defineComponent({
    components: {ClassRoomSelect, TeacherSelect},
    props: {
        visible: Boolean,
        data: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    emits: ['update:visible', "ok"],
    setup(props, {emit}) {

        const formRef = ref();

        const getDefaultFormData = () => {
            return {
                name: undefined,
                consumeClassCount: undefined,
                classCycleType: undefined,
                classCount: undefined,
                startDate: undefined,
                endDate: undefined,
                cycleValue: undefined,
                timeRange: undefined,
                classroomIds: [],
                mainTeacherIds: [],
                assistTeacherIds: [],
                isCustomClassNum: false,
            };
        };

        const formData = ref(getDefaultFormData());

        const formRules = ref({
            consumeClassCount: [{required: true, message: '耗课数量是必填的'}],
            classCycleType: [{required: true, message: '上课频率是必填的'}],
            classCount: [{required: true, message: '课节数量是必填的'}],
            startDate: [{required: true, message: '开始日期是必填的'}],
            endDate: [{required: true, message: '结束日期是必填的'}],
            cycleValue: [{required: true, message: '星期是必填的'}],
            timeRange: [{required: true, message: '时间是必填的'}],
            classroomIds: [{required: true, message: '未选择教室'}],
            mainTeacherIds: [{required: true, message: '未选择教师'}],
        });

        const confirmLoading = ref(false);

        let selfUpdate = false;

        const handleConfirm = () => {
            confirmLoading.value = true;
            formRef.value.validate().then(() => {
                return addChildrenClassApi({
                    classFatherId: props.data.id,
                    ...formData.value,
                    roomIds: formData.value.classroomIds,
                    teacherIds: formData.value.mainTeacherIds,
                    lessonCount: formData.value.classCount,
                    consumeLessonCount: formData.value.consumeClassCount,
                    startTime: dayjs("2024-01-01 " + formData.value.timeRange[0]).format("HH:mm:ss"),
                    classDuration: dayjs('2024-01-01 ' + formData.value.timeRange[1]).diff(dayjs('2024-01-01 ' + formData.value.timeRange[0]), "minute")
                });
            }).then(() => {
                emit('update:visible', false);
                emit('ok');
                reloadClass();
            }).finally(() => confirmLoading.value = false);
        };

        const reloadClass = inject('reloadClass');

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value = getDefaultFormData();
                setTimeout(() => {
                    formRef.value.clearValidate();
                });
            }
        });

        watch(() => JSON.stringify(formData.value), (newVal) => {
            if (selfUpdate) {
                selfUpdate = false;
            } else {
                if (formData.value.startDate
                    && formData.value.endDate
                    && formData.value.classCycleType === 'WeekCycle'
                    && formData.value.cycleValue !== undefined) {
                    formData.value.classCount = countWeekdays(
                        dayjs(formData.value.startDate).format('YYYY-MM-DD HH:mm:ss'),
                        dayjs(formData.value.endDate).format('YYYY-MM-DD HH:mm:ss'),
                        formData.value.cycleValue
                    );
                } else {
                    formData.value.classCount = 1;
                }
            }
        }, {deep: true});

        return {
            ...toRefs(props),
            formRef,
            formData,
            formRules,
            confirmLoading,
            handleConfirm
        };
    }
});
</script>

<style lang="scss" scoped>
.req {
    font-size: 14px;
    color: #292e33;

    &::after {
        content: '*';
        color: #ff0000;
        margin-left: 5px;
    }
}

.top_menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    div {
        span {
            font-size: 14px;
            color: #292e33;
        }
    }
}

.down_menu {
    margin-top: 20px;

    div + div {
        margin-top: 20px;

        span {
            font-size: 14px;
            color: #292e33;
        }
    }
}

.form-multiple-item-line {
    gap: 20px;
    flex: 1 1;
}
</style>