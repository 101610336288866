<template>
<el-dialog title="回收名单原因"
           width="20%"
           custom-modal
           append-to-body
           :visible="visible"
           @update:visible="val=>$emit('update:visible',val)">
    <el-form label-position="top">
        <el-form-item label="原因">
            <el-input v-model="recallReason"
                      type="textarea"
                      show-word-limit
                      :maxlength="200"
                      :autosize="{ minRows: 3}">
            </el-input>
        </el-form-item>
    </el-form>
    <template v-slot:footer>
        <div>
            <el-button @click="$emit('update:visible',false)">取消</el-button>
            <el-button type="primary" @click="handleConfirm" :loading="confirmLoading">提交</el-button>
        </div>
    </template>
</el-dialog>
</template>
<script>
import {defineComponent, toRefs, ref, computed, inject} from 'vue';
import {recallApi} from '@/api/student/listApi';

export default defineComponent({
    props: {visible: Boolean},
    emits: ['update:visible', 'ok'],
    setup (props, {emit}) {

        const recallReason = ref();

        const confirmLoading = ref(false);

        const handleConfirm = () => {
            confirmLoading.value = true;
            recallApi({listId: data.value.id, reason: recallReason.value})
                .then(() => {
                    emit('ok');
                    emit('update:visible', false);
                })
                .finally(() => confirmLoading.value = false);
        };

        const getData = inject('getData');

        const data = computed(() => getData());

        return {
            ...toRefs(props),
            recallReason,
            confirmLoading,
            handleConfirm
        };
    }
});
</script>
