<template>
    <el-tooltip content="点击查看学员详情" effect="light" placement="top">
        <div @click="listDetailModalVisible=true">
            <div :class="['list-student',
                          {failed:status==='LeaveOfAbsence'||status==='LeaveOfAbsenceNoConsume'||status==='Canceled'}
                         ]">
                名
            </div>
            <list-detail-modal :list-id="listId"
                               :visible.sync="listDetailModalVisible"
                               @updated="handleStudentUpdated"
                               @close="$emit('close')">
            </list-detail-modal>
        </div>
    </el-tooltip>
</template>
<script>
import {defineComponent, inject, ref, toRefs} from 'vue';

export default defineComponent({
    props: {listId: Number, status: String},
    emits: ['close'],
    setup(props, {emit}) {

        const listDetailModalVisible = ref(false);

        const handleStudentUpdated = () => {
            reloadClass();
        };

        const reloadClass = inject('reloadClass');

        return {
            ...toRefs(props),
            listDetailModalVisible,
            handleStudentUpdated
        };
    }
});
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variables";

.list-student {
    background-color: #faad14;
    color: #fff;
    height: 50px;
    line-height: 46px;
    width: 50px;
    border-radius: 50%;
    font-size: 1.8rem;
    border: 2px solid #edf0f2;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}

.success {
    border: 3px solid $--color-primary
}

.failed {
    border: 3px solid #faad14
}
</style>
