<template>
    <div>
        <el-dialog
            class="asd"
            modal
            width="710px"
            :visible="visible"
            @update:visible="val=>$emit('update:visible',val)"
            custom-class="editClassModal-dialog"
            append-to-body>
            <header>
                <div>
                    <p :class="topSelect === 1 ? 'on' : ''" @click="changeTopSelect(1)">
                        修改单次课
                    </p>
                    <p :class="topSelect === 2 ? 'on' : ''" @click="changeTopSelect(2)">
                        修改总课程
                    </p>
                </div>
                <img @click="$emit('update:visible',false)" src="../../../assets/img/cha3.png" alt=""/>
            </header>
            <update-current-class v-bind="$attrs"
                                  v-if="topSelect===1"
                                  @update:visible="val=>$emit('update:visible',val)">
            </update-current-class>
            <update-total-class v-bind="$attrs"
                                v-else-if="topSelect===2"
                                @update:visible="val=>$emit('update:visible',val)">
            </update-total-class>
        </el-dialog>
    </div>
</template>

<script>
import UpdateCurrentClass from './UpdateCurrentClass.vue';
import UpdateTotalClass from "@/components/Class/detail/UpdateTotalClass.vue";

export default {
    components: {
        UpdateTotalClass,
        UpdateCurrentClass,
    },
    props: {visible: Boolean},
    emits: ['update:visible'],
    data() {
        return {
            //顶部选择 1是修改单次课 2修改总课程
            topSelect: 0,
        };
    },
    methods: {
        //修改顶部选择方法
        changeTopSelect(index) {
            this.topSelect = index;
        },
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                this.topSelect = 1;
            } else {
                this.topSelect = 0;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;

    > img {
        width: 10px;
        height: 10px;
        cursor: pointer;
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            margin: 0;
            font-size: 14px;
            color: #292e33;
            height: 40px;
            cursor: pointer;
            line-height: 40px;

            & + p {
                margin-left: 15px;
            }

            &.on {
                border-bottom: 4px solid #29cb97;
                color: #29cb97;
            }
        }
    }
}

.req {
    &::after {
        content: "*";
        margin-left: 5px;
        color: #f00;
    }
}

.information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 10px 0 20px 0;

    > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.changeClass {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;

    > div {
        width: auto;
    }
}

.del {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 10px;

    p {
        color: #f00;
        margin: 0;
        font-size: 14px;
        cursor: pointer;
    }
}
</style>
<style lang="scss">
.editClassModal-dialog {
    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 40px;
        color: #292e33;
        display: none;

        i::before {
            position: relative;
            top: -10px;
        }

        span {
            font-size: 14px;
        }
    }

    .el-dialog__body {
        padding: 0px;
        box-sizing: border-box;
        min-height: 380px;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
        overflow: hidden;
        border-top: 1px solid #ccc;
    }
}

.changeClassLangModal-dialog {
    .el-dialog__body {
        border: 1px solid #e5e5e5;
        border-left: none;
        border-right: none;
    }
}

.changeClass-dialog {
    .el-dialog__header {
        border-bottom: 1px solid #e5e5e5;
    }

    .el-dialog__body {
        padding: 0 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #e5e5e5;
    }
}
</style>
