<template>
<el-select :value="value" @input="val=>$emit('update:value',val)" filterable>
    <el-option label="男" :value="1"></el-option>
    <el-option label="女" :value="2"></el-option>
    <el-option label="未知" :value="3"></el-option>
</el-select>
</template>
<script>
import {defineComponent} from 'vue';

export default defineComponent({
    model: {
        value: 'value',
        event: 'update:value'
    },
    props: {value: [Number, Array]},
    emits: ['update:value']
});
</script>
