export function copyPropertyTo (target, source, ignoreEmpty = false) {
    const map = new Map();
    map.set(source, target);
    doCopyPropertyTo(target, source, ignoreEmpty, map);
    if (map.size) {
        map.clear();
    }
}

export function doCopyPropertyTo (target, source, ignoreEmpty = false, map) {
    for (let key in source) {
        const value = source[key];

        function doCopy () {
            if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    target[key] = [];
                    if (value.length) {
                        for (const item of value) {
                            target[key].push(cloneObject(item));
                        }
                    }
                } else if (value === null) {
                    target[key] = null;
                } else if (value === undefined) {
                    target[key] = undefined;
                } else {
                    if (map.has(value)) {
                        target[key] = map.get(value);
                    } else {
                        target[key] = {};
                        map.set(value, target[key]);
                        doCopyPropertyTo(target[key], value, ignoreEmpty, map);
                    }
                }
            } else {
                target[key] = value;
            }
        }

        if (ignoreEmpty) {
            if (value !== null && value !== undefined && value !== '' && value !== []) {
                doCopy();
            }
        } else {
            doCopy();
        }

    }
}

export function cloneObject (source) {
    if (typeof source === 'object') {
        if (source) {
            if (Array.isArray(source)) {
                const target = [];
                if (source.length) {
                    for (const item of source) {
                        target.push(cloneObject(item));
                    }
                }
                return target;
            } else {
                const target = {};
                copyPropertyTo(target, source);
                return target;
            }
        } else {
            return null;
        }
    } else {
        return source;
    }
}

function isObject (data) {
    return typeof data === 'object' && data !== null;
}

export function isEqual (obj1, obj2) {
    // 首先判断传入参数类型
    if (!isObject(obj1) || !isObject(obj2)) {
        // 值类型
        return obj1 === obj2;
    }
    if (obj1 === obj2) {
        return true;
    }
    // 两个都是对象或是数组，而且不相等
    // 1.先取出obj1和obj2的key,比较个数
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);
    if (obj1Keys.length !== obj2Keys.length) {
        return false;
    }
    // 2.以obj1为基准，和obj2一次递归比较
    for (let key in obj1) {
        // 比较当前key的val --递归
        const res = isEqual(obj1[key], obj2[key]);
        if (!res) {
            return false;
        }
    }
    // 3.全相等
    return true;
}
