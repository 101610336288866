<template>
    <div class="wp">
        <div style="display: flex;flex-direction: column;align-items: center;cursor: pointer;"
             v-for="(studentClass,index) in data" :key="index">
            <list-student-avatar v-if="studentClass.studentType==='List'"
                                 :list-id="studentClass.listId"
                                 :avatar="studentClass.avatar"
                                 :status="studentClass.status"
                                 @close="$emit('close')">
            </list-student-avatar>
            <vip-student-avatar v-else-if="studentClass.studentType==='VipStudent'"
                                :avatar="studentClass.avatar"
                                :gender="studentClass.gender"
                                :student-id="studentClass.studentId"
                                :default-contract-id="studentClass.defaultContractId"
                                :status="studentClass.status"
                                @close="$emit('close')">
            </vip-student-avatar>
            <div style="margin-top: 5px;font-size: 9px;color: #818c99;">{{ studentClass.name }}</div>
        </div>
    </div>
</template>
<script>
import {defineComponent} from 'vue';
import ListStudentAvatar from './ListStudentAvatar.vue';
import VipStudentAvatar from './VipStudentAvatar.vue';

export default defineComponent({
    components: {VipStudentAvatar, ListStudentAvatar},
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },
    emits: ['close']
});

</script>
<style scoped lang="scss">
.wp {
    display: flex;
    align-items: start;
    gap: 20px;
    height: fit-content;
    padding: 10px 30px;
    flex-wrap: wrap;
    background-color: #ffffff;
    overflow: hidden;
}

article {
    background-color: #faad14;
    color: #fff;
    height: 50px;
    line-height: 46px;
    width: 50px;
    border-radius: 50%;
    font-size: 1.8rem;
    border: 2px solid #edf0f2;
    box-sizing: border-box;
    text-align: center;
}
</style>
