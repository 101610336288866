<template>
    <tenant-user-select :value="value" @update:value="val=>$emit('update:value',val)">
        <template v-slot="{options}">
            <el-option :value="-1" label="本中心"></el-option>
            <el-option :value="-2" disabled>-------------</el-option>
            <el-option v-for="tenantUser in options"
                       :key="tenantUser.id"
                       :value="tenantUser.id"
                       :label="tenantUser.name">
            </el-option>
        </template>
    </tenant-user-select>
</template>
<script>
import {defineComponent} from "vue";
import TenantUserSelect from "./TenantUserSelect.vue";

export default defineComponent({
    components: {TenantUserSelect},
    model: {
        prop: 'value',
        event: 'update:value'
    },
    emits: ['update:value'],
    props: {
        value: Number,
    }
})
</script>
