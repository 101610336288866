<template>
    <div class="detail_right" v-loading="loading===1" v-infinite-scroll="nextPage">
        <div class="title">合同流水</div>
        <div class="btnBox">
            <el-button v-if="data.state === 2" type="primary" class="btn_water1" size="small"
                       @click="receiveRefundModalVisible=true;receiveRefund=true;">
                收款
            </el-button>
            <el-button v-if="data.state === 1 || data.state === 2 || data.state === 5"
                       type="primary" size="small" class="btn_water2"
                       @click="receiveRefundModalVisible=true;receiveRefund=false;">
                退款
            </el-button>
            <template v-if="data.state === 3 || data.state === 7 || data.state === 8">
                <el-button @click="changeComboModalVisible=true" type="primary" size="small"
                           class="btn_water1">
                    改套餐
                </el-button>
                <el-button @click="changeClassTimeModalVisible=true" type="primary" size="small"
                           class="btn_water1">
                    修改课时
                </el-button>
                <!--            <el-button @click="handleButtonClick('openChangeDataModal',3)" type="primary" size="small"
                                       class="btn_water1">
                                修改礼物
                            </el-button>-->
                <el-button @click="updateClassConsumerModalVisible=true" type="primary" size="small"
                           class="btn_water1">
                    修改耗课
                </el-button>
                <el-button @click="changeApplyRuleModalVisible=true" type="primary" size="small"
                           class="btn_water1">
                    修改适用规则
                </el-button>
                <el-button @click="updateApplicableStudentModalVisible=true" type="primary" size="small"
                           class="btn_water1">
                    修改适用学员
                </el-button>
                <el-button v-if="data.state === 3" @click="refundAndCompleteModalVisible=true" type="primary"
                           size="small"
                           class="btn_water2">
                    退款完结
                </el-button>
            </template>
        </div>
        <contract-water-list :records="waterList" :loading="loading"></contract-water-list>
        <template v-if="contractLoaded">
            <change-combo-vue :visible.sync="changeComboModalVisible"></change-combo-vue>
            <money-operation :receiveRefund="receiveRefund" :visible.sync="receiveRefundModalVisible"></money-operation>
            <change-class-time :visible.sync="changeClassTimeModalVisible"></change-class-time>
            <update-class-consumer :visible.sync="updateClassConsumerModalVisible"></update-class-consumer>
            <change-apply-rule :visible.sync="changeApplyRuleModalVisible"></change-apply-rule>
            <update-applicable-student :visible.sync="updateApplicableStudentModalVisible"></update-applicable-student>
            <refund-and-complete :visible.sync="refundAndCompleteModalVisible"></refund-and-complete>
        </template>
    </div>
</template>
<script>
import ChangeComboVue from './ChangeCombo.vue';
import ChangeClassTime from './ChangeClassTime.vue';
import ChangeApplyRule from './ChangeApplyRule.vue';
import {defineComponent, inject, provide, ref, toRefs, watch} from 'vue';
import {getSingleContractWaterPageApi} from '../../../api/student/contractApi';
import {getDefaultPage} from '../../../utils';
import ContractWaterItem from './ContractWaterItem.vue';
import {waterTypeMap} from './config';
import MoneyOperation from './MoneyOperation.vue';
import UpdateClassConsumer from './UpdateClassConsumer.vue';
import UpdateApplicableStudent from './UpdateApplicableStudent.vue';
import RefundAndComplete from './RefundAndComplete.vue';
import ContractWaterList from "@/components/Contract/detail/ContractWaterList.vue";

export default defineComponent({
    components: {
        ContractWaterList,
        RefundAndComplete,
        UpdateApplicableStudent,
        UpdateClassConsumer,
        MoneyOperation,
        ContractWaterItem,
        ChangeComboVue,
        ChangeClassTime,
        ChangeApplyRule
    },
    props: {
        data: Object,
        contractLoaded: Boolean,
    },
    setup(props) {

        const changeComboModalVisible = ref(false);

        const receiveRefundModalVisible = ref(false);

        const changeClassTimeModalVisible = ref(false);

        const updateClassConsumerModalVisible = ref(false);

        const changeApplyRuleModalVisible = ref(false);

        const updateApplicableStudentModalVisible = ref(false);

        const refundAndCompleteModalVisible = ref(false);

        const receiveRefund = ref(false);

        const waterList = ref([]);

        const page = ref(getDefaultPage());

        const loading = ref(0);

        let lastFreezeWaterId = undefined;

        const refreshWaterPage = () => {
            loading.value = 1;
            lastFreezeWaterId = undefined;
            page.value = getDefaultPage();
            waterList.value = [];
            getWaterPage();
            refreshPerformanceSpilt();
        };

        const getWaterPage = () => {
            getSingleContractWaterPageApi({
                contractId: props.data.id,
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp
            }).then(({data}) => {
                if (Array.isArray(data.data.records) && data.data.records.length) {
                    data.data.records.forEach(record => {
                        if (record.updateAfter && record.updateAfter.includes('{')) {
                            const before = record.updateBefore;
                            const after = record.updateAfter;
                            record.updateBefore = JSON.parse(before);
                            record.updateAfter = JSON.parse(after);
                        }
                        if (record.type === 2 && !lastFreezeWaterId) {
                            lastFreezeWaterId = record.id;
                            record._isLastFreezeWater = true;
                        }
                        waterList.value.push(record);
                    });
                    page.value.currentPage = data.data.current;
                    page.value.pageSize = data.data.size;
                    page.value.total = data.data.total;
                }
            }).finally(() => loading.value = 0);
        };

        const nextPage = () => {
            if (page.value.total <= waterList.value.length) {
                return;
            }
            loading.value = 2;
            page.value.currentPage++;
            getWaterPage();
        };

        watch(() => props.contractLoaded, newVal => {
            if (newVal) {
                refreshWaterPage();
            }
        });

        provide('refreshWater', refreshWaterPage);

        const refreshPerformanceSpilt = inject('refreshPerformanceSpilt');

        return {
            ...toRefs(props),
            waterTypeMap,
            waterList,
            changeComboModalVisible,
            receiveRefundModalVisible,
            changeClassTimeModalVisible,
            updateClassConsumerModalVisible,
            changeApplyRuleModalVisible,
            updateApplicableStudentModalVisible,
            refundAndCompleteModalVisible,
            receiveRefund,
            loading,
            refreshWaterPage,
            nextPage
        };
    }
});
</script>
<style lang="scss">
.detail_right {
    background-color: #f5f7fa;

    .title {
        color: #29cb97;
        font-size: 18px;
        margin: 0 0 20px;
        font-weight: 500;
    }

    .btnBox {
        position: relative;
        margin-left: 25px;
        padding-bottom: 5%;

        .btn_water1 {
            padding: 10px;
            background: #29cb97;
            border: none;
            margin: 0 10px 10px 0;
        }

        .btn_water2 {
            padding: 10px;
            background: #f5222d;
            border: none;
            margin: 0 10px 10px 0;
        }
    }

    .btnBox::before {
        content: "";
        position: absolute;
        left: -22px;
        width: 2px;
        top: 30%;
        background: #edf0f2;
        height: calc(100% - 30% + 3px);
    }
}
</style>
