<template>
<div>
    <el-dialog custom-modal
               title="业绩类型修改"
               width="400px"
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               append-to-body>
        <el-form ref="formRef" :rules="fromRules" :model="formData" label-position="top" size="mini">
            <el-form-item prop="performanceType" label="请选择业绩类型">
                <el-select v-model="formData.performanceType" style="width: 100%">
                    <el-option v-for="performanceType in $store.state.common.performanceType"
                               v-bind="performanceType"
                               :key="performanceType.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="reason" label="原因">
                <el-input v-model="formData.reason" :maxlength="250" show-word-limit></el-input>
            </el-form-item>
        </el-form>
        <template v-slot:footer>
            <el-button class="ballet-modal-button" @click="$emit('update:visible',false)">取消</el-button>
            <el-button class="ballet-modal-button" :disabled="formData.performanceType===water.performanceType"
                       type="primary" :loading="confirmLoading" @click="handleConfirm">
                确认
            </el-button>
        </template>
    </el-dialog>
</div>
</template>
<script>
import {computed, defineComponent, inject, ref, watch} from 'vue'
import {updatePerformanceTypeApi} from '../../../api/student/contractApi'

export default defineComponent({
    props: {
        visible: Boolean
    },
    emits: ['update:visible'],
    setup (props, {emit}) {

        const formRef = ref()

        const fromRules = ref({
            performanceType: [{required: true, message: '请选择业绩类型'}],
            reason: [{required: true, message: '原因是必填的'}],
        })

        const formData = ref({
            performanceType: undefined,
            reason: undefined
        })

        const confirmLoading = ref(false)

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate()
            .then(() => updatePerformanceTypeApi({...formData.value, waterId: water.value.id}))
            .then(() => {
                emit('update:visible', false)
                refreshWater()
                refreshContractLog()
            })
            .finally(() => {
                confirmLoading.value = false
            })
        }

        const getWater = inject('getWater')

        const refreshWater = inject('refreshWater')

        const refreshContractLog = inject('refreshContractLog')

        const water = computed(() => {
            const result = getWater()
            return result ? result : {}
        })

        watch(water, (newVal, oldVal) => {
            if (oldVal && newVal.id !== oldVal.id) {
                formData.value.performanceType = newVal.performanceType
            }
        }, {deep: true, immediate: true})

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value.performanceType = water.value.performanceType
            }
        })

        return {
            formRef,
            fromRules,
            formData,
            water,
            confirmLoading,
            handleConfirm
        }
    }
})
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 !important;
    color: #3b3b3b !important;
}
</style>
