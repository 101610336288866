<template>
<div>
    <el-dialog class="asd"
               modal
               width="810px"
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               title="title"
               custom-class="suspendClass-dialog"
               append-to-body>
        <div class="header">
            <p :class="check===1 ? 'on' : ''" @click="check=1">停课</p>
            <p :class="check===2 ? 'on' : ''" @click="check=2">调课</p>
            <p :class="check===3 ? 'on' : ''" @click="check=3">复制课</p>
        </div>
        <div style="padding: 20px 0;">
            <stop-class v-if="check===1"
                        :class-info="classInfo"
                        @update:visible="val=>$emit('update:visible',val)"
                        @updated="$emit('updated')">
            </stop-class>
            <class-reschedule v-else-if="check===2"
                              :class-info="classInfo"
                              @update:visible="val=>$emit('update:visible',val)"
                              @updated="$emit('updated')">
            </class-reschedule>
            <copy-class v-else-if="check===3"
                        :class-info="classInfo"
                        @update:visible="val=>$emit('update:visible',val)"
                        @updated="$emit('updated')">
            </copy-class>
        </div>
    </el-dialog>
</div>
</template>
<script>

import {defineComponent, toRefs, watch, ref} from 'vue'
import StopClass from './detail/StopClass.vue'
import CopyClass from './detail/CopyClass.vue'
import ClassReschedule from './detail/ClassReschedule.vue'

export default defineComponent({
    components: {ClassReschedule, CopyClass, StopClass},
    props: {visible: Boolean, classInfo: Object},
    emits: ['update:visible', 'updated'],
    setup (props, {emit}) {

        const check = ref()

        watch(() => props.visible, newVal => {
            if (newVal) {
                check.value = 1
            } else {
                check.value = undefined
            }
        })

        return {
            ...toRefs(props),
            props,
            check,
        }
    }
})
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 40px;
    border-bottom: 1px solid #ccc;

    p {
        width: fit-content;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;
        font-size: 14px;
        color: #292e33;
        cursor: pointer;
    }

    p.on {
        border-bottom: 4px solid #29cb97;
        color: #29cb97;
        font-weight: 500;
    }
}

.copy_class {
    padding: 0 15px;
    box-sizing: border-box;

    .copy_source_date,
    .copy_target_date {
        display: flex;
        align-items: center;
        margin-top: 10px;
        box-sizing: border-box;

        p {
            font-size: 14px;
            color: #292e33;
            margin: 0;
            margin-right: 20px;

            &::after {
                content: '*';
                color: #f00;
            }
        }
    }

    .conflict {
        display: flex;
        align-items: center;
        height: 100%;

        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            position: relative;
            top: -2px;
        }

        p {
            font-size: 14px;
            color: #292e33;
        }
    }
}
</style>


<style lang="scss">
.suspendClass-dialog {
    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #ccc;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 40px;
        color: #292e33;
        display: none;

        i::before {
            position: relative;
            top: -10px;
        }

        span {
            font-size: 14px;
        }
    }

    .el-dialog__body {
        padding: 0;
        box-sizing: border-box;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
        overflow: hidden;
        border-top: 1px solid #ccc;
        display: none;
    }
}
</style>
