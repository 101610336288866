<template>
    <div class="package-rule-select">
        <div class="package-rule-select-item" v-loading="packageLoading">
            <div class="item-title">
                <div>普通课时适用课类</div>
                <el-radio-group v-model="ordinaryClassRadioVal">
                    <el-radio :label="1">全部课类</el-radio>
                    <el-radio :label="2">适用课类</el-radio>
                </el-radio-group>
            </div>
            <template v-if="ordinaryClassRadioVal===2">
                <div v-if="classTypeList==='loading'" v-loading="true"></div>
                <div class="item-select-info" v-else>
                    <el-checkbox-group v-model="ordinaryClassClassType">
                        <el-checkbox :key="classType.id"
                                     :label="classType.id"
                                     :value="classType.id"
                                     v-for="classType in classTypeList">
                            {{ classType.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </template>
        </div>
        <div class="package-rule-select-item"
             v-if="packageInfo&&[1,'NumCard'].includes(packageInfo.type)||[1,'NumCard'].includes(packageType)">
            <div class="item-title">
                <span>普通课时适用周期</span>
                <el-tooltip placement="top" content="适用周期是指 次卡合同可约课时间，未勾选的时间将不可约课。">
                    <i class="el-icon-question" style="cursor: pointer;font-size: 20px;"></i>
                </el-tooltip>
            </div>
            <div class="item-select-info">
                <el-checkbox-group v-model="ordinaryClassPeriodicity">
                    <el-checkbox label="周一"></el-checkbox>
                    <el-checkbox label="周二"></el-checkbox>
                    <el-checkbox label="周三"></el-checkbox>
                    <el-checkbox label="周四"></el-checkbox>
                    <el-checkbox label="周五"></el-checkbox>
                    <el-checkbox label="周六"></el-checkbox>
                    <el-checkbox label="周日"></el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        <div class="package-rule-select-item">
            <div class="item-title">
                <div>特殊课时适用课类</div>
                <el-radio-group v-model="specialClassRadioVal">
                    <el-radio :label="1">全部课类</el-radio>
                    <el-radio :label="2">适用课类</el-radio>
                </el-radio-group>
            </div>
            <template v-if="specialClassRadioVal===2">
                <div v-if="classTypeList==='loading'" v-loading="true"></div>
                <div class="item-select-info" v-else>
                    <el-checkbox-group v-model="specialClassClassType">
                        <el-checkbox :key="classType.id"
                                     :label="classType.id"
                                     :value="classType.id"
                                     v-for="classType in classTypeList">
                            {{ classType.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </template>

        </div>
        <div class="package-rule-select-item"
             v-if="packageInfo&&[1,'NumCard'].includes(packageInfo.type)||[1,'NumCard'].includes(packageType)">
            <div class="item-title">
                <span>特殊课时适用周期</span>
                <el-tooltip placement="top" content="适用周期是指 次卡合同可约课时间，未勾选的时间将不可约课。">
                    <i class="el-icon-question" style="cursor: pointer;font-size: 20px;"></i>
                </el-tooltip>
            </div>
            <div class="item-select-info">
                <el-checkbox-group v-model="specialClassPeriodicity">
                    <el-checkbox label="周一"></el-checkbox>
                    <el-checkbox label="周二"></el-checkbox>
                    <el-checkbox label="周三"></el-checkbox>
                    <el-checkbox label="周四"></el-checkbox>
                    <el-checkbox label="周五"></el-checkbox>
                    <el-checkbox label="周六"></el-checkbox>
                    <el-checkbox label="周日"></el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
    </div>
</template>
<script>
import {defineComponent, ref, watch} from 'vue';
import {getClassTypeListApi} from '../../api/mall/classTypeApi';
import {getPackageApi} from '../../api/mall/packageApi';

export default defineComponent({
    props: {
        packageId: Number,
        ordinaryClass: Array,
        ordinaryClassPeriodicity: Array,
        specialClass: Array,
        specialClassPeriodicity: Array,
        packageType: [Number, String]
    },
    emits: [
        'update:ordinaryClass',
        'update:ordinaryClassPeriodicity',
        'update:specialClass',
        'update:specialClassPeriodicity',
        'packageChange'
    ],
    setup(props, {emit}) {

        const packageLoading = ref(false);

        const packageInfo = ref();

        const ordinaryClassRadioVal = ref(1);

        const specialClassRadioVal = ref(1);

        const ordinaryClassClassType = ref([]);

        const ordinaryClassPeriodicity = ref(['周一', '周二', '周三', '周四', '周五', '周六', '周日']);

        const specialClassClassType = ref([]);

        const specialClassPeriodicity = ref(['周一', '周二', '周三', '周四', '周五', '周六', '周日']);

        const classTypeList = ref();

        const getClassTypeList = () => {
            classTypeList.value = 'loading';
            getClassTypeListApi()
                .then(res => {
                    classTypeList.value = res.data.data;
                });
        };

        watch(ordinaryClassRadioVal, newVal => {
            if (newVal === 2 && classTypeList.value !== 'loading') {
                getClassTypeList();
            }
        });

        watch(specialClassRadioVal, newVal => {
            if (newVal === 2 && classTypeList.value !== 'loading') {
                getClassTypeList();
            }
        });

        watch(ordinaryClassClassType, (newVal) => emit('update:ordinaryClass', newVal));

        watch(ordinaryClassPeriodicity, (newVal) => emit('update:ordinaryClassPeriodicity', newVal));

        watch(specialClassClassType, (newVal) => emit('update:specialClass', newVal));

        watch(specialClassPeriodicity, (newVal) => emit('update:specialClassPeriodicity', newVal));

        watch(() => props.packageId, newVal => {
            if (newVal) {
                getPackageApi(props.packageId)
                    .then(res => {
                        const balletPackage = res.data.balletPackage;
                        emit('packageChange', balletPackage);
                        if (balletPackage.ordinaryClassList.length) {
                            ordinaryClassClassType.value = balletPackage.ordinaryClassList;
                            ordinaryClassRadioVal.value = 2;
                        }
                        if (balletPackage.specialClassList.length) {
                            specialClassClassType.value = balletPackage.specialClassList;
                            specialClassRadioVal.value = 2;
                        }
                        if (balletPackage.type === 1) {
                            specialClassPeriodicity.value = balletPackage.specialList;
                            ordinaryClassPeriodicity.value = balletPackage.ordinaryList;
                        }
                        packageInfo.value = balletPackage;
                    })
                    .finally(() => packageLoading.value = false);
            }
        }, {immediate: true});

        return {
            packageLoading,
            packageInfo,
            ordinaryClassRadioVal,
            specialClassRadioVal,
            ordinaryClassClassType,
            ordinaryClassPeriodicity,
            specialClassClassType,
            specialClassPeriodicity,
            classTypeList
        };
    }
});
</script>
<style scoped>
.package-rule-select {
    padding: 10px;
    background: #f0f0f0;
    border-radius: 5px;
}

.package-rule-select-item {
    text-align: left;
    padding: 0 0 8px;
    font-size: 14px;
    line-height: 14px;
}

.item-title {
    display: flex;
    padding: 10px 0;
    align-items: center;
    gap: 20px;
}

.item-select-info {
    text-align: left;
    background-color: #f5f7fa;
    padding: 10px;
    border-radius: 4px;
}

>>> .el-checkbox {
    margin: 0 20px 10px 0 !important;
}

>>> .el-checkbox__label {
    font-size: 12px !important;
    color: #333 !important;
}
</style>
