<!--suppress HtmlUnknownAttribute -->
<template>
<el-popover
    consultant-select
    trigger="click"
    :visible-arrow="false"
    popper-class="consultant-select-popover"
    v-model="show">
    <div class="consultant-select" :style="`--height: ${height}px;`">
        <div class="search-input-wrapper">
            <input v-model="keyword" placeholder="搜索市场" class="search-input"/>
        </div>
        <div
            class="consultant-list"
            v-infinite-scroll="handleScroll"
            :infinite-scroll-disabled="loading === 0">
            <div @click="handleItemClick(counselor)"
                 :class="['consultant-item', { selected: selectCounselor === counselor }]"
                 v-for="counselor in markets"
                 :key="counselor.userId">
                {{ counselor.name }}
            </div>
            <div style="text-align: center; padding: 20px 0" v-if="loading === 2">
                加载中...
            </div>
            <div style="text-align: center; padding: 20px 0"
                 v-else-if="keyword ? !searchPage.records.length : !defaultPage.records.length">
                没有更多了
            </div>
        </div>
        <div class="consultant-select-footer">
            <div v-if="selectCounselor" class="confirm-button-wrapper">
                <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确定</el-button>
            </div>
        </div>
    </div>
    <template v-slot:reference>
        <slot></slot>
    </template>
</el-popover>
</template>
<script>
import {getTenantUserPageApi} from '../../../api/system/tenantUserApi'
import {fixedRole} from '../../../utils/const'
import {getDefaultPage} from '../../../utils'

export default {
    emits: ['update:value', 'change'],
    model: {
        prop: 'value',
        event: 'update:value',
    },
    props: {
        value: Number,
        onOk: Function
    },
    data () {
        return {
            loading: 0,
            show: false,
            keyword: '',
            selectCounselor: undefined,
            defaultPage: undefined,
            searchPage: undefined,
            markets: [],
            confirmLoading: false
        }
    },
    methods: {
        handleItemClick (counselor) {
            if (this.selectCounselor === counselor) {
                this.selectCounselor = undefined
            } else {
                this.selectCounselor = counselor
            }
        },
        handleConfirm () {
            const ok = () => {
                this.$emit('update:value', this.selectCounselor.userId)
                this.$emit('change', this.selectCounselor)
                this.show = false
            }

            if (typeof this.onOk === 'function') {
                const res = this.onOk(this.selectCounselor)
                if (res instanceof Promise) {
                    this.confirmLoading = true
                    res.then(() => ok()).finally(() => this.confirmLoading = false)
                } else {
                    ok()
                }
            } else {
                ok()
            }
        },
        handleScroll () {
            if (this.searchPage || this.defaultPage) {
                if (this.keyword) {
                    if (this.searchPage.currentPage < this.searchPage.pages) {
                        this.searchPage.currentPage++
                        this.getMarketsWithKeyword()
                    }
                } else {
                    if (this.defaultPage.currentPage < this.defaultPage.pages) {
                        this.defaultPage.currentPage++
                        this.getMarkets()
                    }
                }
            }
        },
        getMarkets () {
            if (this.loading === 0) {
                this.loading = this.defaultPage.currentPage === 1 ? 1 : 2
                getTenantUserPageApi({
                    currentPage: this.defaultPage.currentPage,
                    pageSize: this.defaultPage.pageSize,
                    timestamp: this.defaultPage.timestamp,
                    roles: [fixedRole.MasterMarket.code, fixedRole.Market.code],
                }).then((res) => {
                    if (
                        Array.isArray(res.data.data.records) &&
                        res.data.data.records.length
                    ) {
                        for (let record of res.data.data.records) {
                            this.defaultPage.records.push(record)
                        }
                        this.markets = this.defaultPage.records
                    }
                }).finally(() => {
                    this.loading = 0
                })
            }
        },
        getMarketsWithKeyword () {
            if (this.loading === 0) {
                this.loading = this.searchPage.currentPage === 1 ? 1 : 2
                this.searchPage.keyword = this.keyword
                getTenantUserPageApi({
                    currentPage: this.searchPage.currentPage,
                    pageSize: this.searchPage.pageSize,
                    timestamp: this.searchPage.timestamp,
                    name: this.keyword,
                    roles: [fixedRole.MasterMarket.code, fixedRole.Market.code],
                })
                    .then((res) => {
                        if (this.searchPage.keyword === this.keyword) {
                            if (
                                Array.isArray(res.data.data.records) &&
                                res.data.data.records.length
                            ) {
                                for (let record of res.data.data.records) {
                                    this.searchPage.records.push(record)
                                }
                                this.markets = this.searchPage.records
                            }
                        }
                    })
                    .finally(() => {
                        if (this.searchPage.keyword === this.keyword) {
                            this.loading = 0
                        }
                    })
            }
        },
        refreshMarkets () {
            this.defaultPage = getDefaultPage()
            this.defaultPage.pageSize = 50
            this.defaultPage.records = []
            this.getMarkets()
        },
        refreshMarketsWithKeyword () {
            this.searchPage = getDefaultPage()
            this.searchPage.pageSize = 50
            this.searchPage.records = []
            this.getMarketsWithKeyword()
        },
    },
    computed: {
        height () {
            return this.selectCounselor ? 425 : 375
        },
    },
    watch: {
        keyword () {
            this.refreshMarketsWithKeyword()
        },
    },
    created () {
        this.refreshMarkets()
    },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variables";

.consultant-select {
    width: 300px;
    height: var(--height);
    background-color: #ffffff;
    box-sizing: border-box;
}

.consultant-select > div {
    border-bottom: 1px solid #ccc;
}

.search-input-wrapper {
    padding: 10px 0;
    font-size: 12px;
}

.search-input {
    border: none;
    outline: none;
    padding: 10px;
    height: 25px;
    width: 100%;
}

.consultant-list {
    height: 310px;
    overflow: auto;
    background-color: #eaeaea;
}

.consultant-item {
    cursor: pointer;
    padding: 8px 30px;
    font-size: 14px;
    color: #3b3b3b;
    position: relative;
}

.consultant-item:hover {
    background-color: #f1f1f1;
}

.consultant-item:hover::after,
.consultant-item.selected::after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 5px;
    background-color: #b4b4b4;
    right: 0;
    top: 0;
}

.consultant-item.selected {
    color: $--color-primary;
}

.consultant-item.selected::after {
    background-color: $--color-primary;
}

.consultant-item:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.consultant-select-footer {
    padding: 10px 20px;
    border: none !important;
}

.confirm-button-wrapper {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: end;
}
</style>
<style>
.consultant-select-popover {
    padding: 0;
    border-radius: 0;
}
</style>
