<template>
    <el-select v-bind="$attrs"
               filterable
               remote
               :multiple="multiple"
               placeholder="输入教室名字搜索"
               :remote-method="remoteMethod"
               :loading="selectLoading"
               :value="value"
               @input="val=>$emit('update:value',val)"
               @change="handleChange"
    >
        <slot :options="options">
            <el-option v-for="option in options"
                       :key="option.id"
                       :value="option.id"
                       :label="option.roomName"></el-option>
        </slot>
    </el-select>
</template>
<script>
import {computed, defineComponent, getCurrentInstance, onMounted, ref, toRefs, watch, watchEffect} from 'vue'
import {isEqual} from '../../utils/ObjectUtils'
import {getTenantUserPageApi} from '../../api/system/tenantUserApi'
import {getClassroomDataApi} from '../../api/system/classRoomApi'


export default defineComponent({
    model: {
        prop: 'value',
        event: 'update:value'
    },
    emits: ['update:value','change'],
    props: {
        value: Array,
        multiple: {
            type: Boolean,
            default () {
                return false
            }
        },
        role: [Array, Number]
    },
    setup (props, {emit}) {

        const currentInstance = getCurrentInstance()

        const selectValue = ref()

        const selectLoading = ref(true)

        const defaultOptions = ref([])

        const searchOptions = ref()

        let searchParams = undefined

        const remoteMethod = (val) => {
            if (selectLoading.value) {
                return
            }
            if (val) {
                selectLoading.value = true
                const currentSearchParams = searchParams = {
                    keyword: val,
                    timestamp: new Date().getTime(),
                }
                getClassroomDataApi(searchParams)
                    .then(res => {
                        if (isEqual(searchParams, currentSearchParams)) {
                            searchOptions.value = res.data.data
                        }
                    }).finally(() => selectLoading.value = false)
            } else {
                searchOptions.value = undefined
            }
        }

        onMounted(() => {
            getClassroomDataApi({
                timestamp: new Date().getTime(),
            }).then(res => {
                defaultOptions.value = res.data.data.records
            }).finally(() => selectLoading.value = false)
        })



        const options = computed(() => {
            if (selectLoading.value) {
                return []
            }
            if (searchOptions.value === undefined) {
                return defaultOptions.value
            } else {
                return searchOptions.value.records
            }
        })
        const handleChange = (val)=>{
            let k = [];
            for (const key of options.value) {
                if(val.includes(key.id)){
                    k.push(key);
                }
            }
            emit('change',val,k);
        }
        return {
            ...toRefs(props),
            selectValue,
            selectLoading,
            options,
            remoteMethod,
            handleChange
        }
    }
})
</script>
