<template>
    <div>
        <el-dialog custom-modal
                   title="改期"
                   width="400px"
                   :visible="visible"
                   @update:visible="val=>$emit('update:visible',val)"
                   append-to-body>
            <el-form ref="formRef" :rules="fromRules" :model="formData" label-position="top" size="mini">
                <el-form-item prop="startTime" label="合同起始日期">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="formData.startTime" style="width: 100%">
                    </el-date-picker>
                </el-form-item>
                <el-form-item prop="endTime" label="合同终止日期">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="formData.endTime" style="width: 100%">
                    </el-date-picker>
                </el-form-item>
                <el-form-item prop="reason" label="原因">
                    <el-input type="textarea" v-model="formData.reason" :maxlength="250" show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <div v-if="!data.activationTime" class="un-activation-present">
                <i class="el-icon-warning-outline" style="margin-right: 3px;"></i>
                <span>未激活的合同改期成功后会自动激活并将填写的日期作为合同有效起止日期，若只想修改合同有效期天数不激活合同可在合同流水操作【改套餐】修改。</span>
            </div>
            <template v-slot:footer>
                <el-button class="ballet-modal-button" @click="$emit('update:visible',false)">取消</el-button>
                <el-tooltip :disabled="canSubmit" content="合同起止日期无变更" placement="top">
                    <el-button class="ballet-modal-button"
                               type="primary"
                               :disabled="!canSubmit"
                               :loading="confirmLoading"
                               @click="handleConfirm">
                        确认
                    </el-button>
                </el-tooltip>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {computed, defineComponent, inject, ref, toRefs, watch} from 'vue';
import {rescheduleApi} from '../../../api/student/contractApi';

export default defineComponent({
    props: {
        visible: Boolean,
    },
    emits: ['update:visible'],
    setup(props, {emit}) {

        const formRef = ref();

        const fromRules = ref({
            startTime: [{required: true, message: '合同起始日期是必填的'}],
            endTime: [{required: true, message: '合同终止日期是必填的'}],
            reason: [{required: true, message: '原因是必填的'}]
        });

        const formData = ref({
            startTime: undefined,
            endTime: undefined,
            reason: undefined
        });

        const confirmLoading = ref(false);

        const handleConfirm = () => {
            confirmLoading.value = true;
            formRef.value.validate()
                .then(() => rescheduleApi({...formData.value, contractId: data.value.id}))
                .then(() => {
                    emit('update:visible', false);
                    refreshWater();
                })
                .finally(() => {
                    confirmLoading.value = false;
                });
        };

        const refreshWater = inject('refreshWater');

        const getData = inject('getData');

        const data = computed(() => getData());

        const canSubmit = computed(() => {
            return !data.value.startTime || !data.value.endTime || data.value.startTime.split(" ")[0] !== formData.value.startTime || data.value.endTime.split(" ")[0] !== formData.value.endTime;
        });

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value = {
                    startTime: data.value.startTime ? data.value.startTime.split(" ")[0] : undefined,
                    endTime: data.value.endTime ? data.value.endTime.split(" ")[0] : undefined,
                    reason: undefined
                };
                setTimeout(() => formRef.value.clearValidate());
            } else {
                setTimeout(() => {
                    formData.value = {};
                    formRef.value.clearValidate();
                });
            }
        });

        return {
            ...toRefs(props),
            formRef,
            fromRules,
            formData,
            confirmLoading,
            data,
            canSubmit,
            handleConfirm
        };
    }
});
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 !important;
    color: #3b3b3b !important;
}

.un-activation-present {
    padding: 10px 5px;
    line-height: 1.5;
    background-color: #f5f7fa;
    color: #f5222d;
    font-size: 12px;
    word-break: break-word;
}
</style>
