import statistic from '@/router/more/statistic';
import pathConst from '@/router/more/pathConst';

export default [
    {
        path: pathConst.CONCURRENT_JOB_MANAGE,
        component: () => import('@/views/store/ConcurrentJobManage.vue'),
        name: 'concurrentJobManage',
        meta: {title: '兼职管理'}
    },
    {
        path: pathConst.EMPLOYEE,
        component: () => import('@/views/moreOptions/Employee.vue'),
        name: 'employee',
        meta: {title: '组织结构'}
    },
    {
        path: pathConst.EMPLOYEE_DETAIL,
        component: () => import('@/views/store/EmployeeDetail.vue'),
        name: 'employeeDetail',
        meta: {title: '员工详情'}
    },
    {
        path: pathConst.CENTER_CONFIG,
        component: () => import('@/views/res/balletchannelsource.vue'),
        name: 'CenterConfig',
        meta: {title: '中心设置'},
    },
    {
        path: pathConst.CENTER_STATISTIC,
        component: () => import('@/views/moreOptions/CenterStatistic.vue'),
        name: 'CenterStatistic',
        meta: {title: '中心统计', followChildren: true},
        children: statistic,
    },
    {
        path: pathConst.PACKAGE_MANAGE,
        component: () => import('@/views/moreOptions/PackageAndGiftManage.vue'),
        meta: {title: '套餐管理'}
    },
    {
        path: pathConst.ROOM_MANAGE,
        component: () => import('@/views/store/balletroom.vue'),
        meta: {title: '教室管理'}
    },
    {
        path: pathConst.CLASS_TYPE_MANAGE,
        component: () => import('@/views/store/balletclasstype.vue'),
        meta: {title: '课类管理'}
    },
    {
        path: pathConst.LIST_TRANSFER,
        component: () => import('@/views/store/ListTransfer.vue'),
        meta: {title: '名单出入'}
    },
    {
        path: pathConst.RESCHEDULE_CLASS,
        component: () => import('@/views/store/RescheduleClass.vue'),
        meta: {title: '补课管理'}
    },
    {
        path: pathConst.CENTER_PERMISSION,
        component: () => import('@/views/store/CenterPermission.vue'),
        meta: {title: '中心权限'}
    },
    {
        path: pathConst.CENTER_MENU_PERMISSION,
        component: () => import('@/views/store/CenterMenuPermission.vue'),
        meta: {title: '中心权限-菜单'}
    },
];

const meta = {
    name: '更多操作'
};

export {meta};
