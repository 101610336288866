export default [
    {
        path: 'product',
        component: () => import('@/views/cTerminal/Product.vue'),
        meta: {title: '商品管理'},
    },
    {
        path: 'order',
        component: () => import('@/views/cTerminal/Order.vue'),
        meta: {title: '订单管理'},
    },
    {
        path: 'homeBanner',
        component: () => import('@/views/cTerminal/HomeBanner.vue'),
        meta: {title: '首页轮播图'},
    },
    {
        path: 'videoGallery',
        component: () => import('@/views/cTerminal/VideoGallery.vue'),
        meta: {title: '视频集锦'},
    },
    {
        path: 'package',
        component: () => import('@/views/cTerminal/PackageManage.vue'),
        meta: {title: '套餐管理'},
    },
    {
        path: 'teacher',
        component: () => import('@/views/cTerminal/Teacher.vue'),
        meta: {title: '教师管理'},
    },
    {
        path: 'settings',
        component: () => import('@/views/cTerminal/Settings.vue'),
        meta: {title: '系统配置'},
    }
];
