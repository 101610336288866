<template>
    <el-select v-bind="$attrs"
               filterable
               remote
               placeholder="输入套餐名搜索"
               :remote-method="remoteMethod"
               :loading="selectLoading"
               v-model="selectValue">
        <el-option v-if="hasAllOption" label="全部" :value="undefined"></el-option>
        <slot :options="options">
            <el-option v-for="option in options"
                       :key="option.id"
                       :value="option.id"
                       :label="option.packageName"></el-option>
        </slot>
    </el-select>
</template>
<script>
import {computed, defineComponent, onActivated, onMounted, ref, toRefs, watch} from 'vue'
import {isEqual} from '../../utils/ObjectUtils'
import {getPackagePageApi} from '../../api/mall/packageApi'

export default defineComponent({
    model: {
        prop: 'value',
        event: 'update:value'
    },
    emits: ['update:value', 'change'],
    props: {
        value: [Number, Array],
        hasAllOption: Boolean
    },
    setup(props, {emit}) {

        const selectValue = ref()

        const selectLoading = ref(true)

        const defaultOptions = ref([])

        const searchOptions = ref()

        let searchParams = undefined

        const remoteMethod = (val) => {
            if (selectLoading.value) {
                return
            }
            if (val) {
                selectLoading.value = true
                const currentSearchParams = {name: val, timestamp: new Date().getTime()}
                searchParams = {name: val, timestamp: new Date().getTime()}
                getPackagePageApi(searchParams)
                    .then(res => {
                        if (isEqual(searchParams, currentSearchParams)) {
                            searchOptions.value = res.data.data.records
                        }
                    }).finally(() => selectLoading.value = false)
            } else {
                searchOptions.value = undefined
            }
        }

        onMounted(() => {
            getPackagePageApi({timestamp: new Date().getTime()})
                .then(res => {
                    defaultOptions.value = res.data.data.records
                }).finally(() => selectLoading.value = false)
        })

        const options = computed(() => {
            if (selectLoading.value) {
                return []
            }
            if (searchOptions.value === undefined) {
                return defaultOptions.value
            } else {
                return searchOptions.value
            }
        })

        watch(selectValue, newVal => {
            if (props.value !== newVal) {
                emit('update:value', newVal)
            }
        })

        watch(() => props.value, newVal => {
            selectValue.value = newVal
            if (newVal) {
                function emitChange() {
                    const option = options.value.find(option => option.id === newVal)
                    if (option) {
                        emit('change', newVal, option)
                    }
                }

                (function execute() {
                    if (selectLoading.value) {
                        setTimeout(execute, 100)
                    } else {
                        emitChange()
                    }
                })()
            }
        }, {immediate: true})

        return {
            ...toRefs(props),
            selectValue,
            selectLoading,
            options,
            remoteMethod,
        }
    }
})
</script>
