<template>
    <el-dialog custom-modal
               append-to-body
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               :show-close="false">
        <class-detail
            :class-id="classId"
            :full-refresh-only="fullRefreshOnly"
            :refresh-self-any-way="refreshSelfAnyWay"
            :show-controls="['REFRESH', 'BATCHSIGN']"
            @updated="handleUpdated"
            @close="$emit('update:visible',false);$emit('close')"
            v-if="visible">
        </class-detail>
    </el-dialog>
</template>
<script>
import {defineComponent, getCurrentInstance, provide, toRefs, watch} from 'vue';

export default defineComponent({
    props: {
        visible: Boolean,
        classId: Number,
        fullRefreshOnly: {
            type: Boolean,
            default() {
                return true;
            }
        },
        refreshSelfAnyWay: {
            type: Boolean,
            default() {
                return true;
            }
        },
    },
    emit: ['update:visible', 'refresh', 'updated'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        let updated = 0;

        let updatedVal = undefined;

        const handleUpdated = (val) => {
            if (props.fullRefreshOnly) {
                updated++;
            } else {
                updatedVal = val;
            }
        };

        provide('handleClassDelete', () => {
            emit('update:visible', false);
            emit('refresh');
        });

        provide('refreshClass', () => updated++);

        watch(() => props.visible, newVal => {
            if (!newVal) {
                if (updated > 0) {
                    if (props.fullRefreshOnly) {
                        emit('refresh');
                    } else {
                        emit('updated', updatedVal);
                    }
                }
                updated = 0;
            }
        });

        watch(() => currentInstance.proxy.$route.path, (oldVal, newVal) => {
            if (oldVal !== newVal) {
                emit('update:visible', false);
            }
        });

        return {
            ...toRefs(props),
            handleUpdated
        };
    }
});
</script>
<style scoped>
>>> .el-dialog__header {
    display: none;
}

>>> .el-dialog__body {
    padding: 0 !important;
}

>>> .today_down_contain_down > ul {
    min-height: 350px;
    padding-bottom: 60px !important;
}
</style>
