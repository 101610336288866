<template>
<div>
    <el-dialog
        title="添加跟进记录"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        width="540px"
        custom-modal
        append-to-body>
        <el-form size="mini"
                 :model="formData"
                 :rules="formRules"
                 ref="formRef"
                 label-position="top"
                 inline>
            <el-form-item label="跟进方式:" style="width: 45%;">
                <el-select style="width: 100%;" v-model="formData.followUpMethod" clearable>
                    <el-option :value="followUpMethod.value"
                               :label="followUpMethod.label"
                               :key="followUpMethod.value"
                               v-for="followUpMethod in $store.getters['common/followUpMethod']">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="服务类型:" style="width: 45%;">
                <el-select style="width: 100%;" v-model="formData.serviceType" clearable placeholder="请选择">
                    <el-option v-for="item in $store.getters['common/serviceTypeList']"
                               :key="item.value"
                               v-bind="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="会员标签:" style="width: 45%;">
                <el-select multiple
                           clearable
                           style="width: 100%;"
                           v-model="formData.tagIds"
                           placeholder="请选择">
                    <el-option v-for="item in $store.getters['common/vipTags']"
                               :key="item.value"
                               v-bind="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="跟进内容:" style="width: 100%;" prop="content">
                <el-input type="textarea"
                          clearable
                          v-model="formData.content"
                          placeholder=""
                          show-word-limit
                          :maxlength="250"></el-input>
            </el-form-item>
            <el-form-item label="下次跟进时间:">
                <el-date-picker
                    clearable
                    v-model="formData.nextTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :picker-options="pickerOptions"
                    placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="上传图片：" style="width: 100%;">
                <el-upload
                    :limit="9"
                    :action="baseUrl+'/file/upload'"
                    :headers="{'Access-Token':localStorage.getItem('token')}"
                    :on-success="handleUploadFileSuccess"
                    v-model:file-list="addFormDataFileList"
                    list-type="picture-card">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
        </el-form>

        <template v-slot:footer>
            <el-button
                style="margin:0 0 0 0;width:96px;height:30px;display:inline-flex;align-items:center;justify-content:center;"
                @click="$emit('update:visible',false)">
                取消
            </el-button>
            <el-button type="primary"
                       style="margin:0 10px 0 10px;width:96px;height:30px;background:#2fcc9a;display:inline-flex;align-items:center;justify-content:center;"
                       :loading="confirmLoading"
                       @click="handleConfirm">
                确认
            </el-button>
        </template>
    </el-dialog>
</div>
</template>
<script>
import {defineComponent, toRefs, ref, watch} from 'vue'
import http from '../../../utils/httpRequest'
import {addStudentFollowUpRecordApi} from '../../../api/student/studentFollowUpRecordApi'
import {cloneObject} from '../../../utils/ObjectUtils'

export default defineComponent({
    props: {visible: Boolean, studentId: Number},
    emits: ['update:visible', 'updated'],
    setup (props, {emit}) {

        const formRules = ref({
            content: [{required: true, message: '跟进内容是必填的'}]
        })

        const defaultFormData = {
            followUpMethod: undefined,
            serviceType: undefined,
            tagIds: [],
            content: undefined,
            nextTime: undefined
        }

        const formData = ref(cloneObject(defaultFormData))

        const addFormDataFileList = ref([])

        const formRef = ref()

        const confirmLoading = ref(false)

        const pickerOptions = {
            disabledDate (time) {
                return time.getTime() < Date.now()
            }
        }

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate().then(() => addStudentFollowUpRecordApi({
                ...formData.value,
                images: addFormDataFileList.value.map(item => {
                    return item.response.data
                }),
                studentId: props.studentId
            })).then(() => {
                emit('updated')
                emit('update:visible', false)
            }).finally(() => confirmLoading.value = false)
        }

        const handleUploadFileSuccess = (response, file, fileList) => {
            if (response.status) {
                addFormDataFileList.value = fileList
            } else {
                if (fileList.length) {
                    fileList[fileList.length - 1] = {...fileList[fileList.length - 1], status: 'error'}
                    addFormDataFileList.value = fileList
                }
            }
        }

        watch(() => props.visible, newVal => {
            if (!newVal) {
                formData.value = cloneObject(defaultFormData)
                formRef.value.resetFields()
            }
        })

        return {
            ...toRefs(props),
            formRules,
            formData,
            addFormDataFileList,
            formRef,
            confirmLoading,
            pickerOptions,
            handleConfirm,
            baseUrl: http.defaults.baseURL,
            handleUploadFileSuccess
        }
    }
})
</script>
<style scoped>
.el-form-item--mini .el-form-item__label {
    padding: 0 !important;
    font-size: 12px !important;
    color: #292e33 !important;
}
</style>
