/**
 * 检查课程信息是否符合禁用条件。
 *
 * @param {Object} classItem - 课程信息对象，包含课程相关的详细信息。
 * @param {Array<number>} suitClassCommonType - 普通课时适用课类数组，包含适用的课类标识。
 * @param {Array<number>} suitClassSpecialType - 特殊课时适用课类数组，包含适用的课类标识。
 * @param {Array<number>} commonClassApplication - 普通课时适用周期数组，包含适用的周期标识。
 * @param {Array<number>} specialClassApplication - 特殊课时适用周期数组，包含适用的周期标识。
 * @returns {boolean} 是否禁用，如果条件满足则返回true，否则返回false。
 */
import dayjs from 'dayjs'

let dateArr = ['周天', '周一', '周二', '周三', '周四', '周五', '周六']

const isDisable = (classItem, suitClassCommonType, suitClassSpecialType, commonClassApplication, specialClassApplication) => {

    suitClassCommonType = Array.isArray(suitClassCommonType) ? suitClassCommonType.map(Number) : []
    suitClassSpecialType = Array.isArray(suitClassSpecialType) ? suitClassSpecialType.map(Number) : []
    commonClassApplication = Array.isArray(commonClassApplication) ? commonClassApplication.filter(item => item !== '') : []
    specialClassApplication = Array.isArray(specialClassApplication) ? specialClassApplication.filter(item => item !== '') : []

    if (classItem.classType === 'Normal') {
        /**
         * suitClassCommonType 如果为空数组 则表示通用课程 直接返回false
         * commonClassApplication如果为空数组 则表示通用课程 直接返回false
         */
            //合同是否禁用 默认false为可用
        let classBoolean = true

        //周期是否禁用 默认false为可用
        let dateBoolean = true
        if (!suitClassCommonType && !commonClassApplication) {
            //如果课类适用周期为空数组 则表示通用课程 直接设置周期为不禁用状态
            dateBoolean = false
            classBoolean = false
        }
        //如果课类适用周期不为空数组 则需要判断是否适用
        if (commonClassApplication) {

            //获取课程当天日期是星期几
            const day = dayjs(classItem.startTime).day() + 1
            const week = dateArr[day]
            //如果普通课的适用周期内有该日期，则代表可用，设置为false，为不禁用状态
            if (commonClassApplication.includes(week) || commonClassApplication.length === 0) {
                dateBoolean = false
            } else {
                dateBoolean = true
            }
        } else {
            //如果课类适用周期为空数组 则表示通用课程 直接设置周期为不禁用状态
            dateBoolean = false
        }
        //如果课类适用不为空数组 则需要判断是否适用
        if (suitClassCommonType) {

            //如果课类适用内有该日期，则代表可用，设置为false，为不禁用状态
            if (suitClassCommonType.includes(classItem.classTypeId - 0) || suitClassCommonType.includes(classItem.childrenClassTypeId - 0)) {
                classBoolean = false
            } else {
                classBoolean = true
            }
        } else {
            //如果课类适用为空数组 则表示通用课程 直接设置为不禁用状态
            classBoolean = false
        }

        //如果classBoolean为true 表示课类不符合 直接返回true表示禁用
        if (classBoolean) {
            return true
            //如果!classBoolean表示课类符合，需要再次判断dateBoolean周期是否符合，如果为true代表不符合，返回true表示禁用
        } else if (!classBoolean && dateBoolean) {
            return true
        } else {
            return false
        }
    } else {
        /**
         * suitClassSpecialType 如果为空数组 则表示通用课程 直接返回false
         * specialClassApplication如果为空数组 则表示通用课程 直接返回false
         */
            //合同是否禁用 默认false为可用
        let classBoolean = true

        //周期是否禁用 默认false为可用
        let dateBoolean = true
        if (!suitClassSpecialType && !specialClassApplication) {
            //如果课类适用周期为空数组 则表示通用课程 直接设置周期为不禁用状态
            dateBoolean = false
            classBoolean = false
        }
        //如果课类适用周期不为空数组 则需要判断是否适用
        if (specialClassApplication) {
            //获取课程当天日期是星期几
            const day = dayjs(classItem.startTime).day()
            const week = dateArr[day]
            //如果普通课的适用周期内有该日期，则代表可用，设置为false，为不禁用状态
            if (specialClassApplication.includes(week) || specialClassApplication.length === 0) {
                dateBoolean = false
            } else {
                dateBoolean = true
            }
        } else {
            //如果课类适用周期为空数组 则表示通用课程 直接设置周期为不禁用状态
            dateBoolean = false
        }
        //如果课类适用不为空数组 则需要判断是否适用
        if (suitClassSpecialType) {
            //如果课类适用内有该日期，则代表可用，设置为false，为不禁用状态
            if (suitClassSpecialType.includes(classItem.classTypeId - 0) || suitClassSpecialType.includes(classItem.childrenClassTypeId - 0)) {
                classBoolean = false
            } else {
                classBoolean = true
            }
        } else {
            //如果课类适用为空数组 则表示通用课程 直接设置为不禁用状态
            classBoolean = false
        }
        //如果classBoolean为true 表示课类不符合 直接返回true表示禁用
        if (classBoolean) {
            return true
            //如果!classBoolean表示课类符合，需要再次判断dateBoolean周期是否符合，如果为true代表不符合，返回true表示禁用
        } else if (!classBoolean && dateBoolean) {
            return true
        } else {
            return false
        }
    }
}
export default isDisable
