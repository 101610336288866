import http from '@/utils/httpRequest';

export function getTransferCountApi () {
    return http.get('/listTransfer/count');
}

export function getTransferOutPageApi (params) {
    return http.get('/listTransfer/transferOutPage', {params});
}

export function getTransferInPageApi (params) {
    return http.get('/listTransfer/transferInPage', {params});
}

export function transferStoreCountApi (ids) {
    return http.get(`/listTransfer/transferStoreCount/${ids.join(',')}`);
}

export function updateTransferTypeApi (data) {
    return http.put('/listTransfer/updateTransferType', data);
}

export function batchUpdateTransferTypeApi (data) {
    return http.put('/listTransfer/batchUpdateTransferType', data);
}

export function transferWithdrawApi (data) {
    return http.put('/listTransfer/withdraw', data);
}

export function preBatchReceiveInfoApi (params) {
    return http.get('/listTransfer/preBatchReceiveInfo', {params});
}

export function transferBatchReceiveApi (data) {
    return http.post('/listTransfer/batchReceive', data);
}

export function transferRejectApi (data) {
    return http.post('/listTransfer/reject', data);
}
