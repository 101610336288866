<template>
    <el-dialog custom-modal
               width="470px"
               title="修改备注"
               :visible="visible"
               :modal-append-to-body="false"
               append-to-body>
        <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
            <el-form-item label="备注" prop="remark">
                <el-input type="textarea" :maxlength="250" show-word-limit v-model="formData.remark"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button @click="$emit('update:visible',false)">取 消</el-button>
            <el-button type="primary" @click="handleConfirm" :loading="confirmLoading"
                       :disabled="formData.remark===data.remark">
                确 定
            </el-button>
        </div>
    </el-dialog>
</template>
<script>
import {computed, defineComponent, inject, ref, toRefs} from "vue";
import {updateRemarkApi} from "../../../api/student/contractApi";

export default defineComponent({
    emits: ['update:visible'],
    props: {visible: Boolean},
    setup(props, {emit}) {

        const formData = ref({code: undefined});

        const formRules = ref({
            remark: [{required: true, message: "备注是必填的"}]
        });

        const confirmLoading = ref(false);

        const formRef = ref();

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate()
                .then(() => updateRemarkApi({
                    ...formData.value,
                    contractId: data.value.id
                }))
                .then(() => {
                    emit('update:visible', false)
                    refreshContract()
                    refreshContractLog()
                })
                .finally(() => confirmLoading.value = false)
        }

        const getData = inject('getData')

        const data = computed(() => getData())

        const refreshContract = inject('refreshContract')

        const refreshContractLog = inject('refreshContractLog')

        return {
            ...toRefs(props),
            formData,
            formRules,
            confirmLoading,
            formRef,
            data,
            handleConfirm
        }
    }
})
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 !important;
    color: #3b3b3b !important;
}
</style>