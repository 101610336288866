<template>
<div>
    <el-dialog
        custom-modal
        width="600px"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        title="修改合同适用学员"
        :close-on-click-modal="false"
        append-to-body>
        <el-form :model="formData" :rules="fromRules" ref="formRef" label-position="top" size="mini">
            <el-form-item label="旧适用学员">
                <div class="form-item-content">
                    {{ data.suitStudent === 1 ? '全部学员' : selectedStudent }}
                </div>
            </el-form-item>
            <el-form-item label="新课程">
                <div class="form-item-content">
                    <div style="margin-top: 5px;">
                        <el-radio-group v-model="formData.suitStudent">
                            <el-radio :label="1">全部适用</el-radio>
                            <el-radio :label="2">指定学员</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="student-check-box-list" v-if="formData.suitStudent===2">
                        <el-checkbox-group v-model="formData.studentIds">
                            <el-checkbox v-for="student in data.students"
                                         :key="student.id"
                                         :label="student.id">
                                {{ student.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="$emit('update:visible',false)">取 消</el-button>
            <el-button type="primary" @click="handleConfirm()" size="small" :disabled="confirmDisable">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import {computed, defineComponent, inject, ref} from 'vue'
import {updateApplicableRuleApi, updateApplicableStudent} from '../../../api/student/contractApi'
import PackageRuleSelect from '../../Form/PackageRuleSelect.vue'

export default defineComponent({
    components: {PackageRuleSelect},
    props: {
        visible: Boolean
    },
    emits: ['update:visible'],
    setup (props, {emit}) {

        const formRef = ref()

        const fromRules = ref({})

        const formData = ref({
            suitStudent: 1,
            studentIds: []
        })

        const confirmLoading = ref(false)

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate()
            .then(() => updateApplicableStudent({
                studentIds: formData.value.suitStudent === 1 ? [] : formData.value.studentIds,
                contractId: data.value.id
            }))
            .then(() => {
                emit('update:visible', false)
                refreshWater()
            })
            .finally(() => {
                confirmLoading.value = false
            })
        }

        const refreshWater = inject('refreshWater')

        const getData = inject('getData')

        const data = computed(() => getData())

        const selectedStudent = computed(() => {
            if (data.value.suitStudent === 2) {
                return data.value.applicableStudentIds.map(studentId => {
                    const student = data.value.students.find(student => student.id === studentId)
                    return student ? student.name : '未知'
                }).join(',')
            }
            return ''
        })

        const confirmDisable = computed(() => {
            if (data.value.suitStudent === 1) {
                return formData.value.suitStudent === 1
            } else if (data.value.suitStudent === 2) {
                if (formData.value.suitStudent === 2) {
                    const set = new Set(data.value.applicableStudentIds)
                    formData.value.studentIds.forEach(studentId => set.add(studentId))
                    return set.size === data.value.applicableStudentIds.length
                } else {
                    return false
                }
            }
            return true
        })

        return {
            formRef,
            fromRules,
            formData,
            data,
            selectedStudent,
            confirmDisable,
            confirmLoading,
            handleConfirm,
        }
    }
})
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 0 5px 0 !important;
    color: #3b3b3b !important;
    font-size: 16px !important;
}

.form-item-content {
    padding: 10px;
    background: #f0f0f0;
    border-radius: 5px;
    margin-top: 5px;
}

.student-check-box-list {
    padding: 10px;
    background-color: #f5f7fa;
    border-radius: 4px;
}

>>> .el-checkbox, >>> .el-radio {
    margin: 0 20px 10px 0 !important;
}
</style>
