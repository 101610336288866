import {cloneObject, copyPropertyTo} from '../../utils/ObjectUtils';
import {fixedRole} from '@/utils/const';
import {getSelfDetailApi} from '@/api/system/tenantUserApi';

export default {
    namespaced: true,
    state: {
        id: null,
        username: null,
        avatar: null,
        name: null,
        gender: null,
        fixedRole: null,
        roles: [],
        menus: [],
        permissions: [],
        _menuKeySet: new Set(),
        _permissionSet: new Set(),
        _promise: null
    },
    getters: {
        hasMenuKey(state) {
            return menuKey => {
                return fixedRole.Admin.code === state.fixedRole || state._menuKeySet.has(menuKey);
            };
        },
        hasPermission(state) {
            return permission => {
                return fixedRole.Admin.code === state.fixedRole || state._permissionSet.has(permission);
            };
        },
        getLoadStatePromise(state) {
            return state._promise;
        }
    },
    mutations: {
        getUserInfo(state, config) {
            let complete = undefined;
            let success = undefined;
            let fail = undefined;
            let refresh = false;
            if (typeof config === 'function') {
                complete = config;
            } else if (typeof config === 'object') {
                complete = config.complete;
                success = config.success;
                fail = config.fail;
                refresh = config.refresh;
            }
            if (!state._promise || refresh) {
                state._promise = getSelfDetailApi().then(res => {
                    this.commit('user/update', res.data.data);
                    return res;
                });
            }
            state._promise.then((res) => {
                if (typeof success === 'function') {
                    success(res);
                }
            }).catch((err) => {
                if (typeof fail === 'function') {
                    fail(err);
                }
            }).finally(() => {
                if (typeof complete === 'function') {
                    complete();
                }
            });
        },
        update(state, user) {
            copyPropertyTo(state, user);
            if (Array.isArray(user.menus) && user.menus.length) {
                state._menuKeySet = new Set(
                    user.menus.filter(menu => menu.menuKey).map(menu => menu.menuKey)
                );
            }
            if (Array.isArray(user.permissions) && user.permissions.length) {
                state._permissionSet = new Set(
                    user.permissions.map(permission => permission.resouceName)
                );
            }
        },
    }
};
