<template>
    <div class="icon-show">
        <template v-if="status === 'NoClasses'">
            <div class="operation_box" @click="handleSign">
                <img src="../../../assets/img/qian.png" alt="签到"/>
            </div>
            <div class="operation_box">
                <p @click="leaveModalVisible=true">假</p>
            </div>
            <div class="operation_box" @click="handleCancel">
                <img src="../../../assets/img/cha2.png" alt="叉"/>
            </div>
        </template>
        <template v-else>
            <el-tooltip
                class="classInfo"
                effect="dark"
                trigger="click"
                placement="top">
                <template v-slot:content>
                    <div class="operation-tooltip">
                        <table>
                            <tr>
                                <td>操作人：</td>
                                <td>{{
                                        studentClass.lastUpdateStatusOperatorName ? studentClass.lastUpdateStatusOperatorName : '未知'
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>备注:</td>
                                <td>{{
                                        studentClass.lastUpdateStatusReason ? studentClass.lastUpdateStatusReason : '无'
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>操作时间：</td>
                                <td>{{
                                        studentClass.lastUpdateStatusTime ? studentClass.lastUpdateStatusTime : '无'
                                    }}
                                </td>
                            </tr>
                        </table>
                        <div>
                            <el-button type="text" size="mini" @click="changeSignInStatusBoolean=true">
                                点击变更状态
                            </el-button>
                        </div>
                    </div>
                </template>
                <div v-if="status === 'SignedIn'" class="primary-text-color">
                    <span>已签到</span>
                    <span>(未迟到)</span>
                </div>
                <div v-else-if="status === 'SignedInLateness'" style="color: #faad14">
                    <span>已签到</span>
                    <span>(已迟到)</span>
                </div>
                <div v-else-if="status === 'LineUp'" style="color: #29cb97">
                    正在排队
                </div>
                <div v-else-if="status === 'LeaveOfAbsence'" style="color: #faad14">
                    已请假(扣课)
                </div>
                <div v-else-if="status === 'LeaveOfAbsenceNoConsume'" style="color: #faad14">
                    已请假(不扣课)
                </div>
                <div v-else-if="status === 'Canceled'" style="color: #f5222d">
                    已取消
                </div>
                <div v-else-if="status === 'Absenteeism'" style="color: #f5222d">
                    已旷课
                </div>
            </el-tooltip>
        </template>
        <div class="split"></div>
        <el-tooltip
            effect="dark"
            content="操作记录"
            placement="top">
            <div slot="content" style="cursor: pointer">
                <span @click="operationRecordModalVisible=true">操作记录</span>
            </div>
            <div style="cursor: pointer">
                <i class="el-icon-more" style="color: #292e33;font-size: 12px;"></i>
            </div>
        </el-tooltip>
        <!-- 修改签到状态的弹窗 -->
        <change-student-class-status :student-class-id="studentClassId"
                                     :status="status"
                                     :visible.sync="changeSignInStatusBoolean">
        </change-student-class-status>
        <!-- 请假弹窗 -->
        <leave-of-absence-modal
            :title="'请假'"
            :type="1"
            :leaveClassId="studentClassId"
            :visible.sync="leaveModalVisible">
        </leave-of-absence-modal>
        <!-- 操作记录 -->
        <student-class-option-record-and-message-modal :visible.sync="operationRecordModalVisible"
                                                       :student-class-id="studentClassId">
        </student-class-option-record-and-message-modal>
    </div>
</template>
<script>
import {defineComponent, inject, ref, toRefs} from 'vue';
import dayjs from 'dayjs';
import {batchSignInApi, changStudentClassStatusApi} from '../../../api/class/classApi';
import LeaveOfAbsenceModal from './LeaveOfAbsenceModal.vue';
import ChangeStudentClassStatus from './ChangeStudentClassStatus.vue';
import StudentClassOptionRecordAndMessageModal from './StudentClassOptionRecordAndMessageModal.vue';
import {openConfirmModal} from "@/utils/messageUtils";

export default defineComponent({
    components: {StudentClassOptionRecordAndMessageModal, ChangeStudentClassStatus, LeaveOfAbsenceModal},
    methods: {dayjs},
    props: {
        status: String,
        studentClassId: Number,
        classBeginTime: String,
        studentClass: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    setup(props, {emit}) {

        const leaveModalVisible = ref(false);

        const changeSignInStatusBoolean = ref(false);

        const operationRecordModalVisible = ref(false);

        const handleSign = () => {
            openConfirmModal('确认要签到吗？', () => batchSignInApi({ids: [props.studentClassId]}).then(() => {
                refreshClass();
            }));
        };

        const handleCancel = () => {
            openConfirmModal('确认要取消吗？', () => changStudentClassStatusApi({
                id: props.studentClassId,
                status: 'Canceled'
            }).then(() => {
                refreshClass();
            }));
        };

        const refreshClass = inject('refreshClass');

        return {
            ...toRefs(props),
            leaveModalVisible,
            changeSignInStatusBoolean,
            operationRecordModalVisible,
            handleSign,
            handleCancel
        };
    }
});
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variables";

.icon-show {
    display: inline-flex;
    align-items: center;
}

.operation_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > * {
        cursor: pointer;
    }

    img,
    p {
        width: 20px;
        height: 20px;
    }

    p {
        border-radius: 50%;
        color: #faad14;
        border: 1px solid #faad14;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        margin: 0 10px;
        padding-left: 3px;
        padding-right: 3px;
    }

    span {
        // width: 30px;
        height: 10px;
        line-height: 10px;
        text-align: center;
        border-left: 1px solid #dedede;
        margin-left: 10px;
    }
}

.split {
    background-color: #b4bfcc;
    width: 1px;
    height: 14px;
    margin: 0 5px;
    opacity: .5;
}

.operation-tooltip {
    table:nth-child(1) {
        border-bottom: 1px solid #ffffff;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    div:nth-child(2) {
        color: $--color-primary;
        text-align: center;
    }
}
</style>
