import http from '../../utils/httpRequest'

export function getStudentFollowUpRecordPageApi (studentId, params) {
    return http.get(`/followUpRecord/student/page/${studentId}`, {params})
}

export function addStudentFollowUpRecordApi (data) {
    return http.post('/followUpRecord/addStudentFollowUpRecord', data)
}

export function getStudentFollowUpDetailRecordApi (params) {
    return http.get(`/followUpRecord/student/detailPage`,{params})
}
