import http from "../../utils/httpRequest";

export function uploadFileToClassPhotoLibraryApi(data) {
    return http.post('/admin/balletclass/photo', data);
}

export function getClassPhotoLibraryApi(params) {
    return http.get('/admin/balletclass/photo', {params});
}

export function batchDeleteClassPhotoLibraryApi(data) {
    return http.delete(`/admin/balletclass/photo/batch`, {data});
}

export function batchDownloadClassPhotoLibraryApi(params) {
    return http.download.get('/admin/balletclass/photoBatchDownload', {params},'课节照片.zip');
}

export function sentStudentPhotoLibraryApi(data) {
    return http.post('/admin/balletclass/photo/sendToStudent', data);
}
