<template>
<div class="yueke_stu" v-loading="loading">
    <div class="filter">
        <div class="filter_left">
            <el-checkbox @input="openZeroClass"
                         style="margin: 0 5px 0 0"
                         :value="isZeroClass">
                0课时约课
            </el-checkbox>
            <p>优先扣除课时: <em>普通课时</em></p>
            <p>课节耗课: {{ consumeCount }}课时</p>
        </div>
        <div class="filter_right">
            <search-input :disabled="loading"
                          placeholder="请输入内容"
                          size="mini"
                          v-model="keyword"
                          @search="handleSearch">
                <template v-slot:append>
                    <div style="width: 100%;height: 100%;cursor: pointer;padding: 0 20px" @click="handleSearch">
                        搜索
                    </div>
                </template>
            </search-input>
        </div>
    </div>
    <el-table :data="tableData" style="width: 100%" max-height="500px">
        <el-table-column prop="studentInfo" label="学员信息" width="180">
            <template v-slot="scope">
                <div class="text">
                    <div>
                        <span>{{ scope.row.name }}</span>
                        <gender-view :gender="scope.row.sex"></gender-view>
                    </div>
                    <p>{{ getBirthdayDisplay(scope.row.birthdayTime, false) }}</p>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="contactsList" label="联系人" width="180">
            <template v-slot="scope">
                <div class="linkMan">
                    <p>
                        <span> {{ scope.row.contactsList[0].name }}</span>
                        <span :style="'color:black;'">
                            （{{
                                $store.getters['common/relationMap'][scope.row.contactsList[0].affiliation]
                            }}）
                        </span>
                    </p>
                    <span>{{ scope.row.contactsList[0].phone }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="construct"
            label="顾问&合同">
            <template v-slot="scope">
                <div class="counselor" style="width: 100%;">
                    <p v-if="scope.row.consultant">
                        {{ '顾问：' + scope.row.consultant.staffName }}
                    </p>
                    <change-contract-button v-if="scope.row.contract"
                                            :student-id="scope.row.id"
                                            :contract="{name:scope.row.contract.paperName,id:scope.row.contract.id}"
                                            click-mode="select"
                                            @change="contract=>{
                            scope.row.contract.id=contract.id
                            scope.row.contract.paperName=contract.paperName
                        }">
                    </change-contract-button>
                    <change-contract-button v-else
                                            :student-id="scope.row.id"
                                            :contract="{}"
                                            click-mode="select"
                                            @change="contract=>{
                            scope.row.contract.id=contract.id
                            scope.row.contract.paperName=contract.paperName?contract.paperName:contract.contractName
                        }">
                    </change-contract-button>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="operation"
            header-align="center"
            align="center"
            label="操作">
            <template v-slot="scope">
                <el-tooltip
                    :disabled="!(studentIds.has(scope.row.id)&&['SignedIn','SignedInLateness','Overdue','NoClasses','LineUp'].includes(studentStatusMap.get(scope.row.id+'')))"
                    placement="top"
                    content="该学员已预约该课程,请勿重复预约！">
                    <!--
                        todo 禁用逻辑待处理
                        所需参数
                            课程信息  透传
                            普通课时适用课类 待返回
                            特殊课时适用课类 待返回
                            普通课时适用周期 待返回
                            特殊课时适用周期 待返回
                        禁用逻辑未处理 调用util/isDisable
                    -->
                    <el-button type="primary"
                               plain
                               size="small"
                               :disabled="studentIds.has(scope.row.id)&&['SignedIn','SignedInLateness','Overdue','NoClasses','LineUp'].includes(studentStatusMap.get(scope.row.id+''))"
                               :loading="computedLoadingObject[scope.row.id]"
                               @click="confirmModalVisible=true;currentStudent=scope.row">
                        约课
                    </el-button>
                </el-tooltip>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog title="确认约课"
               :visible.sync="confirmModalVisible"
               custom-modal
               width="400px"
               append-to-body>
        <el-form label-width="150px" size="mini">
            <el-form-item label="强制约课：">
                <el-radio-group v-model="confirmFormData.force">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="发送用户端通知：">
                <el-radio-group v-model="confirmFormData.sendToUserClient">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <template v-slot:footer>
            <el-button @click="confirmModalVisible=false">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确认</el-button>
        </template>
    </el-dialog>
</div>
</template>

<script>
import {getBirthdayDisplay} from '../../../utils/studentUtils'
import {reserveApi} from '../../../api/class/classApi'
import {computed, defineComponent, getCurrentInstance, inject, onUnmounted, ref, toRefs} from 'vue'
import httpRequest from '../../../utils/httpRequest'
import GenderView from '../../Common/GenderView.vue'
import ChangeContractButton from './ChangeContractButton.vue'
import SearchInput from '@/components/Form/SearchInput.vue'

export default defineComponent({
    components: {SearchInput, ChangeContractButton, GenderView},
    props: {
        visible: Boolean,
        classId: Number,
        classNumberNo: String,
        consumeCount: Number,
        students: Array,
    },
    setup (props, {emit}) {

        const currentInstance = getCurrentInstance()

        const isZeroClass = ref(false)

        const keyword = ref()

        const tableData = ref([])

        const refreshClass = inject('refreshClass')

        const loadingObject = ref()

        const loading = ref(false)

        const confirmModalVisible = ref(false)

        const confirmFormData = ref({
            force: false,
            sendToUserClient: true
        })

        const currentStudent = ref()

        const handleConfirm = () => {
            confirmModalVisible.value = false
            const loading = {...computedLoadingObject.value}
            loading[currentStudent.value.id] = true
            loadingObject.value = JSON.stringify(loading)
            reserveApi({
                studentId: currentStudent.value.id,
                classId: props.classId,
                free: isZeroClass.value,
                ...confirmFormData.value
            }).then(res => {
                if (res.data.code === 40110) {
                    currentInstance.proxy.$confirm('当前课程已满人,是否强制约课?', {
                        type: 'warning',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                setTimeout(() => {
                                    confirmFormData.value.force = true
                                    handleConfirm()
                                })
                                done()
                            } else {
                                done()
                            }
                        }
                    })
                } else {
                    refreshClass()
                    currentInstance.proxy.$confirm('继续添加还是返回课程卡片?', '添加成功', {
                        type: 'warning',
                        confirmButtonText: '继续添加',
                        cancelButtonText: '返回',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                done()
                            } else {
                                done()
                                emit('update:visible', false)
                            }
                        }
                    })
                }
            }).finally(() => {
                const loading = {...computedLoadingObject.value}
                delete loading[currentStudent.value.id]
                loadingObject.value = JSON.stringify(loading)
            })

        }

        const openZeroClass = (flag) => {
            if (flag) {
                currentInstance.proxy.$confirm('确认开启 “零课时约课” 模式吗?', {
                    type: 'warning',
                }).then(() => {
                    isZeroClass.value = true
                })
            } else {
                currentInstance.proxy.$confirm('确认关闭 “零课时约课” 模式吗?', {
                    type: 'warning',
                }).then(() => {
                    isZeroClass.value = false
                })
            }
        }

        const handleSearch = () => {
            if (!keyword.value) {
                currentInstance.proxy.$notify({type: 'warning', message: '请先输入搜索内容再搜索！'})
                return
            }
            loading.value = true
            httpRequest({
                url: httpRequest.adornUrl('/admin/balletstudent/getList'),
                method: 'get',
                params: httpRequest.adornParams({
                    name: keyword.value,
                }),
            }).then(({data}) => {
                if (loading.value) {
                    tableData.value = data.list
                }
            }).finally(() => loading.value = false)
        }

        const computedLoadingObject = computed(() => {
            try {
                return JSON.parse(loadingObject.value)
            } catch (e) {
                return {}
            }
        })

        onUnmounted(() => {
            keyword.value = undefined
            isZeroClass.value = false
            tableData.value = []
            loading.value = false
        })

        const studentIds = computed(() => {
            if (Array.isArray(props.students) && props.students.length) {
                return new Set(props.students.filter(student => student.studentType === 'VipStudent').map(student => student.studentId))
            }
            return new Set()
        })

        const studentStatusMap = computed(() => {
            const map = new Map()
            if (Array.isArray(props.students) && props.students.length) {
                for (let student of props.students) {
                    map.set(student.studentId + '', student.status)
                }
            }
            return map
        })

        return {
            ...toRefs(props),
            loadingObject,
            isZeroClass,
            keyword,
            tableData,
            loading,
            confirmModalVisible,
            confirmFormData,
            currentStudent,
            computedLoadingObject,
            studentIds,
            studentStatusMap,
            handleConfirm,
            openZeroClass,
            handleSearch,
            getBirthdayDisplay
        }

    }
})
</script>

<style lang="scss" scoped>
header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #ccc;
    position: relative;

    img {
        position: absolute;
        width: 14px;
        height: 14px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    p {
        margin: 0;
        width: fit-content;
        padding: 0 10px;
        height: 60px;
        line-height: 60px;
        box-sizing: border-box;
        font-size: 14px;
        color: #292e33;
        cursor: pointer;
    }

    p.on {
        border-bottom: 4px solid #29cb97;
        color: #29cb97;
        font-weight: 500;
    }
}

.yueke_stu {
    padding: 0 15px;
    box-sizing: border-box;

    .filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;

        .filter_left {
            display: flex;
            align-items: center;
            gap: 5px;

            label {
                display: flex;
                align-items: center;
                margin-right: 20px;

                span {
                    cursor: pointer;
                }
            }

            p {
                margin: 0;

                em {
                    color: #29cb97;
                    font-style: normal;
                }
            }
        }
    }
}
</style>


<style lang="scss">
.el-form--label-top .el-form-item__label {
    padding-bottom: 0;
}

.addStudent-dialog {
    //height: 520px;

    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #ccc;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 40px;
        color: #292e33;
        display: none;

        i::before {
            position: relative;
            top: -10px;
        }

        span {
            font-size: 14px;
        }
    }

    .el-dialog__body {
        padding: 0 0 30px;
        box-sizing: border-box;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
        overflow: hidden;
        border-top: 1px solid #ccc;
        display: none;
    }
}
</style>
<style lang="scss" scoped>
.text {
    .sex,
    .wx {
        position: absolute;
        right: 80px;
        top: 0;
        width: 10px;
        height: 10px;
    }

    .sex {
        background: #fda6c9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        right: 95px;
    }

    p {
        color: #818c99;
        margin: 5px 0;
    }
}

.linkMan {
    width: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
        margin: 0;
    }

    span {
        color: #818c99;
        margin-top: 5px;
    }
}

.counselor {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
        margin: 0;
    }

    span {
        color: #818c99;
        margin-top: 5px;
    }
}
</style>
<style scoped>
>>> .el-input-group__append {
    padding: 0 !important;
}
</style>
