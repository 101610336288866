<template>
<el-dialog title="原合同信息"
           custom-modal
           :visible="visible"
           @update:visible="val=>$emit('update:visible',val)"
           width="600px"
           append-to-body>
    <div v-if="loading" v-loading="true"></div>
    <div v-else-if="visible" class="simple-contract-detail-wrap">
        <div class="block" v-if="contract.packageType===1">
            <div class="box">
                <span>总普通课时</span>
                <p>{{ contract.ordinaryClassCount + contract.giveOrdinaryClassCount }}</p>
            </div>
            <div class="box">
                <span>总特殊课时</span>
                <p>{{ contract.specialClassCount }}</p>
            </div>
            <div class="box">
                <span>已收金额</span>
                <p>{{ contract.amountReceived > 0 ? contract.amountReceived : '0.00' }}</p>
            </div>
        </div>
        <div class="block" v-else-if="contract.packageType===2">
            <div class="box">
                <div>每周上课课时数</div>
                <div>{{ contract.weekOrdinaryClassCount }}</div>
            </div>
            <div class="box">
                <div>周末上课次数</div>
                <div>{{ contract.weekendOrdinaryClassCount }}</div>
            </div>
            <div class="box">
                <div>总特殊课时</div>
                <div>{{ contract.specialClassCount }}</div>
            </div>
            <div class="box">
                <div>已收金额</div>
                <div>{{ contract.amountReceived > 0 ? contract.amountReceived : '0.00' }}</div>
            </div>
        </div>
        <div class="block">
            <div>
                <div>
                    <span>套餐类型</span>
                </div>
                <span>
                                    {{
                        contract.packageType === 1 ? '次卡' : (contract.packageType === 2 ? '年卡' : '')
                    }}
                                </span>
            </div>
            <div>
                <div>
                    <span>合同总价(元)</span>
                </div>
                <span>{{ contract.amountReceivable }}</span>
            </div>
            <div>
                <div>
                    <span>会员优惠(%)</span>
                </div>
                <span>
                                    {{
                        (1 - (contract.shouldMoney / contract.amountReceivable).toFixed(2)) * 100
                    }}%
                                </span>
            </div>
            <div>
                <div>
                    <span>应收总金额(元)</span>
                </div>
                <span style="font-weight:600;">{{ contract.shouldMoney }}</span>
            </div>
            <div>
                <div>
                    <span>签约日期</span>
                </div>
                <span>{{ contract.contractDate.split(' ')[0] }}</span>
            </div>
            <div>
                <div>
                    <span>合同状态</span>
                </div>
                <span v-if="contract.state === 6 || contract.state === 7"
                      style="color:red;">{{
                        $store.getters['common/contractStateMap'][contract.state]
                    }}</span>
                <span v-else style="color:#29cb97;">{{
                        $store.getters['common/contractStateMap'][contract.state]
                    }}</span>
            </div>
            <template v-if="contract.activationTime">
                <div>
                    <div>
                        <span>合同启用日期</span>
                    </div>
                    <span>
                                    {{ contract.startTime ? contract.startTime.split(' ')[0] : '-' }}
                                </span>
                </div>
                <div>
                    <div>
                        <span>终止日期</span>
                    </div>
                    <span>{{ contract.endTime ? contract.endTime.split(' ')[0] : '-' }}</span>
                </div>
            </template>
            <template v-else>
                <div>
                    <div>
                        <span>合同启用日期</span>
                    </div>
                    <span style="color: #f5222d;">未激活</span>
                </div>
                <div>
                    <div>
                        <span>终止日期</span>
                    </div>
                    <span style="color: #f5222d;">未激活</span>
                </div>
            </template>
            <div>
                <div>
                    <span>合同有效期</span>
                    <el-tooltip class="item" effect="dark" content="1月 = 30天" placement="top">
                        <img style="cursor:pointer;" src="../../../assets/img/wenhao2.png" alt="">
                    </el-tooltip>
                </div>
                <span>
                    {{
                        `${contract.leaveDate}天（${parseInt(contract.leaveDate / 12)}月${contract.leaveDate % 30}天）`
                    }}
                </span>
            </div>
            <div>
                <div>
                    <span>合同剩余有效期</span>
                    <el-tooltip class="item" effect="dark" content="1月 = 30天" placement="top">
                        <img style="cursor:pointer;" src="../../../assets/img/wenhao2.png" alt="">
                    </el-tooltip>
                </div>
                <span>
                    {{ getRemainingValidityPeriod(contract) }}
                </span>
            </div>
        </div>
        <div class="block">
            <div>
                <div>
                    <span>导入耗课金额</span>
                </div>
                <span>-</span>
            </div>
            <div>
                <div>
                    <span>耗课金额(元)</span>
                </div>
                <span>{{
                        currency(contract.amountReceived).subtract(contract.remainingAmount).value
                    }}</span>
            </div>
            <div>
                <div>
                    <span>手动耗课金额(元)</span>
                </div>
                <span>{{ contract.manualConsumeOrdinaryClassAmount }}</span>
            </div>
            <div>
                <div>
                    <span>剩余金额(元)</span>
                </div>
                <span style="color:#29cb97;">{{ contract.remainingAmount }}</span>
            </div>
            <div>
                <div>
                    <span>导入已耗普通课时</span>
                </div>
                <span>-</span>
            </div>
            <div>
                <div>
                    <span>已上普通课时</span>
                </div>
                <span>{{ contract.studiedOrdinaryClassConsumeCount }}</span>
            </div>
            <div>
                <div>
                    <span>普通课时手动耗课</span>
                    <el-tooltip class="item" effect="dark"
                                content="包含修改耗课及确认收入造成的已耗课变动"
                                placement="top">
                        <img style="cursor:pointer;" src="../../../assets/img/wenhao2.png" alt="">
                    </el-tooltip>
                </div>
                <span>{{ contract.manualConsumeOrdinaryClassCount }}</span>
            </div>
            <div v-if="contract.packageType === 1">
                <div>
                    <span>剩余普通课时</span>
                </div>
                <span>
                                    {{ contract.remainingOrdinaryClassCount }}
                                    <em>
                                        (已排:
                                        {{ contract.planedOrdinaryClassConsumeCount }}
                                        |可用:{{ contract.ordinaryClassCount + contract.giveOrdinaryClassCount }})
                                    </em>
                                </span>
            </div>
            <div v-else>
                <div>
                    <span>已排普通课时</span>
                </div>
                <span>{{ contract.planedOrdinaryClassConsumeCount }}</span>
            </div>
            <div>
                <div>
                    <span>导入已耗特殊课时</span>
                </div>
                <span>-</span>
            </div>
            <div>
                <div>
                    <span>已上特殊课时</span>
                </div>
                <span>{{ contract.studiedSpecialClassConsumeCount }}</span>
            </div>
            <div>
                <div>
                    <span>特殊课时手动耗课</span>
                    <el-tooltip class="item" effect="dark"
                                content="包含修改耗课及确认收入造成的已耗课变动"
                                placement="top">
                        <img style="cursor:pointer;" src="../../../assets/img/wenhao2.png" alt="">
                    </el-tooltip>
                </div>
                <span>{{ contract.manualConsumeSpecialClassCount }}</span>
            </div>
            <div>
                <div>
                    <span>剩余特殊课时</span>
                </div>
                <span>
                                    {{ contract.remainingSpecialClassCount }}
                                    <em>
                                        (已排:{{ contract.planedSpecialClassConsumeCount }}
                                        |可用:{{ contract.specialClassCount }})
                                    </em>
                                </span>
            </div>
        </div>
        <div>
            <div>适用学员</div>
            <div>
                <p v-if="contract.suitStudent === 1">全部学员</p>
                <p v-else>
                    {{ contract.students.map(stu => stu.name).join(',') }}
                </p>
            </div>
        </div>
    </div>
</el-dialog>
</template>
<script>
import {defineComponent, toRefs, watch, ref} from 'vue'
import {getContractDetailApi} from '@/api/student/contractApi'
import dayjs from 'dayjs'
import currency from 'currency.js'

export default defineComponent({
    props: {contractId: Number, visible: Boolean},
    emits: ['update:visible'],
    setup (props) {

        const loading = ref(false)

        const contract = ref()

        const getDetail = () => {
            loading.value = true
            getContractDetailApi(props.contractId)
                .then(res => {
                    contract.value = res.data.data
                })
                .finally(() => loading.value = false)
        }

        const getRemainingValidityPeriod = (contract) => {
            if (contract.activationTime) {
                const validityPeriod = dayjs(contract.endTime).diff(dayjs(), 'day')
                return `${validityPeriod}天（${parseInt(validityPeriod / 12)}月${validityPeriod % 12}天）`
            } else {
                return `${contract.leaveDate}天（${parseInt(contract.leaveDate / 12)}月${contract.leaveDate % 12}天）`
            }
        }

        watch(() => props.visible, newVal => {
            if (newVal) {
                getDetail()
            } else {
                contract.value = undefined
            }
        })

        return {
            ...toRefs(props),
            contract,
            loading,
            getRemainingValidityPeriod,
            currency
        }
    }
})
</script>
<style scoped>
.simple-contract-detail-wrap {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 14px;
    color: #292e33;
}

.simple-contract-detail-wrap > .block {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-bottom: 50px;
}

img {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-left: 3px;
}
</style>
