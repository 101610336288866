<template>
<div>
    <el-dialog batchAllocateModal :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               append-to-body
               width="44%">
        <div slot="title">
            <el-tabs v-model="active">
                <el-tab-pane label="批量转入" name="1"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="batch-allocate-body" v-loading="loading">
            <div class="batch-allocate-body-left">
                <search-input size="mini"
                              v-model="searchKeyword"
                              icon
                              @search="handleSearch">
                </search-input>
                <div class="left-title">
                    <div>
                        <span>市场（</span>
                        <span class="primary-text-color">{{ markets.length }}</span>
                        <span>）</span>
                    </div>
                    <el-button class="add-all-button"
                               type="text"
                               @click="marketSelectUsers=[...marketRecords]"
                               :disabled="!markets.length">
                        全部添加
                    </el-button>
                </div>
                <div>
                    <div class="item" v-for="market in markets" :key="market.id">
                        <div>{{ market.name }}</div>
                        <i class="el-icon-plus" @click="marketSelectUsers.push(market)"></i>
                    </div>
                </div>
            </div>
            <div class="batch-allocate-body-right">
                <div class="right-title">
                    <div>
                        <div class="right-title-label">名单总数：</div>
                        <div class="right-title-count">{{ computedAllowMarket.total }}</div>
                    </div>
                    <div>
                        <div class="right-title-label">可分配：</div>
                        <div class="right-title-count">{{ computedAllowMarket.canAllocate }}</div>
                    </div>
                    <div>
                        <div class="right-title-label">
                            <span>不可分配：</span>
                            <span style="color: red;">{{ computedAllowMarket.notAllow }}</span>
                        </div>
                        <div style="color: #b4bfcc;" class="right-title-label">
                            (无效名单：
                            {{ computedAllowMarket.notValid }} 已签约：
                            {{ computedAllowMarket.contracted }})
                        </div>
                    </div>
                </div>
                <div class="right-source-binding">
                    <div class="right-source-binding-old">
                        <div>将原来源渠道：</div>
                        <div class="old-source-item"
                             v-for="listSourceChannel in $store.getters['common/modifiableListSourceChannels']"
                             :key="listSourceChannel.name">
                            <div style="width: calc(100% - var(--icon-width))">
                                {{ listSourceChannel.name }}
                            </div>
                            <div style="width: var(--icon-width)">&gt;</div>
                        </div>
                    </div>
                    <div class="right-source-binding-new">
                        <div>接收到现在来源渠道：</div>
                        <div class="new-source-item"
                             v-for="listSourceChannel in $store.getters['common/modifiableListSourceChannels']"
                             :key="listSourceChannel.name">
                            <source-channel-select size="mini"
                                                   :props="{checkStrictly:true}"
                                                   style="width: 100%;"
                                                   :source-type.sync="sourceBinding[listSourceChannel.id].sourceType"
                                                   :value.sync="sourceBinding[listSourceChannel.id].sourceIds">
                            </source-channel-select>
                        </div>
                    </div>
                </div>
                <div class="right-table-help">
                    <div>
                        <el-button size="mini"
                                   :disabled="!alreadyAllowMarketCount"
                                   @click="resetCount(marketCount)">
                            清零数值
                        </el-button>
                        <el-button size="mini" type="primary" plain @click="avgAllocateMarket">平均分配</el-button>
                    </div>
                </div>
                <div class="right-table">
                    <el-table :data="marketSelectUsers"
                              :header-cell-style="{fontSize: '12px',color: '#3b3b3b',backgroundColor:'#f5f7fa',padding:0}">
                        <el-table-column label="市场" prop="name" align="center">
                            <template v-slot:header="scope">
                                <span>市场（</span>
                                <span class="primary-text-color">{{ marketSelectUsers.length }}</span>
                                <span>）</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="分配数量" align="center">
                            <template v-slot="{row}">
                                <div class="allocate-input">
                                    <div class="change-count-button"
                                         @click="handleChangeCount(marketCount,row.id,-1)">
                                        <i class="el-icon-minus"></i>
                                    </div>
                                    <el-input size="mini" v-model="marketCount[row.id]"
                                              style="width: calc(100% - 70px);"></el-input>
                                    <div class="change-count-button"
                                         @click="handleChangeCount(marketCount,row.id,1)">
                                        <i class="el-icon-plus"></i>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="清空市场" align="center">
                            <template v-slot:header="{row}">
                                <el-button :disabled="!marketSelectUsers.length"
                                           type="text"
                                           class="clear-button"
                                           @click="marketSelectUsers=[]">
                                    清空市场
                                </el-button>
                            </template>
                            <template v-slot="{row}">
                                <i class="el-icon-minus" style="cursor: pointer;" @click="removeMarket(row)"></i>
                            </template>
                        </el-table-column>
                        <template v-slot:empty>
                            <div></div>
                        </template>
                    </el-table>
                </div>
            </div>
        </div>
        <div slot="footer">
            <el-checkbox v-model="directReception" style="margin-right: 10px;">不分配市场直接接收</el-checkbox>
            <el-button @click="$emit('update:visible',false)">取消</el-button>
            <el-button type="primary" @click="handleConfirm" :loading="confirmLoading">确定</el-button>
        </div>
    </el-dialog>
</div>
</template>
<script>
import {getDefaultPage} from '../../utils';
import {getTenantUserPageApi} from '../../api/system/tenantUserApi';
import {fixedRole} from '../../utils/const';
import SearchInput from '@/components/Form/SearchInput.vue';
import {preBatchReceiveInfoApi, transferBatchReceiveApi} from '@/api/student/listTransferApi';
import SourceChannelSelect from '@/components/Form/SourceChannelSelect.vue';

export default {
    components: {SourceChannelSelect, SearchInput},
    emits: ['update:visible', 'ok'],
    props: {
        visible: {
            type: Boolean,
            default () {
                return false;
            }
        },
        selectedList: Array,
        timestamp: Number,
        useSelected: Boolean
    },
    data () {
        return {
            active: undefined,
            searchKeyword: undefined,
            marketPage: {...getDefaultPage(), pageSize: 100},
            marketRecords: [],
            marketSelectUsers: [],
            marketCount: {},
            unAllocateCount: {
                total: 0,
                canAllocate: 0,
                notAllow: 0,
                notValid: 0,
                contracted: 0
            },
            directReception: false,
            sourceBinding: undefined,
            selfTimestamp: undefined,
            confirmLoading: false,
            loading: false,
            searchUserLoading: false
        };
    },
    methods: {
        handleChangeCount (countMap, key, changeCount) {
            if (changeCount > 0) {
                if (this.alreadyAllowMarketCount === this.computedAllowMarket.canAllocate) {
                    return;
                }
            }
            countMap[key] = countMap[key] ? countMap[key] + changeCount : changeCount > 0 ? changeCount : 0;
        },
        resetCount (countMap) {
            for (let key in countMap) {
                countMap[key] = 0;
            }
        },
        removeMarket (market) {
            this.marketSelectUsers.splice(this.marketSelectUsers.indexOf(market), 1);
        },
        avgAllocateMarket () {
            if (!this.computedAllowMarket.canAllocate) {
                this.$notify.warning('当前无可分配名单！');
                return;
            }
            if (!this.marketSelectUsers.length) {
                this.$notify.warning('请先选择市场！');
                return;
            }
            this.resetCount(this.marketCount);
            const avg = Math.floor(this.computedAllowMarket.canAllocate / this.marketSelectUsers.length);
            const remainder = this.computedAllowMarket.canAllocate % this.marketSelectUsers.length;
            if (avg) {
                for (let key in this.marketCount) {
                    this.marketCount[key] = avg;
                }
            }
            if (remainder) {
                for (let i = 0; i < remainder; i++) {
                    this.marketCount[this.marketSelectUsers[i].id]++;
                }
            }
        },
        handleConfirm () {
            const handlePromise = (promise) => {
                promise.then(() => {
                    this.$emit('update:visible', false);
                    this.marketSelectUsers = [];
                    this.$emit('ok');
                }).finally(() => this.confirmLoading = false);
            };

            if (!this.alreadyAllowMarketCount && !this.directReception) {
                this.$notify.warning('请分配数量后再提交！');
                return;
            }

            this.confirmLoading = true;
            const promise = transferBatchReceiveApi({
                timestamp: this.useSelected ? this.timestamp : this.selfTimestamp,
                allocateInfo: this.marketCount,
                ids: this.useSelected ? this.selectedList.map(item => item.id) : [],
                directReception: this.directReception,
                sourceTypeMap: this.sourceBinding
            });
            handlePromise(promise);
        },
        getMarketPage () {
            this.searchUserLoading = true;
            getTenantUserPageApi({
                keyword: this.searchKeyword,
                currentPage: this.marketPage.currentPage,
                pageSize: this.marketPage.pageSize,
                timestamp: this.marketPage.timestamp,
                roles: [fixedRole.MasterMarket.code, fixedRole.Market.code],
            }).then(res => {
                this.marketRecords = res.data.data.records;
                this.marketPage.currentPage = res.data.data.current;
                this.marketPage.pageSize = res.data.data.size;
                this.marketPage.total = res.data.data.total;
            }).finally(() => {
                this.searchUserLoading = false;
            });
        },
        handleSearch () {
            this.getMarketPage();
        },
        initSourceBinding () {
            this.sourceBinding = {};
            const modifiableListSourceChannels = this.$store.getters['common/modifiableListSourceChannels'];
            for (const modifiableListSourceChannel of modifiableListSourceChannels) {
                this.sourceBinding[modifiableListSourceChannel.id] = {
                    sourceType: modifiableListSourceChannel.id,
                    sourceIds: []
                };
            }
        }
    },
    created () {
        this.initSourceBinding();
    },
    watch: {
        marketSelectUsers (newVal) {
            if (newVal.length) {
                for (const item of newVal) {
                    if (!this.marketCount[item.id]) {
                        this.$set(this.marketCount, item.id, 0);
                    }
                }
            } else {
                this.marketCount = {};
            }
        },
        active (newVal) {
            this.searchKeyword = undefined;
            this.getMarketPage();
            this.marketSelectUsers = [];
            this.marketCount = {};
            this.allocateIgnoreMarket = false;
        },
        visible (newVal) {
            if (newVal) {
                this.initSourceBinding();
                this.active = '1';
                if (this.useSelected) {
                    this.unAllocateCount = {
                        total: this.selectedList.length,
                        canAllocate: this.selectedList.length,
                        notAllow: 0,
                        notValid: 0,
                        contracted: 0
                    };
                } else {
                    this.loading = true;
                    this.selfTimestamp = new Date().getTime();
                    preBatchReceiveInfoApi({timestamp: this.selfTimestamp}).then(res => {
                        this.unAllocateCount = res.data.data;
                    }).finally(() => this.loading = false);
                }
            } else {
                this.active = undefined;
            }
        }
    },
    computed: {
        markets () {
            let marketerList = this.marketRecords;
            if (this.marketSelectUsers.length) {
                return marketerList.filter(marketer => !this.marketSelectUsers.find(user => user.id === marketer.id));
            }
            return marketerList;
        },
        computedAllowMarket () {
            return {
                total: this.unAllocateCount.total,
                canAllocate: this.unAllocateCount.canAllocate,
                notAllow: this.unAllocateCount.notValid + this.unAllocateCount.contracted,
                notValid: this.unAllocateCount.notValid,
                contracted: this.unAllocateCount.contracted
            };
        },
        alreadyAllowMarketCount () {
            let sum = 0;
            for (let value of Object.values(this.marketCount)) {
                sum += value;
            }
            return sum;
        }
    }
};
</script>
<style scoped>
>>> .el-dialog__body {
    overflow: auto;
    position: relative;
}
</style>
<style scoped lang="scss">
@import "../../assets/scss/variables";

.batch-allocate-body {
    display: flex;
    width: 100%;
    height: 400px;
}

.batch-allocate-body-left {
    top: 0;
    position: sticky;
    width: 250px;
    border-right: 1px solid #edf0f2;
    padding: 10px;
    height: 100%;
    overflow: auto;
}

.batch-allocate-body-right {
    width: calc(100% - 251px);
    padding-left: 10px;
}

.left-title {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    background-color: #f5f7fa;
    padding: 5px 15px;
    font-size: 12px;
}

.add-all-button {
    color: $--color-primary !important;
    text-decoration: underline;
    padding: 0;
    margin: 0;
    font-size: inherit;
}

.clear-button {
    color: red !important;
    text-decoration: underline;
    font-size: inherit;
}

.add-all-button.is-disabled, .clear-button.is-disabled {
    opacity: .3;
}

.item {
    padding: 5px 15px;
    display: flex;
    border-bottom: 1px solid #edf0f2;
    justify-content: space-between;
    align-items: center;
    color: #3b3b3b;
}

.item > i {
    color: #818c99;
    cursor: pointer;
}

.right-title, .right-table-help {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #edf0f2;
}

.right-table-help {
    justify-content: space-between;
}

.right-title > div {
    padding: 0 10px;
    flex: 1 1 0;
}

.right-title > div:not(:last-child) {
    border-right: 1px solid #edf0f2;
}

.right-title-label {
    font-size: 12px;
}

.right-title-count {
    color: $--color-primary;
    font-size: 20px;
}

.allocate-input {
    height: 25px;
    display: flex;
}

.change-count-button {
    border: 1px solid #edf0f2;
    margin: 0 5px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    color: #818c99;
    display: inline-block;
    vertical-align: middle;
}

.change-count-button:hover {
    color: #3b3b3b;
}

.right-source-binding {
    margin-top: 10px;
    display: flex;
    font-size: 12px;
    --icon-width: 40px;
}

.right-source-binding-old {
    width: calc(50% + var(--icon-width) / 2);
}

.right-source-binding-new {
    width: calc(50% - var(--icon-width) / 2);
}

.old-source-item, .new-source-item {
    display: flex;
    width: 100%;
    line-height: 28px !important;
}

.old-source-item:not(:last-child), .new-source-item:not(:last-child) {
    margin-bottom: 6px;
}
</style>
<style lang="scss">
@import "../../assets/scss/variables";

[batchAllocateModal] .el-dialog__header {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 0;
}

[batchAllocateModal] .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
}

[batchAllocateModal] .el-tabs__header.is-top {
    margin: 0;
}

[batchAllocateModal] .el-tabs__nav-wrap::after {
    display: none;
}

[batchAllocateModal] .el-table::before {
    display: none;
}

[batchAllocateModal] .allocate-input .el-input__inner {
    border-radius: 0;
    height: 25px;
    padding: 0 5px;
}

[batchAllocateModal] .el-tabs__item:not(.is-active) {
    color: #818c99;
}

[batchAllocateModal] .el-tabs__active-bar.is-top {
    width: 84px !important;
}
</style>
