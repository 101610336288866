<template>
    <div class="student-class-container">
        <div class="info p1 part">
            <slot></slot>
            <div class="zero-consume-tip" v-if="studentClass.consumeClassCount+studentClass.consumeGiveCount===0">
                0课时
            </div>
            <div class="wxTip" v-if="studentClass.isWx">微信</div>
            <vip-student-avatar :avatar="studentClass.avatar"
                                :gender="studentClass.gender"
                                :student-id="studentClass.studentId"
                                :default-contract-id="studentClass.defaultContractId"
                                :status="studentClass.status"
                                class="avatar"
                                @close="$emit('close')">
            </vip-student-avatar>
            <div class="text">
                <div style="display: flex;gap: 8px;border: none;align-items: center;">
                    <span style="width: fit-content;">{{ studentClass.name }}</span>
                    <gender-view :gender="studentClass.gender" style="border: none;padding: 0;"></gender-view>
                    <div v-if="studentClass.isReschedule" class="reschedule-tag">补课</div>
                </div>
                <p>{{ getBirthdayDisplay(studentClass.birthdayTime, false) }}</p>
                <div>{{ studentClass.isComment ? '已点评' : '未点评' }}</div>
                <img
                    src="../../../assets/img/wx.png"
                    alt=""
                    v-if="studentClass.isWx"
                    class="wx"/>
            </div>
        </div>
        <div class="linkMan p2 part">
            <p>
                {{ studentClass.mainContactName }}
                <span :style="'color:black;'">
                    ({{
                        $store.getters['common/relationMap'][studentClass.mainContactAffiliation]
                    }})
            </span>
            </p>
            <span>
            (+{{ studentClass.mainContactPhoneNumberArea }})
            {{ studentClass.mainContactPhoneNumber }}
        </span>
        </div>
        <div class="counselor p3 part">
            <div>
                {{ studentClass.consultantName }}
            </div>
            <change-contract-button style="width: 270px;color: #818c99;"
                                    :contract="{
                                   id:studentClass.defaultContractId,
                                   name:studentClass.contractName
                                }"
                                    click-mode="custom"
                                    :student-id="studentClass.studentId"
                                    :student-class-id="studentClass.id"
                                    @change="handleContractChange">
            </change-contract-button>
        </div>
        <div class="p4 part">
            <student-class-option :status="studentClass.status"
                                  :student-class-id="studentClass.id"
                                  :class-begin-time="studentClass.classStartTime"
                                  :student-class="studentClass">
            </student-class-option>
        </div>
    </div>
</template>
<script>
import {defineComponent, ref, watch} from 'vue';
import {getBirthdayDisplay} from '../../../utils/studentUtils';
import GenderView from '../../Common/GenderView.vue';
import StudentClassOption from './StudentClassOption.vue';
import VipStudentAvatar from './VipStudentAvatar.vue';
import ChangeContractButton from './ChangeContractButton.vue';
import {cloneObject} from '../../../utils/ObjectUtils';
import {changeContractApi} from "@/api/class/classApi";
import {openConfirmModal} from "@/utils/messageUtils";

export default defineComponent({
    components: {
        ChangeContractButton,
        VipStudentAvatar,
        StudentClassOption,
        GenderView,
    },
    props: {
        studentClass: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    emits: ['close'],
    setup(props) {

        const studentClass = ref();

        const handleContractChange = (contract) => {
            openConfirmModal('确认要切换为此合同吗？', () => changeContractApi({
                studentClassId: studentClass.value.id,
                contractId: contract.id
            }).then(() => {
                studentClass.value.contractName = contract.paperName ? contract.paperName : contract.contractName;
                studentClass.value.contractId = contract.id;
            }));
        };

        watch(() => props.studentClass, newVal => {
            studentClass.value = cloneObject(newVal);
        }, {deep: true, immediate: true});

        return {
            studentClass,
            handleContractChange,
            getBirthdayDisplay,
        };
    }

});
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variables";

.button-change-contract:hover {
    text-decoration: none;
}

.student-class-container {
    display: flex;
    align-items: center;
    height: 90px;
    padding: 0 10px;
    box-sizing: border-box;
    background: #fff;
    position: relative;
    width: 100%;

    & + & {
        border-bottom: 1px solid #dedede;
    }

    .zero-consume-tip {
        white-space: nowrap;
        background: #4fc3f7;
        color: #ffffff;
        font-size: 12px;
        position: absolute;
        left: -20px;
        top: -17px;
        padding: 2px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 12px 0;
        z-index: 1000;
    }

    .wxTip {
        width: 40px;
        height: 15px;
        background: $--color-primary;
        color: #fff;
        font-size: 11px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 11px 0;
    }


    .linkMan {
        // width: 160px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        p {
            margin: 0;
        }

        span {
            color: #818c99;
            margin-top: 5px;
        }
    }

    .counselor {
        // width: 230px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        div {
            margin: 0;
        }

        span {
            color: #818c99;
            // margin-top: 5px;
        }
    }
}

.info {
    // width: 230px;
    display: flex;
    align-items: center;
    position: relative;

    > .avatar {
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
        margin-left: 20px;
    }

    .text {
        .sex,
        .wx {
            position: absolute;
            right: 80px;
            top: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }

        .sex {
            background: #fda6c9;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 147px;
            padding: 3px;
            width: 15px;
            height: 15px;
            box-sizing: border-box;
        }

        span {
            display: inline-block;
            color: #000;
            width: 60px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        p {
            color: #818c99;
            margin: 0;
            //margin: 5px 0;
            margin-bottom: 5px;
        }

        article {
            display: flex;
            width: 110px;
            justify-content: space-between;
            white-space: nowrap;
        }

        div {
            width: fit-content;
            padding: 2px 5px;
            box-sizing: border-box;
            border: 1px solid #dedede;
            border-radius: 15px;
            font-size: 12px;
            line-height: 1;
        }
    }
}


.p0 {
    width: 25px;
    padding-right: 0.5em;
    box-sizing: border-box;
}

.h100 {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.p1 {
    width: 30%;
}

.p1 .student {
    cursor: pointer;
    float: left;
    height: 100%;
    font-size: 0.9rem;
    text-align: center;
    color: #818c99;
    margin: 0 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}

.item .p1 .name {
    margin-left: 5.5rem;
}

.item .p1 .age {
    margin-left: 5.5rem;
}

.p2 {
    width: 20%;
}

.p3 {
    flex: 1 1 0;
    box-flex: 1;
    -webkit-box-flex: 1;
    position: relative;
}

.p4 {
    min-width: 5em;
    cursor: pointer;
}

.part {
    vertical-align: middle;
    margin: 0;
}

.chocloud-icon {
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 18px;
    vertical-align: middle;
    color: #818c99;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.reschedule-tag {
    display: inline-flex;
    font-size: 12px;
    border: 1px solid $--color-primary !important;
    padding: 5px 10px;
    color: $--color-primary;
}
</style>
