<template>
    <div>
        <el-table :data="records"
                  :header-cell-style="{fontSize:'12px',backgroundColor:'#f7f7f7'}"
                  :cell-style="{fontSize:'12px',cursor:clickRowOpenListModal?'pointer':''}"
                  @row-click="handleRowClick">
            <el-table-column label="学员信息">
                <template v-slot="{row}">
                    <div v-for="student in row.students"
                         style="display: flex;gap: 5px;flex-wrap: wrap;align-items: center;">
                        <span>{{ student.studentName }}</span>
                        <gender-view :gender="student.studentGender"></gender-view>
                        <span>{{ getBirthdayDisplay(student.studentBirthdayTime) }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="来源/渠道" prop="source">
                <template v-slot="{row}">
                    <div>
                        {{ $store.getters['common/sourceTypeMap'][row.listSourceType] }}
                        {{ (row.sourceNamesStr ? '/' + row.sourceNamesStr : '') }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="联系人">
                <template v-slot="{row}">
                    <div style="white-space: pre-wrap;">{{ row.contactInfo }}</div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="顾问" prop="consultantName"></el-table-column>
            <el-table-column align="center" label="市场" prop="marketName"></el-table-column>
            <el-table-column align="center" label="邀约时间" prop="orderDate"></el-table-column>
            <el-table-column label="到访事宜" prop="orderInfo">
                <template v-slot="{row}">
                    <div @click.stop="openClassDetailModal(row)" class="primary-text-color"
                         v-if="row.visitFor==='Audition'">
                        <div>{{ row.classTypeName }}&nbsp;{{ row.childrenClassTypeName }}</div>
                        <div>{{ row.className }}</div>
                    </div>
                    <div v-else-if="row.visitFor==='Visit'">{{ row.reason }}</div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="授课老师" prop="teacherName">
                <template v-slot="{row}">
                    <div v-if="Array.isArray(row.teachers)&&row.teachers.length">
                        <span>主讲：</span>
                        <span>{{ row.teachers.map(item => item.name).join(',') }}</span>
                    </div>
                    <div v-if="Array.isArray(row.assistTeachers)&&row.assistTeachers.length">
                        <span>辅讲：</span>
                        <span>{{ row.assistTeachers.map(item => item.name).join(',') }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="到访状态">
                <template v-slot="{row}">
                    <template v-if="row.status==='NotVisited'">
                        <span v-if="dayjs(row.orderDate).toDate().getTime()>new Date().getTime()">未到访</span>
                        <span v-else style="color: #818c99;">超时未到</span>
                    </template>
                    <span v-else-if="row.status==='Visited'">已到访</span>
                    <span v-else-if="row.status==='Canceled'" class="fail-text-color">已取消</span>
                    <span v-else-if="row.status==='LeaveOfAbsence'" style="color: #faad14;">已请假</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="签约状态">
                <template v-slot="{row}">
                    <span v-if="row.contractStatus==='Contracted'">已签约</span>
                    <span v-else-if="row.contractStatus==='Unsigned'">未签约</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="首单签约金额(元)" prop="firstContractAmount">
                <template v-slot="{row}">
                    <span>
                        {{ row.firstContractAmount ? row.firstContractAmount : '-' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="130px" fixed="right">
                <template v-slot="{row}">
                    <div class="operation-buttons">
                        <template v-if="row.status==='NotVisited'">
                            <div class="sign-in-button"
                                 @click.stop="signInModalVisible=true;currentRow=row;">
                                签
                            </div>
                            <div class="sign-in-button"
                                 style="color: red;border: 1px solid red;"
                                 @click.stop="handleLeave(row)">
                                假
                            </div>
                            <div class="cancel-button"
                                 v-if="cancelLoadingObject[row.id]"
                                 @click.stop="handleCancel(row)"
                                 my-disabled>
                                <i class="el-icon-loading primary-text-color"></i>
                                <i class="el-icon-circle-close"></i>
                            </div>
                            <div class="cancel-button" v-else
                                 @click.stop="handleCancel(row)">
                                <i class="el-icon-circle-close"></i>
                            </div>
                            <div class="edit-button"
                                 @click.stop="editModalVisible=true;currentRow=row;">
                                <i class="el-icon-edit-outline"></i>
                            </div>
                        </template>
                        <template v-else-if="row.status==='Visited'">
                            <div class="cancel-sign-in-button"
                                 @click.stop="handleCancelSignIn(row)">
                                [取消签到]
                            </div>
                        </template>
                        <template
                            v-else-if="(row.status==='Canceled'||row.status==='LeaveOfAbsence')&&row.visitFor==='Audition'">
                            <el-tooltip placement="top"
                                        content="修改课位状态请点击到访事宜列中的课节进入课节详情进行操作">
                                <i @click.stop style="font-size: 25px;" class="el-icon-question"></i>
                            </el-tooltip>
                        </template>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-bind="page"
                       style="margin-top: 20px;margin-bottom: 20px;text-align: right;"
                       @current-change="val=>$emit('pageChange',val,page.pageSize)"
                       @size-change="val=>$emit('pageChange',page.pageSize,val)"
                       :page-sizes="[10,20,50,100]"
                       background>
        </el-pagination>
        <el-dialog width="20%" custom-modal title="补签到" append-to-body
                   :visible.sync="signInModalVisible">
            <el-form :rules="formRules" size="mini" :model="signInformData" ref="signInFormRef">
                <el-form-item label="到访日期：" prop="visitDate">
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择日期" type="datetime"
                                    v-model="signInformData.visitDate"></el-date-picker>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button @click="signInModalVisible=false">取消</el-button>
                <el-button type="primary" :loading="signInConfirmLoading" @click="handleSignIn">确定</el-button>
            </template>
        </el-dialog>
        <el-dialog width="20%" custom-modal title="改约" append-to-body :visible.sync="editModalVisible">
            <el-form :rules="formRules" size="mini" :model="editFormData" ref="editForm">
                <el-form-item label="到访日期：" prop="visitDate">
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="请选择日期"
                                    type="datetime"
                                    v-model="editFormData.visitDate">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button @click="editModalVisible=false">取消</el-button>
                <el-button type="primary" :loading="editConfirmLoading" @click="handleReschedule">确定</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import GenderView from "@/components/Common/GenderView.vue";
import {defineComponent} from "vue";
import {getDefaultPage} from "@/utils";
import dayjs from "dayjs";
import {cancelApi, cancelSignInApi, rescheduleApi, signInApi} from "@/api/student/listVisitApi";
import {getBirthdayDisplay} from "../../../utils/studentUtils";
import {openModal} from "@/utils/renderUtils";
import ClassDetailModal from "@/components/Class/ClassDetailModal.vue";

export default defineComponent({
    components: {GenderView},
    props: {
        records: Array,
        page: {
            type: Object,
            default() {
                return getDefaultPage();
            }
        },
        clickRowOpenListModal: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    emits: ['pageChange', 'updated'],
    data() {
        return {
            signInModalVisible: false,
            editModalVisible: false,
            signInformData: {},
            editFormData: {},
            signInConfirmLoading: false,
            editConfirmLoading: false,
            cancelLoadingObject: {},
            currentRow: undefined,
            formRules: {
                visitDate: [{required: true, message: '到访日期是必填的'}]
            },
        };
    },
    methods: {
        getBirthdayDisplay,
        dayjs,
        handleCancel(row) {
            if (this.signInConfirmLoading) {
                this.$notify({type: 'warning', title: '提示', message: '正在签到中,请稍后执行操作！'});
                return;
            }
            if (this.editConfirmLoading) {
                this.$notify({type: 'warning', title: '提示', message: '正在改约中,请稍后执行操作！'});
                return;
            }
            this.$confirm('确认要取消邀约吗？', {type: 'warning'})
                .then(() => {
                    this.$set(this.cancelLoadingObject, row.id, true);
                    return cancelApi({visitId: row.id});
                })
                .then(() => {
                    this.$emit('updated', row.orderDate, 'Canceled');
                    this.$emit('updated');
                })
                .finally(() => {
                    this.$set(this.cancelLoadingObject, row.id, false);
                });
        },
        handleSignIn() {
            if (this.cancelLoadingObject[this.currentRow.id]) {
                this.$notify({type: 'warning', title: '提示', message: '正在取消预约中,请稍后执行操作！'});
            } else {
                this.signInConfirmLoading = true;
                this.$refs.signInFormRef.validate()
                    .then(() => signInApi({visitId: this.currentRow.id, visitDate: this.signInformData.visitDate}))
                    .then(() => {
                        this.$emit('updated', this.currentRow.orderDate, 'Visited');
                        this.signInModalVisible = false;
                        this.$refs.signInFormRef.resetFields();
                        this.$emit('updated');
                    })
                    .finally(() => this.signInConfirmLoading = false);
            }
        },
        handleLeave(row) {
            this.$confirm('确认要请假吗？', {
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    this.$notify({type: 'warning', title: '提示', message: '请假功能暂未开放！'});
                    done();
                }
            });
        },
        handleReschedule() {
            if (this.cancelLoadingObject[this.currentRow.id]) {
                this.$notify({type: 'warning', title: '提示', message: '正在取消预约中,请稍后执行操作！'});
            } else {
                this.editConfirmLoading = true;
                this.$refs.editForm.validate()
                    .then(() => rescheduleApi({visitId: this.currentRow.id, visitDate: this.editFormData.visitDate}))
                    .then(() => {
                        this.$emit('updated', this.currentRow.orderDate, 'Canceled', this.editFormData.visitDate);
                        this.editModalVisible = false;
                        this.$refs.editForm.resetFields();
                        this.$emit('updated');
                    })
                    .finally(() => this.editConfirmLoading = false);
            }
        },
        handleCancelSignIn(row) {
            this.$confirm('确认要取消签到吗？', {
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        cancelSignInApi({visitId: row.id}).then(() => {
                            this.$emit('updated', row.orderDate, 'NotVisited');
                            this.$emit('updated');
                            done();
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                    }

                }
            });
        },
        handleRowClick(row) {
            if (this.clickRowOpenListModal) {
                openModal('list-detail-modal', {
                    listId: row.listId,
                    onUpdated: () => {
                        this.$emit('updated');
                    }
                });
            }
        },
        openClassDetailModal(row) {
            openModal(ClassDetailModal, {
                classId: row.classId,
                onUpdated: () => {
                    this.$emit('updated');
                }
            });
        }
    }
});
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variables";

.operation-buttons {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.sign-in-button {
    border: 1px solid $--color-primary;
    border-radius: 50%;
    color: $--color-primary;
    width: 18px;
    height: 18px;
    font-size: 10px;
    line-height: 18px;
    vertical-align: middle;
    cursor: pointer;
}

.edit-button, .cancel-button {
    color: #faad14;
    vertical-align: middle;
    cursor: pointer;
    font-size: 20px;
}

.cancel-button {
    color: red;
}

.cancel-sign-in-button {
    cursor: pointer;
}
</style>