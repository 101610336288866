<template>
    <div>
        <el-dialog
            title="课节照片库"
            :visible.sync="visible"
            @update:visible="val=>$emit('update:visible',val)"
            width="width"
            custom-class="classPhotoLibrary-dialog"
            append-to-body>
            <div class="menu">
                <el-button
                    style="background: #29cb97; border: none"
                    size="small"
                    type="primary"
                    @click="openUploadModal">
                    +&nbsp;&nbsp;点击上传
                </el-button>
                <el-button size="small" :disabled="multipleSelection.length==0" @click="sendStudentPhotoLibrary">
                    发送到学员照片库
                </el-button>
                <el-button size="small" :disabled="multipleSelection.length==0" @click="batchDownload"
                           v-loading="downloadButtonLoading">批量下载
                </el-button>
                <el-button size="small" :disabled="multipleSelection.length==0" @click="batchDelete"
                           v-loading="deleteButtonLoading">批量删除
                </el-button>
            </div>
            <div class="data">
                <el-table
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    v-loading="loadData"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="图片">
                        <template slot-scope="scope">
                            <img :src="scope.row.image" style="width:70px;" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="classStartTime" label="上课时间"></el-table-column>
                    <el-table-column prop="createTime" label="上传时间"></el-table-column>
                    <el-table-column prop="operation" label="操作">
                        <template slot-scope="scope">
                            <div class="operation">
                                <span @click="download(scope.row.image)">下载</span>
                                <span @click="deleteImage(scope.row.id)">删除</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="text-align: right;"
                    background
                    layout="total, prev, pager, next, sizes, jumper"
                    :total="total"
                    :current-page="page.currentPage"
                    :page-size="page.pageSize"
                    :page-sizes="[10, 20, 50, 100]"
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
                >
                </el-pagination>
            </div>
            <div slot="footer">
                <el-button @click="$emit('update:visible',false)">取 消</el-button>
                <el-button type="primary">确 定</el-button>
            </div>
            <UploadPhotoVue @uploadSuccess="getList" :classId="classId" :visible="uploadModalBoolean"
                            :closeUploadPhotoModal="closeUploadPhotoModal"></UploadPhotoVue>
            <class-phont-library-check-student
                v-if="checkStudentVisible"
                :visible.sync="checkStudentVisible"
                :close-class-photo-library-check-student="closeClassPhotoLibraryCheckStudent"
                :classId="classId"
                :check-photo="multipleSelection"
            ></class-phont-library-check-student>
        </el-dialog>
    </div>
</template>

<script>
//上传图片弹窗
import UploadPhotoVue from '../../UploadPhoto/UploadPhoto.vue';
import {
    batchDeleteClassPhotoLibraryApi,
    batchDownloadClassPhotoLibraryApi,
    getClassPhotoLibraryApi
} from "../../../api/class/classPhoto";
import {deleteTagFromStudentApi} from "../../../api/student/studentApi";
import ClassPhontLibraryCheckStudent from "./ClassPhontLibraryCheckStudent.vue";

export default {
    name: "BalletVueClassPhotoLibrary",
    //实际情况看是传递id调接口那数据还是传递数据对象
    props: ["visible", 'classId'],
    emits: ['update:visible'],
    data() {
        return {
            uploadModalBoolean: false,
            tableData: [],
            multipleSelection: [],
            page: {
                currentPage: 1,
                pageSize: 10,
            },
            loadData: false,
            total: 0,
            downloadButtonLoading: false,
            deleteButtonLoading: false,
            checkStudentVisible:false,
        };
    },
    components: {
        ClassPhontLibraryCheckStudent,
        UploadPhotoVue,
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            this.loadData = true;
            this.page.timestamp = new Date().getTime();
            this.page.classId = this.classId;
            getClassPhotoLibraryApi(this.page).then(res => {
                this.tableData = res.data.data.records || [];
                this.total = res.data.data.total;
            }).finally(() => {
                this.loadData = false;
            });
        },
        //打开上传弹窗
        openUploadModal() {
            this.uploadModalBoolean = true;
        },
        //发送到学员照片库
        sendStudentPhotoLibrary() {
            this.checkStudentVisible = true;
        },
        //批量下载
        batchDownload() {
            this.downloadButtonLoading = true;
            batchDownloadClassPhotoLibraryApi({
                ids: this.multipleSelection.map(a => a.id)
            }).then(res => {
                this.multipleSelection = [];
            }).finally(() => {
                this.downloadButtonLoading = false;
            });
        },
        //批量删除
        batchDelete() {
            this.$confirm('确认要删除吗？', {
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true
                        instance.confirmButtonText = '执行中...'
                        batchDeleteClassPhotoLibraryApi({
                            ids: this.multipleSelection.map(a => a.id)
                        }).then(res => {
                            this.multipleSelection = [];
                            this.getList();
                        }).finally(() => {
                            instance.confirmButtonLoading = false;
                            instance.confirmButtonText = '确定'
                            done();
                        });
                    } else {
                        done()
                    }
                }
            })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //删除
        deleteInfo(id) {
            this.tableData = this.tableData.filter(item => item.id !== id);
        },
        //关闭上传弹窗
        closeUploadPhotoModal() {
            this.uploadModalBoolean = false;
        },
        handleSizeChange(now) {
            this.page.pageSize = now;
            this.getList();
        },
        handlePageChange(now) {
            this.page.currentPage = now;
            this.getList();
        },
        async download(imageUrl) {
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = imageUrl; // 下载时文件的名称
            a.click();

            URL.revokeObjectURL(url);
        },
        deleteImage(id) {
            this.$confirm('确认要删除吗？', {
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true
                        instance.confirmButtonText = '执行中...'
                        batchDeleteClassPhotoLibraryApi({
                            ids: [id]
                        }).then(res => {
                            this.getList();
                            done();
                        }).finally(() => {
                            instance.confirmButtonLoading = false;
                        });
                    } else {
                        done()
                    }
                }
            })
        },
        closeClassPhotoLibraryCheckStudent(){
            this.checkStudentVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.menu {
    height: 55px;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
}

.data {
    padding: 30px;
    box-sizing: border-box;
}

.operation {
    display: flex;
    align-items: center;

    span {
        font-size: 12px;
        color: #292e33;
        cursor: pointer;

        &:nth-of-type(2) {
            color: #f5222d;
        }
    }

    span + span::before {
        content: '|';
        margin: 0 5px;
        position: relative;
        top: -1px;
        color: #292e33;
    }
}
</style>
<style lang="scss">
.classPhotoLibrary-dialog {
    .el-dialog__body {
        padding: 0;
        box-sizing: border-box;
        border-top: 1px solid #e5e5e5;
    }

    .el-dialog__footer {
        display: none;
    }
}
</style>
