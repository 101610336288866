<template>
<el-dialog title="修改手机号"
           custom-modal
           :visible="visible"
           width="400px"
           append-to-body
           @update:visible="val=>$emit('update:visible',val)">
    <el-form :model="formData"
             ref="formRef"
             :rules="formRules"
             size="mini"
             label-position="top">
        <el-form-item label="原手机号">
            <div>
                <span>(+{{ phoneNumberArea }})</span>
                <span>{{ phoneNumber }}</span>
            </div>
        </el-form-item>
        <el-form-item label="新手机号" prop="phoneNumber">
            <div style="display: flex;gap: 8px;">
                <el-select v-model="formData.phoneNumberArea" style="width: 70px;">
                    <el-option v-for="phoneNumberArea in $store.getters['common/phoneNumberAreas']"
                               v-bind="phoneNumberArea"
                               :key="phoneNumberArea.value">
                    </el-option>
                </el-select>
                <el-input placeholder="电话"
                          v-model="formData.phoneNumber"
                          style="width: 120px">
                    <template v-slot:prepend>
                        <span>{{ formData.phoneNumberArea ? '+' + formData.phoneNumberArea : '' }}</span>
                    </template>
                </el-input>
            </div>
        </el-form-item>
    </el-form>
    <template v-slot:footer>
        <el-button @click="$emit('update:visible',false)">取消</el-button>
        <el-tooltip :disabled="phoneNumber!==formData.phoneNumber" content="新手机号和原手机号一致" placement="top">
            <el-button type="primary"
                       @click="handleConfirm"
                       :loading="confirmLoading"
                       :disabled="phoneNumber===formData.phoneNumber">
                确定
            </el-button>
        </el-tooltip>
    </template>
</el-dialog>
</template>
<script>
import {defineComponent, getCurrentInstance, ref, toRefs, watch} from 'vue';
import {regexp} from '@/utils/const';
import {updateMainContactPhoneNumber} from '@/api/student/listApi';

export default defineComponent({
    props: {visible: Boolean, listId: Number, phoneNumberArea: String, phoneNumber: String},
    emits: ['update:visible', 'ok'],
    setup (props, {emit}) {

        const currentInstance = getCurrentInstance();

        const formData = ref({
            phoneNumberArea: undefined,
            phoneNumber: undefined
        });

        const formRules = ref({
            phoneNumber: [
                {required: true, message: '手机号是必填的'},
                {pattern: regexp.phoneNumber, message: '手机号格式不正确'},
            ],
        });

        const formRef = ref();

        const confirmLoading = ref(false);

        const handleConfirm = () => {
            confirmLoading.value = true;
            formRef.value.validate().then(() => updateMainContactPhoneNumber(
                {
                    listId: props.listId,
                    phoneNumberArea: formData.value.phoneNumberArea,
                    phoneNumber: formData.value.phoneNumber
                }
            )).then(() => {
                emit('update:visible', false);
                emit('ok');
            }).finally(() => {
                confirmLoading.value = false;
            });
        };

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value.phoneNumberArea = currentInstance.proxy.$store.getters['common/defaultPhoneNumberArea'];
                setTimeout(() => formRef.value.clearValidate());
            }
        });

        return {
            ...toRefs(props),
            formData,
            formRules,
            formRef,
            confirmLoading,
            handleConfirm
        };
    }
});
</script>

