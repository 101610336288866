<template>
    <div>
        <slot name="contract">
            <span>{{ contract.name }}</span>
        </slot>
        <el-button type="text"
                   style="cursor: pointer;margin: 0;padding: 0;"
                   @click.stop="changeContractModalModalVisible=true">
            [更换]
        </el-button>
        <contract-card-page-modal :visible.sync="changeContractModalModalVisible"
                                  :student-id="studentId"
                                  :checkContractId="contract.id"
                                  :click-mode="clickMode"
                                  @change="val=>$emit('change',val)">
        </contract-card-page-modal>
    </div>
</template>
<script>
import {defineComponent, ref, toRefs} from 'vue';
import ContractCardPageModal from '../../Contract/ContractCardPageModal.vue';

export default defineComponent({
    components: {ContractCardPageModal},
    props: {
        contract: {
            type: Object,
            default() {
                return {};
            }
        },
        studentId: Number,
        clickMode: String,
    },
    emits: ['change'],
    setup(props) {

        const changeContractModalModalVisible = ref(false);

        return {
            ...toRefs(props),
            changeContractModalModalVisible
        };
    }
});
</script>
