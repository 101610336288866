<template>
    <el-select
        v-bind="$attrs"
        :value="value"
        @input="val=>$emit('update:value',val)"
        filterable
        placeholder="请选择"
        size="mini">
        <el-option v-for="item in $store.getters['common/vipTags'].filter(it=>it.enableFlag)"
                   :key="item.value"
                   v-bind="item">
        </el-option>
    </el-select>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
    props: {value: [Number, Array]},
    emits: ['update:value'],
    model: {
        event: 'update:value',
        value: 'value'
    }
})
</script>