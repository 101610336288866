<template>
    <el-dialog custom-modal
               width="30%"
               title="添加学员"
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               append-to-body>
        <el-form :model="addStudentFormData"
                 size="mini"
                 inline
                 label-position="top"
                 :rules="formRules"
                 ref="formRef">
            <div style="display: flex;justify-content: space-between;">
                <el-form-item prop="name" style="width: 48%;margin: 0;" label="学员名字：">
                    <el-input v-model="addStudentFormData.name" :maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <el-form-item prop="nickName" style="width: 48%;margin: 0;" label="学员昵称：">
                    <el-input v-model="addStudentFormData.nickName" :maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <el-form-item prop="birthdayTime" style="width: 48%;margin: 0;" label="生日：">
                    <el-date-picker v-model="addStudentFormData.birthdayTime"
                                    value-format="yyyy-MM-dd"
                                    style="width: 100%">
                    </el-date-picker>
                </el-form-item>
                <el-form-item prop="sex" style="width: 48%;margin: 0;" label="性别：">
                    <gender-select style="width: 100%" v-model="addStudentFormData.sex"></gender-select>
                </el-form-item>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <el-form-item prop="school" style="width: 48%;margin: 0;" label="就读学校：">
                    <el-input v-model="addStudentFormData.school" :maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <el-form-item prop="grade" style="width: 48%;margin: 0;" label="就读年级：">
                    <el-input v-model="addStudentFormData.grade" :maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </div>
        </el-form>
        <div slot="footer">
            <el-button @click="$emit('update:visible',false)">取消</el-button>
            <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确认</el-button>
        </div>
    </el-dialog>
</template>
<script>
import {defineComponent, ref, toRefs} from 'vue';
import {addStudentApi} from '../../../api/student/listApi';
import {cloneObject} from '../../../utils/ObjectUtils';
import GenderSelect from '@/components/Form/GenderSelect.vue';

const defaultFormData = {
    name: undefined,
    nickName: undefined,
    birthdayTime: undefined,
    sex: 1,
    school: undefined,
    grade: undefined
};

export default defineComponent({
    components: {GenderSelect},
    emits: ['update:visible', 'ok'],
    props: {
        visible: Boolean,
        listId: Number
    },
    setup(props, {emit}) {

        const formRef = ref();

        const formRules = ref({
            name: [{required: true, message: '姓名是必填的'}],
            birthdayTime: [{required: true, message: '生日是必填的'}],
            sex: [{required: true, message: '请选择性别'}]
        });

        const addStudentFormData = ref(cloneObject(defaultFormData));

        const confirmLoading = ref(false);

        const handleConfirm = () => {
            confirmLoading.value = true;
            formRef.value.validate()
                .then(() => addStudentApi({
                    ...addStudentFormData.value,
                    birthdayTime: addStudentFormData.value.birthdayTime + ' 00:00:00',
                    listId: props.listId
                }))
                .then(() => {
                    formRef.value.resetFields();
                    addStudentFormData.value = cloneObject(defaultFormData);
                    emit('update:visible', false);
                    emit('ok');
                }).finally(() => confirmLoading.value = false);
        };

        return {
            ...toRefs(props),
            formRef,
            formRules,
            addStudentFormData,
            confirmLoading,
            handleConfirm
        };
    }
});
</script>
<style lang="scss" scoped>

</style>
