<template>
    <el-dialog
        title="发送对象"
        width="960px"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        append-to-body
        custom-modal>
        <div class="topSelect">
            <search-input
                @search="refreshPage"
                v-model="keyword"
                size="mini"
                style="width: 180px"
                icon>
            </search-input>
        </div>
        <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{background:'#f7f7f7',fontWeight:'normal',color:'#292e33'}">
            <el-table-column
                prop="studentInfo"
                label="学员信息">
                <template v-slot="scope">
                    <div class="infoBox">
                        <student-avatar class="avatar" :src="undefined" :gender="scope.row.sex"></student-avatar>
                        <div class="if">
                            <div style="display: flex;align-items: center;">
                                <span>{{ scope.row.name }}</span>
                                <gender-view :gender="scope.row.sex"></gender-view>
                            </div>
                            <span>{{ getBirthdayDisplay(scope.row.birthdayTime, false) }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="linkman"
                label="联系人">
                <template v-slot="scope">
                    <div v-if="Array.isArray(scope.row.contacts)&&scope.row.contacts.length">
                        <span>{{ scope.row.contacts.name ? scope.row.contacts.name : '未知' }}</span>
                        <span>{{ scope.row.contacts.phone ? scope.row.contacts.phone : '未知' }}</span>
                    </div>
                    <div v-else>无</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="source"
                label="来源">
                <template v-slot="scope">
                    <div v-if="scope.row.listTable">
                        <span>{{ scope.row.listTable.sourceType }}</span>
                    </div>
                    <div v-else>未知</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="wx"
                label="微信通知">
                <template v-slot="scope">
                    <div v-if="scope.row.wx">
                        <span>{{ scope.row.wx }}</span>
                    </div>
                    <div v-else>未知</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态">
                <template v-slot="scope">
                    <div v-if="scope.row.status">
                        <span>未读</span>
                    </div>
                    <div v-else>未知</div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="total, prev, pager, next, sizes, jumper"
            v-bind="page"
            style="text-align: right;margin-bottom: 20px;margin-top: 20px;"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
    </el-dialog>
</template>
<script>
import {defineComponent, ref, toRefs, watch} from "vue";
import SearchInput from "@/components/Form/SearchInput.vue";
import {getDefaultPage} from "@/utils";
import {getReceptionStudentListApi} from "@/api/motion/message";
import StudentAvatar from "@/components/Common/StudentAvatar.vue";
import dayjs from "dayjs";
import GenderView from "@/components/Common/GenderView.vue";
import {getBirthdayDisplay} from "../../utils/studentUtils";

export default defineComponent({
    methods: {getBirthdayDisplay},
    components: {GenderView, StudentAvatar, SearchInput},
    props: {
        visible: Boolean,
        motionUniqueId: {
            required: true
        },
    },
    emits: ['update:visible'],
    setup(props) {

        const page = ref(getDefaultPage());

        const keyword = ref(undefined);

        const tableData = ref([]);

        const refreshPage = () => {
            tableData.value = [];
            page.value = getDefaultPage();
            page.value.pageSize = 20;
            getPage();
        };

        const getPage = () => {
            getReceptionStudentListApi(Object.assign({}, page.value, {
                timestamp: new Date().getTime(),
                motionUniqueId: props.motionUniqueId
            })).then(res => {
                tableData.value = res.data.data.records;
                page.value.total = res.data.data.total;
            });
        };

        watch(() => props.visible, (newVal) => {
            if (newVal) {
                refreshPage();
            }
        });

        const handleCurrentChange = (nowCurrentPage) => {
            page.value.currentPage = nowCurrentPage;
            getPage();
        };

        const handleSizeChange = (nowSize) => {
            page.value.pageSize = nowSize;
            getPage();
        };

        return {
            ...toRefs(props),
            keyword,
            tableData,
            page,
            refreshPage,
            getPage,
            handleCurrentChange,
            handleSizeChange,
        };
    }
});

</script>
<style scoped lang="scss">
.topSelect {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
}

.infoBox {
    display: flex;
    align-items: center;

    .avatar {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .if {
        display: flex;
        flex-direction: column;
        line-height: 18px;
        margin-left: 5px;

        > span:nth-of-type(1) {
            font-size: 12px;
            color: #818c99;
        }

        > div {
            > span {
                margin-right: 5px;
            }
        }
    }
}
</style>