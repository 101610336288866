<template>
<img :src="src" alt="头像" v-if="src">
<img v-else-if="gender===1" src="https://lolita-api.oss-cn-shenzhen.aliyuncs.com/images/member_avatars/default_male.png" alt="头像">
<img v-else src="https://lolita-api.oss-cn-shenzhen.aliyuncs.com/images/member_avatars/default_female.png" alt="头像">
</template>
<script>
import {defineComponent, toRefs} from 'vue'

export default defineComponent({
    props: {
        src: String,
        gender: Number
    },
    setup (props) {
        return {
            ...toRefs(props)
        }
    }
})
</script>
