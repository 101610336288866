<template>
<div>
    <el-dialog custom-modal
               title="编辑采单信息"
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               width="520px"
               custom-class="ballet-vue-change-contract"
               append-to-body>
        <el-form size="mini" :model="formData" label-position="top">
            <el-form-item label="采单日期" prop="purchaseOrderDate">
                <el-date-picker v-model="formData.purchaseOrderDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="采单备注" prop="remark">
                <el-input type="textarea" v-model="formData.remark" :maxlength="250" show-word-limit></el-input>
            </el-form-item>
        </el-form>
        <template v-slot:footer>
            <el-button class="ballet-modal-button" @click="$emit('update:visible',false)">取消</el-button>
            <el-button class="ballet-modal-button" type="primary" :loading="confirmLoading" @click="handleConfirm">
                确定
            </el-button>
        </template>
    </el-dialog>
</div>
</template>
<script>
import {defineComponent, ref, toRefs, watch} from 'vue'
import {updatePurchaseOrderRecordApi} from '../../../api/student/listFollowUpRecordApi'

export default defineComponent({
    props: {
        visible: Boolean,
        data: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    emits: ['update:visible','updated'],
    setup (props, {emit}) {

        const formData = ref({
            recordId: undefined,
            purchaseOrderDate: undefined,
            remark: undefined
        })

        const confirmLoading = ref()

        const pickerOptions = {
            disabledDate (time) {
                return time.getTime() > Date.now()
            }
        }

        const handleConfirm = () => {
            confirmLoading.value = true
            updatePurchaseOrderRecordApi({...formData.value}).then(() => {
                emit('update:visible', false)
                emit('updated')
            }).finally(() => confirmLoading.value = false)
        }

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value = {...props.data}
            }
        })

        return {
            ...toRefs(props),
            formData,
            confirmLoading,
            pickerOptions,
            handleConfirm
        }
    }
})
</script>
