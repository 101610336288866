<template>
    <div>
        <el-dialog
            custom-modal
            :modal="true"
            width="500px"
            :visible="visible"
            @update:visible="val=>$emit('update:visible',val)"
            title="分单业绩更改"
            append-to-body>
            <el-table :data="formData" :header-cell-style="{backgroundColor:'#f9f9f9'}">
                <el-table-column align="center" label="对象" prop="tenantUserName">
                    <template v-slot:header>
                        <div>
                            <span>对象</span>
                            <span style="color: red">*</span>
                        </div>
                    </template>
                    <template v-slot="{row,$index}">
                        <div v-if="$index>oldData.length">
                            <performance-attribution-select v-model="row.tenantUserId"></performance-attribution-select>
                        </div>
                        <div v-else>{{ row.tenantUserName }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="原金额" prop="oldAmount">
                    <template v-slot:header>
                        <div>
                            <span>原金额</span>
                            <span style="color: red">*</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="新金额" prop="newAmount">
                    <template v-slot:header>
                        <div>
                            <span>新金额</span>
                            <span style="color: red">*</span>
                        </div>
                    </template>
                    <template v-slot="{row,$index}">
                        <div>
                            <el-input style="width: 70%" v-model="row.newAmount"></el-input>
                            <img v-if="$index>oldData.length" alt=""
                                 @click="removeRow($index)"
                                 style="width: 20px;height: 20px;margin-left: 5px;cursor: pointer;"
                                 src="../../../assets/img/jianhao.png">
                            <div v-if="errors[$index]" style="color: red;font-size: 12px;">错误的金额,请重新填写</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div style="background-color: #f9f9f9;padding: 5px 0;">
                <img @click="addRow" alt="" src="../../../assets/img/jiahao2.png"
                     style="width: 20px;height: 20px;margin-left: 40px;cursor: pointer;">
            </div>
            <div>
                <div>
                    <span>原由</span>
                    <span style="color: red">*</span>
                </div>
                <el-input v-model="reason" placeholder=""></el-input>
                <div style="font-size: 12px;color: red;" v-if="reason!==undefined&&!reason">原由是必填的</div>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button class="ballet-modal-button" @click="$emit('update:visible',false)">取 消</el-button>
            <el-button class="ballet-modal-button"
                       type="primary"
                       size="small"
                       :disabled="confirmDisable"
                       :loading="confirmLoading"
                       @click="handleConfirm">
                确 定
            </el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import {computed, defineComponent, inject, ref, toRefs, watch} from "vue";
import PerformanceAttributionSelect from "../../Form/PerformanceAttributionSelect.vue";
import {regexp} from "../../../utils/const";
import {updatePerformanceSpiltApi} from "../../../api/student/contractApi";

export default defineComponent({
    components: {PerformanceAttributionSelect},
    props: {visible: Boolean, records: Array},
    emits: ['update:visible'],
    setup(props, {emit}) {

        let oldData = []

        const formData = ref([])

        const reason = ref()

        const confirmLoading = ref(false)

        const addRow = () => {
            formData.value.push({
                tenantUserId: undefined,
                tenantUserName: undefined,
                oldAmount: 0,
                newAmount: undefined,
            })
        }

        const removeRow = (index) => {
            formData.value.splice(index, 1)
        }

        const handleConfirm = () => {
            confirmLoading.value = true;
            updatePerformanceSpiltApi({
                contractId: data.value.id,
                performanceSpilts: formData.value.map(item => {
                    return {tenantUserId: item.tenantUserId, amount: item.newAmount}
                })
            }).then(() => {
                refreshContract()
                refreshWater()
                emit('update:visible', false)
            }).finally(() => confirmLoading.value = false)
        }

        const refreshContract = inject('refreshContract')

        const refreshWater = inject('refreshWater')

        const getData = inject('getData')

        const data = computed(() => getData())

        const errors = computed(() => {
            if (formData.value.length) {
                const result = {}
                for (let i = 0; i < formData.value.length; i++) {
                    if (!regexp.amount.test(formData.value[i].newAmount)) {
                        result[i] = "error"
                    }
                }
                return result
            }
            return {}
        })

        const confirmDisable = computed(() => {
            if (Object.keys(errors.value).length) {
                return true
            } else if (!reason.value) {
                return true
            }
            return false
        })

        watch(() => props.visible, newVal => {
            if (newVal) {
                if (Array.isArray(props.records) && props.records.length) {
                    formData.value = oldData = props.records.map(item => {
                        return {
                            tenantUserId: item.tenantUserId,
                            tenantUserName: item.tenantUserName,
                            oldAmount: item.amount,
                            newAmount: item.amount,
                        }
                    })
                } else {
                    formData.value = oldData = []
                }
            }
        })

        return {
            ...toRefs(props),
            oldData,
            formData,
            reason,
            confirmLoading,
            errors,
            confirmDisable,
            addRow,
            removeRow,
            handleConfirm
        };
    }
})

</script>
<style lang="scss" scoped>
.req {
    margin: 0;
    font-size: 14px;
    color: #292e33;

    &::after {
        content: '*';
        color: #f00;
    }
}
</style>
