<template>
    <el-dialog
        title="选择合同"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        width="550px"
        custom-class="checkContract-dialog"
        append-to-body>
        <!--    v-if="visible" 不能去掉,否则再次打开弹窗子组件onMounted()不会触发    -->
        <contract-card-page v-if="visible"
                            :default-contract-id="checkContractId"
                            :student-id="studentId"
                            :click-mode="clickMode"
                            @change="handleChange">
        </contract-card-page>
    </el-dialog>
</template>

<script>
import ContractCardPage from '../Student/detail/ContractCardPage.vue';
import {toRefs} from 'vue';

export default {
    components: {ContractCardPage},
    props: {
        checkContractId: Number,
        studentId: Number,
        visible: Boolean,
        clickMode: String,
    },
    emits: ['update:visible', 'change'],
    setup(props, {emit}) {

        const handleChange = (val) => {
            emit('change', val);
            if (props.studentClassId || props.clickMode === 'select') {
                emit('update:visible', false);
            }
        };

        return {
            ...toRefs(props),
            handleChange
        };
    }
};
</script>

<style lang="scss" scoped>
.filter {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    p {
        margin: 0;
        padding: 0 10px;
        font-size: 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.3px solid #d8dee9;
        cursor: pointer;
    }

    p:nth-of-type(1) {
        border-radius: 4px 0 0 4px;
    }

    p:last-child {
        border-radius: 0 4px 4px 0;
    }

    p.on {
        border: 0.3px solid #28cb97;
        color: #28cb97;
    }
}

.contract_item:hover {
    box-shadow: 0 0 10px #ccc;
    transform: translateY(-3px);
}

.contract_item {
    overflow: hidden;
    transition: all 0.2s;
    box-shadow: 0 0 2px #ccc;

    article + article {
        margin-top: 10px;
    }

    width: 423px;
    position: relative;
    height: 327px;
    background: #fff;
    padding: 10px;
    box-sizing: border-box;
    margin: 0 10px 10px 0;

    .top_title {
        p {
            font-size: 18px;
            color: #29cb97;
            margin: 0;
        }

        span {
            font-size: 12px;
            color: #818c99;
        }
    }

    .operation {
        display: flex;
        align-items: center;

        > p + p {
            margin-left: 5px;
        }

        > p {
            width: fit-content;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            background: #29cb97;
            color: #fff;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .money,
    .time {
        display: flex;
        // margin: 10px 0;
        align-items: center;

        img {
            width: 14px;
            height: 14px;
        }

        p {
            margin: 0;
            margin-left: 10px;

            span {
                color: #f5222d;
            }
        }
    }

    .classTime {
        display: flex;
        // margin-top: 10px;
        align-items: flex-start;

        img {
            width: 14px;
            height: 14px;
            position: relative;
            top: 4px;
        }

        p {
            margin: 0;
            margin-left: 10px;
        }

        span {
            margin-left: 10px;
            color: #29cb97;
            cursor: pointer;
        }

        > div {
            div:nth-of-type(1) {
                padding: 0;
                margin-left: 5px;
            }

            div:nth-of-type(2) {
                // margin: 5px 0;
            }

            div {
                display: flex;
                align-items: center;
                // padding-left: 5px;
                box-sizing: border-box;

                .el-progress {
                    width: 130px;
                }

                p {
                    margin: 0;
                    // margin-left: 5px;
                    span {
                        color: #29cb97;
                    }
                }
            }
        }
    }

    .isDefault {
        position: absolute;
        right: 10px;
        bottom: 10px;
        cursor: pointer;
    }

    .num_tab {
        position: absolute;
        right: -50px;
        top: -10px;
        width: 80px;
        height: 40px;
        background: #29cb97;
        transform: rotate(45deg);
    }

    .num_p {
        font-size: 12px;
        color: #fff;
        position: absolute;
        right: 2px;
        top: 0;
    }
}
</style>
<style lang="scss">
.checkContract-dialog {
    .el-dialog__header {
        height: 50px;
        border-bottom: 1px solid #ccc;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 50px;
        color: #292e33;
        font-size: 16px;
    }

    .el-dialog__body {
        padding: 0;
        box-sizing: border-box;
        height: 600px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}
</style>
