<template>
<el-cascader v-bind="$attrs"
             v-model="currentValue"
             :options="modifiableOnly?$store.getters['common/modifiableListSourceChannels']:$store.getters['common/sourceChannels']"
             :props="{value: 'id', label: 'name', emitPath: true,multiple: multiple,...props}">
</el-cascader>
</template>
<script>
import {defineComponent, ref, toRefs, watch} from 'vue';
import {isEqual} from '../../utils/ObjectUtils';

export default defineComponent({
    props: {
        value: Array,
        sourceType: [String, Array],
        props: {
            type: Object,
            default () {
                return {};
            }
        },
        multiple: {
            type: Boolean,
            default () {
                return false;
            }
        },
        modifiableOnly: {
            type: Boolean,
            default () {
                return true;
            }
        }
    },
    emits: ['update:value', 'update:sourceType'],
    setup (props, {emit}) {

        const currentValue = ref([]);

        let selfUpdate = false;

        watch(currentValue, newVal => {
            selfUpdate = true;
            if (props.multiple) {
                if (Array.isArray(newVal) && newVal.length) {
                    emit('update:value', newVal.map(item => item.slice(1)));
                    emit('update:sourceType', newVal.map(item => item[0]));
                } else {
                    emit('update:value', []);
                    emit('update:sourceType', []);
                }
            } else {
                if (Array.isArray(newVal) && newVal.length) {
                    emit('update:value', newVal.slice(1));
                    emit('update:sourceType', newVal[0]);
                } else {
                    emit('update:value', []);
                    emit('update:sourceType', undefined);
                }
            }
        });

        watch(() => [props.sourceType, props.value], (newVal) => {
            if (selfUpdate) {
                selfUpdate = false;
            } else {
                if (props.multiple) {
                    if (Array.isArray(props.sourceType) && props.sourceType.length) {
                        if (Array.isArray(props.value) && props.value.length) {
                            const val = [];
                            for (let i = 0; i < props.sourceType.length; i++) {
                                val[i] = [props.sourceType[i]];
                                if (Array.isArray(props.value[i])) {
                                    //要求排序必须一致
                                    val[i] = [props.sourceType[i], ...props.value[i]];
                                } else {
                                    val[i] = [props.sourceType[i]];
                                }
                            }
                            currentValue.value = val;
                            return;
                        }
                        currentValue.value = props.sourceType.map(item => [item]);
                        return;
                    }
                    currentValue.value = [];
                } else {
                    if (props.sourceType) {
                        const val = Array.isArray(props.value) && props.value.length ? [props.sourceType, ...props.value] : [props.sourceType];
                        if (!isEqual(currentValue.value, val)) {
                            currentValue.value = val;
                        }
                    } else {
                        if (currentValue.value.length) {
                            currentValue.value = [];
                        }
                    }
                }
            }
        }, {immediate: true, deep: true});

        return {
            ...toRefs(props),
            currentValue
        };
    }
});
</script>
