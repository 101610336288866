const regexp = {
    amount: /^\d+(\.\d{1,2})?$/,
    phoneNumber: /^1[3-9]\d{9}$/,
    email:/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
}

const fixedRole = {

    Admin: {code: -1, name: '管理员'},

    DirectorOfEducation: {code: 2, name: '教导主任'},

    MasterConsultant: {code: 4, name: '主顾问/销售'},

    MasterMarket: {code: 8, name: '主市场'},

    Market: {code: 16, name: '市场'},

    Consultant: {code: 32, name: '顾问/销售'},

    MasterCustomerService: {code: 64, name: '主客服'},

    CustomerService: {code: 128, name: '客服'},

    MasterTeacher: {code: 256, name: '主老师'},

    Teacher: {code: 512, name: '老师'},

    Finances: {code: 1024, name: '财务'},
}

export {regexp, fixedRole}
