import http from '../../utils/httpRequest';

export function getTenantUserPageApi(params) {
    return http.get('/sys/user/page', {params});
}

export function createUserApi(data) {
    return http.post('/sys/user/create', data);
}

export function getUserDetailApi(id) {
    return http.get(`/sys/user/${id}`);
}

export function getSelfDetailApi() {
    return http.get(`/sys/user/selfDetail`);
}

export function operationRecordPageApi(params) {
    return http.get('/sys/user/operationRecordPage', {params});
}

export function resourceCountApi(userId) {
    return http.get(`/sys/user/resourceCount/${userId}`);
}

export function listPageApi(params) {
    return http.get("/sys/user/listPage", {params});
}

export function vipStudentPageApi(params) {
    return http.get("/sys/user/vipStudentPage", {params});
}

export function updateEmailApi(data) {
    return http.put(`/sys/user/updateEmail`, data);
}

export function updatePhoneNumberApi(data) {
    return http.put(`/sys/user/updatePhoneNumber`, data);
}

export function storeRoleListApi(userId) {
    return http.get(`/sys/user/storeRole/${userId}`);
}

export function updateStoreRoleApi(data) {
    return http.put(`/sys/user/storeRole`, data);
}

export function settingUpSeparationApi(data) {
    return http.put(`/sys/user/settingUpSeparation`, data);
}
