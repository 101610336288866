<template>
    <div class="today_down_contain"
         :ref="computedClassInfo.id"
         :id="computedClassInfo.id"
         v-loading="loading">
        <div class="today_down_contain_top">
            <div class="operation">
                <div class="operation_top">
                    <div>
                        <p :class="['class-title',{over:computedClassInfo.isOver}]">
                            <span>{{ computedClassInfo.title }}</span>
                            <span>&nbsp;{{ computedClassInfo.name }}</span>
                            <span>
                                (<template v-if="computedClassInfo.classTypeName">
                                    {{ computedClassInfo.classTypeName }}
                                </template>
                                <template v-if="computedClassInfo.childrenClassTypeName">
                                    {{
                                        computedClassInfo.classTypeName ?
                                            ' ' + computedClassInfo.childrenClassTypeName :
                                            computedClassInfo.childrenClassTypeName
                                    }}
                                </template>)
                            </span>
                        </p>
                        <template v-if="commence">
                            <p v-if="computedClassInfo.classTotalCount > 0">
                                第{{ computedClassInfo.classSpeed }}节/共{{ computedClassInfo.classTotalCount }}节
                            </p>
                        </template>
                    </div>
                    <div>
                        <template v-if="showControls.includes('REFRESH')&&commence">
                            <img @click="handleRefresh($event)"
                                 alt=""
                                 src="../../../assets/img/reload.png"/>
                        </template>
                        <template v-if="showControls.includes('FOLD')">
                            <img alt=""
                                 :src="commence?require('../../../assets/img/reduce.png'):require('../../../assets/img/magnify.png')"
                                 @click="commence=!commence">
                        </template>
                    </div>
                </div>
                <div class="operation_down" v-if="commence">
                    <el-tooltip
                        effect="dark"
                        v-for="operationButton in operationButtons"
                        :key="operationButton.id"
                        :style="{display:operationButton.show===undefined||operationButton.show()?'':'none'}"
                        :content="operationButton.id !== 3? operationButton.text: computedClassInfo.isLock?  '解锁该课程':'锁定该课程'"
                        placement="top">
                        <template v-if="operationButton.id!==3">
                            <img :src="operationButton.url"
                                 @click="operationButton.onClick(computedClassInfo)"
                                 alt="">
                        </template>
                        <template v-if="operationButton.id===3">
                            <img :src="operationButton.url(computedClassInfo)"
                                 @click="operationButton.onClick(computedClassInfo)"
                                 alt="">
                        </template>
                    </el-tooltip>
                </div>
                <div v-else class="miniInfo">
                    <p>
                        <span>时间: </span>
                        <span>{{ dayjs(computedClassInfo.beginTime).format('YYYY-MM-DD') }}</span>
                    </p>
                    <p>
                        <span>老师: </span>
                        <span v-if="Array.isArray(computedClassInfo.mainTeachers)&&computedClassInfo.mainTeachers">
                            {{ computedClassInfo.mainTeachers.map(item => item.name).join(',') }}
                        </span>
                    </p>
                    <p>
                        <span>教室: </span>
                        <span v-if="Array.isArray(computedClassInfo.classrooms)&&computedClassInfo.classrooms.length">
                            {{ computedClassInfo.classrooms.map(item => item.name).join(',') }}
                        </span>
                    </p>
                </div>
            </div>
            <div class="information">
                <div v-if="commence" class="information_top">
                    <p>
                        <span>时间:</span>
                        <span>{{ dayjs(computedClassInfo.beginTime).format('YYYY-MM-DD') }}</span>
                        <span>{{ dayjs(computedClassInfo.beginTime).format('HH:mm') }}</span>
                        <span>-</span>
                        <span>{{ dayjs(computedClassInfo.endTime).format('HH:mm') }}</span>
                    </p>
                    <p>
                        <span>耗课:</span>
                        <span>{{ computedClassInfo.consumeCount }}</span>
                    </p>
                    <p>
                        <span>老师:</span>
                        <span v-if="Array.isArray(computedClassInfo.mainTeachers)&&computedClassInfo.mainTeachers">
                            {{ computedClassInfo.mainTeachers.map(item => item.name).join(',') }}
                        </span>
                        <span style="font-size: 0.7rem;color:#818c99;"
                              v-if="Array.isArray(computedClassInfo.assistTeachers)&&computedClassInfo.assistTeachers">
                            ({{ computedClassInfo.assistTeachers.map(item => item.name).join(',') }})
                        </span>
                    </p>
                    <p>
                        <span>试听:</span>
                        <span>{{ computedClassInfo.isAudition ? '可试听' : '不可试听' }}</span>
                    </p>
                    <p>
                        <span>教室:</span>
                        <span v-if="Array.isArray(computedClassInfo.classrooms)&&computedClassInfo.classrooms.length">
                            {{ computedClassInfo.classrooms.map(item => item.name).join(',') }}
                        </span>
                    </p>
                </div>
                <div class="information_down">
                    <div v-for="(classStatusTab, ind) in classStatusTabs"
                         :key="classStatusTab.code"
                         v-if="classStatusTab.show(computedClassInfo)"
                         @click="selectedStatusIndex = ind"
                         :class="classStatusTabs[selectedStatusIndex].code === classStatusTab.code? 'on': ''">
                        <el-tooltip
                            effect="dark"
                            placement="top"
                            v-if="classStatusTab.tooltip"
                            :content="classStatusTab.tooltip(computedRows)">
                            <div slot="content" v-html="classStatusTab.tooltip(computedRows)"></div>
                            <div>
                                <div v-html="classStatusTab.countDisplay(computedClassInfo)"></div>
                                <div style="margin-top: 5px">{{ classStatusTab.name }}</div>
                            </div>
                        </el-tooltip>
                        <template v-else>
                            <div v-html="classStatusTab.countDisplay(computedClassInfo)"></div>
                            <div style="margin-top: 5px">{{ classStatusTab.name }}</div>
                        </template>
                    </div>
                    <div class="bar"
                         :style="(()=>{
                             const filtered = classStatusTabs.filter((tab) => tab.show(computedClassInfo));
                             const selectedCode = classStatusTabs[selectedStatusIndex].code;
                             const index = filtered.findIndex(item=>item.code===selectedCode);
                             return `left:${index *(100 /filtered.length)}%;`
                        })()">
                    </div>
                </div>
            </div>
        </div>
        <div class="today_down_contain_down" v-if="commence">
            <div class="title">
                <p class="p1 part">学员信息</p>
                <p class="p2 part">联系人</p>
                <p class="p3 part">顾问&合同</p>
                <p class="p4 part">操作</p>
            </div>
            <ul style="background-color: #ffffff;">
                <li v-for="(studentClass, idx) in computedRows"
                    style="width: 100%;overflow: hidden;"
                    class="student-item"
                    :key="studentClass.id + idx">
                    <home-list-student-class v-if="studentClass.studentType==='List'"
                                             :student-class="studentClass"
                                             @close="$emit('close')">
                        <el-checkbox
                            v-if="showBatchSign"
                            :disabled="studentClass.status!=='NoClasses'"
                            :value="checkedStudentClass.indexOf(studentClass.id) !== -1"
                            @change="changeCheck(studentClass, computedClassInfo)"
                            style="margin-right: 0">
                        </el-checkbox>
                    </home-list-student-class>
                    <home-student-class v-else
                                        :student-class="studentClass"
                                        @close="$emit('close')">
                        <el-checkbox
                            v-if="showBatchSign"
                            :disabled="studentClass.status!=='NoClasses'&&studentClass.status!=='Overdue'"
                            :value="checkedStudentClass.indexOf(studentClass.id) !== -1"
                            @change="changeCheck(studentClass, computedClassInfo)"
                            style="margin-right: 0;">
                        </el-checkbox>
                    </home-student-class>
                </li>
            </ul>
            <template v-if="showControls.includes('BATCHSIGN')">
                <div class="batch-wrapper" v-if="showBatchSign">
                    <el-checkbox
                        :value="computedCanSignRows.length && computedCanSignRows.length === checkedStudentClass.length"
                        @change="changeAllCheck(computedClassInfo)">
                        全选 ({{ checkedStudentClass.length }} / {{ computedRows.length }})
                    </el-checkbox>
                    <el-button
                        type="primary"
                        @click="batchSignIn"
                        :loading="batchSignInLoading"
                        :disabled="!checkedStudentClass.length">
                        批量签到({{ checkedStudentClass.length }})
                    </el-button>
                </div>
            </template>
        </div>
        <HomeListStudentClassForMini class="mini-student-list"
                                     @close="$emit('close')"
                                     v-else
                                     :data="computedRows">
        </HomeListStudentClassForMini>
        <add-student
            :visible.sync="addStudentModalVisible"
            :classId="computedClassInfo.id"
            :consume-count="computedClassInfo.consumeCount"
            :classNumberNo="computedClassInfo.classNumberNo"
            :is-audition="computedClassInfo.isAudition"
            :students="computedClassInfo.students">
        </add-student>
        <suspend-classes :visible.sync="suspendClassModalVisible"
                         :class-info="computedClassInfo"
                         @updated="refreshClass">
        </suspend-classes>
        <!--打印名帖子-->
        <PrintNameCardVue
            :visible.sync="printNameCardModalVisible"
            :closePrintNameCardModal="closePrintNameCardModal">
        </PrintNameCardVue>
        <update-class-modal :visible.sync="editClassModalBoolean"
                            :data="computedClassInfo">
        </update-class-modal>
        <!--课节操作记录弹窗-->
        <ClassOperationRecordVue
            :visible.sync="classOperationRecordModalVisible"
            v-if="classOperationRecordModalVisible"
            :class-id="computedClassInfo.id">
        </ClassOperationRecordVue>
        <!--课节照片库-->
        <ClassPhotoLibraryVue
            v-if="classPhotoLibraryVisible"
            :visible.sync="classPhotoLibraryVisible"
            :classId="computedClassInfo.id">
        </ClassPhotoLibraryVue>
        <send-class-after-evaluate
            :visible.sync="sendClassAfterEvaluateBoolean"
            :students="computedClassInfo.students">
        </send-class-after-evaluate>
    </div>
</template>
<script>
import {
    computed,
    defineComponent,
    getCurrentInstance,
    inject,
    provide,
    ref,
    toRefs,
    watch,
} from 'vue';
import {classStatusTabs} from './config';
import dayjs from 'dayjs';
import {getBirthdayDisplay} from '../../../utils/studentUtils';
import {batchSignInApi, exportClassSignByIdApi, getClassDetailApi, lockClassApi} from '../../../api/class/classApi';
import ClassOperationRecordVue from '../../ClassOperationRecord/ClassOperationRecord.vue';
import SendClassAfterEvaluate from "@/components/SendClassAfterEvaluate/SendClassAfterEvaluate.vue";
import PrintNameCardVue from './PrintNameCard.vue';
import AddStudent from './AddStudent.vue';
import ClassPhotoLibraryVue from './ClassPhotoLibrary.vue';
import SuspendClasses from '../SuspendClasses.vue';
import UpdateClassModal from './UpdateClassModal.vue';
import HomeStudentClass from './HomeStudentClass.vue';
import HomeListStudentClass from './HomeListStudentClass.vue';
import HomeListStudentClassForMini from './HomeListStudentClassForMini.vue';
import addPng from '../../../assets/img/jiahao.png';
import banPng from '../../../assets/img/ban.png';
import lockPng from '../../../assets/img/lock.png';
import lockPng2 from '../../../assets/img/lock2.png';
import printPng from '../../../assets/img/print.png';
import downloadPng from '../../../assets/img/download.png';
import editPng from '../../../assets/img/edit.png';
import timePng from '../../../assets/img/time.png';
import messagePng from '../../../assets/img/message.png';
import picturePng from '../../../assets/img/picture.png';
import {isEqual} from "@/utils/ObjectUtils";
import {debounce} from "@/utils";

export default defineComponent({
    components: {
        HomeListStudentClassForMini,
        HomeListStudentClass,
        HomeStudentClass,
        UpdateClassModal,
        SuspendClasses,
        SendClassAfterEvaluate,
        ClassPhotoLibraryVue,
        AddStudent,
        PrintNameCardVue,
        ClassOperationRecordVue,
    },
    props: {
        classInfo: Object,
        classId: Number,
        fullRefreshOnly: {
            type: Boolean,
            default() {
                return true;
            }
        },
        refreshSelfAnyWay: {
            type: Boolean,
            default() {
                return false;
            }
        },
        showControls: {
            type: Array,
            default: () => {
                return ['FOLD', 'REFRESH', 'BATCHSIGN'];
            }
        },
    },
    emits: ['updated', 'refresh', 'close'],
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const editClassModalBoolean = ref(false);

        const sendClassAfterEvaluateData = ref([]);

        const sendClassAfterEvaluateBoolean = ref(false);

        const loading = ref(false);

        const commence = ref(!props.showControls.includes('FOLD'));

        const operationButtons = [
            {
                id: 1,
                text: '添加学生',
                url: addPng,
                onClick: () => {
                    addStudentModalVisible.value = true;
                },
            },
            {
                id: 2,
                text: '停用',
                url: banPng,
                show: () => {
                    return !computedClassInfo.value.isOver;
                },
                onClick: () => {
                    suspendClassModalVisible.value = true;
                },
            },
            {
                id: 3,
                text: '锁定该课程',
                url: (data) => {
                    return data.isLock ? lockPng2 : lockPng;
                },
                show: () => {
                    return !computedClassInfo.value.isOver;
                },
                onClick: (data) => {
                    currentInstance.proxy.$confirm(`是否${data.isLock ? '解锁' : '锁定'}该课程?`, {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        showClose: false,
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                instance.confirmButtonLoading = true;
                                lockClassApi({type: data.isLock ? 0 : 1, id: data.id}).then(() => {
                                    refreshData();
                                    done();
                                }).finally(() => {
                                    instance.confirmButtonLoading = false;
                                });
                            } else {
                                done();
                            }
                        }
                    });
                },
            },
            {
                id: 4,
                text: '打印签到名帖',
                url: printPng,
                show: () => {
                    // return !computedClassInfo.value.isOver
                    return false;
                },
                onClick: () => {
                    printNameCardModalVisible.value = true;
                },
            },
            {
                id: 5,
                text: '下载签到表',
                url: downloadPng,
                show: () => {
                    return !computedClassInfo.value.isOver;
                },
                onClick: () => {
                    exportClassSignByIdApi(computedClassInfo.value.id);
                },
            },
            {
                id: 6,
                text: '编辑',
                url: editPng,
                onClick: () => {
                    editClassModalBoolean.value = true;
                },
            },
            {
                id: 7,
                text: '课节操作记录',
                url: timePng,
                onClick: () => {
                    classOperationRecordModalVisible.value = true;
                },
            },
            {
                id: 8,
                text: '发送课后评价',
                url: messagePng,
                onClick: (data) => {
                    sendClassAfterEvaluateData.value = data.students;
                    sendClassAfterEvaluateBoolean.value = true;
                },
            },
            {
                id: 9,
                text: '课节照片库',
                url: picturePng,
                onClick: () => {
                    classPhotoLibraryVisible.value = true;
                },
            },
        ];

        const addStudentModalVisible = ref(false);

        const suspendClassModalVisible = ref(false);

        const printNameCardModalVisible = ref(false);

        const classOperationRecordModalVisible = ref(false);

        const classPhotoLibraryVisible = ref(false);

        const selectedStatusIndex = ref(0);

        const checkedStudentClass = ref([]);

        const batchSignInLoading = ref(false);

        const selfClassInfo = ref({});

        const changeCheck = (studentClass) => {
            const indexOf = checkedStudentClass.value.indexOf(studentClass.id);
            if (indexOf !== -1) {
                checkedStudentClass.value.splice(indexOf, 1);
            } else {
                checkedStudentClass.value.push(studentClass.id);
            }
        };

        const changeAllCheck = () => {
            if (computedCanSignRows.value.length === checkedStudentClass.value.length) {
                checkedStudentClass.value = [];
            } else {
                checkedStudentClass.value = computedCanSignRows.value.map(studentClass => studentClass.id);
                checkedStudentClass.value = checkedStudentClass.value.filter(item => item);
            }
        };

        const batchSignIn = () => {
            batchSignInLoading.value = true;
            currentInstance.proxy
                .$confirm('确认批量签到吗?', {type: 'warning'})
                .then(() => batchSignInApi({ids: checkedStudentClass.value}))
                .then(() => {
                    currentInstance.proxy.$message({
                        message: '签到成功',
                        type: 'success',
                    });
                    checkedStudentClass.value = [];
                    refreshData();
                })
                .finally(() => (batchSignInLoading.value = false));
        };

        const refreshData = (updated = true) => {
            loading.value = true;
            getClassDetailApi(props.classId ? props.classId : computedClassInfo.value.id).then(res => {
                selfClassInfo.value = res.data.data;
                let emitUpdated = updated;
                if (typeof updated === "function") {
                    emitUpdated = updated(res.data.data);
                }
                if (emitUpdated) {
                    emit('updated', res.data.data);
                }
            }).finally(() => loading.value = false);
        };

        const computedRows = computed(() => {
            return classStatusTabs[selectedStatusIndex.value].filterData(
                computedClassInfo.value.students
            );
        });

        const computedCanSignRows = computed(() => {
            return computedRows.value.filter(studentClass => studentClass.status === 'NoClasses');
        });

        const closeSendClassAfterEvaluateModal = () => {
            sendClassAfterEvaluateBoolean.value = false;
        };

        const closeSuspendClassesModal = () => {
            suspendClassModalVisible.value = false;
        };

        const closePrintNameCardModal = () => {
            printNameCardModalVisible.value = false;
        };

        const handleRefresh = debounce((e) => {
            if (!loading.value) {
                e.target.style.transition = 'all .5s';
                e.target.style.transform = 'rotate(360deg)';

                setTimeout(() => {
                    e.target.style.transition = null;
                    e.target.style.transform = 'rotate(0)';
                }, 500);
                refreshData(data => {
                    return !isEqual(selfClassInfo.value, data);
                });
            }
        }, 1000, true);

        const refreshClass = inject('refreshClass');

        if (props.fullRefreshOnly) {

            const refreshFun = () => {
                refreshClass();
                //如果开启总是全量刷新模式,理论上课程信息应该从外部传过来
                //当外部不传课程详情时refreshSelfAnyWay设置为true,组件内部会自动更新
                if (props.refreshSelfAnyWay) {
                    refreshData();
                }
            };

            provide('refreshClass', refreshFun);

            provide('reloadClass', refreshFun);
        } else {
            provide('refreshClass', () => {
                refreshData();
            });

            provide('reloadClass', () => emit('refresh'));
        }

        const computedClassInfo = computed(() => {
            return (props.classId || selfClassInfo.value.id) ? selfClassInfo.value : props.classInfo;
        });

        const showBatchSign = computed(() => {
            return !computedClassInfo.isOver
                && !computedRows.value.every(studentItem => studentItem.status !== 'NoClasses' && studentItem.status !== 'Overdue');
        });

        watch(() => props.classInfo, newVal => {
            selfClassInfo.value = newVal;
        }, {deep: true});

        watch(() => props.classId, newVal => {
            if (newVal) {
                refreshData(false);
            }
        }, {immediate: true});

        return {
            ...toRefs(props),
            classStatusTabs,
            operationButtons,
            addStudentModalVisible,
            suspendClassModalVisible,
            printNameCardModalVisible,
            classOperationRecordModalVisible,
            classPhotoLibraryVisible,
            selectedStatusIndex,
            checkedStudentClass,
            batchSignInLoading,
            computedRows,
            computedCanSignRows,
            computedClassInfo,
            showBatchSign,
            loading,
            commence,
            dayjs,
            getBirthdayDisplay,
            handleRefresh,
            changeCheck,
            changeAllCheck,
            batchSignIn,
            editClassModalBoolean,
            sendClassAfterEvaluateBoolean,
            closeSendClassAfterEvaluateModal,
            sendClassAfterEvaluateData,
            closeSuspendClassesModal,
            closePrintNameCardModal,
            refreshClass,
        };
    },
});
</script>
<style lang="scss" src="../../../assets/scss/modules/class/homeClassInfo.scss" scoped></style>
