<template>
    <el-dialog append-to-body
               referral
               custom-modal
               title="转介绍名单列表"
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)">
        <el-table>
            <el-table-column align="center" label="被介绍人"></el-table-column>
            <el-table-column align="center" label="名单状态"></el-table-column>
            <el-table-column align="center" label="名单跟进"></el-table-column>
            <el-table-column align="center" label="介绍时间"></el-table-column>
            <el-table-column align="center" label="签约金额"></el-table-column>
            <el-table-column align="center" label="签约时间"></el-table-column>
        </el-table>
        <el-pagination style="margin-top: 20px;text-align: right;" :total="0" background></el-pagination>
    </el-dialog>
</template>
<script>
import {defineComponent, toRefs} from 'vue'

export default defineComponent({
    props: {visible: Boolean},
    emits: ['update:visible'],
    setup(props) {

        return {
            ...toRefs(props)
        }
    }
})
</script>
<style>
[referral] .el-dialog__header > .el-dialog__title {
    font-weight: 500;
    color: #292e33;
    font-size: 18px;
}

[referral] thead > tr > th > .cell {
    border-right: 1px solid #edf0f2;
}

[referral] thead > tr > th:nth-child(6) > .cell {
    border-right: none;
}
</style>
