<template>
    <custom-number-input :min="0.01"
                         :max="max"
                         :precision="2"
                         :value="value"
                         @update:value="val=>$emit('update:value',val)">
    </custom-number-input>
</template>
<script>
import {defineComponent} from "vue";
import CustomNumberInput from "@/components/Form/CustomNumberInput.vue";

export default defineComponent({
    components: {CustomNumberInput},
    props: {value: Number, max: Number},
    emits: ['update:value'],
    model: {
        value: 'value',
        event: 'update:value'
    },
});
</script>