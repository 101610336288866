import {defineComponent, getCurrentInstance, inject} from 'vue';
import ContractWaterItem from './ContractWaterItem.vue';
import dayjs from 'dayjs';
import {withdrawTransferApi} from '../../../api/student/contractApi';

function createCommonWaterItemComponent(title, leftRender, rightRender) {
    return defineComponent({
        props: {water: Object},
        emits: ['approve', 'confirm'],
        render(h) {
            const scopedSlots = {
                default: () => {
                    if (leftRender) {
                        return h(leftRender(this.water));
                    }
                }
            };
            if (rightRender) {
                const rightRendered = rightRender(this.water);
                if (rightRendered) {
                    scopedSlots.right = () => {
                        return h(rightRendered);
                    };
                }
            }
            return h(ContractWaterItem, {
                props: {
                    title, water: this.water
                },
                scopedSlots
            });
        }
    });

}

const _5_6_r = water => defineComponent({
    template: `
        <div class="confirm_left-change-content">
            <div class="confirm_left-change-before">
                <div>套餐{{ water.updateBefore.packType === 1 ? '(次卡)' : '(年卡)' }}</div>
                <div>套餐名: {{ water.updateBefore.packageName }}</div>
                <div v-if="water.updateBefore.packType === 1">合同普通课时:{{ water.updateBefore.allOrdinaryClass }}
                </div>
                <div v-if="water.updateBefore.packType === 2">每周上课课时: {{ water.updateBefore.weekAllClass }}</div>
                <div v-if="water.updateBefore.packType === 2">周末上课次数:{{ water.updateBefore.weekendAllClass }}
                </div>
                <div>合同特殊课时: {{ water.updateBefore.allSpecialClass }}</div>
                <div>合同终止日期: {{ water.updateBefore.endTime }}</div>
                <div>普通课时使用课类: {{ water.updateBefore.ordinaryClassStr }}</div>
                <div>特殊课时使用课类: {{ water.updateBefore.specialClassStr }}</div>
                <div>→</div>
            </div>
            <div class="confirm_left-change-after primary-text-color">
                <div>套餐{{ water.updateAfter.packType === 1 ? '(次卡)' : '(年卡)' }}</div>
                <div>套餐名: {{ water.updateAfter.packageName }}</div>
                <div v-if="water.updateAfter.packType === 1">合同普通课时:{{ water.updateAfter.allOrdinaryClass }}</div>
                <div v-if="water.updateAfter.packType === 2">每周上课课时: {{ water.updateAfter.weekAllClass }}</div>
                <div v-if="water.updateAfter.packType === 2">周末上课次数: {{ water.updateAfter.weekendAllClass }}</div>
                <div>合同特殊课时: {{ water.updateAfter.allSpecialClass }}</div>
                <div>合同终止日期: {{ water.updateAfter.endTime }}</div>
                <div>普通课时使用课类: {{ water.updateAfter.ordinaryClassStr }}</div>
                <div>特殊课时使用课类: {{ water.updateAfter.specialClassStr }}</div>
            </div>
            <div class="confirm_left-change-amount" style="margin-top: 10px;padding-top: 5px;font-size: 16px;">
                <span>金额变化:</span>
                <span class="primary-text-color"
                      v-if="water.updateAfter.shouldMoney > water.updateBefore.shouldMoney">
                    &nbsp;&nbsp;¥&nbsp;{{ water.updateAfter.shouldMoney - water.updateBefore.shouldMoney }}
                </span>
                <span v-else style="color: #f5222d;">
                    &nbsp;&nbsp;¥&nbsp;{{ water.updateAfter.shouldMoney - water.updateBefore.shouldMoney }}
                </span>
            </div>
        </div>`,
    setup() {
        return {water};
    }
});

const _13_14_r = water => defineComponent({
    template: `
        <div class="confirm_left-change-content">
            <div class="confirm_left-change-before">
                <div>套餐({{ water.updateBefore.packType === 1 ? '次卡' : '年卡' }})</div>
                <div>套餐名：{{ water.updateBefore.packName }}</div>
                <div>合同普通课时：{{ water.updateBefore.allOrdinaryClass }}</div>
                <div>合同特殊课时：{{ water.updateBefore.allSpecialClass }}</div>
                <div> →</div>
            </div>
            <div class="confirm_left-change-after primary-text-color">
                <div>套餐(
                    {{ water.updateAfter.packType === 1 ? '次卡' : (water.updateAfter.packType === 1 ? '年卡' : '') }})
                </div>
                <div>套餐名：{{ water.updateAfter.packName }}</div>
                <div>合同普通课时：{{ water.updateAfter.allOrdinaryClass }}</div>
                <div>合同特殊课时：{{ water.updateAfter.allSpecialClass }}</div>
            </div>
            <div class="confirm_left-change-amount">
                <span>金额变化：</span>
                <span class="primary-text-color">
                    &nbsp;&nbsp;{{ water.updateAfter.shouldMoney - water.updateBefore.shouldMoney }}
                </span>
            </div>
        </div>`,
    setup() {
        return {water};
    }
});

const _1 = createCommonWaterItemComponent('收款',
    water => defineComponent({
        template: `
            <div style="font-size: 16px;" class="confirm_left-change-content">
                <span>收款：</span>
                <span class="primary-text-color">&nbsp;&nbsp;¥ {{ water.paymentPrice }}</span>
            </div>`,
        setup() {
            return {water};
        }
    })
);

const _2 = createCommonWaterItemComponent('冻结',
    water => defineComponent({
        template: `
            <div class="confirm_left-change-content">
                <div style="font-size: 16px;">申请冻结</div>
                <div class="confirm_left-change-after"
                     style="margin-top: 10px;margin-bottom: -10px;font-size: 12px;color: #818c99;">
                    <span>冻结时段：</span>
                    <span>
                        {{ dayjs(water.freezeStartTime).format("YYYY/MM/DD") }}~
                        {{ dayjs(water.freezeEndTime).format("YYYY/MM/DD") }}
                    </span>
                </div>
            </div>
        `,
        setup() {
            if (water.checkResult === 0) {

                const refreshContractLog = inject('refreshContractLog');

                const refreshContract = inject('refreshContract');

                const onApprove = inject('onApprove');

                onApprove(() => {
                    refreshContractLog();
                    refreshContract();
                });
            }

            return {water, dayjs};
        }
    })
);

const _3 = createCommonWaterItemComponent('中心转出',
    water => defineComponent({
            template: `
                <div class="confirm_left-change-content" style="color: #292e33;">
                    <div>
                        <div v-if="contract.transferType==='Contract'" class="confirm_left-change-after">
                            <span>转入中心：</span>
                            <span>{{ water.updateAfter.store[1] }}</span>
                        </div>
                        <div class="confirm_left-change-after">
                            <span>转出金额：</span>
                            <span style="color: #f5222d">￥{{ -water.paymentPrice }}</span>
                        </div>
                        <div class="confirm_left-change-after">
                            <span>转出普通课时：</span>
                            <span style="color: #f5222d">{{ water.updateAfter.ordinaryClassCount }}</span>
                        </div>
                        <div class="confirm_left-change-after">
                            <span>转出特殊课时：</span>
                            <span style="color: #f5222d">{{ water.updateAfter.specialClassCount }}</span>
                        </div>
                        <div class="confirm_left-change-after">
                            <span>终止日期改为：</span>
                            <span style="color: #f5222d">{{ water.updateAfter.endDate }}</span>
                        </div>
                    </div>
                    <template v-if="contract.transferType==='ClassCount'">
                        <div style="margin-top: 12px;padding-top: 12px;border-top: 1px solid #edf0f2;">
                            <div class="confirm_left-change-after">
                                <span>接收中心：</span>
                                <span>{{ water.updateAfter.store[1] }}</span>
                            </div>
                            <div class="confirm_left-change-after">
                                <span>接收人：</span>
                                <span>{{ water.updateAfter.receiverName }}</span>
                            </div>
                            <div class="confirm_left-change-after">
                                <span>手机号：</span>
                                <span>
                                    +{{ water.updateAfter.receiverPhoneNumberArea }}
                                    -
                                    {{ water.updateAfter.receiverPhoneNumber }}
                                </span>
                            </div>
                        </div>
                        <div
                            style="margin-top: 12px;padding: 12px 0;border-top: 1px solid #edf0f2;border-bottom: 1px solid #edf0f2;">
                            <div class="confirm_left-change-after">
                                <span>转出人：</span>
                                <span>{{ water.updateAfter.transferName }}</span>
                            </div>
                            <div class="confirm_left-change-after">
                                <span>手机号：</span>
                                <span>
                                    +{{ water.updateAfter.transferPhoneNumberArea }}
                                    -
                                    {{ water.updateAfter.transferPhoneNumber }}
                                </span>
                            </div>
                        </div>
                    </template>
                </div>`,
            setup() {
                const getData = inject('getData');

                const contract = getData();

                return {water, contract};
            }
        }
    ),
    water => water.checkResult === 0 ? null : defineComponent({
        template: `
            <div class="confirm_right">
                <template v-if="water.checkResult!==2&&water.checkResult!==4">
                    <div>{{ water.checkTime }}</div>
                    <div style="font-size: 16px;margin: 5px 0;" class="primary-text-color">
                        <span>已同意</span>
                    </div>
                    <div>
                        <span>操作人: </span>
                        <b>{{ water.checkUserName }}</b>
                    </div>
                </template>
                <template v-else>
                    <div>{{ water.checkTime }}</div>
                    <div style="font-size: 16px;margin: 5px 0;color:#f5222d;">
                        已取消
                    </div>
                    <div>
                        <span>操作人: </span>
                        <b>{{ water.checkUserName }}</b>
                    </div>
                </template>
                <div v-if="water.checkResult>=3"
                     style="border-top: 1px solid #d8dee9;padding-top: 5px;margin-top: 5px;"></div>
                <div v-if="water.checkResult===5">
                    <el-button type="primary"
                               size="mini" style="width: fit-content;"
                               @click="confirmCollection(true)">
                        确认转出
                    </el-button>
                    <el-button type="danger"
                               size="mini"
                               style="width: fit-content; background-color: #f5222d;"
                               @click="confirmCollection(false)">
                        取消交易
                    </el-button>
                </div>
                <template v-else-if="water.checkResult===4">
                    <div>{{ water.confirmCollectionTime }}</div>
                    <div style="font-size: 16px;margin: 5px 0;" class="primary-text-color">
                        <span>已取消</span>
                    </div>
                    <div>
                        <span>操作人: </span>
                        <b>{{ water.confirmCollectionUserName }}</b>
                    </div>
                </template>
                <template v-else-if="[3,6,7,8].includes(water.checkResult)">
                    <span>{{ water.checkTime }}</span>
                    <p>
                        <em>已确认转出</em>
                    </p>
                    <span>
                    <span>支付方式:</span>
                        <em>{{ $store.getters['common/payMethodMap'][water.checkPayType] }}</em>
                    </span>
                    <span>交易备注: {{ water.checkNotes }}</span>
                    <span>
                    <span>退款日期:</span>
                    <i>{{ water.confirmCollectionTime.split(' ')[0] }}</i>
                    <em class="cur" @click="handleUpdateReceiptDateClick">[修改]</em>
                    </span>
                    <span>
                        <span>操作人:</span>
                        <b> {{ water.checkUserName }}</b>
                    </span>
                </template>
                <div style="border-top: 1px solid #d8dee9;padding-top: 10px;margin-top: 10px;"
                     v-if="water.checkResult===3||water.checkResult===6"></div>
                <el-button v-if="water.checkResult===3"
                           type="primary"
                           size="mini"
                           @click="withdrawTransfer"
                           style="width: fit-content;">
                    撤回
                </el-button>
                <template v-else-if="water.checkResult===6">
                    <div>{{ water.withdrawTime }}</div>
                    <div style="font-size: 16px;margin: 5px 0;color:#f5222d;">
                        <span>已撤回</span>
                    </div>
                    <div>
                        <span>操作人: </span>
                        <b>{{ water.withdrawUserName }}</b>
                    </div>
                </template>
                <div style="margin-top: 20px;"></div>
            </div>`,
        setup() {
            const currentInstance = getCurrentInstance();

            const withdrawTransfer = () => {
                currentInstance.proxy.$confirm('确认撤回转出中心？', {
                    type: 'warning',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            withdrawTransferApi({waterId: water.id}).then(() => {
                                refreshContract();
                                refreshWater();
                                done();
                            }).finally(() => instance.confirmButtonLoading = false);
                        } else {
                            done();
                        }
                    }
                });
            };

            const getData = inject('getData');

            const refreshContract = inject('refreshContract');

            const refreshWater = inject('refreshWater');

            const confirmWater = inject('confirmWater');

            const confirmCollection = inject('confirmCollection');

            const handleUpdateReceiptDateClick = inject('handleUpdateReceiptDateClick');

            const contract = getData();

            return {
                water,
                contract,
                withdrawTransfer,
                confirmWater,
                confirmCollection,
                handleUpdateReceiptDateClick
            };
        }
    })
);

const _4 = createCommonWaterItemComponent('改期',
    water => defineComponent({
        template: `
            <div style="font-size: 16px;" class="confirm_left-change-content">
                <div class="confirm_left-change-before">
                    <div>合同原起止日期：</div>
                    <div>{{ water.updateBefore }}</div>
                    <div> →</div>
                </div>
                <div class="confirm_left-change-after primary-text-color">
                    <div>合同新起止日期：</div>
                    <div>{{ water.updateAfter }}</div>
                </div>
            </div>`,
        setup() {

            if (water.checkResult === 0) {

                const refreshContractLog = inject('refreshContractLog');

                const refreshContract = inject('refreshContract');

                const onApprove = inject('onApprove');

                onApprove(() => {
                    refreshContractLog();
                    refreshContract();
                });
            }

            return {water};
        }
    })
);

const _5 = createCommonWaterItemComponent('改套餐（大改小）', _5_6_r);

const _6 = createCommonWaterItemComponent('改套餐（小改大）', _5_6_r);

const _7 = createCommonWaterItemComponent('修改课时',
    water => defineComponent({
        template: `
            <div style="font-size: 16px;" class="confirm_left-change-content">
                <span v-if="water.updateAfter.classType===1">普通课时：</span>
                <span v-else-if="water.updateAfter.classType===2">特殊课时：</span>
                <span class="primary-text-color">
                    &nbsp;&nbsp;¥
                    {{ water.updateAfter.count > 0 ? "+" + water.updateAfter.count : water.updateAfter.count }}
                </span>
            </div>`,
        setup() {

            const refreshContract = inject('refreshContract');

            const onApprove = inject('onApprove');

            onApprove(() => {
                refreshContract();
            });

            return {water};
        }
    })
);

const _9 = createCommonWaterItemComponent('修改耗课',
    water => defineComponent({
        template: `
            <div style="font-size: 16px;" class="confirm_left-change-content">
                <div>
                    <span v-if="water.updateAfter.consumeClassType==='Ordinary'">普通课时：</span>
                    <span v-else-if="water.updateAfter.consumeClassType==='Special'">特殊课时：</span>
                    <span class="primary-text-color">
                         {{ water.updateAfter.count > 0 ? "+" + water.updateAfter.count : water.updateAfter.count }}
                    </span>
                </div>
                <div>
                    <span>剩余金额变化(元)：</span>
                    <span class="primary-text-color" v-if="typeof water.updateAfter.consumeAmount==='number'">
                        {{ water.updateAfter.consumeAmount > 0 ? '+ ¥' + water.updateAfter.consumeAmount : '¥' + water.updateAfter.consumeAmount }}
                    </span>
                    <span v-else>0</span>
                </div>
            </div>`,
        setup() {

            const refreshContract = inject('refreshContract');

            const onApprove = inject('onApprove');

            onApprove(() => {
                refreshContract();
            });

            return {water};
        }
    })
);

const _10 = createCommonWaterItemComponent('修改适用规则',
    water => defineComponent({
        template: `
            <div class="confirm_left-change-content">
                <div class="confirm_left-change-before">
                    <div>
                        <div>旧普通课时适用课类:</div>
                        <div> {{ water.updateBefore.ordinaryClassStr }}</div>
                    </div>
                    <div v-if="water.updateBefore.packType===1">
                        <div>旧普通课时适用周期:</div>
                        <div>{{ water.updateBefore.ordinaryTime }}</div>
                    </div>
                    <div>
                        <div>旧特殊课时适用课类:</div>
                        <div>{{ water.updateBefore.specialClassStr }}</div>
                    </div>
                    <div v-if="water.updateBefore.packType===1">
                        <div>旧特殊课时适用周期:</div>
                        <div>{{ water.updateBefore.specialTime }}</div>
                    </div>
                    <div>→</div>
                </div>
                <div class="confirm_left-change-after">
                    <div>
                        <div>新普通课时适用课类:</div>
                        <div class="primary-text-color">{{ water.updateAfter.ordinaryClassStr }}</div>
                    </div>
                    <div v-if="water.updateAfter.packType===1">
                        <div>新普通课时适用周期:</div>
                        <div class="primary-text-color">{{ water.updateAfter.ordinaryTime }}</div>
                    </div>
                    <div>
                        <div>新特殊课时适用课类:</div>
                        <div class="primary-text-color">{{ water.updateAfter.specialClassStr }}</div>
                    </div>
                    <div v-if="water.updateAfter.packType===1">
                        <div>新特殊课时适用周期:</div>
                        <div class="primary-text-color">{{ water.updateAfter.specialTime }}</div>
                    </div>
                </div>
            </div>`,
        setup() {

            const refreshContract = inject('refreshContract');

            const onApprove = inject('onApprove');

            onApprove(() => {
                refreshContract();
            });

            return {water};
        }
    })
);

const _11 = createCommonWaterItemComponent('修改合同适用学员',
    water => defineComponent({
        template: `
            <div class="confirm_left-change-content">
                <div class="confirm_left-change-before">
                    <div>旧适用学员：{{ water.updateBefore.info }}</div>
                    <div> →</div>
                </div>
                <div class="confirm_left-change-after">
                    <div>
                        <span>新适用学员：</span>
                        <span class="primary-text-color">{{ water.updateAfter.info }}</span>
                    </div>
                </div>
            </div>`,
        setup() {

            const refreshContract = inject('refreshContract');

            const onApprove = inject('onApprove');

            onApprove(() => {
                refreshContract();
            });

            return {water};
        }
    })
);

const _12 = createCommonWaterItemComponent('解冻',
    water => defineComponent({
        template: `
            <div style="font-size: 16px;" class="confirm_left-change-content">
                <div>合同解冻</div>
                <div style="font-size: 12px;color: #818c99;line-height: 20px;margin: 10px 0 -10px 0;">冻结时长：
                    {{ water.freezeDate }}天
                </div>
            </div>`,
        setup() {

            const refreshContract = inject('refreshContract');

            const onApprove = inject('onApprove');

            onApprove(() => {
                refreshContract();
            });

            return {water};
        }
    })
);

const _13 = createCommonWaterItemComponent('改套餐（大转小） - 改合同', _13_14_r);

const _14 = createCommonWaterItemComponent('改套餐（小转大） - 改合同', _13_14_r);

const _15 = createCommonWaterItemComponent('退款(完结)',
    water => defineComponent({
        template: `
            <div style="font-size: 16px;" class="confirm_left-change-content">
                <div>
                    <span>退款：</span>
                    <span style="color: #f5222d;">&nbsp;&nbsp;¥ {{ water.paymentPrice }}</span>
                </div>
                <div>
                    <span>剩余普通课时：</span>
                    <span style="color: #f5222d;">
                        {{ water.updateAfter.allOrdinaryClass > 0 ? "-" : '' }} {{ water.updateAfter.allOrdinaryClass }}
                    </span>
                </div>
                <div>
                    <span>剩余特殊课时：</span>
                    <span style="color: #f5222d;">
                        {{ water.updateAfter.allSpecialClass > 0 ? "-" : '' }}{{ water.updateAfter.allSpecialClass }}
                    </span>
                </div>
            </div>`,
        setup() {

            const refreshContract = inject('refreshContract');

            const onApprove = inject('onApprove');

            onApprove(() => {
                refreshContract();
            });

            return {water};
        }
    })
);

const _16 = createCommonWaterItemComponent('退款',
    water => defineComponent({
        template: `
            <div style="font-size: 16px;" class="confirm_left-change-content">
                <span>退款：</span>
                <span style="color: #f5222d;">&nbsp;&nbsp;¥ {{ water.paymentPrice }}</span>
            </div>`,
        setup() {
            return {water};
        }
    })
);

const _18 = createCommonWaterItemComponent('业绩调整',
    water => defineComponent({
        template: `
            <div style="font-size: 16px;" class="confirm_left-change-content">
                <span v-if="water.performancePersonId===-1" class="primary-text-color">【本中心】</span>
                <span v-else>【{{ water.performancePerson }}】</span>
                <span>调整</span>
                <span v-if="water.paymentPrice>0" class="primary-text-color">
                    &nbsp;&nbsp;+¥{{ water.paymentPrice }}
                </span>
                <span v-else style="color: #f5222d;">&nbsp;&nbsp;¥ {{ water.paymentPrice }}</span>
            </div>`,
        setup() {
            return {water};
        }
    })
);

const _19 = createCommonWaterItemComponent('中心转入',
    water => defineComponent({
            template: `
                <div class="confirm_left-change-content">
                    <div class="confirm_left-change-after" style="font-size: 16px;">
                        <span>中心转入：</span>
                        <span class="primary-text-color">￥{{ water.paymentPrice }}</span>
                    </div>
                </div>`,
            setup() {
                const getData = inject('getData');

                const contract = getData();

                return {water, contract};
            }
        }
    ),
    water => defineComponent({
        template: `
            <div class="confirm_right">
                <div v-if="water.checkResult===0">
                    <el-button type="primary"
                               size="mini" style="width: fit-content;"
                               @click="confirmWater(true,'确认接收？')">
                        接收
                    </el-button>
                    <el-button type="danger"
                               size="mini"
                               style="width: fit-content; background-color: #f5222d;"
                               @click="confirmWater(false,'是否确认拒绝接收，拒绝之后此条合同将退回转出方。')">
                        拒绝
                    </el-button>
                </div>
                <template v-else-if="water.checkResult>=3">
                    <div>{{ water.checkTime }}</div>
                    <div style="font-size: 16px;margin: 5px 0;" class="primary-text-color">
                        <span>已接收</span>
                    </div>
                    <div>
                        <span>操作人: </span>
                        <b>{{ water.checkUserName }}</b>
                    </div>
                    <template v-if="water.checkResult===3">
                        <div style="border-top: 1px solid #d8dee9;padding-top: 5px;margin-top: 5px;"></div>
                        <div>{{ water.confirmCollectionTime }}</div>
                        <div style="font-size: 16px;margin: 5px 0;" class="primary-text-color">
                            <span>已收款</span>
                        </div>
                        <span>
                            <span>支付方式:</span>
                            <em>{{ $store.getters['common/payMethodMap'][water.checkPayType] }}</em>
                        </span>
                        <span>交易备注: {{ water.checkNotes }}</span>
                        <span>
                            <span>收款日期:</span>
                            <i>{{ water.confirmCollectionTime.split(' ')[0] }}</i>
                            <em class="cur" @click="handleUpdateReceiptDateClick">[修改]</em>
                        </span>
                        <span>
                            <span>操作人:</span>
                            <b> {{ water.confirmCollectionUserName }}</b>
                        </span>
                    </template>
                </template>
            </div>`,
        setup() {
            const currentInstance = getCurrentInstance();

            const getData = inject('getData');

            const confirmWater = inject('confirmWater');

            const handleUpdateReceiptDateClick = inject('handleUpdateReceiptDateClick');

            const contract = getData();

            return {
                water,
                contract,
                confirmWater,
                handleUpdateReceiptDateClick
            };
        }
    })
);

const _20 = createCommonWaterItemComponent('转入课时',
    water => defineComponent({
            template: `
                <div class="confirm_left-change-content" style="color: #292e33;">
                    <div>
                        <div v-if="contract.transferType==='Contract'" class="confirm_left-change-after">
                            <span>转入中心：</span>
                            <span>{{ water.updateAfter.store[1] }}</span>
                        </div>
                        <div class="confirm_left-change-after">
                            <span>转入金额：</span>
                            <span style="color: #f5222d">￥{{ water.paymentPrice }}</span>
                        </div>
                        <div class="confirm_left-change-after">
                            <span>转入普通课时：</span>
                            <span style="color: #f5222d">{{ water.updateAfter.ordinaryClassCount }}</span>
                        </div>
                        <div class="confirm_left-change-after">
                            <span>转入特殊课时：</span>
                            <span style="color: #f5222d">{{ water.updateAfter.specialClassCount }}</span>
                        </div>
                        <div class="confirm_left-change-after">
                            <span>转入合同有效期：</span>
                            <span style="color: #f5222d">{{ water.updateAfter.validityPeriod }}</span>
                        </div>
                    </div>
                    <template v-if="contract.transferType==='ClassCount'">
                        <div style="margin-top: 12px;padding-top: 12px;border-top: 1px solid #edf0f2;">
                            <div class="confirm_left-change-after">
                                <span>接收中心：</span>
                                <span>{{ water.updateAfter.store[1] }}</span>
                            </div>
                            <div class="confirm_left-change-after">
                                <span>接收人：</span>
                                <span>{{ water.updateAfter.receiverName }}</span>
                            </div>
                            <div class="confirm_left-change-after">
                                <span>手机号：</span>
                                <span>
                                    +{{ water.updateAfter.receiverPhoneNumberArea }}
                                    -
                                    {{ water.updateAfter.receiverPhoneNumber }}
                                </span>
                            </div>
                        </div>
                        <div
                            style="margin-top: 12px;padding: 12px 0;border-top: 1px solid #edf0f2;border-bottom: 1px solid #edf0f2;">
                            <div class="confirm_left-change-after">
                                <span>转出人：</span>
                                <span>{{ water.updateAfter.transferName }}</span>
                            </div>
                            <div class="confirm_left-change-after">
                                <span>手机号：</span>
                                <span>
                                    +{{ water.updateAfter.transferPhoneNumberArea }}
                                    -
                                    {{ water.updateAfter.transferPhoneNumber }}
                                </span>
                            </div>
                        </div>
                    </template>
                </div>`,
            setup() {
                const getData = inject('getData');

                const contract = getData();

                return {water, contract};
            }
        }
    ),
    water => defineComponent({
        template: `
            <div class="confirm_right">
                <div v-if="water.checkResult===0">
                    <el-button type="primary"
                               size="mini" style="width: fit-content;"
                               @click="confirmWater(true,'确认接收？')">
                        接收
                    </el-button>
                    <el-button type="danger"
                               size="mini"
                               style="width: fit-content; background-color: #f5222d;"
                               @click="confirmWater(false,'是否确认拒绝接收，拒绝之后此条合同将退回转出方。')">
                        拒绝
                    </el-button>
                </div>
                <template v-else-if="water.checkResult>=3">
                    <div>{{ water.checkTime }}</div>
                    <div style="font-size: 16px;margin: 5px 0;" class="primary-text-color">
                        <span>已接收</span>
                    </div>
                    <div>
                        <span>操作人: </span>
                        <b>{{ water.checkUserName }}</b>
                    </div>
                    <template v-if="water.checkResult===3">
                        <div style="border-top: 1px solid #d8dee9;padding-top: 5px;margin-top: 5px;"></div>
                        <div>{{ water.confirmCollectionTime }}</div>
                        <div style="font-size: 16px;margin: 5px 0;" class="primary-text-color">
                            <span>已收款</span>
                        </div>
                        <span>
                            <span>支付方式:</span>
                            <em>{{ $store.getters['common/payMethodMap'][water.checkPayType] }}</em>
                        </span>
                        <span>交易备注: {{ water.checkNotes }}</span>
                        <span>
                            <span>收款日期:</span>
                            <i>{{ water.confirmCollectionTime.split(' ')[0] }}</i>
                            <em class="cur" @click="handleUpdateReceiptDateClick">[修改]</em>
                        </span>
                        <span>
                            <span>操作人:</span>
                            <b> {{ water.confirmCollectionUserName }}</b>
                        </span>
                    </template>
                </template>
            </div>`,
        setup() {
            const currentInstance = getCurrentInstance();

            const getData = inject('getData');

            const confirmWater = inject('confirmWater');

            const handleUpdateReceiptDateClick = inject('handleUpdateReceiptDateClick');

            const contract = getData();

            return {
                water,
                contract,
                confirmWater,
                handleUpdateReceiptDateClick
            };
        }
    })
);

const waterTypes = [
    {
        type: 1,
        name: '',
        component: _1
    },
    {
        type: 2,
        name: '',
        component: _2
    },
    {
        type: 3,
        name: '',
        component: _3
    },
    {
        type: 4,
        name: '',
        component: _4
    },
    {
        type: 5,
        component: _5
    },
    {
        type: 6,
        name: '',
        component: _6
    },
    {
        type: 7,
        name: '',
        component: _7
    },
    {
        type: 8,
        name: '',
        component: null
    },
    {
        type: 9,
        name: '',
        component: _9
    },
    {
        type: 10,
        name: '',
        component: _10
    },
    {
        type: 11,
        component: _11
    },
    {
        type: 12,
        name: '',
        component: _12
    },
    {
        type: 13,
        name: '',
        component: _13
    },
    {
        type: 14,
        name: '',
        component: _14
    },
    {
        type: 15,
        name: '',
        component: _15
    },
    {
        type: 16,
        name: '',
        component: _16
    },
    {
        type: 17,
        name: '',
        component: null
    },
    {
        type: 18,
        name: '',
        component: _18
    },
    {
        type: 19,
        name: '',
        component: _19
    },
    {
        type: 20,
        name: '',
        component: _20
    }
];

const waterTypeMap = (() => {
    const result = {};
    for (const waterType of waterTypes) {
        result[waterType.type] = waterType;
    }
    return result;
})();

export {
    waterTypeMap
};
