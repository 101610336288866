<template>
<div>
    <el-dialog custom-modal
               title="修改业绩归属"
               width="500px"
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               append-to-body>
        <el-form ref="formRef" :rules="fromRules" :model="formData" label-position="top" size="mini">
            <el-form-item prop="performancePersonId" label="业绩归属">
                <performance-attribution-select v-model="formData.performancePersonId"
                                                style="width: 100%;">
                </performance-attribution-select>
            </el-form-item>
            <el-form-item prop="reason" label="原因">
                <el-input type="textarea" v-model="formData.reason" :maxlength="250" show-word-limit></el-input>
            </el-form-item>
        </el-form>
        <template v-slot:footer>
            <el-button class="ballet-modal-button" @click="$emit('update:visible',false)">取消</el-button>
            <el-button class="ballet-modal-button"
                       :disabled="formData.performancePersonId===water.performancePersonId"
                       type="primary" :loading="confirmLoading" @click="handleConfirm">
                确认
            </el-button>
        </template>
    </el-dialog>
</div>
</template>
<script>
import {computed, defineComponent, inject, onMounted, ref, watch} from 'vue'
import {
    updatePerformanceTypeApi,
    updatePerformanceVestingApi,
    updateReceiptDateApi
} from '../../../api/student/contractApi'
import {getTenantUserPageApi} from '../../../api/system/tenantUserApi'
import {isEqual} from '../../../utils/ObjectUtils'
import TenantUserSelect from '../../Form/TenantUserSelect.vue'
import PerformanceAttributionSelect from '../../Form/PerformanceAttributionSelect.vue'

export default defineComponent({
    components: {PerformanceAttributionSelect, TenantUserSelect},
    props: {
        visible: Boolean
    },
    emits: ['update:visible'],
    setup (props, {emit}) {

        const formRef = ref()

        const fromRules = ref({
            performancePersonId: [{required: true, message: '请选择业绩归属人'}],
            reason: [{required: true, message: '原因是必填的'}]
        })

        const formData = ref({
            performancePersonId: undefined,
            reason: undefined
        })

        const pickerOptions = {
            disabledDate (time) {
                return time.getTime() > Date.now()
            }
        }

        const confirmLoading = ref(false)

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate()
                .then(() => updatePerformanceVestingApi({...formData.value, waterId: water.value.id}))
                .then(() => {
                    emit('update:visible', false)
                    refreshWater()
                    refreshContractLog()
                })
                .finally(() => {
                    confirmLoading.value = false
                })
        }

        const getWater = inject('getWater')

        const refreshWater = inject('refreshWater')

        const refreshContractLog = inject('refreshContractLog')

        const water = computed(() => {
            const result = getWater()
            return result ? result : {}
        })

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value = {
                    performancePersonId: water.value.performancePersonId,
                }
                setTimeout(() => formRef.value.clearValidate())
            } else {
                formData.value = {
                    performancePersonId: undefined,
                    reason: undefined
                }
                formRef.value.resetFields()
            }
        })

        return {
            formRef,
            fromRules,
            formData,
            pickerOptions,
            water,
            confirmLoading,
            handleConfirm,
        }
    }
})
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 !important;
    color: #3b3b3b !important;
}
</style>
