<template>
    <div class="list_wrap">
        <div class="list">
            <el-button style="background:#29cb97;border:none;"
                       type="primary"
                       class="btn"
                       @click="createMotionModalVisible=true">
                新建动态
            </el-button>
            <div class="down_wrap" v-loading="loading===1">
                <template v-if="Array.isArray(records)&&records.length">
                    <motion-item
                        v-for="(item,index) in records"
                        :key="index"
                        :data="item"
                        @delete="refreshPage">
                    </motion-item>
                </template>
            </div>
        </div>
        <div style="padding: 20px 0;text-align: center;" v-if="loading===2">正在加载中...</div>
        <div style="padding: 20px 0;text-align: center;" v-if="records.length>=page.total">暂无更多动态</div>
        <push-motion-modal
            :visible.sync="createMotionModalVisible"
            :students="[student]"
            title="新建动态"
            :type="2"
            @ok="refreshPage">
        </push-motion-modal>
    </div>
</template>

<script>
import PushMotionModal from './PushMotionModal.vue';
import {getMessageListApi} from "@/api/motion/message";
import {getDefaultPage} from "../../utils";
import MotionItem from "@/components/Motion/MotionItem.vue";

export default {
    components: {MotionItem, PushMotionModal},
    props: {
        student: {
            type: Object,
            default() {
                return {};
            }
        },
        query: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            createMotionModalVisible: false,
            page: getDefaultPage(),
            loading: 0,
            records: [],
        };
    },
    methods: {
        refreshPage() {
            this.loading = 1;
            this.records = [];
            this.page = getDefaultPage();
            this.getPage();
        },
        getPage() {
            getMessageListApi({
                ...this.query,
                currentPage: this.page.currentPage,
                pageSize: this.page.pageSize,
                timestamp: this.page.timestamp,
                studentId: this.student.id,
            }).then(res => {
                if (Array.isArray(res.data.data.records) && res.data.data.records.length) {
                    res.data.data.records.forEach(item => {
                        //给所有数据默认设置状态，用来显示输入框
                        item.showInp = false;
                    });
                    this.records = this.records.concat(this.removeDuplicatesById(res.data.data.records));
                }
                this.page.currentPage = res.data.data.current;
                this.page.pageSize = res.data.data.size;
                this.page.total = res.data.data.total;
            }).finally(() => {
                this.loading = 0;
            });
        },
        removeDuplicatesById(inputArray) {
            return inputArray.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.uniqueId === item.uniqueId
                    ))
            );
        },
        nextPage() {
            if (this.page.total <= this.records.length) {
                return;
            }
            this.loading = 2;
            this.page.currentPage += 1;
            this.getPage();
        },
    },
    created() {
        this.refreshPage();
    },
};
</script>

<style lang="scss" scoped>
.list_wrap {
    padding: 10px 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    //min-height: 402px;
    //height: 400px;
    .list {
        width: 700px;
        // height: 265px;
        background: #fff;
        margin: 0 auto;
        position: relative;

        .btn {
            position: absolute;
            right: -110px;
            top: 0;
        }

        .item {
            border-bottom: 1px solid #edf0f2;
            display: flex;
            padding: 10px;
            padding-bottom: 0;
            box-sizing: border-box;
            height: fit-content;
            min-height: 170px;

            article {
                flex: 0 0 72px;
                display: flex;
                justify-content: flex-end;
                box-sizing: border-box;
                height: 100%;

                > img {
                    width: 40px;
                    height: 40px;
                }
            }

            > div {
                padding: 0 10px;
                box-sizing: border-box;
                flex: 1;
                position: relative;

                .top {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .left_info {
                        .name {
                            margin: 0;
                        }

                        .time {
                            color: #b4bfcc;
                            margin: 0;
                            margin-top: 10px;
                        }
                    }

                    > p {
                        margin: 0;
                        color: #b4bfcc;
                        border: 1px solid #b4bfcc;
                        height: fit-content;
                        border-radius: 10px;
                        padding: 0 5px;
                        box-sizing: border-box;

                        span {
                            color: #28cb97;
                            margin-right: 5px;
                        }
                    }
                }

                .content {
                    .title {
                        display: block;
                        margin: 5px 0;
                    }

                    .nr {
                        width: 100%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin: 5px 0;
                        overflow: hidden;
                    }

                    .imgBox {
                        width: 440px;
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                        grid-gap: 10px;

                        img {
                            height: 100px;
                            // border: 1px solid #0f0;
                        }
                    }
                }

                .down {
                    //position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 45px;
                    width: 100%;
                    border-top: 1px solid #edf0f2;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .hezi {
                        display: flex;
                        align-items: center;

                        > img {
                            width: 16px;
                            height: 14px;
                            margin-right: 5%;
                        }

                        p {
                            margin: 0;
                            color: #28cb97;
                            white-space: nowrap;
                        }
                    }

                    > div {
                        display: flex;
                        align-items: center;

                        span {
                            color: #b4bfcc;
                            cursor: pointer;
                        }

                        span:nth-of-type(2)::before {
                            content: '|';
                            margin: 0 10px;
                            position: relative;
                            top: -2px;
                        }
                    }
                }
            }
        }
    }
}

.down_wrap {
    height: 100% !important;
}
</style>
