<template>
<div>
    <el-dialog
        custom-modal
        width="400px"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        title="修改冻结"
        :close-on-click-modal="false"
        append-to-body>
        <el-form :model="formData" :rules="fromRules" ref="formRef" label-position="top" size="mini">
            <el-form-item required label="冻结时间" prop="timeRange">
                <el-date-picker value-format="yyyy-MM-dd"
                                v-model="formData.timeRange"
                                :picker-options="pickerOptions"
                                type="daterange">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="reason" label="原因">
                <el-input :maxlength="250" show-word-limit type="textarea" v-model="formData.reason" size="mini"
                          placeholder=""></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="$emit('update:visible',false)">取 消</el-button>
            <el-button type="primary" @click="handleConfirm()" size="small">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import {computed, defineComponent, inject, ref} from 'vue'
import {updateFreezeApi} from '../../../api/student/contractApi'
import dayjs from 'dayjs'

export default defineComponent({
    props: {
        visible: Boolean
    },
    emits: ['update:visible'],
    setup (props, {emit}) {

        const formRef = ref()

        const fromRules = ref({
            timeRange: [{required: true, message: '请选择冻结时间'}],
            reason: [{required: true, message: '原因是必填的'}]
        })

        const formData = ref({
            timeRange: [],
            reason: undefined
        })

        const confirmLoading = ref(false)

        const pickerOptions = {
            disabledDate (time) {
                return dayjs(time).add(1, 'day').isBefore(dayjs())
            }
        }

        const handleConfirm = () => {
            confirmLoading.value = true
            formRef.value.validate()
                .then(() => updateFreezeApi({
                    ...formData.value,
                    startTime: formData.value.timeRange[0],
                    endTime: formData.value.timeRange[1],
                    contractId: data.value.id
                }))
                .then(() => {
                    emit('update:visible', false)
                    refreshContract()
                    refreshWater()
                })
                .finally(() => {
                    confirmLoading.value = false
                })
        }

        const refreshWater = inject('refreshWater')

        const refreshContract = inject('refreshContract')

        const getData = inject('getData')

        const data = computed(() => getData())

        return {
            formRef,
            fromRules,
            formData,
            data,
            confirmLoading,
            pickerOptions,
            handleConfirm
        }
    }
})
</script>
<style scoped>
>>> .el-form-item__label {
    line-height: 12px !important;
    margin: 0 !important;
    color: #3b3b3b !important;
}
</style>
