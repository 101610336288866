<template>
    <div>
        <el-dialog title="改合同"
                   :visible="visible"
                   @update:visible="val=>$emit('update:visible',val)"
                   width="750px"
                   :close-on-click-modal="false"
                   custom-class="ballet-vue-change-contract"
                   append-to-body>
            <div slot="title" class="title">
                <span @click="goBackModal">改套餐</span>&gt;
                <p>改合同</p>
            </div>
            <div class="contract_info">
                <div>
                    <div class="contract_title">当前合同</div>
                    <div class="info-items">
                        <div class="info-item">
                            <span>套餐名称</span>
                            <span>{{ data.paperName }}</span>
                        </div>
                        <div class="info-item">
                            <span>套餐类型</span>
                            <span>{{ data.packageType === 1 ? '次卡' : (data.packageType === 2 ? '年卡' : '') }}</span>
                        </div>
                        <div class="info-item">
                            <div style="display: flex;align-items: center;gap: 5px;">
                                <span>合同有效期</span>
                                <el-tooltip placement="top" content="一个月 = 30天">
                                    <i class="el-icon-question" style="cursor: pointer;font-size: 20px;"></i>
                                </el-tooltip>
                            </div>
                            <span>{{ `${data.leaveDate}天 (${Math.floor(data.leaveDate / 30)})个月` }}</span>
                        </div>
                        <div class="info-item">
                            <span>合同终止日期</span>
                            <span v-if="data.activationTime">{{ data.overTime ? data.overTime : data.endTime }}</span>
                            <span v-else style="color: #f5222d;">未激活</span>
                        </div>
                    </div>
                    <div class="info-items">
                        <div class="info-item" v-if="data.packageType===1">
                            <span>普通课时</span>
                            <span>{{ data.ordinaryClassCount + data.giveOrdinaryClassCount }}</span>
                        </div>
                        <template v-else-if="data.packageType===2">
                            <div class="info-item">
                                <span>每周上课次数</span>
                                <span>{{ data.weekOrdinaryClassCount }}</span>
                            </div>
                            <div class="info-item">
                                <span>周末上课次数</span>
                                <span>{{ data.weekendOrdinaryClassCount }}</span>
                            </div>
                        </template>
                        <div class="info-item">
                            <span>特殊课时</span>
                            <span>{{ data.specialClassCount }}</span>
                        </div>
                    </div>
                    <div class="info-items">
                        <div class="info-item">
                            <span>剩余金额（元）</span>
                            <span>{{ remainingAmount.toFixed(2) }}</span>
                        </div>
                        <div class="info-item" v-if="data.packageType===1">
                            <span>剩余普通课时</span>
                            <span>{{ data.remainingOrdinaryClassCount }}</span>
                        </div>
                        <div class="info-item">
                            <span>剩余特殊课时</span>
                            <span>{{ data.remainingSpecialClassCount }}</span>
                        </div>
                    </div>
                </div>
                <el-divider/>
                <div>
                    <div class="contract_title" style="color: #faad14;">修改后合同</div>
                    <div class="info-items">
                        <div class="info-item">
                            <span>剩余金额（元）</span>
                            <span>{{ afterUpdateRemainingAmount.toFixed(2) }}</span>
                        </div>
                        <div class="info-item" v-if="formData.type===1">
                            <span>剩余普通课时</span>
                            <span>{{ formData.allOrdinaryClass + formData.giftOrdinaryClass }}</span>
                        </div>
                        <template v-else-if="formData.type===2">
                            <div class="info-item">
                                <span>每周上课次数</span>
                                <span>{{ formData.weekAllClass }}</span>
                            </div>
                            <div class="info-item">
                                <span>周末上课次数</span>
                                <span>{{ formData.weekendAllClass }}</span>
                            </div>
                        </template>
                        <div class="info-item">
                            <span>剩余特殊课时</span>
                            <span>{{ formData.allSpecialClass }}</span>
                        </div>
                    </div>
                    <el-divider/>
                    <div class="info-items">
                        <div class="info-item">
                            <span>合同差价（元）</span>
                            <span>{{ difference.toFixed(2) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <el-form :model="formData" ref="formRef" :rules="formRules" label-position="top" size="mini" inline>
                <el-form-item prop="packageId" style="width: 45%;" label="新合同套餐">
                    <package-select @change="handlePackageChange"
                                    style="width: 100%;"
                                    v-model="formData.packageId">
                    </package-select>
                </el-form-item>
                <el-form-item style="width: 45%;" label="套餐类型">
                    {{ formData.type === 1 ? '次卡' : (formData.type === 2 ? '年卡' : '') }}
                </el-form-item>
                <el-form-item prop="shouldMoney" style="width: 45%;" label="应收价格（元）">
                    <amount-input v-model="formData.shouldMoney"></amount-input>
                </el-form-item>
                <template v-if="formData.type === 1">
                    <el-form-item prop="allOrdinaryClass" style="width: 45%;" label="普通课时">
                        <custom-number-input v-model="formData.allOrdinaryClass"></custom-number-input>
                    </el-form-item>
                    <el-form-item prop="giftOrdinaryClass" style="width: 45%;" label="赠送普通课时">
                        <custom-number-input v-model="formData.giftOrdinaryClass"></custom-number-input>
                    </el-form-item>
                </template>
                <template v-else-if="formData.type === 2">
                    <el-form-item prop="weekClassNum" style="width: 45%;" label="每周上课课时">
                        <custom-number-input v-model="formData.weekAllClass"></custom-number-input>
                    </el-form-item>
                    <el-form-item prop="weekendClassNum" style="width: 45%;" label="周末上课课时">
                        <custom-number-input v-model="formData.weekendAllClass"></custom-number-input>
                    </el-form-item>
                </template>
                <el-form-item prop="allSpecialClass" style="width: 45%;" label="特殊课时">
                    <custom-number-input v-model="formData.allSpecialClass"></custom-number-input>
                </el-form-item>
                <el-form-item prop="leaveDate" style="width: 45%;" label="合同有效期">
                    <div style="display: flex;align-items: center;gap: 3px;">
                        <el-input @input.native="handleDayInput" v-model="formData.leaveDate"
                                  style="width: 60px;"></el-input>
                        <span>天(</span>
                        <el-input @input.native="handleMonthInput" v-model="formData.leaveDateMonth"
                                  style="width: 60px;"></el-input>
                        <span>月</span>
                        <el-input @input.native="handleDateInput" v-model="formData.leaveDateDay"
                                  style="width: 60px;"></el-input>
                        <span>天</span>
                        <span>)</span>
                        <el-tooltip placement="top" content="一个月 = 30天">
                            <i class="el-icon-question" style="cursor: pointer;font-size: 20px;"></i>
                        </el-tooltip>
                    </div>
                </el-form-item>
                <el-form-item required style="width: 100%;" label="适用规则">
                    <package-rule-select :package-id="formData.packageId"
                                         :ordinary-class.sync="formData.ordinaryClass"
                                         :special-class.sync="formData.specialClass"
                                         :ordinary-class-periodicity.sync="formData.ordinaryTime"
                                         :special-class-periodicity.sync="formData.specialTime">
                    </package-rule-select>
                </el-form-item>
                <br>
                <el-form-item prop="payType" style="width: 45%;" label="支付方式">
                    <pay-method-select v-model="formData.payType"></pay-method-select>
                </el-form-item>
                <el-form-item prop="reason" style="width: 100%;" label="原因">
                    <el-input v-model="formData.reason" :maxlength="250" show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button size="mini" @click="$emit('update:visible',false)">取 消</el-button>
                <el-button size="mini" style="background:#29cb97;border:none;" type="primary" @click="doChange"
                           :loading="confirmLoading">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {changeContractApi, changeContractPackageApi} from '../../../api/student/contractApi';
import {cloneObject, isEqual} from '../../../utils/ObjectUtils';
import PackageSelect from '../../Form/PackageSelect.vue';
import PackageRuleSelect from '../../Form/PackageRuleSelect.vue';
import AmountInput from "@/components/Form/AmountInput.vue";
import CustomNumberInput from "@/components/Form/CustomNumberInput.vue";
import PayMethodSelect from "@/components/Form/PayMethodSelect.vue";

const defaultFormData = {
    packageId: undefined,
    type: undefined,
    shouldMoney: undefined,
    allOrdinaryClass: undefined,
    giftOrdinaryClass: undefined,
    weekAllClass: undefined,
    weekendAllClass: undefined,
    allSpecialClass: undefined,
    ordinaryClass: undefined,
    specialClass: undefined,
    ordinaryTime: undefined,
    specialTime: undefined,
    leaveDate: undefined,
    leaveDateMonth: undefined,
    leaveDateDay: undefined,
    payType: undefined,
    reason: undefined
};

export default {
    components: {PayMethodSelect, CustomNumberInput, AmountInput, PackageRuleSelect, PackageSelect},
    props: ['visible'],
    emits: ['update:visible', 'back'],
    inject: ['getData', 'refreshWater'],
    data() {
        return {
            formRules: {
                packageId: [{required: true, message: '请选择新套餐合同'}],
                shouldMoney: [{required: true, message: '应收金额是必填的'}],
                allOrdinaryClass: [{required: true, message: '赠送课时是必填的'}],
                giftOrdinaryClass: [{required: true, message: '赠送课时是必填的'}],
                allSpecialClass: [{required: true, message: '特殊课时是必填的'}],
                leaveDate: [{required: true, message: '合同有效期是必填的'}],
                payType: [{required: true, message: '支付方式是必填的'}],
                reason: [{required: true, message: '原因是必填的'}],
            },
            formData: cloneObject(defaultFormData),
            confirmLoading: false,
        };
    },
    methods: {
        goBackModal() {
            this.$emit('update:visible', false);
            this.$emit('back');
        },
        doChange() {
            this.confirmLoading = true;
            this.$refs.formRef.validate()
                .then(() => changeContractPackageApi({
                    ...this.formData,
                    ordinaryClass: this.formData.ordinaryClass ? this.formData.ordinaryClass.join(',') : '',
                    specialClass: this.formData.specialClass ? this.formData.specialClass.join(',') : '',
                    ordinaryTime: this.formData.ordinaryTime ? this.formData.ordinaryTime.join(',') : '',
                    specialTime: this.formData.specialTime ? this.formData.specialTime.join(',') : '',
                    contractId: this.data.id
                }))
                .then(() => {
                    this.refreshWater();
                    this.$refs.formRef.resetFields();
                    this.$emit('update:visible', false);
                })
                .finally(() => this.confirmLoading = false);
        },
        handlePackageChange(val, packageInfo) {
            this.formData.type = packageInfo.type;
            this.formData.shouldMoney = packageInfo.price;
            if (packageInfo.type === 1) {
                this.formData.allOrdinaryClass = packageInfo.classNum;
                this.formData.giftOrdinaryClass = packageInfo.giveClassNum;
            } else if (packageInfo.type === 2) {
                this.formData.weekAllClass = packageInfo.weekClassNum;
                this.formData.weekendAllClass = packageInfo.weekendClassNum;
            }
            this.formData.allSpecialClass = packageInfo.specialClassNum;
            this.formData.leaveDate = packageInfo.validityPeriod;
            this.formData.leaveDateMonth = Math.floor(packageInfo.validityPeriod / 30);
            this.formData.leaveDateDay = packageInfo.validityPeriod % 30;
        },
        handleDayChange(e, fun) {
            const value = parseInt(e.target.value);
            if (isNaN(value) || value < 0) {
                this.formData.leaveDate = 0;
                this.formData.leaveDateMonth = 0;
                this.formData.leaveDateDay = 0;
            } else {
                fun(value);
            }
        },
        handleDayInput(e) {
            this.handleDayChange(e, (value) => {
                this.formData.leaveDate = value;
                this.formData.leaveDateMonth = Math.floor(this.formData.leaveDate / 30);
                this.formData.leaveDateDay = this.formData.leaveDate % 30;
            });
        },
        handleMonthInput(e) {
            this.handleDayChange(e, (value) => {
                if (value > 12) {
                    value = 12;
                }
                this.formData.leaveDateMonth = value;
                this.formData.leaveDate = this.formData.leaveDateMonth * 30 + this.formData.leaveDateDay;
            });
        },
        handleDateInput(e) {
            this.handleDayChange(e, (value) => {
                if (value > 30) {
                    value = 30;
                }
                this.formData.leaveDateDay = value;
                this.formData.leaveDate = this.formData.leaveDateMonth * 30 + this.formData.leaveDateDay;
            });
        },
    },
    computed: {
        data() {
            return this.getData();
        },
        remainingAmount() {
            return this.data.remainingAmount;
        },
        difference() {
            const float = parseFloat(this.formData.shouldMoney);
            if (float) {
                return float - this.data.shouldMoney;
            }
            return 0;
        },
        afterUpdateRemainingAmount() {
            return this.remainingAmount + this.difference;
        }
    },
    watch: {
        visible: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.formData = cloneObject(defaultFormData);
                    this.formData.packageId = this.data.packageId;
                    this.formData.shouldMoney = this.data.shouldMoney;
                    this.formData.allOrdinaryClass = this.data.remainingOrdinaryClassCount;
                    this.formData.giftOrdinaryClass = this.data.giveOrdinaryClassCount;
                    this.formData.allSpecialClass = this.data.remainingSpecialClassCount;
                    this.formData.payType = this.data.payType;

                    console.log(cloneObject(this.formData));
                    setTimeout(() => {
                        this.$refs.formRef.clearValidate();
                    });
                }
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.title {
    display: flex;
    align-items: center;

    span {
        margin-right: 10px;
    }

    p {
        margin: 0 0 0 10px;
    }

    span {
        font-size: 16px;
        color: #818c99;
        text-decoration: underline;
        text-align: center;
        cursor: pointer;
    }
}

.contract_info {
    background: #f5f7fa;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 4px;

    div {
        .contract_title {
            font-size: 14px;
            margin: 0 0 10px;
            color: #292e33;
        }

        .info-items {
            display: flex;
            align-items: start;

            .info-item {
                width: 25%;
                display: flex;
                gap: 8px;
                flex-direction: column;

                span:first-child {
                    color: #818c99;
                }

                span:last-child {
                    word-break: break-all;
                    line-height: 1.3em;
                    color: #3b3b3b;
                }
            }
        }

        .info-items:not(:last-child) {
            margin-bottom: 10px;
        }
    }

}
</style>
<style lang="scss">
.ballet-vue-change-contract {
    .el-dialog__body {
        padding: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeef5;
        border-top: 1px solid #ebeef5;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
    }
}
</style>
<style scoped>
>>> .el-form-item__label {
    color: #818c99 !important;
    padding: 0 !important;
}
</style>
