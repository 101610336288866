<template>
    <div>
        <el-dialog
            class="asd"
            :modal="true"
            width="810px"
            :visible.sync="visible"
            @update:visible="val=>$emit('update:visible',val)"
            title="打印名帖"
            custom-class="printNameCardModal-dialog"
            append-to-body
            :before-close="closePrintNameCardModal"
        >
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="学员信息">
                    <template slot-scope="scope">
                        <div class="student_info">
                            <img :src="scope.row.imgUrl" alt="">
                            <div class="font">
                                <div>
                                    <span>{{ scope.row.nickname }}</span>
                                    <img :style="scope.row.sex==1?'background:#29cb97;':'background:#fda6c9;'"
                                         :src="scope.row.sex==1?'/src/assets/img/man.png':'/src/assets/img/woman.png'"
                                         alt="">
                                </div>
                                <span>{{ Math.floor(scope.row.age / 12) }}岁{{ scope.row.age % 12 }}个月</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="联系人">
                    <template slot-scope="scope">
                        <div class="linkman">
                            <div>
                                <span>{{ scope.row.linkMan }}</span>
                                <span>{{ '(' + scope.row.linkManRelation + ')' }}</span>
                            </div>
                            <span>{{ scope.row.linkMnaTel }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="adviser" label="顾问">
                </el-table-column>
            </el-table>
            <span slot="footer">
        <el-button size="mini" @click="$emit('update:visible',false)">取消</el-button>
        <el-button
            type="primary"
            size="mini"
            style="background: #29cb97; border: 1px solid #29cb97">打印{{ 3 }}</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "BalletVuePrintNameCard",
    props: ["visible",'closePrintNameCardModal'],
    emits: ['update:visible'],
    data() {
        return {
            tableData: [
                {
                    id: 1,
                    //头像
                    imgUrl: 'https://picsum.photos/50/?key=' + Math.random(),
                    //昵称
                    nickname: '小明',
                    //年龄 单位(月)
                    age: 43,
                    //性别 0女 1男
                    sex: 0,
                    //联系人
                    linkMan: 'lucy',
                    //联系人关系
                    linkManRelation: '其他',
                    //联系人电话
                    linkMnaTel: '13802122014',
                    //顾问
                    adviser: '张三',
                },
                {
                    id: 2,
                    //头像
                    imgUrl: 'https://picsum.photos/50/?key=' + Math.random(),
                    //昵称
                    nickname: '小张',
                    //年龄 单位(月)
                    age: 43,
                    //性别 0女 1男
                    sex: 1,
                    //联系人
                    linkMan: 'jack',
                    //联系人关系
                    linkManRelation: '其他',
                    //联系人电话
                    linkMnaTel: '13802122014',
                    //顾问
                    adviser: '张三',
                },
                {
                    id: 3,
                    //头像
                    imgUrl: 'https://picsum.photos/50/?key=' + Math.random(),
                    //昵称
                    nickname: '小王',
                    //年龄 单位(月)
                    age: 43,
                    //性别 0女 1男
                    sex: 1,
                    //联系人
                    linkMan: 'bill',
                    //联系人关系
                    linkManRelation: '其他',
                    //联系人电话
                    linkMnaTel: '13802122014',
                    //顾问
                    adviser: '李四',
                },
            ],
            multipleSelection: [],
        };
    },

    mounted() {
    },

    methods: {
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
    },
};
</script>

<style lang="scss" scoped>
.student_info {
    display: flex;
    align-items: center;
    height: 100%;

    > img {
        flex: 0 0 50px;
        height: 50px;
        border-radius: 50%;
        background: #29cb97;
        margin-right: 10px;
    }

    .font {
        flex: 1;

        > div {
            display: flex;
            align-items: center;

            > span {
                font-size: 14px;
                color: #333;
                margin-right: 10px;
            }

            img {
                width: 15px;
                height: 15px;
                background: #29cb97;
                border-radius: 50%;
                padding: 3px;
                box-sizing: border-box;
                position: relative;
                top: -3px;
            }
        }
    }
}
</style>
<style lang="scss">
.printNameCardModal-dialog {
    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #ccc;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 40px;
        color: #292e33;

        i::before {
            position: relative;
            top: -10px;
        }

        span {
            font-size: 14px;
        }
    }

    .el-dialog__body {
        padding: 20px 10px;
        box-sizing: border-box;
        min-height: 380px;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
        overflow: hidden;
        border-top: 1px solid #ccc;
    }
}
</style>
