<template>
<el-dialog list-detail-modal
           width="1200px"
           :show-close="false"
           center
           append-to-body
           top="10vh"
           :visible="visible"
           lock-scroll
           @update:visible="val=>$emit('update:visible',val)">
    <div class="dialog-main" v-infinite="loadMore">
        <div style="height: 100%;width: 100%;" v-loading="true" v-if="state==='loading'"></div>
        <div style="height: 100%;width: 100%;" v-else-if="state==='error'" @click="refreshData">
            加载失败,点击重试
        </div>
        <div class="list-info" v-else-if="state==='success'">
            <div class="list-info-left">
                <div style="font-size: 19px;" class="primary-text-color">
                    <template>
                        <div>(+{{ list.contactsList[0].phoneArea }})
                            {{ list.contactsList[0].phone }}
                        </div>
                        <div>
                            {{ list.contactsList[0].name ? list.contactsList[0].name : '未知' }}
                            （{{ $store.getters['common/relationMap'][list.contactsList[0].affiliation] }}）
                        </div>
                    </template>
                </div>
                <div>
                    <span>微信昵称：</span>
                    <span>
                            {{
                            list.contactsList[0].wechatName ? list.contactsList[0].wechatName : '暂无'
                        }}
                        </span>
                    <el-button style="padding: 0;margin: 0;" type="text"
                               @click="infoDialogVisible=true">
                        [查看更多信息]
                    </el-button>
                </div>
                <div>
                    <span>来源渠道：</span>
                    <span v-if="Array.isArray(list.sources)&&list.sources.length">
                            {{ $store.getters['common/sourceTypeMap'][list.sourceType] }}/
                            {{ list.sources.map(s => s.name).join('/') }}
                        </span>
                    <span v-else>{{ $store.getters['common/sourceTypeMap'][list.sourceType] }}</span>
                </div>
            </div>
            <div class="list-info-middle">
                <div class="student-card-wrapper">
                    <div class="student-card" v-for="student in list.students">
                        <div style="display: flex;justify-content: space-between;"
                             :key="student.id">
                            <div style="display: flex;flex-direction: column;gap: 8px;">
                                <div style="display:flex;gap: 5px;align-items: center;">
                                    <span>{{ student.name }}</span>
                                    <gender-view :gender="student.sex"></gender-view>
                                </div>
                                <div class="student-card-age">
                                    {{ getBirthdayDisplay(student.birthdayTime) }}
                                </div>
                            </div>
                        </div>
                        <div class="student-card-others">
                            <div>
                                <span>学校：</span>
                                <span class="none">{{ student.school ? student.school : '暂无' }}</span>
                            </div>
                            <div>
                                <span>年级：</span>
                                <span class="none">{{ student.grade ? student.grade : '暂无' }}</span>
                            </div>
                            <div style="display: flex;">
                                <span>兴趣课类：</span>
                            </div>
                            <div class="interest-class-list"
                                 v-if="Array.isArray(student.likeSubjectIds)&&student.likeSubjectIds.length">
                                <div class="interest-class-item"
                                     v-for="(likeSubjectName,index) in student.likeSubjectNames"
                                     :key="index">{{ likeSubjectName }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list-info-right">
                <div style="font-size: 18px;">
                    <div v-if="computedReceiveStatus==='Pending'">
                        <el-button size="mini" type="primary" @click="handleConfirm">接收</el-button>
                        <el-button size="mini" type="danger" @click="handleReject">拒绝</el-button>
                    </div>
                    <div class="fail-text-color" v-else-if="computedReceiveStatus==='Reject'">已被拒绝</div>
                    <div style="color: #818c99;" v-else-if="computedReceiveStatus==='Withdraw'">已撤回</div>
                    <div v-else-if="computedReceiveStatus==='Repeat'">名单重复</div>
                    <div class="primary-text-color" v-else-if="computedReceiveStatus==='Received'">已接收</div>
                </div>
                <table class="list-status">
                    <tr>
                        <th style="display: flex;justify-content: space-between;width: 100%;">
                            <span>顾</span>
                            <span>问：</span>
                        </th>
                        <th>
                            <span>{{ list.consultantId ? list.consultantName : '无' }}</span>
                        </th>
                    </tr>
                    <tr>
                        <th style="display: flex;justify-content: space-between;width: 100%;">
                            <span>市</span>
                            <span>场：</span>
                        </th>
                        <th>
                            <span>{{ list.marketId ? list.marketName : '无' }}</span>
                        </th>
                    </tr>
                    <tr>
                        <th>客户评级：</th>
                        <th>
                            <div style="display: flex;align-items: center;">
                                <div style="width: 25px;" v-for="i in 5" :key="i">
                                    <i v-if="list.customerRating>=i"
                                       style="color: #fab222;font-size: 25px;cursor: pointer;"
                                       class="el-icon-star-on"></i>
                                    <i v-else style="color: #fab222;font-size: 20px;cursor: pointer;"
                                       class="el-icon-star-off"></i>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>名单状态：</th>
                        <th>
                            <span class="primary-text-color" v-if="list.tableState==='AwaitADecision'">
                                待定【默认】
                            </span>
                            <span class="primary-text-color" v-else-if="list.tableState==='Efficiently'">
                                有效【默认】
                            </span>
                            <span style="color: red;" v-else-if="list.tableState==='NotValid'">
                                无效
                            </span>
                        </th>
                    </tr>
                    <tr>
                        <th>分配状态：</th>
                        <th>
                            <span style="color: red;"
                                  v-if="list.allocationState===1||list.allocationState===5">
                                {{ $store.getters['common/allocationStateMap'][list.allocationState] }}
                            </span>
                            <span class="primary-text-color" v-else>
                                {{ $store.getters['common/allocationStateMap'][list.allocationState] }}
                            </span>
                        </th>
                    </tr>
                    <tr>
                        <th>跟进状态：</th>
                        <th>
                            <span class="primary-text-color">
                                {{ $store.getters['common/followStateMap'][list.followState] }}
                            </span>
                        </th>
                    </tr>
                </table>
            </div>
        </div>
        <div style="line-height: normal;" v-if="state==='success'">
            <div class="nav-bar">
                <ul>
                    <li v-for="selectTab in selectTabs"
                        :key="selectTab.name"
                        @click="activeBar=selectTab"
                        :class="['nav-bar-item',{active:activeBar&&activeBar.code===selectTab.code}]">
                        {{ selectTab.name }}
                    </li>
                </ul>
            </div>
            <div class="other-content" v-if="activeBar">
                <component :is="activeBar.component"
                           ref="activeRef"
                           v-bind="activeBar.getBindValue()">
                </component>
            </div>
        </div>
    </div>
    <list-more-info-modal :visible.sync="infoDialogVisible"
                          :data="list">
    </list-more-info-modal>
    <batch-receive-transfer-modal :visible.sync="batchReceiveTransferModalVisible"
                                  use-selected
                                  @ok="handleReceiveOk"
                                  :selected-list="[cloneObject(transferRecord)]">
    </batch-receive-transfer-modal>
</el-dialog>
</template>
<script>
import {defineComponent, toRefs, ref, watch, getCurrentInstance, computed} from 'vue';
import GenderView from '@/components/Common/GenderView.vue';
import ListMoreInfoModal from '@/components/List/detail/ListMoreInfoModal.vue';
import {getBirthdayDisplay} from '../../utils/studentUtils';
import ListFollowUpRecord from '@/components/List/detail/ListFollowUpRecord.vue';
import ListOperationRecord from '@/components/List/detail/ListOperationRecord.vue';
import BatchReceiveTransferModal from '@/components/List/BatchReceiveTransferModal.vue';
import {getDetailApi} from '@/api/student/listApi';
import {initListDetailData} from '@/components/List/ListUtils';
import {transferRejectApi} from '@/api/student/listTransferApi';
import {cloneObject} from '@/utils/ObjectUtils';

export default defineComponent({
    methods: {cloneObject, getBirthdayDisplay},
    components: {
        BatchReceiveTransferModal,
        ListMoreInfoModal,
        GenderView
    },
    props: {
        visible: Boolean,
        listId: Number,
        receiveStatus: String,
        transferRecordId: Number,
        transferRecord: {
            type: Object,
            default () {
                return {};
            }
        }
    },
    emits: ['update:visible', 'updated'],
    setup (props, {emit}) {

        const currentInstance = getCurrentInstance();

        const state = ref();

        const list = ref({});

        const activeRef = ref();

        const selectTabs = [
            {
                code: '1',
                name: '跟进记录',
                component: ListFollowUpRecord,
                nextPage: () => {
                    activeRef.value.nextPage();
                },
                getBindValue: () => {
                    return {
                        listId: list.value.id,
                        listStatus: list.value.tableState,
                        allocationState: list.value.allocationState,
                        formListStatus: undefined,
                        followUpPhase: list.value.followUpPhase
                    };
                }
            },
            {
                code: '4',
                name: '操作记录',
                component: ListOperationRecord,
                nextPage: () => {
                    activeRef.value.nextPage();
                },
                getBindValue: () => {
                    return {listId: list.value.id};
                }
            },
        ];

        const activeBar = ref();

        const infoDialogVisible = ref(false);

        const batchReceiveTransferModalVisible = ref(false);

        const modelReceiveStatus = ref();

        const handleConfirm = () => {
            batchReceiveTransferModalVisible.value = true;
        };

        const handleReject = () => {
            currentInstance.proxy.$confirm('确认要拒绝吗？', {
                type: 'warning',
                showClose: false,
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        transferRejectApi({id: props.transferRecordId}).then(() => {
                            done();
                            modelReceiveStatus.value = 'Reject';
                            emit('updated');
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                        instance.confirmButtonLoading = false;
                    }
                }
            });

        };

        const handleReceiveOk = () => {
            modelReceiveStatus.value = 'Received';
            emit('updated');
        };

        const refreshData = () => {
            state.value = 'loading';
            getDetailApi(props.listId)
                .then(res => {
                    state.value = 'success';
                    list.value = initListDetailData(res.data.data);
                }).catch(() => state.value = 'error')
                .finally(() => state.value = 'success');
            const currentActiveBar = activeBar.value ? activeBar.value : selectTabs[0];
            activeBar.value = undefined;
            setTimeout(() => activeBar.value = currentActiveBar);
        };

        const loadMore = () => {
            activeBar.value && activeBar.value.nextPage();
        };

        const computedReceiveStatus = computed(() => {
            return modelReceiveStatus.value ? modelReceiveStatus.value : props.receiveStatus;
        });

        watch(() => props.visible, newVal => {
            if (newVal) {
                refreshData();
            } else {
                activeBar.value = undefined;
            }
        });

        return {
            ...toRefs,
            state,
            list,
            selectTabs,
            activeBar,
            activeRef,
            modelReceiveStatus,
            infoDialogVisible,
            batchReceiveTransferModalVisible,
            handleConfirm,
            handleReject,
            handleReceiveOk,
            refreshData,
            loadMore,
            computedReceiveStatus
        };
    }
});
</script>
<style scoped lang="scss" src="../../assets/scss/modules/list/listDetail.scss"></style>
<style scoped>
>>> .follow-up-record-container .el-icon-edit {
    display: none;
}

>>> .nav-bar-item {
    width: 50%;
}
</style>
<style>
[list-detail-modal] .el-dialog__header, [list-detail-modal] .el-dialog__body {
    padding: 0 !important;
}
</style>
