<template>
<el-input
    class="search-input"
    v-bind="$attrs"
    @focus="handleFocus"
    @blur="handleBlur"
    :value="value"
    @input="val=>$emit('update:value',val)">
    <template v-for="(index, name) in $slots" v-slot:[name]>
        <slot :name="name"></slot>
    </template>
    <template v-if="icon" v-slot:suffix>
        <i class="el-input__icon el-icon-search" @click="$emit('search')"></i>
    </template>
</el-input>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'update:value',
    },
    emits: ['search', 'update:value'],
    props: {
        value: [Number, String],
        icon: Boolean
    },
    methods: {
        handleEnter (e) {
            if (e.key === 'Enter') {
                this.$emit('search')
                e.stopPropagation()
            }
        },
        handleFocus () {
            window.addEventListener('keypress', this.handleEnter)
        },
        handleBlur () {
            window.removeEventListener('keypress', this.handleEnter)
        },
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/variables";

.search-input .el-icon-search {
    cursor: pointer;
}

.search-input .el-icon-search:hover {
    color: $--color-primary;
}

</style>
