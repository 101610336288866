<template>
    <el-dialog
        width="1000px"
        :visible="visible"
        @update:visible="val=>$emit('update:visible',val)"
        title="title"
        custom-class="PaikeComp-dialog"
        append-to-body>
        <div class="top">
            <span>排课</span>
            <img @click="$emit('update:visible',false)" src="../../../assets/img/cha3.png" alt=""/>
        </div>
        <div class="cont" v-loading="loading">
            <div class="font">
                <div class="box title">
                    <div class="h4 primary-text-color">
                        <span>{{ classInfo.className }}</span>
                        <span>
                            ({{
                                [classInfo.classTypeName, classInfo.childrenClassTypeName].filter(item => item).join(' ')
                            }})
                        </span>
                    </div>
                    <div class="kouke">
                        <span>扣课课时：</span>
                        <el-radio v-model="formData.consumeClassType" label="Ordinary">普通</el-radio>
                        <el-radio v-model="formData.consumeClassType" label="Special">特殊</el-radio>
                    </div>
                </div>
                <div class="box info">
                    <div>
                        <div class="info_item">
                            <p v-if="classInfo.startTime">{{ getWeekDayDisplay(dayjs(classInfo.startTime).day()) }}
                                {{ classInfo.startTime.split(' ')[1] }}</p>
                            <div v-if="Array.isArray(classInfo.mainTeachers)">
                                <img src="../../../assets/img/user-yellow.png" alt=""/>
                                <p>{{ classInfo.mainTeachers[0].name }}</p>
                            </div>
                            <div v-if="Array.isArray(classInfo.classrooms)">
                                <img src="../../../assets/img/classroom.png" alt=""/>
                                <p>{{ classInfo.classrooms[0].name }}</p>
                            </div>
                        </div>
                        <div class="box tip">
                            <strong>温馨提示：先选中开始与结束课程，再对单节课程进行选中或取消选中。</strong>
                        </div>
                    </div>

                    <div style="text-align: right;">
                        <div style="display: flex;gap: 8px;justify-content: right">
                            <div>
                                <span>一周可选课时：</span>
                                <span class="primary-text-color">{{ classInfo.weekClass }}</span>
                            </div>
                            <div>
                                <span>周末可选课次：</span>
                                <span class="primary-text-color">{{ classInfo.weekendClass }}</span>
                            </div>
                        </div>
                        <div class="contract_info">
                            <span>当前扣课合同：</span>
                            <change-contract-button :student-id="studentId"
                                                    :contract="{id:contractId?contractId:defaultContractId}"
                                                    click-mode="select"
                                                    @change="handleContractChange">
                                <template v-slot:contract>
                                    <span>{{ classInfo.contractName }}</span>
                                    <span class="primary-text-color">{{
                                            'Effective' === classInfo.contractStatus ? '有效' : $store.getters["common/contractStatusMap"][classInfo.contractStatus]
                                        }}</span>
                                </template>
                            </change-contract-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="class">
                <div class="top_class">
                    <div class="left">
                        <div>
                            <span>自动排课</span>
                            <el-switch
                                v-model="formData.auto"
                                size="mini">
                            </el-switch>
                        </div>
                        <div>
                            <span>是否自动加入排队</span>
                            <el-checkbox size="mini" v-model="formData.autoLineUp"></el-checkbox>
                        </div>
                    </div>
                    <div class="right">
                        <div>
                            <span>开始时间:</span>
                            <el-date-picker
                                style="width: 140px"
                                v-model="queryFormData.startDate"
                                size="mini"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择开始时间">
                            </el-date-picker>
                        </div>
                        <div>
                            <span>结束时间:</span>
                            <el-date-picker
                                style="width: 140px"
                                v-model="queryFormData.endDate"
                                size="mini"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择结束时间">
                            </el-date-picker>
                        </div>
                        <el-button type="primary" size="mini" @click="resetQueryTime">
                            重置时间
                        </el-button>
                    </div>
                </div>
                <div class="class_cont">
                    <div class="confirm">
                        <div v-if="available.length<=8" class="class-select-group">
                            <label class="class-select-item" v-for="(item, index) in available" :key="index">
                                <div>
                                    <span>{{ item.startTime.split(' ')[0] }}</span>
                                    <el-checkbox :value="formData.classIds.indexOf(item.id)!==-1"
                                                 label=" "
                                                 @change="val=>handleCheckChange(val,item)">
                                    </el-checkbox>
                                </div>
                                <p>{{ getWeekDayDisplay(dayjs(item.startTime).day()) }} {{
                                        item.startTime.split(' ')[1]
                                    }}</p>
                                <p>{{ item.consumeCount }}课时</p>
                            </label>
                        </div>
                        <div v-else class="class-select-group" v-for="(group, index) in availableClassList"
                             :key="index">
                            <div class="label">
                                <div>
                                    <span>{{ group.weekStartDate.format('YYYY-MM-DD') }}</span>
                                    <span>~</span>
                                </div>
                                <div>{{ group.weekEndDate.format('YYYY-MM-DD') }}</div>
                            </div>
                            <div class="class-select-list-wrapper">
                                <label class="class-select-item" v-for="(item, index) in group.classList" :key="index">
                                    <div>
                                        <span>{{ item.startTime.split(' ')[0] }}</span>
                                        <el-checkbox :value="formData.classIds.indexOf(item.id)!==-1"
                                                     label=" "
                                                     @change="val=>handleCheckChange(val,item)">
                                        </el-checkbox>
                                    </div>
                                    <p>{{ getWeekDayDisplay(dayjs(item.startTime).day()) }} {{
                                            item.startTime.split(' ')[1]
                                        }}</p>
                                    <p>{{ item.consumeCount }}课时</p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <template v-if="unavailable.length">
                        <div class="line">
                            <p>不可选课程</p>
                        </div>
                        <div class="cancel">
                            <div v-for="(item, index) in unavailable" :key="index">
                                <p>{{ item.startTime.split(' ')[0] }}</p>
                                <p>{{ getWeekDayDisplay(dayjs(item.startTime).day()) }} {{
                                        item.startTime.split(' ')[1]
                                    }}</p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="bottom">
                <template v-if="formData.consumeClassType!=='Ordinary'">
                    <span>总可用特殊课时: <strong>{{ classInfo.totalSpecialClass }}</strong></span>
                    <span>剩余可用特殊课时: <strong>{{ classInfo.remainSpecialClass }}</strong></span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </template>
                <template v-else-if="formData.packageType===1">
                    <span>总可用普通课时: <strong>{{ classInfo.totalOrdinaryClass }}</strong></span>
                    <span>剩余可用普通课时: <strong>{{ classInfo.remainOrdinaryClass }}</strong></span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </template>
                <span>已选课节: <strong>{{ formData.classIds.length }}</strong></span>
                <div style="margin-right: 20px;">
                    <span>课节耗课：</span>
                    <span></span>
                    <strong>{{ formData.consumeCount }}</strong>
                    <span>课时</span>
                    <span class="primary-text-color" style="cursor: pointer;"
                          @click="updateConsumeCountModalVisible=true">
                    [修改]
                </span>
                </div>
                <span>消耗课时: <strong>{{
                        formData.consumeCount ? formData.consumeCount * formData.classIds.length : 0
                    }}</strong></span>
                <span>排队课程: <strong>{{ formData.autoLineUp ? lineupCount : 0 }}</strong></span>
            </div>
        </div>
        <div slot="footer">
            <el-button size="mini" plain @click="$emit('update:visible',false)">取消</el-button>
            <el-button v-if="canConfirm"
                       size="mini"
                       type="primary"
                       :loading="confirmLoading"
                       @click="handleConfirm">
                确认排课
            </el-button>
            <el-tooltip v-else
                        :content="formData.classIds.length?'合同可用耗课不足,无法提交！':'无可排课程！'"
                        placement="top">
                <el-button size="mini"
                           type="primary"
                           :loading="confirmLoading"
                           disabled
                           @click="handleConfirm">
                    确认排课
                </el-button>
            </el-tooltip>
        </div>
        <el-dialog custom-modal
                   title="修改课节耗课"
                   :visible.sync="updateConsumeCountModalVisible"
                   width="300px"
                   append-to-body>
            <el-form label-position="top" size="mini">
                <el-form-item label="修改此数值将会为该会员使用填写的课节耗课进行排课">
                    <el-input-number v-model="updateConsumeCountFormData.consumeCount"
                                     :min="0"
                                     controls-position="right"
                                     style="width: 100%;"
                                     left
                                     placeholder="不填写该项则使用默认耗课数进行排课">
                    </el-input-number>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button @click="updateConsumeCountModalVisible=false">取消</el-button>
                <el-button type="primary"
                           @click="formData.consumeCount=updateConsumeCountFormData.consumeCount;updateConsumeCountModalVisible=false;updateConsumeCountFormData.consumeCount=undefined">
                    确认
                </el-button>
            </template>
        </el-dialog>
    </el-dialog>
</template>
<script>
import {computed, defineComponent, ref, toRefs, watch} from 'vue';
import {getClassDetailApi, getPrePlanClassInfoApi, planClassApi} from '../../../api/class/classApi';
import dayjs, {Dayjs} from 'dayjs';
import {copyPropertyTo} from '../../../utils/ObjectUtils';
import ChangeContractButton from "@/components/Class/detail/ChangeContractButton.vue";
import {getWeekDayDisplay} from "@/utils/dateUtils";

export default defineComponent({
    components: {ChangeContractButton},
    methods: {getWeekDayDisplay, dayjs},
    props: {
        visible: Boolean,
        classId: Number,
        studentId: Number,
        defaultContractId: Number,
        consumeCount: Number
    },
    emits: ['update:visible', 'ok'],
    setup(props, {emit}) {

        const loading = ref(false);

        const classInfo = ref({});

        const queryFormData = ref({
            startDate: undefined,
            endDate: undefined
        });

        const getDefaultFormData = () => {
            return {
                auto: true,
                autoLineUp: true,
                consumeClassType: 'Ordinary',
                consumeCount: props.consumeCount,
                classIds: []
            };
        };

        const formData = ref(getDefaultFormData());

        const available = ref([]);

        const unavailable = ref([]);

        const availableClassList = ref([]);

        const unavailableClassList = ref([]);

        const confirmLoading = ref(false);

        const updateConsumeCountModalVisible = ref(false);

        const updateConsumeCountFormData = ref({consumeCount: undefined});

        const contractId = ref();

        let classMap = {};

        let dateChangeQueryData = true;

        let clearSelectClass = true;

        const computeAvailableClassList = () => {
            if (Array.isArray(classInfo.value.classList) && classInfo.value.classList.length) {
                function group(classList) {
                    const resultObject = {};
                    const result = [];
                    for (const classItem of classList) {
                        const weekStart = dayjs(classItem.startTime).startOf('week');
                        const weekStartDisplay = weekStart.format('YYYY-MM-DD');
                        let arr = resultObject[weekStartDisplay];
                        if (!arr) {
                            resultObject[weekStartDisplay] = arr = [];
                            result.push({
                                date: weekStartDisplay,
                                weekStartDate: weekStart,
                                weekEndDate: weekStart.add(7, 'day'),
                                classList: arr
                            });
                        }
                        arr.push(classItem);
                    }
                    result.sort((a, b) => {
                        return a.weekStartDate.toDate().getTime() - b.weekStartDate.toDate().getTime();
                    });
                    return result;
                }

                available.value = classInfo.value.classList.filter(item => !item.planed);
                unavailable.value = classInfo.value.classList.filter(item => item.planed);
                availableClassList.value = group(available.value);
                unavailableClassList.value = group(unavailable.value);
            } else {
                available.value = [];
                unavailable.value = [];
                availableClassList.value = [];
                unavailableClassList.value = [];
            }
        };

        const resetQueryTime = () => {
            queryFormData.value.startDate = classInfo.value.startTime.split(' ')[0];
            queryFormData.value.endDate = classInfo.value.endDate;
        };

        const handleCheckChange = (checked, classItem) => {
            if (checked) {
                formData.value.classIds.push(classItem.id);
            } else {
                formData.value.classIds.splice(formData.value.classIds.indexOf(classItem.id), 1);
                formData.value.auto = false;
                clearSelectClass = false;
            }
        };

        const getPrePlanClassInfo = (clo = true) => {
            loading.value = true;
            const classListOnly = !clo ? clo : Boolean(queryFormData.value.startDate) && Boolean(queryFormData.value.endDate);
            getPrePlanClassInfoApi({
                classId: props.classId,
                contractId: contractId.value ? contractId.value : props.defaultContractId,
                startDate: classListOnly ? queryFormData.value.startDate + ' 00:00:00.000' : undefined,
                endDate: classListOnly ? queryFormData.value.endDate + ' 23:59:59.999' : undefined,
                studentId: props.studentId,
                classListOnly
            }).then(res => {
                if (classListOnly) {
                    copyPropertyTo(classInfo.value, res.data.data, true);
                } else {
                    dateChangeQueryData = false;
                    queryFormData.value.startDate = res.data.data.startTime.split(' ')[0];
                    queryFormData.value.endDate = res.data.data.endDate;
                    classInfo.value = res.data.data;
                    if (res.data.data.classType !== 'Normal') {
                        formData.value.consumeClassType = 'Special';
                    }
                }
                computeAvailableClassList();
                if (Array.isArray(res.data.data.classList) && res.data.data.classList.length) {
                    res.data.data.classList.forEach(item => {
                        classMap[item.id] = item;
                    });
                    if (formData.value.auto) {
                        formData.value.classIds = res.data.data.classList.filter(item => !item.planed).map(item => item.id);
                    }
                } else {
                    classMap = {};
                    formData.value.classIds = [];
                }
                if (!formData.value.consumeCount) {
                    formData.value.consumeCount = res.data.data.consumeCount;
                }
            }).finally(() => loading.value = false);
        };

        const handleContractChange = (val) => {
            contractId.value = val.id;
            //TODO 后续需要改进为不调用接口,因为合同信息都在里面了
            getPrePlanClassInfo(false);
        };

        const handleConfirm = () => {
            confirmLoading.value = true;
            planClassApi({
                ...formData.value,
                startDate: queryFormData.value.startDate + ' 00:00:00.000',
                endDate: queryFormData.value.endDate + ' 23:59:59.999',
                auto: false,
                contractId: props.defaultContractId,
                studentId: props.studentId,
                classId: props.classId,
            }).then(() => {
                emit('update:visible', false);
                emit('ok');
            }).finally(() => confirmLoading.value = false);
        };

        const canConfirm = computed(() => {
            if (formData.value.consumeClassType === 'Ordinary') {
                if (classInfo.value.packageType === 1) {
                    return formData.value.classIds.length && classInfo.value.remainOrdinaryClass + classInfo.value.remainGiveOrdinaryClass >= formData.value.consumeCount * formData.value.classIds.length;
                } else {
                    return formData.value.classIds.length;
                }
            } else if (formData.value.consumeClassType === 'Special') {
                return formData.value.classIds.length && classInfo.value.remainSpecialClass >= formData.value.consumeCount * formData.value.classIds.length;
            } else {
                return formData.value.classIds.length;
            }
        });

        const lineupCount = computed(() => {
            if (formData.value.classIds.length) {
                return formData.value.classIds.filter(classId => classMap[classId].full).length;
            }
            return 0;
        });

        watch(() => props.visible, newVal => {
            if (newVal) {
                getPrePlanClassInfo();
            } else {
                formData.value = getDefaultFormData();
                dateChangeQueryData = false;
                queryFormData.value = {startDate: undefined, endDate: undefined};
            }
        });

        watch(queryFormData, () => {
            if (dateChangeQueryData) {
                getPrePlanClassInfo();
            } else {
                dateChangeQueryData = true;
                contractId.value = undefined;
            }
        }, {deep: true});

        watch(() => formData.value.auto, newVal => {
            if (newVal) {
                if (Array.isArray(classInfo.value.classList) && classInfo.value.classList.length) {
                    formData.value.classIds = classInfo.value.classList.filter(item => !item.planed).map(item => item.id);
                }
            } else {
                if (clearSelectClass) {
                    formData.value.classIds = [];
                } else {
                    clearSelectClass = true;
                }
            }
        }, {immediate: true});

        return {
            ...toRefs(props),
            loading,
            queryFormData,
            formData,
            available,
            unavailable,
            availableClassList,
            unavailableClassList,
            classInfo,
            contractId,
            confirmLoading,
            updateConsumeCountModalVisible,
            updateConsumeCountFormData,
            canConfirm,
            lineupCount,
            resetQueryTime,
            handleCheckChange,
            handleContractChange,
            handleConfirm
        };
    },
});
</script>

<style scoped lang="scss">
p {
    margin: 0;
}

.top {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    height: 50px;

    span {
        font-size: 16px;
        color: #292e33;
    }

    img {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
}

.cont {
    padding: 0 20px;
    box-sizing: border-box;

    .font {
        padding-top: 10px;
        box-sizing: border-box;

        .box {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .title {
            .h4 {
                > span:nth-child(1) {
                    font-size: 18px;
                }

                > span:nth-child(2) {
                    font-size: 14px;
                }
            }

            .kouke {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }

        .info {
            img {
                width: 15px;
                height: 15px;
                margin-right: 3px;
                position: relative;
                top: -1px;
            }

            em {
                font-style: normal;
                color: #29cb97;
            }

            .info_item {
                display: flex;
                align-items: center;

                > * {
                    margin-right: 20px;
                }

                > div {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .tip {
            strong {
                color: #faad14;
                font-size: 14px;
                font-weight: normal;
            }
        }
    }

    .class {
        background: #f5f7fa;
        padding: 10px;
        box-sizing: border-box;

        .top_class {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left, .right {
                display: flex;
                align-items: center;

                > div {
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }
        }

        .class_cont {
            .confirm {
                display: flex;
                align-items: center;
                padding: 10px;
                box-sizing: border-box;
                flex-wrap: wrap;
                gap: 10px;
                font-size: 14px;
                color: #606266;
                overflow: auto;
                max-height: 500px;

                > .class-select-group {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    border-bottom: 1px solid #edf0f2;
                    padding-bottom: 10px;

                    &:has(.label) {
                        flex-wrap: nowrap;
                    }

                    > .label {
                        height: fit-content;
                        margin-right: 10px;
                        display: flex;
                        flex-direction: column;
                        white-space: nowrap;
                    }

                    .class-select-list-wrapper {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;
                    }

                    .class-select-item {
                        background: #fff;
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                        padding: 3px 10px;
                        box-sizing: border-box;
                        border-radius: 5px;
                        cursor: pointer;

                        div {
                            display: flex;

                            span {
                                margin-right: 15px;
                            }
                        }
                    }
                }


            }

            .line {
                position: relative;
                border-bottom: 1px solid #e5e5e5;

                p {
                    font-size: 12px;
                    color: #292e33;
                    position: absolute;
                    bottom: -13px;
                    background: #f5f7fa;
                    width: 90px;
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: center;
                }
            }

            .cancel {
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;

                div {
                    background: #edf0f2;
                    width: 125px;
                    border-radius: 4px;
                    padding: 10px;
                    box-sizing: border-box;
                    box-shadow: 0 0 5px rgba(0, 0, 0, .1);

                    p {
                        font-size: 12px;
                        line-height: 16px;
                        color: #292e33;
                    }
                }
            }
        }
    }

    .bottom {
        padding: 10px 0 0 50px;
        box-sizing: border-box;
        display: flex;

        > span {
            margin-right: 20px;
        }
    }
}

.el-radio {
    margin: 0;
}
</style>
<style lang="scss">
.PaikeComp-dialog {
    //height: 520px;

    .el-dialog__header {
        height: 40px;
        border-bottom: 1px solid #ccc;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 40px;
        color: #292e33;
        display: none;

        i::before {
            position: relative;
            top: -10px;
        }

        span {
            font-size: 14px;
        }
    }

    .el-dialog__body {
        padding: 0 0 30px;
        box-sizing: border-box;
    }

    .el-dialog__footer {
        padding: 10px 20px;
        box-sizing: border-box;
        overflow: hidden;
        border-top: 1px solid #ccc;
        // display: none;
    }
}
</style>
