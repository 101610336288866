<template>
    <div>
        <el-dialog
            class="asd"
            width="1000px"
            :visible="visible"
            @update:visible="val=>$emit('update:visible',val)"
            title="总课程编辑记录"
            append-to-body>
            <div class="menu">
                <div>
                    <span>操作人:</span>
                    <tenant-user-select v-model="queryFormData.tenantUserIds" multiple></tenant-user-select>
                </div>
                <div>
                    <span>查询时段:</span>
                    <el-date-picker
                        v-model="queryFormData.dateRange"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        size="mini"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <img src="../../../assets/img/sort-1.png" alt="">
            </div>
            <div class="content">
                <div style="text-align: center;" v-if="!records.length">暂无数据</div>
                <div class="list" v-for="(item,index) in records" :key="index">
                    <div class="list_header">
                        <span>编辑课程</span>
                        <div>
                            <p>操作人: <em>{{ item.createUserName }}</em></p>
                            <p>操作时间: {{ item.createDate }}</p>
                        </div>
                    </div>
                    <div class="list_content">
                        <el-table v-loading="loading"
                                  :data="records"
                                  style="width: 100%">
                            <el-table-column
                                prop="className"
                                label="课程名称"
                                width="width">
                            </el-table-column>
                            <el-table-column
                                prop="class"
                                label="时间"
                                width="width">
                                <template v-slot="scope">
                                    <div>

                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="classNum"
                                label="课节总数"
                                width="width">
                                <template v-slot="scope">
                                    <div>
                                        <span>节</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <el-pagination
                    style="text-align: right;margin-top:20px;"
                    background
                    layout="total,prev, pager, next, sizes ,jumper"
                    v-bind="page"
                    :page-sizes="[10,20,50,100]"
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import {defineComponent, ref, watch} from "vue";
import {getDefaultPage} from "@/utils";
import TenantUserSelect from "@/components/Form/TenantUserSelect.vue";
import {cloneObject} from "@/utils/ObjectUtils";
import {getClassFatherOperationRecordListApi} from "@/api/class/classApi";

const defaultQueryFormData = {
    dateRange: [],
    tenantUserIds: [],
};

export default defineComponent({
    components: {TenantUserSelect},
    props: {visible: Boolean, classFatherId: Number},
    emits: ['update:visible'],
    setup(props) {

        const page = ref(getDefaultPage());

        const records = ref([]);

        const queryFormData = ref(cloneObject(defaultQueryFormData));

        const loading = ref(false);

        const refreshPage = () => {
            page.value = getDefaultPage();
            records.value = [];
            getPage();
        };

        const getPage = () => {
            loading.value = true;
            getClassFatherOperationRecordListApi({
                currentPage: page.value.currentPage,
                pageSize: page.value.pageSize,
                timestamp: page.value.timestamp,
                startTime: Array.isArray(queryFormData.value.dateRange) && queryFormData.value.dateRange.length ? queryFormData.value.dateRange[0] + ' 00:00:00.000' : undefined,
                endTime: Array.isArray(queryFormData.value.dateRange) && queryFormData.value.dateRange.length ? queryFormData.value.dateRange[1] + ' 23:59:59.999' : undefined,
                tenantUserIds: queryFormData.value.tenantUserIds,
                classFatherId: props.classFatherId
            }).then(res => {
                records.value = res.data.data.records;
                page.value.pageSize = res.data.data.size;
                page.value.total = res.data.data.total;
            }).finally(() => loading.value = false);
        };

        const sizeChangeHandle = (val) => {
            page.value.pageSize = val;
            page.value.currentPage = 1;
            getPage();
        };

        const currentChangeHandle = (val) => {
            page.value.currentPage = val;
            getPage();
        };

        watch(() => props.visible, newVal => {
            if (newVal) {
                refreshPage();
            }
        });

        return {
            page,
            queryFormData,
            records,
            loading,
            refreshPage,
            sizeChangeHandle,
            currentChangeHandle,
        };

    }
});
</script>

<style lang="scss" scoped>
em {
    font-style: normal;
    color: #29cb97;
}

.menu {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
    box-sizing: border-box;

    > div {
        margin-right: 10px;
    }

    > img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.content {
    max-height: 600px;
    overflow-y: scroll;
    background: #f5f7fa;
    padding: 20px;
    box-sizing: border-box;

    .list + .list {
        margin-top: 20px;
    }

    .list {
        .list_header {
            height: 40px;
            background: #edf0f2;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            box-sizing: border-box;

            > span {
                font-size: 14px;
                color: #292e33;
            }

            > div {
                display: flex;
                align-items: center;

                > p {
                    margin: 0;
                    font-size: 14px;
                    color: #292e33;

                    em {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
</style>
<style scoped>
>>> .el-dialog__body {
    padding: 0 !important;
}
</style>