<template>
    <el-dialog title="修改课程容量"
               :visible="visible"
               @update:visible="val=>$emit('update:visible',val)"
               append-to-body
               width="500px"
               custom-modal>
        <el-form ref="formRef"
                 :rules="formRules"
                 :model="formData"
                 label-position="top"
                 size="mini">
            <el-form-item style="width: 100%;" label="课类" prop="capacity">
                <el-input-number v-model="formData.capacity"
                                 left
                                 style="width: 100%;"
                                 controls-position="right"
                                 :min="1"
                                 :precision="0">
                </el-input-number>
            </el-form-item>
        </el-form>
        <template v-slot:footer>
            <el-button size="mini" @click="$emit('update:visible',false)">取消</el-button>
            <el-button size="mini"
                       type="primary"
                       @click="handleConfirm"
                       :loading="confirmLoading">
                确定
            </el-button>
        </template>
    </el-dialog>
</template>
<script>
import {defineComponent, inject, ref, toRefs, watch} from "vue";
import ClassTypeCascader from "@/components/Form/ClassTypeCascader.vue";
import {updateTotalCapacityApi} from "@/api/class/classApi";

export default defineComponent({
    components: {ClassTypeCascader},
    props: {
        visible: Boolean,
        data: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    emits: ['update:visible', "ok"],
    setup(props, {emit}) {

        const formRef = ref();

        const formData = ref({
            capacity: undefined,
        });

        const formRules = ref({
            capacity: [{required: true, message: '课程容量是必填的'}],
        });

        const confirmLoading = ref(false);

        const handleConfirm = () => {
            confirmLoading.value = true;
            formRef.value.validate().then(() => updateTotalCapacityApi({
                classFatherId: props.data.id,
                capacity: formData.value.capacity
            })).then(() => {
                emit('update:visible', false);
                emit('ok');
                reloadClass();
            }).finally(() => confirmLoading.value = false);
        };

        watch(() => props.visible, newVal => {
            if (newVal) {
                formData.value = {
                    capacity: props.data.classCapacity,
                };
                setTimeout(() => {
                    formRef.value.clearValidate();
                });
            }
        });

        const reloadClass = inject('reloadClass');

        return {
            ...toRefs(props),
            formRef,
            formData,
            formRules,
            confirmLoading,
            handleConfirm
        };
    }

});
</script>
<style scoped>
.form-multiple-item-line {
    gap: 20px;
    flex: 1 1;
}
</style>