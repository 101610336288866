<template>
    <el-dialog list-detail-modal
               width="1200px"
               :show-close="false"
               center
               append-to-body
               custom-modal
               top="10vh"
               :visible="visible"
               lock-scroll
               @update:visible="val=>$emit('update:visible',val)">
        <div class="dialog-main" v-infinite="loadMore">
            <div style="height: 100%;width: 100%;" v-loading="true" v-if="state==='loading'"></div>
            <div style="height: 100%;width: 100%;" v-else-if="state==='error'" @click="refreshData">
                加载失败,点击重试
            </div>
            <div class="list-info" v-else-if="state==='success'">
                <div class="list-info-left">
                    <div style="font-size: 19px;" class="primary-text-color">
                        <template>
                            <div>
                                ({{
                                    Array.isArray(list.contactsList) && list.contactsList.length && list.contactsList[0].phoneArea ? '+' + list.contactsList[0].phoneArea : ''
                                }})
                                {{
                                    Array.isArray(list.contactsList) && list.contactsList.length ? list.contactsList[0].phone : ''
                                }}
                            </div>
                            <div v-if="Array.isArray(list.contactsList) && list.contactsList.length">
                                {{ list.contactsList[0].name ? list.contactsList[0].name : '未知' }}
                                （{{ $store.getters['common/relationMap'][list.contactsList[0].affiliation] }}）
                            </div>
                        </template>
                        <el-button style="padding: 0;margin: 0;font-size: inherit;"
                                   type="text"
                                   icon="el-icon-edit-outline"
                                   @click="updateContactModalVisible=true"
                                   v-if="defaultCanOperation">
                        </el-button>
                    </div>
                    <div>
                        <span>微信昵称：</span>
                        <span>
                            {{
                                Array.isArray(list.contactsList) && list.contactsList.length && list.contactsList[0].wechatName ? list.contactsList[0].wechatName : '暂无'
                            }}
                        </span>
                        <el-button style="padding: 0;margin: 0;"
                                   type="text"
                                   @click="infoDialogVisible=true">
                            [查看更多信息]
                        </el-button>
                    </div>
                    <div>
                        <span>录入来源：</span>
                        <div style="display:flex;gap: 8px;" v-if="list.recordSource==='OtherCenter'">
                            <span style="color: #4fc3f7;">【{{ list.transferFromStoreName }}】</span>
                            <el-button style="padding: 0;margin: 0;" type="text"
                                       @click="transferFromListDetailVisibleModal=true">
                                [转入资料]
                            </el-button>
                            <!--                        <el-button style="padding: 0;margin: 0;" type="text">[转入跟进记录]</el-button>-->
                        </div>
                        <el-button style="padding: 0;margin: 0;color: #4fc3f7;" type="text" v-else>
                            {{ $store.getters['common/listRecordSourceMap'][list.recordSource] }}
                        </el-button>
                    </div>
                    <div>
                        <span>来源渠道：</span>
                        <span v-if="Array.isArray(list.sources)&&list.sources.length">
                            {{ $store.getters['common/sourceTypeMap'][list.sourceType] }}/
                            {{ list.sources.map(s => s.name).join('/') }}
                        </span>
                        <span v-else>{{ $store.getters['common/sourceTypeMap'][list.sourceType] }}</span>
                        <el-button style="padding: 0;margin: 0;" type="text" @click="updateSourceModalVisible=true">[修改]
                        </el-button>
                    </div>
                    <div>
                        <span>转介绍：</span>
                        <div>
                            <span class="referrals" @click="referralsDialogVisible=true">0</span>
                            <span>人</span>
                        </div>
                    </div>
                    <div>
                        <span>首次采单信息：</span>
                        <div style="margin-left: 20px;display: flex;flex-direction: column;gap: 10px;">
                            <span>{{ list.purchaseOrderNotes }}</span>
                            <span style="color: #b4bfcc;">({{ list.purchaseOrderTime.split(' ')[0] }})</span>
                        </div>
                    </div>
                    <div v-if="list.lastPurchaseOrderDate">
                        <span>最后一次采单信息：</span>
                        <div style="margin-left: 20px;display: flex;flex-direction: column;gap: 10px;">
                            <span>{{ list.lastPurchaseOrderRemark }}</span>
                            <span style="color: #b4bfcc;">
                                ({{ list.lastPurchaseOrderDate.split(' ')[0] }})
                            </span>
                        </div>
                    </div>
                </div>
                <div class="list-info-middle">
                    <div class="student-card-wrapper">
                        <div class="student-card" v-for="student in list.students">
                            <div style="display: flex;justify-content: space-between;"
                                 :key="student.id">
                                <div style="display: flex;flex-direction: column;gap: 8px;">
                                    <div style="display:flex;gap: 5px;align-items: center;">
                                        <span>{{ student.name }}</span>
                                        <gender-view :gender="student.sex"></gender-view>
                                        <el-button style="padding: 0;margin: 0;font-size: 16px;"
                                                   type="text"
                                                   icon="el-icon-edit-outline"
                                                   v-if="defaultCanOperation"
                                                   @click="updateVipDialogVisible=true;updateStudentFormData=student;">
                                        </el-button>
                                    </div>
                                    <div class="student-card-age">
                                        {{ getBirthdayDisplay(student.birthdayTime) }}
                                    </div>
                                </div>
                                <el-button style="height: fit-content;"
                                           size="mini"
                                           v-if="list.followUpPhase!=='Contracted'&&list.consultantId"
                                           type="primary"
                                           @click="tryReserveClass(student)">
                                    约课
                                </el-button>
                            </div>
                            <div class="student-card-others">
                                <div>
                                    <span>学校：</span>
                                    <span class="none">{{ student.school ? student.school : '暂无' }}</span>
                                </div>
                                <div>
                                    <span>年级：</span>
                                    <span class="none">{{ student.grade ? student.grade : '暂无' }}</span>
                                </div>
                                <div style="display: flex;">
                                    <span>兴趣课类：</span>
                                    <span>
                                    <update-student-like-subject-modal v-model="student.likeSubjectIds"
                                                                       :on-ok="val=>handleUpdateStudentLikeSubject(val,student.id)"
                                                                       @change="classTypes=>{
                                                                           student.likeSubjectNames=classTypes.map(classType=>classType.name)
                                                                           if(activeBar.code==='4'){
                                                                               refreshChildren();
                                                                           }
                                                                       }"
                                                                       :student-id="student.id">
                                         <el-button style="padding: 0;margin: 0;font-size: 14px;" type="text"
                                                    icon="el-icon-edit-outline"></el-button>
                                    </update-student-like-subject-modal>
                                </span>
                                </div>
                                <div class="interest-class-list"
                                     v-if="Array.isArray(student.likeSubjectIds)&&student.likeSubjectIds.length">
                                    <div class="interest-class-item"
                                         v-for="(likeSubjectName,index) in student.likeSubjectNames"
                                         :key="index">{{ likeSubjectName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="add-student-button"
                         v-if="defaultCanOperation"
                         @click="addVipDialogVisible=true">
                        <i class="el-icon-plus"></i>
                    </div>
                </div>
                <div class="list-info-right">
                    <div v-if="list.abandon===1">
                        <el-button size="mini" type="primary" class="btn" @click="handleAccept">认领</el-button>
                    </div>
                    <div v-else-if="list.tableState==='NotValid'">
                        <el-button type="primary" class="btn" @click="activate">激活</el-button>
                        <el-button type="primary" class="btn" @click="transferOutModalVisible=true">
                            转中心
                        </el-button>
                        <el-button type="primary"
                                   class="btn"
                                   v-if="list.consultantId"
                                   @click="abandonModalVisible=true">
                            放弃
                        </el-button>
                    </div>
                    <div v-else>
                        <el-button type="primary"
                                   class="btn"
                                   v-if="list.consultantId"
                                   @click="toNewSign">
                            新签
                        </el-button>
                        <template v-if="defaultCanOperation">
                            <el-button type="primary"
                                       class="btn"
                                       v-if="list.consultantId"
                                       @click="handleInvalid">
                                无效
                            </el-button>
                            <el-button type="primary"
                                       class="btn"
                                       v-if="list.consultantId"
                                       @click="reserveVisitModal=true">
                                新签到访
                            </el-button>
                            <el-button type="primary"
                                       class="btn"
                                       @click="transferOutModalVisible=true">
                                转中心
                            </el-button>
                            <el-button type="primary"
                                       class="btn"
                                       v-if="list.consultantId"
                                       @click="abandonModalVisible=true">
                                放弃
                            </el-button>
                        </template>

                    </div>
                    <table class="list-status">
                        <tr>
                            <th style="display: flex;justify-content: space-between;width: 100%;">
                                <span>顾</span>
                                <span>问：</span>
                            </th>
                            <th>
                                <span>{{ list.consultantId ? list.consultantName : '无' }}</span>
                                <template v-if="list.abandon===0">
                                    <template v-if="defaultCanOperation||list.tableState==='NotValid'">
                                        <consultant-button
                                            @ok="refreshData"
                                            :list-id="list.id"
                                            :default-value="list.consultantId">
                                            <template v-slot="{loading}">
                                                <el-button style="padding: 0;margin-left: 5px;"
                                                           type="text"
                                                           :loading="loading">
                                                    {{ list.consultantId ? '[更换]' : '[分配]' }}
                                                </el-button>
                                            </template>
                                        </consultant-button>
                                        <el-button style="padding: 0;margin-left: 5px;color: #faad14;"
                                                   type="text"
                                                   v-if="list.consultantId"
                                                   @click="recallDialogVisible=true">
                                            [回收]
                                        </el-button>
                                    </template>
                                </template>
                            </th>
                        </tr>
                        <tr>
                            <th style="display: flex;justify-content: space-between;width: 100%;">
                                <span>市</span>
                                <span>场：</span></th>
                            <th>
                                <span>{{ list.marketId ? list.marketName : '无' }}</span>
                                <template v-if="list.abandon===0">
                                    <market-button
                                        @ok="refreshData"
                                        :list-id="list.id"
                                        v-if="defaultCanOperation||list.tableState==='NotValid'"
                                        :default-value="list.marketId">
                                        <template v-slot="{loading}">
                                            <el-button style="padding: 0;margin-left: 5px;" :loading="loading"
                                                       type="text">
                                                {{ list.marketId ? '[更换]' : '[分配]' }}
                                            </el-button>
                                        </template>
                                    </market-button>
                                </template>
                            </th>
                        </tr>
                        <tr>
                            <th>客户评级：</th>
                            <th>
                                <div style="display: flex;align-items: center;">
                                    <div style="width: 25px;" v-for="i in 5" :key="i" @click="rate=i">
                                        <i v-if="computedRate>=i"
                                           style="color: #fab222;font-size: 25px;cursor: pointer;"
                                           class="el-icon-star-on"></i>
                                        <i v-else style="color: #fab222;font-size: 20px;cursor: pointer;"
                                           class="el-icon-star-off"></i>
                                    </div>
                                    <template v-if="enableUpdateRate">
                                        <el-button style="margin-left: 5px;" size="mini" @click="updateRating"
                                                   :loading="updateRatingLoading">保存
                                        </el-button>
                                        <el-button size="mini" type="danger" plain @click="enableUpdateRate=false"
                                                   :disabled="updateRatingLoading">取消
                                        </el-button>
                                    </template>
                                    <el-button style="padding: 7px 15px;" type="text" v-else
                                               @click="enableUpdateRate=true">
                                        [编辑]
                                    </el-button>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th>名单状态：</th>
                            <th>
                            <span class="primary-text-color" v-if="list.tableState==='AwaitADecision'">
                                待定{{ list.followUpResultReason ? `【${list.followUpResultReasonName}】` : '' }}
                            </span>
                                <span class="primary-text-color" v-else-if="list.tableState==='Efficiently'">
                                有效{{ list.followUpResultReason ? `【${list.followUpResultReasonName}】` : '' }}
                            </span>
                                <span style="color: red;" v-else-if="list.tableState==='NotValid'">
                                无效{{ list.followUpResultReason ? `【${list.followUpResultReasonName}】` : '' }}
                            </span>
                            </th>
                        </tr>
                        <tr>
                            <th>分配状态：</th>
                            <th>
                            <span style="color: red;"
                                  v-if="list.allocationState===1||list.allocationState===5">
                                {{ $store.getters['common/allocationStateMap'][list.allocationState] }}
                            </span>
                                <span class="primary-text-color" v-else>
                                {{ $store.getters['common/allocationStateMap'][list.allocationState] }}
                            </span>
                            </th>
                        </tr>
                        <tr>
                            <th>跟进状态：</th>
                            <th>
                            <span class="primary-text-color">
                                {{ $store.getters['common/followStateMap'][list.followState] }}
                            </span>
                            </th>
                        </tr>
                        <tr v-if="list.invitationStatus==='Invited'">
                            <th>约访时间：</th>
                            <th>
                                <span style="color: #818c99;">{{ list.lastReserveDate }}</span>
                                <span class="primary-text-color"
                                      v-if="list.lastReserveVisitStatus==='Visited'">已签到</span>
                                <span style="color: red;"
                                      v-else-if="list.lastReserveVisitStatus==='NotVisited'">未签到</span>
                                <span style="color: red;"
                                      v-else-if="list.lastReserveVisitStatus==='Canceled'">已取消</span>
                                <el-button style="padding: 0;" type="text" @click="visitInfoDialogVisible=true">
                                    [查看详情]
                                </el-button>
                            </th>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="line-height: normal;" v-if="state==='success'">
                <div class="nav-bar">
                    <ul>
                        <li v-for="selectTab in selectTabs"
                            :key="selectTab.name"
                            @click="activeBar=selectTab"
                            :class="['nav-bar-item',{active:activeBar&&activeBar.code===selectTab.code}]">
                            {{ selectTab.name }}
                        </li>
                    </ul>
                </div>
                <div class="other-content" v-if="activeBar">
                    <component :is="activeBar.component"
                               ref="activeRef"
                               @updated="refreshData"
                               v-bind="activeBar.getBindValue()">
                    </component>
                </div>
            </div>
        </div>
        <referral-list :visible.sync="referralsDialogVisible"></referral-list>
        <list-more-info-modal :visible.sync="infoDialogVisible"
                              :data="list">
        </list-more-info-modal>
        <add-student-modal :visible.sync="addVipDialogVisible"
                           :list-id="list.id"
                           @ok="refreshData">
        </add-student-modal>
        <update-student-modal :visible.sync="updateVipDialogVisible"
                              :form-data="updateStudentFormData"
                              @ok="refreshData"
                              @delete="refreshData"
                              allow-delete>
        </update-student-modal>
        <recall-list-modal :visible.sync="recallDialogVisible"
                           @ok="refreshData">
        </recall-list-modal>
        <visit-info-modal :list-id="list.id"
                          :visible.sync="visitInfoDialogVisible"
                          @updated="refreshData">
        </visit-info-modal>
        <update-source-modal :visible.sync="updateSourceModalVisible"
                             :data="list"
                             @ok="refreshData">
        </update-source-modal>
        <update-list-modal :visible.sync="updateContactModalVisible"
                           :list="list"
                           @updated="refreshData"
                           @delete="$emit('update:visible',false);$emit('updated',false)">
        </update-list-modal>
        <abandon-modal :visible.sync="abandonModalVisible"
                       :list-id="list.id"
                       @ok="refreshData">
        </abandon-modal>
        <transfer-out-modal :visible.sync="transferOutModalVisible"
                            :list-id="list.id"
                            :store-id="list.storeId"
                            @ok="refreshData">
        </transfer-out-modal>
        <reserve-visit-modal :visible.sync="reserveVisitModal"
                             :list-id="list.id"
                             @ok="refreshData">
        </reserve-visit-modal>
        <appoint-class v-bind="appointClassData"
                       :visible.sync="appointClassModalVisible"
                       @ok="refreshData">
        </appoint-class>
        <transfer-list-detail-modal :visible.sync="transferFromListDetailVisibleModal"
                                    :list-id="list.transferFrom">
        </transfer-list-detail-modal>
    </el-dialog>
</template>
<script>
import ContractCardPage from '../Student/detail/ContractCardPage.vue';
import ConsultantButton from './form/ConsultantButton.vue';
import {cloneObject} from '../../utils/ObjectUtils';
import {
    acceptApi,
    activateListApi,
    getDetailApi,
    updateLikeSubjectApi,
    updateRatingApi
} from '../../api/student/listApi';
import AddStudentModal from './detail/AddStudentModal.vue';
import UpdateStudentModal from './detail/UpdateStudentModal.vue';
import MarketButton from './form/MarketButton.vue';
import TransferOutModal from './detail/TransferOutModal.vue';
import ReserveVisitModal from './detail/ReserveVisitModal.vue';
import VisitInfoModal from './detail/VisitInfoModal.vue';
import ListFollowUpRecord from './detail/ListFollowUpRecord.vue';
import AbandonModal from './detail/AbandonModal.vue';
import UpdateStudentLikeSubjectModal from '../Form/UpdateStudentLikeSubjectModal.vue';
import SourceChannelSelect from '../Form/SourceChannelSelect.vue';
import AppointClass from '../Student/AppointClass.vue';
import ListClassPage from './detail/ListClassPage.vue';
import ListOperationRecord from './detail/ListOperationRecord.vue';
import GenderView from '../Common/GenderView.vue';
import ListMoreInfoModal from '@/components/List/detail/ListMoreInfoModal.vue';
import {getBirthdayDisplay} from '../../utils/studentUtils';
import UpdateListModal from '@/components/List/detail/UpdateListModal.vue';
import ReferralList from '@/components/List/detail/ReferralList.vue';
import RecallListModal from '@/components/List/detail/RecallListModal.vue';
import UpdateSourceModal from '@/components/List/detail/UpdateSourceModal.vue';
import {computed, defineComponent, getCurrentInstance, toRefs, watch, ref, provide, nextTick} from 'vue';
import {initListDetailData} from '@/components/List/ListUtils';
import TransferListDetailModal from '@/components/List/TransferListDetailModal.vue';

export default defineComponent({
    components: {
        TransferListDetailModal,
        UpdateSourceModal,
        RecallListModal,
        ReferralList,
        UpdateListModal,
        ListMoreInfoModal,
        GenderView,
        ListClassPage,
        AppointClass,
        SourceChannelSelect,
        AbandonModal,
        ListFollowUpRecord,
        VisitInfoModal,
        ReserveVisitModal,
        TransferOutModal,
        MarketButton,
        UpdateStudentModal,
        AddStudentModal,
        ConsultantButton,
        UpdateStudentLikeSubjectModal,
        ContractCardPage
    },
    emits: ['update:visible', 'updated', 'close'],
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            }
        },
        data: {
            type: Object,
            default() {
                return {};
            }
        },
        listId: Number
    },
    setup(props, {emit}) {

        const currentInstance = getCurrentInstance();

        const state = ref();

        const rate = ref(0);

        const referralsDialogVisible = ref(false);

        const infoDialogVisible = ref(false);

        const addVipDialogVisible = ref(false);

        const updateVipDialogVisible = ref(false);

        const recallDialogVisible = ref(false);

        const visitInfoDialogVisible = ref(false);

        const updateSourceModalVisible = ref(false);

        const updateContactModalVisible = ref(false);

        const abandonModalVisible = ref(false);

        const transferOutModalVisible = ref(false);

        const reserveVisitModal = ref(false);

        const appointClassModalVisible = ref(false);

        const transferFromListDetailVisibleModal = ref(false);

        const transferFromListFollowUpdateRecordVisibleModal = ref(false);

        const appointClassData = ref({});

        const updateStudentFormData = ref({});

        const activeRef = ref();

        const selectTabs = [
            {
                code: '1',
                name: '跟进记录',
                component: ListFollowUpRecord,
                nextPage: () => {
                    activeRef.value.nextPage();
                },
                getBindValue: () => {
                    return {
                        listId: list.value.id,
                        listStatus: list.value.tableState,
                        allocationState: list.value.allocationState,
                        formListStatus: listStatus.value,
                        followUpPhase: list.value.followUpPhase
                    };
                }
            },
            {
                code: '2',
                name: '合同信息',
                component: ContractCardPage,
                nextPage: () => {
                    activeRef.value.nextPage();
                },
                getBindValue: () => {
                    return {listId: list.value.id};
                }
            },
            {
                code: '3',
                name: '课程信息',
                component: ListClassPage,
                nextPage: () => {
                    activeRef.value.nextPage();
                },
                getBindValue: () => {
                    return {
                        listId: list.value.id,
                        students: list.value.students
                    };
                }
            },
            {
                code: '4',
                name: '操作记录',
                component: ListOperationRecord,
                nextPage: () => {
                    activeRef.value.nextPage();
                },
                getBindValue: () => {
                    return {listId: list.value.id};
                }
            },
        ];

        const activeBar = ref();

        const enableUpdateRate = ref(false);

        const updateRatingLoading = ref(false);

        const list = ref({});

        const updateObject = ref({});

        const updated = ref(0);

        const listStatus = ref();

        const toNewSign = () => {
            emit('update:visible', false);
            emit('close');
            setTimeout(() => currentInstance.proxy.$router.push(`/createContract?listId=${list.value.id}&paperType=1`));
        };

        const handleInvalid = () => {
            activeRef.value.addListFollowUpRecordModalVisible = true;
            listStatus.value = 'NotValid';
        };

        const handleUpdateStudentLikeSubject = (likeSubjectIds, studentId) => {
            return updateLikeSubjectApi({studentId, likeSubjectIds});
        };

        const activate = () => {
            currentInstance.proxy.$confirm('是否要把无效名单激活为待定名单？', {
                type: 'warning',
                showClose: false,
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        activateListApi(list.value.id).then(() => {
                            refreshData();
                            done();
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                    }

                }
            });
        };

        const updateRating = () => {
            if (rate.value === list.value.customerRating) {
                enableUpdateRate.value = false;
            } else {
                updateRatingLoading.value = true;
                updateRatingApi({listId: list.value.id, listRating: rate.value}).then(() => {
                    enableUpdateRate.value = false;
                    refreshData();
                }).finally(() => updateRatingLoading.value = false);
            }
        };

        const tryReserveClass = (student) => {
            if (student.birthdayTime) {
                appointClassModalVisible.value = true;
                appointClassData.value = {listId: list.value.id};
            } else {
                currentInstance.proxy.$notify({
                    type: 'warning',
                    title: '提示',
                    message: '请先补充生日再选课'
                });
            }
        };

        const handleAccept = () => {
            currentInstance.proxy.$confirm('确定要认领名单吗？', {
                type: 'warning',
                showClose: false,
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        acceptApi({listId: list.value.id}).then(() => {
                            done();
                            refreshData();
                        }).finally(() => instance.confirmButtonLoading = false);
                    } else {
                        done();
                    }
                }
            });
        };

        const initData = (data) => {
            const listData = initListDetailData(data);
            listStatus.value = listData.tableState;
            rate.value = listData.customerRating;
            list.value = listData;
            updateObject.value = cloneObject(listData);
        };

        const refreshChildren = () => {
            const currentActiveBar = activeBar.value ? activeBar.value : selectTabs[0];
            activeBar.value = undefined;
            setTimeout(() => activeBar.value = currentActiveBar);
        };

        const refreshModal = () => {
            state.value = 'loading';
            getDetailApi(list.value.id)
                .then(res => {
                    initData(res.data.data);
                    state.value = 'success';
                }).catch(() => state.value = 'error')
                .finally(() => state.value = 'success');
            refreshChildren();
        };

        const refreshData = () => {
            updated.value++;
            refreshModal();
        };

        const loadMore = () => {
            activeBar.value && activeBar.value.nextPage();
        };

        const openAddFollowUpRecordModal = () => {
            setTimeout(() => {
                setTimeout(() => {
                    if (!activeBar.value || activeBar.value.code !== '1') {
                        activeBar.value = selectTabs[0];
                    }

                    (function run() {
                        setTimeout(() => {
                            if (!activeRef.value) {
                                run();
                            } else {
                                activeRef.value.addListFollowUpRecordModalVisible = true;
                            }
                        }, 50);
                    })();

                });
            });
        };

        const computedRate = computed(() => {
            return enableUpdateRate.value ? rate.value : list.value.customerRating;
        });

        provide('getData', () => list.value);

        const defaultCanOperation = computed(() => {
            return list.value.abandon === 0 && list.value.followUpPhase !== 'Contracted' && list.value.tableState !== 'NotValid';
        });

        watch(enableUpdateRate, (newVal) => {
            if (newVal) {
                updateObject.value.rate = list.value.customerRating;
            }
        });

        watch(() => props.visible, newVal => {
            if (newVal) {
                if (props.listId) {
                    list.value = {id: props.listId};
                    refreshModal();
                } else {
                    state.value = 'success';
                    initData(props.data);
                    refreshChildren();
                }
            } else {
                if (updated.value) {
                    emit('updated');
                    updated.value = 0;
                }
                activeBar.value = undefined;
            }
        }, {immediate: true});

        return {
            ...toRefs(props),
            state,
            rate,
            referralsDialogVisible,
            infoDialogVisible,
            addVipDialogVisible,
            updateVipDialogVisible,
            recallDialogVisible,
            visitInfoDialogVisible,
            updateSourceModalVisible,
            updateContactModalVisible,
            abandonModalVisible,
            transferOutModalVisible,
            reserveVisitModal,
            appointClassModalVisible,
            transferFromListDetailVisibleModal,
            transferFromListFollowUpdateRecordVisibleModal,
            appointClassData,
            updateStudentFormData,
            activeRef,
            selectTabs,
            activeBar,
            enableUpdateRate,
            updateRatingLoading,
            list,
            updateObject,
            listStatus,
            toNewSign,
            handleInvalid,
            handleUpdateStudentLikeSubject,
            activate,
            updateRating,
            tryReserveClass,
            handleAccept,
            refreshChildren,
            refreshModal,
            refreshData,
            loadMore,
            openAddFollowUpRecordModal,
            computedRate,
            defaultCanOperation,
            getBirthdayDisplay,
        };
    }
});

</script>
<style scoped lang="scss" src="../../assets/scss/modules/list/listDetail.scss"></style>
<style scoped>
>>> .contract-cart-page-wrapper {
    height: inherit !important;
}
</style>
<style>
[list-detail-modal] .el-dialog__header, [list-detail-modal] .el-dialog__body {
    padding: 0 !important;
}
</style>
