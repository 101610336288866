<template>
    <div class="leave_wrap" v-infinite-scroll="loadList">
        <article>
            <div class="content" v-for="item in limit" :key="item">
                <div class="left">
                    <span>2023-11-02 17:04:19</span>
                </div>
                <div class="right">
                    <div class="box">黎小姐为会员 黎江文 签到课程私课一对一课程 万豪校区-私课一对一(2023-11-02 17:04:19)</div>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
export default {
    name: 'BalletVueLeaveList',

    data() {
        return {
            limit:8,
        };
    },

    mounted() {

    },

    methods: {
         //加载数据函数
         loadList(){
            setTimeout(() => {
                this.limit+=2;
            }, 1500);
        },
    },
};
</script>

<style lang="scss" scoped>
.leave_wrap {
    padding: 10px 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    //min-height: 402px;
    display: flex;
    height: 400px;
    justify-content: flex-end;
    .content{
        width: 950px;
        display: flex;
        // border: 1px solid #0f0;
        height: fit-content;
        .left{
            border-right: 3px solid #cccccc;
            min-height: 77px;
            flex: 0 0 140px;
            padding-top: 10px;
            box-sizing: border-box;
            span{
                color: #818c99;
                font-size: 12px;
                position: relative;
            }
            span::after{
                content: '';
                width: 10px;
                height: 10px;
                background: #cccccc;
                border-radius: 50%;
                position: absolute;
                right: -33px;
                top: 2px;
            }
        }
        .right{
            flex: 1;
            height: auto;
            width: 100%;
            white-space: wrap;
            // border-right: 1px solid #00f;
            padding-top: 3px;
            padding-left: 25px;
            box-sizing: border-box;
            .box::before{
                content: '';
                position: absolute;
                width: 0px;
                height: 0px;
                overflow: hidden;
                left: -16px;
                top: 7px;
                transform: rotate(180deg);
                border: 8px solid #fff;
                border-right: 8px solid transparent;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }
            .box{
                background: #fff;
                white-space: wrap;
                padding: 10px;
                box-sizing: border-box;
                min-height: 40px;
                width: fit-content;
                max-width: 780px;
                display: flex;
                flex-wrap: wrap;
                // overflow: hidden;
                word-break: break-all;
                filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
            }
        }
    }
}
</style>
